import React, { useState, useEffect } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '../../components/Button';
import FormTitle from '../../components/FormTitle';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import { API_WC_INTEGRATION } from '@api/constants';
import { FormRow, GridColumn } from '@components/Grid';
import { fetchIntegrations } from '@redux/modules/application/actions';
import { hideModal } from '@redux/modules/UI/actions';
import { useUserCompanyId } from '@redux/selectors';
import { urlValidatorRegExp } from '@utils/validators';

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.error};
  ${(props) => props.theme.text.micro};
`;

const WoocommerceIntegrationForm = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      url: 'https://',
    },
  });

  const [isLoading, toggleIsLoading] = useState();
  const dispatch = useDispatch();
  const userCompanyId = useUserCompanyId();

  useEffect(() => {
    fetchIntegrations();
  }, []);

  const onSubmit = async (formData) => {
    toggleIsLoading(true);
    const storeUrl = formData.url;
    const newWin = window.open('', 'newWin', 'width=600,height=680');
    fetch(API_WC_INTEGRATION, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({ store_url: storeUrl, company_id: userCompanyId }),
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(hideModal());
        }
        return response.json();
      })
      .then((res) => {
        const url = res.auth_link;
        newWin.location.href = url;
        return false;
      });
  };

  return (
    <Modal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTitle scale={3}>Please enter WooCommerce store URL</FormTitle>
        <Controller
          name="url"
          control={control}
          rules={{ required: true, pattern: urlValidatorRegExp }}
          render={({ field }) => <Input {...field} placeholder="store URL" hasError={errors.url} full />}
        />
        {errors.url && <ErrorMessage>Please enter store URL beginning with https://</ErrorMessage>}
        <FormRow>
          <GridColumn lg={6} md={6}>
            <Button type="submit" disabled={isLoading} primary full>
              Confirm
            </Button>
          </GridColumn>
          <GridColumn lg={6} md={6}>
            <Button onClick={() => dispatch(hideModal())} tertiary full>
              Cancel
            </Button>
          </GridColumn>
        </FormRow>
      </form>
    </Modal>
  );
};

export default WoocommerceIntegrationForm;
