import axios from 'axios';
import { stringify } from 'query-string';

import { API_INTEGRATION_PORT, API_SHOPIFY_INTEGRATION } from '@api/constants';
import { getRequest, putRequest, postRequest } from '@api/requests';

export const integrationEndpoint = `${API_INTEGRATION_PORT}/integration/v1`;

const getStripeRecord = async (type, query, body) => {
  const result = await postRequest(`${integrationEndpoint}/payments/${type}/list?${stringify(query)}`, body);

  return result;
};

export const getPayments = async (query = {}, body = {}) => postRequest(`${integrationEndpoint}/payments/list?${stringify(query)}`, body);

export const getPayouts = async (query = {}, body = {}) => getStripeRecord('payouts', query, body);

export const getBalanceTransactions = async (query = {}, body = {}) => getStripeRecord('balance_transactions', query, body);

export const getBalance = async () => {
  const result = await getRequest(`${integrationEndpoint}/payments/balance`);

  return result;
};

export const getStripeOverviewData = async (type, payload) => {
  const result = await postRequest(`${integrationEndpoint}/payments/reports/overview/${type}`, {
    ...payload,
    // something we can use in the future to show by month or day
    // unit: 'month',
  });

  return result;
};

export const getStripeConnectedAccounts = async () => {
  const result = await getRequest(`${integrationEndpoint}/payments/accounts`);

  return result;
};

export const paymentOnboarding = async () => {
  const result = await postRequest(`${integrationEndpoint}/payments/onboard`);

  return result;
};

export const resumeOnboarding = async () => {
  const result = await postRequest(`${integrationEndpoint}/payments/resume-onboarding`);

  return result;
};

export const paymentSignUpRequest = async () => {
  const result = await postRequest(`${integrationEndpoint}/payments/request-payment`);

  return result;
};

export const refundPayment = async (payload) => {
  const result = await postRequest(`${integrationEndpoint}/payments/refund`, payload);

  return result;
};

export const linkShopifyUser = async (payload) => {
  const result = await axios.put(`${API_SHOPIFY_INTEGRATION}/v1/link-logged-in-user`, payload);

  return result;
};

export const getWalletSignUpRequest = async () => {
  const result = await postRequest(`${integrationEndpoint}/wallets/accounts`);

  return result;
};

export const getWalletAccounts = async () => {
  const result = await getRequest(`${integrationEndpoint}/wallets/accounts`);

  return result;
};

export const getWalletOnboarding = async () => {
  const result = await postRequest(`${integrationEndpoint}/wallets/accounts/links`);

  return result;
};

export const getWalletTotalBalance = async (account_id) => {
  const result = await getRequest(`${integrationEndpoint}/wallets/${account_id}/estimated-balance`);

  return result;
};

export const getWalletTransactions = async (account_id, query, body = {}) => {
  const result = await postRequest(`${integrationEndpoint}/wallets/${account_id}/financial_transactions/search?${stringify(query)}`, body);

  return result;
};

export const getSettlementAccount = async (account_id, currency) => {
  const result = await getRequest(`${integrationEndpoint}/wallets/${account_id}/settlement-account?currency=${currency}`);

  return result;
};

export const getXeroReport = async (payload) => {
  const result = await postRequest(`${integrationEndpoint}/xero/report`, payload);

  return result;
};

export const getXeroAvailableMonths = async (xeroAccountId, type = 'PnL') => {
  const result = await getRequest(`${integrationEndpoint}/xero/${xeroAccountId}/report/${type}/available-month`);

  return result;
};

export const getPaymentIntentDetail = async (paymentId) => {
  const result = await getRequest(`${integrationEndpoint}/payments/${paymentId}`);

  return result;
};

export const postShopIntegration = async (query) => {
  return await getRequest(`/api/v2/portal/platforms/shop/integrate?${stringify(query)}`, {});
};
