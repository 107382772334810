import { findFirstMatchInArray, isRealObject } from '@utils/dataTypes';
import { formatPrice } from '@utils/priceHelpers';

export const getTitleFromSchema = (key, schemas) => {
  const schema = findFirstMatchInArray(schemas, ({ field }) => field.key === key);
  return schema?.field?.label === 'Aba' ? 'Routing number' : schema?.field?.label;
};

// export const calculatePaymentAmount = (sourceAmount, rate,)

export const convertFXAmount = (FX, fromCurrency, fromAmount, debug = true) => {
  if (isRealObject(FX)) {
    if (FX.currency_pair.indexOf(fromCurrency) === 0) {
      return fromAmount * FX?.client_rate;
    } else {
      return fromAmount / FX?.client_rate;
    }
  }

  return 0;
};

const fromSourceToPayment = (a, b) => a - b;
const fromPaymentToSource = (a, b) => a + b;

export const calculateToAmount = (payload) => {
  const { fromAmount, fromCurrency, toCurrency, amountOrigin, foreignExchange = {}, fee } = payload;
  const handleFee = amountOrigin === 'sourceAmount' ? fromSourceToPayment : fromPaymentToSource;
  const sourceCurrency = amountOrigin === 'sourceAmount' ? fromCurrency : toCurrency;
  const paymentCurrency = amountOrigin === 'sourceAmount' ? toCurrency : fromCurrency;
  const fxKeyOfSourceAndPaymentCurrency = `${sourceCurrency}to${paymentCurrency}`;
  const fxOfSourceAndPaymentCurrency = foreignExchange[fxKeyOfSourceAndPaymentCurrency];
  let snapshot = {};

  const fxKeyOfSourceAndFee = `${sourceCurrency}toUSD`;
  const fxOfSourceCurrencyAndUSD = foreignExchange[fxKeyOfSourceAndFee];
  const feeAmountInSourceCurrency =
    sourceCurrency === 'USD' ? fee : convertFXAmount(fxOfSourceCurrencyAndUSD, 'USD', fee);

  let toAmountInToCurrency;
  let toAmountInSourceCurrency;
  if (fromCurrency === toCurrency) {
    toAmountInSourceCurrency = handleFee(fromAmount, feeAmountInSourceCurrency);
    toAmountInToCurrency = toAmountInSourceCurrency;
  } else {
    if (amountOrigin === 'sourceAmount') {
      // deduct fee first before conversion
      toAmountInSourceCurrency = handleFee(fromAmount, feeAmountInSourceCurrency);
      toAmountInToCurrency = convertFXAmount(fxOfSourceAndPaymentCurrency, fromCurrency, toAmountInSourceCurrency);
    } else {
      // add fee after conversion
      toAmountInSourceCurrency = convertFXAmount(fxOfSourceAndPaymentCurrency, fromCurrency, fromAmount);
      toAmountInToCurrency = handleFee(toAmountInSourceCurrency, feeAmountInSourceCurrency);
    }
  }

  const result = toAmountInToCurrency;
  snapshot = {
    toAmountInSourceCurrency,
    feeAmountInSourceCurrency,
    fxOfSourceAndPaymentCurrency,
    fxOfSourceAndFee: fxOfSourceCurrencyAndUSD,
  };

  return {
    toAmount: result,
    payload,
    snapshot,
  };
};

export const formatFXRatio = ({ foreignExchange, sourceCurrency, paymentCurrency }) => {
  if (isRealObject(foreignExchange)) {
    const fromSource = convertFXAmount(foreignExchange, sourceCurrency, 1, false);
    if (fromSource >= 1) {
      return `${formatPrice(sourceCurrency, 1, false, 0)} = ${formatPrice(paymentCurrency, fromSource, false, 4)}`;
    } else {
      return `${formatPrice(paymentCurrency, 1, false, 0)} = ${formatPrice(
        sourceCurrency,
        foreignExchange.client_rate,
        false,
        6,
      )}`;
    }
  }

  return '';
};

export const convertBenePageMapToArray = (pageMap) => {
  const pageNumbers = Object.keys(pageMap)
    .map((pageNumber) => Number(pageNumber))
    .filter((pageNumber) => Array.isArray(pageMap[pageNumber]) && pageMap[pageNumber].length > 0)
    .sort((a, b) => {
      return a - b;
    });

  const beneficiaries = [];
  pageNumbers.forEach((num) => {
    const benes = pageMap[num];
    beneficiaries.push(...benes);
  });

  return beneficiaries;
};
