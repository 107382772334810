import React, { useEffect, useState } from 'react';

import deepEqual from 'deep-equal';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, Switch, Route, Redirect, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import BackButton from '../../../../../components/BackButton';
import HR from '../../../../../components/HR';
import LoadingCircle from '../../../../../components/LoadingCircle';
import { Wrapper, Card } from '../../../components';
import PATH from '../../../path';
import { TransferStatusOptions, OPTION_VALUE_ALL } from '../../constants';
import { getWalletPayoutById } from '@api/modules/integration/airwallex';
import { countryMap } from '@/constants';
import { fetchWalletPayoutById } from '@redux/modules/wallet/actions';
import { useWalletAccount } from '@redux/selectors';
import { convertToFullDateAndTime } from '@utils/dateHelpers';
import { getOptionLabelByValue } from '@utils/optionHelpers';
import { formatPrice } from '@utils/priceHelpers';
import { capitalize, replaceDelimiter } from '@utils/stringHelpers';

const Title = styled.div`
  ${(props) => props.theme.text.four};
`;

const StyledCard = styled(Card)`
  padding: 40px 20px;
`;

const StyledBackButton = styled(BackButton)`
  margin-bottom: 24px;
`;

const TH = styled.th`
  font-weight: bold;
  line-height: 120%;
`;

const TR = styled.tr`
  color: ${(props) => props.theme.colors.grayThree};
  td:first-child {
    width: ${(props) => props.cellWidth}px;
  }
  font-weight: 600;
`;

const Circle = styled.div`
  width: 8px;
  background-color: ${(props) => props.theme.colors.grayTwo};
  height: 8px;
  border-radius: 50%;
`;

const HistoryTable = styled.table`
  tr:first-child {
    color: #a5a5a5;
    font-size: 10px;
  }

  tr:last-child {
    font-size: 12px;
  }
`;

const Detail = ({ match: { params } }) => {
  const { paymentId } = params;
  const history = useHistory();
  const walletAccount = useWalletAccount();
  const { payoutDetail, isLoading } = useSelector((state) => state.wallet);

  useEffect(() => {
    if (walletAccount?.account_id && payoutDetail?.payment_id !== paymentId) {
      fetchWalletPayoutById(walletAccount?.account_id, paymentId);
    }
  }, [JSON.stringify(walletAccount)]);

  const formatTransferMethod = () => {
    if (payoutDetail?.payment_method === 'SWIFT') {
      return `${payoutDetail?.payment_method} - ${payoutDetail?.swift_charge_option}`;
    } else {
      return payoutDetail?.beneficiary?.bank_details?.local_clearing_system || payoutDetail?.payment_method;
    }
  };

  return isLoading && deepEqual(payoutDetail, {}) ? (
    <LoadingCircle />
  ) : (
    <Wrapper>
      <StyledBackButton text="Back" path={PATH.WALLET_TRANSFER_PAYOUTS} />
      <Title>{`Transfer to ${payoutDetail?.beneficiary?.bank_details?.account_name}`}</Title>
      <TR cellWidth={150}>
        <td>Short reference:</td>
        <td>{payoutDetail?.short_reference_id}</td>
      </TR>
      <TR>
        <td>Status:</td>
        <td>{getOptionLabelByValue(payoutDetail?.status, TransferStatusOptions)}</td>
      </TR>
      <StyledCard>
        <Title>Transfer details</Title>
        <HR color="grayOne" />
        <table>
          <tbody>
            <TR cellWidth={180}>
              <td>You send: </td>
              <td>{formatPrice(payoutDetail?.source_currency, payoutDetail?.amount_payer_pays)}</td>
            </TR>
            <TR>
              <td>Recipient gets: </td>
              <td>{formatPrice(payoutDetail?.payment_currency, payoutDetail?.amount_beneficiary_receives)}</td>
            </TR>
            <TR>
              <td>Transfer method: </td>
              <td>{formatTransferMethod(payoutDetail?.payment_method)}</td>
            </TR>
            <TR>
              <td>Transfer fee: </td>
              <td>{formatPrice(payoutDetail?.fee_currency, payoutDetail?.fee_amount)}</td>
            </TR>
            <TR>
              <td>Transfer date: </td>
              <td>{payoutDetail?.payment_date}</td>
            </TR>
            <TR>
              <td>Reference: </td>
              <td>{payoutDetail?.reference}</td>
            </TR>
            <TR>
              <td>Internal reference: </td>
              <td>{payoutDetail?.request_id}</td>
            </TR>
          </tbody>
        </table>
      </StyledCard>
      <StyledCard>
        <Title>Payer and recipient</Title>
        <HR color="grayOne" />
        <table>
          <thead>
            <TH>PAYER DETAILS</TH>
          </thead>
          <tbody>
            <TR cellWidth={180}>
              <td>Payer name:</td>
              <td>{payoutDetail?.payer?.company_name}</td>
            </TR>
          </tbody>
        </table>
        <HR color="grayOne" />
        <table>
          <thead>
            <TH>RECIPIENT DETAILS</TH>
          </thead>
          <tbody>
            <TR cellWidth={180}>
              <td>Account name:</td>
              <td>{payoutDetail?.beneficiary?.company_name}</td>
            </TR>
            <TR>
              <td>Bank name:</td>
              <td>{payoutDetail?.beneficiary?.bank_details?.bank_name}</td>
            </TR>
            {payoutDetail?.beneficiary?.bank_details.swift_code && (
              <TR>
                <td>Swift code:</td>
                <td>{payoutDetail?.beneficiary?.bank_details?.swift_code}</td>
              </TR>
            )}
            <TR>
              <td>Account number:</td>
              <td>{payoutDetail?.beneficiary?.bank_details?.account_number}</td>
            </TR>
            <TR>
              <td>Bank location:</td>
              <td>{countryMap[payoutDetail?.beneficiary?.bank_details?.bank_country_code]}</td>
            </TR>
          </tbody>
        </table>
      </StyledCard>
      <Title>History</Title>
      <Card>
        <HistoryTable>
          <tbody>
            <TR cellWidth={10}>
              <td />
              <td>{convertToFullDateAndTime(payoutDetail?.created_at)}</td>
            </TR>
            <TR>
              <td>
                <Circle />
              </td>
              <td>Created</td>
            </TR>
          </tbody>
        </HistoryTable>
      </Card>
    </Wrapper>
  );
};

export default Detail;
