import React from 'react';

import { useDispatch } from 'react-redux';
import { useTable } from 'react-table';
import styled, { css } from 'styled-components';

import { BANK_SETTLEMENT_TRANSACTION_MODAL, AUTO_SETTLEMENT_TRANSACTION_MODAL } from '../../../Modal/constants';
import { Table, Th, SummaryTableRow } from '../../Business/components';
import { REVENUE_FORM_NAME } from '../CreateManualInvoice/constants';
import { breakdownTableColumns } from './constants';
import { getBankSettlementDetailById } from '@api/modules/dashboard/settlement';
import { XERO_TRX_TYPE } from '@constants/xero';
import { showModal } from '@redux/modules/UI/actions';
import { formatNumber } from '@utils/priceHelpers';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
`;

const Tr = styled.tr`
  ${(props) =>
    props.isClickable &&
    css`
      td.description {
        color: ${props.theme.colors.orange};
      }
      :hover {
        background-color: ${props.theme.colors.grayOne};
        cursor: pointer;
      }
    `}
`;

const BreakdownTable = ({ invoiceDetail }) => {
  const dispatch = useDispatch();
  const { breakdowns = [], revenue_amount_in_usd: totalRevenue, invoiced_amount_usd } = invoiceDetail;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data:
      invoiceDetail.type === REVENUE_FORM_NAME
        ? [
            {
              channel: 'Payment',
              description: invoiceDetail.description,
              currency: invoiceDetail.currency,
              invoice_currency: invoiceDetail.currency,
              amount_in_usd: invoiceDetail.invoiced_amount_usd,
              amount: invoiceDetail.invoiced_amount,
              type: REVENUE_FORM_NAME,
            },
          ]
        : breakdowns,
    columns: breakdownTableColumns,
  });

  const viewSettlementDetail = (breakdown) => async () => {
    if (breakdown.type === 'bank') {
      dispatch(
        showModal(BANK_SETTLEMENT_TRANSACTION_MODAL, {
          invoiceDetail,
          breakdown,
          type: breakdown.breakdown_type === 'received' ? XERO_TRX_TYPE.RECEIVE : XERO_TRX_TYPE.SPEND,
        }),
      );
    } else if (breakdown.type === 'auto') {
      dispatch(
        showModal(AUTO_SETTLEMENT_TRANSACTION_MODAL, {
          breakdown,
        }),
      );
    }
  };

  return (
    <Wrapper>
      <Table>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <Th className={column.className} {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </Th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          <SummaryTableRow>
            {breakdownTableColumns.map(({ accessor, className }, index) => {
              if (index === 0) {
                return <td>Summary</td>;
              } else if (accessor === 'description') {
                return <td>Total Revenue</td>;
              } else if (accessor === 'invoice_currency') {
                return <td>USD</td>;
              } else if (accessor === 'amount_in_usd') {
                return (
                  <td className={className}>
                    {formatNumber(invoiceDetail.type === REVENUE_FORM_NAME ? invoiced_amount_usd : totalRevenue, 2)}
                  </td>
                );
              } else if (accessor === 'amount') {
                return <td className={className}>-</td>;
              }

              return <td>-</td>;
            })}
          </SummaryTableRow>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Tr
                {...row.getRowProps()}
                onClick={viewSettlementDetail(row.original)}
                isClickable={invoiceDetail.type !== REVENUE_FORM_NAME}
              >
                {row.cells.map((cell) => {
                  return (
                    <td className={cell.column.className} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </Tr>
            );
          })}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default BreakdownTable;
