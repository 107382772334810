import { isRealNumber } from './dataTypes';
import { formatNumberWithUnit } from './unitHelpers';
import { FILE_TYPE_DISPLAY_NAME_MAP, SIZE_UNITS, SIZE_SCALE } from '@constants/file';

export const getFileTypeDisplayName = (type) => {
  return FILE_TYPE_DISPLAY_NAME_MAP[type] || '';
};

export const formatFileSize = (sizeInByte, decimalScale) => {
  return formatNumberWithUnit(sizeInByte, decimalScale, SIZE_SCALE, SIZE_UNITS, ' ');
};
