import COLORS from '../../../styles/colors';
import { findFirstMatchInArray, isRealObject } from '@utils/dataTypes';

export const getColorByWalletStatus = (status) => {
  switch (status) {
    case 'SETTLED':
      return COLORS.green;
    case 'CANCELLED':
      return COLORS.red;
    case 'PENDING':
      return COLORS.grayThree;
    default:
      return COLORS.black;
  }
};

export const getColorByAccountStatus = (status) => {
  switch (status) {
    case 'ACTIVE':
      return COLORS.green;
    case 'PROCESSING':
      return COLORS.orange;
    case 'INACTIVE':
      return COLORS.red;
    default:
      return COLORS.black;
  }
};

export const getColorByGlobalAccountTransactionStatus = (status) => {
  switch (status) {
    case 'ACTIVE':
      return COLORS.green;
    case 'PROCESSED':
      return COLORS.green;
    case 'INACTIVE':
      return COLORS.red;
    default:
      return COLORS.black;
  }
};

export const getRecipientDisplayName = (beneficiaryDetail) => {
  const { entity_type, company_name, first_name, last_name } = beneficiaryDetail;
  return entity_type === 'COMPANY' ? company_name : entity_type === 'PERSONAL' ? `${first_name} ${last_name}` : '';
};

export const generatePaymentMethodOptions = (paymentMethodOptions, localClearingSystemOptions) => {
  let combinedOptions = [
    ...(Array.isArray(localClearingSystemOptions) ? localClearingSystemOptions : []),
    ...(Array.isArray(paymentMethodOptions) ? paymentMethodOptions : []),
  ];

  if (Array.isArray(localClearingSystemOptions) && localClearingSystemOptions.length > 0) {
    combinedOptions = combinedOptions.filter(({ value }) => value !== 'LOCAL');
  }

  return combinedOptions;
};

export const convertFormSchemaResponse = (data, order = []) => {
  return {
    ...data,
    fields: data.fields.map((schema) => {
      const orderIndex = order.indexOf(schema.path);
      return { ...schema, order: orderIndex };
    }),
  };
};

export const getPaymentMethodCondition = (paymentMethod, localClearingSystem) => {
  return {
    payment_method: paymentMethod === 'SWIFT' ? 'SWIFT' : 'LOCAL',
    local_clearing_system:
      localClearingSystem || (['SWIFT', 'LOCAL'].indexOf(paymentMethod) !== -1 ? undefined : paymentMethod),
  };
};

export const getDefaultValueFromSchema = (key, schemas) => {
  const schema = findFirstMatchInArray(schemas, ({ field }) => field.key === key);

  return schema?.field?.default;
};

export const generateRecipientPayload = (recipient, schemas) => {
  const methodCategory = recipient.paymentMethod === 'SWIFT' ? 'SWIFT' : 'LOCAL';

  return {
    beneficiary: {
      ...recipient.beneficiary,
      bank_details: {
        ...recipient.beneficiary.bank_details,
        ...(methodCategory === 'SWIFT'
          ? {
              swift_code: recipient.beneficiary.bank_details.swift_code,
            }
          : {
              local_clearing_system: recipient.paymentMethod,
              account_routing_type1: getDefaultValueFromSchema('account_routing_type1', schemas),
            }),
      },
    },
    nickname: recipient.nickname,
    payment_methods: [methodCategory],
  };
};

export const getFeeByMethod = (feeObject, method, swiftChargeOption) => {
  if (isRealObject(feeObject)) {
    if (method === 'SWIFT') {
      return feeObject[method][swiftChargeOption];
    } else {
      return feeObject[method] || feeObject.LOCAL;
    }
  } else {
    return '';
  }
};

export const generateSourceCurrencyOptions = (currencyBalances, globalAccountsOptions) => {
  const creditedAccountCurrencies = currencyBalances
    .filter(({ total_amount }) => total_amount)
    .map(({ currency }) => currency);
  const emptyAccountCurrencies = currencyBalances
    .filter(({ total_amount }) => !total_amount)
    .map(({ currency }) => currency);

  const matchedCreditAccountCurrencies = globalAccountsOptions.filter(
    ({ value }) => creditedAccountCurrencies.indexOf(value) !== -1,
  );
  const matchedEmptyAccountCurrencies = globalAccountsOptions.filter(
    ({ value }) => emptyAccountCurrencies.indexOf(value) !== -1,
  );

  const sortByCurrency = (prev, curr) => (prev.value > curr.value ? 1 : -1);

  return [...matchedCreditAccountCurrencies, ...matchedEmptyAccountCurrencies.sort(sortByCurrency)];
};

export const isSourceAmountValid = (value, totalAmount) => {
  // TODO: amount higher than your transacting limit.
  return value < totalAmount;
};
