import { LOGOUT_USER } from '../auth/constants';
import { SELECT_COMPANY_REQUEST } from '../company/constants';
import { SWITCH_TO_ADMIN_VIEW, SWITCH_TO_USER_VIEW } from '../UI/constants';
import {
  GET_CREDIT_MODEL_REPORT_REQUEST,
  GET_CREDIT_MODEL_REPORT_FAILURE,
  GET_CREDIT_MODEL_REPORT_SUCCESS,
  GET_INTEGRATION_PROGRESS_REQUEST,
  GET_INTEGRATION_PROGRESS_FAILURE,
  GET_INTEGRATION_PROGRESS_SUCCESS,
} from './constants';

const initialState = {
  report: null,
  isLoading: false,
  integrationProgressMap: {},
};

const bankReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CREDIT_MODEL_REPORT_REQUEST:
      return {
        ...state,
        isLoading: false,
      };
    case GET_CREDIT_MODEL_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_CREDIT_MODEL_REPORT_SUCCESS:
      return {
        ...state,
        report: action.payload,
        isLoading: false,
      };
    case GET_INTEGRATION_PROGRESS_SUCCESS:
      return {
        ...state,
        integrationProgressMap: {
          ...state.integrationProgressMap,
          [action.payload.platform]: {
            ...state.integrationProgressMap[action.payload.platform],
            [action.payload.integrationId]: action.payload.progress,
          },
        },
      };
    // case SWITCH_TO_ADMIN_VIEW:
    case SELECT_COMPANY_REQUEST:
    case SWITCH_TO_USER_VIEW:
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default bankReducer;
