import React from 'react';

import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: inline-block;
  color: ${(props) => props.theme.colors.purple};
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.purple};
  border-radius: 5px;
  padding: 12px 10px;
  font-weight: 500;
  line-height: 24px;

  ${(props) =>
    typeof props.onClick === 'function' &&
    css`
      cursor: pointer;
    `}

  > img {
    margin-left: 12px;
  }

  > span,
  > img {
    /* vertical-align: middle; */
  }
`;

const FilterTag = ({ children, canRemove = true, onRemove, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <span>{children}</span>
      {canRemove && <img src="/icons/circle_close.svg" alt="close" onClick={onRemove} />}
    </Wrapper>
  );
};

export default FilterTag;
