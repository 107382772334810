import store from '../../store';
import {
  UPDATE_APPLICATION_DRAFT,
  GET_APPLICATION_REQUEST,
  GET_APPLICATION_SUCCESS,
  GET_APPLICATION_FAILURE,
  GET_INTEGRATIONS_REQUEST,
  GET_INTEGRATIONS_SUCCESS,
  GET_INTEGRATIONS_FAILURE,
} from './constants';
import { initialState } from './reducer';
import { getApplication, getIntegratedAccounts } from '@api/modules/application';
import { convertToBoolean } from '@utils/dataTypes';

export const updateApplicationDraft = (payload) => ({
  type: UPDATE_APPLICATION_DRAFT,
  payload,
});

export const fetchApplication = async () => {
  store.dispatch({ type: GET_APPLICATION_REQUEST });

  const { data, error, statusCode } = await getApplication();

  if (error || statusCode !== 200) {
    store.dispatch({ type: GET_APPLICATION_FAILURE });
  } else {
    store.dispatch({
      type: GET_APPLICATION_SUCCESS,
      payload: {
        applicationId: data.application_id,
        statusCode: data.statusCode,
        additionalInformation: data.additional_information,
        fundingNumber: data.funding_number ? `#${data.funding_number}` : `#${data.application_id.split('-')[0]}`,
        salesForm: {
          noPlatform: convertToBoolean(data.no_platform),
          amount: data.monthly_revenue,
          currency: data.currency,
        },
        offerForm: {
          currency: 'USD',
          availableFund: data.funding_amount,
          fund: data.requested_funding_amount,
          preliminaryOffer: data.preliminary_offer
            ? {
                fundingAmount: data.preliminary_offer.funding_amount,
                remittanceRate: data.preliminary_offer.remittance_rate,
                paybackMonths: data.preliminary_offer.payback_months,
                remittanceAmount: data.preliminary_offer.remittance_amount,
                fundingAmountRange: data.preliminary_offer.funding_amount_range,
                targetedTotalRepayment: data.preliminary_offer.targeted_total_repayment,
                targetTotalRemittanceAmount: data.preliminary_offer.target_total_remittance_amount,
              }
            : initialState.data.offerForm.preliminaryOffer,
        },
        history: data.history,
      },
    });
  }
};

export const fetchIntegrations = async () => {
  store.dispatch({ type: GET_INTEGRATIONS_REQUEST });

  const { data } = await getIntegratedAccounts();

  if (Array.isArray(data)) {
    store.dispatch({
      type: GET_INTEGRATIONS_SUCCESS,
      payload: data.map((account) => ({
        id: account.integration_id,
        accountId: account.channel_account_id,
        accountName: account.channel_account_name,
        accountUserId: account.account_user_id,
        platformName: account.platform_name,
        status: account.status,
        integrationType: account.integration_type,
        ...(account.installation_link && {
          installationLink: account.installation_link,
        }),
      })),
    });
  } else {
    store.dispatch({ type: GET_INTEGRATIONS_FAILURE });
  }
};
