import React from 'react';

import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';

import { isRealNumber } from '@utils/dataTypes';

const Placeholder = styled.span`
  cursor: pointer;
`;

const StyledTooltip = styled(ReactTooltip)`
  ${(props) =>
    isRealNumber(props.width)
      ? css`
          width: ${props.width}px;
        `
      : css`
          display: inline-block;
        `}
  text-align: initial;
`;

const ToolTip = ({ tip, placeholder, innerRef, className, ...restProps }) => {
  return (
    <>
      <Placeholder ref={innerRef} className={className} data-tip={tip}>
        {placeholder || <img src="/icons/tool_tip.svg" alt="toolTip" />}
      </Placeholder>
      <StyledTooltip {...restProps} />
    </>
  );
};

export default ToolTip;
