import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Button from '../../../../../components/Button';
import FancyDateRangePicker from '../../../../../components/FancyDateRangePicker';
import Table from '../../../../../components/Table/index';
import { TransferStatusOptions, OPTION_VALUE_ALL } from '../../constants';
import SimplePagination from '../../../../../components/Pagination/SimplePagination';
import SearchBar from '../../../../../components/SearchBar';
import PATH from '../../../path';
import Logger from '../../../../../utils/logger';
import { Wrapper } from '../../../components';
import { FilterLabel, StyledSelect } from '../../IntegratedOverview/components';
import { usePagination } from '@hooks/UI';
import { fetchWalletPayouts } from '@redux/modules/wallet/actions';
import { GET_WALLET_PAYOUT_DETAIL_SUCCESS } from '@redux/modules/wallet/constants';
import { useWalletAccount, useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';
import { media } from '@styles/breakpoints';
import { convertToFullDate, convertToApiDate } from '@utils/dateHelpers';
import { getOptionLabelByValue } from '@utils/optionHelpers';
import { formatNumber } from '@utils/priceHelpers';
import { padStart } from '@utils/stringHelpers';

const PageInfo = styled.div`
  flex-grow: 2;

  div:first-child {
    ${(props) => props.theme.text.four};
  }
`;

const Container = styled.div`
  display: flex;

  ${media.dablet`
    flex-direction: column;
  `};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;

  ${media.dablet`
    display: flex;
    gap: 5px;
    justify-content: start;
  `};
`;

const AccountNumber = styled.div`
  color: ${(props) => props.theme.colors.grayThree};
`;

const FilterSection = styled.span`
  display: flex;
  margin: 26px 0;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 20px;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const payoutsTableColumns = [
  {
    Header: 'Transfer date',
    accessor: 'created_at',
    Cell: ({ value }) => {
      return convertToFullDate(value);
    },
    getStyle: () => {
      return css`
        width: 150px;
      `;
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => {
      return getOptionLabelByValue(value, TransferStatusOptions);
    },
  },
  {
    Header: 'Recipient',
    accessor: 'beneficiary',
    Cell: ({ value }) => {
      return (
        <>
          <div>{value.bank_details.account_name}</div>
          <AccountNumber>
            Account Number:&nbsp;
            {padStart(value.bank_details.account_number, 4)}
          </AccountNumber>
        </>
      );
    },
    getStyle: () => {
      return css`
        width: 30%;
      `;
    },
  },
  {
    Header: 'Short reference',
    accessor: 'short_reference_id',
    Cell: ({ value }) => {
      return value;
    },
  },
  {
    Header: 'Currency',
    accessor: 'payment_currency',
    Cell: ({ value }) => {
      return value;
    },
    getStyle: () => {
      return css`
        width: 100px;
      `;
    },
  },
  {
    Header: 'Recipient gets',
    accessor: 'amount_beneficiary_receives',
    Cell: ({ value }) => {
      return formatNumber(value);
    },
    getStyle: () => {
      return css`
        width: 150px;
        text-align: right;
      `;
    },
  },
];

const List = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pageNumber, pageSize, onPageNumberChange, onPageSizeChange } = usePagination({
    pageNumber: 1,
    pageSize: 20,
  });
  const payoutPageMap = useSelector((state) => state.wallet.payoutPageMap);
  const isLoading = useSelector((state) => state.wallet.isLoading);
  const email = useSelector((state) => state.user.profile.username);
  const data = payoutPageMap[pageNumber] || [];
  const walletAccount = useWalletAccount();
  const [statusFilter, setStatusFilter] = useState(OPTION_VALUE_ALL);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateShortcutValue, setDateShortValue] = useState();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchResult, setSearchResult] = useState('');
  const disabled = useCheckIsAdminViewingAnotherCompany() && email !== 'lewis@choco-up.com'; // TODO: remove 'lewis@choco-up.com' after debug

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchResult(searchKeyword), 1000);
    return () => clearTimeout(timeOutId);
  }, [searchKeyword]);

  const applyCalendar = (calendarData) => {
    setStartDate(calendarData.startDate);
    setEndDate(calendarData.endDate);
    setDateShortValue(calendarData.dateShortcutSelectValue);
  };

  const getWalletPayouts = async () => {
    try {
      const filters = [];
      if (statusFilter !== OPTION_VALUE_ALL) {
        filters.push({
          field: 'status',
          operator: 'in',
          value: statusFilter.map(({ value }) => value),
        });
      }

      if (searchResult.length > 1) {
        filters.push({
          fields: ['short_reference_id'],
          operator: 'like',
          value: [searchResult],
        });
      }

      fetchWalletPayouts(walletAccount?.account_id, {
        pageNumber,
        pageSize,
        // Todo: quick fix for now, need to fix in future
        ...(startDate && { startDate: convertToApiDate(startDate) }),
        ...(endDate && {
          endDate: convertToApiDate(moment(endDate).add(1, 'days')),
        }),
        filters,
      });
    } catch (err) {
      Logger.error(err);
    }
  };

  useEffect(() => {
    if (walletAccount?.account_id) {
      getWalletPayouts();
    }
  }, [JSON.stringify(walletAccount), pageNumber, pageSize, startDate, endDate]);

  useEffect(() => {
    getWalletPayouts();
  }, [statusFilter, searchResult]);

  const handleRowClick = (value) => {
    dispatch({
      type: GET_WALLET_PAYOUT_DETAIL_SUCCESS,
      payload: value,
    });
    history.push(
      generatePath(PATH.WALLET_TRANSFER_PAYOUT_DETAIL, {
        paymentId: value.payment_id,
      })
    );
  };

  const showAddTransferModal = () => {
    history.push(PATH.WALLET_TRANSFER_CREATE_PAYOUTS);
  };

  return (
    <Wrapper>
        <Container>
          <PageInfo>
            <div>Transfer</div>
            <div>Send money from your wallet to other wallet accounts and accounts held with other banks</div>
          </PageInfo>
          <ButtonWrapper>
            <Button onClick={showAddTransferModal} disabled={disabled}>
              Send Money
            </Button>
          </ButtonWrapper>
        </Container>
        <FilterSection>
          <SearchBar
            width="500px"
            placeholder="Search by short reference"
            value={searchKeyword}
            onChange={(event) => setSearchKeyword(event.target.value)}
          />
          <SelectWrapper>
            <FilterLabel>Status</FilterLabel>
            <StyledSelect
              isMulti
              width={280}
              value={statusFilter}
              options={TransferStatusOptions}
              onChange={setStatusFilter}
              multiValueLabel="Statuses"
            />
          </SelectWrapper>
          <SelectWrapper>
            <FancyDateRangePicker
              startDate={startDate}
              endDate={endDate}
              dateShortcutValue={dateShortcutValue}
              onDateRangeSelectValueChange={setDateShortValue}
              onApply={applyCalendar}
              disabled={isLoading}
            />
          </SelectWrapper>
        </FilterSection>
        <Table columns={payoutsTableColumns} data={data} onRowClick={handleRowClick} isLoading={isLoading} pagination={{ pageSize }} />
        <SimplePagination
          pageSize={pageSize}
          pageNumber={pageNumber}
          onPageNumberChange={onPageNumberChange}
          onPageSizeChange={onPageSizeChange}
          noNextPage={data.length < pageSize}
        />
      </Wrapper>
  );
};

export default List;
