import React, { useState } from 'react';

import Slider from 'rc-slider';
import { useForm, useController, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import NumberFormatter from '../../../../components/NumberFormatter';
import Logger from '../../../../utils/logger';
import { CALCULATOR_CONFIG, SLIDER_CONFIG } from '../../../Application/Forms/PreliminaryOfferForm/constants';
import RHFMoneyInput from '../../../ReactHookForm/RHFMoneyInput';
import { Card } from '../../components';
import Countdown from '../Countdown';
import { updateCreditRiskModel } from '@api/modules/dashboard';
import { fetchCreditModelReport } from '@redux/modules/creditModel/actions';
import { getPercentFromDecimal } from '@utils/priceHelpers';

const Title = styled.div`
  ${(props) => props.theme.text.four};
  margin-bottom: 16px;
`;

const FieldTitle = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: 600;
`;

const Row = styled.div`
  > div {
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;

    :last-child {
      width: 60%;
    }
  }

  > div,
  > input {
    :first-child {
      width: 350px;
      margin-right: 32px;
    }
  }
`;

const AmountSlider = styled(Slider)`
  .rc-slider-mark-text {
    &:first-child {
      left: 3% !important;
    }

    &:last-child {
      left: 96% !important;
    }
  }
`;

const PercentSlider = styled(Slider)`
  .rc-slider-mark-text {
    &:first-child {
      left: 1% !important;
    }

    &:last-child {
      left: 99% !important;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 36px;
  text-align: right;
`;

const SliderWrapper = styled.div`
  margin: 24px 0;
`;

const SuccessMessage = styled.div`
  color: ${(props) => props.theme.colors.green};
  ${(props) => props.theme.text.micro};
`;

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.red};
  ${(props) => props.theme.text.micro};
`;

const PERCENTAGE_CONFIG = {
  min: 1,
  max: 50,
  step: 0.01,
};

const PERCENTAGE_SLIDER_MARKS = {
  [PERCENTAGE_CONFIG.min]: `${PERCENTAGE_CONFIG.min}%`,
  [PERCENTAGE_CONFIG.max / 2]: `${PERCENTAGE_CONFIG.max / 2}%`,
  [PERCENTAGE_CONFIG.max]: `${PERCENTAGE_CONFIG.max}%`,
};

const SUCCESS_VALUE = 'SUCCESS';
const FAILURE_VALUE = 'FAILURE';

// RiskAdjustment is a component for handling adjustment of parameters to
// be sent to run the credit risk model.
const RiskAdjustment = ({ creditModelReport = {}, isLoading, toggleIsLoading, refreshTableau, tableauViz }) => {
  const { fund, currency } = useSelector((state) => state.application.data.offerForm);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fundingCurrency: 'USD',
      fundingAmount: creditModelReport.funding_amount, // assuming it is in USD
      feePercent: getPercentFromDecimal(creditModelReport.fee_percentage),
      retrievalPercent: getPercentFromDecimal(creditModelReport.retrieval_rate),
    },
  });

  const [isOpen, toggleIsOpen] = useState(false);
  const [apiStatus, setAPIStatus] = useState(''); // '' or 'SUCCESS' or 'FAILURE'

  const watchOfFundingAmount = watch('fundingAmount');

  const retrievalPercentController = useController({ control, name: 'retrievalPercent' });
  const feePercentController = useController({ control, name: 'feePercent' });

  const onSubmit = async (formData) => {
    toggleIsLoading(true);
    setAPIStatus('');
    try {
      const { statusCode } = await updateCreditRiskModel(
        {}, // query
        {
          funding_amount: formData.fundingAmount,
          fee_percent: formData.feePercent,
          retrieval_percent: formData.retrievalPercent,
        },
      );
      if (statusCode < 300) {
        setAPIStatus(SUCCESS_VALUE);
      } else {
        setAPIStatus(FAILURE_VALUE);
      }
    } catch (err) {
      setAPIStatus(FAILURE_VALUE);
      Logger.error(err);
    } finally {
      // Update the tableau report regardless
      refreshTableau(tableauViz);
      fetchCreditModelReport();
      toggleIsLoading(false);
    }
  };

  return isOpen ? (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title>Funding Terms Adjustment</Title>
        <div>
          <FieldTitle scale={5}>Funding Amount (USD)</FieldTitle>
          <Row>
            <RHFMoneyInput
              control={control}
              prefix="funding"
              selectProps={{ disabled: true }}
              inputProps={{
                ...CALCULATOR_CONFIG,
                disabled: isLoading,
              }}
              amountRules={{ required: true, min: 0, max: 2000000 }}
              errors={errors}
            />
            <SliderWrapper>
              <AmountSlider
                value={watchOfFundingAmount}
                onChange={(v) => setValue('fundingAmount', v)}
                marks={SLIDER_CONFIG}
                disabled={isLoading}
                {...CALCULATOR_CONFIG}
              />
            </SliderWrapper>
          </Row>
        </div>
        <div>
          <FieldTitle scale={5}>Funding Fee</FieldTitle>
          <Row>
            <NumberFormatter {...feePercentController.field} thousandSeparator="," disabled={isLoading} />
            <SliderWrapper>
              <PercentSlider
                {...feePercentController.field}
                {...PERCENTAGE_CONFIG}
                marks={PERCENTAGE_SLIDER_MARKS}
                disabled={isLoading}
              />
            </SliderWrapper>
          </Row>
        </div>
        <div>
          <FieldTitle scale={5}>Monthly Revenue Share %</FieldTitle>
          <Row>
            <NumberFormatter {...retrievalPercentController.field} thousandSeparator="," disabled={isLoading} />
            <SliderWrapper>
              <PercentSlider
                {...retrievalPercentController.field}
                {...PERCENTAGE_CONFIG}
                marks={PERCENTAGE_SLIDER_MARKS}
                disabled={isLoading}
              />
            </SliderWrapper>
          </Row>
        </div>
        <ButtonWrapper>
          <Button disabled={isLoading}>Revise Reports</Button>
          {apiStatus === SUCCESS_VALUE && (
            <SuccessMessage>Successfully updated. Click refresh button at the bottom of Tableau</SuccessMessage>
          )}
          {apiStatus === FAILURE_VALUE && <ErrorMessage>Something went wrong</ErrorMessage>}
          {apiStatus === FAILURE_VALUE && <Countdown refreshTableau={refreshTableau} tableauViz={tableauViz} />}
        </ButtonWrapper>
      </form>
    </Card>
  ) : (
    <ButtonWrapper>
      <Button tertiary onClick={() => toggleIsOpen(true)}>
        Risk Adjustment
      </Button>
    </ButtonWrapper>
  );
};

export default RiskAdjustment;
