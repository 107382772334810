import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import LoadingCircle from '../../../components/LoadingCircle';
import { Wrapper, Card } from '../components';
import ReportInitiation from './ReportInitiation';
import RiskAdjustment from './RiskAdjustment';
import { TABLEAU_SCRIPT_URL } from '@constants/platform';
import { useScript } from '@/hooks';
import { fetchCreditModelReport } from '@redux/modules/creditModel/actions';
import { useSelectedCompanyId } from '@redux/selectors';

const Header = styled.div`
  ${(props) => props.theme.text.two};
  margin-bottom: 20px;
`;

const SectionTitle = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
`;

const TableauContainer = styled.div`
  margin: auto;
  width: 100%;
  height: 1800px;
  ${(props) =>
    props.isLoading &&
    css`
      display: none;
    `};
`;

const containerId = 'tableau-viz-container';

const options = {
  hideTabs: true,
  onFirstInteractive: () => {
    console.log('Run this code when the viz has finished loading.');
  },
};

// CreditRiskContainer is the main component that renders the Credit Risk Report page.
const CreditRiskContainer = () => {
  const report = useSelector((state) => state.creditModel.report);
  const formProps = useForm({
    defaultValues: {
      sourceType: 'INTEGRATION',
      integrationType: 'all',
    },
  });
  const { watch } = formProps;
  const watchOfSourceType = watch('sourceType');

  const selectedCompanyId = useSelectedCompanyId();
  const [isLoading, toggleIsLoading] = useState(false);

  // The tableau viz that has to be shared between subcomponents
  const [viz, setViz] = useState(undefined);

  /*
    refreshTableau receives a tableau viz and fetches
    live data for the embedded report.
   */
  const refreshTableau = (tableauViz) => {
    if (tableauViz !== undefined) {
      tableauViz.refreshDataAsync();
    }
  };

  useScript(TABLEAU_SCRIPT_URL);

  useEffect(() => {
    const containerDiv = document.getElementById(containerId);

    // Create a viz object and embed it in the container div.
    options['Company Id'] = selectedCompanyId;
    const vizz = new window.tableau.Viz(containerDiv, TABLEAU_SCRIPT_URL, options);
    setViz(vizz);

    // Fetch company credit risk result from cal-engine
    fetchCreditModelReport();
  }, []);

  return (
    <>
      <Header>Credit Risk</Header>
      <Wrapper>
        <SectionTitle>Funding Request Information</SectionTitle>
        <ReportInitiation
          formProps={formProps}
          isLoading={isLoading}
          toggleIsLoading={toggleIsLoading}
          refreshTableau={refreshTableau}
          tableauViz={viz}
        />
        <SectionTitle>Credit Analysis Report</SectionTitle>
        {report && watchOfSourceType === 'INTEGRATION' && (
          <RiskAdjustment
            isLoading={isLoading}
            toggleIsLoading={toggleIsLoading}
            creditModelReport={report}
            refreshTableau={refreshTableau}
            tableauViz={viz}
          />
        )}
        <Card>
          {isLoading && <LoadingCircle />}
          <TableauContainer id={containerId} isLoading={isLoading} />
        </Card>
      </Wrapper>
    </>
  );
};

export default CreditRiskContainer;
