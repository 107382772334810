export const formatInputCurrencyOptions = (currency) => {
  const standardCurrencyOptions = currency.slice(0, 3).reverse();
  const otherCurrencyOptions = currency.slice(3);
  const formattedCurrencyOptions = [
    ...standardCurrencyOptions,
    ...otherCurrencyOptions,
  ];

  return formattedCurrencyOptions;
};
