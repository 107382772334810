import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import APPLICATION_PATH from '../../Application/path';
import { getApplicationPathByApplicationStatusCode } from '../path';
import { STATUS_CODE } from '@constants/application';

const TopInfoBar = styled.div`
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 12px;
  font-weight: 600;
  ${(props) => props.theme.text.micro};
  color: ${(props) => props.theme.colors.grayThree};
  background-color: ${(props) => props.theme.colors.lightOrange};
`;

const TextLink = styled(Link)`
  ${(props) => props.theme.text.link};
`;
const TopBar = ({ statusCode }) => {
  const landingUrl = getApplicationPathByApplicationStatusCode(statusCode);
  return statusCode >= STATUS_CODE.RECEIVED_PRE_OFFER && statusCode <= STATUS_CODE.PENDING_USER_SIGN_AGREEMENT ? (
    <TopInfoBar>
      {/* Your application is still being processed. <TextLink to={landingUrl}>Click here to continue</TextLink> */}
    </TopInfoBar>
  ) : null;
};

export default TopBar;
