import deepEqual from 'deep-equal';

import { Currency } from '@/constants';
import COLORS from '../styles/colors';
import { isRealObject } from './dataTypes';
import { toUpperCase } from './stringHelpers';

export const countRemittanceChannels = (stream = {}) => {
  const { sales_channel, payment_channel, bank_channel, analytic_channel } = stream;
  let count = 0;
  [sales_channel, payment_channel, bank_channel, analytic_channel].forEach((channel) => {
    if (isRealObject(channel) && !deepEqual(channel, {})) {
      const keys = Object.keys(channel);
      for (const key of keys) {
        if (channel[key]) {
          count += channel[key].length;
        }
      }
    }
  });

  return count;
};

export const getColorByTransactionStatus = (status = '') => {
  switch (toUpperCase(status)) {
    case 'FAILED':
    case 'OVERDUE':
      return COLORS.red;
    case 'SUCCESS':
    case 'SUCCEEDED':
      return COLORS.green;
    case 'CANCELLED':
      return COLORS.grayThree;
    default:
      return COLORS.black;
  }
};

export const getColorByPaymentStatus = (status) => {
  switch (status) {
    case 'OVERDUE':
    case 'UNCOLLECTIBLE':
      return COLORS.red;
    case 'PAID':
      return COLORS.green;
    case 'VOID':
      return COLORS.grayThree;
    default:
      return COLORS.black;
  }
};

export const getFundBalanceAmountsByCurrency = (fundBalance) => {
  if (fundBalance?.currency) {
    const suffix = `_in_${fundBalance.currency.toLowerCase()}`;
    return {
      fundDisbursementAmount: fundBalance[`fund_disbursement_amount${suffix}`],
      fundingFeeAmount: fundBalance[`funding_fee_amount${suffix}`],
      paidAmount: fundBalance[`paid_amount${suffix}`],
      pendingAmount: fundBalance[`pending_amount${suffix}`],
      dueAmount: fundBalance[`due_amount${suffix}`],
      outstandingAmount: fundBalance[`outstanding_amount${suffix}`],
      paidFee: fundBalance[`paid_fee${suffix}`],
      pendingFee: fundBalance[`pending_fee${suffix}`],
      dueFee: fundBalance[`due_fee${suffix}`],
      outstandingFee: fundBalance[`outstanding_fee${suffix}`],
    };
  } else {
    return {};
  }
};
