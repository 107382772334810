import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import Tabs from '../../../../../components/Tabs';
import PATH from '../../../path';
import { GLOBAL_ACCOUNTS_TAB_OPTIONS } from '../../constants';
import AccountActivity from './AccountActivity';
import AccountSummary from './AccountSummary';
import AccountOverview from './Overview';

const GlobalAccountsOverview = () => {
  return (
    <>
      <Tabs options={GLOBAL_ACCOUNTS_TAB_OPTIONS} />
      <Switch>
        <Route path={PATH.WALLET_GLOBAL_ACCOUNTS_OVERVIEW} component={AccountOverview} />
        <Route path={PATH.WALLET_GLOBAL_ACCOUNT_ACTIVITY} component={AccountActivity} />
        <Route path={PATH.WALLET_GLOBAL_ACCOUNT_ID} component={AccountSummary} />
        <Redirect to={PATH.WALLET_GLOBAL_ACCOUNTS_OVERVIEW} />
      </Switch>
    </>
  );
};

export default GlobalAccountsOverview;
