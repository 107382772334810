import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import Button from '../../../../components/Button';
import HR from '../../../../components/HR';
import featureToggle from '../../../../featureToggle';
import { Description } from '../../../Application/components';
import { BANK_ACCOUNT_MODAL, NEW_BANK_ACCOUNT_OVERVIEW_MODAL } from '../../../Modal/constants';
import BankAccountDetailsTable from '../../../Platform/BankAccountDetailsTable';
import FinverseBankAccountTable from '../../../Platform/FinverseBankAccountTable';
import { Card } from "../../components";
import { BANK_SOURCE } from '@constants/bank';
import { PLATFORM } from '@constants/platform';
import { useIntegrationChannel } from '@hooks/channel';
import { useModule } from '@hooks/routing';
import { fetchBankAccounts } from '@redux/modules/bank/actions';
import { fetchIntegratedFinverseInstitutions } from '@redux/modules/integration/finverse/actions';
import { showModal, hideModal } from '@redux/modules/UI/actions';
import { useIsUnableToEditUnderAdminView, useCheckIsSignUpViaShopify } from '@redux/selectors';

const BankAccountsTitle = styled.div`
  ${(props) => props.theme.text.two};

  ${(props) =>
    props.module === 'dashboard' &&
    css`
      ${props.theme.text.three}
    `}
`;

const BankAccountsHeader = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: bold;
  margin-bottom: 16px;
`;

const BankAccountsTypeHeader = styled.div`
  ${(props) => props.theme.text.four};
  font-weight: bold;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  margin-top: 15px;
`;

const channel = null;

const FinanceAccountsForm = () => {
  const module = useModule();
  const dispatch = useDispatch();
  const unableToEditAnotherCompany = useIsUnableToEditUnderAdminView();
  const isSignUpViaShopify = useCheckIsSignUpViaShopify();
  const bankAccounts = useSelector((state) => state.bank.activeBankAccounts);
  const manualBankAccounts = Array.isArray(bankAccounts)
    ? bankAccounts.filter((account) => [BANK_SOURCE.MANUAL].indexOf(account.add_method) !== -1)
    : [];
  const xeroBankAccounts = Array.isArray(bankAccounts) ? bankAccounts.filter((account) => PLATFORM.XERO === account.add_method) : [];
  const finverseBankAccounts =
    Array.isArray(bankAccounts) && featureToggle.FINVERSE
      ? bankAccounts.filter((account) => account.add_method === BANK_SOURCE.FINVERSE)
      : [];

  const getIndexOfAccountsById = (id) => {
    const accountIds = bankAccounts.map((account) => account.id);
    return accountIds.indexOf(id);
  };

  const showNewBankAccountModal = () => {
    dispatch(
      showModal(BANK_ACCOUNT_MODAL, {
        onAfterSubmit: fetchBankAccounts,
        disabled: unableToEditAnotherCompany,
      })
    );
  };

  const handleAddAccountClick = () => {
    dispatch(showModal(NEW_BANK_ACCOUNT_OVERVIEW_MODAL));
  };

  const showEditingBankAccountModal = (id) => {
    const currentBankIndex = getIndexOfAccountsById(id);
    dispatch(
      showModal(BANK_ACCOUNT_MODAL, {
        isEditingBank: true,
        editingBank: bankAccounts[currentBankIndex],
        onAfterSubmit: fetchBankAccounts,
        disabled: unableToEditAnotherCompany,
      })
    );
  };

  const hasXeroBanks = Array.isArray(xeroBankAccounts) && xeroBankAccounts.length > 0;
  const hasFinverseBanks = Array.isArray(finverseBankAccounts) && finverseBankAccounts.length > 0;
  const hasManuallyAddedBanks = Array.isArray(manualBankAccounts) && manualBankAccounts.length > 0;

  const handleFinverseIntegrationSuccess = () => {
    dispatch(hideModal());
    fetchBankAccounts();
    fetchIntegratedFinverseInstitutions();
  };

  useIntegrationChannel(channel, handleFinverseIntegrationSuccess);

  return (
    <>
      <BankAccountsTitle module={module}>Provide us with your financial information</BankAccountsTitle>
      {isSignUpViaShopify ? (
        <Description>Adding more bank accounts would make our analytics about your business more accurate.</Description>
      ) : (
        <Description>
          Your financial information will determine your funding eligibility. Adding your <b>bank accounts & statements</b> will ensure that
          you get the most capital possible.
        </Description>
      )}
      <BankAccountsHeader>Bank Accounts</BankAccountsHeader>
      <Card>
        {hasXeroBanks && (
          <>
            <BankAccountsTypeHeader>Xero Bank Accounts</BankAccountsTypeHeader>
            <BankAccountDetailsTable
              accounts={xeroBankAccounts}
              onEditClick={showEditingBankAccountModal}
              fetchBankAccounts={fetchBankAccounts}
              disabled={unableToEditAnotherCompany}
            />
          </>
        )}
        {hasXeroBanks && hasFinverseBanks && <HR />}
        {hasFinverseBanks && (
          <>
            <BankAccountsTypeHeader>Connected Bank Accounts</BankAccountsTypeHeader>
            <FinverseBankAccountTable
              accounts={finverseBankAccounts}
              onEditClick={showEditingBankAccountModal}
              fetchBankAccounts={fetchBankAccounts}
              disabled={unableToEditAnotherCompany}
            />
          </>
        )}
        {hasFinverseBanks && hasManuallyAddedBanks && <HR />}
        {hasManuallyAddedBanks && (
          <>
            <BankAccountsTypeHeader>Manually Added Bank Accounts</BankAccountsTypeHeader>
            <BankAccountDetailsTable
              accounts={manualBankAccounts}
              onEditClick={showEditingBankAccountModal}
              fetchBankAccounts={fetchBankAccounts}
              disabled={unableToEditAnotherCompany}
            />
          </>
        )}
        <ButtonWrapper>
          <Button
            onClick={featureToggle.FINVERSE ? handleAddAccountClick : showNewBankAccountModal}
            disabled={unableToEditAnotherCompany}
            tertiary
          >
            Add a Bank Account
          </Button>
        </ButtonWrapper>
      </Card>
    </>
  );
};

export default FinanceAccountsForm;
