import React, { useEffect, useState, useRef } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import BackButton from '../../../../../components/BackButton';
import Button from '../../../../../components/Button';
import HR from '../../../../../components/HR';
import LoadingSkeleton from '../../../../../components/LoadingSkeleton';
import Logger from '../../../../../utils/logger';
import { Card } from '../../../components';
import PATH from '../../../path';
import { COMPANY_CARD_CONFIG, CARD_STATUS_LABEL } from '../../constants';
import CardSettingsMenu from '../components/CardSettingsMenu';
import { getCardStatusColor, getLimitLabelByInterval, getCardLimitRefreshDescription, getColorByCardStatus } from '../helpers';
import Transactions from '../Transactions';
import { getCardSensitiveDetailLink, getCardRemainingLimits } from '@api/modules/integration/airwallex';
import { useOnClickOutside } from '@hooks/UI';
import { SENSITIVE_CARD_DETAIL_MODAL } from '@modules/Modal/constants';
import { showModal } from '@redux/modules/UI/actions';
import { fetchWalletCardDetail } from '@redux/modules/wallet/actions';
import { useWalletAccountId, useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';
import { convertToFullDate } from '@utils/dateHelpers';
import { formatPrice, formatNumber } from '@utils/priceHelpers';
import { capitalize, replaceDelimiter } from '@utils/stringHelpers';
import { findFirstMatchInArray } from '@utils/dataTypes';

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;

  > span {
    font-weight: 700;
  }
`;

const CardBasicInfoWrapper = styled.div`
  > * {
    vertical-align: middle;
  }

  text-align: center;
  margin: 32px 0;
`;

const CardBasicInfo = styled.span`
  display: inline-block;

  line-height: 16.8px;
  font-size: 14px;
  margin: 0 16px;
`;

const CardOtherInfoTable = styled.table`
  td:first-child {
    width: 160px;
  }
`;

const ButtonWrapper = styled.div`
  ${(props) => props.theme.buttonWrapper};
`;

const SectionContentWrapper = styled.div`
  padding: 16px 16px 20px;

  ${(props) =>
    props.showButtonBorder &&
    css`
      border-bottom: 1px solid #ececec;
    `}
`;

const LimitTable = styled.table`
  td:first-child {
    width: 160px;
  }
`;

const RefreshDescription = styled.span`
  margin-left: 24px;
  color: #808080;
  font-weight: 700;
`;

const Status = styled.span`
  ~ div {
    margin-left: 12px;
  }
`;

const columns = [
  {
    Header: 'Date',
    accessor: 'transaction_date',
    Cell: ({ value }) => {
      return convertToFullDate(value);
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => {
      return capitalize(replaceDelimiter(value, '_', ' ') || '-');
    },
    getStyle: ({ value }) => {
      return css`
        color: ${getColorByCardStatus(value)};
      `;
    },
  },
  {
    Header: 'Description',
    accessor: 'merchant',
    Cell: ({ value }) => {
      return <>{[value.name, value.city, value.country].filter((name) => name).join(', ')}</>;
    },
  },
  {
    Header: 'Currency',
    accessor: 'transaction_currency',
    getStyle: () => {
      return css`
        width: 60px;
      `;
    },
  },
  {
    Header: 'Amount',
    accessor: 'transaction_amount',
    Cell: ({ value }) => {
      return formatNumber(value, 2);
    },
    getStyle: () => {
      return css`
        text-align: right;
        width: 150px;
      `;
    },
  },
];

const CardDetail = () => {
  const { id } = useParams();
  const menuRef = useRef();
  const dispatch = useDispatch();

  const accountId = useWalletAccountId();
  const cardDetail = useSelector((state) => state.wallet.card.detailMap[id]);
  const cardTransactionsList = useSelector((state) => state.wallet.cardTransactionPageMap);

  const [remainingLimits, setRemainingLimits] = useState([]);
  const [url, setUrl] = useState();
  const [urlExpiryTime, setUrlExpiryTime] = useState();
  const [isLoading, toggleIsLoading] = useState(false);
  const [menuIsExpanded, toggleMenuIsExpanded] = useState(false);
  const isAdminViewingAnotherCompany = useCheckIsAdminViewingAnotherCompany();
  const [isUpdatingCard, toggleIsUpdatingCard] = useState(false);

  useOnClickOutside(menuRef, () => toggleMenuIsExpanded(false));

  const fetchCardRemainingLimits = async () => {
    const { data, statusCode } = await getCardRemainingLimits(accountId, id);
    if (statusCode === 200) {
      setRemainingLimits(data.limits);
    }
  };

  useEffect(() => {
    fetchWalletCardDetail(accountId, id);
    fetchCardRemainingLimits();
  }, [id]);

  const handleViewDetailClick = async () => {
    toggleIsLoading(true);
    try {
      let link;
      if (moment().isAfter(urlExpiryTime)) {
        const { data } = await getCardSensitiveDetailLink(accountId, id);
        setUrlExpiryTime(moment().add(1, 'minute'));
        setUrl(data);
        link = data;
      } else if (url) {
        link = url;
      } else {
        const { data } = await getCardSensitiveDetailLink(accountId, id);
        setUrlExpiryTime(moment().add(1, 'minute'));
        setUrl(data);
        link = data;
      }
      dispatch(showModal(SENSITIVE_CARD_DETAIL_MODAL, { cardId: id, url: link }));
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const getRemainingLimit = (currency, remainingAmount) => {
    if (currency && remainingAmount) {
      return `${formatPrice(currency, remainingAmount)} remaining`;
    } else {
      return 'N/A';
    }
  };

  const transactionLimits = _.get(cardDetail, 'authorization_controls.transaction_limits');
  const primaryContactDetails = _.get(cardDetail, 'primary_contact_details');

  return cardDetail ? (
    <>
      <BackButton path={PATH.WALLET_CARDS} text="All cards" />
      <Card>
        <TitleRow>
          <span>{cardDetail.name_on_card}</span>
          <span style={{ color: getCardStatusColor(cardDetail.card_status) }}>
            {isUpdatingCard ? (
              <LoadingSkeleton width="70px" />
            ) : (
              <>
                <Status>{CARD_STATUS_LABEL[cardDetail.card_status] || cardDetail.card_status}</Status>
                <CardSettingsMenu cardDetail={cardDetail} source="cardDetailPage" toggleIsLoading={toggleIsUpdatingCard} />
              </>
            )}
          </span>
        </TitleRow>
        <HR margin={10} />
        <CardBasicInfoWrapper>
          <img src={COMPANY_CARD_CONFIG.imgUrl} alt="company-card" />
          <CardBasicInfo>
            {/* <div>Card holder</div>
            <div>{cardDetail?.card_number}</div>
            <HR color="#ECECEC" margin={6} /> */}
            <div>Card Number</div>
            <div>{cardDetail?.card_number}</div>
            <HR color="#ECECEC" margin={6} />
            <div>Expiry Date</div>
            <div>**/****</div>
            <HR color="#ECECEC" margin={6} />
            <div>Security Code</div>
            <div>***</div>
          </CardBasicInfo>
        </CardBasicInfoWrapper>
        <HR margin={0} />
        <SectionContentWrapper>
          <CardOtherInfoTable>
            <tbody>
              {primaryContactDetails?.full_name && (
                <tr>
                  <td>Contact person:</td>
                  <td>{primaryContactDetails?.full_name}</td>
                </tr>
              )}
              {primaryContactDetails?.date_of_birth && (
                <tr>
                  <td>Date of birth:</td>
                  <td>{primaryContactDetails?.date_of_birth}</td>
                </tr>
              )}
              {primaryContactDetails?.mobile_number && (
                <tr>
                  <td>Contact number:</td>
                  <td>{primaryContactDetails?.mobile_number}</td>
                </tr>
              )}
            </tbody>
          </CardOtherInfoTable>
        </SectionContentWrapper>
        <ButtonWrapper>
          <Button secondary onClick={handleViewDetailClick} disabled={isLoading || isAdminViewingAnotherCompany}>
            View Card Details
          </Button>
        </ButtonWrapper>
      </Card>
      <Card>
        <TitleRow>
          <span>Limit Tracker</span>
        </TitleRow>
        <HR margin={10} />
        {Array.isArray(remainingLimits) && remainingLimits.length > 0 ? (
          <>
            {Array.isArray(transactionLimits?.limits) &&
              transactionLimits?.limits.map((limit, index) => {
                const { amount, interval } = limit;
                const remainingLimit = findFirstMatchInArray(remainingLimits, (item) => item.interval === interval);

                if (!remainingLimit) {
                  return null;
                }

                return (
                  <SectionContentWrapper key={limit.interval} showButtonBorder={index < transactionLimits.limits.length - 1}>
                    <LimitTable>
                      <tbody>
                        <tr>
                          <td>
                            <b>{getLimitLabelByInterval(interval)}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>{formatPrice(transactionLimits.currency, amount)}</td>
                          <td>
                            {getRemainingLimit(transactionLimits.currency, remainingLimit.remaining)}
                            <RefreshDescription>{getCardLimitRefreshDescription(interval)}</RefreshDescription>
                          </td>
                        </tr>
                      </tbody>
                    </LimitTable>
                  </SectionContentWrapper>
                );
              })}
          </>
        ) : (
          <>
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
          </>
        )}
      </Card>
      <Transactions />
    </>
  ) : (
    <>
      <Card>
        <LoadingSkeleton />
        <HR margin={10} />
        <CardBasicInfoWrapper>
          <img src={COMPANY_CARD_CONFIG.imgUrl} alt="company-card" />
          <CardBasicInfo>
            {/* <div>Card holder</div>
            <div>{cardDetail?.card_number}</div>
            <HR color="#ECECEC" margin={6} /> */}
            <div>Card Number</div>
            <LoadingSkeleton />
            <HR color="#ECECEC" margin={6} />
            <div>Expiry Date</div>
            <LoadingSkeleton />
            <HR color="#ECECEC" margin={6} />
            <div>Security Code</div>
            <LoadingSkeleton />
          </CardBasicInfo>
        </CardBasicInfoWrapper>
        <HR margin={10} />
        <LoadingSkeleton />
      </Card>
      <Card>
        <LoadingSkeleton />
        <HR margin={10} />
        <LoadingSkeleton />
        <LoadingSkeleton />
        <LoadingSkeleton />
      </Card>
      <Transactions />
    </>
  );
};

export default CardDetail;
