import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PlatformLogo from '../../components/PlatformLogo';
import { MARKETING_PLATFORM_FB_INTEGRATION, MANUAL_INTEGRATION_MODAL, FB_TUTORIAL_MODAL } from '../Modal/constants';
import AccountsTable from './MarketingAccountsTable';
import { API_FB_INTEGRATION } from '@api/constants';
import { deleteIntegratedAccount } from '@api/modules/application';
import { MARKETING_PLATFORM_LOGOS, PLATFORM, MANUAL_INTEGRATION_PLATFORMS } from '@constants/platform';
import { fetchIntegrations } from '@redux/modules/application/actions';
import { showModal } from '@redux/modules/UI/actions';
import { useIsUnableToEditUnderAdminView } from '@redux/selectors';
import { findFirstMatchInArray } from '@utils/dataTypes';
import { marketingPlatformsFilter } from '@utils/platformHelpers';

const MarketingAccountIntegrations = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.user.profile.companyId);
  const integrations = useSelector((state) => state.application.integrations);
  const accounts = integrations.filter(({ platformName }) => marketingPlatformsFilter(platformName));
  const FBAccounts = accounts.filter(({ platformName }) => platformName === PLATFORM.FACEBOOK_ADS);
  const isAdminView = useIsUnableToEditUnderAdminView();

  const [FBAdAccounts, setFBAdAccounts] = useState([]);
  const [FBAdAccountsLookup, setFBAdAccountsLookup] = useState({});

  const getAcctSummary = (userCompanyId, fbAccountId) => {
    return fetch(`${API_FB_INTEGRATION}/v1/acct-summary/${userCompanyId}/${fbAccountId}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data) {
          return;
        }
        const accountData = data.data;
        if (!Array.isArray(accountData)) {
          return;
        }
        if (Array.isArray(accountData) && accountData.length > 0) {
          setFBAdAccounts(accountData);
          const lookup = {};
          accountData.forEach((acct) => {
            lookup[acct.id] = acct.name;
          });
          setFBAdAccountsLookup(lookup);
        }
      });
  };

  const showFBIntegrationModal = (integrationId, accountId, accountName) => {
    dispatch(
      showModal(MARKETING_PLATFORM_FB_INTEGRATION, {
        component: PLATFORM.FACEBOOK_ADS,
        integrationId,
        accountId,
        accountName,
        FBAccounts: FBAdAccounts,
        companyId,
      })
    );
  };

  const showManualIntegrationModal = (platformName) => {
    dispatch(
      showModal(MANUAL_INTEGRATION_MODAL, {
        companyName: platformName,
      })
    );
  };

  const integrateFBModal = () => {
    if (Array.isArray(FBAccounts) && FBAccounts.length > 0) {
      showFBIntegrationModal(FBAccounts[0].id, FBAccounts[0].accountId, FBAccounts[0].accountName);
    } else {
      dispatch(showModal(FB_TUTORIAL_MODAL));
    }
  };

  useEffect(() => {
    if (Array.isArray(FBAccounts) && FBAccounts.length > 0 && !isAdminView) {
      getAcctSummary(companyId, FBAccounts[0].accountId);
      const incompleteAccount = findFirstMatchInArray(FBAccounts, (account) => account.status === -2 || !account.accountUserId);
      if (incompleteAccount) {
        showFBIntegrationModal(incompleteAccount.id, incompleteAccount.accountId, incompleteAccount.accountName);
      }
    }
  }, [JSON.stringify(FBAccounts), JSON.stringify(integrations)]);

  const onLogoClick = (value, isDisabled) => () => {
    if (!isDisabled) {
      if (value === PLATFORM.FACEBOOK_ADS) {
        integrateFBModal();
      } else if (MANUAL_INTEGRATION_PLATFORMS.indexOf(value) !== -1) {
        showManualIntegrationModal(value);
      }
    }
  };

  const onDeletePlatformClick = (account) => async () => {
    await deleteIntegratedAccount({
      platformName: account.platformName,
      integrationId: account.id,
      ...(account.platformName === PLATFORM.GOOGLE_ANALYTICS && {
        accountId: account.accountId,
      }),
    });
    await fetchIntegrations();
  };

  return (
    <>
      {Array.isArray(accounts) && accounts.length > 0 && (
        <AccountsTable
          accounts={accounts}
          onDeletePlatformClick={onDeletePlatformClick}
          disabled={isAdminView}
          showFBIntegrationModal={showFBIntegrationModal}
          fbAdAcctLookup={FBAdAccountsLookup}
        />
      )}
      {MARKETING_PLATFORM_LOGOS.map(({ value, displayName, logoUrl, enabled }) => {
        const isDisabled = isAdminView || !enabled;
        return (
          <PlatformLogo
            value={value}
            displayName={displayName}
            logoUrl={logoUrl}
            disabled={isDisabled}
            onClick={onLogoClick(value, isDisabled)}
          />
        );
      })}
    </>
  );
};

export default MarketingAccountIntegrations;
