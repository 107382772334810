import React, { useEffect, useState } from 'react';

import deepEqual from 'deep-equal';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';

import { BASE_APPLICATION_PATH, BASE_DASHBOARD_PATH, BASE_INTEGRATION_PATH, BASE_REGISTRATION_PATH } from './basePath';
import { AppContext } from './context';
import Application from './modules/Application';
import ConfirmEmail from './modules/Authentication/ConfirmEmail';
import ForgotPassword from './modules/Authentication/ForgotPassword';
import LoginPage from './modules/Authentication/Login';
import AUTH_PATH from './modules/Authentication/path';
import Registration from './modules/Authentication/Registration';
import ResetPassword from './modules/Authentication/ResetPassword';
import Dashboard from './modules/Dashboard';
import DASHBOARD_PATH from './modules/Dashboard/path';
import Integration from './modules/Integration';
import ModalContainer from './modules/Modal/ModalContainer';
import SuccessPage from './modules/Authentication/Registration/SuccessPage';
import { initialState as companyReducerInitialState } from './redux/modules/company/reducer';
import { initialState as userInitialState } from './redux/modules/user/reducer';
import theme from './styles/newTheme';
import Logger from './utils/logger';
import { TARGET_COMPANY_ID_NAME, USER_COMPANY_ID_NAME, USER_ID_NAME } from '@api/constants';
import { getCookieProvider } from '@api/cookies';
import { linkShopifyUser } from '@api/modules/integration';
import { STATUS_CODE } from '@constants/application';
import { useQuery } from '@hooks/routing';
import { CONTENT_CONTAINER_ID } from '@modules/Dashboard/constants';
import { fetchApplication, fetchIntegrations } from '@redux/modules/application/actions';
import { fetchCompany, fetchCompany2fa, fetchUserCompany, selectCompany } from '@redux/modules/company/actions';
import { fetchRemittanceStreams } from '@redux/modules/fund/actions';
import { fetchIntegratedFinverseInstitutions } from '@redux/modules/integration/finverse/actions';
import { fetchInputCurrency } from '@redux/modules/metadata/actions';
import { fetchUserOffers } from '@redux/modules/offer/actions';
import { switchToAdminView } from '@redux/modules/UI/actions';
import { fetchUser } from '@redux/modules/user/actions';
import 'react-toastify/dist/ReactToastify.min.css';
import Home from './modules/ShopifyInsight/Home/index';
import TermsConditions from './modules/ShopifyInsight/TermConditions';
import PrivacyPolicy from './modules/ShopifyInsight/Privacy';

const cookieProvider = getCookieProvider();

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { parsed } = useQuery();

  const user = useSelector((state) => state.user);
  const view = useSelector((state) => state.ui.view);
  const { data: companies, selectedCompanyId } = useSelector((state) => state.company);
  const applicationData = useSelector((state) => state.application.data);
  const [loggedIn, toggleLoggedIn] = useState(!!user.profile.id);
  const [homeUrl, setHomeUrl] = useState(AUTH_PATH.LOGIN);

  const handleShopifyUserLinkage = async () => {
    try {
      const decodedString = decodeURIComponent(escape(window.atob(parsed.info)));
      const { email, shopify_store_name } = JSON.parse(decodedString);
      const cookieUserCompanyId = cookieProvider.get(USER_COMPANY_ID_NAME);
      if (cookieUserCompanyId) {
        await linkShopifyUser({
          company_id: cookieUserCompanyId,
          shopify_email: email,
          shopify_store: shopify_store_name,
        });

        fetchIntegrations();
      }
    } catch (err) {
      Logger.error(err);
    }
  };

  useEffect(() => {
    fetchUser();

    const cookieUserId = cookieProvider.get(USER_ID_NAME);

    if (cookieUserId) {
      if (parsed?.utm_campaign === 'SHOPIFY' && parsed.info) {
        handleShopifyUserLinkage();
      }

      // TODO: avoid run into unauthenticated pages
    } else {
      if (location.pathname.indexOf(BASE_APPLICATION_PATH) !== -1 || location.pathname.indexOf(BASE_DASHBOARD_PATH) !== -1) {
        history.replace('/login');
      }
    }
  }, []);

  useEffect(() => {
    document.getElementById(CONTENT_CONTAINER_ID)?.scroll(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (user.profile.id) {
      fetchInputCurrency();
    }
  }, [user.profile.id]);

  useEffect(() => {
    if (selectedCompanyId) {
      fetchCompany2fa();
      fetchApplication();
      fetchIntegratedFinverseInstitutions();
    }
  }, [selectedCompanyId]);

  useEffect(() => {
    if (user.profile.companyId && !deepEqual(companies, companyReducerInitialState.data)) {
      const targetCompanyId = cookieProvider.get(TARGET_COMPANY_ID_NAME);
      if (targetCompanyId && targetCompanyId !== user.profile.companyId) {
        dispatch(switchToAdminView());
        if (companies[targetCompanyId]) {
          selectCompany(targetCompanyId);
        }
      }
    }
  }, [user.profile.companyId, JSON.stringify(Object.keys(companies))]);

  useEffect(() => {
    async function fetchApi() {
      if (user.profile.id && !deepEqual(user.profile, userInitialState.profile)) {
        if (view === 'admin') {
          const companyId = cookieProvider.get(TARGET_COMPANY_ID_NAME);
          await fetchCompany(companyId);
        } else if (view === 'user') {
          await fetchUserCompany();
        }
      }
    }

    fetchApi();
  }, [user.profile.id, view]);

  useEffect(() => {
    if (applicationData?.applicationId) {
      fetchUserOffers(applicationData?.applicationId);

      if (applicationData?.statusCode >= STATUS_CODE.PENDING_USER_SIGN_AGREEMENT) {
        fetchRemittanceStreams();
      }
    }
  }, [applicationData?.applicationId]);

  useEffect(() => {
    const hasUserId = !!user.profile.id;
    const nextHomeUrl = hasUserId ? (user.profile.applicationSubmitted ? DASHBOARD_PATH.HOME : BASE_APPLICATION_PATH) : AUTH_PATH.LOGIN;
    toggleLoggedIn(hasUserId);
    setHomeUrl(nextHomeUrl);
  }, [user.profile.id]);

  return (
    <div className="App">
      <AppContext.Provider
        value={{
          // TODO: use redux actions
          fetchUser,
          fetchUserCompany,
          homeUrl,
        }}
      >
        <ThemeProvider theme={theme}>
          <Switch>
            <Route exact path="/home" component={Home} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/terms-conditions" component={TermsConditions} />
            <Route path={BASE_INTEGRATION_PATH} component={Integration} />
            <Route path={BASE_APPLICATION_PATH} component={Application} />
            <Route path={BASE_DASHBOARD_PATH} component={Dashboard} />
            <Route path={AUTH_PATH.CONFIRM_EMAIL} component={ConfirmEmail} />
            <Route path={AUTH_PATH.REGISTRATION_SUCCESS} component={SuccessPage} />
            {loggedIn && <Route exact path="/" component={user.profile.applicationSubmitted ? Dashboard : Application} />}
            {!loggedIn && (
              <>
                <Route exact path="/" component={Home} />
                <Route path={AUTH_PATH.RESET_PASSWORD} component={ResetPassword} />
                <Route exact path={AUTH_PATH.FORGOT_PASSWORD} component={ForgotPassword} />
                <Route exact path={AUTH_PATH.LOGIN} component={LoginPage} />
                <Route path={`${BASE_REGISTRATION_PATH}`} component={Registration} />
              </>
            )}
            <Redirect to={homeUrl} />
          </Switch>
          <ModalContainer />
        </ThemeProvider>
      </AppContext.Provider>
      <ToastContainer />
    </div>
  );
};

export default App;
