import React, { useRef } from 'react';

import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import HR from '../../components/HR';
import Icon from '../../components/Icon';
import ToolTip from '../../components/ToolTip';
import Logger from '../../utils/logger';
import { MESSAGE_MODAL, CONFIRM_DIALOG, UPLOAD_FILES_MODAL } from '../Modal/constants';
import { uploadCompanyFiles, deleteFileById } from '@api/modules/dashboard/file';
import { FormRow, GridColumn } from '@components/Grid';
import { COMPANY_FILE_TYPE } from '@constants/file';
import { useSelectRow } from '@hooks/UI';
import { UPLOAD_FILE_REQUEST, UPLOAD_FILE_FAILURE, UPLOAD_FILE_SUCCESS } from '@redux/modules/company/constants';
import { showModal, hideModal } from '@redux/modules/UI/actions';
import { useCheckIsSignUpViaShopify } from '@redux/selectors';
import { convertDate, YYYY_MM_DD_FORMAT } from '@utils/dateHelpers';
import { getFileTypeDisplayName, formatFileSize } from '@utils/fileHelpers';

const Title = styled(GridColumn)`
  font-weight: bold;
`;

const DataColumn = styled(GridColumn)`
  ${(props) => props.theme.ellipsis};
`;

const StyledIcon = styled(Icon)`
  margin-right: 12px;
  vertical-align: middle;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `};
`;

const NoFileLabel = styled.div`
  color: ${(props) => props.theme.colors.grayTwo};
  font-style: italic;
`;

const HeaderRow = styled.div`
  min-height: 50px;
`;

const Header = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: bold;
  line-height: 50px;
  display: inline;
  vertical-align: middle;
  text-align: left;
`;

const ButtonWrapper = styled.span`
  float: right;
`;

const StyledButton = styled(Button)`
  vertical-align: middle;
  margin-left: 8px;
`;

const FileTable = ({ title = '', files, disabled, addFileType = COMPANY_FILE_TYPE.OTHER, fetchFiles }) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const isSignUpViaShopify = useCheckIsSignUpViaShopify();

  const allFileIds = Array.isArray(files) ? files.map(({ attachment_id }) => attachment_id) : [];
  const { selectedIds, areAllChecked, toggleSelectAll, toggleSelectRow } = useSelectRow(allFileIds);

  const deleteFile = async (id) => {
    try {
      const { statusCode } = await deleteFileById(id);
      if (statusCode === 200) {
        await fetchFiles();
      }
    } catch (err) {
      // setApiError(err);
      Logger.error(err);
    }
  };

  const submitOtherFiles = async (pendingFiles) => {
    try {
      dispatch({ type: UPLOAD_FILE_REQUEST });
      if (Array.isArray(pendingFiles) && pendingFiles.length > 0) {
        await Promise.all(pendingFiles.map((file) => uploadCompanyFiles(addFileType, [file])));
        await fetchFiles();
        dispatch(hideModal());
      }

      dispatch({ type: UPLOAD_FILE_SUCCESS });
    } catch (err) {
      dispatch({ type: UPLOAD_FILE_FAILURE });
      Logger.error(err);
    }
  };

  const downloadSelected = () => {
    const selectedFiles = files.filter(({ attachment_id }) => selectedIds.indexOf(attachment_id) !== -1);
    selectedFiles.forEach(({ s3DownloadUrl, attachment_id }, index) => {
      const delay = index * 10;
      setTimeout(() => {
        window.open(s3DownloadUrl, attachment_id);
      }, delay);
    });
  };

  const showFileModal = () => {
    dispatch(
      showModal(UPLOAD_FILES_MODAL, {
        title: 'Upload Other Documents',
        description: isSignUpViaShopify
          ? 'Please upload any additional documents that can help analysis more accurate.'
          : 'Please upload any additional documents that can help our investment team better understand your business and company operations, eg. organization chart, shareholdings structure, memorandum of associations (company articles), business plans, projections, key agreements, investment deck etc.',
        onConfirmClick: (data) => submitOtherFiles(data),
        disabled,
      })
    );
  };

  const confirmRemoveFile =
    ({ attachment_id, file_type }) =>
    () => {
      if (!disabled) {
        if (file_type === COMPANY_FILE_TYPE.AGREEMENT) {
          dispatch(
            showModal(MESSAGE_MODAL, {
              title: isSignUpViaShopify ? 'This file is read-only' : 'Agreement is read-only',
              description: isSignUpViaShopify
                ? ''
                : 'Agreement is uploaded by your investment manager. Should there be any problem, please contact him/her directly.',
              rightButtonProps: {
                label: 'OK',
                secondary: true,
              },
              showLeftButton: false,
            })
          );
        } else {
          dispatch(
            showModal(MESSAGE_MODAL, {
              type: CONFIRM_DIALOG,
              title: 'Do you confirm to delete this file?',
              rightButtonProps: {
                label: 'Yes',
              },
              leftButtonProps: {
                label: 'No',
              },
              onConfirm: () => deleteFile(attachment_id),
            })
          );
        }
      }
    };

  const isFileChecked = (id) => {
    return Array.isArray(selectedIds) ? selectedIds.indexOf(id) !== -1 : false;
  };

  return (
    <>
      <HeaderRow>
        {title && <Header>{title}</Header>}
        <ButtonWrapper>
          <ToolTip
            innerRef={ref}
            width={300}
            placeholder={
              <StyledButton onClick={downloadSelected} disabled={!Array.isArray(selectedIds) || !selectedIds.length > 0} tertiary>
                Download Selected
              </StyledButton>
            }
            tip="Please check your browser permissions to allow pop up and redirect for our site"
          />
          <StyledButton onClick={showFileModal} disabled={disabled} width={160} tertiary>
            Add Document
          </StyledButton>
        </ButtonWrapper>
      </HeaderRow>
      <FormRow>
        <Title lg={1} md={1} sm={1}>
          <Checkbox onClick={toggleSelectAll} value={areAllChecked} />
        </Title>
        <Title lg={2} md={2} sm={3}>
          DATE
        </Title>
        <Title lg={3} md={3} sm={5}>
          NAME
        </Title>
        <Title lg={2} md={2} sm={0}>
          DOC TYPE
        </Title>
        <Title lg={2} md={2} sm={0}>
          SIZE
        </Title>
        <Title lg={2} md={2} sm={3}>
          ACTION
        </Title>
      </FormRow>
      <HR margin={20} />
      {Array.isArray(files) && files.length > 0 ? (
        files.map((file) => {
          const isChecked = isFileChecked(file.attachment_id);
          return (
            <FormRow>
              <DataColumn lg={1} md={1} sm={1}>
                <Checkbox value={isChecked} onClick={toggleSelectRow(file.attachment_id)} />
              </DataColumn>
              <DataColumn lg={2} md={2} sm={3}>
                {convertDate(file.updated_date, YYYY_MM_DD_FORMAT)}
              </DataColumn>
              <DataColumn lg={3} md={3} sm={5}>
                {file.file_name}
              </DataColumn>
              <DataColumn lg={2} md={2} sm={0}>
                {getFileTypeDisplayName(file.file_type)}
              </DataColumn>
              <DataColumn lg={2} md={2} sm={0}>
                {formatFileSize(file.file_size)}
              </DataColumn>
              <DataColumn lg={2} md={2} sm={3}>
                <a href={file.s3DownloadUrl}>
                  <StyledIcon src="/icons/download.svg" />
                </a>
                <a href={file.s3OpenUrl} target="_blank" rel="noreferrer">
                  <StyledIcon src="/icons/inspect_file.svg" />
                </a>
                <StyledIcon src="/icons/trash_bin.svg" onClick={confirmRemoveFile(file)} disabled={disabled} />
              </DataColumn>
            </FormRow>
          );
        })
      ) : (
        <NoFileLabel>You have no uploads yet.</NoFileLabel>
      )}
    </>
  );
};

export default FileTable;
