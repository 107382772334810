import { css } from 'styled-components';

import { PLATFORM, PLATFORM_DISPLAY_NAME } from '@constants/platform';
import { isRealNumber } from '@utils/dataTypes';
import { convertToFullDate } from '@utils/dateHelpers';
import { formatPrice, formatNumber } from '@utils/priceHelpers';

const LineAmountTypesMap = {
  NoTax: 'No Tax',
  Inclusive: 'Inclusive',
  Exclusive: 'Exclusive',
};

export const xeroColumnConfig = [
  {
    Header: 'Date',
    accessor: 'Date',
    Cell: (props) => convertToFullDate(props.value),
  },
  {
    Header: 'Bank account name',
    accessor: 'BankAccountName',
  },
  {
    Header: 'Reference',
    accessor: 'Reference',
  },
  {
    Header: (props) => {
      const transaction = props.data[0];
      if (transaction) {
        return `Spent (${transaction.CurrencyCode})`;
      } else {
        return 'Spent';
      }
    },
    accessor: 'spentAmount',
    Cell: (props) => {
      const { Type, Total } = props.row.original;
      return Type.indexOf('SPEND') !== -1 ? formatNumber(Total, 2) : '';
    },
    className: 'textRight',
  },
  {
    Header: (props) => {
      const transaction = props.data[0];
      if (transaction) {
        return `Received (${transaction.CurrencyCode})`;
      } else {
        return 'Received';
      }
    },
    accessor: 'receivedAmount',
    Cell: (props) => {
      const { Type, Total } = props.row.original;
      return Type.indexOf('RECEIVE') !== -1 ? formatNumber(Total, 2) : '';
    },
    className: 'textRight',
  },
  {
    Header: 'Contact name',
    accessor: 'ContactName',
  },
  {
    Header: 'Status',
    accessor: 'Status',
  },
  {
    Header: 'Reconciled',
    accessor: 'isReconciled',
    Cell: (props) => {
      const { LineAmountTypes } = props.row.original;
      return LineAmountTypesMap[LineAmountTypes] || LineAmountTypes;
    },
  },
];

export const finverseColumnConfig = [
  {
    Header: 'Post Date',
    accessor: 'posted_date',
    Cell: (props) => convertToFullDate(props.value),
  },
  {
    Header: 'Description',
    accessor: 'description',
    getStyle: () => css`
      width: 30%;
    `,
  },
  {
    Header: (props) => {
      const transaction = props.data[0];
      if (transaction) {
        return `Deposit (${transaction.currency})`;
      } else {
        return 'Deposit';
      }
    },
    accessor: 'deposit',
    Cell: (props) => {
      if (isRealNumber(props.value)) {
        return formatNumber(props.value, 2);
      } else {
        return '';
      }
    },
    getStyle: () => css`
      text-align: right;
    `,
  },
  {
    Header: (props) => {
      const transaction = props.data[0];
      if (transaction) {
        return `Withdraw (${transaction.currency})`;
      } else {
        return 'Withdraw';
      }
    },
    accessor: 'withdraw',
    Cell: (props) => {
      if (isRealNumber(props.value)) {
        return formatNumber(props.value, 2);
      } else {
        return '';
      }
    },
    getStyle: () => css`
      text-align: right;
    `,
  },
  {
    Header: (props) => {
      const transaction = props.data[0];
      if (transaction) {
        return `Balance (${transaction.currency})`;
      } else {
        return 'Balance';
      }
    },
    accessor: 'balanceAmount',
    getStyle: () => css`
      text-align: right;
    `,
  },
];

export const modalColumnConfig = xeroColumnConfig.slice(0, xeroColumnConfig.length - 2);

export const BANK_PLATFORM_OPTIONS = [
  {
    value: PLATFORM.XERO,
    label: PLATFORM_DISPLAY_NAME.XERO,
  },
  {
    value: PLATFORM.FINVERSE,
    label: 'Other Connected Accounts',
  },
];
