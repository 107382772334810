import React from 'react';

import ChangePasswordCard from './ChangePassword/ChangePasswordCard';

const ProfileInformation = () => {
  return (
    <ChangePasswordCard />
  );
};

export default ProfileInformation;
