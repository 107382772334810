import { dashboardUrl } from ".";
import { getCompanyId } from '@api/cookies';
import { getRequest, putRequest, postRequest, deleteRequest } from '@api/requests';

export const createBusinessOwner = async (payload) => {
  return await putRequest(`${dashboardUrl}/company/business-owner`, payload);
};

export const updateBusinessOwner = async (businessOwnerId, payload) => {
  return await postRequest(`${dashboardUrl}/company/business-owner/${businessOwnerId}`, payload);
};

export const deleteBusinessOwner = async (businessOwnerId) => {
  const companyId = getCompanyId();
  return await deleteRequest(`${dashboardUrl}/company/${companyId}/business-owner/${businessOwnerId}`);
};

export const getAllBusinessOwners = async () => {
  const companyId = getCompanyId();
  return await getRequest(`${dashboardUrl}/company/${companyId}/business-owners`);
};

export const getBusinessOwnerById = async (companyId, businessOwnerId) => {
  return await getRequest(`${dashboardUrl}/company/${companyId}/business-owner/${businessOwnerId}`);
};

export const updateCompany = async (payload) => {
  const companyId = getCompanyId();
  return await postRequest(`${dashboardUrl}/company/${companyId}`, payload);
};

export const createBusinessOwnerEntity = async (payload) => {
  const companyId = getCompanyId();
  return await putRequest(`${dashboardUrl}/company/${companyId}/business-owner-entity`, payload);
};

export const updateBusinessOwnerEntity = async (entityId, payload) => {
  const companyId = getCompanyId();
  return await postRequest(`${dashboardUrl}/company/${companyId}/business-owner-entity/${entityId}`, payload);
};

export const getAllBusinessOwnerEntities = async () => {
  const companyId = getCompanyId();
  return await getRequest(`${dashboardUrl}/company/${companyId}/business-owner-entities`);
};

export const getBusinessOwnerEntityById = async (companyId, entityId) => {
  return await getRequest(`${dashboardUrl}/company/${companyId}/business-owner-entity/${entityId}`);
};

export const deleteBusinessOwnerEntity = async (businessOwnerId) => {
  const companyId = getCompanyId();
  return await deleteRequest(`${dashboardUrl}/company/${companyId}/business-owner-entity/${businessOwnerId}`);
};

export const getCompany2fa = async () => {
  const companyId = getCompanyId();
  return await getRequest(`${dashboardUrl}/company/${companyId}/settings/2FA`);
};

// handles enable and disable 2fa
export const toggleCompany2fa = async (body = {}) => {
  const companyId = getCompanyId();
  return await putRequest(`${dashboardUrl}/company/${companyId}/settings`, body);
};

export const verifyCompany2fa = async (type, body = {}) => {
  const companyId = getCompanyId();
  return await postRequest(`${dashboardUrl}/company/${companyId}/otp/${type}`, body);
};

export const resend2faCode = async (type, body) => {
  const companyId = getCompanyId();
  return await postRequest(`${dashboardUrl}/company/${companyId}/otp/resend/${type}`, body);
};
