import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import PATH from '../path';
import Card from './Card';
import Contact from './Contact';
import ConversionOverview from './IntegratedOverview/Conversion';
import GlobalAccountsOverview from './IntegratedOverview/GlobalAccounts';
import WalletSetup from './Onboarding/WalletSetup';
import Overview from './Overview';
import Transfer from './Transfer';
import { WALLET_ACCOUNT_STATUS } from '@/constants';
import { fetchWalletBeneficiaries, fetchWalletCurrentBalance, fetchWalletCards } from '@redux/modules/wallet/actions';
import { useWalletAccount } from '@redux/selectors';

const Wallet = () => {
  const [isWalletReady, toggleIsWalletReady] = useState(false);
  const walletAccount = useWalletAccount();

  useEffect(() => {
    if (
      walletAccount &&
      walletAccount.airwallex_account_id !== null &&
      walletAccount.status === WALLET_ACCOUNT_STATUS.ACTIVE &&
      walletAccount.airwallex_status !== null &&
      walletAccount.airwallex_status !== 'CREATED' &&
      walletAccount.airwallex_status !== 'SUBMITTED'
    ) {
      toggleIsWalletReady(true);
      fetchWalletCurrentBalance(walletAccount.account_id);
      fetchWalletBeneficiaries(walletAccount.account_id, { pageNumber: 1, pageSize: 20 });
      fetchWalletCards(walletAccount.account_id, { pageNumber: 1, pageSize: 100 });
    } else {
      toggleIsWalletReady(false);
    }
  }, [JSON.stringify(walletAccount)]);

  return (
    <Switch>
      {!isWalletReady && <Route exact path={PATH.WALLET_SETUP} component={WalletSetup} />}
      <Route path={PATH.WALLET_GLOBAL_ACCOUNTS} component={GlobalAccountsOverview} />
      <Route path={PATH.WALLET_OVERVIEW} component={Overview} />
      <Route path={PATH.WALLET_TRANSFER} component={Transfer} />
      <Route path={PATH.WALLET_CONTACT} component={Contact} />
      <Route path={PATH.WALLET_CARDS} component={Card} />
      <Route path={PATH.WALLET_CONVERSION} component={ConversionOverview} />
      <Redirect to={PATH.WALLET_OVERVIEW} />
    </Switch>
  );
};

export default Wallet;
