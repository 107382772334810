import React, { useEffect, useState, useRef } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import FancyDateRangePicker from '../../../../components/FancyDateRangePicker';
import FormTitle from '../../../../components/FormTitle';
import Pagination from '../../../../components/Pagination';
import Select from '../../../../components/Select';
import Table from '../../../../components/Table';
import ToolTip from '../../../../components/ToolTip';
import Logger from '../../../../utils/logger';
import { finverseColumnConfig } from './constants';
import { combineTransactionsWithBalanceHistories } from './helpers';
import {
  getFinverseFilterValues,
  getFinverseTransactionsByAccount,
  getFinverseAccountBalanceHistory,
} from '@api/modules/integration/finverse';
import { GridColumn } from '@components/Grid';
import { PLATFORM } from '@constants/platform';
import { usePagination } from '@hooks/UI';
import { fetchBankAccounts } from '@redux/modules/bank/actions';
import { fetchIntegratedFinverseInstitutions } from '@redux/modules/integration/finverse/actions';
import { findFirstMatchInArray } from '@utils/dataTypes';
import { convertToApiDate } from '@utils/dateHelpers';
import { getOptionLabelByValue } from '@utils/optionHelpers';

const Row = styled.div`
  margin: 12px 0;
`;

const AccountWrapper = styled(GridColumn)`
  padding-top: 0;
`;

const DateWrapper = styled.div`
  float: right;
  margin: 30px 15px;
`;

const FinverseTransactions = () => {
  const activeBankAccounts = useSelector((state) => state.bank.activeBankAccounts);
  const integratedInstitutions = useSelector((state) => state.integration.finverse.integratedInstitutions);
  const [isLoading, toggleIsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const {
    pageNumber,
    pageSize,
    pageCount,
    onPageNumberChange,
    onPageSizeChange,
    changePageCountByTotalAndSize,
  } = usePagination({
    pageNumber: 1,
    pageCount: 0,
    pageSize: 20,
  });
  const [balanceHistories, setBalanceHistories] = useState([]);
  const [selectedChannelAccount, setSelectedChannelAccount] = useState();
  const [channelAccountOptions, setChannelAccountOptions] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState();
  const [institutionOptions, setInstitutionOptions] = useState([]);
  const [dateShortcutValue, setDateShortValue] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const handleDateFilterChange = (value) => {
    setStartDate(value.startDate);
    setEndDate(value.endDate);
    setDateShortValue(value.dateShortcutSelectValue);
  };

  useEffect(() => {
    fetchIntegratedFinverseInstitutions();
    fetchBankAccounts();
  }, []);

  useEffect(() => {
    const generateInstitutionOptions = () => {
      if (Array.isArray(integratedInstitutions)) {
        const options = integratedInstitutions.map((institution) => ({
          value: institution.channel_institution_id,
          label: institution.channel_institution_name,
        }));
        setInstitutionOptions(options);
      }
    };

    generateInstitutionOptions();
  }, [JSON.stringify(integratedInstitutions)]);

  useEffect(() => {
    const generateAccountOptions = () => {
      if (Array.isArray(activeBankAccounts)) {
        const options = activeBankAccounts
          .filter(({ channelAccountId }) => channelAccountId === selectedInstitution)
          .map(({ channelBankId, accountName }) => ({
            value: channelBankId,
            label: accountName,
          }));
        setChannelAccountOptions(options);
      }
    };

    generateAccountOptions();
  }, [selectedInstitution, JSON.stringify(activeBankAccounts)]);

  useEffect(() => {
    const clearSelectedAccount = () => {
      setSelectedChannelAccount();
      setTransactions([]);
    };

    clearSelectedAccount();
  }, [selectedInstitution]);

  const fetchTransactions = async () => {
    toggleIsLoading(true);
    try {
      const account = findFirstMatchInArray(
        activeBankAccounts,
        ({ channelBankId }) => channelBankId === selectedChannelAccount,
      );
      if (!account) {
        return;
      }
      const filterPayload = [
        { field: 'channel_id', value: account.channelBankId, operator: 'eq' },
        {
          field: 'channel_type',
          value: PLATFORM.FINVERSE,
          operator: 'eq',
        },
      ];
      if (startDate) {
        filterPayload.push({
          field: 'posted_date',
          value: convertToApiDate(startDate),
          operator: 'ge',
        });
      }
      if (endDate) {
        filterPayload.push({
          field: 'posted_date',
          value: convertToApiDate(endDate),
          operator: 'le',
        });
      }
      const { data } = await getFinverseTransactionsByAccount(
        { page: pageNumber, pageSize, sortField: 'posted_date', sortDirection: 'DESC' },
        { filter: filterPayload },
      );
      if (Array.isArray(data?.transactions)) {
        setTransactions(data.transactions);
        changePageCountByTotalAndSize(data.total, pageSize);
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const fetchBalanceHistory = async () => {
    try {
      const { data } = await getFinverseAccountBalanceHistory({
        channel_bank_id: selectedChannelAccount,
      });

      setBalanceHistories(data);
    } catch (err) {
      Logger.error(err);
    }
  };

  useEffect(() => {
    if (selectedChannelAccount) {
      fetchTransactions();
      fetchBalanceHistory();
    }
  }, [selectedChannelAccount, pageNumber, pageSize, startDate, endDate]);

  useEffect(() => {
    if (channelAccountOptions.length > 0) {
      setSelectedChannelAccount(channelAccountOptions[0].value);
    }
  }, [JSON.stringify(channelAccountOptions)]);

  const convertedTransactions = combineTransactionsWithBalanceHistories(transactions, balanceHistories);

  return (
    <div>
      <AccountWrapper lg={3} md={6} sm={6}>
        <FormTitle scale={5}>Account</FormTitle>
        <Row>
          <Select value={selectedInstitution} options={institutionOptions} onChange={setSelectedInstitution} />
        </Row>
        <Row>
          <Select value={selectedChannelAccount} options={channelAccountOptions} onChange={setSelectedChannelAccount} />
        </Row>
      </AccountWrapper>
      <DateWrapper>
        <GridColumn>
          <FormTitle scale={5}>Date Range</FormTitle>
          <FancyDateRangePicker
            id={`SearchFilter_${Date.now()}`}
            startDate={startDate}
            endDate={endDate}
            dateShortcutValue={dateShortcutValue}
            onApply={handleDateFilterChange}
            full
          />
        </GridColumn>
      </DateWrapper>
      <Table data={convertedTransactions} columns={finverseColumnConfig} isLoading={isLoading} />
      {pageCount ? (
        <Pagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          pageCount={pageCount}
          onPageNumberChange={onPageNumberChange}
          onPageSizeChange={onPageSizeChange}
        />
      ) : null}
    </div>
  );
};

export default FinverseTransactions;
