import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '../../components/Button';
import FileDropzone from '../../components/FileDropzone';
import Modal from '../../components/Modal';
import { Description } from "../Dashboard/components";
import { FormRow, GridColumn } from '@components/Grid';

const Header = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: bold;
`;

const UploadFilesModal = ({ title, description, acceptFileType, onConfirmClick, disabled, hideModal }) => {
  const [files, setFiles] = useState([]);
  const isLoading = useSelector((state) => state.company.isHandlingFiles);
  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const handleUpload = async (event) => {
    event.preventDefault(); // prevent form validation on BusinessDetailsForm
    await onConfirmClick(files);
    hideModal();
  };

  return (
    <Modal canHideModal={!isLoading}>
      <Header>{title}</Header>
      <Description>{description}</Description>
      <FileDropzone onDrop={onDrop} disabled={disabled} acceptFileType={acceptFileType} full />
      <FormRow>
        <GridColumn lg={6} md={6}>
          <Button onClick={handleUpload} disabled={disabled || !(Array.isArray(files) && files.length > 0) || isLoading} full>
            Confirm
          </Button>
        </GridColumn>
        <GridColumn lg={6} md={6}>
          <Button onClick={hideModal} disabled={isLoading} tertiary full>
            Cancel
          </Button>
        </GridColumn>
      </FormRow>
    </Modal>
  );
};

export default UploadFilesModal;
