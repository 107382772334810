import { REVENUE_FORM_NAME, REVENUE_FORM_PATH, ONE_TIME_FORM_NAME } from './constants';

export const generateInvoicePayload = ({ formData, formType, companyId, fundingId, offerId, remittanceStreamId }) => {
  return {
    type: formType === REVENUE_FORM_PATH ? REVENUE_FORM_NAME : ONE_TIME_FORM_NAME,
    company_id: companyId,
    funding_id: fundingId,
    offer_id: offerId,
    remittance_stream_id: remittanceStreamId,
    ...(formType === REVENUE_FORM_PATH
      ? {
          revenue_amount: formData.revenueAmount,
        }
      : {
          invoiced_amount: formData.invoicedAmount,
        }),
    currency: formData.revenueCurrency,
    date_debit: new Date(formData.invoiceDueDate),
    payment_method: formData.paymentMethod,
    description: formData.description,
  };
};
