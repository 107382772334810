import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import BreadCrumb from '../../../../components/BreadCrumb';
import RevenueChannels from '../../../Remittance/RevenueChannels';
import { Wrapper } from '../../components';
import PATH from '../../path';

const RevenueStreamDetail = () => {
  const streams = useSelector((state) => state.fund.streams);
  const stream = streams[0];
  return (
    <>
      <BreadCrumb items={[{ value: PATH.TERM_SHEET, label: 'Term sheet' }, { label: 'Revenue Channels' }]} />
      <Wrapper>
        <RevenueChannels stream={stream} />
      </Wrapper>
    </>
  );
};

export default RevenueStreamDetail;
