import { STATUS_CODE } from '@constants/application';

export const instruction = {
  [STATUS_CODE.REJECTED]: {
    analyst: 'Application rejected by {whom}',
    manager: 'Application rejected by {whom}',
    credit: 'Application rejected by {whom}',
  },
  [STATUS_CODE.PENDING_INVESTMENT_REVIEW]: {
    analyst: 'Create up to 3 offers',
    manager: 'Waiting for analyst to create offers',
    credit: 'Waiting for analyst to create offers',
  },
  [STATUS_CODE.PENDING_INVESTMENT_MANAGER_REVIEW]: {
    analyst: 'The offer is pending for manager review',
    manager: 'Please review the proposed offer',
    credit: 'The offer is pending for manager review',
  },
  [STATUS_CODE.PENDING_USER_REVIEW_TERM_SHEET]: {
    analyst: 'Waiting for user to accept offer',
    manager: 'Waiting for user to accept offer',
    credit: 'Waiting for user to accept offer',
  },
  [STATUS_CODE.PENDING_CREDIT_TEAM_REVIEW]: {
    analyst: 'The offer is pending for credit team review',
    manager: 'The offer is pending for credit team review',
    credit: 'Please review the accepted offer',
  },
  [STATUS_CODE.PENDING_USER_ACCEPT_OFFER]: {
    analyst: 'Pending user accept the final offer',
    manager: 'Pending user accept the final offer',
    credit: 'Pending user accept the final offer',
  },
  [STATUS_CODE.PENDING_USER_SIGN_AGREEMENT]: {
    analyst: 'Your funding offer is approved.',
    manager: 'Your funding offer is approved.',
    credit: 'Your funding offer is approved.',
  },
};
