import React from 'react';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../../../components/Button';
import { Card } from "../../../components";
import PATH from '../../../path';
import { useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';
import { media } from '@styles/breakpoints';

const FieldTitle = styled.span`
  ${(props) => props.theme.text.five};
`;

const CardTitle = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
  margin-bottom: 8px;
`;

const Container = styled.div`
  display: flex;

  ${media.desktop`
    flex-direction: column;
  `};
`;

const Description = styled.span`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  margin: 12px 0;
  flex-grow: 1;
`;

const ButtonWrapper = styled.div`
  flex-grow: 0;
`;

const ChangePasswordCard = () => {
  const history = useHistory();
  const isAdminView = useCheckIsAdminViewingAnotherCompany();

  const goToPasswordForm = (event) => {
    event.preventDefault();
    history.push(PATH.SETTINGS_CHANGE_PASSWORD);
  };

  return (
    <Card>
        <CardTitle>Security</CardTitle>
        <FieldTitle>Password</FieldTitle>
        <Container>
          <Description>Set a strong password to keep your account safe</Description>
          <ButtonWrapper>
            <Button secondary onClick={goToPasswordForm} disabled={isAdminView}>
              Change Password
            </Button>
          </ButtonWrapper>
        </Container>
      </Card>
  );
};

export default ChangePasswordCard;
