import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import Button from '../../../../../components/Button';
import FormTitle from '../../../../../components/FormTitle';
import HR from '../../../../../components/HR';
import Modal from '../../../../../components/Modal';
import Logger from '../../../../../utils/logger';
import ControlledInput from '../../../../ReactHookForm/ControlledInput';
import { toggleCompany2fa, verifyCompany2fa, resend2faCode } from '@api/modules/dashboard/company';
import { FormRow } from '@components/Grid';
import { useTimer } from '@hooks/UI';
import { media } from '@styles/breakpoints';

const Title = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: 600;
  margin-bottom: 8px;
`;

const Description = styled.span`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  margin: 12px 0;
`;

const ErrorMessage = styled.div`
  margin-top: 10px;
  font-size: 15px;
  line-height: 25px;
  color: ${(props) => props.theme.colors.red};
`;

const StyledButton = styled(Button)`
  margin-left: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  margin: 12px 0;
  justify-content: end;
`;

const ResendLink = styled(Button)`
  ${(props) => props.theme.text.link};
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  position: absolute;
  right: 70px;
  top: 175px;

  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.colors.grayTwo};
      cursor: not-allowed;
    `}

  ${media.phone`
    ${(props) => props.theme.text.micro};
    right: 70px;
    top: 205px;
  `};
`;

const Transfer2faModal = ({ trxId, hideModal, checkIsVerified }) => {
  const userEmail = useSelector((state) => state.company.company2fa[0].transaction2faEmail);

  const { remainingTime, restartTimer } = useTimer({ timeLimitInSeconds: 90 });
  const [isLoading, toggleIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  // verifies 2fa
  const onSubmit = async (formData) => {
    toggleIsLoading(true);
    try {
      const body = {
        OTP: formData.OTP,
        transactionRecordId: trxId,
      };

      const { statusCode } = await verifyCompany2fa('transaction', body);
      if (statusCode === 200) {
        checkIsVerified();
        hideModal();
      } else {
        setApiError('Authentication code is invalid. Please try again.');
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const handleCancel = () => {
    hideModal();
  };

  // Resend 2fa code to user, OTP code expires in 60 seconds
  const handleResendCode = async (event) => {
    event.preventDefault();
    toggleIsLoading(true);
    reset({
      OTP: '',
    });
    try {
      const body = {
        transaction2faEmail: userEmail,
        transactionRecordId: trxId,
      };
      await resend2faCode('transaction', body);
      restartTimer(90);
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  return (
    <Modal>
      <>
        <Title>Two-step verification required</Title>
        <HR margin={8} color="grayOne" />
        <Title>We have sent authentication code to your email.</Title>
        <Description>
          Enter the 6-digit code sent to your email&nbsp;
          {userEmail}.
        </Description>
        <FormRow>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormTitle scale={5} required>
              Authentication code
            </FormTitle>
            <ControlledInput
              name="OTP"
              control={control}
              rules={{ required: { value: true, message: 'Please enter the 6-digit code for verification.' } }}
              disabled={isLoading}
              full
            />
            <ResendLink disabled={remainingTime !== 0 || isLoading} onClick={handleResendCode}>
              Resend code {remainingTime === 0 ? '' : `(${remainingTime}s)`}
            </ResendLink>
            <Wrapper>
              <Button tertiary onClick={handleCancel}>
                Cancel
              </Button>
              <StyledButton type="submit" disabled={isLoading || remainingTime === 0}>
                Verify
              </StyledButton>
            </Wrapper>
            <Wrapper>
              <ErrorMessage>{apiError}</ErrorMessage>
            </Wrapper>
          </form>
        </FormRow>
      </>
    </Modal>
  );
};

export default Transfer2faModal;
