import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import RadioWithText from '../../../../components/RadioWithText';
import SingleDatePicker from '../../../../components/SingleDatePicker';
import Logger from '../../../../utils/logger';
import { updateInvoiceStatus } from '@api/modules/dashboard/invoice';
import { FormRow, GridColumn } from '@components/Grid';
import { INVOICE_STATUS } from '@/constants';
import { fetchInvoiceDetailById, fetchBalanceTransactions, fetchFundBalance } from '@redux/modules/fund/actions';
import { useSelectedCompanyId, useAcceptedOffer } from '@redux/selectors';

const Title = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
`;

const Content = styled.div`
  margin: 24px 0;
`;

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.error};
  ${(props) => props.theme.text.micro};
  text-align: right;
`;

const invoiceStatusOptions = [
  {
    value: INVOICE_STATUS.PAID,
    label: 'Paid',
    description: 'Payment was collected',
  },
  // {
  //   value: INVOICE_STATUS.VOID,
  //   label: 'Void',
  //   description: 'The invoice wasn’t finalized or contains a mistake.',
  // },
  {
    value: INVOICE_STATUS.UNCOLLECTIBLE,
    label: 'Uncollectible',
    description: 'The transaction was not processed successfully.',
  },
];

const InvoiceStatusModal = ({ hideModal, invoiceId }) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      paymentDate: moment(),
    },
  });
  const [apiError, setApiError] = useState();
  const [isLoading, toggleIsLoading] = useState(false);
  const invoiceDetail = useSelector((state) => state.fund.invoiceDetailMap[invoiceId]);
  const { invoice_id, payment_status } = invoiceDetail ?? {};
  const { fundingNumber, applicationId } = useSelector((state) => state.application.data);
  const acceptedOffer = useAcceptedOffer();
  const selectedCompanyId = useSelectedCompanyId();

  const watchOfStatus = watch('status');
  const watchOfPaymentDate = watch('paymentDate');

  const handleStatusChange = (value) => {
    setValue('status', value);
  };

  const onSubmit = async ({ paymentDate, status }) => {
    toggleIsLoading(true);
    setApiError('');
    try {
      if (status === invoiceDetail.payment_status) {
        setApiError('You should change status for update');
        return;
      }

      const result = await updateInvoiceStatus(invoice_id, status, {
        ...(status === INVOICE_STATUS.PAID && {
          date: paymentDate?.toISOString(),
        }),
      });

      if (result?.status === 204) {
        hideModal();
        fetchInvoiceDetailById(invoice_id);
        fetchBalanceTransactions(fundingNumber);
        fetchFundBalance({
          company_id: selectedCompanyId,
          funding_id: applicationId,
          offer_id: acceptedOffer?.id,
        });
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  useEffect(() => {
    reset({
      status: invoiceDetail.payment_status,
      ...(invoiceDetail.date_paid && {
        paymentDate: moment(invoiceDetail.date_paid),
      }),
    });
  }, [JSON.stringify(invoiceDetail)]);

  return (
    <Modal>
      <Title>Change Invoice payment status</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Content>
          Mark invoice as
          {invoiceStatusOptions.map(({ value, label, description }) => {
            return (
              <RadioWithText
                key={value}
                value={value}
                checked={value === watchOfStatus}
                label={label}
                description={description}
                disabled={value === payment_status}
                onClick={handleStatusChange}
              >
                {value === INVOICE_STATUS.PAID && watchOfStatus === INVOICE_STATUS.PAID && (
                  <>
                    <div>Payment date</div>
                    <Controller
                      name="paymentDate"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <SingleDatePicker
                            {...field}
                            disabled={value === payment_status || isLoading}
                            hasError={errors.paymentDate}
                            isOutsideRange={() => false}
                          />
                        );
                      }}
                    />
                  </>
                )}
              </RadioWithText>
            );
          })}
        </Content>

        <FormRow>
          <GridColumn lg={6} md={6}>
            {/* <Button secondary full>
            Cancel
          </Button> */}
          </GridColumn>
          <GridColumn lg={6} md={6}>
            <Button full disabled={!watchOfStatus || isLoading} onClick={handleSubmit}>
              Change Status
            </Button>
            {apiError && <ErrorMessage>{apiError}</ErrorMessage>}
          </GridColumn>
        </FormRow>
      </form>
    </Modal>
  );
};

export default InvoiceStatusModal;
