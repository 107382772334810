import React, { useEffect, useState } from 'react';

import { ResponsiveContainer, LineChart, Legend, Line, XAxis, YAxis, LabelList, Tooltip, CartesianGrid } from 'recharts';
import styled, { css } from 'styled-components';

import COLORS from '../../../../styles/colors';
import Logger from '../../../../utils/logger';
import { Card } from '../../components';
import { getYAxisDomain } from '../../Home/helpers';
import { formatLines, formatPercentageChange } from './helpers';
import { getStripeOverviewData } from '@api/modules/integration';
import { Currency } from '@/constants';
import { isRealObject } from '@utils/dataTypes';
import { convertToApiDate, convertToFullDate } from '@utils/dateHelpers';
import { formatPrice, formatPercent } from '@utils/priceHelpers';

const CardHeader = styled.span`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.purple};
  font-weight: 600;
`;

const StyledCard = styled(Card)`
  padding: 25px;
`;

const HeaderWrapper = styled.span`
  display: flex;
  justify-content: space-between;
`;

const ChangePercentage = styled.span`
  ${(props) => props.theme.text.five};

  ${(props) =>
    props.showPercentageColor
      ? css`
          color: ${props.theme.colors.red};
        `
      : css`
          color: ${props.theme.colors.green};
        `}

  ${(props) =>
    props.showZeroPercentageColor &&
    css`
      color: ${props.theme.colors.black};
    `};
  font-weight: 600;
`;

const CurrentDataSum = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
`;

const PrecedingDataSum = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  font-weight: 600;
`;

const ChartWrapper = styled.div`
  padding-top: 18px;
`;

const defaultStyleConfig = {
  strokeWidth: 2,
  dot: false,
  type: 'linear',
  stroke: COLORS.purple,
};

const SummaryChart = ({
  title,
  type,
  formatSumLabel = (params) => params.value,
  startDate,
  endDate,
  precedingStartDate,
  precedingEndDate,
  lineStyle = defaultStyleConfig,
}) => {
  const [currentDataSum, setCurrentDataSum] = useState(0);
  const [precedingDataSum, setPrecedingDataSum] = useState(0);
  const [currentCurrency, setCurrentCurrency] = useState(Currency.USD);
  const [currentData, setCurrentData] = useState([]);
  const [precedingData, setPrecedingData] = useState([]);

  const fetchApi = async () => {
    try {
      const { data = {}, error } = await getStripeOverviewData(type, {
        startDate: convertToApiDate(startDate),
        endDate: convertToApiDate(endDate),
      });

      if (error) {
        return;
      }

      if (isRealObject(data)) {
        setCurrentCurrency(data.currency);
        setCurrentDataSum(data.current.sum);
        setPrecedingDataSum(data.previous.sum);
        setCurrentData(data.current.data);
        setPrecedingData(data.previous.data);
      }
    } catch (err) {
      Logger.error(err);
    }
  };

  const percentageChangeAmount = formatPercentageChange(currentDataSum, precedingDataSum);

  useEffect(() => {
    fetchApi();
  }, [startDate, endDate]);

  const yAxisDataKey = 'current_amount';
  // preceding dataKey
  const yAxisPrecedingDataKey = 'preceding_amount';
  const formattedData = formatLines(currentData, precedingData);

  const currentDataYAxisDomain = getYAxisDomain(currentData.map((item) => Number(item.amount)));
  const precedingDataYAxisDomain = getYAxisDomain(precedingData.map((item) => Number(item.amount)));

  const largestYAxisValue = Math.max(...currentDataYAxisDomain, ...precedingDataYAxisDomain);
  const smallestYAxisValue = Math.min(...currentDataYAxisDomain, ...precedingDataYAxisDomain);

  return (
    <StyledCard>
      <HeaderWrapper>
        <CardHeader>{title}</CardHeader>
        <ChangePercentage showZeroPercentageColor={percentageChangeAmount === 0} showPercentageColor={percentageChangeAmount < 0}>
          {formatPercent(percentageChangeAmount)}
        </ChangePercentage>
      </HeaderWrapper>
      <HeaderWrapper>
        <CurrentDataSum>{formatSumLabel({ currency: currentCurrency, value: currentDataSum })}</CurrentDataSum>
        <PrecedingDataSum>{formatSumLabel({ currency: currentCurrency, value: precedingDataSum })}</PrecedingDataSum>
      </HeaderWrapper>
      <ChartWrapper>
        <ResponsiveContainer height={250}>
          <LineChart data={formattedData}>
            <XAxis interval="preserveStartEnd" dataKey="current_date" />
            <YAxis hide="false" dataKey={yAxisDataKey} domain={[smallestYAxisValue, largestYAxisValue]} />
            <LabelList />
            <Tooltip wrapperStyle={{ fontSize: '12px' }} />
            <Legend wrapperStyle={{ fontSize: '10px' }} align="right" iconType="circle" iconSize="10" />
            <CartesianGrid vertical={false} stroke={COLORS.grayTwo} />
            <Line name={`${convertToFullDate(startDate)} - ${convertToFullDate(endDate)}`} dataKey={yAxisDataKey} {...lineStyle} />
            <Line
              name={`${convertToFullDate(precedingStartDate)} - ${convertToFullDate(precedingEndDate)}`}
              dataKey={yAxisPrecedingDataKey}
              {...lineStyle}
              stroke={COLORS.grayThree}
            />
          </LineChart>
        </ResponsiveContainer>
      </ChartWrapper>
    </StyledCard>
  );
};

export default SummaryChart;
