/* eslint-disable guard-for-in */
import React, { useState, useEffect } from 'react';

import deepEqual from 'deep-equal';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as getUUID } from 'uuid';

import BreadCrumb from '../../../../components/BreadCrumb';
import Button from '../../../../components/Button';
import FormTitle from '../../../../components/FormTitle';
import Icon from '../../../../components/Icon';
import LoadingCircle from '../../../../components/LoadingCircle';
import Select from '../../../../components/Select';
import Logger from '../../../../utils/logger';
import RevenueChannels from '../../../Remittance/RevenueChannels';
import { Wrapper } from '../../components';
import PATH from '../../path';
import { CHANNELS_OPTIONS, CATEGORY_BANK } from './constants';
import {
  generateCreateRemittanceStreamPayload,
  constructSelectedChannelAccountObject,
  generateOtherBankOption,
  generateXeroBankOption,
} from './helpers';
import StreamSelection from './StreamSelection';
import { PLATFORM, PLATFORM_MAP, CATEGORY } from '@constants/platform';
import { media } from '@styles/breakpoints';
import { BANK_SOURCE } from '@constants/bank';
import { createRemittanceStream, setRemittanceStreamChannel } from '@api/modules/dashboard/remittance';
import { useAcceptedOffer } from '@redux/selectors';
import { STATUS_CODE } from '@constants/application';
import { COMPANY_FILE_TYPE } from '@constants/file';
import { isRealObject, findFirstMatchInArray } from '@utils/dataTypes';
import { formatGAAccountLabel } from '@utils/platformHelpers';
import { useIsUserSalesAnalyst } from '@utils/userHelpers';
import { fetchRemittanceStreams } from '@redux/modules/fund/actions';

const Header = styled.div`
  ${(props) => props.theme.text.four};
  font-weight: 600;
`;

const Form = styled.form`
  width: 50%;
  ${media.desktop`
    width: 80%;
  `}
`;

const AddChannel = styled.div`
  ${(props) => props.theme.text.link};
  display: inline;
`;

const StyledSelect = styled(Select)`
  vertical-align: middle;
  display: inline-block;
  width: calc(100% - 48px);
`;

const StyledIcon = styled(Icon)`
  vertical-align: middle;
  margin-left: 20px;
`;

const Content = styled.div`
  margin-bottom: 20px;
`;

const ErrorMessage = styled.div`
  margin: 8px 0;
  ${(props) => props.theme.text.error};
  ${(props) => props.theme.text.micro};
`;

const RevenueStream = () => {
  const history = useHistory();
  const acceptedOffer = useAcceptedOffer();
  const applicationData = useSelector((state) => state.application.data);
  const integrations = useSelector((state) => state.application.integrations);
  const allBankAccounts = useSelector((state) => state.bank.allBankAccounts);
  const activeBankAccounts = useSelector((state) => state.bank.activeBankAccounts);
  const streams = useSelector((state) => state.fund.streams);
  const activeStream = streams[0] ?? {};
  const [channels, setChannels] = useState([{ value: null, id: getUUID() }]);
  const [selectedStoreMap, setSelectedStoreMap] = useState({});
  const agreementFiles = useSelector((state) => state.company.files[COMPANY_FILE_TYPE.AGREEMENT]);
  const [apiError, setApiError] = useState();
  const [isLoading, toggleIsLoading] = useState(true);
  const isAnalyst = useIsUserSalesAnalyst();
  const disabled = (applicationData.statusCode > STATUS_CODE.PENDING_USER_SIGN_AGREEMENT && agreementFiles.length > 0) || isLoading;
  const readOnly = applicationData.statusCode > STATUS_CODE.PENDING_USER_SIGN_AGREEMENT;
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getChannelOptions = () => {
    if (Array.isArray(channels)) {
      const selectedChannels = channels.map(({ value }) => value);
      return CHANNELS_OPTIONS.map(({ value, label }) => {
        return {
          value,
          label,
          isDisabled: selectedChannels.indexOf(value) !== -1,
        };
      });
    }

    return [];
  };

  const channelOptions = getChannelOptions();

  // initiate
  useEffect(() => {
    if (isRealObject(selectedStoreMap)) {
      for (const channelCategory in selectedStoreMap) {
        for (const platformName in selectedStoreMap[channelCategory]) {
          const stores = selectedStoreMap[channelCategory][platformName];
          const key = `${channelCategory}.${platformName}`;

          if (platformName === PLATFORM.GOOGLE_ANALYTICS) {
            const gaOptions = Array.isArray(stores)
              ? // eslint-disable-next-line no-loop-func
                stores.map((value) => {
                  const integration = findFirstMatchInArray(integrations, ({ id }) => id === value);
                  return {
                    value: integration.id,
                    label: formatGAAccountLabel(integration?.accountId, integration?.accountName),
                  };
                })
              : [];

            setValue(key, gaOptions);
          } else if (['otherBankAccounts', PLATFORM.XERO].indexOf(platformName) !== -1) {
            const bankOptions = Array.isArray(stores)
              ? // eslint-disable-next-line no-loop-func
                stores
                  .map((value) => {
                    const bankAccount = findFirstMatchInArray(
                      allBankAccounts,
                      ({ id, channelBankId }) => id === value || channelBankId === value
                    );
                    if (bankAccount) {
                      return bankAccount.add_method === BANK_SOURCE.XERO
                        ? generateXeroBankOption(bankAccount)
                        : generateOtherBankOption(bankAccount);
                    }
                    return '';
                  })
                  .filter((value) => !!value)
              : [];
            setValue(key, bankOptions);
          } else {
            const selectedStoreOptions = Array.isArray(stores) ? stores.map((value) => ({ value, label: value })) : [];
            setValue(key, selectedStoreOptions);
          }
        }
      }
    }
    toggleIsLoading(false);
  }, [JSON.stringify(selectedStoreMap)]);

  useEffect(() => {
    if (Array.isArray(streams) && streams.length > 0) {
      const nextChannels = [];
      let nextStoreMap = {};
      if (activeStream.sales_channel && !deepEqual(activeStream.sales_channel, {})) {
        nextChannels.push({ value: CATEGORY.SALES, id: getUUID() });
        nextStoreMap = {
          ...nextStoreMap,
          [CATEGORY.SALES]: activeStream.sales_channel,
        };
      }
      if (activeStream.payment_channel && !deepEqual(activeStream.payment_channel, {})) {
        nextChannels.push({ value: CATEGORY.PAYMENT, id: getUUID() });
        nextStoreMap = {
          ...nextStoreMap,
          [CATEGORY.PAYMENT]: activeStream.payment_channel,
        };
      }
      if (activeStream.analytic_channel && !deepEqual(activeStream.analytic_channel, {})) {
        nextChannels.push({ value: CATEGORY.ANALYTICS, id: getUUID() });
        nextStoreMap = {
          ...nextStoreMap,
          [CATEGORY.ANALYTICS]: activeStream.analytic_channel,
        };
      }
      if (activeStream.bank_channel && !deepEqual(activeStream.bank_channel, {})) {
        nextChannels.push({ value: CATEGORY_BANK, id: getUUID() });
        nextStoreMap = {
          ...nextStoreMap,
          [CATEGORY_BANK]: activeStream.bank_channel,
        };
      }

      setSelectedStoreMap(nextStoreMap);
      setChannels(nextChannels);
    }
  }, [JSON.stringify(activeStream)]);

  const onSubmit = async (data) => {
    let isSuccess;
    toggleIsLoading(true);
    try {
      if (activeStream.stream_id) {
        const payload = constructSelectedChannelAccountObject(data, channels);
        // avoid sql override
        await setRemittanceStreamChannel({
          stream_id: activeStream.stream_id,
          channel: 'sales',
          accounts: payload.sales,
        });
        await setRemittanceStreamChannel({
          stream_id: activeStream.stream_id,
          channel: 'payment',
          accounts: payload.payment,
        });

        await setRemittanceStreamChannel({
          stream_id: activeStream.stream_id,
          channel: 'bank',
          accounts: payload.bank,
        });

        await setRemittanceStreamChannel({
          stream_id: activeStream.stream_id,
          channel: 'analytic',
          accounts: payload.analytic,
        });

        isSuccess = true;
        history.push(PATH.TERM_SHEET);
      } else {
        const payload = generateCreateRemittanceStreamPayload(applicationData.applicationId, acceptedOffer, data);
        const { statusCode } = await createRemittanceStream(payload);
        isSuccess = statusCode === 201;
      }
    } catch (err) {
      setApiError(err?.message || 'Something went wrong');
      Logger.error(err);
    } finally {
      if (isSuccess) {
        fetchRemittanceStreams();
        history.push(PATH.TERM_SHEET);
      }
      toggleIsLoading(false);
    }
  };

  const handleAddChannel = () => {
    setChannels([...channels, { value: null, id: getUUID() }]);
  };

  const handleRemoveChannel = (itemId) => () => {
    const index = _.findIndex(channels, ({ id }) => id === itemId);
    const nextChannels = [...channels];
    nextChannels.splice(index, 1);
    setChannels(nextChannels);
  };

  const handleOptionChange = (itemId) => (value) => {
    const index = _.findIndex(channels, ({ id }) => id === itemId);
    const nextChannels = [...channels];
    nextChannels.splice(index, 1, { value, id: itemId });
    setChannels(nextChannels);
  };

  const shouldRenderAddRevenueChannel = !disabled;

  return isLoading ? (
    <LoadingCircle />
  ) : (
    <>
      <BreadCrumb items={[{ value: PATH.TERM_SHEET, label: 'Term sheet' }, { label: 'Revenue Channels' }]} />
      {readOnly ? (
        <Wrapper>
          <RevenueChannels stream={activeStream} />
        </Wrapper>
      ) : (
        <Wrapper>
          <Header>Select Revenue Channels</Header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Content>
              {Array.isArray(channels) &&
                channels.map((item, index) => {
                  const channelIntegrations = integrations.filter((integration) => {
                    return (
                      PLATFORM_MAP[integration.platformName]?.category === item.value ||
                      (Array.isArray(PLATFORM_MAP[integration.platformName]?.categories) &&
                        PLATFORM_MAP[integration.platformName]?.categories.indexOf(item.value) !== -1)
                    );
                  });
                  return (
                    <div key={item.id}>
                      <FormTitle scale={5} required>
                        Revenue Channels
                      </FormTitle>
                      <div>
                        <StyledSelect
                          key={item.id}
                          value={item.value}
                          options={channelOptions}
                          onChange={handleOptionChange(item.id)}
                          disabled={disabled}
                        />
                        {channels.length > 1 && !disabled && (
                          <StyledIcon src="/icons/trash_bin.svg" onClick={handleRemoveChannel(item.id)} />
                        )}
                      </div>
                      {item.value && (
                        <StreamSelection
                          control={control}
                          channelCategory={item.value}
                          integrations={channelIntegrations}
                          activeBankAccounts={activeBankAccounts}
                          allBankAccounts={allBankAccounts}
                          disabled={disabled}
                        />
                      )}
                    </div>
                  );
                })}
              {shouldRenderAddRevenueChannel && <AddChannel onClick={handleAddChannel}>+ Add another revenue channel</AddChannel>}
            </Content>
            {!disabled && <Button>Confirm</Button>}
            {apiError && <ErrorMessage>{apiError}</ErrorMessage>}
          </Form>
        </Wrapper>
      )}
    </>
  );
};

export default RevenueStream;
