import React from 'react';

import { NumericFormat } from 'react-number-format';
import styled, { css } from 'styled-components';

const StyledNumberFormat = styled(NumericFormat)`
  display: inline-block;
  vertical-align: middle;

  border: 1px solid ${(props) => props.theme.colors.grayTwo};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px 16px;
  line-height: 24px;

  ${(props) =>
    props.full &&
    css`
      box-sizing: border-box;
      width: 65%;
    `}

  ${(props) =>
    props.hasError &&
    css`
      border: 1px solid ${props.theme.colors.red};
      box-shadow: inset 1px 0 ${props.theme.colors.red}, inset 0 1px ${props.theme.colors.red},
        inset -1px 0 ${props.theme.colors.red}, inset 0 -1px ${props.theme.colors.red};
      color: ${props.theme.colors.red};
    `}

  :focus-visible {
    outline: none;
  }

  :focus-within {
    border: 1px solid ${(props) => props.theme.colors.black};
    box-shadow: inset 1px 0 ${(props) => props.theme.colors.black}, inset 0 1px ${(props) => props.theme.colors.black},
      inset -1px 0 ${(props) => props.theme.colors.black}, inset 0 -1px ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.black};
  }

  ::placeholder {
    color: ${(props) => props.theme.colors.grayTwo};
  }
`;

export default StyledNumberFormat;
