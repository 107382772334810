import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import Email from '../../../components/Email';
import HR from '../../../components/HR';
import PlatformLogo, { LogoWrapper } from '../../../components/PlatformLogo';
import Logger from '../../../utils/logger';
import SalesFilesSection from '../../Integration/SalesFilesSection';
import {
  MANUAL_INTEGRATION_MODAL,
  WOOCOMMERCE_INTEGRATION_MODAL,
  SHOPLAZZA_INTEGRATION_MODAL,
  SALES_OTHERS_PLATFORM_MODAL,
  SHOPIFY_INTEGRATION_MODAL,
  MAGENTO_INTEGRATION_MODAL,
  MARKETING_PLATFORM_INTEGRATION,
  GA_TUTORIAL_MODAL,
  MESSAGE_MODAL,
} from '../../Modal/constants';
import AccountingSection from '../../Platform/AccountingSection';
import MarketingAccountIntegration from '../../Platform/MarketingAccountIntegrations';
import SalesAccountsTable from '../../Platform/SalesAccountsTable';
import { GrayCard, CardHeader, PageHeader } from '../components';
import { deleteIntegratedAccount, integrateAccount } from '@api/modules/application';
import { SALES_PLATFORM_LOGOS, PLATFORM, PLATFORM_DISPLAY_NAME, MANUAL_INTEGRATION_PLATFORMS } from '@constants/platform';
import { salesPlatformsFilter, filterSalesAccountLogos } from '@utils/platformHelpers';
import { GridColumn } from '@components/Grid';
import { useIntegrationChannel } from '@hooks/channel';
import { useCheckIsAdminViewingAnotherCompany, useSelectedCompanyId, useCheckIsSignUpViaShopify } from '@redux/selectors';
import { fetchIntegrations } from '@redux/modules/application/actions';
import { showModal } from '@redux/modules/UI/actions';
import { findFirstMatchInArray } from '@utils/dataTypes';
import { INTEGRATION_STATUS } from '@/constants';
import { SUPPORT_EMAIL } from '@constants/email';
import { postShopIntegration } from '@/api/modules/integration';
import { API_BASE_URL } from '@/api/constants';

export const Description = styled.div`
  color: ${(props) => props.theme.colors.grayThree};
  margin-bottom: 15px;
`;

export const deletePlatformModalDescription = (
  <Description>
    Your account is successfully connected and in progress of data integration. Disconnecting the account may interrupt the system
    operations. If you confirm to proceed, please contact <Email address={SUPPORT_EMAIL} />
  </Description>
);

const SalesOthersPlatformTitle = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => props.theme.text.five};
  font-weight: bold;
`;

// eslint-disable-next-line prefer-const
let channel = null;

const Integration = () => {
  const dispatch = useDispatch();
  const adminIsViewingAnotherCompany = useCheckIsAdminViewingAnotherCompany();
  const isSignUpViaShopify = useCheckIsSignUpViaShopify();

  const [isLoadingSales, setIsLoadingSales] = useState(false);

  const companyId = useSelectedCompanyId();
  const integrations = useSelector((state) => state.application.integrations);
  const salesAccounts = integrations.filter(({ platformName }) => salesPlatformsFilter(platformName));
  const GAAccounts = integrations.filter(({ platformName }) => platformName === PLATFORM.GOOGLE_ANALYTICS);

  const showManualIntegrationModal = (platformName) => {
    dispatch(
      showModal(MANUAL_INTEGRATION_MODAL, {
        companyName: platformName,
      })
    );
  };

  const showWoocommerceIntegrationModal = () => {
    dispatch(showModal(WOOCOMMERCE_INTEGRATION_MODAL));
  };

  const showShoplazzaIntegrationModal = () => {
    dispatch(showModal(SHOPLAZZA_INTEGRATION_MODAL));
  };

  const afterSubmit = async () => {
    await fetchIntegrations();
  };

  const showSalesOthersPlatformModal = () => {
    if (!adminIsViewingAnotherCompany) {
      dispatch(showModal(SALES_OTHERS_PLATFORM_MODAL, { afterSubmit }));
    }
  };

  const registerConnection = (platformName, disabled) => async () => {
    if (disabled) {
      return;
    }

    if (!isLoadingSales) {
      try {
        setIsLoadingSales(true);
        if ([PLATFORM.STRIPE, PLATFORM.XERO, PLATFORM.SHOPEE, PLATFORM.LAZADA].includes(platformName)) {
          let newWin;
          if ([PLATFORM.SHOPEE, PLATFORM.LAZADA].includes(platformName)) {
            newWin = window.open('', 'newWin', 'width=1080,height=680');
          } else {
            newWin = window.open('', 'newWin', 'width=520,height=680');
          }
          const {
            data: { url },
          } = await integrateAccount(platformName);
          if (newWin && url) {
            newWin.location.href = url;
          }
        } else if (platformName === PLATFORM.SHOPIFY) {
          dispatch(showModal(SHOPIFY_INTEGRATION_MODAL, { afterSubmit }));
        } else if (platformName === PLATFORM.MAGENTO) {
          dispatch(showModal(MAGENTO_INTEGRATION_MODAL, { afterSubmit }));
        } else if (platformName === PLATFORM.WOOCOMMERCE) {
          showWoocommerceIntegrationModal();
        } else if (platformName === PLATFORM.SHOPLAZZA) {
          showShoplazzaIntegrationModal();
        } else if (MANUAL_INTEGRATION_PLATFORMS.indexOf(platformName) !== -1) {
          showManualIntegrationModal(platformName);
        } else if (platformName === PLATFORM.GOOGLE_ANALYTICS) {
          dispatch(showModal(GA_TUTORIAL_MODAL));
        }
      } catch (err) {
        Logger.error(err);
      } finally {
        setIsLoadingSales(false);
      }
    }
  };

  const showGAIntegrationModal = (integrationId, accountId) => {
    dispatch(
      showModal(MARKETING_PLATFORM_INTEGRATION, {
        component: PLATFORM.GOOGLE_ANALYTICS,
        integrationId,
        accountId,
      })
    );
  };

  useEffect(() => {
    if (Array.isArray(GAAccounts) && GAAccounts.length > 0 && !adminIsViewingAnotherCompany) {
      const incompleteAccount = findFirstMatchInArray(GAAccounts, (account) => account.status === -2);
      if (incompleteAccount) {
        showGAIntegrationModal(incompleteAccount.id, incompleteAccount.accountId);
      }
    }
  }, [JSON.stringify(GAAccounts)]);

  useEffect(() => {
    fetchIntegrations();
  }, []);

  useIntegrationChannel(channel, fetchIntegrations);

  const onDeletePlatformClick =
    ({ id, platformName, accountId, status }) =>
    async () => {
      if (MANUAL_INTEGRATION_PLATFORMS.indexOf(platformName) === -1 && status === INTEGRATION_STATUS.CONNECTED) {
        dispatch(
          showModal(MESSAGE_MODAL, {
            title: 'Disconnect account',
            description: deletePlatformModalDescription,
            showLeftButton: false,
            showRightButton: false,
            showCloseButton: true,
          })
        );
      } else {
        await deleteIntegratedAccount({
          platformName,
          integrationId: id,
          ...(platformName === PLATFORM.GOOGLE_ANALYTICS && { accountId }),
        });
        await fetchIntegrations();
      }
    };

  const handleShopReAuth = async (accountId) => {
    console.log(accountId);
    try {
      // const response = await postShopIntegration({
      //   shop: accountId,
      // });
      // if (response.statusCode === 200) {
      //   console.log(response.data);
      //   const data = response.data;
      //   const link = data.link.integrate_link;
      //   window.location.href = link;
      // }
      const url = `${API_BASE_URL}/api/v2/portal/platforms/public/shop/generate-installation-link?shop=${accountId}&source=public&company_id=${companyId}`;
      window.location.href = url;
    } catch (err) {
      Logger.error(err);
    }
  };

  return (
    <>
      <PageHeader>Integration</PageHeader>
      <GrayCard>
        <GridColumn>
          <CardHeader>Sales Accounts</CardHeader>
          <Description>Connect your sales platform</Description>
          {Array.isArray(salesAccounts) && salesAccounts.length > 0 && (
            <>
              <SalesAccountsTable
                accounts={salesAccounts}
                onDeletePlatformClick={onDeletePlatformClick}
                onShopPlatformReAuth={handleShopReAuth}
                showGAIntegrationModal={showGAIntegrationModal}
                disabled={adminIsViewingAnotherCompany}
              />
              <HR />
            </>
          )}
          {filterSalesAccountLogos(SALES_PLATFORM_LOGOS, salesAccounts).map(({ value, logoUrl, displayName, enabled, message }) => {
            const isDisabled = !enabled || adminIsViewingAnotherCompany;
            return (
              <PlatformLogo
                value={value}
                disabled={isDisabled}
                onClick={registerConnection(value, isDisabled)}
                logoUrl={logoUrl}
                message={message}
                displayName={displayName}
              />
            );
          })}
          <LogoWrapper onClick={showSalesOthersPlatformModal} disabled={adminIsViewingAnotherCompany}>
            <SalesOthersPlatformTitle>{PLATFORM_DISPLAY_NAME.SALES_OTHERS}</SalesOthersPlatformTitle>
          </LogoWrapper>
          {!isSignUpViaShopify && (
            <>
              <HR />
              <SalesFilesSection />
            </>
          )}
        </GridColumn>
      </GrayCard>
      <GrayCard>
        <GridColumn>
          <CardHeader>Marketing Accounts</CardHeader>
          <Description>Connect your marketing platform</Description>
          <MarketingAccountIntegration />
        </GridColumn>
      </GrayCard>
      <GrayCard>
        <GridColumn>
          <AccountingSection />
        </GridColumn>
      </GrayCard>
    </>
  );
};

export default Integration;
