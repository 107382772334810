export const ADD_OFFER = 'ADD_OFFER';
export const DELETE_OFFER = 'DELETE_OFFER';
export const EDIT_OFFER = 'EDIT_OFFER';
export const SUBMIT_OFFERS_REQUEST = 'SUBMIT_OFFERS_REQUEST';
export const SUBMIT_OFFERS_SUCCESS = 'SUBMIT_OFFERS_SUCCESS';
export const SUBMIT_OFFERS_FAILURE = 'SUBMIT_OFFERS_FAILURE';
export const GET_ADMIN_OFFERS_REQUEST = 'GET_ADMIN_OFFERS_REQUEST';
export const GET_ADMIN_OFFERS_SUCCESS = 'GET_ADMIN_OFFERS_SUCCESS';
export const GET_ADMIN_OFFERS_FAILURE = 'GET_ADMIN_OFFERS_FAILURE';
export const GET_USER_OFFERS_REQUEST = 'GET_USER_OFFERS_REQUEST';
export const GET_USER_OFFERS_SUCCESS = 'GET_USER_OFFERS_SUCCESS';
export const GET_USER_OFFERS_FAILURE = 'GET_USER_OFFERS_FAILURE';
export const APPROVE_OFFERS_REQUEST = 'APPROVE_OFFERS_REQUEST';
export const APPROVE_OFFERS_SUCCESS = 'APPROVE_OFFERS_SUCCESS';
export const APPROVE_OFFERS_FAILURE = 'APPROVE_OFFERS_FAILURE';
export const APPROVE_OFFER_BY_CREDIT_TEAM_REQUEST =
  'APPROVE_OFFERS_BY_CREDIT_TEAM_REQUEST';
export const APPROVE_OFFER_BY_CREDIT_TEAM_SUCCESS =
  'APPROVE_OFFERS_BY_CREDIT_TEAM_SUCCESS';
export const APPROVE_OFFER_BY_CREDIT_TEAM_FAILURE =
  'APPROVE_OFFERS_BY_CREDIT_TEAM_FAILURE';
export const REJECT_OFFERS_REQUEST = 'REJECT_OFFERS_REQUEST';
export const REJECT_OFFERS_SUCCESS = 'REJECT_OFFERS_SUCCESS';
export const REJECT_OFFERS_FAILURE = 'REJECT_OFFERS_FAILURE';
export const RETURN_OFFERS_REQUEST = 'RETURN_OFFERS_REQUEST';
export const RETURN_OFFERS_SUCCESS = 'RETURN_OFFERS_SUCCESS';
export const RETURN_OFFERS_FAILURE = 'RETURN_OFFERS_FAILURE';
export const ACCEPT_OFFER_REQUEST = 'ACCEPT_OFFER_REQUEST';
export const ACCEPT_OFFER_SUCCESS = 'ACCEPT_OFFER_SUCCESS';
export const ACCEPT_OFFER_FAILURE = 'ACCEPT_OFFER_FAILURE';
export const GET_DRAFT_OFFERS_REQUEST = 'GET_DRAFT_OFFERS_REQUEST';
export const GET_DRAFT_OFFERS_SUCCESS = 'GET_DRAFT_OFFERS_SUCCESS';
export const GET_DRAFT_OFFERS_FAILURE = 'GET_DRAFT_OFFERS_FAILURE';
