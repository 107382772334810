import React from 'react';

import _ from 'lodash';
import styled, { css, keyframes } from 'styled-components';

const Wrapper = styled.div`
  /* TODO: the following css make dropdown menu submerged, find another way to make table horizontal scrollable */
  /* display: block;
  overflow-x: auto;
  white-space: nowrap; */
`;

const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  background-color: ${(props) => props.theme.colors.white};
`;

const TR = styled.tr`
  ${(props) =>
    typeof props.onClick === 'function' &&
    css`
      cursor: pointer;
      :hover {
        background-color: ${props.theme.colors.grayOne} !important;
      }
    `}
`;

const TH = styled.th`
  box-sizing: border-box;
  padding: 8px 16px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.grayThree};
  ${(props) => props.css};
`;

const TD = styled.td`
  padding: 4px 16px;
  font-size: 12px;
  border-top: 1px solid ${(props) => props.theme.colors.grayOne};
  box-sizing: border-box;
  ${(props) => props.css};

  ${(props) =>
    typeof props.onClick === 'function' &&
    css`
      cursor: pointer;
      :hover {
        background-color: ${props.theme.colors.grayOne} !important;
      }
    `}
`;

const loading = keyframes`
  100% {
      transform: translateX(100%);
  }
`;

const LoadingSkeleton = styled.div`
  height: 16px;
  width: 100%;
  position: relative;
  background-color: ${(props) => props.theme.colors.grayOne};
  border-radius: 3px;
  :after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100px);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
    animation: ${loading} 0.8s infinite;
  }
`;

const Table = ({
  className,
  columns = [],
  data = [],
  showSkeleton = true,
  isLoading,
  useCachedDataWhenLoading = false,
  pagination = { pageSize: 10, pageNumber: 1, pageCount: 1 },
  onRowClick,
  CollapseContent,
  refreshData = () => {},
  parentProps,
  disabled,
}) => {
  const clickRow = (rowData) => (event) => {
    onRowClick(rowData);
  };

  return (
    <Wrapper>
      <StyledTable className={className}>
        <thead>
          <TR>
            {columns.map(({ Header, className: columnClassName, getStyle = () => ({}) }) => {
              return (
                <TH className={[columnClassName, 'th'].join('')} css={getStyle({})}>
                  {typeof Header === 'string' ? Header : typeof Header === 'function' ? Header({ data }) : ''}
                </TH>
              );
            })}
          </TR>
        </thead>
        <tbody>
          {isLoading && !useCachedDataWhenLoading && showSkeleton ? (
            (() => {
              const skeletonArray = ['skeleton'];
              return skeletonArray.map((da, index) => (
                <TR key={`skeleton${index}`}>
                  {columns.map(({ className: columnClassName, getStyle = () => ({}) }) => {
                    return (
                      <TD className={columnClassName} css={getStyle({})}>
                        <LoadingSkeleton />
                      </TD>
                    );
                  })}
                </TR>
              ));
            })()
          ) : Array.isArray(data) && data.length > 0 ? (
            data.map((datum, index) => {
              return (
                <>
                  <TR {...(typeof onRowClick === 'function' && { onClick: () => {} })}>
                    {columns.map(
                      ({
                        accessor,
                        Cell = ({ value }) => value || '-',
                        className: columnClassName,
                        applyOnRowClick = true,
                        getStyle = () => ({}),
                      }) => {
                        const rowParam = {
                          value: datum[accessor] || _.get(datum, accessor),
                          row: datum,
                          index,
                          parentProps,
                          disabled,
                        };

                        const callback = { refreshData };

                        return (
                          <TD
                            className={[columnClassName, 'td'].join('')}
                            css={getStyle(rowParam)}
                            {...(typeof onRowClick === 'function' && {
                              onClick: applyOnRowClick ? clickRow(datum) : undefined,
                            })}
                          >
                            {Cell(rowParam, callback)}
                          </TD>
                        );
                      }
                    )}
                  </TR>
                  {CollapseContent && (
                    <tr>
                      <td colSpan={columns.length}>
                        <CollapseContent {...datum} />
                      </td>
                    </tr>
                  )}
                </>
              );
            })
          ) : (
            <TR>
              {columns.map(({ className: columnClassName, getStyle = () => ({}) }) => (
                <TD className={columnClassName} css={getStyle({})}>
                  -
                </TD>
              ))}
            </TR>
          )}
        </tbody>
      </StyledTable>
    </Wrapper>
  );
};

export default Table;
