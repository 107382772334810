import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import AlertCard from '../../../../../../components/AlertCard';
import Button from '../../../../../../components/Button';
import CircularProgressBar from '../../../../../../components/CircularProgressBar';
import COLORS from '../../../../../../styles/colors';
import Logger from '../../../../../../utils/logger';
import { Card } from '../../../../components';
import PATH from '../../../../path';
import { Footer } from "../../../Transfer/CreateTransferPage/components";
import { formatFXRatio } from '../../../Transfer/CreateTransferPage/helpers';
import { createWalletConversion } from '@api/modules/integration/airwallex';
import { useCountdown } from '@hooks/UI';
import { fetchForeignExchange } from '@redux/modules/wallet/actions';
import { useWalletAccount, useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';
import { media } from '@styles/breakpoints';
import { convertToFullDate, convertToFullDateAndTime, convertToApiDate } from '@utils/dateHelpers';
import { formatPrice } from '@utils/priceHelpers';
import { convertArrayToObject } from '@utils/dataTypes';

const ReviewFlowInfoPadding = css`
  padding: 0px 0 10px 16px;
`;

const StepsWrapper = styled.div`
  margin: 2px 0;
`;

const ReviewFlowInfoWrapper = styled.div`
  margin-left: 20px;
  ${ReviewFlowInfoPadding};
  border-left: 1px dashed ${(props) => props.theme.colors.grayTwo};

  ${StepsWrapper} {
    position: relative;
    .circle {
      margin: 8px;
      width: 20px;
      height: 18px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.white};
      position: absolute;
      left: -31px;
      top: -5px;
      div {
        position: absolute;
        top: -20%;
        right: 22%;
        width: 15px;
        height: 15px;
        background-color: ${(props) => props.theme.colors.white};
        border: 1.5px solid ${(props) => props.theme.colors.grayTwo};
        border-radius: 50%;
      }
    }
  }
`;

const FormWrapper = styled(Card)`
  width: 66%;
  box-sizing: border-box;
  ${media.desktop`
    width: 100%;
  `}
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const Title = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
`;

const Description = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: 600;
`;

const StepsHeader = styled.div`
  ${(props) => props.theme.text.micro};
  font-weight: 600;
`;

const ButtonWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: start;
`;

const Container = styled.div`
  margin-top: 40px;
`;

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.error};
`;

const AvailableAmount = styled.div`
  color: ${(props) => props.theme.colors.grayThree};
  ${(props) => props.theme.text.micro};
`;

const ReviewConversionForm = ({ onClick = () => {}, formProps }) => {
  const history = useHistory();
  const walletAccount = useWalletAccount();
  const foreignExchange = useSelector((state) => state.wallet.foreignExchange) || {};
  const {
    control,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
    handleSubmit,
    formState: { errors },
  } = formProps;

  const [isLoading, toggleIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const formInfo = getValues();
  const currencyBalances = useSelector((state) => state.wallet.currencyBalances);
  const currencyBalanceMap = convertArrayToObject(currencyBalances, 'currency');
  const isAdminViewingAnotherCompany = useCheckIsAdminViewingAnotherCompany();

  const selectedSourceBalance = currencyBalanceMap[formInfo?.sourceCurrency];
  const selectedConvertBalance = currencyBalanceMap[formInfo?.convertCurrency];

  const sourceToPaymentFX = foreignExchange[`${formInfo?.sourceCurrency}to${formInfo?.convertCurrency}`];

  const { remainingTime } = useCountdown({
    startTime: sourceToPaymentFX?.valid_from,
    endTime: sourceToPaymentFX?.valid_to,
  });

  useEffect(() => {
    if (
      remainingTime === 0 &&
      formInfo?.sourceCurrency &&
      formInfo?.convertCurrency &&
      formInfo?.sourceCurrency !== formInfo?.convertCurrency
    ) {
      fetchForeignExchange(walletAccount?.account_id, {
        fromCurrency: formInfo?.sourceCurrency,
        toCurrency: formInfo?.convertCurrency,
        amount: 10000,
      });
      fetchForeignExchange(walletAccount?.account_id, {
        fromCurrency: formInfo?.convertCurrency,
        toCurrency: formInfo?.sourceCurrency,
        amount: 10000,
      });
    }
  }, [remainingTime]);

  const onSubmit = async (formData) => {
    toggleIsLoading(true);
    setApiError('');
    try {
      const payload = {
        buy_amount: formData.convertAmount,
        buy_currency: formData.convertCurrency,
        conversion_date: convertToApiDate(formData.conversionDate),
        quote_id: sourceToPaymentFX.quote_id,
        reason: 'Balance Conversion/Transfer',
        sell_currency: formData.sourceCurrency,
        term_agreement: 1,
      };

      const { data, statusCode } = await createWalletConversion(walletAccount?.account_id, payload);
      if (statusCode === 200) {
        history.push(PATH.WALLET_CONVERSION);
      } else {
        setApiError('Cannot create transfer, please double check amount is valid');
        throw new Error(`Error ${statusCode}: cannot create transfer`);
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Title>Review conversion</Title>
      <FormWrapper>
        <ReviewFlowInfoWrapper>
          <StepsWrapper>
            <div className="circle">
              <div />
            </div>
          </StepsWrapper>
          <StepsHeader scale={5}>From amount</StepsHeader>
          <Description scale={5}>{formatPrice(formInfo?.sourceCurrency, formInfo?.sourceAmount, false, 2)}</Description>
          {selectedSourceBalance && (
            <AvailableAmount>
              {'Available balance: '}
              <b>{formatPrice(formInfo?.sourceCurrency, selectedSourceBalance?.total_amount)}</b>
            </AvailableAmount>
          )}
          <Container>
            <StepsWrapper>
              <div className="circle">
                <div />
              </div>
              <StepsHeader scale={5}>
                {formatFXRatio({
                  foreignExchange: sourceToPaymentFX,
                  sourceCurrency: formInfo?.sourceCurrency,
                  paymentCurrency: formInfo?.convertCurrency,
                })}
              </StepsHeader>
              <Footer>
                <div>{convertToFullDateAndTime(sourceToPaymentFX?.valid_from)}</div>
                <CircularProgressBar
                  total={moment(sourceToPaymentFX?.valid_to).diff(sourceToPaymentFX?.valid_from, 'second')}
                  lines={[
                    {
                      value: remainingTime,
                      color: COLORS.purple,
                      id: 'countdown',
                    },
                  ]}
                  strokeWidth={2}
                  radius={4}
                  strokeLinecap="square"
                />
              </Footer>
            </StepsWrapper>
          </Container>
          <Container>
            <StepsWrapper>
              <div className="circle">
                <div />
              </div>
              <StepsHeader scale={5}>To amount</StepsHeader>
              <Description scale={5}>{formatPrice(formInfo?.convertCurrency, formInfo?.convertAmount, false, 2)}</Description>
              {selectedConvertBalance && (
                <AvailableAmount>
                  {'Available balance: '}
                  <b>{formatPrice(formInfo?.convertCurrency, selectedConvertBalance?.total_amount)}</b>
                </AvailableAmount>
              )}
            </StepsWrapper>
          </Container>
          <Container>
            <StepsWrapper>
              <div className="circle">
                <div />
              </div>
              <StepsHeader scale={5}>Conversion date</StepsHeader>
              <Description scale={5}>{convertToFullDate(formInfo?.conversionDate)}</Description>
            </StepsWrapper>
          </Container>
        </ReviewFlowInfoWrapper>
        <AlertCard backgroundColor={COLORS.grayOne} title="Once confirmed, the conversion cannot be cancelled." />
        <ButtonWrapper>
          <StyledButton onClick={onClick} tertiary disabled={isLoading}>
            Back
          </StyledButton>
          <StyledButton disabled={isLoading || isAdminViewingAnotherCompany}>Confirm conversion</StyledButton>
        </ButtonWrapper>
        {apiError && <ErrorMessage>{apiError}</ErrorMessage>}
      </FormWrapper>
    </form>
  );
};

export default ReviewConversionForm;
