import { CATEGORY, PLATFORM_MAP } from '@constants/platform';

export const CATEGORY_BANK = CATEGORY.ACCOUNTING; // TODO: use CATEGORY.ACCOUNTING directly
export const CATEGORY_OTHER_BANK = 'other_bank_accounts';

export const CHANNELS_OPTIONS = [
  {
    value: CATEGORY.SALES,
    label: 'Sales Channel (e.g. Shopify)',
  },
  {
    value: CATEGORY.PAYMENT,
    label: 'Payment Channel (e.g. Stripe)',
  },
  {
    value: CATEGORY.ANALYTICS,
    label: 'Analytics Platform (e.g. Google Analytics)',
  },
  {
    value: CATEGORY_BANK,
    label: 'Banks (e.g. Xero)',
  },
];

export const ChannelMap = {
  ...PLATFORM_MAP,
  otherBankAccounts: {
    displayName: 'Other bank accounts',
    category: CATEGORY_OTHER_BANK,
  },
};
