import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useTable } from 'react-table';
import styled from 'styled-components';

import FancyDateRangePicker from '../../../../components/FancyDateRangePicker';
import HR from '../../../../components/HR';
import LoadingCircle from '../../../../components/LoadingCircle';
import SimplePagination from '../../../../components/Pagination/SimplePagination';
import Select from '../../../../components/Select';
import Logger from '../../../../utils/logger';
import { Wrapper } from '../../components';
import { TD, PageHeader } from '../components';
import { getColorByStripeStatus } from '../helpers';
import BalanceCard from './BalanceCard';
import { tableColumns, balanceStatusOptions } from './constants';
import { OPTION_VALUE_ALL } from '@constants/options';
import { usePagination } from '@hooks/UI';
import { Currency } from '@/constants';
import { fetchPayouts } from '@redux/modules/payment/actions';
import { isRealNumber } from '@utils/dataTypes';
import { convertToApiDate } from '@utils/dateHelpers';
import { areRecordsFewerThanPageSize } from '@utils/utilHelpers';
import { getStripeOverviewData } from '@api/modules/integration';

const SelectWrapper = styled.div`
  margin-bottom: 36px;
  text-align: right;
`;

const FilterWrapper = styled.span`
  display: inline-block;
  margin-left: 16px;
`;

const PayoutsHeader = styled.div`
  ${(props) => props.theme.text.four};
`;

const Title = styled.div`
  ${(props) => props.theme.text.five};
  text-align: left;
`;

const BaseSelect = styled(Select)`
  width: 180px;
`;

const DateFilterWrapper = styled(FilterWrapper)`
  text-align: left;
`;

const Table = styled.table`
  ${(props) => props.theme.table};
`;

const StyledLoadingCircle = styled(LoadingCircle)`
  text-align: center;
  margin: 16px;
`;

const EndLabel = styled.div`
  text-align: center;
`;

const PayoutDetails = () => {
  const { isLoading, payoutPageMap } = useSelector((state) => state.payment);
  const [currency, setCurrency] = useState(Currency.USD);
  const [payoutBalance, setPayoutBalance] = useState();
  const [futurePayouts, setFuturePayouts] = useState();
  const [statusFilter, setStatusFilter] = useState(OPTION_VALUE_ALL);
  const [methodFilter, setMethodFilter] = useState(OPTION_VALUE_ALL);
  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');
  const [dateShortcutValue, setDateShortcutValue] = useState('');
  const { pageNumber, pageSize, pageCount, onPageNumberChange, onPageSizeChange, changePageCount } = usePagination({
    pageSize: 20,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data: payoutPageMap[pageNumber] || [],
    columns: tableColumns,
  });

  const formatPageNavLabel = (number) => {
    return ` ${number} `;
  };

  const applyCalendar = (calendarData) => {
    setStartDateFilter(calendarData.startDate);
    setEndDateFilter(calendarData.endDate);
    setDateShortcutValue(calendarData.dateShortcutSelectValue);
  };

  const searchPayments = () => {
    const filters = [];
    if (statusFilter && statusFilter !== OPTION_VALUE_ALL) {
      filters.push({ field: 'status', operator: 'eq', value: statusFilter });
    }
    fetchPayouts({
      pageNumber,
      pageSize,
      ...(startDateFilter && { startDate: convertToApiDate(startDateFilter) }),
      ...(endDateFilter && { endDate: convertToApiDate(endDateFilter) }),
      filters,
    });
  };

  const fetchBalance = async () => {
    try {
      const { data = {}, error } = await getStripeOverviewData('today');

      if (error) {
        return;
      }
      const currencies = Object.keys(data);

      if (Array.isArray(currencies) && currencies.length > 0) {
        setPayoutBalance(data[currencies].pendingPayout);
        setFuturePayouts(data[currencies].futurePayout);
        setCurrency(data[currencies].currency);
      }
    } catch (err) {
      Logger.error(err);
    }
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  useEffect(() => {
    searchPayments();
  }, [pageNumber]);

  useEffect(() => {
    searchPayments();
    onPageNumberChange(1);
  }, [statusFilter, methodFilter, startDateFilter, endDateFilter, pageSize]);

  const noNextPage = areRecordsFewerThanPageSize(payoutPageMap[pageNumber], pageSize);

  return (
    <>
      <PageHeader>Balances</PageHeader>
      <Wrapper>
        {isRealNumber(payoutBalance) && isRealNumber(futurePayouts) && (
          <>
            <BalanceCard currency={currency} payoutBalance={payoutBalance} futurePayouts={futurePayouts} />
            <HR style={{ opacity: 0.3 }} />
          </>
        )}
        <PayoutsHeader>Payouts</PayoutsHeader>
        <SelectWrapper>
          <FilterWrapper>
            <Title>Status</Title>
            <BaseSelect value={statusFilter} onChange={setStatusFilter} options={balanceStatusOptions} disabled={isLoading} />
          </FilterWrapper>
          <DateFilterWrapper>
            <Title> </Title>
            <FancyDateRangePicker
              startDate={startDateFilter}
              endDate={endDateFilter}
              dateShortcutValue={dateShortcutValue}
              onDateRangeSelectValueChange={setDateShortcutValue}
              onApply={applyCalendar}
              disabled={isLoading}
            />
          </DateFilterWrapper>
        </SelectWrapper>
        {isLoading ? (
          <StyledLoadingCircle />
        ) : (
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th key={column.id} className={column.className} {...column.getHeaderProps}>
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <TD
                            key={cell.column.id}
                            className={cell.column.className}
                            {...cell.getCellProps()}
                            color={getColorByStripeStatus(cell.value)}
                          >
                            {cell.render('Cell')}
                          </TD>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  {tableColumns.map((column) => {
                    return <td className={column.className}>-</td>;
                  })}
                </tr>
              )}
            </tbody>
          </Table>
        )}
        <SimplePagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          pageSizeOptions={[
            {
              value: 20,
              label: 20,
            },
            {
              value: 50,
              label: 50,
            },
            {
              value: 100,
              label: 100,
            },
          ]}
          onPageNumberChange={onPageNumberChange}
          onPageSizeChange={onPageSizeChange}
          formatPageNavLabel={formatPageNavLabel}
          disabled={isLoading}
          isLoading={isLoading}
          noNextPage={noNextPage}
        />
      </Wrapper>
    </>
  );
};

export default PayoutDetails;
