import { css, FlattenSimpleInterpolation } from 'styled-components';

type TSize = {
  [label: string]: number;
}

export const sizes: TSize = {
  // Large devices (desktops, under 1024)
  desktop: 1023,
  // Medium devices (dablets & ipad, under 869px)
  dablet: 868,
  // Small devices (landscape phones, under 576px)
  phone: 575,
};

// iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((accumulator, label: string) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16;
  accumulator[label] = (args: any) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {} as Record<string | number | symbol, (args: TemplateStringsArray) => FlattenSimpleInterpolation>);
