import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AlertCard from '../../../../components/AlertCard';
import Button from '../../../../components/Button';
import FancyDateRangePicker from '../../../../components/FancyDateRangePicker';
import HR from '../../../../components/HR';
import Logger from '../../../../utils/logger';
import { configOfCharts } from './constants';
import Onboarding from './Onboarding/';
import { getStripeOverviewData } from '@api/modules/integration';
import SummaryChart from './SummaryChart';
import { GridColumn } from '@components/Grid';
import { SUPPORT_EMAIL } from '@constants/email';
import { Currency } from '@/constants';
import { LAST_WEEK_OPTION } from '@constants/options';
import PATH from '../../path';
import LoadingCircle from '../../../../components/LoadingCircle';
import { Wrapper, Card } from '../../components';
import { media } from '@styles/breakpoints';
import { getDateMomentsByShortcut, convertToFullDate } from '@utils/dateHelpers';
import { PageHeader } from '../components';
import { ALERT_CARD_COLOR } from '@styles/colors';
import { formatPrice } from '@utils/priceHelpers';

const SectionHeader = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
  margin-bottom: 12px;
`;

const ButtonWrapper = styled.span`
  float: right;
`;

const StyledFancyDateRangePicker = styled(FancyDateRangePicker)`
  .portal {
    right: initial;
  }
`;

const StyledGridColumn = styled(GridColumn)`
  padding: 0px 26px 0px 0px;
`;

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const CardDataWrapper = styled.div`
  display: flex;
  gap: 60px;
`;

const StyledButton = styled(Button)`
  vertical-align: middle;
  margin-left: 4px;

  ${media.desktop`
    margin: 20px 2px;
  `};
`;

const CardHeader = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  padding-bottom: 6px;
  font-weight: 600;
`;

const CardData = styled.div`
  ${(props) => props.theme.text.four};
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
`;

const PrecedingPeriod = styled.span`
  padding-left: 10px;
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  font-weight: 600;
`;

const LinkLabel = styled.a`
  ${(props) => props.theme.text.link};
  font-size: 14px;
`;

const Overview = () => {
  const history = useHistory();
  const paymentAccounts = useSelector((state) => state.payment.stripeAccounts);
  const dateMoments = getDateMomentsByShortcut(LAST_WEEK_OPTION.value, true);
  const [startDate, setStartDate] = useState(dateMoments.startDateMoment);
  const [endDate, setEndDate] = useState(dateMoments.endDateMoment);
  const [dateShortcutValue, setDateShortValue] = useState(LAST_WEEK_OPTION.value);
  const [currency, setCurrency] = useState(Currency.USD);
  const [payoutBalance, setPayoutBalance] = useState(0);
  const [futurePayouts, setFuturePayouts] = useState(0);
  const [isLoading, toggleIsLoading] = useState(true);
  const [isStripeReady, toggleIsStripeReady] = useState(false);
  const [isKycRequired, toggleKycRequired] = useState(false);

  const dateDifference = moment.duration(endDate.diff(startDate)).asDays();

  const precedingStartDate = moment(startDate).subtract(dateDifference + 1, 'days');
  const precedingEndDate = moment(precedingStartDate).add(dateDifference, 'days');

  const applyCalendar = (calendarData) => {
    setStartDate(calendarData.startDate);
    setEndDate(calendarData.endDate);
    setDateShortValue(calendarData.dateShortcutSelectValue);
  };

  const fetchApi = async () => {
    try {
      const { data = {}, error } = await getStripeOverviewData('today');

      if (error) {
        return;
      }
      const currencies = Object.keys(data);

      if (Array.isArray(currencies) && currencies.length > 0) {
        setPayoutBalance(data[currencies].pendingPayout);
        setFuturePayouts(data[currencies].futurePayout);
        setCurrency(data[currencies].currency);
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  useEffect(() => {
    if (paymentAccounts.length > 0 && paymentAccounts?.[0]?.charges_enabled === 1) {
      toggleIsStripeReady(true);
    }
    if (paymentAccounts?.[0]?.required_kyc) {
      toggleKycRequired(true);
    }
  }, [JSON.stringify(paymentAccounts)]);

  useEffect(() => {
    fetchApi();
  }, []);

  if (isLoading) {
    return <LoadingCircle />;
  }

  return (
    <>
      {isKycRequired && (
        <AlertCard
          title="Verify your account"
          description={
            <>
              We ran into some issue verifying your account details. Charges and payouts are paused until you resolve the issue. Please
              contact us by sending an email to <LinkLabel href={`mailto: ${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</LinkLabel>
            </>
          }
          backgroundColor={ALERT_CARD_COLOR.redBackground}
          border={`1px solid ${ALERT_CARD_COLOR.redBorder}`}
          bottom
        />
      )}
      <PageHeader>Overview</PageHeader>
      <Wrapper>
        {isStripeReady ? (
          <>
            <SectionHeader>Today</SectionHeader>
            <Card>
              <CardWrapper>
                <CardDataWrapper>
                  <div>
                    <CardHeader>Outgoing payouts</CardHeader>
                    <CardData>{formatPrice(currency, payoutBalance, false, 2)}</CardData>
                  </div>
                  <div>
                    <CardHeader>Estimated future payouts</CardHeader>
                    <CardData>{formatPrice(currency, futurePayouts, false, 2)}</CardData>
                  </div>
                </CardDataWrapper>
                <ButtonWrapper>
                  <StyledButton secondary onClick={() => history.push(PATH.PAYMENT_PAYMENTS)}>
                    View All Payments
                  </StyledButton>
                  <StyledButton secondary onClick={() => history.push(PATH.PAYMENT_BALANCES)}>
                    View Balances
                  </StyledButton>
                </ButtonWrapper>
              </CardWrapper>
            </Card>
            <HR color="grayTwo" />
            <SectionHeader>Reports</SectionHeader>
            <StyledFancyDateRangePicker
              startDate={startDate}
              endDate={endDate}
              dateShortcutValue={dateShortcutValue}
              onDateRangeSelectValueChange={setDateShortValue}
              onApply={applyCalendar}
              includeToday
            />
            <PrecedingPeriod>
              Compared to {convertToFullDate(precedingStartDate)} -{'  '}
              {convertToFullDate(precedingEndDate)}
            </PrecedingPeriod>
            <div>
              {configOfCharts.map((data) => {
                return (
                  <StyledGridColumn lg={4} m={6}>
                    <SummaryChart
                      startDate={startDate}
                      endDate={endDate}
                      precedingStartDate={precedingStartDate}
                      precedingEndDate={precedingEndDate}
                      dateShortcutValue={dateShortcutValue}
                      {...data}
                    />
                  </StyledGridColumn>
                );
              })}
            </div>
          </>
        ) : (
          <Onboarding />
        )}
      </Wrapper>
    </>
  );
};

export default Overview;
