import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import CheckBox, { CheckboxText } from '../../../../components/Checkbox';
import { Description } from '../../../Application/components';
import { MANUAL_INTEGRATION_FORM } from '../../../Modal/constants';
import { GridColumn } from '@components/Grid';
import { DD_EMAIL } from '@constants/email';
import { DD_USER_ID } from '@/constants';
import { PLATFORM, PLATFORM_DISPLAY_NAME } from '@constants/platform';
import { showModal, hideModal } from '@redux/modules/UI/actions';

const Title = styled.div`
  ${(props) => props.theme.text.two};
  margin: 10px 0 20px 0;
`;

const Link = styled.a`
  ${(props) => props.theme.text.link};
  text-decoration: underline;
  font-weight: bold;
`;
const InstructionTitle = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: bold;
  margin: 20px 0;
`;

const InstructionsImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

const PayPal = () => {
  const dispatch = useDispatch();
  const [isChecked, toggleIsChecked] = useState(false);

  const handleIntegrationClick = () => {
    dispatch(
      showModal(MANUAL_INTEGRATION_FORM, {
        platformName: PLATFORM.PAYPAL,
      })
    );
  };

  return (
    <>
      <Title>Connect {PLATFORM_DISPLAY_NAME.PAYPAL} Account</Title>
      <Description>Please follow below steps to add access to your account</Description>
      <InstructionTitle>Step 1</InstructionTitle>
      <Description>
        Go to{' '}
        <Link href="https://www.paypal.com/businessmanage/account/accountAccess" target="_blank">
          Account access
        </Link>{' '}
      </Description>
      <Description>
        Click <b>Update</b> in the &quot;Manage Users&quot; section.
      </Description>
      <InstructionsImage src="https://asset.choco-up.com/tutorial/paypal/PayPal-1.png" alt="Connect PayPal Account" />
      <InstructionTitle>Step 2</InstructionTitle>
      <Description>
        Please use information given below to fill in the new user form
        <ul>
          <li>First name: Choco</li>
          <li>Last name: Up</li>
          <li>Email: {DD_EMAIL}</li>
          <li>User ID: {DD_USER_ID}</li>
          <li>Date of birth: 1990-01-01</li>
          <li>Address: 6/F Benefit IND Factory Building, 59-61 Wong Chuk Hang Road, Wong Chuk Hang, Hong Kong Island</li>
          <li>District: Hong Kong</li>
          <li>Region: Hong Kong Island</li>
        </ul>{' '}
        <b>Click Next</b>
      </Description>
      <InstructionsImage src="https://asset.choco-up.com/tutorial/paypal/PayPal-3.png" alt="Connect PayPal Account" />
      <InstructionTitle>Step 3</InstructionTitle>
      <Description>
        Please grant us with the permissions below:
        <ul>
          <li>Access APIs and developer support site</li>
          <li>Access user audit report</li>
          <li>Access report</li>
          <li>See balance, access money movement</li>
          <li>See insights</li>
        </ul>{' '}
        <b>Click Continue</b>
      </Description>
      <InstructionsImage src="https://asset.choco-up.com/tutorial/paypal/PayPal-4.png" alt="Connect PayPal Account" />
      <GridColumn lg={12} md={12}>
        <CheckBox onClick={() => toggleIsChecked(!isChecked)} />
        <CheckboxText>Confirm I have connected with my {PLATFORM_DISPLAY_NAME.PAYPAL} account</CheckboxText>
      </GridColumn>
      <GridColumn lg={6} md={6}>
        <Button onClick={handleIntegrationClick} disabled={!isChecked} primary full>
          Next
        </Button>
      </GridColumn>
      <GridColumn lg={6} md={6}>
        <Button onClick={() => dispatch(hideModal())} tertiary full>
          Cancel
        </Button>
      </GridColumn>
    </>
  );
};

export default PayPal;
