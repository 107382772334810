import React, { useEffect, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { ReactMultiEmail } from 'react-multi-email';
import { useHistory } from 'react-router-dom';
import 'react-multi-email/dist/style.css';
import styled, { css } from 'styled-components';

import Button from '../../../../../components/Button';
import FormTitle from '../../../../../components/FormTitle';
import COLORS from '../../../../../styles/colors';
import Logger from '../../../../../utils/logger';
import ControlledSelect from '../../../../ReactHookForm/ControlledSelect';
import { Card } from '../../../components';
import PATH from '../../../path';
import { getRoles, inviteNewUsers } from '@api/modules/dashboard';
import { FormRow, GridColumn } from '@components/Grid';
import { Error } from '@modules/Authentication/components/styled';
import { useSelectedCompanyId } from '@redux/selectors';
import { emailRegExp } from '@utils/validators';

const CardTitle = styled.span`
  ${(props) => props.theme.text.three};
  font-weight: 600;
`;

const StyledReactMultiEmail = styled(ReactMultiEmail)`
  ${(props) => props.theme.text.five};
  padding: 0.6rem 0.8rem;
  overflow-wrap: anywhere;

  ${(props) =>
    props.hasError &&
    css`
      border: 1px solid ${COLORS.red};
      box-shadow: inset 1px 0 ${COLORS.red}, inset 0 1px ${COLORS.red}, inset -1px 0 ${COLORS.red}, inset 0 -1px ${COLORS.red};
      color: ${COLORS.red};
    `}

  > input {
    width: 100% !important;
  }

  :focus-within {
    ${(props) => props.theme.input.borderFocus};
  }
`;

export const ErrorMessage = styled.div`
  margin-top: 15px;
  font-size: 15px;
  line-height: 25px;
  color: ${(props) => props.theme.colors.red};
`;

const UserInviteForm = () => {
  const history = useHistory();
  const selectedCompanyId = useSelectedCompanyId();
  const [isLoading, toggleIsLoading] = useState();
  const [errorMsg, setErrorMsg] = useState('');
  const [roleOptions, setRoleOptions] = useState();

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const { data } = await getRoles();
        if (data) {
          const options = data.map(({ role_id, role_name }) => {
            return { value: role_id, label: role_name };
          });
          setRoleOptions(options);
        }
      } catch (err) {
        Logger.error(err);
      }
    };
    fetchApi();
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const handleCancel = () => {
    history.push(PATH.SETTINGS_USER_MANAGEMENT);
  };

  const onSubmit = async ({ role, emails }) => {
    try {
      const { data, statusCode, error } = await inviteNewUsers(selectedCompanyId, role, emails);
      if (statusCode === 409) {
        setErrorMsg('User already exists');
      } else if (statusCode === 201) {
        history.push(PATH.SETTINGS_USER_MANAGEMENT);
      }
    } catch (err) {
      Logger.Error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  return (
    <>
      <CardTitle>Invite new user</CardTitle>
      <Card>
        <GridColumn lg={8} md={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormRow>
              <FormTitle scale={5} required>
                Work Email Address
              </FormTitle>
              <Controller
                name="emails"
                control={control}
                // todo: fix long term to check each email that is passed through
                rules={{ required: true, pattern: emailRegExp }}
                render={({ field }) => (
                  <StyledReactMultiEmail
                    {...field}
                    placeholder="Please enter user's email"
                    emails={field.value}
                    hasError={errors.emails}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          {email}
                          <span data-tag-handle onClick={() => removeEmail(index)}>
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                )}
              />
              {errors.emails?.type === 'required' && <Error>Please enter valid email</Error>}
              <ErrorMessage>{errorMsg}</ErrorMessage>
            </FormRow>
            <FormRow>
              <FormTitle scale={5} required>
                Role
              </FormTitle>
              <ControlledSelect
                name="role"
                control={control}
                rules={{
                  required: true,
                }}
                options={roleOptions}
                disabled={isLoading}
              />
            </FormRow>
            <FormRow>
              <GridColumn lg={6} md={6}>
                <Button type="submit" disabled={isLoading} full>
                  Send invite
                </Button>
              </GridColumn>
              <GridColumn lg={6} md={6}>
                <Button onClick={handleCancel} disabled={isLoading} tertiary full>
                  Cancel
                </Button>
              </GridColumn>
            </FormRow>
          </form>
        </GridColumn>
      </Card>
    </>
  );
};

export default UserInviteForm;
