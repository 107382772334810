import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useLocation, Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import LoadingCircle from '../../../../components/LoadingCircle';
import PATH from '../../path';
import { Header } from '../components';
import BalanceSheet from './BalanceSheet';
import TabsFilter from './component/TabsFilter';
import { ACCOUNTING_TAB_OPTIONS, TAB } from './constant';
import ProfitLoss from './ProfitLoss';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.grayOne};
  padding: 24px 18px;
`;

const AccountingReport = () => {
  const history = useHistory();

  const handleTabChange = (value) => {
    history.push(value);
  };

  return (
    <>
      <Header>Accounting Report</Header>
      <Wrapper>
        <TabsFilter tabOptions={ACCOUNTING_TAB_OPTIONS} onCategoryChange={handleTabChange} />
        <Switch>
          <Route exact path={PATH.BUSINESS_ACCOUNTING_REPORT_BALANCE_SHEET} component={BalanceSheet} />
          <Route exact path={PATH.BUSINESS_ACCOUNTING_REPORT_PROFIT_LOSS} component={ProfitLoss} />
          <Redirect to={PATH.BUSINESS_ACCOUNTING_REPORT_PROFIT_LOSS} />
        </Switch>
      </Wrapper>
    </>
  );
};

export default AccountingReport;
