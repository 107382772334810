import React, { ReactNode } from 'react';

import styled, { css } from 'styled-components';

import COLORS from '@styles/colors';

const HeaderTwo = styled.h2`
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  margin: 0;
  ${(props) =>
    props.highlight &&
    css`
      color: ${COLORS.purple};
    `};

  ${(props) =>
    props.color &&
    css`
      color: ${COLORS[props.color] || COLORS.black};
    `};
`;

const HeaderThree = styled.h3`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  ${(props) =>
    props.highlight &&
    css`
      color: ${COLORS.purple};
    `};

  ${(props) =>
    props.color &&
    css`
      color: ${COLORS[props.color] || COLORS.black};
    `};
`;

const HeaderFour = styled.h4`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  ${(props) =>
    props.highlight &&
    css`
      color: ${COLORS.purple};
    `};

  ${(props) =>
    props.color &&
    css`
      color: ${COLORS[props.color] || COLORS.black};
    `};
`;

const HeaderFive = styled.h3`
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  margin: 0;
  ${(props) =>
    props.highlight &&
    css`
      color: ${COLORS.purple};
    `};

  ${(props) =>
    props.color &&
    css`
      color: ${COLORS[props.color] || COLORS.black};
    `};
`;

interface HeaderProps {
  scale: number;
  children: ReactNode;
  [key: string]: any;
}

// restricted to marketing page only, for search engine optimization in future
// gradually migrate to use theme.text (in theme.js) with styled-components for pages other than that
const Header = ({ scale, children, ...restProps }: HeaderProps) => {
  switch (scale) {
    case 2:
      return <HeaderTwo {...restProps}>{children}</HeaderTwo>;
    case 3:
      return <HeaderThree {...restProps}>{children}</HeaderThree>;
    case 4:
      return <HeaderFour {...restProps}>{children}</HeaderFour>;
    case 5:
    default:
      return <HeaderFive {...restProps}>{children}</HeaderFive>;
  }
};

export default Header;
