import Logger from '../../../utils/logger';
import store from '../../store';
import {
  GET_WALLET_ACCOUNT_REQUEST,
  GET_WALLET_ACCOUNT_SUCCESS,
  GET_WALLET_ACCOUNT_FAILURE,
  GET_WALLET_TRANSACTIONS_REQUEST,
  GET_WALLET_TRANSACTIONS_SUCCESS,
  GET_WALLET_TRANSACTIONS_FAILURE,
  GET_GLOBAL_ACCOUNTS_REQUEST,
  GET_GLOBAL_ACCOUNTS_SUCCESS,
  GET_GLOBAL_ACCOUNTS_FAILURE,
  GET_WALLET_PAYOUTS_REQUEST,
  GET_WALLET_PAYOUTS_SUCCESS,
  GET_WALLET_PAYOUTS_FAILURE,
  GET_WALLET_PAYOUT_DETAIL_REQUEST,
  GET_WALLET_PAYOUT_DETAIL_SUCCESS,
  GET_WALLET_PAYOUT_DETAIL_FAILURE,
  GET_WALLET_BENEFICIARIES_REQUEST,
  GET_WALLET_BENEFICIARIES_SUCCESS,
  GET_WALLET_BENEFICIARIES_FAILURE,
  GET_WALLET_FORM_SCHEMA_REQUEST,
  GET_WALLET_FORM_SCHEMA_SUCCESS,
  GET_WALLET_FORM_SCHEMA_FAILURE,
  GET_GLOBAL_ACCOUNT_TRANSACTION_REQUEST,
  GET_GLOBAL_ACCOUNT_TRANSACTION_SUCCESS,
  GET_GLOBAL_ACCOUNT_TRANSACTION_FAILURE,
  GET_WALLET_CURRENCY_BALANCE_REQUEST,
  GET_WALLET_CURRENCY_BALANCE_SUCCESS,
  GET_WALLET_CURRENCY_BALANCE_FAILURE,
  GET_WALLET_TRANSFER_FEE_REQUEST,
  GET_WALLET_TRANSFER_FEE_SUCCESS,
  GET_WALLET_TRANSFER_FEE_FAILURE,
  GET_WALLET_FOREIGN_EXCHANGE_REQUEST,
  GET_WALLET_FOREIGN_EXCHANGE_SUCCESS,
  GET_WALLET_FOREIGN_EXCHANGE_FAILURE,
  DELETE_WALLET_BENEFICIARY_REQUEST,
  DELETE_WALLET_BENEFICIARY_SUCCESS,
  DELETE_WALLET_BENEFICIARY_FAILURE,
  GET_WALLET_CONVERSION_LIST_REQUEST,
  GET_WALLET_CONVERSION_LIST_SUCCESS,
  GET_WALLET_CONVERSION_LIST_FAILURE,
  GET_WALLET_CARDS_REQUEST,
  GET_WALLET_CARDS_SUCCESS,
  GET_WALLET_CARDS_FAILURE,
  GET_WALLET_CARD_DETAIL_REQUEST,
  GET_WALLET_CARD_DETAIL_SUCCESS,
  GET_WALLET_CARD_DETAIL_FAILURE,
  UPDATE_WALLET_CARD_REQUEST,
  UPDATE_WALLET_CARD_SUCCESS,
  UPDATE_WALLET_CARD_FAILURE,
  GET_WALLET_CARD_TRANSACTIONS_REQUEST,
  GET_WALLET_CARD_TRANSACTIONS_SUCCESS,
  GET_WALLET_CARD_TRANSACTIONS_FAILURE,
  GET_WALLET_ACCOUNT_SETTINGS_REQUEST,
  GET_WALLET_ACCOUNT_SETTINGS_SUCCESS,
  GET_WALLET_ACCOUNT_SETTINGS_FAILURE,
  UPDATE_WALLET_ACCOUNT_SETTINGS_REQUEST,
  UPDATE_WALLET_ACCOUNT_SETTINGS_SUCCESS,
  UPDATE_WALLET_ACCOUNT_SETTINGS_FAILURE,
  UPDATE_CARD_STATUS_REQUEST,
  UPDATE_CARD_STATUS_SUCCESS,
  UPDATE_CARD_STATUS_FAILURE,
} from './constants';
import { getWalletAccounts, getWalletTransactions } from '@api/modules/integration';
import {
  getWalletPayouts,
  getWalletPayoutById,
  getWalletBeneficiaries,
  getGlobalAccounts,
  getGlobalAccountTransactions,
  getWalletBeneficiarySchema,
  getWalletCurrentBalance,
  getWalletTransferFee,
  getWalletFX,
  deleteWalletBeneficiary,
  getWalletConversionList,
  getWalletAllCards,
  updateWalletCard,
  getCardTransactions,
  getWalletCardDetail,
  getAccountSettings,
  updateWalletAccountSettings,
} from '@api/modules/integration/airwallex';

export const fetchWalletData = async () => {
  store.dispatch({ type: GET_WALLET_ACCOUNT_REQUEST });
  try {
    const { data, error } = await getWalletAccounts();

    if (error) {
      throw new Error(error);
    }

    if (Array.isArray(data)) {
      store.dispatch({
        type: GET_WALLET_ACCOUNT_SUCCESS,
        payload: data,
      });
    }
  } catch (err) {
    store.dispatch({ type: GET_WALLET_ACCOUNT_FAILURE });
    Logger.error(err);
  }
};

export const fetchWalletTransactions = async (account_id, criteria) => {
  store.dispatch({ type: GET_WALLET_TRANSACTIONS_REQUEST });
  try {
    const { data } = await getWalletTransactions(
      account_id,
      {
        page: criteria.pageNumber,
        pageSize: criteria.pageSize,
        ...(criteria.startDate && {
          from_created_at: `${criteria.startDate}T00:00:00`,
        }),
        ...(criteria.endDate && {
          to_created_at: `${criteria.endDate}T23:59:59`,
        }),
      },
      {
        filters: criteria.filters,
      },
    );
    if (Array.isArray(data)) {
      store.dispatch({
        type: GET_WALLET_TRANSACTIONS_SUCCESS,
        payload: { data, pageNumber: criteria.pageNumber },
      });
    } else {
      store.dispatch({ type: GET_WALLET_TRANSACTIONS_FAILURE });
    }
  } catch (err) {
    store.dispatch({ type: GET_WALLET_TRANSACTIONS_FAILURE });
    Logger.error(err);
  }
};

export const fetchGlobalAccounts = async (account_id, criteria) => {
  store.dispatch({ type: GET_GLOBAL_ACCOUNTS_REQUEST });
  try {
    const { data } = await getGlobalAccounts(
      account_id,
      {
        page: criteria.pageNumber,
        pageSize: criteria.pageSize,
      },
      {
        filters: criteria.filters,
      },
    );
    if (data) {
      store.dispatch({
        type: GET_GLOBAL_ACCOUNTS_SUCCESS,
        payload: { data, pageNumber: criteria.pageNumber },
      });
    } else {
      store.dispatch({ type: GET_GLOBAL_ACCOUNTS_FAILURE });
    }
  } catch (err) {
    store.dispatch({ type: GET_GLOBAL_ACCOUNTS_FAILURE });
    Logger.error(err);
  }
};

export const fetchWalletPayouts = async (account_id, criteria) => {
  store.dispatch({ type: GET_WALLET_PAYOUTS_REQUEST });
  try {
    const { data, error } = await getWalletPayouts(
      account_id,
      {
        page: criteria.pageNumber,
        pageSize: criteria.pageSize,
        ...(criteria.startDate && {
          from_created_at: `${criteria.startDate}`,
        }),
        ...(criteria.endDate && {
          to_created_at: `${criteria.endDate}`,
        }),
      },
      {
        filters: criteria.filters,
      },
    );
    if (Array.isArray(data)) {
      store.dispatch({
        type: GET_WALLET_PAYOUTS_SUCCESS,
        payload: { data, pageNumber: criteria.pageNumber },
      });
    } else {
      throw error;
    }
  } catch (err) {
    store.dispatch({ type: GET_WALLET_PAYOUTS_FAILURE });
    Logger.error(err);
  }
};

export const fetchWalletPayoutById = async (accountId, paymentId) => {
  store.dispatch({ type: GET_WALLET_PAYOUT_DETAIL_REQUEST });
  try {
    const { data, error } = await getWalletPayoutById(accountId, paymentId);
    if (error) {
      throw error;
    } else {
      store.dispatch({
        type: GET_WALLET_PAYOUT_DETAIL_SUCCESS,
        payload: data,
      });
    }
  } catch (err) {
    store.dispatch({ type: GET_WALLET_PAYOUT_DETAIL_FAILURE });
    Logger.error(err);
  }
};

export const fetchWalletBeneficiaries = async (accountId, criteria) => {
  store.dispatch({ type: GET_WALLET_BENEFICIARIES_REQUEST });
  try {
    const { data, error } = await getWalletBeneficiaries(accountId, {
      page: criteria.pageNumber,
      pageSize: criteria.pageSize,
    });
    if (error) {
      throw error;
    } else {
      store.dispatch({
        type: GET_WALLET_BENEFICIARIES_SUCCESS,
        payload: {
          pageNumber: criteria.pageNumber,
          data,
        },
      });
    }
  } catch (err) {
    store.dispatch({ type: GET_WALLET_BENEFICIARIES_FAILURE });
    Logger.error(err);
  }
};

export const fetchFormSchema = async (formName, payload) => {
  store.dispatch({ type: GET_WALLET_FORM_SCHEMA_REQUEST });
  try {
    const { data, error } = await getWalletBeneficiarySchema(payload);
    if (data) {
      store.dispatch({
        type: GET_WALLET_FORM_SCHEMA_SUCCESS,
        payload: {
          data: { ...data, condition: payload },
          formName,
        },
      });
    } else {
      store.dispatch({
        type: GET_WALLET_FORM_SCHEMA_FAILURE,
        payload: {
          data: undefined,
          formName,
        },
      });
    }
  } catch (err) {
    Logger.error(err);
    store.dispatch({
      type: GET_WALLET_FORM_SCHEMA_FAILURE,
      payload: {
        data: undefined,
        formName,
      },
    });
  }
};

export const fetchGlobalAccountTransactions = async (accountId, globalAccountId, criteria) => {
  store.dispatch({ type: GET_GLOBAL_ACCOUNT_TRANSACTION_REQUEST });
  try {
    const { data, error } = await getGlobalAccountTransactions(
      accountId,
      globalAccountId,
      {
        page: criteria.pageNumber,
        pageSize: criteria.pageSize,
        ...(criteria.startDate && {
          from_created_at: `${criteria.startDate}`,
        }),
        ...(criteria.endDate && {
          to_created_at: `${criteria.endDate}`,
        }),
      },
      {
        filters: criteria.filters,
      },
    );
    if (data) {
      store.dispatch({
        type: GET_GLOBAL_ACCOUNT_TRANSACTION_SUCCESS,
        payload: { data, pageNumber: criteria.pageNumber },
      });
    } else {
      store.dispatch({
        type: GET_GLOBAL_ACCOUNT_TRANSACTION_FAILURE,
        payload: data,
      });
    }
  } catch (err) {
    store.dispatch({ type: GET_GLOBAL_ACCOUNT_TRANSACTION_FAILURE });
    Logger.error(err);
  }
};

export const fetchWalletCurrentBalance = async (accountId) => {
  store.dispatch({ type: GET_WALLET_CURRENCY_BALANCE_REQUEST });
  try {
    const { data, error } = await getWalletCurrentBalance(accountId);

    if (error) {
      throw new Error(error);
    }

    if (data) {
      store.dispatch({ type: GET_WALLET_CURRENCY_BALANCE_SUCCESS, payload: data });
    }
  } catch (err) {
    store.dispatch({ type: GET_WALLET_CURRENCY_BALANCE_FAILURE });
    Logger.error(err);
  }
};

export const fetchWalletTransferFee = async (bankCountryCode, beneficiaryCurrencyCode) => {
  store.dispatch({ type: GET_WALLET_TRANSFER_FEE_REQUEST });
  try {
    const { data, error } = await getWalletTransferFee(bankCountryCode, beneficiaryCurrencyCode);

    if (error) {
      throw new Error(error);
    }

    if (data) {
      store.dispatch({ type: GET_WALLET_TRANSFER_FEE_SUCCESS, payload: data });
    }
  } catch (err) {
    store.dispatch({ type: GET_WALLET_TRANSFER_FEE_FAILURE });
    Logger.error(err);
  }
};

export const fetchForeignExchange = async (accountId, payload) => {
  const { fromCurrency, toCurrency, amount } = payload;
  store.dispatch({ type: GET_WALLET_FOREIGN_EXCHANGE_REQUEST });
  try {
    const { data, error } = await getWalletFX(accountId, fromCurrency, toCurrency, amount);
    if (data) {
      store.dispatch({ type: GET_WALLET_FOREIGN_EXCHANGE_SUCCESS, payload: data });
    } else {
      throw new Error(error);
    }
  } catch (err) {
    store.dispatch({ type: GET_WALLET_FOREIGN_EXCHANGE_FAILURE, payload });
    Logger.error(err);
  }
};

export const fetchWalletConversionList = async (accountId, criteria) => {
  store.dispatch({ type: GET_WALLET_CONVERSION_LIST_REQUEST });
  try {
    const { data } = await getWalletConversionList(
      accountId,
      {
        page: criteria.pageNumber,
        pageSize: criteria.pageSize,
        ...(criteria.startDate && {
          from_created_at: `${criteria.startDate}`,
        }),
        ...(criteria.endDate && {
          to_created_at: `${criteria.endDate}`,
        }),
      },
      {
        filters: criteria.filters,
      },
    );
    if (Array.isArray(data)) {
      store.dispatch({
        type: GET_WALLET_CONVERSION_LIST_SUCCESS,
        payload: { data, pageNumber: criteria.pageNumber },
      });
    } else {
      store.dispatch({ type: GET_WALLET_CONVERSION_LIST_FAILURE });
    }
  } catch (err) {
    store.dispatch({ type: GET_WALLET_CONVERSION_LIST_FAILURE });
    Logger.error(err);
  }
};

export const fetchWalletCards = async (accountId, query = { pageNumber: 1, pageSize: 100 }) => {
  store.dispatch({ type: GET_WALLET_CARDS_REQUEST });
  try {
    const { data } = await getWalletAllCards(accountId, {
      page: query.pageNumber,
      pageSize: query.pageSize,
    });

    if (Array.isArray(data)) {
      store.dispatch({
        type: GET_WALLET_CARDS_SUCCESS,
        payload: { data, pageNumber: query.pageNumber },
      });
    } else {
      store.dispatch({ type: GET_WALLET_CARDS_FAILURE });
    }
  } catch (err) {
    store.dispatch({ type: GET_WALLET_CARDS_FAILURE });
    Logger.error(err);
  }
};

export const fetchWalletCardDetail = async (accountId, cardId) => {
  store.dispatch({ type: GET_WALLET_CARD_DETAIL_REQUEST });
  try {
    const { data, statusCode } = await getWalletCardDetail(accountId, cardId);

    if (statusCode === 200) {
      store.dispatch({
        type: GET_WALLET_CARD_DETAIL_SUCCESS,
        payload: { id: cardId, detail: data },
      });
    } else {
      store.dispatch({ type: GET_WALLET_CARD_DETAIL_FAILURE });
    }
  } catch (err) {
    store.dispatch({ type: GET_WALLET_CARD_DETAIL_FAILURE });
    Logger.error(err);
  }
};

export const updateWalletCardDetail = async (accountId, cardId, payload) => {
  store.dispatch({ type: UPDATE_WALLET_CARD_REQUEST });
  try {
    const { statusCode } = await updateWalletCard(accountId, cardId, payload);
    if (statusCode === 200) {
      store.dispatch({ type: UPDATE_WALLET_CARD_SUCCESS });
    } else {
      store.dispatch({ type: UPDATE_WALLET_CARD_FAILURE });
    }
  } catch (err) {
    store.dispatch({ type: UPDATE_WALLET_CARD_FAILURE });
    Logger.error(err);
  }
};

export const fetchCardTransactions = async (account_id, criteria) => {
  store.dispatch({ type: GET_WALLET_CARD_TRANSACTIONS_REQUEST });
  try {
    const { data } = await getCardTransactions(
      account_id,
      {
        page: criteria.pageNumber,
        pageSize: criteria.pageSize,
        ...(criteria.startDate && {
          from_created_at: criteria.startDate,
        }),
        ...(criteria.endDate && {
          to_created_at: criteria.endDate,
        }),
      },
      {
        filters: criteria.filters,
      },
    );
    if (Array.isArray(data)) {
      store.dispatch({
        type: GET_WALLET_CARD_TRANSACTIONS_SUCCESS,
        payload: { data, pageNumber: criteria.pageNumber },
      });
    } else {
      store.dispatch({ type: GET_WALLET_CARD_TRANSACTIONS_FAILURE });
    }
  } catch (err) {
    store.dispatch({ type: GET_WALLET_CARD_TRANSACTIONS_FAILURE });
    Logger.error(err);
  }
};

export const fetchAccountSettings = async (accountId) => {
  store.dispatch({ type: GET_WALLET_ACCOUNT_SETTINGS_REQUEST });
  try {
    const { data } = await getAccountSettings(accountId);

    if (data) {
      store.dispatch({
        type: GET_WALLET_ACCOUNT_SETTINGS_SUCCESS,
        payload: data,
      });
    } else {
      store.dispatch({ type: GET_WALLET_ACCOUNT_SETTINGS_FAILURE });
    }
  } catch (err) {
    store.dispatch({ type: GET_WALLET_ACCOUNT_SETTINGS_FAILURE });
    Logger.error(err);
  }
};

export const updateAccountSettings = async (accountId, payload) => {
  store.dispatch({ type: UPDATE_WALLET_ACCOUNT_SETTINGS_REQUEST });
  try {
    const { data } = await updateWalletAccountSettings(accountId, payload);

    if (data) {
      store.dispatch({
        type: UPDATE_WALLET_ACCOUNT_SETTINGS_SUCCESS,
        payload: data,
      });
    } else {
      store.dispatch({ type: GET_WALLET_ACCOUNT_SETTINGS_FAILURE });
    }
  } catch (err) {
    store.dispatch({ type: UPDATE_WALLET_ACCOUNT_SETTINGS_FAILURE });
    Logger.error(err);
  }
};

export const updateCardStatus = async (accountId, cardId, status, callback = () => {}) => {
  store.dispatch({ type: UPDATE_CARD_STATUS_REQUEST });
  try {
    const { statusCode, error } = await updateWalletCard(accountId, cardId, { card_status: status });

    if (statusCode === 200) {
      if (typeof callback === 'function') {
        await callback({ statusCode, error });
        store.dispatch({ type: UPDATE_CARD_STATUS_SUCCESS });
      }
    } else {
      throw new Error(error || `cannot update card ${cardId} status to ${status}`);
    }
  } catch (err) {
    store.dispatch({ type: UPDATE_CARD_STATUS_FAILURE });
    Logger.error(err);
  }
};
