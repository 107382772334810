import React, { useState } from 'react';

import styled from 'styled-components';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { Description } from '../../Application/components';
import { FormRow, GridColumn } from '@components/Grid';

const Title = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: bold;
`;

const MessageModal = ({
  title,
  description,
  onConfirm = () => {},
  onCancel = () => {},
  hideModal,
  hideModalAfterConfirm = true,
  showCloseButton = true,
  hideModalOnClickOutside = true,
  showLeftButton = true,
  leftButtonProps = {
    label: 'Cancel',
  },
  showRightButton = true,
  rightButtonProps = {
    label: 'Confirm',
  },
}) => {
  const [isLoading, toggleIsLoading] = useState(false);
  const handleConfirm = async () => {
    toggleIsLoading(true);
    await onConfirm();
    if (hideModalAfterConfirm) {
      hideModal();
    }

    toggleIsLoading(false);
  };

  const handleCancel = () => {
    onCancel();
    hideModal();
  };

  return (
    <Modal showCloseButton={showCloseButton} hideModalOnClickOutside={hideModalOnClickOutside}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {(showRightButton || showLeftButton) && (
        <FormRow>
          <GridColumn lg={6} md={6}>
            {showLeftButton && (
              <Button onClick={handleCancel} tertiary full {...leftButtonProps}>
                {leftButtonProps.label || 'Cancel'}
              </Button>
            )}
          </GridColumn>
          <GridColumn lg={6} md={6}>
            {showRightButton && (
              <Button onClick={handleConfirm} full {...rightButtonProps} disabled={isLoading}>
                {rightButtonProps.label || 'Confirm'}
              </Button>
            )}
          </GridColumn>
        </FormRow>
      )}
    </Modal>
  );
};

export default MessageModal;
