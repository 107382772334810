import React, { useState, useEffect } from 'react';

import Select from '../../../../components/Select';
import { getAdmins, assignAdminsToCompany } from '@api/modules/dashboard/admin';
import { fetchCompany } from '@redux/modules/company/actions';
import { useCanAssignCompanyToAdmin } from '@utils/userHelpers';

const AdminSelect = ({ companyId, value }) => {
  const [adminOptions, setAdminOptions] = useState();
  const [isLoading, toggleIsLoading] = useState(false);
  useEffect(() => {
    const fetchApi = async () => {
      const { error, data } = await getAdmins();
      if (error) {
        //
      } else if (data) {
        const options = data.map((item) => ({
          value: item.admin_id,
          label: item.name,
        }));
        setAdminOptions(options);
      }
    };

    fetchApi();
  }, []);

  const isSelectActive = useCanAssignCompanyToAdmin();

  const handleChange = async (id) => {
    toggleIsLoading(true);
    await assignAdminsToCompany({ companyId, adminIds: [id] });
    await fetchCompany(companyId);
    toggleIsLoading(false);
  };

  return <Select value={value} options={adminOptions} onChange={handleChange} disabled={isLoading || !isSelectActive} />;
};

export default AdminSelect;
