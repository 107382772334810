import moment from 'moment';

import Logger from '../../../utils/logger';
import store from '../../store';
import {
  GET_COMPANY_REQUEST,
  GET_COMPANY_FAILURE,
  GET_COMPANY_SUCCESS,
  GET_USER_COMPANY_REQUEST,
  GET_USER_COMPANY_FAILURE,
  GET_USER_COMPANY_SUCCESS,
  GET_ADMIN_COMPANIES_REQUEST,
  GET_ADMIN_COMPANIES_FAILURE,
  GET_ADMIN_COMPANIES_SUCCESS,
  SELECT_COMPANY_REQUEST,
  SELECT_COMPANY_FAILURE,
  SELECT_COMPANY_SUCCESS,
  GET_COMPANY_OWNERS_REQUEST,
  GET_COMPANY_OWNERS_FAILURE,
  GET_COMPANY_OWNERS_SUCCESS,
  GET_FILES_BY_TYPE_REQUEST,
  GET_FILES_BY_TYPE_SUCCESS,
  GET_FILES_BY_TYPE_FAILURE,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  GET_COMPANY_ENTITIES_REQUEST,
  GET_COMPANY_ENTITIES_FAILURE,
  GET_COMPANY_ENTITIES_SUCCESS,
  GET_COMPANY_2FA_REQUEST,
  GET_COMPANY_2FA_SUCCESS,
  GET_COMPANY_2FA_FAILURE,
} from './constants';
import { convertBusinessOwnerResponse, convertCompanyResponse } from './helpers';
import { switchCompany } from '@api/modules/auth';
import { getAllCompaniesAccessedByAdmin } from '@api/modules/dashboard/admin';
import {
  getAllBusinessOwners,
  getAllBusinessOwnerEntities,
  getCompany2fa,
} from '@api/modules/dashboard/company';
import { getCompanyFilesByType } from '@api/modules/dashboard/file';
import { getUserCompany, getCompany } from '@api/modules/user';
import { isRealObject } from '@utils/dataTypes';

export const fetchCompany2fa = async () => {
  store.dispatch({ type: GET_COMPANY_2FA_REQUEST });
  try {
    const { data } = await getCompany2fa();
    if (isRealObject(data)) {
      store.dispatch({
        type: GET_COMPANY_2FA_SUCCESS,
        payload: data,
      });
    } else {
      store.dispatch({
        type: GET_COMPANY_2FA_FAILURE,
      });
    }
  } catch (err) {
    Logger.error(err);
  }
};

export const fetchCompany = async (companyId) => {
  store.dispatch({ type: GET_COMPANY_REQUEST });
  const { data, error } = await getCompany(companyId);

  if (error) {
    store.dispatch({ type: GET_COMPANY_FAILURE });
  } else if (data) {
    store.dispatch({
      type: GET_COMPANY_SUCCESS,
      payload: convertCompanyResponse(data),
    });
  }
};

export const selectCompany = async (companyId) => {
  store.dispatch({
    type: SELECT_COMPANY_REQUEST,
  });

  const { data, error } = await switchCompany(companyId);

  if (error) {
    store.dispatch({ type: SELECT_COMPANY_FAILURE });
  } else if (data) {
    store.dispatch({
      type: SELECT_COMPANY_SUCCESS,
      payload: { id: data },
    });

    await fetchCompany(companyId);
  }
};

export const fetchUserCompany = async () => {
  store.dispatch({ type: GET_USER_COMPANY_REQUEST });
  const { data, error } = await getUserCompany();
  if (error) {
    store.dispatch({ type: GET_USER_COMPANY_FAILURE });
  } else if (data) {
    store.dispatch({
      type: GET_USER_COMPANY_SUCCESS,
      payload: convertCompanyResponse(data),
    });
  }
};

export const fetchAllCompaniesAccessedByAdmin = async (adminId) => {
  store.dispatch({ type: GET_ADMIN_COMPANIES_REQUEST });

  const { data, error } = await getAllCompaniesAccessedByAdmin(adminId);
  if (error) {
    store.dispatch({ type: GET_ADMIN_COMPANIES_FAILURE });
  } else if (Array.isArray(data)) {
    const companyMap = {};
    for (const company of data) {
      companyMap[company.company_id] = convertCompanyResponse(company);
    }

    store.dispatch({
      type: GET_ADMIN_COMPANIES_SUCCESS,
      payload: companyMap,
    });
  }
};

export const fetchBusinessOwners = async () => {
  store.dispatch({ type: GET_COMPANY_OWNERS_REQUEST });
  const { data, error } = await getAllBusinessOwners();
  if (Array.isArray(data)) {
    const convertedData = data.map(convertBusinessOwnerResponse);
    store.dispatch({
      type: GET_COMPANY_OWNERS_SUCCESS,
      payload: convertedData,
    });
  } else {
    store.dispatch({ type: GET_COMPANY_OWNERS_FAILURE });
  }
};

export const fetchBusinessEntities = async () => {
  store.dispatch({ type: GET_COMPANY_ENTITIES_REQUEST });
  const { data, error } = await getAllBusinessOwnerEntities();
  if (Array.isArray(data)) {
    store.dispatch({
      type: GET_COMPANY_ENTITIES_SUCCESS,
      payload: data,
    });
  } else {
    store.dispatch({ type: GET_COMPANY_ENTITIES_FAILURE });
  }
};

export const fetchFilesByType = async (type: string, referenceId?: string) => {
  store.dispatch({ type: GET_FILES_BY_TYPE_REQUEST });
  const { data, error } = await getCompanyFilesByType(type, referenceId);

  if (Array.isArray(data)) {
    store.dispatch({
      type: GET_FILES_BY_TYPE_SUCCESS,
      payload: {
        type,
        data,
      },
    });
  } else {
    store.dispatch({ type: GET_FILES_BY_TYPE_FAILURE });
  }
};

export const startUploadFiles = () => ({
  type: UPLOAD_FILE_REQUEST,
});

export const endUploadFiles = () => ({
  type: UPLOAD_FILE_SUCCESS,
});
