import React, { useEffect, useState } from 'react';

import countries from 'i18n-iso-countries';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Table from '../../../../../components/Table/index';
import Button from '../../../../../components/Button';
import SimplePagination from '../../../../../components/Pagination/SimplePagination';
import SearchBar from '../../../../../components/SearchBar';
import { showModal } from '@redux/modules/UI/actions';
import Logger from '../../../../../utils/logger';
import { Wrapper } from '../../../components';
import PATH from '../../../path';
import {
  OPTION_VALUE_ALL,
  globalAccountsCurrencyOptions,
  globalAccountsBankOptions,
  globalAccountsStatusOptions,
  WALLET_COUNTRIES,
} from '../../constants';
import { getColorByAccountStatus } from '../../helpers';
import { FilterLabel, StyledSelect } from '../components';
import { usePagination } from '@hooks/UI';
import { CREATE_GLOBAL_ACCOUNTS_MODAL } from '@modules/Modal/constants';
import { fetchGlobalAccounts } from '@redux/modules/wallet/actions';
import { useWalletAccount, useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';
import { media } from '@styles/breakpoints';
import { areRecordsFewerThanPageSize } from '@utils/utilHelpers';

const Container = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;

  ${media.dablet`
    flex-direction: column;
  `};
`;

const Title = styled.div`
  ${(props) => props.theme.text.four};
  font-weight: 600;
`;

const Description = styled.span`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  font-weight: 600;
  margin-top: 6px;
`;

const FlagWrapper = styled.div`
  display: flex;
  padding: 12px;
`;

const CurrencyWrapper = styled.div`
  padding-left: 14px;
`;

const CurrencyHeader = styled.div`
  font-weight: 600;
`;

const AccountNumber = styled.div`
  color: ${(props) => props.theme.colors.grayThree};
`;

const FilterSection = styled.span`
  display: flex;
  margin: 24px 0;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
`;

const AccountOverview = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pageNumber, pageSize, pageCount, onPageNumberChange, onPageSizeChange, changePageCount } = usePagination({
    pageNumber: 1,
    pageCount: 0,
    pageSize: 20,
  });
  const walletAccounts = useSelector((state) => state.wallet.walletAccounts);
  const walletAccount = useWalletAccount();
  const globalAccounts = useSelector((state) => state.wallet.globalAccountsPageMap);
  const data = globalAccounts[pageNumber];
  const isLoading = useSelector((state) => state.wallet.isLoading);
  const [currencyFilter, setCurrencyFilter] = useState(OPTION_VALUE_ALL);
  const [bankLocationFilter, setBankLocationFilter] = useState(OPTION_VALUE_ALL);
  const [statusFilter, setStatusFilter] = useState(OPTION_VALUE_ALL);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchResult, setSearchResult] = useState('');
  const disabled = useCheckIsAdminViewingAnotherCompany();

  const globalAccountsColumns = [
    {
      Header: 'Currency',
      accessor: 'currency',
      Cell: ({ value }) => {
        return (
          <FlagWrapper>
            <img src={WALLET_COUNTRIES[value]?.logoUrl} alt="country flags" />
            <CurrencyWrapper>
              <CurrencyHeader>{value}</CurrencyHeader>
              {WALLET_COUNTRIES[value]?.displayName}
            </CurrencyWrapper>
          </FlagWrapper>
        );
      },
    },
    {
      Header: 'Bank location',
      accessor: 'country_code',
      Cell: ({ value }) => {
        return <>{value === 'HK' ? 'Hong Kong (China)' : countries.getName(value, 'en', { select: 'official' })}</>;
      },
    },
    {
      Header: 'Name',
      accessor: 'nick_name',
      Cell: ({ value, row }) => {
        return (
          <>
            <div>{value}</div>
            <AccountNumber>
              Account Number:&nbsp;
              {row?.account_name}
            </AccountNumber>
          </>
        );
      },
    },
    {
      Header: 'Account Number',
      accessor: 'account_number',
      Cell: ({ value }) => {
        return value;
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      className: 'textRight',
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      getStyle: ({ value }) => {
        return css`
          color: ${getColorByAccountStatus(value)};
        `;
      },
    },
  ];

  const formatPageNavLabel = (number) => {
    return ` ${number} `;
  };

  const noNextPage = areRecordsFewerThanPageSize(globalAccounts[pageNumber], pageSize);

  const getGlobalAccounts = async () => {
    try {
      const filters = [];
      if (currencyFilter !== OPTION_VALUE_ALL) {
        filters.push({
          field: 'currency',
          operator: 'in',
          value: currencyFilter.map(({ value }) => value),
        });
      }

      if (statusFilter !== OPTION_VALUE_ALL) {
        filters.push({
          field: 'status',
          operator: 'in',
          value: statusFilter.map(({ value }) => value),
        });
      }

      if (searchResult.length > 1) {
        filters.push({
          fields: ['account_name', 'account_number', 'nick_name'],
          operator: 'like',
          value: [searchResult],
        });
      }

      if (bankLocationFilter !== OPTION_VALUE_ALL) {
        filters.push({
          field: 'country_code',
          operator: 'in',
          value: bankLocationFilter.map(({ value }) => value),
        });
      }

      fetchGlobalAccounts(walletAccounts[0]?.account_id, {
        pageNumber,
        pageSize,
        filters,
      });
    } catch (err) {
      Logger.error(err);
    }
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchResult(searchKeyword), 1000);
    return () => clearTimeout(timeOutId);
  }, [searchKeyword]);

  useEffect(() => {
    if (walletAccount?.account_id) {
      getGlobalAccounts();
    }
  }, [JSON.stringify(walletAccount), pageNumber]);

  useEffect(() => {
    getGlobalAccounts();
  }, [currencyFilter, statusFilter, bankLocationFilter, searchResult]);

  const handleCreateGlobalAccounts = () => {
    dispatch(
      showModal(CREATE_GLOBAL_ACCOUNTS_MODAL, {
        id: walletAccounts?.[0]?.account_id,
      })
    );
  };

  const handleClickByAccount = (value) => {
    history.push(PATH.WALLET_GLOBAL_ACCOUNT_ID?.replace(':globalAccountId', value.id));
  };

  return (
    <Wrapper>
      <Container>
        <div>
          <Title>Global Accounts</Title>
          <Description>Bank accounts used to receive funds into your multi-currency wallet.</Description>
        </div>
        <Button width={200} onClick={handleCreateGlobalAccounts} disabled={disabled}>
          Create global account
        </Button>
      </Container>
      <FilterSection>
        <SearchBar
          width="100%"
          value={searchKeyword}
          onChange={(event) => setSearchKeyword(event.target.value)}
          placeholder="Search account name, nickname or account number"
        />
      </FilterSection>
      <FilterSection>
        <SelectWrapper>
          <FilterLabel>Currency</FilterLabel>
          <StyledSelect
            isMulti
            width={200}
            value={currencyFilter}
            options={globalAccountsCurrencyOptions}
            onChange={setCurrencyFilter}
            multiValueLabel="Currencies"
          />
        </SelectWrapper>
        <SelectWrapper>
          <FilterLabel>Bank location</FilterLabel>
          <StyledSelect
            isMulti
            width={250}
            value={bankLocationFilter}
            options={globalAccountsBankOptions}
            onChange={setBankLocationFilter}
            multiValueLabel="Bank Locations"
          />
        </SelectWrapper>
        <SelectWrapper>
          <FilterLabel>Status</FilterLabel>
          <StyledSelect
            isMulti
            width={200}
            value={statusFilter}
            options={globalAccountsStatusOptions}
            onChange={setStatusFilter}
            multiValueLabel="Statuses"
          />
        </SelectWrapper>
      </FilterSection>
      <Table columns={globalAccountsColumns} data={data} onRowClick={handleClickByAccount} isLoading={isLoading} />
      <SimplePagination
        pageNumber={pageNumber}
        pageSize={pageSize}
        pageSizeOptions={[
          {
            value: 20,
            label: 20,
          },
          {
            value: 50,
            label: 50,
          },
          {
            value: 100,
            label: 100,
          },
        ]}
        onPageNumberChange={onPageNumberChange}
        onPageSizeChange={onPageSizeChange}
        formatPageNavLabel={formatPageNavLabel}
        disabled={isLoading}
        isLoading={isLoading}
        noNextPage={noNextPage}
      />
    </Wrapper>
  );
};

export default AccountOverview;
