import React from 'react';

import styled from 'styled-components';

import Arrow, { ARROW_DIRECTION } from '../../../../assets/icons/Arrow';
import { Card } from '../../components';
import { useModal } from '@hooks/UI';
import { formatPrice } from '@utils/priceHelpers';
import { toUpperCase } from '@utils/stringHelpers';

const PayoutBalanceLabel = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: 600;
`;

const Total = styled.div`
  ${(props) => props.theme.text.four};
  margin: 6px 0;
`;

const TableWrapper = styled.div`
  padding: 14px;
  display: inline-block;
  min-width: 50%;
  background-color: ${(props) => props.theme.colors.grayOne};
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  tr {
    color: ${(props) => props.theme.colors.grayThree};
    font-weight: 500;
    ${(props) => props.theme.text.five};
  }

  td {
    padding: 4px 0;
  }

  td:last-child {
    text-align: right;
  }
`;

const Toggle = styled.div`
  cursor: pointer;
  margin-bottom: 8px;
  span,
  svg {
    vertical-align: middle;
    color: ${(props) => props.theme.colors.orange};
    fill: ${(props) => props.theme.colors.orange};
    font-weight: 600;

    &:last-child {
      margin-left: 8px;
    }
  }
`;

const BalanceCard = ({ currency = '', futurePayouts, payoutBalance }) => {
  const [isDetailExpanded, toggleIsDetailExpanded] = useModal(false);

  const getTotal = () => {
    const sum = futurePayouts + payoutBalance;
    return formatPrice(currency, sum);
  };

  const total = getTotal();
  return (
    <Card>
      <PayoutBalanceLabel>
        {toUpperCase(currency)}
        {' '}
        Balance
      </PayoutBalanceLabel>
      <Total>{total}</Total>
      <Toggle onClick={toggleIsDetailExpanded}>
        <span>Balance details</span>
        <Arrow direction={isDetailExpanded ? ARROW_DIRECTION.UP : ARROW_DIRECTION.DOWN} />
      </Toggle>
      {isDetailExpanded && (
        <TableWrapper>
          <Table>
            <tr>
              <td>Outgoing payouts</td>
              <td>{formatPrice(currency, payoutBalance, false, 2)}</td>
            </tr>
            <tr>
              <td>Estimated future payouts</td>
              <td>{formatPrice(currency, futurePayouts, false, 2)}</td>
            </tr>
          </Table>
        </TableWrapper>
      )}
    </Card>
  );
};

export default BalanceCard;
