import React, { useEffect, useState } from 'react';

import countries from 'i18n-iso-countries';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import HR from '../../../../components/HR';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import RadioButton from '../../../../components/Radio';
import Logger from '../../../../utils/logger';
import { globalAccountsRegions, WALLET_COUNTRIES } from '../constants';
import CreateGlobalAccountSuccess from './GlobalAccounts/CreateGlobalAccountSuccess';
import { COUNTRY_CURRENCY_MAP, Currency, WALLET_ACCOUNT_STATUS } from '@/constants';
import { createGlobalAccounts, getGlobalAccounts } from '@api/modules/integration/airwallex';
import { useWalletAccount } from '@redux/selectors';
import { findFirstMatchInArray } from '@utils/dataTypes';

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0;
`;

const SectionHeader = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
  margin-bottom: 12px;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
`;

const Description = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  width: 100%;
`;

const Title = styled.span`
  ${(props) => props.theme.text.five};
  padding: 0 5px;
  color: ${(props) => props.theme.colors.grayThree};
  font-weight: 600;
`;
const CheckboxText = styled.span`
  margin-left:5px;
  font-weight: 600;
`;

const StyledImg = styled.img`
  padding: 0px 8px;
`;

const Container = styled.div`
  margin: 16px 0;
  display: flex;
  vertical-align: middle;
`;

const FundsInfoContainer = styled.div`
  margin: 14px 0;
`;

const Wrapper = styled.span`
  vertical-align: middle;
  display: flex;
  margin: 10px 0;
`;

const RadioButtonContainer = styled.span`
  vertical-align: middle;
  display: flex;
  cursor: pointer;
`;

const Header = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: 600;
  margin: 6px 0;
`;

export const Label = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  margin-bottom: 8px;
  font-weight: 600;
  text-align: left;
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CopyButton = styled.button`
  border: none;
  background: white;

  :hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 26px;
  justify-content: end;
`;

const Li = styled.li`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  margin: 10px 0;
`;

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.error};
`;

const AddFundsModal = ({ countryOrigin, id, hideModal }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.wallet.isLoading);
  const [isDetails, toggleIsDetails] = useState(true);
  const walletAccount = useWalletAccount();
  const [accountNickName, setAccountNickName] = useState();
  const [globalAccountSession, setGlobalAccountSession] = useState(1);
  const [apiError, setApiError] = useState('');
  const [currency, setCurrency] = useState();
  const [addFundDetails, setAddFundDetails] = useState([]);
  const [globalAccountDetails, setGlobalAccountDetails] = useState();

  const filteredCurrencies = Object.keys(WALLET_COUNTRIES).filter((key) => key.indexOf(countryOrigin) === -1);

  const findExistingGlobalAccount = async () => {
    try {
      const { data } = await getGlobalAccounts(
        walletAccount?.account_id,
        {},
        {
          filters: [
            {
              field: 'currency',
              operator: 'in',
              value: [currency],
            },
          ],
        }
      );
      if (data) {
        const accountInfo = findFirstMatchInArray(
          data,
          (account) => account.currency === currency && account.status === WALLET_ACCOUNT_STATUS.ACTIVE
        );
        setAddFundDetails(accountInfo);
      }
    } catch (err) {
      Logger.error(err);
    }
  };

  const fetchApi = async () => {
    try {
      if (['CAD', 'NZD', 'CHF'].indexOf(currency) !== -1) {
        const payload = {
          country_code: 'HK',
          currency,
          nick_name: accountNickName,
        };

        const { data, statusCode } = await createGlobalAccounts(walletAccount?.account_id, payload);
        if (statusCode === 500) {
          setApiError('Something went wrong');
        } else if (data) {
          setGlobalAccountDetails(data);
          setGlobalAccountSession(2);
        }
      } else {
        const filteredCountryCode = globalAccountsRegions.filter((region) => {
          return region.currencyCode === currency;
        });

        const payload = {
          country_code: filteredCountryCode[0].value,
          currency,
          nick_name: accountNickName,
        };
        const { data, statusCode } = await createGlobalAccounts(walletAccount?.account_id, payload);
        if (statusCode === 500) {
          setApiError('Something went wrong');
        } else if (data) {
          setGlobalAccountDetails(data);
          setGlobalAccountSession(2);
        }
      }
    } catch (err) {
      Logger.error(err);
    }
  };
  const handleCreateGlobalAccount = () => {
    fetchApi();
  };

  const handleAccountNickName = (event) => {
    setAccountNickName(event.target.value);
  };

  useEffect(() => {
    if (currency !== undefined) {
      findExistingGlobalAccount();
    }
  }, [currency]);

  const handleNext = () => {
    findExistingGlobalAccount();
    toggleIsDetails(false);
  };

  const handleCurrencySelect = (value) => () => {
    setCurrency(value);
  };

  const handleBack = () => {
    toggleIsDetails(true);
  };

  return (
    <Modal showCloseButton>
      {isDetails ? (
        <>
          <SectionHeader>Add funds</SectionHeader>
          <Header>
            Add&nbsp;
            {COUNTRY_CURRENCY_MAP[countryOrigin]} via bank transfer
          </Header>
          <HR color="grayOne" />
          <div>
            <RadioWrapper>
              <RadioButton
                name="currency"
                value={COUNTRY_CURRENCY_MAP[countryOrigin]}
                checked={currency === COUNTRY_CURRENCY_MAP[countryOrigin]}
                onClick={handleCurrencySelect(COUNTRY_CURRENCY_MAP[countryOrigin])}
              />
              <RadioButtonContainer onClick={handleCurrencySelect(COUNTRY_CURRENCY_MAP[countryOrigin])}>
                <StyledImg src={WALLET_COUNTRIES[Currency.HKD].logoUrl} alt="country flags" />
                <CheckboxText>{[Currency.HKD]}</CheckboxText>
                <CheckboxText>{WALLET_COUNTRIES[Currency.HKD].displayName}</CheckboxText>
              </RadioButtonContainer>
            </RadioWrapper>
            <Description>Or add one of these other currencies to your wallet</Description>
          </div>
          <HR color="grayOne" />
          {filteredCurrencies.map((countriesCurrencies) => {
            return (
              <Container>
                <RadioButton
                  name="currency"
                  value={countriesCurrencies}
                  checked={currency === countriesCurrencies}
                  onClick={handleCurrencySelect(countriesCurrencies)}
                />
                <RadioButtonContainer onClick={handleCurrencySelect(countriesCurrencies)}>
                  <StyledImg src={WALLET_COUNTRIES[countriesCurrencies].logoUrl} alt="country flags" />
                  <CheckboxText>{[countriesCurrencies]}</CheckboxText>
                  <CheckboxText>{WALLET_COUNTRIES[countriesCurrencies].displayName}</CheckboxText>
                </RadioButtonContainer>
              </Container>
            );
          })}
          <ButtonWrapper>
            <Button width={155} onClick={handleNext} disabled={currency === undefined}>
              Next
            </Button>
          </ButtonWrapper>
        </>
      ) : addFundDetails !== undefined ? (
        <>
          <SectionHeader>
            Add&nbsp;
            {addFundDetails.currency} to your wallet
          </SectionHeader>
          <Description>
            To add {addFundDetails.currency} to your Airwallex account simply input these details in your existing business account.
          </Description>
          <Wrapper>
            <img src={WALLET_COUNTRIES[addFundDetails.currency].logoUrl} alt="country flags" />
            <Title>{addFundDetails.currency} deposit details</Title>
          </Wrapper>
          <FundsInfoContainer>
            {addFundDetails?.account_name && (
              <>
                <Header>Account name</Header>
                <DetailsContainer>
                  {addFundDetails?.account_name}
                  <CopyButton
                    onClick={() => {
                      navigator.clipboard.writeText(addFundDetails?.account_name);
                    }}
                  >
                    <img src="/icons/copy-icon.svg" alt="copy" />
                  </CopyButton>
                </DetailsContainer>
              </>
            )}
            <Header>Bank account number</Header>
            <DetailsContainer>
              {addFundDetails?.account_number}
              <CopyButton
                onClick={() => {
                  navigator.clipboard.writeText(addFundDetails?.account_number);
                }}
              >
                <img src="/icons/copy-icon.svg" alt="copy" />
              </CopyButton>
            </DetailsContainer>
            {Array.isArray(addFundDetails?.routing_codes) && addFundDetails?.routing_codes.length > 0 && (
              <>
                <Header>{addFundDetails?.routing_codes?.[0]?.type === 'bsb' ? 'BSB code' : 'Bank code'}</Header>
                <DetailsContainer>
                  {addFundDetails?.routing_codes[0]?.value}
                  <CopyButton
                    onClick={() => {
                      navigator.clipboard.writeText(addFundDetails?.routing_codes?.[0]?.value);
                    }}
                  >
                    <img src="/icons/copy-icon.svg" alt="copy" />
                  </CopyButton>
                </DetailsContainer>
              </>
            )}
            {addFundDetails?.routing_codes?.[1]?.value && (
              <>
                <Header>Branch code</Header>
                <DetailsContainer>
                  {addFundDetails?.routing_codes[1]?.value}
                  <CopyButton
                    onClick={() => {
                      navigator.clipboard.writeText(addFundDetails?.routing_codes[1]?.value);
                    }}
                  >
                    <img src="/icons/copy-icon.svg" alt="copy" />
                  </CopyButton>
                </DetailsContainer>
              </>
            )}
            {addFundDetails?.swift_code && (
              <>
                <Header>SWIFT code</Header>
                <DetailsContainer>
                  {addFundDetails.swift_code}
                  <CopyButton
                    onClick={() => {
                      navigator.clipboard.writeText(addFundDetails.swift_code);
                    }}
                  >
                    <img src="/icons/copy-icon.svg" alt="copy" />
                  </CopyButton>
                </DetailsContainer>
              </>
            )}
            <Header>Bank name</Header>
            <DetailsContainer>
              {addFundDetails?.institution?.name}
              <CopyButton
                onClick={() => {
                  navigator.clipboard.writeText(addFundDetails?.institution?.name);
                }}
              >
                <img src="/icons/copy-icon.svg" alt="copy" />
              </CopyButton>
            </DetailsContainer>
            {addFundDetails?.institution?.address && (
              <>
                <Header>Bank address</Header>
                <DetailsContainer>
                  {addFundDetails?.institution?.address} {addFundDetails?.institution?.city}
                  <CopyButton
                    onClick={() => {
                      navigator.clipboard.writeText(addFundDetails?.institution?.address);
                    }}
                  >
                    <img src="/icons/copy-icon.svg" alt="copy" />
                  </CopyButton>
                </DetailsContainer>
              </>
            )}
            <Header>Bank Location</Header>
            <DetailsContainer>
              {addFundDetails?.country_code === 'HK'
                ? 'Hong Kong (China)'
                : countries.getName(addFundDetails?.country_code, 'en', { select: 'official' })}
            </DetailsContainer>
            <Header>Accept funds via</Header>
            <ul>
              {Array.isArray(addFundDetails?.capability?.clearing_systems) &&
                addFundDetails?.capability?.clearing_systems.map((paymentType) => {
                  return <Li>{paymentType}</Li>;
                })}
              {addFundDetails?.country_code === 'HK' && <Li>International SWIFT Transfer</Li>}
            </ul>
          </FundsInfoContainer>
          <ButtonWrapper>
            <StyledButton width={155} secondary onClick={handleBack}>
              Back
            </StyledButton>
            <StyledButton width={155} onClick={() => dispatch(hideModal())}>
              Done
            </StyledButton>
          </ButtonWrapper>
        </>
      ) : (
        <>
          {globalAccountSession === 1 ? (
            <>
              <SectionHeader>Create a new global account</SectionHeader>
              <Header>
                Let&apos;s create a {currency}
                &nbsp;account in {currency}
              </Header>
              <HR color="grayOne" />
              <Label>Account nickname*</Label>
              <Input
                value={accountNickName}
                onChange={handleAccountNickName}
                full
                placeholder="Please specify account nickname for your global account"
              />
              {apiError && <ErrorMessage>{apiError}</ErrorMessage>}
              <ButtonWrapper>
                <StyledButton width={155} secondary onClick={handleBack}>
                  Back
                </StyledButton>
                <StyledButton width={155} disabled={accountNickName === undefined || isLoading} onClick={handleCreateGlobalAccount}>
                  Create
                </StyledButton>
              </ButtonWrapper>
            </>
          ) : globalAccountSession === 2 ? (
            <CreateGlobalAccountSuccess id={walletAccount?.account_id} accountDetails={globalAccountDetails} />
          ) : null}
        </>
      )}
    </Modal>
  );
};

export default AddFundsModal;
