import { convertToFullDate } from '@utils/dateHelpers';
import { formatNumber } from '@utils/priceHelpers';

export const breakdownTableColumns = [
  {
    Header: 'Type',
    accessor: 'channel',
  },
  {
    Header: 'Description',
    accessor: 'description',
    className: 'description',
  },
  {
    Header: 'Original Currency',
    accessor: 'currency',
    Cell: ({ value }) => {
      return typeof value === 'string' ? value.toUpperCase() : value;
    },
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value }) => {
      return formatNumber(value, 2);
    },
    className: 'textRight',
  },
  {
    Header: 'Currency',
    accessor: 'invoice_currency',
    Cell: () => {
      return 'USD';
    },
  },
  {
    Header: 'Amount',
    accessor: 'amount_in_usd',
    Cell: ({ value }) => {
      return formatNumber(value, 2);
    },
    className: 'textRight',
  },
];

export const bankModalTableColumns = [
  {
    Header: 'Date',
    accessor: 'Date',
    Cell: (props) => convertToFullDate(props.value),
  },
  {
    Header: 'Description',
    accessor: 'Reference',
  },
  {
    Header: 'Currency',
    accessor: 'CurrencyCode',
    Cell: ({ value }) => {
      return typeof value === 'string' ? value.toUpperCase() : value;
    },
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ row }) => {
      const { Type, Total } = row.original;
      return formatNumber(Total, 2);
    },
    className: 'textRight',
  },
  {
    Header: 'Currency',
    accessor: 'invoice_currency',
    Cell: () => {
      return 'USD';
    },
  },
  {
    Header: 'Amount',
    accessor: 'amount_in_usd',
    Cell: () => {
      return '';
    },
    className: 'textRight',
  },
];

export const autoModalTableColumns = [
  {
    Header: 'Date',
    accessor: 'txn_created_date',
    Cell: (props) => convertToFullDate(props.value),
  },
  {
    Header: 'Currency',
    accessor: 'currency',
    Cell: ({ value }) => {
      return typeof value === 'string' ? value.toUpperCase() : value;
    },
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value }) => {
      return formatNumber(value, 2);
    },
    className: 'textRight',
  },
  {
    Header: 'Currency',
    accessor: 'invoice_currency',
    Cell: () => {
      return 'USD';
    },
  },
  {
    Header: 'Amount',
    accessor: 'amount_in_usd',
    Cell: ({ value }) => {
      return formatNumber(value, 2);
    },
    className: 'textRight',
  },
];
