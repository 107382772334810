import { dashboardUrl } from ".";
import { getCompanyId } from '@api/cookies';
import { getRequest, postFileRequest, deleteRequest } from '@api/requests';
import { COMPANY_FILE_TYPE } from '@constants/file';

const uploadFiles = async (category, id, fileType, files, referenceId = '') => {
  return await postFileRequest(
    `${dashboardUrl}/document/${category}/${id}/${fileType}${referenceId && `/${referenceId}`}`,
    files,
  );
};

export const uploadCompanyFiles = async (fileType, file, referenceId) => {
  const companyId = getCompanyId();
  return await uploadFiles('company', companyId, fileType, file, referenceId);
};

export const getCompanyFilesByType = async (fileType, referenceId = '') => {
  const companyId = getCompanyId();
  return await getRequest(
    `${dashboardUrl}/document/company/${companyId}/${fileType}${referenceId && `/${referenceId}`}`,
  );
};

export const getPNLFiles = async () => {
  return await getCompanyFilesByType(COMPANY_FILE_TYPE.PNL);
};

export const getBankStatementFiles = async (bankId) => {
  return await getCompanyFilesByType(COMPANY_FILE_TYPE.BANK_STATEMENT, bankId);
};

export const getBusinessOwnerFiles = async (businessOwnerId) => {
  return await getCompanyFilesByType(COMPANY_FILE_TYPE.BUSINESS_OWNER, businessOwnerId);
};

export const getBusinessOwnerEntityFiles = async (businessOwnerEntityId) => {
  return await getCompanyFilesByType(COMPANY_FILE_TYPE.BUSINESS_OWNER_ENTITY, businessOwnerEntityId);
};

export const getIncorporationFiles = async () => {
  return await getCompanyFilesByType(COMPANY_FILE_TYPE.INCORPORATION);
};

export const getOtherFiles = async () => {
  return await getCompanyFilesByType(COMPANY_FILE_TYPE.OTHER);
};

export const getAllCompanyFiles = async () => {
  const companyId = getCompanyId();
  return await getRequest(`${dashboardUrl}/document/company/${companyId}/all`);
};

export const deleteFileById = async (fileId) => {
  const companyId = getCompanyId();
  return await deleteRequest(`${dashboardUrl}/document/company/${companyId}/${fileId}`);
};
