import { LOGIN_USER, LOGOUT_USER } from '../auth/constants';
import { GET_USER_COMPANY_SUCCESS } from '../company/constants';
import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  CLEAN_USER_PROFILE,
  RESET_USER,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
} from './constants';

export const initialState = {
  isLoading: false,
  profile: {
    id: '',
    firstName: '',
    lastName: '',
    mobilePhone: '',
    optInEmails: false,
    userRole: '',
    adminRole: {
      name: '',
      permissions: [],
    },
    username: '',
    status: '',
    applicationSubmitted: false,
  },
  users: [],
};

const userReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_ALL_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        isLoading: false,
      };
    case CLEAN_USER_PROFILE:
      return {
        ...state,
        profile: initialState.profile,
      };
    case GET_USER_COMPANY_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          companyId: action.payload.id,
        },
      };
    case GET_ALL_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case RESET_USER:
      return initialState;
    case LOGIN_USER:
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
