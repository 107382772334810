import React, { useState, useEffect } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Avatar from '../../../../components/Avatar';
import Button from '../../../../components/Button';
import Textarea from '../../../../components/Textarea';
import Logger from '../../../../utils/logger';
import Comment from './Comment';
import { ButtonWrapper } from './components';
import { createOffersComment, getOffersComments } from '@api/modules/dashboard/comment';

const Wrapper = styled.div`
  margin: 16px 0;
`;

const Header = styled.div`
  ${(props) => props.theme.text.four};
  margin-bottom: 24px;
`;

const Form = styled.form`
  width: 70%;
  min-width: 500px;
  display: inline-block;
`;

const AvatarWrapper = styled.span`
  width: 20%;
`;

const StyledAvatar = styled(Avatar)`
  vertical-align: top !important;
  margin: 2px 16px 0 0;
`;

const ToggleListButtonWrapper = styled.div`
  width: 70%;
  text-align: center;
`;

const SHORTENED_LIST_LENGTH = 3;

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.micro};
  ${(props) => props.theme.text.error};
`;

const CommentSection = ({ applicationId }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const userProfile = useSelector((state) => state.user.profile);
  const [comments, setComments] = useState();
  const [isLoading, toggleIsLoading] = useState(false);
  const [isExpanded, toggleIsExpanded] = useState(false);
  const [apiError, setApiError] = useState();

  const fetchComments = async () => {
    const { data } = await getOffersComments(applicationId);

    if (Array.isArray(data)) {
      setComments(data);
    }
  };

  useEffect(() => {
    fetchComments();
  }, []);

  const onSubmit = async ({ body }) => {
    try {
      toggleIsLoading(true);
      setApiError('');
      const { statusCode } = await createOffersComment(applicationId, { body });
      if (statusCode === 201) {
        await fetchComments();
        reset({ body: '' });
      } else {
        throw new Error('Something went wrong');
      }
    } catch (err) {
      setApiError(err.message);
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const toggleList = () => {
    toggleIsExpanded(!isExpanded);
  };

  return (
    <Wrapper>
      <Header>Comments</Header>
      <AvatarWrapper>
        <StyledAvatar id={userProfile.id} useRandomColor name={userProfile.firstName} size={30} />
      </AvatarWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="body"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Textarea {...field} placeholder="Add a comment" rows={2} hasError={errors.body || apiError} full />
          )}
        />
        {errors.body?.type === 'required' && <ErrorMessage>Required</ErrorMessage>}
        {apiError && <ErrorMessage>{apiError}</ErrorMessage>}
        <ButtonWrapper>
          <Button width={100} type="submit" disabled={isLoading}>
            Save
          </Button>
        </ButtonWrapper>
      </Form>
      {Array.isArray(comments) &&
        comments.length > 0 &&
        comments.slice(0, isExpanded ? comments.length : SHORTENED_LIST_LENGTH).map((comment) => {
          return (
            <Comment key={comment.message_id} userId={userProfile.id} fetchComments={fetchComments} {...comment} />
          );
        })}
      {Array.isArray(comments) && comments.length > SHORTENED_LIST_LENGTH && (
        <ToggleListButtonWrapper>
          <Button onClick={toggleList} small tertiary>
            {isExpanded ? 'Show less' : 'Show more'}
          </Button>
        </ToggleListButtonWrapper>
      )}
    </Wrapper>
  );
};

export default CommentSection;
