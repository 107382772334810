// This cookie used to store user jwt after login
export const ACCESS_TOKEN_NAME = 'choco_access_token';
export const REFRESH_TOKEN_NAME = 'choco_refresh_token';
export const TARGET_COMPANY_ID_NAME = 'target_company_id';
export const USER_COMPANY_ID_NAME = 'user_company_id';
export const USER_ID_NAME = 'user_id';
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || 'localhost';

export const CONTENT_TYPE = {
  APPLICATION_JSON: 'application/json',
  FILE: 'multipart/form-data',
};
export const CONTENT_TYPE_APPLICATION_JSON = 'application/json';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

export const API_AUTH_PORT = process.env.REACT_APP_API_AUTH_PORT || '';
export const API_INTEGRATION_PORT = process.env.REACT_APP_API_INTEGRATION_PORT || '';
export const API_APPLICATION_PORT = process.env.REACT_APP_API_APPLICATION_PORT || '';
export const API_DASHBOARD_PORT = process.env.REACT_APP_API_DASHBOARD_PORT || '';
export const API_PAYMENT_PORT = process.env.REACT_APP_REACT_APP_API_PAYMENT_PORT || '';
export const WEB_APP_URL = process.env.REACT_APP_WEB_APP_URL || 'http://localhost:3000';
export const API_GA_INTEGRATION = process.env.REACT_APP_API_GA_INTEGRATION;
export const API_WC_INTEGRATION = process.env.REACT_APP_API_WC_INTEGRATION;
export const API_MAGENTO_INTEGRATION = process.env.REACT_APP_API_MAGENTO_INTEGRATION;
export const API_FB_INTEGRATION = process.env.REACT_APP_API_FB_INTEGRATION;
export const API_SHOPIFY_INTEGRATION = process.env.REACT_APP_API_SHOPIFY_INTEGRATION;
export const REACT_APP_API_CAL_ENGINE = process.env.REACT_APP_API_CAL_ENGINE;
export const API_PORTAL_PORT = process.env.REACT_APP_API_PORTAL_PORT;
