import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, LabelList, Tooltip } from 'recharts';
import styled from 'styled-components';

import Button from '../../../components/Button';
import Header from '../../../components/Header';
import Mask from '../../../components/Mask';
import COLORS from '../../../styles/colors';
import Logger from '../../../utils/logger';
import { GrayCard } from '../components';
import {
  TAB,
  TAB_OPTIONS,
  VIEW_ALL_OPTION,
  CONTENT_CONTAINER_ID,
  ENABLED_SALES_PLATFORM_OPTIONS,
  ENABLED_PAYMENT_PLATFORM_OPTIONS,
  ENABLED_ANALYTICS_PLATFORM_OPTIONS,
} from '../constants';
import PATH from '../path';
import Filters from './components/Filters';
import { formatAnalyticsData, priceTickFormatter, getYAxisDomain, isMaskOverlayActive, getIntegratedPlatforms } from './helpers';
import { getAnalytics, getAnalyticData } from '@api/modules/dashboard';
import { GridRow, GridColumn } from '@components/Grid';
import { Currency } from '@/constants';
import { PLATFORM } from '@constants/platform';
import { fetchIntegrations } from '@redux/modules/application/actions';
import { useSelectedCompanyId, useIsUnableToEditUnderAdminView } from '@redux/selectors';
import { media } from '@styles/breakpoints';
import { findFirstMatchInArray, isRealObject } from '@utils/dataTypes';
import { isBBMSLIntegrated } from '@utils/platformHelpers';
import { formatNumber, formatPrice } from '@utils/priceHelpers';

const defaultStyleConfig = {
  strokeWidth: 2,
  dot: false,
  type: 'linear',
  stroke: COLORS.purple,
};

const HeaderRow = styled.div`
  margin-bottom: 15px;
`;

const ConnectNowCard = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px 20px;
  border-radius: 5px;
`;

const ConnectCardTitle = styled.div`
  ${(props) => props.theme.text.four};
  font-weight: bold;
  padding-top: 5px;
  margin-bottom: 5px;
`;

const ButtonWrapper = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: end;

  ${media.desktop`
    justify-content: start;
  `};

  ${media.dablet`
    justify-content: start;
  `};
`;

const Card = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 5px;
  padding: 50px 90px;
`;

const CardDescription = styled.div`
  ${(props) => props.theme.text.micro};
  font-weight: bold;
  color: ${(props) => props.theme.colors.grayThree};
`;

const ChartWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const Title = styled.div`
  ${(props) => props.theme.text.three};
  color: ${(props) => props.theme.colors.black};
  font-weight: bold;
  padding-top: 7px;
  margin-bottom: 10px;
  text-align: center;
`;

export const NO_DATA_MESSAGE = {
  placeholder: 'No connected channel',
  cta_message: 'Connect Now',
};

const DEFAULT_CURRENCY = Currency.USD;
const DEFAULT_CATEGORY = TAB.SALES;
const DEFAULT_PLATFORM = VIEW_ALL_OPTION.value;

const Analytics = () => {
  const history = useHistory();
  const selectedCompanyId = useSelectedCompanyId();
  const [revenue, setRevenue] = useState([]);
  const [revenueSum, setRevenueSum] = useState();
  const [refund, setRefund] = useState([]);
  const [refundSum, setRefundSum] = useState();
  const [order, setOrder] = useState([]);
  const [orderSum, setOrderSum] = useState();
  const [customer, setCustomer] = useState([]);
  const [customerSum, setCustomerSum] = useState();
  const [currency, setCurrency] = useState(DEFAULT_CURRENCY);
  const [category, setCategory] = useState(DEFAULT_CATEGORY);
  const [store, setStore] = useState(DEFAULT_PLATFORM);
  const [isSettingFilter, toggleIsSettingFilter] = useState(false);
  const integrations = useSelector((state) => state.application.integrations);
  const disableIntegrationButton = useIsUnableToEditUnderAdminView();
  const [isMaskOverlay, toggleIsMaskOverlay] = useState(false);

  const yAxisDataKey = 'Total';

  const findPlatformFromStoreId = (storeId) => {
    if (storeId === VIEW_ALL_OPTION.value) {
      return VIEW_ALL_OPTION.value;
    }
    return findFirstMatchInArray(integrations, (account) => account.id === store).platformName;
  };

  const getPaymentOptions = () => {
    return [VIEW_ALL_OPTION, ...ENABLED_PAYMENT_PLATFORM_OPTIONS].filter((option) =>
      isBBMSLIntegrated(integrations) ? true : option.value !== PLATFORM.BBMSL
    );
  };

  const OPTIONS = {
    [TAB.SALES]: [VIEW_ALL_OPTION, ...ENABLED_SALES_PLATFORM_OPTIONS],
    [TAB.PAYMENT]: getPaymentOptions(),
    [TAB.ANALYTICS]: ENABLED_ANALYTICS_PLATFORM_OPTIONS,
  };

  // return platforms which are have status 1 && must not be manual integration or other integrations
  const connectedChannelAccounts = getIntegratedPlatforms(integrations);

  const getTabOptions = (options) => {
    // get all integrated platform with status = 1 and not manual or other platform, platformNames
    const connectedPlatformNames = connectedChannelAccounts.map(({ platformName }) => {
      return platformName;
    });

    return options
      .filter(({ value }) => {
        return value !== TAB.OVERVIEW;
      })
      .filter((option) => {
        const channelPlatformNames = OPTIONS[option.value]
          .map(({ platformName }) => platformName)
          .filter((name) => name !== VIEW_ALL_OPTION.value);

        let hasIntegrated = false;
        channelPlatformNames.forEach((name) => {
          if (connectedPlatformNames.indexOf(name) !== -1) {
            hasIntegrated = true;
          }
        });

        return hasIntegrated;
      });
  };

  const tabOptions = getTabOptions(TAB_OPTIONS);

  useEffect(() => {
    const selectedPlatformName = findPlatformFromStoreId(store);

    const isMaskActive = isMaskOverlayActive(category, selectedPlatformName, integrations);
    toggleIsMaskOverlay(isMaskActive);
  }, [category, store, JSON.stringify(integrations)]);

  useEffect(() => {
    if (Array.isArray(tabOptions) && tabOptions.length > 0) {
      setCategory(tabOptions[0].value);
    }
  }, [JSON.stringify(tabOptions)]);

  const handleCategoryChange = (value) => {
    setCategory(value);
  };

  const handlePlatformChange = (value) => {
    setStore(value);
  };

  const handleNoDataButtonClick = () => {
    history.push(PATH.INTEGRATION);
    document.getElementById(CONTENT_CONTAINER_ID)?.scroll(0, 0);
  };

  const getPlatformPayload = () => {
    if (store === VIEW_ALL_OPTION.value) {
      return [];
    } else {
      return [store];
    }
  };

  const getAccountId = () => {
    if (store === VIEW_ALL_OPTION.value) {
      return '';
    } else {
      return findFirstMatchInArray(integrations, (account) => account.id === store).accountId;
    }
  };

  const fetchStoreDataApi = async () => {
    try {
      const accountId = getAccountId();
      const { data = {}, error } = await getAnalyticData(category, encodeURIComponent(accountId), currency);

      if (error) {
        return;
      }

      if (isRealObject(data)) {
        setRevenue(data.revenue || []);
        setRevenueSum(data.revenueSum);
        setRefund(data.refund || []);
        setRefundSum(data.refundSum);
        setOrder(data.order || []);
        setOrderSum(data.orderSum);
        setCustomer(data.customer || []);
        setCustomerSum(data.customerSum);
      }
    } catch (err) {
      Logger.error(err);
    }
  };

  const fetchChannelDataApi = async () => {
    try {
      const platformPayload = getPlatformPayload();
      const { data = {}, error } = await getAnalytics(category, currency, platformPayload);

      if (error) {
        return;
      }

      if (isRealObject(data)) {
        setRevenue(data.revenue || []);
        setRevenueSum(data.revenueSum);
        setRefund(data.refund || []);
        setRefundSum(data.refundSum);
        setOrder(data.order || []);
        setOrderSum(data.orderSum);
        setCustomer(data.customer || []);
        setCustomerSum(data.customerSum);
      }
    } catch (err) {
      Logger.error(err);
    }
  };

  // wait for all search criteria are restored to default values to avoid multiple api calls
  useEffect(() => {
    if (isSettingFilter && currency === DEFAULT_CURRENCY && category === DEFAULT_CATEGORY && store === DEFAULT_PLATFORM) {
      toggleIsSettingFilter(false);
    }
  }, [isSettingFilter]);

  // restore to default criteria after having switched company
  useEffect(() => {
    if (selectedCompanyId) {
      toggleIsSettingFilter(true);
      setCurrency(DEFAULT_CURRENCY);
      setStore(DEFAULT_PLATFORM);
      setTimeout(() => {
        toggleIsSettingFilter(false);
      }, 10);
    }
  }, [selectedCompanyId]);

  useEffect(() => {
    if (category && store === VIEW_ALL_OPTION.value && !isSettingFilter) {
      fetchChannelDataApi();
    } else {
      fetchStoreDataApi();
    }
  }, [currency, category, store, isSettingFilter]);

  useEffect(() => {
    setStore(VIEW_ALL_OPTION.value);
  }, [category]);

  useEffect(() => {
    fetchIntegrations();
  }, []);

  return (
    <GrayCard>
      <GridColumn>
        <Header scale={3}>Key Highlights</Header>
      </GridColumn>
      <Filters
        tabOptions={tabOptions}
        currency={currency}
        category={category}
        platform={store}
        connectedChannelAccounts={connectedChannelAccounts}
        onCurrencyChange={setCurrency}
        onCategoryChange={handleCategoryChange}
        onPlatformChange={handlePlatformChange}
      />
      <Mask
        Placeholder={
          <Card>
            <Title>{NO_DATA_MESSAGE.placeholder}</Title>
            <Button full secondary onClick={handleNoDataButtonClick} disabled={disableIntegrationButton}>
              {NO_DATA_MESSAGE.cta_message}
            </Button>
          </Card>
        }
        active={isMaskOverlay}
      >
        {/* Sales data */}
        <GridColumn lg={6}>
          <ChartWrapper>
            <HeaderRow>
              <Header scale={5} highlight>
                Sales
              </Header>
            </HeaderRow>
            <HeaderRow>
              <Header scale={5}>{formatPrice(currency, revenueSum, false, 0)}</Header>
            </HeaderRow>
            <ResponsiveContainer height={300}>
              {(() => {
                const data = formatAnalyticsData(revenue, 2);
                const yAxisData = data.map((item) => item[yAxisDataKey]);
                const yAxisDomain = getYAxisDomain(yAxisData);
                return (
                  <LineChart data={data}>
                    <XAxis dataKey="date" />
                    <YAxis
                      dataKey={yAxisDataKey}
                      padding={{
                        top: 20,
                      }}
                      tickFormatter={priceTickFormatter}
                      domain={yAxisDomain}
                    >
                      <LabelList />
                    </YAxis>
                    <Tooltip />
                    <Line dataKey={yAxisDataKey} {...defaultStyleConfig} />
                  </LineChart>
                );
              })()}
            </ResponsiveContainer>
          </ChartWrapper>
        </GridColumn>
        {/* Refund Data */}
        <GridColumn lg={6}>
          <ChartWrapper>
            <HeaderRow>
              <Header scale={5} highlight>
                Refunds
              </Header>
            </HeaderRow>
            <HeaderRow>
              <Header scale={5}>{formatPrice(currency, refundSum, false, 0)}</Header>
            </HeaderRow>
            <ResponsiveContainer height={300}>
              {(() => {
                const data = formatAnalyticsData(refund, 2);
                const yAxisData = data.map((item) => item[yAxisDataKey]);
                const yAxisDomain = getYAxisDomain(yAxisData);
                return (
                  <LineChart data={data}>
                    <XAxis dataKey="date" />
                    <YAxis dataKey={yAxisDataKey} tickFormatter={priceTickFormatter} domain={yAxisDomain}>
                      <LabelList />
                    </YAxis>
                    <Tooltip />
                    <Line dataKey={yAxisDataKey} {...defaultStyleConfig} />
                  </LineChart>
                );
              })()}
            </ResponsiveContainer>
          </ChartWrapper>
        </GridColumn>
        <GridRow>
          <GridColumn lg={6}>
            <ChartWrapper>
              <HeaderRow>
                <Header scale={5} highlight>
                  Number of Orders
                </Header>
              </HeaderRow>
              <HeaderRow>
                <Header scale={5}>{formatNumber(orderSum, 0)}</Header>
              </HeaderRow>
              <ResponsiveContainer height={250}>
                {(() => {
                  const data = formatAnalyticsData(order, 0);
                  const yAxisData = data.map((item) => item[yAxisDataKey]);
                  const yAxisDomain = getYAxisDomain(yAxisData);
                  return (
                    <LineChart data={data}>
                      <XAxis dataKey="date" />
                      <YAxis dataKey={yAxisDataKey} domain={yAxisDomain} />
                      <Tooltip />
                      <Line dataKey={yAxisDataKey} {...defaultStyleConfig} />
                    </LineChart>
                  );
                })()}
              </ResponsiveContainer>
            </ChartWrapper>
          </GridColumn>
          <GridColumn lg={6}>
            <ChartWrapper>
              <HeaderRow>
                <Header scale={5} highlight>
                  Number of Customers
                </Header>
              </HeaderRow>
              <HeaderRow>
                <Header scale={5}>{formatNumber(customerSum, 0)}</Header>
              </HeaderRow>
              <ResponsiveContainer height={250}>
                {(() => {
                  const data = formatAnalyticsData(customer, 0);
                  const yAxisData = data.map((item) => item[yAxisDataKey]);
                  const yAxisDomain = getYAxisDomain(yAxisData);
                  return (
                    <LineChart data={data}>
                      <XAxis dataKey="date" />
                      <YAxis dataKey={yAxisDataKey} domain={yAxisDomain} />
                      <Tooltip />
                      <Line dataKey={yAxisDataKey} {...defaultStyleConfig} />
                    </LineChart>
                  );
                })()}
              </ResponsiveContainer>
            </ChartWrapper>
          </GridColumn>
        </GridRow>
      </Mask>
      {isMaskOverlay && (
        <GridColumn lg={12} md={12}>
          <ConnectNowCard>
            <GridColumn lg={10} md={12} disableGutter>
              <ConnectCardTitle>Connect channel</ConnectCardTitle>
              <CardDescription>Connect a new channel to view your revenue easily and Use Data to Grow Your Channel Faster.</CardDescription>
            </GridColumn>
            <GridColumn lg={2} md={12} disableGutter>
              <ButtonWrapper>
                <Button secondary onClick={handleNoDataButtonClick}>
                  Connect now
                </Button>
              </ButtonWrapper>
            </GridColumn>
          </ConnectNowCard>
        </GridColumn>
      )}
    </GrayCard>
  );
};

export default Analytics;
