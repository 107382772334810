import React, { useState, useEffect } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../components/Button';
import FormTitle from '../../../components/FormTitle';
import Input from '../../../components/Input';
import PasswordInput from '../../../components/PasswordInput';
import Logger from '../../../utils/logger';
import ApplicationPath from '../../Application/path';
import Banner from '../components/Banner';
import {
  Form,
  FormRow,
  Tagline,
  Error,
  Container,
  ContentDiv,
  CenterDiv,
  ChocoUpLogo,
  Title,
} from '../components/styled';
import SwitchForm from '../components/SwitchForm';
import Path from '../path';
import { emailLogin } from '@api/modules/auth';
import { XERO_LOGIN } from '@constants/campaign';
import { useQuery } from '@hooks/routing';
import { loginUser } from '@redux/modules/auth/actions';
import { fetchUser } from '@redux/modules/user/actions';
import { media } from '@styles/breakpoints';

const ForgotPasswordLabel = styled(Link)`
  ${(props) => props.theme.text.link};
  font-size: 14px;
`;

const LoginContainer = styled(Container)`
  ${media.dablet`
    height: 100vh;
  `};
`;

const StyledContentDiv = styled(ContentDiv)`
  height: 50vh;
`;

const Login = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });
  const { parsed } = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const [apiError, setApiError] = useState();
  const [isLoading, toggleIsLoading] = useState();
  const isXEROLogin = parsed.utm_campaign === XERO_LOGIN;

  // handle xero
  useEffect(() => {
    try {
      if (isXEROLogin && parsed.info) {
        const decodedString = decodeURIComponent(escape(window.atob(parsed.info)));

        const { email } = JSON.parse(decodedString);
        setValue('username', email);
      }
    } catch (err) {
      Logger.error(err);
    }
  }, []);

  const onSubmit = async ({ username, password }) => {
    try {
      toggleIsLoading(true);
      setApiError('');
      const { error, statusCode, data } = await emailLogin({
        username,
        password,
      });
      dispatch(loginUser());
      await fetchUser(); // will trigger fetching company in App.jsx

      history.push(data ?? ApplicationPath.SALES_ACCOUNTS);
    } catch (err) {
      setApiError(err.message);
      toggleIsLoading(false);
    }
  };

  return (
    <>
      <Banner />
      <LoginContainer>
        <StyledContentDiv>
          <ChocoUpLogo src="/images/choco-up-black-no-title.png" alt="Choco Up Logo" />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Title>Welcome Back!</Title>
            <Tagline>Enter you account details to log into Choco Up.</Tagline>

            <FormRow>
              <FormTitle scale={5}>Company Email Address</FormTitle>
              <Controller
                name="username"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Joe@company.com"
                    hasError={errors.username}
                    autoComplete="on"
                    disabled={isLoading}
                    full
                  />
                )}
              />
            </FormRow>

            <FormRow>
              <FormTitle scale={5}>Password</FormTitle>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <PasswordInput
                    {...field}
                    hasError={errors.password}
                    type="password"
                    autoComplete="on"
                    disabled={isLoading}
                    full
                  />
                )}
              />
            </FormRow>
            <Error>{apiError}</Error>
            <FormRow>
              <Button disabled={!isValid || isLoading} type="submit" full>
                Log in
              </Button>
            </FormRow>
            <CenterDiv>
              <ForgotPasswordLabel to={Path.FORGOT_PASSWORD}>Forgot Password?</ForgotPasswordLabel>
              <SwitchForm target={Path.REGISTER_BUSINESS_INFO} />
            </CenterDiv>
          </Form>
        </StyledContentDiv>
      </LoginContainer>
    </>
  );
};

export default Login;
