import React, { ForwardedRef, forwardRef, useCallback } from 'react';

import styled, { css } from 'styled-components';

import COLORS from '../styles/colors';

type StyledInputProps = {
  full?: boolean;
  isLoading?: boolean;
  outline?: boolean;
  fieldType?: string;
};

const StyledInput = styled.input`
  border: 1px solid ${COLORS.grayTwo};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px 16px;
  line-height: 24px;
  font-size: 15px;

  ${(props: StyledInputProps) =>
    props.full &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.hasError &&
    css`
      border: 1px solid ${COLORS.red};
      box-shadow: inset 1px 0 ${COLORS.red}, inset 0 1px ${COLORS.red}, inset -1px 0 ${COLORS.red}, inset 0 -1px ${COLORS.red};
      color: ${COLORS.red};
    `}

  :focus {
    border: 1px solid ${COLORS.purple};
    box-shadow: inset 1px 0 ${COLORS.black}, inset 0 1px ${COLORS.black}, inset -1px 0 ${COLORS.black}, inset 0 -1px ${COLORS.black};
    color: ${COLORS.black};
  }

  :focus-visible {
    outline: none;
  }

  ::placeholder {
    color: ${COLORS.grayTwo};
  }

  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type='number'] {
    -moz-appearance: textfield;
  }

  ${(props: StyledInputProps) =>
    !props.outline &&
    css`
      border: none;
      box-shadow: none;
      :focus {
        border: none;
        box-shadow: none;
      }
    `}

  ${(props: StyledInputProps) =>
    props.isLoading &&
    css`
      .react-select__control {
        opacity: 0.5;
      }
    `}
`;
export const FormError = styled.span`
  color: ${COLORS.red};
  font-size: 14px;
  font-weight: 600;
`;
const Footer = styled.div`
  ${(props) => props.theme.input.footer};
`;

const Input = forwardRef(
  (
    {
      value,
      onChange = () => {},
      placeholder,
      hasError,
      className,
      type = 'text',
      autoComplete = 'off',
      outline = true,
      footer,
      fieldType,
      errorMsg = '',
      ...restProps
    }: StyledInputProps,
    ref: ForwardedRef<any>
  ) => {
    const renderErrorMsg = useCallback(() => {
      if (!hasError) {
        return;
      }
      // Priority default error
      if (fieldType === 'text' && hasError?.type === 'required') {
        return <FormError>Required</FormError>;
      }

      // Custom error, priority custom msg
      if (errorMsg) {
        return <FormError>{errorMsg}</FormError>;
      }
      if (fieldType === 'text' && hasError?.type === 'pattern') {
        return <FormError>Incorrect input format</FormError>;
      }
    }, [hasError, errorMsg, hasError?.type]);

    return (
      <>
        <StyledInput
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          hasError={hasError}
          className={className}
          ref={ref}
          autoComplete={autoComplete}
          outline={outline}
          {...restProps}
        />
        {renderErrorMsg()}
      </>
    );
  }
);

export default Input;
