import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import Logger from '../../../../utils/logger';
import { Description } from '../../components';
import ProfitLossTable from './component/ProfitLossTable';
import { PERIOD_LENGTH_OPTIONS } from './constant';
import { generateMonths } from './helpers';
import { getXeroReport, getXeroAvailableMonths } from '@api/modules/integration';
import { Currency } from '@/constants';
import { PLATFORM } from '@constants/platform';
import { isRealObject } from '@utils/dataTypes';
import { convertToMonthYear, convertToApiDate } from '@utils/dateHelpers';

const FilterSection = styled.span`
  display: flex;
  margin: 24px 0;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
`;

const StyledSelect = styled(Select)`
  width: ${(props) => props.width};
  font-weight: 600;
  min-width: 150px;
`;

const FilterLabel = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  margin-bottom: 4px;
  font-weight: 600;
  text-align: left;
`;

const StyledButton = styled(Button)`
  margin-bottom: 2px;
  height: 48px;
`;

const Container = styled.div`
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.black};
  ${(props) => props.theme.text.four};
  font-weight: 600;
  text-align: center;
`;

const ProfitLoss = () => {
  const [isLoading, toggleIsLoading] = useState(false);
  const [mostRecentAvailableMonth, setMostRecentAvailableMonth] = useState();
  const [earliestAvailableMonth, setEarliestAvailableMonth] = useState();
  const [tableData, setTableData] = useState([]);
  const [duration, setDuration] = useState(PERIOD_LENGTH_OPTIONS[0].value);
  const integrations = useSelector((state) => state.application.integrations);
  const xeroAccounts = integrations.filter(({ platformName }) => platformName === PLATFORM.XERO);
  const xeroAccountOptions = xeroAccounts.map((account) => {
    return { value: account.accountId, label: account.accountName };
  });
  const [selectedXeroAccountId, setSelectedXeroAccountId] = useState();
  const [currency, setCurrency] = useState();
  const currencyOptions = [{ value: currency, label: currency }];
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  // find starting month based on duration
  const startMonth = moment(mostRecentAvailableMonth).subtract(duration, 'months').add(1, 'months');

  const months = generateMonths(earliestAvailableMonth);

  const monthOptions = months.reverse().map((month) => {
    return { value: month, label: convertToMonthYear(month) };
  });

  const handleApply = async () => {
    toggleIsLoading(true);
    try {
      const formattedStartMonth = moment(startMonth).startOf('month');
      const formattedEndMonth = moment(mostRecentAvailableMonth).endOf('month');
      const payload = {
        report_type: 'PnL',
        account_id: selectedXeroAccountId,
        start_date: convertToApiDate(formattedStartMonth),
        end_date: convertToApiDate(formattedEndMonth),
      };

      const { data } = await getXeroReport(payload);

      if (isRealObject(data)) {
        setStartDate(data?.condition?.start_date);
        setEndDate(data?.condition?.end_date);
        setTableData(data?.result);
        setCurrency(data?.condition?.currency);
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchAvailableMonths = async () => {
      try {
        const { data } = await getXeroAvailableMonths(xeroAccounts?.[0]?.accountId, 'PnL');
        if (Array.isArray(data)) {
          setEarliestAvailableMonth(data[0].start_date);
          setMostRecentAvailableMonth(data[0].end_date);
        }
      } catch (err) {
        Logger.error(err);
      }
    };
    fetchAvailableMonths();
  }, [JSON.stringify(xeroAccounts)]);

  useEffect(() => {
    const prefillSelectedXeroAccountId = () => {
      if (xeroAccounts.length > 0) {
        setSelectedXeroAccountId(xeroAccounts[0].accountId);
      }
    };
    try {
      toggleIsLoading(true);
      prefillSelectedXeroAccountId();
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  }, [JSON.stringify(xeroAccounts)]);

  useEffect(() => {
    const fetchProfitLoss = async () => {
      const formattedEndMonth = moment(mostRecentAvailableMonth).endOf('month');
      const formattedStartMonth = moment(mostRecentAvailableMonth).subtract(duration, 'months').add(1, 'months').startOf('month');

      const payload = {
        report_type: 'PnL',
        account_id: selectedXeroAccountId,
        start_date: convertToApiDate(formattedStartMonth),
        end_date: convertToApiDate(formattedEndMonth),
      };

      const { data } = await getXeroReport(payload);

      if (isRealObject(data)) {
        setStartDate(data?.condition?.start_date);
        setEndDate(data?.condition?.end_date);
        setTableData(data?.result);
        setCurrency(data?.condition?.currency);
      }
    };
    fetchProfitLoss();
  }, [selectedXeroAccountId]);

  const handleDurationChange = (value) => {
    setDuration(value);
  };

  return (
    <>
      <FilterSection>
        <SelectWrapper>
          <FilterLabel>Account</FilterLabel>
          <StyledSelect
            width="250"
            value={selectedXeroAccountId}
            options={xeroAccountOptions}
            onChange={setSelectedXeroAccountId}
            disabled={xeroAccounts.length === 0 || isLoading}
          />
        </SelectWrapper>
      </FilterSection>
      <FilterSection>
        <SelectWrapper>
          <FilterLabel>Period End</FilterLabel>
          <StyledSelect
            width="200"
            value={mostRecentAvailableMonth}
            options={monthOptions}
            onChange={setMostRecentAvailableMonth}
            disabled={xeroAccounts.length === 0 || isLoading}
          />
        </SelectWrapper>
        <SelectWrapper>
          <FilterLabel>Period Length</FilterLabel>
          <StyledSelect
            width="200"
            value={duration}
            options={PERIOD_LENGTH_OPTIONS}
            onChange={handleDurationChange}
            disabled={xeroAccounts.length === 0 || isLoading}
          />
        </SelectWrapper>
        <SelectWrapper>
          <FilterLabel>Currency</FilterLabel>
          <StyledSelect value={currency} options={currencyOptions} disabled />
        </SelectWrapper>
        <StyledButton disabled={xeroAccounts.length === 0 || isLoading} onClick={handleApply}>
          Apply
        </StyledButton>
      </FilterSection>
      {tableData.length === 0 ? (
        <Container>
          <Title>No Data</Title>
          <Description>Please try again later</Description>
        </Container>
      ) : (
        <ProfitLossTable data={tableData} startDate={startDate} endDate={endDate} isLoading={isLoading} />
      )}
    </>
  );
};

export default ProfitLoss;
