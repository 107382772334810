import React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import CreateButton from './CreateButton';
import SettlementTable from './SettlementTable';
import { STATUS_CODE } from '@constants/application';
import { useCanAdminHandleSettlement } from '@utils/userHelpers';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 10px 24px;
  :after {
    clear: both;
    content: '';
    display: block;
  }

  span {
    line-height: 42px; // align with create button
  }
`;

const Title = styled.span`
  ${(props) => props.theme.text.three};
  line-height: 42px; // align with create button
`;

const AdminPanel = ({ settlements, bankAccounts }) => {
  const { statusCode } = useSelector((state) => state.application.data);
  const isReadyToCreate = statusCode >= STATUS_CODE.PENDING_FUND_DISBURSEMENT;
  const cannotHandleSettlement = !useCanAdminHandleSettlement();
  return Array.isArray(settlements) && settlements.length > 0 ? (
    <div>
      <div>
        <Title>Active Revenue Records</Title>
        <CreateButton bankAccounts={bankAccounts} disabled={!isReadyToCreate || cannotHandleSettlement} />
      </div>
      <SettlementTable items={settlements} />
    </div>
  ) : (
    <Wrapper>
      <span>You have no revenue record now</span>
      <CreateButton bankAccounts={bankAccounts} disabled={!isReadyToCreate || cannotHandleSettlement} />
    </Wrapper>
  );
};

export default AdminPanel;
