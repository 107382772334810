import React, { useRef } from 'react';

import styled, { css } from 'styled-components';

import ToolTip from './ToolTip';
import { MANUAL_INTEGRATION_PLATFORMS } from '@constants/platform';

export const LogoWrapper = styled.div`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 200px;
  height: 80px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.grayTwo};
  border-radius: 5px;
  margin: 0 25px 25px 0;
  background-color: white;

  ${(props) =>
    props.disabled
      ? css`
          opacity: 0.6;
          cursor: not-allowed;
        `
      : css`
          :hover {
            box-shadow: inset 1px 0 ${props.theme.colors.grayTwo}, inset 0 1px ${props.theme.colors.grayTwo},
              inset -1px 0 ${props.theme.colors.grayTwo}, inset 0 -1px ${props.theme.colors.grayTwo};
          }
        `}
`;

export const Logo = styled.img`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: bold;

  width: 150px;
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `};
`;

const PlatformLogo = ({ value, disabled, onClick, logoUrl, displayName, width, message }) => {
  const ref = useRef();
  return (
    <ToolTip
      innerRef={ref}
      tip={message}
      placeholder={
        <LogoWrapper key={value} onClick={onClick} disabled={disabled}>
          <Logo src={logoUrl} alt={displayName} width={width} />
        </LogoWrapper>
      }
    />
  );
};

export default PlatformLogo;
