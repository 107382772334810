import moment from 'moment';

import { convertToApiDate } from '@utils/dateHelpers';

export const generateMonths = (start) => {
  if (start && moment(start).isValid() && typeof start !== 'number') {
    return Array.from({ length: moment(new Date()).diff(start, 'month') + 1 }).map((_, index) =>
      convertToApiDate(moment(start).add(index, 'month')),
    );
  }
  return [];
};
