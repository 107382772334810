import React from 'react';

import ReactSelect, { components as ReactSelectComponents } from 'react-select';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';

import CheckBox from './CheckBox';
import { getSelectedOptionValue } from './helpers';
import { dynamicStyles, baseSelectStyles } from './styles';

const StyledSelect = styled(ReactSelect)`
  ${baseSelectStyles};
`;

const StyledAsyncSelect = styled(AsyncSelect)`
  ${baseSelectStyles};
`;

const BadgeLabel = styled.div`
  ${(props) => props.theme.text.micro};
  color: ${(props) => props.theme.colors.grayThree};
  padding: 5px;
`;

const Footer = styled.div`
  ${(props) => props.theme.input.footer};
`;

const getMultiSelectLabel = (items, multiValueLabel) => {
  const length = items.length;
  const label = length < 2 ? items[0].label : `${length} ${multiValueLabel}`;

  return <BadgeLabel>{label}</BadgeLabel>;
};

const MultiValue = ({ index, getValue, selectProps, ...restProps }) => {
  const { maxToShow, value, multiValueLabel } = selectProps;

  return index < maxToShow ? (
    <ReactSelectComponents.MultiValue {...restProps} />
  ) : index === maxToShow ? (
    getMultiSelectLabel(value, multiValueLabel)
  ) : null;
};

const Option = ({ isSelected, label, ...restProps }) => {
  return (
    <div>
      <ReactSelectComponents.Option {...restProps}>
        <CheckBox checked={isSelected} onChange={() => null} />
        <label>{label}</label>
      </ReactSelectComponents.Option>
    </div>
  );
};

const Select = ({
  value,
  options,
  disabled,
  onChange = () => {},
  innerRef,
  components,
  isMulti,
  closeMenuOnSelect,
  styles,
  maxToShow = 0,
  hasError,
  type,
  multiValueLabel = 'items',
  footer,
  async,
  ...restProps
}) => {
  const selectedOption = getSelectedOptionValue(value, options, isMulti);

  const handleChange = (option) => {
    if (isMulti) {
      onChange(option);
    } else {
      onChange(option?.value ?? null);
    }
  };

  const ReactComponent = async ? StyledAsyncSelect : StyledSelect;
  return (
    <>
      <ReactComponent
        ref={innerRef}
        closeMenuOnSelect={!isMulti}
        classNamePrefix="react-select"
        components={{
          IndicatorSeparator: () => null,
          ...(isMulti === true && { MultiValue, Option }),
          ...components,
        }}
        value={selectedOption}
        options={options}
        hideSelectedOptions={false}
        onChange={handleChange}
        isDisabled={disabled}
        hasError={hasError}
        styles={{ ...dynamicStyles, ...styles }}
        isMulti={isMulti}
        maxToShow={maxToShow}
        multiValueLabel={multiValueLabel}
        {...restProps}
      />
      {footer && <Footer>{footer}</Footer>}
    </>
  );
};

export default Select;
