import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import BreadCrumb from '../../../../components/BreadCrumb';
import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Checkbox';
import HR from '../../../../components/HR';
import StepIndicator from '../../../../components/StepIndicator';
import Logger from '../../../../utils/logger';
import { MESSAGE_MODAL } from '../../../Modal/constants';
import { Wrapper, Card, PageHeader } from '../../components';
import PATH from '../../path';
import { getWalletSignUpRequest, getWalletOnboarding } from '@api/modules/integration';
import { WALLET_ACCOUNT_STATUS } from '@/constants';
import { useIntegrationChannel } from '@hooks/channel';
import { media } from '@styles/breakpoints';
import { fetchWalletData } from '@redux/modules/wallet/actions';
import { showModal } from '@redux/modules/UI/actions';
import { useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';

const Title = styled.div`
  ${(props) => props.theme.text.four};
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
  margin-bottom: 12px;
`;

const Description = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  font-weight: 600;
  margin: 20px 0;
  width: 80%;

  ${media.desktop`
    width: 100%;
  `};
`;

const ButtonWrapper = styled.span`
  float: right;
  margin-left: 10px;

  ${media.desktop`
    margin-top: 10px;
  `};
`;

const StepsDescription = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.desktop`
    flex-direction: column;
    align-items: start;
  `};
`;

const StepsWrapper = styled.div`
  display: flex;
  margin: 12px 0;
`;

const StepsDescriptionWrapper = styled.div`
  margin-left: 8px;
`;

const CardHeader = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  padding-bottom: 6px;
  font-weight: 600;
`;

const AgreementWrapper = styled.div`
  ${media.desktop`
    flex-direction: column;
    align-items: start;
  `};
`;

const AgreementContainer = styled.div`
  margin: 32px 0;
`;

const AgreementDescription = styled.span`
  color: ${(props) => props.theme.colors.black};
  ${(props) => props.theme.text.five};
  font-weight: 600;
  padding-left: 8px;
`;

const TextLink = styled.a`
  ${(props) => props.theme.text.link};
  display: inline-block;
`;

const FlexDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const WalletSetup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const walletAccounts = useSelector((state) => state.wallet.walletAccounts);
  const isInitiating = useSelector((state) => state.wallet.isInitiating);
  const [isLoading, toggleIsLoading] = useState(false);
  const [isEligible, toggleIsEligible] = useState(Array.isArray(walletAccounts) && walletAccounts.length > 0);
  const [isApplicationRejected, toggleIsApplicationRejected] = useState(false);
  const [isPendingApproval, toggleIsPendingApproval] = useState();
  const [isAccountActive, toggleIsAccountActive] = useState(false);
  const [isAgreementChecked, toggleIsAgreementChecked] = useState(false);
  const isAdminViewingAnotherCompany = useCheckIsAdminViewingAnotherCompany();

  const channel = null;

  const reloadPage = () => {
    history.go(0);
  };

  useIntegrationChannel(channel, reloadPage);

  const signUpRequestApi = async () => {
    try {
      const { statusCode, error } = await getWalletSignUpRequest();
      if (statusCode === 201) {
        toggleIsEligible(true);
      }
      if (error) {
        throw new Error(error);
      }
    } catch (err) {
      Logger.error(err);
    }
  };

  const viewWalletSetupProgress = async () => {
    toggleIsLoading(true);
    await signUpRequestApi();
    await fetchWalletData();
    dispatch(
      showModal(MESSAGE_MODAL, {
        title: 'Your request was sent to our team successfully',
        description:
          'We will get back to you once we confirm the eligibility of activating the account, subject to the business model and your operating countries (regions)',
        showRightButton: false,
        showLeftButton: false,
      })
    );
    toggleIsLoading(false);
  };

  const viewSetupResult = () => {
    dispatch(
      showModal(MESSAGE_MODAL, {
        title: 'Your business is not eligible to setup wallet account',
        description:
          'Unfortunately, your business is not eligible to setup wallet account at the moment. The availability may vary due to your business model and the country you operated in.',
        rightButtonProps: {
          label: 'Notify me once it’s available',
        },
        leftButtonProps: {
          label: 'Close',
        },
        showCloseButton: true,
      })
    );
  };

  const onboardWallet = async () => {
    toggleIsLoading(true);
    try {
      const { data, error } = await getWalletOnboarding();
      const windowId = 'Airwallex';
      const newWin = window.open('', windowId, 'fullscreen');
      const url = data.url;
      newWin.location.href = url;
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  useEffect(() => {
    if (walletAccounts[0]?.airwallex_status === 'SUBMITTED') {
      toggleIsPendingApproval(true);
    }
  }, [JSON.stringify(walletAccounts)]);

  useEffect(() => {
    switch (walletAccounts[0]?.status) {
      case WALLET_ACCOUNT_STATUS.PENDING:
        toggleIsEligible(true);
        break;
      case WALLET_ACCOUNT_STATUS.ACTIVE:
        toggleIsEligible(true);
        toggleIsAccountActive(true);
        break;
      case WALLET_ACCOUNT_STATUS.REJECTED:
        toggleIsApplicationRejected(true);
        break;
      default:
        break;
    }
  }, [JSON.stringify(walletAccounts)]);

  const handleAgreementClick = () => {
    toggleIsAgreementChecked(!isAgreementChecked);
  };

  return (
    <>
      <BreadCrumb
        items={[
          {
            value: PATH.WALLET_OVERVIEW,
            label: 'Overview',
          },
          {
            label: 'Setup wallet account',
          },
        ]}
      />
      <PageHeader>Setup wallet account</PageHeader>
      <Wrapper>
        <Card>
          <Title>Setup Guide:</Title>
          <Description>Create your free borderless account in 2 simple steps.</Description>
          <HR color="grayOne" />
          <Container>
            <StepsWrapper>
              <div>
                <StepIndicator highlight stepNumber={1} showTick={isAccountActive} showStepNumber />
              </div>
              <StepsDescriptionWrapper>
                <CardHeader>Check if your business is eligible to setup wallet account</CardHeader>
                <StepsDescription>The availability may vary due to your business model and the country you operated in.</StepsDescription>
              </StepsDescriptionWrapper>
            </StepsWrapper>
            {!isPendingApproval && (
              <ButtonWrapper>
                {isApplicationRejected ? (
                  <Button width={155} full onClick={viewSetupResult} disabled={isAdminViewingAnotherCompany}>
                    View Result
                  </Button>
                ) : (
                  <Button
                    width={155}
                    full
                    onClick={viewWalletSetupProgress}
                    disabled={isEligible || isInitiating || isLoading || isAdminViewingAnotherCompany}
                  >
                    {isEligible ? 'Request Sent' : 'Send Request'}
                  </Button>
                )}
              </ButtonWrapper>
            )}
          </Container>
          <HR color="grayOne" />
          <AgreementWrapper>
            <StepsWrapper>
              <div>
                <StepIndicator highlight={isAccountActive} stepNumber={2} showTick={isPendingApproval} showStepNumber />
              </div>
              <FlexDiv>
                <StepsDescriptionWrapper>
                  <CardHeader>Setup wallet account</CardHeader>
                  <StepsDescription>Connect to wallet account.</StepsDescription>
                </StepsDescriptionWrapper>
                {isPendingApproval ||
                  (isEligible && (
                    <ButtonWrapper>
                      <Button
                        width={155}
                        full
                        onClick={onboardWallet}
                        disabled={!isAccountActive || isPendingApproval || !isAgreementChecked || isAdminViewingAnotherCompany}
                      >
                        Activate now
                      </Button>
                    </ButtonWrapper>
                  ))}
              </FlexDiv>
            </StepsWrapper>
            {isInitiating ||
              (isEligible && walletAccounts[0]?.status === WALLET_ACCOUNT_STATUS.ACTIVE && walletAccounts[0]?.airwallex_status === null && (
                <AgreementContainer>
                  <Checkbox onClick={handleAgreementClick} checked={isAgreementChecked} disabled={isAdminViewingAnotherCompany} />
                  <AgreementDescription>
                    I agree to{' '}
                    <TextLink
                      // Todo: once confirmed replace url with new for T&C and privacy policy and merchant scale terms
                      target="_blank"
                      href={
                        walletAccounts[0]?.region === 'SG'
                          ? 'https://www.airwallex.com/sg/terms/payout-and-fx'
                          : 'https://www.airwallex.com/hk/terms/payout-and-fx'
                      }
                    >
                      Terms and Condition
                    </TextLink>
                    ,{' '}
                    <TextLink
                      // Todo: once confirmed replace url with new for T&C and privacy policy and merchant scale terms
                      target="_blank"
                      href={
                        walletAccounts[0]?.region === 'SG'
                          ? 'https://www.airwallex.com/sg/terms/privacy-policy'
                          : 'https://www.airwallex.com/hk/terms/privacy-policy'
                      }
                    >
                      Privacy policy
                    </TextLink>{' '}
                    and{' '}
                    <TextLink
                      // Todo: once confirmed replace url with new for T&C and privacy policy and merchant scale terms
                      target="_blank"
                      href={
                        walletAccounts[0]?.region === 'SG'
                          ? 'https://www.airwallex.com/hk/terms/merchant-scale-terms'
                          : 'https://www.airwallex.com/hk/terms/merchant-scale-terms'
                      }
                    >
                      Merchant Scale Terms
                    </TextLink>{' '}
                    of Choco Up & Airwallex
                  </AgreementDescription>
                </AgreementContainer>
              ))}
          </AgreementWrapper>
          <HR color="grayOne" />
          {isPendingApproval && (
            <Container>
              <StepsWrapper>
                <div>
                  <StepIndicator highlight={isAccountActive} stepNumber={3} showStepNumber />
                </div>
                <div>
                  <StepsDescriptionWrapper>
                    <CardHeader>Pending Approval</CardHeader>
                    <StepsDescription>Application currently undergoing Airwallex KYC review. Please come back later.</StepsDescription>
                  </StepsDescriptionWrapper>
                </div>
              </StepsWrapper>
            </Container>
          )}
        </Card>
      </Wrapper>
    </>
  );
};

export default WalletSetup;
