import { css } from 'styled-components';

import COLORS from './colors';

const theme = {
  colors: COLORS,
  gutter: 24,
  mobile: {
    gutter: 20,
  },
  ellipsis: css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `,
  formTitle: css`
    margin: 4px 0;
  `,
  error: css`
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: ${COLORS.red};
  `,
  text: {
    one: css`
      font-size: 48px;
      line-height: 62px;
      font-weight: 600;
    `,
    two: css`
      font-size: 34px;
      line-height: 44px;
      font-weight: 600;
    `,
    three: css`
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    `,
    four: css`
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    `,
    five: css`
      font-size: 14px;
      line-height: 120%;
    `,
    body: css`
      font-size: 16px;
      line-height: 140%;
    `,
    micro: css`
      font-size: 12px;
      line-height: 16px;
    `,
    link: css`
      color: ${COLORS.orange};
      font-weight: 600;
      cursor: pointer;
    `,
    error: css`
      font-weight: 600;
      color: ${COLORS.red};
    `,
    button: css`
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0125em;
      text-transform: capitalize;
      padding: 14.5px 21px;
    `,
  },
  form: {
    field: css`
      margin: 15px 0;
    `,
  },
  input: {
    border: css`
      border: 1px solid ${COLORS.grayTwo};
    `,
    borderFocus: css`
      border: 1px solid ${COLORS.black};
      box-shadow: inset 1px 0 ${COLORS.black}, inset 0 1px ${COLORS.black}, inset -1px 0 ${COLORS.black},
        inset 0 -1px ${COLORS.black};
      color: ${COLORS.black};
    `,
    borderError: css`
      border: 1px solid ${COLORS.red};
      box-shadow: inset 1px 0 ${COLORS.red}, inset 0 1px ${COLORS.red}, inset -1px 0 ${COLORS.red},
        inset 0 -1px ${COLORS.red};
      color: ${COLORS.red};
    `,
    footer: css`
      color: ${(props) => props.theme.colors.grayTwo};
      ${(props) => props.theme.text.micro};
      div {
        display: inline-block;
        vertical-align: middle;
      }
    `,
  },
  iconPlusText: css`
    * {
      vertical-align: middle;
    }

    > *:first-child {
      margin-right: 8px;
    }
  `,
  table: css`
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    background-color: ${(props) => props.theme.colors.white};

    th {
      font-weight: 600;
      padding: 10px 16px;
      ${(props) => props.theme.text.five};
      color: ${(props) => props.theme.colors.grayThree};
    }

    td {
      ${(props) => props.theme.text.micro};
      padding: 8px 16px;
      border-top: 1px solid ${(props) => props.theme.colors.grayOne};
    }
  `,
  buttonWrapper: css`
    text-align: right;
    button ~ button {
      margin-left: 16px;
    }
  `,
};

export default theme;
