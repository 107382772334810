import { isRealNumber } from '@utils/dataTypes';
import { adjustStripeTimeStamp, convertToFullDate } from '@utils/dateHelpers';

export const formatLines = (currentData = [], precedingData = [], decimalScale) => {
  return currentData.map((item, index) => {
    return {
      current_date: convertToFullDate(new Date(adjustStripeTimeStamp(item.timestamp))),
      current_amount: isRealNumber(item.amount) ? item.amount.toFixed(decimalScale) : item.amount,
      preceding_date: convertToFullDate(new Date(adjustStripeTimeStamp(precedingData[index]?.timestamp))),
      preceding_amount: isRealNumber(precedingData[index]?.amount)
        ? precedingData[index]?.amount.toFixed(decimalScale)
        : precedingData[index]?.amount,
    };
  });
};

export const formatPercentageChange = (currentAmount, PrecedingAmount) => {
  const newNumber = Number(currentAmount);
  const originalNumber = Number(PrecedingAmount);
  if (originalNumber === 0 && newNumber === 0) {
    return 0;
  } else if (isRealNumber(originalNumber) && originalNumber === 0) {
    return 100;
  }
  const increasedAmount = (newNumber - originalNumber) / originalNumber;
  const results = increasedAmount * 100;
  return results;
};

// for demo purpose only
// re construct data from getStripeOverviewData to have displayable
export const prefillMockData = (type, data) => {
  let multi = 10;
  switch (type) {
    case 'new_customers':
      multi = 100;
      break;
    case 'gross_volume':
      multi = 10000;
      break;
    case 'successful_payment':
      multi = 150;
      break;
    case 'net_volume':
      multi = 10000;
      break;
    default:
      break;
  }

  const currentItems = data.current.data.map((item) => ({
    ...item,
    amount: Math.floor(Math.random() * multi + multi),
  }));
  const currentAmountsSum = currentItems.map(({ amount }) => amount).reduce((prev, curr) => prev + curr, 0);

  const previousItems = data.previous.data.map((item) => ({
    ...item,
    amount: Math.floor(Math.random() * multi + multi),
  }));
  const previousAmountsSum = previousItems.map(({ amount }) => amount).reduce((prev, curr) => prev + curr, 0);

  return {
    ...data,
    current: {
      ...data.current.data,
      data: currentItems,
      sum: currentAmountsSum,
    },
    previous: {
      ...data.current.data,
      data: previousItems,
      sum: previousAmountsSum,
    },
  };
};
