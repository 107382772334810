import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import Select from '../../../../../../components/Select';
import { countryMap } from '@/constants';
import { fetchWalletBeneficiaries } from '@redux/modules/wallet/actions';
import { useWalletAccountId } from '@redux/selectors';
import { padStart, includes } from '@utils/stringHelpers';

const RecipientOptionLabel = styled.div`
  div:first-child {
    ${(props) => props.theme.text.body};
    font-weight: 700px;
  }

  div:last-child {
    ${(props) => props.theme.text.micro};
    font-weight: 600px;
  }
`;

export const StyledSelect = styled(Select)`
  .react-select__control {
    min-height: 60px;
  }
`;

const RecipientSelect = ({ beneficiaryId, setRecipientId, ...restProps }) => {
  const walletAccountId = useWalletAccountId();
  const { beneficiaryPageMap, isLoading } = useSelector((state) => state.wallet);
  const [options, setOptions] = useState([]);

  const filterRecipients = (option, keyword) => {
    const {
      beneficiary: { bank_details, company_name, first_name, last_name },
      nickname,
      payment_methods,
    } = option?.data?.original || {};
    const maskedAccountNumber = padStart(bank_details.account_number, 4);
    const checkProps = [
      nickname,
      company_name,
      countryMap[bank_details.bank_country_code],
      bank_details.bank_country_code,
      bank_details.account_currency,
      payment_methods[0],
      bank_details.local_clearing_system,
      maskedAccountNumber,
      `${first_name} ${last_name}`,
    ]
      .filter((_props) => !!_props)
      .map((item) => includes(item, keyword));

    return checkProps.some((prop) => prop === true);
  };

  const getOptionLabel = ({ original }) => {
    const { bank_details } = original.beneficiary;
    const maskedAccountNumber = padStart(bank_details.account_number, 4);
    const additionalInfo = [
      countryMap[bank_details.bank_country_code] || bank_details.bank_country_code,
      bank_details.account_currency,
      original.payment_methods[0],
      bank_details.local_clearing_system,
      maskedAccountNumber,
    ].filter((item) => !!item);
    return (
      <RecipientOptionLabel>
        <div>
          {original.nickname || original.beneficiary.company_name || `${original.beneficiary.first_name} ${original.beneficiary.last_name}`}
        </div>
        <div>{_.flatten(additionalInfo).join(' ‧ ')}</div>
      </RecipientOptionLabel>
    );
  };

  const onMenuScrollToBottom = () => {
    const pages = Object.keys(beneficiaryPageMap)
      .map((pageNumber) => Number(pageNumber))
      .filter((pageNumber) => Array.isArray(beneficiaryPageMap[pageNumber]) && beneficiaryPageMap[pageNumber].length > 0)
      .sort((a, b) => {
        return a - b;
      });
    fetchWalletBeneficiaries(walletAccountId, { pageSize: 20, pageNumber: Math.max(pages.length + 1, 1) });
  };

  useEffect(() => {
    const pageNumbers = Object.keys(beneficiaryPageMap)
      .map((pageNumber) => Number(pageNumber))
      .filter((pageNumber) => Array.isArray(beneficiaryPageMap[pageNumber]) && beneficiaryPageMap[pageNumber].length > 0)
      .sort((a, b) => {
        return a - b;
      });

    const nextOptions = [];
    pageNumbers.forEach((num) => {
      const beneficiaries = beneficiaryPageMap[num];
      if (Array.isArray(beneficiaries)) {
        const converted = beneficiaries.map((bene) => {
          return {
            value: bene.beneficiary_id,
            label: bene.beneficiary.company_name,
            original: bene,
          };
        });
        nextOptions.push(...converted);
      }
    });

    setOptions(nextOptions);
  }, [JSON.stringify(beneficiaryPageMap)]);

  return (
    <StyledSelect
      value={beneficiaryId}
      options={options}
      onChange={setRecipientId}
      getOptionLabel={getOptionLabel}
      filterOption={filterRecipients}
      placeholder="Select Recipient"
      onMenuScrollToBottom={onMenuScrollToBottom}
      isLoading={isLoading}
      {...restProps}
    />
  );
};

export default RecipientSelect;
