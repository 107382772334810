import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import PATH from '../path';
import BalanceTransactionList from './BalanceTransaction/BalanceTransactionList';
import CreateManualInvoiceContainer from './CreateManualInvoice';
import InvoiceDetail from './InvoiceDetail';
import Overview from './Overview';
import { fetchInvoices, fetchBalanceTransactions } from '@redux/modules/fund/actions';

const CapitalContainer = () => {
  const { applicationId, fundingNumber } = useSelector((state) => state.application.data);
  useEffect(() => {
    if (applicationId) {
      fetchInvoices();
      fetchBalanceTransactions(fundingNumber);
    }
  }, [applicationId]);

  return (
    <Switch>
      <Route path={PATH.CAPITAL_MANUAL_INVOICE} component={CreateManualInvoiceContainer} />
      <Route exact path={PATH.CAPITAL_INVOICE_DETAIL} component={InvoiceDetail} />
      <Route exact path={PATH.CAPITAL_BALANCE_TRANSACTIONS} component={BalanceTransactionList} />
      <Route exact path={PATH.CAPITAL_PAST_FUNDING} component={Overview} />
      <Route exact path={PATH.CAPITAL} component={Overview} />
      <Redirect to={PATH.CAPITAL} />
    </Switch>
  );
};

export default CapitalContainer;
