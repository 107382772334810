import { LOGIN_USER, LOGOUT_USER } from '../auth/constants';
import { SELECT_COMPANY_REQUEST } from '../company/constants';
import { SWITCH_TO_ADMIN_VIEW, SWITCH_TO_USER_VIEW } from '../UI/constants';
import {
  GET_PAYMENTS_INTENTS_REQUEST,
  GET_PAYMENTS_INTENTS_SUCCESS,
  GET_PAYMENTS_INTENTS_FAILURE,
  GET_PAYOUTS_REQUEST,
  GET_PAYOUTS_SUCCESS,
  GET_PAYOUTS_FAILURE,
  GET_STRIPE_TRANSACTIONS_REQUEST,
  GET_STRIPE_TRANSACTIONS_SUCCESS,
  GET_STRIPE_TRANSACTIONS_FAILURE,
  GET_STRIPE_ACCOUNT_REQUEST,
  GET_STRIPE_ACCOUNT_SUCCESS,
  GET_STRIPE_ACCOUNT_FAILURE,
  GET_PAYMENT_INTENT_DETAIL_REQUEST,
  GET_PAYMENT_INTENT_DETAIL_SUCCESS,
  GET_PAYMENT_INTENT_DETAIL_FAILURE,
} from './constants';

const initialState = {
  isInitiating: true,
  isLoading: false,
  stripeAccounts: [],
  paymentIntentPageMap: {
    1: [],
  },
  paymentIntentDetailMap: {},
  payoutPageMap: {
    1: [],
  },
  transactionPageMap: {
    1: [],
  },
};

const paymentReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_STRIPE_ACCOUNT_REQUEST:
    case GET_PAYMENTS_INTENTS_REQUEST:
    case GET_PAYOUTS_REQUEST:
    case GET_STRIPE_TRANSACTIONS_REQUEST:
    case GET_PAYMENT_INTENT_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_STRIPE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isInitiating: false,
        stripeAccounts: action.payload,
        isLoading: false,
      };
    case GET_PAYMENTS_INTENTS_SUCCESS:
      return {
        ...state,
        paymentIntentPageMap: {
          ...state.paymentIntentPageMap,
          [action.payload.pageNumber]: action.payload.data,
        },
        isLoading: false,
      };

    case GET_PAYOUTS_SUCCESS:
      return {
        ...state,
        payoutPageMap: {
          ...state.payoutPageMap,
          [action.payload.pageNumber]: action.payload.data,
        },
        isLoading: false,
      };
    case GET_STRIPE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionPageMap: {
          ...state.transactionPageMap,
          [action.payload.pageNumber]: action.payload.data,
        },
        isLoading: false,
      };
    case GET_PAYMENT_INTENT_DETAIL_SUCCESS:
      return {
        ...state,
        paymentIntentDetailMap: {
          ...state.paymentIntentDetailMap,
          [action.payload.id]: action.payload.data,
        },
      };
    case GET_STRIPE_ACCOUNT_FAILURE:
    case GET_PAYMENTS_INTENTS_FAILURE:
    case GET_PAYOUTS_FAILURE:
    case GET_STRIPE_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isInitiating: false,
        isLoading: false,
      };
    // case SWITCH_TO_ADMIN_VIEW:
    // case SELECT_COMPANY_REQUEST:
    case SWITCH_TO_USER_VIEW:
    case LOGIN_USER:
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default paymentReducer;
