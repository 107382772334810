import React, { useEffect, useState, useRef } from 'react';

import _ from 'lodash';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import ToolTip from '../../../../components/ToolTip';
import { getFeeByMethod } from '../helpers';
import { useOnClickOutside } from '@hooks/UI';
import { getOptionByValue } from '@utils/optionHelpers';
import { formatPrice } from '@utils/priceHelpers';

const Wrapper = styled.div`
  ${(props) =>
    props.isLoading &&
    css`
      opacity: 0.5;
    `}
`;

const Menu = styled.div`
  max-height: 2000px;
  transition: max-height 0.35s ease-in;
  ${(props) =>
    props.isMenuOpen
      ? css``
      : css`
          max-height: 0;
          transition: max-height 0.35s ease-out;
          overflow: hidden;
        `}
`;

const Placeholder = styled.div`
  padding: 24px;
  border: 1px solid ${(props) => props.theme.colors.grayTwo};
  border-radius: 5px;
  ${(props) => props.theme.text.micro};
  color: ${(props) => props.theme.colors.grayTwo};
`;

const OptionWrapper = styled.div`
  margin-bottom: 8px;
  padding: 14px;
  background-color: ${(props) => props.theme.colors.grayOne};
  border-radius: 5px;

  ${(props) =>
    !props.disabled &&
    css`
      cursor: pointer;
      :hover {
        margin-bottom: 12px;
        ${props.theme.input.borderFocus};
      }
    `}
  .title {
    ${(props) => props.theme.text.body};
    font-weight: 700;
  }

  table {
    width: 100%;

    th,
    td {
      width: 33%;
    }
  }
`;

const ConvertedFee = styled.div`
  > span,
  > img {
    vertical-align: middle;
  }

  span ~ img {
    margin-left: 4px;
  }
`;

const TransferMethodSelect = (props) => {
  const {
    sourceCurrency,
    paymentCurrency,
    bankCountryCode,
    swiftChargeOption,
    options = [],
    value,
    onChange,
    disabled,
    isLoading,
    isMenuOpen,
    toggleIsMenuOpen,
  } = props;

  const ref = useRef();
  const transferFee = useSelector((state) => state.wallet.transferFee) || {};
  const foreignExchange = useSelector((state) => state.wallet.foreignExchange) || {};
  const feeFX = foreignExchange[`${sourceCurrency}toUSD`];

  useOnClickOutside(ref, () => {
    if (!disabled) {
      toggleIsMenuOpen(false);
    }
  });

  const Option = ({ option, onClick = () => {} }) => {
    const { value: optionValue, label, description, processing_time, payment_limit } = option;
    const descriptionItems = description.split('\n');
    const fee =
      transferFee[bankCountryCode] && transferFee[bankCountryCode][paymentCurrency]
        ? getFeeByMethod(transferFee[bankCountryCode][paymentCurrency], optionValue, swiftChargeOption)
        : '';

    return (
      <OptionWrapper key={optionValue} onClick={onClick} disabled={disabled}>
        <div className="title">{label}</div>
        {Array.isArray(descriptionItems) &&
          descriptionItems.map((item) => (
            <>
              {item}
              <br />
            </>
          ))}
        <table>
          <thead>
            <th>Transfer fee</th>
            <th>Speed</th>
            <th>Transfer limit</th>
          </thead>
          <tbody>
            <tr>
              <td>
                {!feeFX || (paymentCurrency && !sourceCurrency) || sourceCurrency === 'USD' ? (
                  <div>{formatPrice('USD', fee)}</div>
                ) : (
                  <>
                    {feeFX && (
                      <ToolTip
                        width={200}
                        tip="Actual converted fee amount to your sending currency will be based on the real-time FX rate at settlement."
                        placeholder={
                          <ConvertedFee>
                            <span>{formatPrice(sourceCurrency, fee * (feeFX.client_rate || 1))}</span>
                            <img src="/icons/tool_tip.svg" alt="toolTip" />
                          </ConvertedFee>
                        }
                      />
                    )}
                    <div>{`(${formatPrice('USD', fee)})`}</div>
                  </>
                )}
              </td>
              <td>{processing_time}</td>
              <td>{payment_limit}</td>
            </tr>
          </tbody>
        </table>
      </OptionWrapper>
    );
  };

  const openMenu = () => {
    if (!disabled) {
      toggleIsMenuOpen(!isMenuOpen);
    }
  };

  const handleOptionClick = (optionValue) => (event) => {
    // if (!disabled) {
    onChange(optionValue);
    toggleIsMenuOpen(false);
    // }
  };

  return (
    <Wrapper isLoading={isLoading}>
      {Array.isArray(options) && options.length > 0 && (
        <div>
          {(() => {
            const selectedOption = getOptionByValue(value, options);

            if (selectedOption) {
              return <Option option={selectedOption} onClick={openMenu} />;
            }

            return null;
          })()}
          {Array.isArray(options) &&
            (() => {
              const remainingOptions = options.filter((option) => (value ? option.value !== value : true));

              return (
                <Menu isMenuOpen={isMenuOpen}>
                  {remainingOptions.length > 0 ? (
                    remainingOptions.map((option) => {
                      return <Option option={option} onClick={handleOptionClick(option.value)} />;
                    })
                  ) : (
                    <Placeholder onClick={() => toggleIsMenuOpen(false)}>No other payment options</Placeholder>
                  )}
                </Menu>
              );
            })()}
        </div>
      )}
    </Wrapper>
  );
};

export default TransferMethodSelect;
