import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../../components/Button';
import HR from '../../../../../components/HR';
import Input from '../../../../../components/Input';
import LoadingCircle from '../../../../../components/LoadingCircle';
import Modal from '../../../../../components/Modal';
import RadioButton from '../../../../../components/Radio';
import Select from '../../../../../components/Select';
import Logger from '../../../../../utils/logger';
import { globalAccountsRegions, globalAccountsOptions } from '../../constants';
import CreateGlobalAccountSuccess from './CreateGlobalAccountSuccess';
import { createGlobalAccounts } from '@api/modules/integration/airwallex';
import { isRealObject } from '@utils/dataTypes';

const SectionHeader = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
  margin-bottom: 12px;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
`;

const Description = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.greyThree};
`;

const Title = styled.span`
  ${(props) => props.theme.text.five};
  padding: 0 5px;
  color: ${(props) => props.theme.colors.greyThree};
  font-weight: 600;
`;

const Container = styled.div`
  margin: 16px 0;
  display: flex;
  vertical-align: middle;
`;

const InputContainer = styled.div`
  margin: 12px 30px;
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const Wrapper = styled.span`
  vertical-align: middle;
  display: flex;
`;

const Header = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: 600;
`;

export const Label = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  margin-bottom: 8px;
  font-weight: 600;
  text-align: left;
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CopyButton = styled.button`
  border: none;
  background: white;

  :hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 26px;
  justify-content: end;
`;

const RadioDescription = styled.span`
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.error};
`;

const CreateGlobalAccountsModal = ({ id, hideModal }) => {
  const dispatch = useDispatch();
  const [isLoading, toggleIsLoading] = useState(false);
  const [steps, setSteps] = useState(1);
  const [country, setCountry] = useState();
  const [currency, setCurrency] = useState();
  const [accountNickName, setAccountNickName] = useState();
  const [displayName, setDisplayName] = useState();
  const [accountDetails, setAccountDetails] = useState();
  const [apiError, setApiError] = useState('');

  const [isInput, toggleIsInput] = useState(false);

  const fetchApi = async () => {
    try {
      toggleIsLoading(true);
      setApiError('');
      const payload = {
        country_code: country,
        currency,
        nick_name: accountNickName,
      };
      const { data, statusCode } = await createGlobalAccounts(id, payload);
      if (statusCode === 500) {
        setApiError('Something went wrong, cannot create account please try again');
      }
      if (isRealObject(data)) {
        setAccountDetails(data);
        setSteps(3);
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const handleNext = () => {
    setApiError('');
    setSteps(2);
  };

  const handleCreateGlobalAccount = () => {
    fetchApi();
  };

  const handleCurrencySelect = (value, label, currencyCode) => () => {
    if (value === 'HK') {
      toggleIsInput(true);
      setDisplayName(label);
      setCountry(value);
    } else {
      setDisplayName(label);
      toggleIsInput(false);
      setCurrency(currencyCode);
      setCountry(value);
    }
  };

  const handleAccountNickName = (event) => {
    setAccountNickName(event.target.value);
  };

  const handleBack = () => {
    setSteps(1);
  };

  return (
    <Modal showCloseButton>
      {steps === 1 ? (
        <>
          <SectionHeader>Create a new global account</SectionHeader>
          <Header>Where do you want to open your bank account?</Header>
          <HR color="grayOne" />
          <Description>Select from a wide range of bank account locations to facilitate your collections</Description>
          {globalAccountsRegions.map(({ label, value, description, currencyCode }) => {
            return (
              <>
                {value === 'HK' ? (
                  <>
                    <Container>
                      <RadioButton name="globalAccount" value={value} checked={country === value} onClick={handleCurrencySelect(value)} />
                      <RadioDescription onClick={handleCurrencySelect(value, label, currencyCode)}>
                        <Title>{label}</Title>
                        {description}
                      </RadioDescription>
                    </Container>
                    {isInput && (
                      <InputContainer>
                        <Label>Account currency*</Label>
                        <Select
                          options={globalAccountsOptions}
                          width="200px"
                          value={currency}
                          onChange={setCurrency}
                          disabled={isLoading}
                        />
                      </InputContainer>
                    )}
                  </>
                ) : (
                  <Container>
                    <RadioButton
                      name="globalAccount"
                      value={value}
                      checked={country === value}
                      onClick={handleCurrencySelect(value, label, currencyCode)}
                    />
                    <RadioDescription onClick={handleCurrencySelect(value, label, currencyCode)}>
                      <Title>{label}</Title>
                      {description}
                    </RadioDescription>
                  </Container>
                )}
              </>
            );
          })}
          <ButtonWrapper>
            <Button
              width={155}
              onClick={handleNext}
              disabled={country === undefined || (country === globalAccountsRegions[0]?.value && currency === undefined)}
            >
              Next
            </Button>
          </ButtonWrapper>
        </>
      ) : steps === 2 ? (
        <>
          {isLoading ? (
            <LoadingCircle />
          ) : (
            <>
              <SectionHeader>Create a new global account</SectionHeader>
              <Header>{currency && `Let's create a ${currency} account in ${displayName}`}</Header>
              <HR color="grayOne" />
              <Label>Account nickname*</Label>
              <Input
                value={accountNickName}
                onChange={handleAccountNickName}
                full
                placeholder="Please specify account nickname for your global account"
              />
              <ErrorMessage>{apiError}</ErrorMessage>
              <ButtonWrapper>
                <StyledButton width={155} secondary onClick={handleBack}>
                  Back
                </StyledButton>
                <StyledButton width={155} disabled={accountNickName === undefined || isLoading} onClick={handleCreateGlobalAccount}>
                  Create
                </StyledButton>
              </ButtonWrapper>
            </>
          )}
        </>
      ) : steps === 3 ? (
        <>
          <CreateGlobalAccountSuccess id={id} country={displayName} accountDetails={accountDetails} />
          <ButtonWrapper>
            <StyledButton width={155} onClick={() => dispatch(hideModal())}>
              Done
            </StyledButton>
          </ButtonWrapper>
        </>
      ) : null}
    </Modal>
  );
};

export default CreateGlobalAccountsModal;
