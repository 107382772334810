import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useLocation, Switch, Route, Redirect } from 'react-router-dom';
import styled, { css } from 'styled-components';

import LoadingCircle from '../../../../components/LoadingCircle';
import UnderlineTab from '../../../../components/UnderlineTab';
import Logger from '../../../../utils/logger';
import { Wrapper, PageHeader } from '../../components';
import PATH from '../../path';
import { WALLET_TAB_OPTIONS } from '../constants';
import WalletBalance from '../IntegratedOverview/Balance/WalletBalance';
import WalletBalanceCurrency from '../IntegratedOverview/Balance/WalletBalanceCurrency';
import WalletTransactions from '../IntegratedOverview/Transactions/WalletTransactions';
import Onboarding from '../Onboarding';
import { WALLET_ACCOUNT_STATUS } from '@/constants';

const Tab = styled(UnderlineTab)`
  margin-bottom: 16px;
  ${(props) => props.theme.text.three};
`;

const Overview = () => {
  const history = useHistory();
  const location = useLocation();
  const walletAccounts = useSelector((state) => state.wallet.walletAccounts);
  const [isInitiating, toggleIsInitiating] = useState(true);
  const [isWalletReady, toggleIsWalletReady] = useState(false);

  useEffect(() => {
    toggleIsInitiating(true);
    try {
      if (
        Array.isArray(walletAccounts) &&
        walletAccounts[0]?.airwallex_account_id !== null &&
        walletAccounts[0]?.status === WALLET_ACCOUNT_STATUS.ACTIVE &&
        walletAccounts[0]?.airwallex_status !== null &&
        walletAccounts[0]?.airwallex_status !== 'CREATED' &&
        walletAccounts[0]?.airwallex_status !== 'SUBMITTED'
      ) {
        toggleIsWalletReady(true);
      } else {
        toggleIsWalletReady(false);
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsInitiating(false);
    }
  }, [JSON.stringify(walletAccounts)]);

  const handleTabChange = (value) => {
    history.push(value);
  };

  return isInitiating ? (
    <LoadingCircle />
  ) : (
    <>
      {isWalletReady ? (
        <>
          {WALLET_TAB_OPTIONS.map(({ value, label }) => {
            return (
              <Tab selected={location.pathname.indexOf(value) !== -1} onClick={() => handleTabChange(value)}>
                {label}
              </Tab>
            );
          })}
          <Switch>
            <Route path={PATH.WALLET_BALANCES_CURRENCY} component={WalletBalanceCurrency} />
            <Route path={PATH.WALLET_BALANCES} component={WalletBalance} />
            <Route path={PATH.WALLET_TRANSACTIONS} component={WalletTransactions} />
            <Redirect to={PATH.WALLET_BALANCES} />
          </Switch>
        </>
      ) : (
        <>
          <PageHeader>Overview</PageHeader>
          <Wrapper>
            <Onboarding />
          </Wrapper>
        </>
      )}
    </>
  );
};

export default Overview;
