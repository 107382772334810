import React from 'react';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../components/Button';
import Header from '../../../components/Header';
import Path from '../path';
import { media } from '@styles/breakpoints';

const Wrapper = styled.div`
  padding-top: 12px;

  ${media.phone`
    padding: 0;
    text-align: center;
  `};
`;

const Hint = styled(Header)`
  display: inline;
  font-size: 14px;
  line-height: 20px;
  margin-right: 2px;
`;

const RegisterLinkLabel = styled.a`
  ${(props) => props.theme.text.link};
  font-size: 14px;
`;

const SwitchForm = ({ target }) => {
  const history = useHistory();
  const goToRegister = target === Path.REGISTER_BUSINESS_INFO;
  const hint = goToRegister ? "Don't have an account yet?" : 'Already have an account?';

  const label = goToRegister ? 'Register' : 'Log In';

  const handleClick = () => {
    if (goToRegister) {
      history.push(Path.REGISTER_BUSINESS_INFO);
    } else {
      history.push(Path.LOGIN);
    }
  };

  return (
    <Wrapper>
      <Hint>{hint}</Hint>
      <RegisterLinkLabel onClick={handleClick}>{label}</RegisterLinkLabel>
    </Wrapper>
  );
};

export default SwitchForm;
