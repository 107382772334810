import React from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import PATH from '../../path';
import Detail from './Detail';
import List from './List';

const PaymentsContainer = () => {
  return (
    <Switch>
      {/* <Route path={PATH.PAYMENT_DETAIL} component={Detail} /> */}
      <Route exact path={PATH.PAYMENT_PAYMENTS} component={List} />
      <Redirect to={PATH.PAYMENT_PAYMENTS} />
    </Switch>
  );
};

export default PaymentsContainer;
