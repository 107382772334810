import React from 'react';

import ReactAvatar from 'react-avatar';

import COLORS from '../styles/colors';

export const AVATAR_SIZE = {
  SMALL: 20,
  MEDIUM: 25,
  LARGE: 50,
};

const Avatar = ({
  maxInitials = 1,
  color = COLORS.grayTwo,
  useRandomColor,
  round = true,
  textSizeRatio = 1.7,
  size = AVATAR_SIZE.SMALL,
  id,
  name,
  ...restProps
}) => {
  const colorCode = useRandomColor ? ReactAvatar.getRandomColor(id || name) : color;
  return (
    <ReactAvatar
      name={name}
      maxInitials={maxInitials}
      color={colorCode}
      round={round}
      textSizeRatio={textSizeRatio}
      size={size}
      {...restProps}
    />
  );
};

export default Avatar;
