import { isRealObject } from '@utils/dataTypes';

export const getSelectedOptionValue = (value, options, isMulti) => {
  if (Array.isArray(options) && options.length > 0) {
    if (isMulti) {
      return Array.isArray(value)
        ? value.map((item) => {
            if (!item.value) {
              const selectedOption = options.find((option) => option.value === item.value);
              return selectedOption;
            }

            return item;
          })
        : value;
    } else {
      if (typeof value === 'string' || typeof value === 'number') {
        const selectedOption = options.find((option) => option.value === value);
        return selectedOption;
      }
      if (isRealObject(value)) {
        return value;
      }
    }
  }

  return null;
};
