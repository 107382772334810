import React from 'react';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  ${(props) => props.theme.text.four};
  color: ${(props) => props.theme.colors.orange};
  font-weight: 600;
  margin-bottom: 24px;
  padding-left: 10px;
  display: inline-block;
  cursor: pointer;
`;

const Chevron = styled.span`
  margin: 0 2px;
`;

const BackButton = ({ text = 'Back', path = '' }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(path);
  };

  return (
    <Container onClick={handleClick}>
        <Chevron>{'<'}</Chevron>
        {text}
      </Container>
  );
};

export default BackButton;
