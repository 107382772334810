import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import PATH from '../../../path';
import CreateButton from './components/CreateButton';
import SettlementTable from './components/SettlementTable';
import { STATUS_CODE } from '@constants/application';
import { fetchSettlements } from '@redux/modules/settlements/actions';
import { useIsAdminView, useIsUserAssignedAdmin, useSelectedCompanyId } from '@redux/selectors';
import { useCheckRoleHasAllPermissions } from '@utils/userHelpers';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.grayOne};
  padding: 20px 24px;
`;

const Title = styled.span`
  ${(props) => props.theme.text.three};
  line-height: 43px;
  font-weight: 600;
`;

const BackIndicator = styled(Link)`
  ${(props) => props.theme.text.link};
  margin: 20px 0;
  display: inline-block;
`;

const Overview = () => {
  const settlements = useSelector((state) => state.settlement.list);
  const { statusCode } = useSelector((state) => state.application.data);
  const isReadyToCreate = statusCode >= STATUS_CODE.PENDING_FUND_DISBURSEMENT;
  const isAssignedAdmin = useIsUserAssignedAdmin();
  const isAdmin = useIsAdminView();
  const selectedCompanyId = useSelectedCompanyId();

  // workaround to avoid page crash upon refresh
  const cannotHandleSettlement = !(useCheckRoleHasAllPermissions(['handle_settlement']) && isAssignedAdmin);

  useEffect(() => {
    if (selectedCompanyId) {
      fetchSettlements(isAdmin);
    }
  }, [isAdmin, selectedCompanyId]);

  return (
    <Wrapper>
      <BackIndicator to={PATH.BUSINESS_BANK_TRANSACTIONS}>{'< Back to Bank Transaction list'}</BackIndicator>
      <div>
        <Title>Revenue record</Title>
        {isAdmin && <CreateButton disabled={!isReadyToCreate || cannotHandleSettlement} />}
      </div>
      <SettlementTable items={settlements} />
    </Wrapper>
  );
};

export default Overview;
