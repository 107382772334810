import { Currency, countryMap, PERIOD } from '.';

export const OPTION_VALUE_ALL = 'ALL';

export const currencyOptions = [
  {
    value: Currency.USD,
    label: Currency.USD,
  },
  {
    value: Currency.SGD,
    label: Currency.SGD,
  },
  {
    value: Currency.HKD,
    label: Currency.HKD,
  },
];

export const AllCurrencyOptions = Object.keys(Currency).map((value) => ({
  value,
  label: value,
}));

export const highlightedCountryOptions = [
  { value: 'SG', label: countryMap.SG },
  { value: 'HK', label: countryMap.HK },
  { value: 'US', label: countryMap.US },
  { value: 'TH', label: countryMap.TH },
  { value: 'PH', label: countryMap.PH },
  { value: 'VN', label: countryMap.VN },
  { value: 'ID', label: countryMap.ID },
  { value: 'MY', label: countryMap.MY },
  { value: 'TW', label: countryMap.TW },
];

const highlightedCountries = highlightedCountryOptions.map((option) => option.value);

const generateCountryOptions = () => {
  const result = [];
  for (const key in countryMap) {
    if (countryMap[key]) {
      if (highlightedCountries.indexOf(key) !== -1) {
        result.unshift({
          value: key,
          label: countryMap[key],
        });
      } else {
        result.push({
          value: key,
          label: countryMap[key],
        });
      }
    }
  }
  return result;
};
export const allCountryOptions = generateCountryOptions();

export const remainingCountryOptions = allCountryOptions
  .map((option) => {
    if (highlightedCountries.indexOf(option.value) === -1) {
      return option;
    }
    return undefined;
  })
  .filter((option) => !!option);

export const groupedCountryOptions = [
  {
    label: 'Common',
    options: highlightedCountryOptions,
  },
  {
    label: 'Remaining',
    enableSeparator: true,
    options: remainingCountryOptions,
  },
];

export const businessTypeOptions = [
  {
    value: 'E_COMMERCE',
    label: 'E-Commerce',
  },
  {
    value: 'D2C',
    label: 'D2C',
  },
  {
    value: 'SAAS',
    label: 'SaaS',
  },
  {
    value: 'SUBSCRIPTION',
    label: 'Subscription',
  },
  {
    value: 'MARKETPLACE',
    label: 'Marketplace',
  },
  {
    value: 'MOBILE_APP',
    label: 'Mobile App',
  },
  {
    value: 'OTHERS',
    label: 'Others',
  },
];

export const monthlyRevenueOptions = [
  {
    value: 'UNDER_10K',
    label: 'Under 10K',
  },
  {
    value: '10K_100K',
    label: '10K - 100K',
  },
  {
    value: '100K_300K',
    label: '100K - 300K',
  },
  {
    value: '300K_1M',
    label: '300K - 1M',
  },
  {
    value: '1M+',
    label: '1M+',
  },
];

export const leadSourceTypeOptions = [
  {
    value: 'LINKEDIN',
    label: 'LinkedIn',
  },
  {
    value: 'FACEBOOK',
    label: 'Facebook',
  },
  {
    value: 'GOOGLE_SEARCH',
    label: 'Google search',
  },
  {
    value: 'MEDIA',
    label: 'Media',
  },
  {
    value: 'BLOG',
    label: 'Blogs',
  },
  {
    value: 'VENTURE_CAPITAL',
    label: 'VCs',
  },
  {
    value: 'GOVERNMENT',
    label: 'Governments',
  },
  {
    value: 'TECH_SOLUTION_PROVIDER',
    label: 'Tech solution providers',
  },
  {
    value: 'AGENCY',
    label: 'Agencies',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
];

export const businessStructureOptions = [
  { value: 'CORPORATION', label: 'Corporation' },
  { value: 'LLC', label: 'Limited Liability Company' },
  { value: 'PARTNERSHIP', label: 'Partnership' },
  { value: 'SOLE_PROPRIETORSHIP', label: 'Sole Proprietorship' },
  { value: 'NON_PROFIT', label: 'Nonprofit' },
  { value: 'OTHERS', label: 'Others' },
];

export const documentTypeOptions = [
  { value: 'BANK', label: 'Bank Statement' },
  { value: 'P&L', label: 'P&L' },
  { value: 'COMPANY', label: 'Company Doc' },
  { value: 'Identity', label: 'Identity Doc' },
  { value: 'OTHERS', label: 'Others' },
];

export const ownerIdentityOptions = [
  { value: 'ID_CARD', label: 'Identity Card' },
  { value: 'PASSPORTS', label: 'Passports' },
];

export const remittancePeriodOptions = [
  { value: PERIOD.DAILY, label: 'daily' },
  { value: PERIOD.WEEKLY, label: 'weekly' },
  { value: PERIOD.MONTHLY, label: 'monthly' },
];

export const offerReviewOptions = [
  {
    value: 'APPROVE',
    label: 'Approve',
  },
  {
    value: 'RETURN',
    label: 'Return',
  },
  {
    value: 'REJECT',
    label: 'Reject',
  },
];

export const offerReturnOptions = [
  {
    value: 'RETURN',
    label: 'Retract from client to sales',
  },
];

export const pageSizeOptions = [
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
  {
    value: 250,
    label: 250,
  },
];

export const LAST_WEEK_OPTION = {
  value: 'LAST_WEEK',
  label: 'Last 7 days',
};

export const LAST_30_DAYS_OPTION = {
  value: 'LAST_30_DAYS',
  label: 'Last 30 days',
};

export const LAST_90_DAYS_OPTION = {
  value: 'LAST_90_DAYS',
  label: 'Last 90 days',
};

export const LAST_12_MONTHS_OPTION = {
  value: 'LAST_12_MONTHS',
  label: 'Last 12 months',
};

export const THIS_MONTH_OPTION = {
  value: 'THIS_MONTH',
  label: 'This month',
};

export const LAST_MONTH_OPTION = {
  value: 'LAST_MONTH',
  label: 'Last month',
};

export const THIS_YEAR_OPTION = {
  value: 'THIS_YEAR',
  label: 'This year',
};

export const LAST_YEAR_OPTION = {
  value: 'LAST_YEAR',
  label: 'Last year',
};

export const calendarShortcutOptions = [
  LAST_WEEK_OPTION,
  LAST_30_DAYS_OPTION,
  LAST_90_DAYS_OPTION,
  LAST_12_MONTHS_OPTION,
  THIS_MONTH_OPTION,
  LAST_MONTH_OPTION,
  THIS_YEAR_OPTION,
  LAST_YEAR_OPTION,
];

export const FB_THIS_MONTH_OPTION = {
  value: 'this_month',
  label: 'This month',
};

export const FB_LAST_MONTH_OPTION = {
  value: 'last_month',
  label: 'Last month',
};

export const FB_LAST_WEEK_OPTION = {
  value: 'last_7d',
  label: 'Last 7 days',
};

export const FB_LAST_30_DAYS_OPTION = {
  value: 'last_30d',
  label: 'Last 30 days',
};

export const FB_LAST_90_DAYS_OPTION = {
  value: 'last_90d',
  label: 'Last 90 days',
};

export const FB_LAST_QUARTER = {
  value: 'last_quarter',
  label: 'Last Quarter',
};

export const FB_LAST_YEAR_OPTION = {
  value: 'last_year',
  label: 'Last year',
};

export const FB_THIS_YEAR_OPTION = {
  value: 'this_year',
  label: 'This year',
};

// available date presets from Facebook
// reference: https://developers.facebook.com/docs/marketing-api/insights/parameters/v12.0
export const FBDatePresetOptions = [
  FB_LAST_WEEK_OPTION,
  FB_LAST_30_DAYS_OPTION,
  FB_LAST_90_DAYS_OPTION,
  FB_THIS_MONTH_OPTION,
  FB_LAST_MONTH_OPTION,
  FB_THIS_YEAR_OPTION,
  FB_LAST_YEAR_OPTION,
];

export const remittanceStatusOptions = [
  {
    value: 'DRAFT',
    label: 'Draft',
  },
  {
    value: 'OPEN',
    label: 'Open',
  },
  {
    value: 'PAID',
    label: 'Paid',
  },
  {
    value: 'VOID',
    label: 'Void',
  },
  {
    value: 'UNCOLLECTIBLE',
    label: 'Uncollectible',
  },
];

export const balanceStatusOptions = [
  {
    value: OPTION_VALUE_ALL,
    label: 'All',
  },
  {
    value: 'PENDING',
    label: 'Pending',
  },
  {
    value: 'IN_TRANSIT',
    label: 'In Transit',
  },
  {
    value: 'SUCCESS',
    label: 'Success',
  },
  {
    value: 'FAILED',
    label: 'Failed',
  },
  {
    value: 'OVERDUE',
    label: 'Overdue',
  },
];

export const balanceSourceTypeOptions = [
  {
    value: OPTION_VALUE_ALL,
    label: 'All',
  },
  {
    value: 'INVOICE',
    label: 'Invoice',
  },
  {
    value: 'CHARGE_REFUND',
    label: 'Charge Refund',
  },
  {
    value: 'CREDIT_NOTE',
    label: 'Credit note',
  },
  {
    value: 'BALANCE_ADJUSTMENT',
    label: 'Balance Adjustment',
  },
  {
    value: 'FUNDING_FEE',
    label: 'Funding Fee',
  },
  {
    value: 'FUND_DISBURSEMENT',
    label: 'Fund Disbursement',
  },
];
