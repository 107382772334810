import React, { useEffect, useRef } from 'react';

import deepEqual from 'deep-equal';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Arrow, { ARROW_DIRECTION } from '../../../../assets/icons/Arrow';
import BreadCrumb from '../../../../components/BreadCrumb';
import LoadingSkeleton from '../../../../components/LoadingSkeleton';
import ToolTip from '../../../../components/ToolTip';
import featureToggle from '../../../../featureToggle';
import Logger from '../../../../utils/logger';
import { INVOICE_STATUS_MODAL } from '../../../Modal/constants';
import { Wrapper } from '../../components';
import PATH from '../../path';
import BreakdownTable from './BreakdownTable';
import { fetchInvoiceDetailById } from '@redux/modules/fund/actions';
import { convertToFullDate } from '@utils/dateHelpers';
import { formatPercent, formatPrice } from '@utils/priceHelpers';
import { showModal } from '@redux/modules/UI/actions';
import { Menu, DropdownOption } from '@components/Dropdown';
import { useModal, useOnClickOutside } from '@hooks/UI';
import { getColorByTransactionStatus, getColorByPaymentStatus } from '@utils/remittanceHelpers';
import { capitalize } from '@utils/stringHelpers';
import { getOptionLabelByValue } from '@utils/optionHelpers';
import { INVOICE_STATUS } from '@/constants';
import {
  formOptions as invoiceFormOptions,
  paymentMethodOptions,
  REVENUE_FORM_NAME,
  ONE_TIME_FORM_NAME,
} from '../CreateManualInvoice/constants';
import { INVOICE_PAYMENT_METHOD } from '@constants/remittance';
import { useSelectedCompany } from '@redux/selectors';

const Header = styled.div`
  ${(props) => props.theme.text.four};
`;

const FlatFieldWrapper = styled.div`
  padding-right: 8px;
  div {
    display: inline;
  }
`;

const FieldTitle = styled.div`
  ${(props) => props.theme.text.five};
  margin-right: 16px;
`;

const FieldData = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  font-weight: 600;
`;

const KeyDataWrapper = styled.table`
  width: 100%;
  padding: 10px 15px;
  background-color: ${(props) => props.theme.colors.white};
  margin: 16px 0;

  td {
    padding: 12px;
  }
`;

const InvoiceAmountTd = styled.td`
  position: relative;
  background-color: ${(props) => props.theme.colors.grayOne};
`;

const InvoiceAmountWrapper = styled.div`
  cursor: pointer;
`;

const InvoiceDetailDropdown = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px 24px;
  position: absolute;
  right: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  color: ${(props) => props.theme.colors.grayThree};
  min-width: 400px;

  > div:first-child {
    font-weight: bold;
    margin-bottom: 15px;
  }

  > div:last-child {
    > div:first-child {
      font-weight: bold;
      margin-bottom: 15px;
    }

    span:last-child {
      font-weight: bold;
    }

    > div {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  -ms-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
`;

const StatusActionDropdown = styled.span`
  float: right;
  position: relative;
  ${(props) => props.theme.text.four};
  color: ${(props) => props.theme.colors.orange};

  svg {
    margin-left: 4px;

    fill: ${(props) => props.theme.colors.orange};
  }
`;

const StatusMenu = styled(Menu)`
  min-width: 300px !important;
`;

const StatusActionToggle = styled.div`
  cursor: pointer;
`;

const StatusTag = styled.span`
  color: ${(props) => props.color};
  font-weight: bold;
`;

const TextWithToolTip = styled.div`
  > span,
  > .tooltip {
    vertical-align: middle;
  }

  .tooltip {
    margin-left: 4px;
  }
`;

const InfoTable = styled.table`
  td {
    padding-right: 16px;
    font-weight: 700;
    &.title {
      width: 150px;
      font-weight: normal;
    }
  }
`;

const SubHeader = styled.div`
  font-weight: 700;
`;

const DueLabel = styled(SubHeader)`
  margin: 30px 0;
`;

const SummaryTable = styled.table`
  border-collapse: collapse;
  margin: 10px 0;
  background-color: ${(props) => props.theme.colors.white};
  width: 100%;

  thead {
    color: ${(props) => props.theme.colors.grayThree};
    font-weight: 600;
  }

  tbody {
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
  }

  tr {
    border-bottom: 1px solid ${(props) => props.theme.colors.grayOne};
  }

  tr:last-child {
    font-weight: 700;
  }

  td {
    padding: 9px 16px;
    ${(props) => props.theme.text.five};
    border-top: 1px solid ${(props) => props.theme.colors.grayOne};
  }

  td:nth-child(2) {
    width: 150px;
  }

  td:last-child {
    text-align: right;
    min-width: 100px;
    width: 20%;
    max-width: 200px;
  }
`;

const InvoiceDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const invoiceDetailDropdownRef = useRef();
  const statusActionDropdownRef = useRef();
  const { invoiceDetailMap, isLoading } = useSelector((state) => state.fund);
  const selectedCompany = useSelectedCompany();
  const invoiceDetail = invoiceDetailMap[id] || {};
  const [isInvoiceDropdownShown, toggleIsInvoiceDropdownShown] = useModal(false);
  const [isActionDropdownShown, toggleIsActionDropdownShown] = useModal(false);

  const { fundingNumber, applicationId } = useSelector((state) => state.application.data);
  const {
    invoice_id,
    date_start,
    date_finished,
    date_debit,
    date_paid,
    funding_id,
    currency,
    breakdowns,
    status,
    payment_status,
    payment_method,
    invoice_number,
    invoiced_amount,
    service_charge_amount,
    return_amount,
    funding_number,
    type,
    description,
  } = invoiceDetail ?? {};

  useOnClickOutside(invoiceDetailDropdownRef, () => toggleIsInvoiceDropdownShown(false));
  useOnClickOutside(statusActionDropdownRef, () => toggleIsActionDropdownShown(false));

  const handleInvoiceDetailClick = () => {
    toggleIsInvoiceDropdownShown(!isInvoiceDropdownShown);
  };

  const handleStatusActionClick = () => {
    toggleIsActionDropdownShown(!isActionDropdownShown);
  };

  const showInvoiceStatusModal = () => {
    dispatch(showModal(INVOICE_STATUS_MODAL, { invoiceId: id }));
    toggleIsActionDropdownShown(false);
  };

  useEffect(() => {
    try {
      fetchInvoiceDetailById(id);
    } catch (err) {
      Logger.error(err);
    }
  }, [applicationId]);

  return (
    <>
      <BreadCrumb
        items={[
          {
            value: PATH.CAPITAL,
            label: 'Capital',
          },
          {
            value: PATH.CAPITAL_BALANCE_TRANSACTIONS,
            label: 'Transactions',
          },
          {
            label: 'Transaction details',
          },
        ]}
      />
      {isLoading && deepEqual(invoiceDetail, {}) ? (
        <Wrapper>
          <LoadingSkeleton color="grayTwo" width="50%" />
          <LoadingSkeleton color="grayTwo" width="33%" />
          <LoadingSkeleton color="grayTwo" width="33%" />
          <LoadingSkeleton color="grayTwo" width="33%" />
          <KeyDataWrapper>
            <LoadingSkeleton color="grayOne" height="48px" />
          </KeyDataWrapper>

          <LoadingSkeleton color="grayTwo" />
          <LoadingSkeleton color="grayTwo" />
          <LoadingSkeleton color="grayTwo" />
        </Wrapper>
      ) : (
        <Wrapper>
          {featureToggle.INVOICE_404_UI && deepEqual(invoiceDetail, {}) ? (
            <>
              <Header>404 Invoice Not Found</Header>
              <div>The invoice you are checking is not found.</div>
            </>
          ) : (
            <>
              <Header>
                <span>{`Invoice #${invoice_number} (${convertToFullDate(date_start)} - ${convertToFullDate(date_finished)})`}</span>
                <StatusActionDropdown ref={statusActionDropdownRef}>
                  <StatusActionToggle onClick={handleStatusActionClick}>
                    <span>Action</span>
                    <Arrow direction={isActionDropdownShown ? ARROW_DIRECTION.UP : ARROW_DIRECTION.DOWN} />
                  </StatusActionToggle>
                  {isActionDropdownShown && (
                    <StatusMenu>
                      <DropdownOption onClick={showInvoiceStatusModal}>Change Invoice payment status</DropdownOption>
                      <DropdownOption disabled>Send Invoice</DropdownOption>
                    </StatusMenu>
                  )}
                </StatusActionDropdown>
              </Header>
              <InfoTable>
                <tr>
                  <td className="title">From:</td>
                  <td>Choco Up</td>
                </tr>
                <tr>
                  <td className="title">To:</td>
                  <td>{selectedCompany.companyName}</td>
                </tr>
                <tr>
                  <td className="title">Status:</td>
                  <td>
                    <StatusTag color={getColorByTransactionStatus(status)}>{capitalize(status)}</StatusTag>
                  </td>
                  <td className="title">Payment Status:</td>
                  <td>
                    <StatusTag color={getColorByPaymentStatus(payment_status)}>{capitalize(payment_status)}</StatusTag>
                  </td>
                </tr>
                {date_paid && payment_status === INVOICE_STATUS.PAID ? (
                  <tr>
                    <td className="title">Paid Date:</td>
                    <td>{convertToFullDate(date_paid)}</td>
                  </tr>
                ) : (
                  <tr>
                    <td className="title">Due Date:</td>
                    <td>{convertToFullDate(date_debit)}</td>
                  </tr>
                )}
                <tr>
                  <td className="title">Funding ID:</td>
                  <td>{fundingNumber}</td>
                </tr>
                {type && (
                  <tr>
                    <td className="title">Invoice Type:</td>
                    <td>
                      {getOptionLabelByValue(type, invoiceFormOptions, {
                        defaultLabel: 'General',
                      })}
                    </td>
                  </tr>
                )}
                {payment_method !== INVOICE_PAYMENT_METHOD.WHATEVER && (
                  <tr>
                    <td className="title">Payment method:</td>
                    <td>{getOptionLabelByValue(payment_method, paymentMethodOptions)}</td>
                  </tr>
                )}
              </InfoTable>
              {type === ONE_TIME_FORM_NAME && (
                <>
                  <DueLabel>{`${formatPrice(currency, invoiced_amount)} due on ${convertToFullDate(date_debit)}`}</DueLabel>
                  <SubHeader>Summary</SubHeader>
                  <SummaryTable>
                    <thead>
                      <tr>
                        <td>Invoice Description</td>
                        <td />
                        <td>Amount</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{description || 'One time invoice'}</td>
                        <td />
                        <td>{formatPrice(currency, invoiced_amount)}</td>
                      </tr>
                      <tr>
                        <td />
                        <td className="textRight">Total</td>
                        <td>{formatPrice(currency, invoiced_amount)}</td>
                      </tr>
                    </tbody>
                  </SummaryTable>
                </>
              )}
              {type !== ONE_TIME_FORM_NAME && (
                <>
                  <KeyDataWrapper>
                    <td>
                      <FieldTitle>Invoice Currency</FieldTitle>
                      <FieldData>{currency}</FieldData>
                    </td>
                    <td>
                      <FieldTitle>Total Revenue</FieldTitle>
                      <FieldData>
                        {formatPrice(
                          currency,
                          invoiceDetail.type === REVENUE_FORM_NAME
                            ? (invoiceDetail.settlement_amount / invoiceDetail.percentage) * 100
                            : invoiceDetail[`revenue_amount_in_${currency?.toLowerCase()}`],
                          true
                        )}
                      </FieldData>
                    </td>
                    <td>
                      <FieldTitle>Remittance rate</FieldTitle>
                      <FieldData>{formatPercent(invoiceDetail.percentage)}</FieldData>
                    </td>
                    <td>
                      <FieldTitle>
                        <TextWithToolTip>
                          <span>Adjustment Amount</span>
                          <ToolTip
                            width={250}
                            className="tooltip"
                            tip="Adjustment made on the final invoice when expected remittance amount for the given invoice period (revenue* remittance rate) exceeds the remaining funding balance."
                          />
                        </TextWithToolTip>
                      </FieldTitle>
                      <FieldData>{formatPrice(currency, invoiceDetail[`adjustment_amount_${currency?.toLowerCase()}`], true)}</FieldData>
                    </td>
                    <InvoiceAmountTd ref={invoiceDetailDropdownRef}>
                      <InvoiceAmountWrapper onClick={handleInvoiceDetailClick}>
                        <span>
                          <FieldTitle>
                            <TextWithToolTip>
                              <span>Invoice Amount</span>
                              <ToolTip
                                width={250}
                                className="tooltip"
                                tip="Actual amount (after adjustment if applicable) to be remitted as repayment for the given invoice period."
                              />
                            </TextWithToolTip>
                          </FieldTitle>
                          <FieldData>{formatPrice(currency, invoiced_amount, true)}</FieldData>
                        </span>
                        <ArrowWrapper>
                          <Arrow direction={isInvoiceDropdownShown ? ARROW_DIRECTION.UP : ARROW_DIRECTION.DOWN} />
                        </ArrowWrapper>
                      </InvoiceAmountWrapper>
                      {isInvoiceDropdownShown && (
                        <InvoiceDetailDropdown>
                          <div>Amount Breakdown</div>
                          <div>
                            <div>
                              <span>Invoice Amount</span>
                              <span>{formatPrice(currency, invoiced_amount)}</span>
                            </div>
                            <div>
                              <span>Investment service charges</span>
                              <span>{formatPrice(currency, service_charge_amount)}</span>
                            </div>
                            <div>
                              <span>Investment return</span>
                              <span>{formatPrice(currency, return_amount)}</span>
                            </div>
                          </div>
                        </InvoiceDetailDropdown>
                      )}
                    </InvoiceAmountTd>
                  </KeyDataWrapper>
                  <BreakdownTable invoiceDetail={invoiceDetail} />
                </>
              )}
            </>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default InvoiceDetail;
