import React, { useState } from 'react';

import parsePhoneNumber from 'libphonenumber-js';
import { Controller } from 'react-hook-form';
import styled, { css } from 'styled-components';

import Button from '../../../../../components/Button';
import Checkbox, { CheckboxText } from '../../../../../components/Checkbox';
import FileDropzone from '../../../../../components/FileDropzone';
import FormTitle from '../../../../../components/FormTitle';
import HR from '../../../../../components/HR';
import Input from '../../../../../components/Input';
import PhoneInput from '../../../../../components/PhoneInput';
import Select from '../../../../../components/Select';
import SingleDatePicker from '../../../../../components/SingleDatePicker';
import { Description } from "../../../components";
import AddressForm from '../../../components/AddressForm';
import { FormRow, GridColumn } from '@components/Grid';
import { businessStructureOptions, businessTypeOptions } from '@constants/options';
import { datePickerAllowDaysBeforeToday } from '@utils/dateHelpers';

const ShippingAddressWrapper = styled.div`
  ${(props) =>
    props.hide &&
    css`
      display: none;
    `}
`;

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.error};
  ${(props) => props.theme.text.micro};
`;

const CompanyInfoForm = ({
  form: {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
    watch,
  },
  onSubmit,
  disabled,
  onDropFile,
  apiError,
  uploadedFiles,
  isLoading,
  noFileAttached,
  onCancel,
  inactiveFields = [],
}) => {
  const [country, setCountry] = useState();
  const watchIsShippingSameAddress = watch('isShippingSameAddress');
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormRow>
        <FormTitle scale={5} required>
          Company Name
        </FormTitle>
        <GridColumn lg={8} disableGutter>
          <Controller
            name="legalCompanyName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input {...field} hasError={errors.legalCompanyName} placeholder="Registered Company Name" disabled={disabled} full />
            )}
          />
        </GridColumn>
      </FormRow>
      <FormRow>
        <FormTitle scale={5}>Business Name</FormTitle>
        <GridColumn lg={8} disableGutter>
          <Controller
            name="businessName"
            control={control}
            render={({ field }) => (
              <Input {...field} hasError={errors.businessName} placeholder="Business / Brand Name" disabled={disabled} full />
            )}
          />
        </GridColumn>
      </FormRow>
      <FormRow>
        <GridColumn lg={8} disableGutter>
          <FormTitle scale={5} required>
            Legal Business Structure
          </FormTitle>
          <Controller
            name="legalBusinessStructure"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select {...field} options={businessStructureOptions} hasError={errors.legalBusinessStructure} disabled={disabled} full />
            )}
          />
        </GridColumn>
      </FormRow>
      <FormRow>
        <GridColumn lg={4} md={6}>
          <FormTitle scale={5} required>
            Company Register No.
          </FormTitle>
          <Controller
            name="registerNumber"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input {...field} hasError={errors.registerNumber} placeholder="E.g. 12421512" disabled={disabled} full />
            )}
          />
        </GridColumn>
        <GridColumn lg={4} md={6}>
          <FormTitle scale={5} required>
            Incorporation Date
          </FormTitle>
          <Controller
            name="incorporationDate"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <SingleDatePicker
                {...field}
                id="settings_business_details"
                hasError={errors.incorporationDate}
                isOutsideRange={datePickerAllowDaysBeforeToday}
                disabled={disabled}
                full
              />
            )}
          />
        </GridColumn>
      </FormRow>
      <HR />
      <FormRow>
        <FormTitle scale={3} required>
          Registered Address
        </FormTitle>
      </FormRow>
      <AddressForm
        control={control}
        errors={errors}
        disabled={disabled}
        fieldNames={{
          country: 'registerAddress.country',
          zipCode: 'registerAddress.zipCode',
          addressLine1: 'registerAddress.addressLine1',
          addressLine2: 'registerAddress.addressLine2',
          state: 'registerAddress.state',
          city: 'registerAddress.city',
        }}
      />
      <HR />
      <FormTitle scale={3} required>
        Shipping Address
      </FormTitle>
      <Controller name="isShippingSameAddress" control={control} render={({ field }) => <Checkbox {...field} disabled={disabled} />} />
      <CheckboxText>Same as registered address</CheckboxText>
      <ShippingAddressWrapper hide={watchIsShippingSameAddress}>
        <AddressForm
          control={control}
          errors={errors}
          required={!watchIsShippingSameAddress}
          disabled={disabled}
          fieldNames={{
            country: 'shippingAddress.country',
            zipCode: 'shippingAddress.zipCode',
            addressLine1: 'shippingAddress.addressLine1',
            addressLine2: 'shippingAddress.addressLine2',
            state: 'shippingAddress.state',
            city: 'shippingAddress.city',
          }}
        />
      </ShippingAddressWrapper>
      <HR />
      {Array.isArray(inactiveFields) && inactiveFields.indexOf('businessType') === -1 && (
        <>
          <FormTitle scale={3} required>
            Other Business Information
          </FormTitle>
          <FormRow>
            <GridColumn lg={8}>
              <Controller
                name="businessType"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select {...field} hasError={errors.businessType} options={businessTypeOptions} disabled={disabled} full />
                )}
              />
            </GridColumn>
          </FormRow>
        </>
      )}
      <FormTitle scale={3} required>
        Business Contact Number
      </FormTitle>
      <FormRow>
        <GridColumn lg={8}>
          <Controller
            name="businessContactPhone"
            control={control}
            rules={{
              required: true,
              validate: (value) => {
                const phoneNumber = parsePhoneNumber(value, country);
                return phoneNumber?.isValid();
              },
            }}
            render={({ field }) => (
              <PhoneInput
                {...field}
                hasError={errors.businessContactPhone}
                country={country}
                onCountryChange={setCountry}
                disabled={disabled}
                full
              />
            )}
          />
        </GridColumn>
      </FormRow>
      <FormRow>
        <GridColumn lg={8}>
          <FormTitle scale={3} required>
            Upload Business Document
          </FormTitle>
          <Description>
            For verification purposes, we require the incorporation document of your business. (You can upload multiple documents)
          </Description>
          <FileDropzone uploadedFiles={uploadedFiles} onDrop={onDropFile} disabled={disabled} hasError={isSubmitted && noFileAttached} />
        </GridColumn>
      </FormRow>
      {apiError && <ErrorMessage>{apiError}</ErrorMessage>}
      <FormRow>
        <GridColumn lg={4} md={4}>
          <Button type="submit" disabled={isLoading || disabled} full>
            Save
          </Button>
        </GridColumn>
        <GridColumn lg={4} md={4}>
          <Button onClick={onCancel} disabled={isLoading} tertiary full>
            Cancel
          </Button>
        </GridColumn>
      </FormRow>
    </form>
  );
};

export default CompanyInfoForm;
