import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../../../components/Button';
import { XERO_SETTLEMENT_MODAL, MESSAGE_MODAL } from '@modules/Modal/constants';
import { showModal } from '@redux/modules/UI/actions';

const Wrapper = styled.div`
  ${(props) => props.theme.text.micro};
  margin-bottom: 24px;
  > * {
    margin-right: 16px;
    vertical-align: middle;
  }
`;

const TextButton = styled.span`
  ${(props) => props.theme.text.link};
`;

const SelectionControl = ({ selectedIds, toggleIsSelectingTrx, clearAll, selectedAccountId }) => {
  const dispatch = useDispatch();
  const showSettlementModal = () => {
    dispatch(
      showModal(XERO_SETTLEMENT_MODAL, {
        selectedIds,
        selectedAccountId,
        afterSubmit: () => {
          toggleIsSelectingTrx(false);
          clearAll();
        },
      })
    );
  };

  const showConfirmModal = () => {
    dispatch(
      showModal(MESSAGE_MODAL, {
        title: 'Exit create this revenue?',
        description: 'Your Active revenue selection will be deleted.',
        showCloseButton: true,
        showLeftButton: false,
        onConfirm: () => toggleIsSelectingTrx(false),
        leftButtonProps: {
          secondary: true,
          tertiary: false,
          warning: true,
        },
        rightButtonProps: {
          warning: true,
          label: 'Exit Create Revenue',
        },
      })
    );
  };

  return (
    <Wrapper>
      <span>{selectedIds.length === 0 ? 'Select item to continue' : `${selectedIds.length} selected`}</span>
      {selectedIds.length > 0 ? (
        <>
          <Button onClick={showSettlementModal} disabled={selectedIds.length === 0} small>
            Add Settlement
          </Button>
          <TextButton onClick={clearAll}>Clear all</TextButton>
        </>
      ) : (
        <TextButton onClick={showConfirmModal}>Exit Create Revenue</TextButton>
      )}
    </Wrapper>
  );
};

export default SelectionControl;
