import moment from 'moment';

import { DB_STATUS } from '@/constants';
import { convertToApiDate } from '@utils/dateHelpers';

export const generateCreateBusinessOwnerPayload = (data) => {
  return {
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    job_title: data.jobTitle,
    identity_document_type: data.idDocType,
    identity_number: data.idDocNumber,
    date_of_birth: data.dateOfBirth,
    company_id: data.companyId,
    address_line_1: data.addressLine1,
    address_line_2: data.addressLine2,
    city: data.city ?? undefined,
    zip_code: data.zipCode ?? undefined,
    country: data.country,
    state: data.state,
  };
};

export const convertBusinessOwnerResponse = (data) => {
  return {
    firstName: data.first_name,
    lastName: data.last_name,
    id: data.business_owner_id,
    jobTitle: data.job_title,
    companyId: data.company_id,
    dateOfBirth: data.date_of_birth ? moment(data.date_of_birth) : null,
    email: data.email,
    idDocType: data.identity_document_type,
    idDocNumber: data.identity_number,
    addressLine1: data.address_line_1,
    addressLine2: data.address_line_2,
    city: data.city,
    zipCode: data.zip_code,
    country: data.country,
    state: data.state,
  };
};

export const convertBusinessOwnerEntityResponse = (data) => {
  return {
    legalCompanyName: data.legal_company_name,
    businessName: data.business_name,
    legalBusinessStructure: data.legal_business_structure,
    registerNumber: data.company_reg_no,
    companyId: data.company_id,
    incorporationDate: data.incorporation_date ? moment(data.incorporation_date) : null,
    businessContactPhone: data.business_contact_phone,
    registerAddress: {
      country: data.registered_address?.country,
      zipCode: data.registered_address?.zip_code,
      addressLine1: data.registered_address?.address_line_1,
      addressLine2: data.registered_address?.address_line_2,
      state: data.registered_address?.state,
      city: data.registered_address?.city,
    },
    isShippingSameAddress: !data.shipping_address,
    shippingAddress: {
      country: data.shipping_address.country,
      zipCode: data.shipping_address.zip_code || undefined,
      addressLine1: data.shipping_address.address_line_1,
      addressLine2: data.shipping_address.address_line_2,
      state: data.shipping_address.state,
      city: data.shipping_address.city || undefined,
      status: data.shipping_address.status,
    },
  };
};

export const generateUpdateCompanyPayload = (formData) => {
  return {
    legal_company_name: formData.legalCompanyName,
    business_name: formData.businessName,
    company_reg_no: formData.registerNumber,
    incorporation_date: convertToApiDate(formData.incorporationDate),
    business_contact_phone: formData.businessContactPhone,
    business_type: formData.businessType,
    legal_business_structure: formData.legalBusinessStructure,
    registeredAddress: {
      country: formData.registerAddress.country,
      zip_code: formData.registerAddress.zipCode,
      address_line_1: formData.registerAddress.addressLine1,
      address_line_2: formData.registerAddress.addressLine2,
      state: formData.registerAddress.state,
      city: formData.registerAddress.city,
    },
    shippingAddress: formData.isShippingSameAddress
      ? null
      : {
          country: formData.shippingAddress.country,
          zip_code: formData.shippingAddress.zipCode,
          address_line_1: formData.shippingAddress.addressLine1,
          address_line_2: formData.shippingAddress.addressLine2,
          state: formData.shippingAddress.state,
          city: formData.shippingAddress.city,
        },
    isShippingSameAddress: formData.isShippingSameAddress,
  };
};

export const convertCompanyResponse = (data) => ({
  id: data.company_id,
  admins: data.admins,
  companyName: data.company_name,
  businessName: data.business_name,
  legalCompanyName: data.legal_company_name,
  website: data.business_website,
  businessType: data.business_type,
  registerNumber: data.company_reg_no,
  businessContactPhone: data.business_contact_phone,
  legalBusinessStructure: data.legal_business_structure,
  monthlyRevenueRange: data.monthly_revenue_range,
  incorporationDate: data.incorporation_date ? moment(data.incorporation_date) : null,
  createdDate: moment(data.created_date),
  countryOrigin: data.country_origin,
  leadSourceType: data.lead_source_type,
  leadSourceDetail: data.lead_source_detail,
  referrer: data.referrer,
  registerAddress: {
    country: data.registered_address?.country,
    zipCode: data.registered_address?.zip_code,
    addressLine1: data.registered_address?.address_line_1,
    addressLine2: data.registered_address?.address_line_2,
    state: data.registered_address?.state,
    city: data.registered_address?.city,
  },
  shippingAddress: data.shipping_address
    ? {
        country: data.shipping_address?.country,
        zipCode: data.shipping_address?.zip_code,
        addressLine1: data.shipping_address?.address_line_1,
        addressLine2: data.shipping_address?.address_line_2,
        state: data.shipping_address?.state,
        city: data.shipping_address?.city,
        status: data.shipping_address?.status,
      }
    : null,
  // contact info
  contactFirstName: data.contact_first_name,
  contactLastName: data.contact_last_name,
  contactEmail: data.contact_email,
  contactMobile: data.contact_mobile,
});

export const shouldSameShippingAddressBeChecked = (shippingAddress = {}) => {
  // shippingAddress is null when a new user has never submitted shipping address
  // shippingAddress.status is inactive when an user checked isShippingSameAddress after he has submitted once
  return shippingAddress === null || shippingAddress.status === 'INACTIVE' || shippingAddress.status === 'I';
};

export const hasFileAttached = (pendingFiles, uploadedFiles) => {
  return (Array.isArray(pendingFiles) && pendingFiles.length > 0) || (Array.isArray(uploadedFiles) && uploadedFiles.length > 0);
};
