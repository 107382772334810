import React from 'react';

import styled from 'styled-components';

import featureToggle from '../../../featureToggle';
import landingBanner from '../../../landingBanner.png';
import { media } from '@styles/breakpoints';

const BannerWrapper = styled.span`
  float: left;
  position: relative;
  max-width: 475px;
  height: 100vh;
  margin: 0 auto;
  background-color: black;
  ${media.dablet`
    display: none;
  `};
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 475px;
  height: 100vh;
  ${media.dablet`
    display: none;
  `};
`;

const ChocoUpLogo = styled.img`
  position: absolute;
  top: 40px;
  left: 40px;
  width: 40px;
`;

const BannerContent = styled.div`
  position: absolute;
  bottom: 0;
  color: #f1f1f1;
  padding: 40px 25px 50px 40px;
`;

const ReviewQuote = styled.div`
  margin-bottom: 16px;
  font-size: 15px;
  line-height: 25px;
  margin: 25px 0;
`;

const BannerImage = styled.div`
  width: 475px;
  height: 100vh;
  background-image: url(${landingBanner});
  background-size: cover;
  background-position: center center;
  ${media.dablet`
    display: none;
  `};
`;

const BuzzTextLogo = styled.div`
  margin-top: 20px;
  font-family: Futura;
  font-weight: bold;
  font-size: 25px;
  line-height: 25px;
  letter-spacing: 0.2em;
`;

const Banner = () => {
  return (
    <BannerWrapper>
      <BannerImage />
      <Overlay />
      <ChocoUpLogo src="/images/choco-up-white-no-title.png" alt="Choco Up" />
      <BannerContent>
        <ReviewQuote>
          {featureToggle.HIDE_FOR_SHOPIFY
            ? '“Choco Up’s growth platform and partnership network have helped to jumpstart my growth massively.”'
            : '“Choco Up’s financing platform and partnership network have helped to jumpstart my growth massively.”'}
        </ReviewQuote>
        <b>Bell Beh,</b>
        {' '}
        Co-Founder & CEO
        <BuzzTextLogo>Buzz AR</BuzzTextLogo>
      </BannerContent>
    </BannerWrapper>
  );
};

export default Banner;
