import React, { useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '../../components/Button';
import CountrySelect from '../../components/CountrySelect';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import Logger from '../../utils/logger';
import ControlledInput from '../ReactHookForm/ControlledInput';
import ControlledSelect from '../ReactHookForm/ControlledSelect';
import { createBankAccount, updateBankAccountById } from '@api/modules/application';
import { GridRow, GridColumn } from '@components/Grid';
import { BANK_SOURCE } from '@constants/bank';
import { AllCurrencyOptions } from '@constants/options';
import { PLATFORM } from '@constants/platform';
import { hideModal } from '@redux/modules/UI/actions';

const ButtonWrapper = styled(GridColumn)`
  justify-content: space-between;
`;

const BankAccountFormModal = ({ isEditingBank = false, editingBank, onAfterSubmit = () => {}, disabled }) => {
  const isXeroAccount = editingBank?.add_method === PLATFORM.XERO;
  const isFinverseAccount = editingBank?.add_method === BANK_SOURCE.FINVERSE;

  const dispatch = useDispatch();
  const [isLoading, toggleIsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    ...(editingBank && { defaultValues: editingBank }),
  });

  // const isFinverseAccount = editingBank?.add_method === 'finverse_api';

  const editBank = async (formData) => {
    await updateBankAccountById({
      bank_id: formData.id,
      country: formData.country,
      bank_name: formData.bankName,
      account_holder_name: formData.accountHolderName,
      swift_bic: formData.swiftBic,
      currency: formData.currency,
      account_name: formData.accountName,
      ...(!isXeroAccount && !isFinverseAccount && { account_no: formData.accountNumber }),
    });
    await onAfterSubmit();
    dispatch(hideModal());
  };

  const addBank = async (formData) => {
    await createBankAccount({
      country: formData.country,
      bank_name: formData.bankName,
      account_holder_name: formData.accountHolderName,
      account_no: formData.accountNumber,
      swift_bic: formData.swiftBic,
      currency: formData.currency,
      account_name: formData.accountName,
    });
    await onAfterSubmit();
    dispatch(hideModal());
  };

  const onSubmit = (data) => {
    try {
      if (isEditingBank) {
        editBank(data);
      } else {
        addBank(data);
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  return (
    <Modal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridRow>
          <GridColumn>
            <Header scale={2}>{isEditingBank ? 'Edit Bank Account' : 'Add a Bank Account'}</Header>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn lg={6} md={6}>
            {isFinverseAccount ? (
              <ControlledInput name="country" control={control} disabled full />
            ) : (
              <Controller
                name="country"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CountrySelect {...field} hasError={errors.country} placeholder="Select a Country" disabled={isLoading || disabled} />
                )}
              />
            )}
          </GridColumn>
          <GridColumn lg={6} md={6}>
            <ControlledInput name="swiftBic" control={control} placeholder="SWIFT / BIC" disabled={isLoading || disabled} full />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn lg={6} md={6}>
            <ControlledSelect
              name="currency"
              control={control}
              options={AllCurrencyOptions}
              placeholder="Currency"
              disabled={isLoading || disabled || isFinverseAccount}
            />
          </GridColumn>
          <GridColumn lg={6} md={6}>
            <ControlledInput
              name="accountName"
              control={control}
              placeholder="e.g USD Saving Account"
              disabled={isLoading || disabled || isXeroAccount || isFinverseAccount}
              full
            />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn lg={12} md={12}>
            <ControlledInput
              placeholder="Name of Bank"
              name="bankName"
              control={control}
              rules={{
                required: true,
              }}
              disabled={isLoading || disabled || isFinverseAccount}
              full
            />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn lg={12} md={12}>
            <ControlledInput
              name="accountHolderName"
              control={control}
              placeholder="Name of Account Holder"
              disabled={isLoading || disabled}
              full
            />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn lg={12} md={12}>
            <ControlledInput
              name="accountNumber"
              control={control}
              rules={{ required: !isXeroAccount && !isFinverseAccount }}
              placeholder="Account No."
              type="number"
              disabled={isLoading || disabled || isXeroAccount || isFinverseAccount}
              full
            />
          </GridColumn>
        </GridRow>
        <GridRow>
          <ButtonWrapper lg={6} md={6}>
            <Button type="submit" disabled={isLoading || disabled} full>
              {isEditingBank ? 'Update Bank Account' : 'Add a Bank Account'}
            </Button>
          </ButtonWrapper>
          <ButtonWrapper lg={6} md={6}>
            <Button onClick={() => dispatch(hideModal())} disabled={isLoading} tertiary full>
              Cancel
            </Button>
          </ButtonWrapper>
        </GridRow>
      </form>
    </Modal>
  );
};

export default BankAccountFormModal;
