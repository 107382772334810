import React from 'react';

import styled from 'styled-components';

import { pageSizeOptions as defaultPageSizeOptions } from '../../constants/options';
import { baseStyle } from './styles';

const Wrapper = styled.div`
  ${baseStyle};
`;

const Pagination = ({
  pageNumber,
  pageSize,
  pageCount,
  pageSizeOptions = defaultPageSizeOptions,
  onPageNumberChange = () => {},
  onPageSizeChange = () => {},
  formatPageNavLabel = (number, count) => {
    return ` ${number} of ${count} `;
  },
  disabled,
}) => {
  const inFirstPage = pageNumber === 1;
  const inLastPage = pageNumber === pageCount;

  const handlePageSizeChange = (event) => {
    onPageSizeChange(Number(event.target.value));
  };

  const goToFirstPage = () => {
    onPageNumberChange(1);
  };

  const goToPreviousPage = () => {
    onPageNumberChange(pageNumber - 1);
  };

  const goToNextPage = () => {
    onPageNumberChange(pageNumber + 1);
  };

  const goToLastPage = () => {
    onPageNumberChange(pageCount);
  };

  return (
    <Wrapper>
      <button type="button" disabled={inFirstPage || disabled} onClick={goToFirstPage}>
        {'<<'}
      </button>
      <button type="button" onClick={goToPreviousPage} disabled={inFirstPage || disabled}>
        {'<'}
      </button>
      <span>{formatPageNavLabel(pageNumber, pageCount)}</span>
      <button type="button" disabled={inLastPage || disabled} onClick={goToNextPage}>
        {'>'}
      </button>
      <button type="button" disabled={inLastPage || disabled} onClick={goToLastPage}>
        {'>>'}
      </button>
      <select onChange={handlePageSizeChange} value={pageSize} disabled={disabled}>
        {pageSizeOptions.map((option) => {
          return (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </Wrapper>
  );
};

export default Pagination;
