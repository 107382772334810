import React from 'react';

import _ from 'lodash';
import { Controller } from 'react-hook-form';

import Button from '../../../../components/Button';
import FormTitle from '../../../../components/FormTitle';
import HR from '../../../../components/HR';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import ControlledInput from '../../../ReactHookForm/ControlledInput';
import ControlledSelect from '../../../ReactHookForm/ControlledSelect';
import { FieldWrapper } from '../../components';
import { Footer } from '../Transfer/CreateTransferPage/components';
import TransferMethodSelect from './TransferMethodSelect';

const customFooter = {};
const FormField = ({ schema: { enabled, field, path, required, rule }, formProps, customSchema = {} }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = formProps;

  const getFooter = () => {
    return customFooter[field.key] || field.description;
  };

  const getLabel = () => {
    return field.label === 'Aba' ? 'Routing number' : field.label;
  };

  if (enabled !== true) {
    return null;
  }

  const footer = getFooter();

  return (
    <FieldWrapper>
      <FormTitle scale={5} required={required}>
        {getLabel()}
      </FormTitle>
      {(() => {
        const controllerProps = {
          control,
          name: path,
          rules: {
            required,
            ...(rule?.pattern && { pattern: new RegExp(rule.pattern) }),
          },
        };

        const hasError = _.get(errors, path);
        switch (field.type) {
          case 'INPUT':
            return <ControlledInput {...controllerProps} full />;
          case 'RADIO':
          case 'SELECT':
            return (
              <ControlledSelect
                {...controllerProps}
                options={field.options}
                full
                {...(field.key === 'country_code' && { disabled: true })}
              />
            );
          case 'PAYMENT_METHOD':
            return (
              <Controller
                {...controllerProps}
                rules={{ required }}
                render={({ field: controllerField }) => {
                  return (
                    <TransferMethodSelect
                      {...controllerField}
                      {...customSchema}
                      hasError={hasError}
                      formProps={formProps}
                      full
                    />
                  );
                }}
              />
            );
          default:
            return null;
        }
      })()}
      {footer && <Footer>{footer}</Footer>}
    </FieldWrapper>
  );
};

export default FormField;
