import PATH from '../../path';

export const TAB = {
  PROFIT_LOSS: PATH.BUSINESS_ACCOUNTING_REPORT_PROFIT_LOSS,
  BALANCE_SHEET: PATH.BUSINESS_ACCOUNTING_REPORT_BALANCE_SHEET,
};

export const ACCOUNTING_TAB_OPTIONS = [
  {
    value: TAB.PROFIT_LOSS,
    label: 'Profit and loss',
  },
  {
    value: TAB.BALANCE_SHEET,
    label: 'Balance Sheet',
  },
];

export const PERIOD_LENGTH_OPTIONS = [
  {
    value: 1,
    label: '1 Month',
  },
  {
    value: 2,
    label: '2 Months',
  },
  {
    value: 3,
    label: '3 Months',
  },
  {
    value: 6,
    label: '6 Months',
  },
  {
    value: 12,
    label: '12 Months',
  },
];
