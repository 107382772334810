import axios from 'axios';
import { stringify } from 'query-string';

import featureToggle from '../../../featureToggle';
import { API_DASHBOARD_PORT, TARGET_COMPANY_ID_NAME, REACT_APP_API_CAL_ENGINE } from '@api/constants';
import { getCompanyId } from '@api/cookies';
import { getRequest, postRequest, deleteRequest } from '@api/requests';

export const dashboardUrl = `${API_DASHBOARD_PORT}/dashboard/v1`;

export const getAnalytics = async (category, currency, platform = []) => {
  const companyId = getCompanyId();
  return await postRequest(
    `${dashboardUrl}/company/${companyId}/analytic${featureToggle.PLATFORM_FILTER ? `/${category}` : ''}`,
    {
      currency,
      timeInterval: 'month',
      timeUnit: 12,
      platform,
    },
  );
};

export const getXeroBankTransactions = async (query = {}, payload) => {
  const companyId = getCompanyId();
  return await postRequest(`${dashboardUrl}/company/${companyId}/xero-bank-transaction?${stringify(query)}`, payload);
};

export const getXeroFilterValues = async () => {
  const companyId = getCompanyId();
  return await getRequest(`${dashboardUrl}/company/${companyId}/xero-filter`);
};

export const getSalesAccounts = async () => {
  const companyId = getCompanyId();
  return await getRequest(`${dashboardUrl}/company/${companyId}/sales-transaction-accounts`);
};

export const getSalesTransactionsData = async (query, payload) => {
  const companyId = getCompanyId();
  return await postRequest(`${dashboardUrl}/company/${companyId}/sales-transaction?${stringify(query)}`, payload);
};

export const getSalesReportSummary = async () => {
  const companyId = getCompanyId();
  return await postRequest(`${dashboardUrl}/company/${companyId}/sales-report-summary`, {
    channel: 'all',
    channel_account_id: '',
    platform: '',
    currency: 'USD',
    group_by: 'channel',
    start_date: '',
    end_date: '',
  });
};

export const getAnalyticData = async (category, accountId, currency, platform = []) => {
  const companyId = getCompanyId();
  return await postRequest(`${dashboardUrl}/company/${companyId}/analytic/${category}/${accountId}`, {
    platform,
    currency,
    timeInterval: 'month',
    timeUnit: 12,
  });
};

export const getRoles = async () => {
  const result = await getRequest(`${dashboardUrl}/users/roles`);

  return result;
};

export const inviteNewUsers = async (company_id, role_id, username) => {
  const result = await postRequest(`${dashboardUrl}/company/${company_id}/users`, {
    role_id,
    username,
  });

  return result;
};

export const getAllUsers = async () => {
  const companyId = getCompanyId();
  const result = await getRequest(`${dashboardUrl}/company/${companyId}/users/role`);

  return result;
};

export const getCreditReport = async () => {
  const companyId = getCompanyId();
  const { data, status } = await axios.get(`${REACT_APP_API_CAL_ENGINE}/v1/company_credit_risk_models/${companyId}`);

  return { data, statusCode: status };
};

export const getIntegrationTasks = async (query) => {
  const { data, status } = await axios.get(
    `${REACT_APP_API_CAL_ENGINE}/v1/integration_tasks/search?${stringify(query, { arrayFormat: 'comma' })}`,
  );

  return { data, statusCode: status };
};

/*
  updateCreditRiskModel sends a request to AWS Gateway to
  run the credit risk model.
  Example URL: https://j46yffjbz4.execute-api.ap-southeast-1.amazonaws.com/Credit_Risk_Production?chosen_revenue_channel=sales&company_id=eaffde27-6f98-48c3-92fa-b78f41f8ba46&mode=system_flow
  @param {object} query the object containing the query parameters for the request URL
  @param {object} payload the object containing the payload to be sent for the POST request URL
  @returns {object} response object containing the statusCode
 */
export const updateCreditRiskModel = async (query = {}, payload) => {
  const companyId = getCompanyId();
  const result = await axios.post(
    `https://j46yffjbz4.execute-api.ap-southeast-1.amazonaws.com/Credit_Risk_Production?${stringify({
      company_id: companyId,
      ...query,
    })}`,
    payload,
  );

  return { statusCode: result.status };
};

export const deleteUserById = async (userId) => {
  const companyId = getCompanyId();
  const result = await deleteRequest(`${dashboardUrl}/company/${companyId}/${userId}`);

  return result;
};
