import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import AppHeader from '../AppHeader';
import { fetchFundBalance } from '@redux/modules/fund/actions';
import { fetchBankAccounts } from '@redux/modules/bank/actions';
import { useSelectedCompanyId, useIsAdminView, useCheckIsAdminViewingAnotherCompany, useAcceptedOffer } from '@redux/selectors';
import TopBar from './components/TopBar';
import TermSheet from './TermSheet';
import Business from './Business';
import Integration from './Integration';
import Capital from './Capital';
import Payment from './Payment';
import Wallet from './Wallet';
import CreditRisk from './CreditRisk';
import { CONTENT_CONTAINER_ID } from './constants';
import Home from './Home';
import PATH from './path';
import Settings from './Settings';
import SideMenu from './SideMenu';
import { fetchIntegrations } from '@redux/modules/application/actions';
import { fetchAllCompaniesAccessedByAdmin } from '@redux/modules/company/actions';
import { fetchAdminOffers, fetchUserOffers } from '@redux/modules/offer/actions';
import { fetchStripeData } from '@redux/modules/payment/actions';
import { fetchWalletData } from '@redux/modules/wallet/actions';
import { media } from '@styles/breakpoints';

const ContentWrapper = styled.div`
  height: calc(100vh - 50px);
  overflow: scroll;
`;

const Content = styled.div`
  padding: 20px 30px 100px;
  float: left;
  width: 100%;
  box-sizing: border-box;
  ${media.desktop`
    padding: 20px;
  `};
`;

const DashboardContainer = () => {
  const selectedCompanyId = useSelectedCompanyId();
  const application = useSelector((state) => state.application.data);
  const userProfile = useSelector((state) => state.user.profile);
  const acceptedOffer = useAcceptedOffer();
  const hideTopBar = useCheckIsAdminViewingAnotherCompany();
  const isInAdminView = useIsAdminView();

  useEffect(() => {
    const fetchApi = async () => {
      if (selectedCompanyId) {
        fetchIntegrations();
        fetchBankAccounts();
        fetchStripeData();
        fetchWalletData();
      }
    };

    fetchApi();
  }, [selectedCompanyId]);

  useEffect(() => {
    const fetchApi = async () => {
      if (isInAdminView && userProfile?.adminRole?.adminId) {
        fetchAllCompaniesAccessedByAdmin(userProfile.adminRole.adminId);
      }
    };

    fetchApi();

    fetchBankAccounts();
  }, [isInAdminView]);

  useEffect(() => {
    if (application.applicationId) {
      if (isInAdminView) {
        fetchAdminOffers(application.applicationId);
      } else {
        fetchUserOffers(application.applicationId);
      }
    }
  }, [isInAdminView, application.applicationId]);

  useEffect(() => {
    if (selectedCompanyId && application.applicationId && acceptedOffer?.id) {
      fetchFundBalance({
        company_id: selectedCompanyId,
        funding_id: application.applicationId,
        offer_id: acceptedOffer?.id,
      });
    }
  }, [selectedCompanyId, application.applicationId, acceptedOffer?.id]);

  return (
    <>
      <SideMenu />
      <div>
        <AppHeader />
        <ContentWrapper id={CONTENT_CONTAINER_ID}>
          {!hideTopBar && <TopBar statusCode={application.statusCode} />}
          <Content>
            <Switch>
              <Route path={PATH.HOME} component={Home} />
              <Route path={PATH.SETTINGS} component={Settings} />
              <Route path={PATH.BUSINESS} component={Business} />
              <Route path={PATH.WALLET} component={Wallet} />
              <Route path={PATH.CAPITAL} component={Capital} />
              <Route path={PATH.INTEGRATION} component={Integration} />
              <Route path={PATH.PAYMENT} component={Payment} />
              {isInAdminView && <Route path={PATH.TERM_SHEET} component={TermSheet} />}
              {isInAdminView && <Route path={PATH.CREDIT_RISK} component={CreditRisk} />}
              {/* <Redirect to={PATH.HOME} /> */}
              <Redirect to={PATH.HOME} />
            </Switch>
          </Content>
        </ContentWrapper>
      </div>
    </>
  );
};

export default DashboardContainer;
