import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '../../components/Button';
import HR from '../../components/HR';
import Modal from '../../components/Modal';
import Select from '../../components/Select';
import { BANK_ACCOUNT_MODAL } from '../Modal/constants';
import ControlledSelect from '../ReactHookForm/ControlledSelect';
import { getSupportedFinverseInstitutions, generateFinverseUrl } from '@api/modules/integration/finverse';
import { fetchBankAccounts } from '@redux/modules/bank/actions';
import { fetchSupportedFinverseInstitutions } from '@redux/modules/integration/finverse/actions';
import { showModal } from '@redux/modules/UI/actions';
import { findFirstMatchInArray } from '@utils/dataTypes';

const Title = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: bold;
  margin-bottom: 30px;
`;

const ManuallyAddAccountMessage = styled.div`
  margin: 18px 0;
`;

const ManualBankLink = styled.span`
  font-weight: 500;
  ${(props) => props.theme.text.link};
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
  ${(props) => props.theme.buttonWrapper};
`;

const EncryptedMessage = styled.div`
  font-weight: 600;
  ${(props) => props.theme.iconPlusText}
`;

const Disclaimer = styled.div`
  font-weight: 500;
  color: ${(props) => props.theme.colors.grayTwo};
  a {
    text-decoration: underline;
  }
`;

const StyledSelect = styled(Select)`
  .react-select__menu-list {
    max-height: 180px;
  }
`;

const NewBankAccountOverviewModal = ({ hideModal }) => {
  const { isLoading, integratedInstitutions, supportedInstitutions } = useSelector((state) => state.integration.finverse);
  const { control, getValues, handleSubmit, watch } = useForm();
  const watchOfBank = watch('bank');
  const [bankOptions, setBankOptions] = useState([]);
  const dispatch = useDispatch();

  const showManualAccountModal = () => {
    dispatch(
      showModal(BANK_ACCOUNT_MODAL, {
        onAfterSubmit: fetchBankAccounts,
      })
    );
  };

  useEffect(() => {
    if (!(Array.isArray(supportedInstitutions) && supportedInstitutions.length > 0)) {
      fetchSupportedFinverseInstitutions();
    }
  }, []);

  useEffect(() => {
    const generateBankOptions = async () => {
      if (Array.isArray(supportedInstitutions)) {
        const options = supportedInstitutions
          .filter((institution) => institution.institution_type === 'BANK')
          .map((institution) => {
            const integratedInstitution = findFirstMatchInArray(
              integratedInstitutions,
              ({ channel_institution_id }) => institution.institution_id === channel_institution_id
            );
            return {
              value: institution.institution_id,
              label: institution.institution_name,
              isDisabled: integratedInstitution,
            };
          });
        setBankOptions(options);
      }
    };

    generateBankOptions();
  }, [JSON.stringify(supportedInstitutions)]);

  const onSubmit = async () => {
    const newWin = window.open('', 'finverse', 'width=520,height=680');
    const { data } = await generateFinverseUrl({
      institution_id: getValues('bank'),
    });

    if (data?.link_url) {
      newWin.location.href = data.link_url;
    }
  };

  return (
    <Modal hideModalOnClickOutside>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title>Add a Bank Account</Title>
        <ControlledSelect
          control={control}
          name="bank"
          rules={{
            required: true,
          }}
          options={bankOptions}
          placeholder="Select a bank"
          isLoading={isLoading}
        />

        <ManuallyAddAccountMessage>
          <span>Unable to search your bank? </span>
          <ManualBankLink onClick={showManualAccountModal}>Add manually here</ManualBankLink>
        </ManuallyAddAccountMessage>
        {watchOfBank && (
          <Disclaimer>
            By clicking continue, you agree to the{' '}
            <a href="https://www.finverse.com/" target="_blank" rel="noreferrer">
              Finverse Term of Use and Private Policy
            </a>
          </Disclaimer>
        )}
        <ButtonWrapper>
          <Button onClick={hideModal} tertiary>
            Cancel
          </Button>
          <Button>Continue</Button>
        </ButtonWrapper>
      </form>
      <HR height={1} margin={20} />
      <EncryptedMessage>
        <img src="/icons/lock.svg" alt="toolTip" />
        <span>Transfer of your information is encrypted end-to-end</span>
      </EncryptedMessage>
    </Modal>
  );
};

export default NewBankAccountOverviewModal;
