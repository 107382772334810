import { isRealObject, findFirstMatchInArray } from './dataTypes';

export const getOptionByValue = (value, options) => {
  if (
    Array.isArray(options) &&
    options.length > 0 &&
    ['number', 'string'].indexOf(typeof value) !== -1 && // in theory value could be any type but we try to restrict its possibility
    value
  ) {
    const response = findFirstMatchInArray(options, (option) => option.value === value);
    if (response) {
      return response;
    }
  }

  return null;
};

export const getOptionLabelByValue = (value, options, config = { defaultLabel: '' }) => {
  const { defaultLabel } = config;
  const option = getOptionByValue(value, options);

  return option?.label ?? defaultLabel;
};

export const getOptionValue = (option) => {
  if (isRealObject(option)) {
    return option.value || '';
  }

  if (typeof option === 'string') {
    return option;
  }

  return '';
};
