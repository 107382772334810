import React from 'react';

import _ from 'lodash';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

import Checkbox, { CheckboxText } from '../../components/Checkbox';

const StyledCheckboxText = styled(CheckboxText)`
  cursor: pointer;
`;

const ControlledCheckbox = ({ name, control, rules = {}, defaultValue, text, ...restProps }) => {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields, errors },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const hasError = _.get(errors, name);
  return (
    <div>
      <Checkbox {...field} hasError={hasError} {...restProps} />
      {text && <StyledCheckboxText onClick={() => field.onChange(!field.value)}>{text}</StyledCheckboxText>}
    </div>
  );
};

export default ControlledCheckbox;
