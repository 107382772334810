import { BASE_REGISTRATION_PATH } from '@/basePath';

const PATH = {
  REGISTER_BUSINESS_INFO: `${BASE_REGISTRATION_PATH}/business`,
  REGISTER_USER_INFO: `${BASE_REGISTRATION_PATH}/user`,
  INVITE_NEW_USER: `${BASE_REGISTRATION_PATH}/team-user`,
  REGISTRATION_SUCCESS: `${BASE_REGISTRATION_PATH}/success`,
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  CONFIRM_EMAIL: '/confirm-email',
};

export default PATH;
