import React from 'react';

import styled from 'styled-components';

import COLORS from '../../styles/colors';
import { PLATFORM } from '@constants/platform';

const Wrapper = styled.div`
  color: ${(props) => props.color};

  ${(props) => props.isLink && props.theme.text.link};
`;

const configure = (status, showInstallationLink) => {
  switch (status) {
    case -1:
      return {
        color: COLORS.red,
        label: 'Inactive',
      };
    case 0:
      if (showInstallationLink) {
        return {
          color: COLORS.orange,
          label: 'Connect Now',
        };
      }
      return {
        color: COLORS.red,
        label: 'Pending',
      };
    case 1:
    case 2:
      return {
        color: COLORS.green,
        label: 'Connected',
      };
    default:
      return {
        color: '',
        label: '',
      };
  }
};

const PlatformStatusTag = ({ status, platformName, installationLink }) => {
  const showInstallationLink = status === 0 && platformName === PLATFORM.SHOPIFY && installationLink;
  const { color, label } = configure(status, showInstallationLink);
  return (
    <Wrapper color={color} isLink={showInstallationLink}>
      {showInstallationLink ? (
        <a href={showInstallationLink} target="_blank" rel="noreferrer">
          {label}
        </a>
      ) : (
        label
      )}
    </Wrapper>
  );
};

export default PlatformStatusTag;
