import axios from 'axios';
import { stringify } from 'query-string';

import { REACT_APP_API_CAL_ENGINE } from '@api/constants';
import { getCompanyId } from '@api/cookies';
import { getRequest, postRequest } from '@api/requests';

const bankSettlementEndpoint = `${REACT_APP_API_CAL_ENGINE}/v1/bank_rev_settlements`;
const autoSettlementEndpoint = `${REACT_APP_API_CAL_ENGINE}/v1/auto_rev_settlements`;

export const createManualAdjustment = async (payload) => {
  const companyId = getCompanyId();
  const { status } = await axios.post(`${bankSettlementEndpoint}/manual`, {
    ...payload,
    company_id: companyId,
  });

  return {
    statusCode: status,
  };
};

export const editManualAdjustment = async (id, payload) => {
  const { status } = await axios.put(`${bankSettlementEndpoint}/manual/${id}`, payload);

  return {
    statusCode: status,
  };
};

export const getSettlements = async (query) => {
  const companyId = getCompanyId();
  const result = await axios.get(
    `${bankSettlementEndpoint}/search?${stringify({
      ...query,
      company_id: companyId,
    })}`
  );

  return result;
};

export const getSettlementNames = async () => {
  const companyId = getCompanyId();
  const result = await axios.get(`${bankSettlementEndpoint}/used_names/${companyId}`);
  return result.data;
};

export const submitSettlementById = async (id) => {
  const { status } = await axios.put(`${bankSettlementEndpoint}/${id}/submit`);

  return {
    statusCode: status,
  };
};

export const confirmSettlementById = async (id) => {
  const { status } = await axios.put(`${bankSettlementEndpoint}/${id}/confirm`);

  return {
    statusCode: status,
  };
};

export const withdrawSettlementById = async (id) => {
  const { status } = await axios.put(`${bankSettlementEndpoint}/${id}/withdraw`);

  return {
    statusCode: status,
  };
};

export const appealSettlementById = async (id, payload) => {
  const { status } = await axios.put(`${bankSettlementEndpoint}/${id}/appeal`, payload);

  return {
    statusCode: status,
  };
};

export const createBankTrxSettlement = async (payload) => {
  const companyId = getCompanyId();
  const result = await axios.post(`${bankSettlementEndpoint}/bank`, {
    ...payload,
    company_id: companyId,
  });

  return result;
};

export const getAccountUsedTransactions = async (accountId) => {
  const result = await axios.get(`${bankSettlementEndpoint}/used_txns/${accountId}`);

  return result;
};

export const getBankSettlementDetailById = async (id) => {
  const result = await axios.get(`${bankSettlementEndpoint}/${id}?expand=true`);
  return result;
};

export const getAutoSettlementDetailById = async (id, type = 'in', query) => {
  const result = await axios.get(`${autoSettlementEndpoint}/${id}/chosen_txns/${type}?${stringify(query)}`);
  return result;
};
