import { css } from 'styled-components';

export const baseStyle = css`
  margin: 12px 0;
  text-align: center;
  span {
    line-height: 22px;
  }

  select {
    margin-left: 16px;
  }

  select,
  button {
    height: 22px;
  }

  button {
    margin: 0 4px;
  }
`;
