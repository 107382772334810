import React from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Dropdown, { DropdownOption } from '../../../../../components/Dropdown';
import Table from "../../../../../components/Table";
import { deleteUserById } from '@api/modules/dashboard';
import { MESSAGE_MODAL } from '@modules/Modal/constants';
import { showModal } from '@redux/modules/UI/actions';
import { useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';

export const userListColumns = [
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'First name',
    accessor: 'first_name',
  },
  {
    Header: 'Last name',
    accessor: 'last_name',
  },
  {
    Header: 'Role',
    accessor: 'role_name',
  },
  {
    Header: '',
    accessor: 'user_id',
    applyOnRowClick: false,
    Cell: ({ value, row }) => {
      const dispatch = useDispatch();
      const disabled = useCheckIsAdminViewingAnotherCompany() || row.role_name === 'Owner';
      const history = useHistory();
      return (
        <Dropdown>
          <DropdownOption
            onClick={() => {
              if (!disabled) {
                dispatch(
                  showModal(MESSAGE_MODAL, {
                    title: 'Do you confirm to delete',
                    description: `Remove ${row.email} from account`,
                    onConfirm: async () => {
                      const { statusCode } = await deleteUserById(value);

                      if (statusCode === 200) {
                        // TODO: fix in future
                        history.go(0);
                      }
                    },
                    rightButtonProps: {
                      label: 'Remove',
                    },
                  })
                );
              }
            }}
            warning
            disabled={disabled}
          >
            Delete
          </DropdownOption>
        </Dropdown>
      );
    },
  },
];

const InvitedUserTable = ({ data }) => {
  return <Table columns={userListColumns} data={data} />;
};
export default InvitedUserTable;
