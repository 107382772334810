import React from 'react';

import styled, { css } from 'styled-components';

import Button from '../../components/Button';
import { STATUS_CODE } from '@constants/application';
import { OFFER_CONDITION } from '@constants/offer';
import { remittancePeriodOptions } from '@constants/options';
import { isRejectedByCreditTeam } from '@utils/applicationHelpers';
import { convertToFullDate } from '@utils/dateHelpers';
import { getOptionLabelByValue } from '@utils/optionHelpers';
import { formatPrice, formatPercent } from '@utils/priceHelpers';

export const Wrapper = styled.span`
  width: 275px;
  min-height: 300px;
  background: ${(props) => props.theme.colors.white};
  border: 2px solid ${(props) => props.theme.colors.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  margin-right: 25px;
  margin-bottom: 25px;
  text-align: center;
  font-weight: 600;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;

const Content = styled.div`
  margin-top: 16px;
  ${(props) => props.theme.text.micro};
`;

const StyledButton = styled(Button)`
  margin: 16px 0;
`;

const Label = styled.div`
  ${(props) => props.theme.text.button};
  text-align: left;
  font-weight: 700;
`;

const ExpiryDate = styled.div`
  ${(props) => props.theme.text.micro};
  text-align: left;
`;

const Amount = styled.div`
  ${(props) => props.theme.text.three};
  color: ${(props) => props.theme.colors.purple};
`;

const OfferCard = ({ offer, index, applicationHistory, buttonLabel = 'View', onButtonClick = () => {} }) => {
  const disabled =
    [OFFER_CONDITION.DISCARDED, OFFER_CONDITION.EXPIRED].indexOf(offer.condition) !== -1 || isRejectedByCreditTeam(applicationHistory);
  return (
    <Wrapper disabled={disabled}>
      <Label>{`OFFER ${index + 1}`}</Label>
      <Content>
        <Amount>{formatPrice(offer.purchasedPriceCurrency, offer.purchasedPriceAmount)}</Amount>
        (amount received on {convertToFullDate(offer.disbursementDate)}
        )
        <br />
        <br />
        You’ll return {formatPercent(offer.retrievalPercentage, 2)} of your{' '}
        {getOptionLabelByValue(offer.remittancePeriod, remittancePeriodOptions)} revenue starting from{' '}
        {convertToFullDate(offer.firstRemittanceDate)}, until you’ve remitted{' '}
        {formatPrice(offer.purchasedReceivablesCurrency, offer.purchasedReceivablesAmount)}.
      </Content>
      <StyledButton onClick={onButtonClick} disabled={disabled} full>
        {buttonLabel}
      </StyledButton>
      <ExpiryDate>{`OFFER EXPIRES: ${convertToFullDate(offer.expiryDate)}`}</ExpiryDate>
    </Wrapper>
  );
};

export default OfferCard;
