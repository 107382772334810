const env = process.env.REACT_APP_NODE_ENV;

const featureToggle = {
  SWIFT_BIC_VALIDATOR: false,
  FILE_MANAGEMENT: true,
  MANUAL_INTEGRATION: true,
  PLATFORM_FILTER: true,
  DOWNLOAD_ALL: true,
  REMITTANCE: true,
  INVOICE: true,
  ACCOUNTING_REPORT: true,
  WALLET: true,
  WALLET_VIRTUAL_CARD: env !== 'production',
  WALLET_CONVERSION: true,
  PAYMENT: true,
  NEXT_PAYMENT_DATE: false,
  FINVERSE: env !== 'production',
  INVOICE_404_UI: false,
  HIDE_FOR_SHOPIFY: true,
};

export default featureToggle;
