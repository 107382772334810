export const GET_REMITTANCE_STREAMS_REQUEST = 'GET_REMITTANCE_STREAMS_REQUEST';
export const GET_REMITTANCE_STREAMS_SUCCESS = 'GET_REMITTANCE_STREAMS_SUCCESS';
export const GET_REMITTANCE_STREAMS_FAILURE = 'GET_REMITTANCE_STREAMS_FAILURE';
export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILURE = 'GET_INVOICES_FAILURE';
export const GET_INVOICE_REQUEST = 'GET_INVOICE_REQUEST';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const GET_INVOICE_FAILURE = 'GET_INVOICE_FAILURE';
export const GET_BALANCE_TRANSACTIONS_REQUEST = 'GET_BALANCE_TRANSACTIONS_REQUEST';
export const GET_BALANCE_TRANSACTIONS_SUCCESS = 'GET_BALANCE_TRANSACTIONS_SUCCESS';
export const GET_BALANCE_TRANSACTIONS_FAILURE = 'GET_BALANCE_TRANSACTIONS_FAILURE';
export const SET_SELECTED_INVOICE = 'SET_SELECTED_INVOICE';
export const GET_FUND_BALANCE_REQUEST = 'GET_FUND_BALANCE_REQUEST';
export const GET_FUND_BALANCE_SUCCESS = 'GET_FUND_BALANCE_SUCCESS';
export const GET_FUND_BALANCE_FAILURE = 'GET_FUND_BALANCE_FAILURE';
