import styled from 'styled-components';

import styles from './Contact.module.scss';

const ContactWrapper = styled.div`
  margin-bottom: 60px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  margin-top: 72px;
  justify-content: center;
  @media screen and (max-width: 479px) {
    padding-right: 20px;
    padding-left: 20px;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
  }
`;

const BodyWrapper = styled.div`
  margin-top: 24px;
`;

const ImageWrapper = styled.div`
  display: flex;
  margin-top: 44px;
  margin-bottom: 24px;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;
`;

const Contact = () => {
  const btnClasses = `${styles['contact__btn']} ${styles['contact__w-btn']}`;
  const imageUrl =
    'https://uploads-ssl.webflow.com/62abd75c30087cf5eaaabbf2/62abd75c30087c5574aabead_Shopify%20landing%20page%20hero%20image.png';
  return (
    <ContactWrapper>
      <div>
        <HeaderWrapper>
          <h1 className={styles['contact__header']}>
            <span className={styles['contact__header--special']}>Growth insight</span> of your Shopify shop
            <span className={styles['contact__header--dot']}>.</span>
          </h1>
        </HeaderWrapper>
        <BodyWrapper>
          <div className={styles['contact__body']}>
            We know your company needs clear insights to grow in a short time. We are here with you.
          </div>
        </BodyWrapper>
        <ButtonWrapper>
          <a href="mailto:support@choco-up.com" className={btnClasses}>
            Contact us
          </a>
        </ButtonWrapper>
        <ImageWrapper>
          <img src={imageUrl} alt="choco up logo" width="600" height="350"></img>
        </ImageWrapper>
      </div>
    </ContactWrapper>
  );
};

export default Contact;
