import React from 'react';

import Modal from '../../../components/Modal';
import Airwallex from './Instructions/Airwallex';
import AppleStore from './Instructions/AppleStore';
import Ebay from './Instructions/Ebay';
import GoogleAds from './Instructions/GoogleAds';
import PayPal from './Instructions/PayPal';
import PlayStore from './Instructions/PlayStore';
import QuickBooks from './Instructions/Quickbooks';
import ZohoBooks from './Instructions/ZohoBooks';
import { PLATFORM } from '@constants/platform';

const ManualIntegrationModal = (modalProps) => {
  const platformName = modalProps;

  const getModalContent = () => {
    switch (platformName.companyName) {
      case PLATFORM.PAYPAL:
        return (
          <PayPal />
        );
      case PLATFORM.AIRWALLEX:
        return (
          <Airwallex />
        );
      case PLATFORM.EBAY:
        return (
          <Ebay />
        );
      case PLATFORM.GOOGLE_PLAY:
        return (
          <PlayStore />
        );
      case PLATFORM.APPLE_STORE:
        return (
          <AppleStore />
        );
      case PLATFORM.ZOHOBOOKS:
        return (
          <ZohoBooks />
        );
      case PLATFORM.QUICKBOOKS:
        return (
          <QuickBooks />
        );
      case PLATFORM.GOOGLE_ADS:
        return (
          <GoogleAds />
        );
      default:
        return (
          null
        );
    }
  };
  return (
    <Modal>
      {getModalContent()}
    </Modal>
  );
};

export default ManualIntegrationModal;
