const COLORS = {
  purple: '#3A0CA3',
  lightPurple: '#EBE7F6',
  darkPurple: '#2C1956',
  orange: '#E56824',
  lightOrange: '#fdd9b5',
  red: '#9A031E',
  green: '#41D3BD',
  black: '#222222',
  white: '#FFFFFF',
  grayOne: '#F8F8F8', // named off-white in Figma
  grayTwo: '#C9C9C9', // named light gray in Figma
  grayThree: '#505050', // named dark gray in Figma
  grayFour: '#F7F7F7', // name background-color checkbox in Figma
};

export const ALERT_CARD_COLOR = {
  redBackground: '#FFF6F6',
  redBorder: '#DEC1C7',
};

export default COLORS;
