import React, { useEffect, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import Button from '../../../../components/Button';
import CountrySelect from '../../../../components/CountrySelect';
import Header from '../../../../components/Header';
import PhoneInput from '../../../../components/PhoneInput';
import COLORS from '../../../../styles/colors';
import Logger from '../../../../utils/logger';
import ControlledInput from '../../../ReactHookForm/ControlledInput';
import ControlledSelect from '../../../ReactHookForm/ControlledSelect';
import { Card } from '../../components';
import { FormRow, GridColumn } from '@components/Grid';
import { monthlyRevenueOptions, businessTypeOptions } from '@constants/options';
import { useSelectedCompany, useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';
import { emailRegExp, webAddressRegExp } from '@utils/validators';
import { USER_STATUS } from '@/constants';
import { resendEmailVerification } from '@api/modules/auth';

const env = process.env.REACT_APP_NODE_ENV;

const FieldTitle = styled.span`
  ${(props) => props.theme.text.five};
`;

const CardTitle = styled.span`
  ${(props) => props.theme.text.three};
  font-weight: 600;
`;

const FieldWrapper = styled.div`
  ${(props) => props.theme.form.field};
  width: 66%;
`;

const VerifyButton = styled.button`
  margin-left: 8px;
  color: ${(props) => props.theme.colors.orange};
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  ${(props) => props.theme.text.five};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;

const StatusLabel = styled.div`
  ${(props) => props.theme.iconPlusText};
  color: ${(props) => props.color};
  ${(props) => props.theme.text.five};
  line-height: 25px;
`;
const isInDevelopment = env === 'development';

const Account = () => {
  const { status: userStatus, username } = useSelector((state) => state.user.profile);
  const company = useSelectedCompany();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: company,
  });

  const [isLoading, toggleIsLoading] = useState(false);
  const adminIsViewingAnotherCompany = useCheckIsAdminViewingAnotherCompany();

  useEffect(() => {
    reset(company);
  }, [JSON.stringify(company)]);

  const onSubmit = (formData) => {
    console.log('formData: ', formData);
  };

  const handleResendClick = async (event) => {
    toggleIsLoading(true);
    try {
      event.preventDefault();
      await resendEmailVerification(username);
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  return (
    <>
      <Header>Your Account Information</Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <FormRow>
            <CardTitle>Account Information</CardTitle>
          </FormRow>
          <FieldWrapper>
            <FieldTitle>Your First Name</FieldTitle>
            <ControlledInput
              name="contactFirstName"
              control={control}
              rules={{ required: true }}
              placeholder="First name"
              disabled={!isInDevelopment}
              full
            />
          </FieldWrapper>
          <FieldWrapper>
            <FieldTitle>Your Last Name</FieldTitle>
            <ControlledInput
              name="contactLastName"
              control={control}
              rules={{ required: true }}
              placeholder="Last name"
              disabled={!isInDevelopment}
              full
            />
          </FieldWrapper>
          <FieldWrapper>
            <FieldTitle scale={5}>Your Mobile Number</FieldTitle>
            <Controller
              name="contactMobile"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <PhoneInput {...field} hasError={errors.contactMobile} placeholder="" disabled={!isInDevelopment} full />
              )}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FieldTitle>Work Email Address</FieldTitle>
            <ControlledInput
              name="contactEmail"
              control={control}
              rules={{
                required: true,
                pattern: emailRegExp,
              }}
              placeholder="Email address"
              disabled
              full
            />
            {!adminIsViewingAnotherCompany && (
              <>
                {userStatus === USER_STATUS.PENDING && (
                  <StatusLabel color={COLORS.red}>
                    <img src="/icons/alert-in-circle.svg" alt="tick" />
                    <span>Email not verified</span>
                    <VerifyButton onClick={handleResendClick} disabled={isLoading} secondary small>
                      Resend Verification
                    </VerifyButton>
                  </StatusLabel>
                )}
                {userStatus === USER_STATUS.ACTIVE && (
                  <StatusLabel color={COLORS.green}>
                    <img src="/icons/tick-in-circle.svg" alt="tick" />
                    <span>Email verified</span>
                  </StatusLabel>
                )}
              </>
            )}
          </FieldWrapper>
        </Card>
        <Card>
          <CardTitle>Other Account Information</CardTitle>
          <FieldWrapper>
            <FieldTitle>Account Name</FieldTitle>
            <ControlledInput
              name="companyName"
              control={control}
              rules={{
                required: true,
              }}
              placeholder="The Nugget Company"
              disabled={!isInDevelopment}
              full
            />
          </FieldWrapper>
          <FieldWrapper>
            <FieldTitle>Website of Business</FieldTitle>
            <ControlledInput
              name="website"
              control={control}
              rules={{
                required: true,
                pattern: webAddressRegExp,
              }}
              placeholder="http://"
              disabled={!isInDevelopment}
              full
            />
          </FieldWrapper>
          <FieldWrapper>
            <FieldTitle>Country of Origin</FieldTitle>
            <Controller
              name="countryOrigin"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => <CountrySelect {...field} hasError={errors.countryOrigin} disabled={!isInDevelopment} full />}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FieldTitle>Monthly Revenue (USD)</FieldTitle>
            <ControlledSelect
              name="monthlyRevenueRange"
              control={control}
              rules={{
                required: true,
              }}
              options={monthlyRevenueOptions}
              disabled={!isInDevelopment}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FieldTitle>Business Type</FieldTitle>
            <ControlledSelect
              name="businessType"
              control={control}
              rules={{ required: true }}
              options={businessTypeOptions}
              disabled={!isInDevelopment}
            />
          </FieldWrapper>
          {isInDevelopment && (
            <FormRow>
              <GridColumn lg={4} md={4}>
                <Button type="submit" full>
                  Save
                </Button>
              </GridColumn>
            </FormRow>
          )}
        </Card>
      </form>
    </>
  );
};

export default Account;
