import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import StepIndicator from '../../../components/StepIndicator';
import { checkSalesFormErrors, checkBankFormErrors } from '../helpers';
import PATH, { getPathOptions } from '../path';
import { STATUS_CODE } from '@constants/application';
import { COMPANY_FILE_TYPE } from '@constants/file';
import { INTEGRATION_STATUS } from '@/constants';
import { fetchFilesByType } from '@redux/modules/company/actions';
import { useSelectedCompany, useCheckIsAdminViewingAnotherCompany, useCheckIsSignUpViaShopify } from '@redux/selectors';
import {
  salesPlatformsFilter,
  marketingPlatformsFilter,
  accountingPlatformsFilter,
  salesOthersPlatformsFilter,
} from '@utils/platformHelpers';

const Wrapper = styled.div`
  margin: 0 auto;
  width: 200px;
`;

const LinkWrapper = styled.div`
  margin: 12px 0;
`;

const Link = styled.div`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 12px;
  ${(props) =>
    props.active &&
    css`
      color: ${props.theme.colors.purple};
      font-size: 15px;
    `};
`;

const Label = styled.span`
  vertical-align: middle;
  margin-left: 20px;
`;

const Separator = styled.div`
  width: 4px;
  height: 20px;
  margin: 0 8px;
  border-radius: 4px;
  background-color: ${(props) => (props.isHighlighted ? props.theme.colors.purple : props.theme.colors.grayTwo)};
`;

const MenuItem = ({ value, label, index, isActive, isCompleted, isAllowed, highlightSeparator, optionLength, isReady }) => {
  const history = useHistory();
  const handleClick = () => {
    if (isCompleted || isAllowed) {
      history.push(value);
    }
  };

  return (
    <>
      <LinkWrapper>
        <Link href={PATH.SALES_ACCOUNTS} onClick={handleClick} active={isActive}>
          <StepIndicator highlight={isActive || isCompleted || isReady} showTick={isCompleted} stepNumber={index + 1} />
          <Label>{label}</Label>
        </Link>
      </LinkWrapper>
      {index < optionLength - 1 && <Separator isHighlighted={highlightSeparator} />}
    </>
  );
};

const SideMenu = ({ allowAllPages }) => {
  const history = useHistory();
  const company = useSelectedCompany();
  const companyOwners = useSelector((state) => state.company.owners);
  const companyEntities = useSelector((state) => state.company.entities);
  const { data: applicationData } = useSelector((state) => state.application);
  const banks = useSelector((state) => state.bank.activeBankAccounts);
  const integrations = useSelector((state) => state.application.integrations);
  const marketingIntegrations = integrations.filter(({ platformName }) => marketingPlatformsFilter(platformName));
  const pnlFiles = useSelector((state) => state.company.files[COMPANY_FILE_TYPE.PNL]);
  const otherFiles = useSelector((state) => state.company.files[COMPANY_FILE_TYPE.OTHER]);
  const agreementFiles = useSelector((state) => state.company.files[COMPANY_FILE_TYPE.AGREEMENT]);
  const location = useLocation();
  const isAdmin = useCheckIsAdminViewingAnotherCompany();
  const isSignUpViaShopify = useCheckIsSignUpViaShopify();

  useEffect(() => {
    fetchFilesByType(COMPANY_FILE_TYPE.PNL);
    fetchFilesByType(COMPANY_FILE_TYPE.OTHER);
    fetchFilesByType(COMPANY_FILE_TYPE.AGREEMENT, applicationData.applicationId);
  }, []);

  const hasCompleted = (validateCallback) => {
    const errors = validateCallback();
    return Object.values(errors).filter((error) => error === true).length === 0;
  };

  const salesAccounts = integrations.filter(({ platformName }) => salesPlatformsFilter(platformName));
  const salesOthersPlatform = integrations.filter(({ integrationType }) => salesOthersPlatformsFilter(integrationType));

  const isSalesFormCompleted = hasCompleted(() =>
    checkSalesFormErrors(salesAccounts, salesOthersPlatform, applicationData.salesForm.amount, applicationData.salesForm.currency)
  );
  const isOfferFormCompleted = applicationData.statusCode >= STATUS_CODE.REQUESTED_FUNDING_AMOUNT;
  const isMarketingFormComplete =
    Array.isArray(marketingIntegrations) &&
    marketingIntegrations.length > 0 &&
    marketingIntegrations.every(({ status }) => status >= INTEGRATION_STATUS.CONNECTED);
  const isBankAccountsCompleted = hasCompleted(() => checkBankFormErrors(banks));
  const isAccountingCompleted =
    integrations.filter(({ platformName, status }) => accountingPlatformsFilter(platformName) && status >= INTEGRATION_STATUS.CONNECTED)
      .length > 0 || pnlFiles.length > 0;
  const isCompanyDetailsCompleted =
    company.registerNumber &&
    ((Array.isArray(companyOwners) && companyOwners.length > 0) || (Array.isArray(companyEntities) && companyEntities.length > 0));
  const isTermSheetCompleted = applicationData.statusCode > STATUS_CODE.PENDING_USER_REVIEW_TERM_SHEET;
  const isAdditionalInfoCompleted = Array.isArray(otherFiles) && otherFiles.length > 0;
  const isAgreementCompleted = applicationData.statusCode >= STATUS_CODE.FUND_DISBURSED;

  const formCompleteness = [
    isSalesFormCompleted,
    isOfferFormCompleted,
    isMarketingFormComplete,
    isBankAccountsCompleted,
    isAccountingCompleted,
    isTermSheetCompleted,
    isCompanyDetailsCompleted,
    isAdditionalInfoCompleted,
    isAgreementCompleted,
  ];

  const pathOptions = getPathOptions(allowAllPages, isSignUpViaShopify);

  const checkPageIsReady = (value) => {
    if (value === PATH.TERM_SHEET) {
      return applicationData.statusCode === STATUS_CODE.PENDING_USER_REVIEW_TERM_SHEET;
    } else if (value === PATH.AGREEMENT) {
      return applicationData.statusCode >= STATUS_CODE.PENDING_USER_SIGN_AGREEMENT;
    } else {
      return false;
    }
  };

  const checkIsNextFormActive = (nextPath) => {
    // entailing url, e.g. '/company/key-owner' entail '/company'
    return location.pathname.indexOf(nextPath) !== -1;
  };

  return (
    <Wrapper>
      {Array.isArray(pathOptions) &&
        pathOptions.map(({ value, label, alwaysAllowed }, index) => {
          const isActive = location.pathname.indexOf(value) !== -1;
          const isCompleted = formCompleteness[index];
          const isReady = checkPageIsReady(value);
          const nextFormPathValue = pathOptions[index + 1] ? pathOptions[index + 1].value : '';
          const isNextFormActive = checkIsNextFormActive(nextFormPathValue);
          const isNextFormCompleted = formCompleteness[index + 1];
          return (
            <MenuItem
              key={value}
              value={value}
              label={label}
              index={index}
              isActive={isActive}
              isCompleted={isCompleted}
              isAllowed={alwaysAllowed || isAdmin}
              highlightSeparator={isCompleted && (isNextFormActive || isNextFormCompleted)}
              optionLength={pathOptions.length}
              isReady={isReady}
            />
          );
        })}
    </Wrapper>
  );
};

export default SideMenu;
