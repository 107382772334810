import Logger from '../../../utils/logger';
import store from '../../store';
import {
  GET_REMITTANCE_STREAMS_REQUEST,
  GET_REMITTANCE_STREAMS_SUCCESS,
  GET_REMITTANCE_STREAMS_FAILURE,
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE,
  SET_SELECTED_INVOICE,
  GET_INVOICE_REQUEST,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_FAILURE,
  GET_BALANCE_TRANSACTIONS_REQUEST,
  GET_BALANCE_TRANSACTIONS_SUCCESS,
  GET_BALANCE_TRANSACTIONS_FAILURE,
  GET_FUND_BALANCE_REQUEST,
  GET_FUND_BALANCE_SUCCESS,
  GET_FUND_BALANCE_FAILURE,
} from './constants';
import { getInvoiceDetailById } from '@api/modules/dashboard/invoice';
import { getRemittanceStreams, getInvoices } from '@api/modules/dashboard/remittance';
import { getFundingBalanceTransactions, getFundingBalanceSummary } from '@api/modules/funding';

export const fetchRemittanceStreams = async () => {
  store.dispatch({ type: GET_REMITTANCE_STREAMS_REQUEST });

  try {
    const { data } = await getRemittanceStreams();
    if (Array.isArray(data)) {
      store.dispatch({ type: GET_REMITTANCE_STREAMS_SUCCESS, payload: data });
    }
  } catch (err) {
    Logger.error(err);
  }
};

export const fetchInvoices = async () => {
  store.dispatch({ type: GET_INVOICES_REQUEST });

  try {
    const { data } = await getInvoices();
    if (Array.isArray(data)) {
      store.dispatch({ type: GET_INVOICES_SUCCESS, payload: data });
    }
  } catch (err) {
    Logger.error(err);
  }
};

export const fetchInvoiceDetailById = async (id) => {
  store.dispatch({ type: GET_INVOICE_REQUEST });

  try {
    const data = await getInvoiceDetailById(id);
    if (data) {
      store.dispatch({
        type: GET_INVOICE_SUCCESS,
        payload: { id, data },
      });
    } else {
      store.dispatch({ type: GET_INVOICE_FAILURE });
    }
  } catch (err) {
    Logger.error(err);
  }
};

export const fetchBalanceTransactions = async (fundingNumber) => {
  store.dispatch({ type: GET_BALANCE_TRANSACTIONS_REQUEST });

  try {
    const { data } = await getFundingBalanceTransactions();
    if (Array.isArray(data.results)) {
      store.dispatch({
        type: GET_BALANCE_TRANSACTIONS_SUCCESS,
        payload: data.results.map((item) => ({ ...item, funding_number: fundingNumber })),
      });
    } else {
      store.dispatch({ type: GET_BALANCE_TRANSACTIONS_FAILURE });
    }
  } catch (err) {
    Logger.error(err);
  }
};

export const fetchFundBalance = async (payload) => {
  store.dispatch({ type: GET_FUND_BALANCE_REQUEST });
  try {
    const data = await getFundingBalanceSummary(payload);
    if (Array.isArray(data.results) && data.results[0]) {
      store.dispatch({
        type: GET_FUND_BALANCE_SUCCESS,
        payload: data.results[0],
      });
    } else {
      store.dispatch({ type: GET_FUND_BALANCE_FAILURE });
    }
  } catch (err) {
    Logger.error(err);
  }
};
