import React, { useState, useRef } from 'react';

import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';

import Avatar from '../../../../components/Avatar';
import Button from '../../../../components/Button';
import Textarea from '../../../../components/Textarea';
import ToolTip from '../../../../components/ToolTip';
import Logger from '../../../../utils/logger';
import { ButtonWrapper } from './components';
import { deleteMessageById, editMessageById } from '@api/modules/dashboard/comment';
import { useOnClickOutside } from '@hooks/UI';
import { convertToFullDateAndTime } from '@utils/dateHelpers';

const Wrapper = styled.div`
  padding: 8px 0;
`;

const Content = styled.div`
  display: inline-block;
  width: 70%;
  min-width: 500px;
`;

const InfoItem = styled.span`
  margin-right: 8px;
  color: ${(props) => props.theme.colors.grayThree};
  opacity: 0.8;
  ${(props) => props.theme.text.micro};
`;

const Name = styled.span`
  font-weight: bold;
  margin-right: 8px;
  color: ${(props) => props.theme.colors.grayThree};
`;

const StyledAvatar = styled(Avatar)`
  vertical-align: top !important;
  margin: 2px 16px 0 0;
`;

const Body = styled.div`
  margin: 4px 0;
`;

const ControlItem = styled.span`
  cursor: pointer;
  margin-right: 8px;
  ${(props) => props.theme.text.micro};
`;

const Paragraph = styled.div`
  margin-bottom: 16px;

  :last-child {
    margin-bottom: 0;
  }
`;

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.micro};
  ${(props) => props.theme.text.error};
`;

const Comment = ({
  id,
  userId,
  first_name,
  last_name,
  body,
  created_date,
  created_user,
  snapshot_admin_role_name,
  fetchComments,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const textareaRef = useRef();
  const toolTipRef = useRef();

  const [apiError, setApiError] = useState();
  const [isEditing, toggleIsEditing] = useState(false);
  const [isLoading, toggleIsLoading] = useState(false);
  const createdDateMoment = moment(created_date).fromNow();
  const name = `${first_name} ${last_name}`;

  const deleteMessage = async () => {
    try {
      toggleIsLoading(true);
      await deleteMessageById(id);
      await fetchComments();
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const startEdit = () => {
    toggleIsEditing(true);
    setValue('body', body);
  };

  const cancelEdit = () => {
    toggleIsEditing(false);
    setApiError('');
  };

  const onSubmit = async (formData) => {
    try {
      toggleIsLoading(true);
      setApiError('');
      const { statusCode } = await editMessageById(id, formData);
      if (statusCode === 200) {
        toggleIsEditing(false);
        await fetchComments();
      } else {
        throw new Error('Something went wrong');
      }
    } catch (err) {
      setApiError(err.message);
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const getTextareaRows = () => {
    return Math.max(body.split('\n').length, 3);
  };

  const getBodyText = () => {
    return body.split('\n').map((str) => <Paragraph>{str}</Paragraph>);
  };

  useOnClickOutside(textareaRef, cancelEdit);

  return (
    <Wrapper>
      <StyledAvatar id={created_user} name={name} size={30} useRandomColor />
      <Content>
        <span>
          <Name>{name}</Name>
          <InfoItem>{snapshot_admin_role_name}</InfoItem>
          <ToolTip
            placeholder={<InfoItem>{createdDateMoment}</InfoItem>}
            tip={convertToFullDateAndTime(created_date)}
            innerRef={toolTipRef}
          />
        </span>
        {isEditing ? (
          <form onSubmit={handleSubmit(onSubmit)} ref={textareaRef}>
            <Controller
              name="body"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Textarea
                  {...field}
                  placeholder="Add a comment"
                  hasError={errors.body || apiError}
                  rows={getTextareaRows()}
                  full
                />
              )}
            />
            {apiError && <ErrorMessage>{apiError}</ErrorMessage>}
            <ButtonWrapper>
              <Button type="submit" disabled={isLoading} small>
                Save
              </Button>
              <Button onClick={cancelEdit} disabled={isLoading} type="button" small tertiary>
                Cancel
              </Button>
            </ButtonWrapper>
          </form>
        ) : (
          <Body>{getBodyText()}</Body>
        )}
        {created_user === userId && !isEditing && (
          <div>
            <ControlItem onClick={startEdit}>Edit</ControlItem>
            <ControlItem onClick={deleteMessage} disabled={isLoading}>
              Delete
            </ControlItem>
          </div>
        )}
      </Content>
    </Wrapper>
  );
};

export default Comment;
