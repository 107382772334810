export const PLATFORM = {
  STRIPE: 'stripe',
  XERO: 'xero',
  FRESH_BOOKS: 'fresh-books',
  SHOPIFY: 'shopify',
  PAYPAL: 'paypal',
  CHECKOUT: 'checkout.com',
  LAZADA: 'lazada',
  BBMSL: 'bbmsl',
  GOOGLE_ANALYTICS: 'google-analytics',
  GOOGLE_ADS: 'google-ads',
  FACEBOOK_ADS: 'facebook',
  AIRWALLEX: 'airwallex',
  EBAY: 'ebay',
  APPLE_STORE: 'app-store',
  GOOGLE_PLAY: 'google-play-store',
  WOOCOMMERCE: 'woocommerce',
  MAGENTO: 'magento',
  AMAZON: 'amazon',
  ZOHOBOOKS: 'zohobooks',
  QUICKBOOKS: 'quickbooks',
  SHOPEE: 'shopee',
  SALES_OTHERS: 'sales-others',
  FINVERSE: 'finverse',
  SHOPLAZZA: 'shoplazza',
};

export const PLATFORM_DISPLAY_NAME = {
  STRIPE: 'Stripe',
  XERO: 'Xero',
  FRESH_BOOKS: 'Fresh Books',
  SHOPIFY: 'Shopify',
  SHOPIFY_PAYMENT: 'Shopify Payment',
  PAYPAL: 'PayPal',
  CHECKOUT: 'Checkout.com',
  LAZADA: 'Lazada',
  BBMSL: 'BBMSL',
  GOOGLE_ANALYTICS: 'Google Analytics',
  GOOGLE_ADS: 'Google Ads',
  FACEBOOK_ADS: 'Facebook Ads',
  AIRWALLEX: 'Airwallex',
  EBAY: 'eBay',
  GOOGLE_PLAY: 'Google Play',
  APPLE_STORE: 'App Store',
  WOOCOMMERCE: 'WooCommerce',
  MAGENTO: 'Magento',
  AMAZON: 'Amazon',
  ZOHOBOOKS: 'Zoho Books',
  QUICKBOOKS: 'QuickBooks',
  SHOPEE: 'Shopee',
  SALES_OTHERS: 'Others',
  FINVERSE: 'finverse',
  SHOPLAZZA: 'Shoplazza',
};

export const CATEGORY = {
  SALES: 'SALES',
  PAYMENT: 'PAYMENT',
  MARKETING: 'MARKETING',
  ANALYTICS: 'ANALYTICS',
  ACCOUNTING: 'ACCOUNTING',
  CAMPAIGN: 'CAMPAIGN',
};

export const PLATFORM_MAP = {
  // payment
  [PLATFORM.BBMSL]: {
    value: PLATFORM.BBMSL,
    displayName: PLATFORM_DISPLAY_NAME.BBMSL,
    logoUrl: '/images/platforms/BBMSL.png',
    category: CATEGORY.PAYMENT,
    enabled: true,
    shown: false,
  },
  [PLATFORM.STRIPE]: {
    value: PLATFORM.STRIPE,
    displayName: PLATFORM_DISPLAY_NAME.STRIPE,
    logoUrl: '/images/platforms/stripe.png',
    category: CATEGORY.PAYMENT,
    enabled: true,
    shown: true,
  },
  [PLATFORM.PAYPAL]: {
    value: PLATFORM.PAYPAL,
    displayName: PLATFORM_DISPLAY_NAME.PAYPAL,
    logoUrl: '/images/platforms/paypal.png',
    category: CATEGORY.PAYMENT,
    enabled: true,
    shown: true,
  },
  // TODO: compare this method
  // [PLATFORM.SHOPIFY]: {
  //   value: PLATFORM.SHOPIFY,
  //   displayName: PLATFORM_DISPLAY_NAME.SHOPIFY_PAYMENT,
  //   logoUrl: '/images/platforms/shopify.png',
  //   category: CATEGORY.PAYMENT,
  //   enabled: true,
  //   shown: true,
  // },
  // sales
  [PLATFORM.SHOPIFY]: {
    value: PLATFORM.SHOPIFY,
    displayName: PLATFORM_DISPLAY_NAME.SHOPIFY,
    logoUrl: '/images/platforms/shopify.png',
    category: CATEGORY.SALES,
    categories: [CATEGORY.SALES, CATEGORY.PAYMENT],
    enabled: true,
    shown: true,
  },
  [PLATFORM.FRESH_BOOKS]: {
    value: PLATFORM.FRESH_BOOKS,
    displayName: PLATFORM_DISPLAY_NAME.FRESH_BOOKS,
    logoUrl: '/images/platforms/fresh-book.png',
    category: CATEGORY.SALES,
    enabled: false,
    shown: true,
  },
  [PLATFORM.LAZADA]: {
    value: PLATFORM.LAZADA,
    displayName: PLATFORM_DISPLAY_NAME.LAZADA,
    logoUrl: '/images/platforms/Lazada.png',
    category: CATEGORY.SALES,
    enabled: true,
    shown: true,
  },

  [PLATFORM.AIRWALLEX]: {
    value: PLATFORM.AIRWALLEX,
    displayName: PLATFORM_DISPLAY_NAME.AIRWALLEX,
    logoUrl: '/images/platforms/Airwallex.png',
    category: CATEGORY.SALES,
    message: 'Coming soon',
    enabled: false,
    shown: true,
  },
  [PLATFORM.EBAY]: {
    value: PLATFORM.EBAY,
    displayName: PLATFORM_DISPLAY_NAME.EBAY,
    logoUrl: '/images/platforms/ebay.png',
    category: CATEGORY.SALES,
    enabled: true,
    shown: true,
  },
  [PLATFORM.GOOGLE_PLAY]: {
    value: PLATFORM.GOOGLE_PLAY,
    displayName: PLATFORM_DISPLAY_NAME.GOOGLE_PLAY,
    logoUrl: '/images/platforms/googlePlay.png',
    category: CATEGORY.SALES,
    enabled: true,
    shown: true,
  },
  [PLATFORM.APPLE_STORE]: {
    value: PLATFORM.APPLE_STORE,
    displayName: PLATFORM_DISPLAY_NAME.APPLE_STORE,
    logoUrl: '/images/platforms/appleStore.png',
    category: CATEGORY.SALES,
    enabled: true,
    shown: true,
  },
  [PLATFORM.AMAZON]: {
    value: PLATFORM.AMAZON,
    displayName: PLATFORM_DISPLAY_NAME.AMAZON,
    logoUrl: '/images/platforms/amazon.png',
    category: CATEGORY.SALES,
    enabled: false,
    shown: false,
  },
  [PLATFORM.WOOCOMMERCE]: {
    value: PLATFORM.WOOCOMMERCE,
    displayName: PLATFORM_DISPLAY_NAME.WOOCOMMERCE,
    logoUrl: '/images/platforms/woocommerce.png',
    category: CATEGORY.SALES,
    enabled: true,
    shown: true,
  },
  [PLATFORM.MAGENTO]: {
    value: PLATFORM.MAGENTO,
    displayName: PLATFORM_DISPLAY_NAME.MAGENTO,
    logoUrl: '/images/platforms/magento.png',
    category: CATEGORY.SALES,
    enabled: true,
    shown: true,
  },
  // analytics
  [PLATFORM.GOOGLE_ANALYTICS]: {
    value: PLATFORM.GOOGLE_ANALYTICS,
    displayName: PLATFORM_DISPLAY_NAME.GOOGLE_ANALYTICS,
    logoUrl: '/images/platforms/ggl-analytics.png',
    category: CATEGORY.ANALYTICS,
    enabled: true,
    shown: true,
  },
  // marketing
  [PLATFORM.GOOGLE_ADS]: {
    value: PLATFORM.GOOGLE_ADS,
    displayName: PLATFORM_DISPLAY_NAME.GOOGLE_ADS,
    // use abbr ggl to prevent some browsers remove the image for google ads
    logoUrl: '/images/platforms/ggl-ads.png',
    category: CATEGORY.MARKETING,
    enabled: true,
    shown: true,
  },
  [PLATFORM.FACEBOOK_ADS]: {
    value: PLATFORM.FACEBOOK_ADS,
    displayName: PLATFORM_DISPLAY_NAME.FACEBOOK_ADS,
    logoUrl: '/images/platforms/facebook-ads.png',
    category: CATEGORY.MARKETING,
    enabled: true,
    shown: true,
  },
  // accounting
  [PLATFORM.XERO]: {
    value: PLATFORM.XERO,
    displayName: PLATFORM_DISPLAY_NAME.XERO,
    logoUrl: '/images/platforms/xero.png',
    category: CATEGORY.ACCOUNTING,
    enabled: true,
    shown: true,
  },
  [PLATFORM.ZOHOBOOKS]: {
    value: PLATFORM.ZOHOBOOKS,
    displayName: PLATFORM_DISPLAY_NAME.ZOHOBOOKS,
    logoUrl: '/images/platforms/zoho-books.png',
    category: CATEGORY.ACCOUNTING,
    enabled: true,
    shown: true,
  },
  [PLATFORM.QUICKBOOKS]: {
    value: PLATFORM.QUICKBOOKS,
    displayName: PLATFORM_DISPLAY_NAME.QUICKBOOKS,
    logoUrl: '/images/platforms/Quickbooks.png',
    category: CATEGORY.ACCOUNTING,
    enabled: true,
    shown: true,
  },
  [PLATFORM.SHOPEE]: {
    value: PLATFORM.SHOPEE,
    displayName: PLATFORM_DISPLAY_NAME.SHOPEE,
    logoUrl: '/images/platforms/shopee.png',
    category: CATEGORY.SALES,
    enabled: true,
    shown: true,
  },
  [PLATFORM.SHOPLAZZA]: {
    value: PLATFORM.SHOPLAZZA,
    displayName: PLATFORM_DISPLAY_NAME.SHOPLAZZA,
    logoUrl: '/images/platforms/shoplazza.png',
    category: CATEGORY.SALES,
    enabled: true,
    shown: true,
  },
};

export const PLATFORM_LIST = Object.values(PLATFORM_MAP);

export const ENABLED_PLATFORMS = PLATFORM_LIST.filter((platform) => platform.enabled).map((platform) => platform.value);

export const MANUAL_INTEGRATION_PLATFORMS = [
  PLATFORM.PAYPAL,
  PLATFORM.AIRWALLEX,
  PLATFORM.EBAY,
  PLATFORM.GOOGLE_PLAY,
  PLATFORM.APPLE_STORE,
  PLATFORM.ZOHOBOOKS,
  PLATFORM.QUICKBOOKS,
  PLATFORM.FACEBOOK_ADS,
  PLATFORM.GOOGLE_ADS,
  // PLATFORM.AMAZON,
];

export const PAYMENT_PLATFORMS = [PLATFORM.STRIPE, PLATFORM.PAYPAL, PLATFORM.CHECKOUT];

export const CAMPAIGN_PLATFORM_LOGOS = [
  {
    value: PLATFORM.BBMSL,
    displayName: PLATFORM_DISPLAY_NAME.BBMSL,
    logoUrl: '/images/platforms/BBMSL.png',
  },
];

// combined sales and analytics platforms
// order matters
export const SALES_PLATFORM_LOGOS = [
  PLATFORM_MAP[PLATFORM.SHOPIFY],
  PLATFORM_MAP[PLATFORM.GOOGLE_ANALYTICS],
  PLATFORM_MAP[PLATFORM.STRIPE],
  PLATFORM_MAP[PLATFORM.WOOCOMMERCE],
  PLATFORM_MAP[PLATFORM.MAGENTO],
  PLATFORM_MAP[PLATFORM.SHOPEE],
  PLATFORM_MAP[PLATFORM.AIRWALLEX],
  PLATFORM_MAP[PLATFORM.LAZADA],
  PLATFORM_MAP[PLATFORM.SHOPLAZZA],
];

export const SALES_PLATFORMS = [...SALES_PLATFORM_LOGOS.map((item) => item.value), PLATFORM.BBMSL];

export const ACCOUNTING_PLATFORM_LOGOS = PLATFORM_LIST.filter(({ enabled, category }) => enabled && category === CATEGORY.ACCOUNTING);

export const ACCOUNTING_PLATFORMS = ACCOUNTING_PLATFORM_LOGOS.map((item) => item.value);

export const MARKETING_PLATFORM_LOGOS = [PLATFORM_MAP[PLATFORM.GOOGLE_ADS], PLATFORM_MAP[PLATFORM.FACEBOOK_ADS]];

export const MARKETING_PLATFORMS = MARKETING_PLATFORM_LOGOS.map((item) => item.value);

export const ALL_LOGOS = [...SALES_PLATFORM_LOGOS, ...ACCOUNTING_PLATFORM_LOGOS, ...CAMPAIGN_PLATFORM_LOGOS, ...MARKETING_PLATFORM_LOGOS];

export const ALL_PLATFORMS = ALL_LOGOS.map((item) => item.value);

// / TODO: Kevin - replace this with an env variable after demo and everything is stable
export const TABLEAU_SCRIPT_URL = 'https://prod-apnortheast-a.online.tableau.com/t/datachocoupcom/views/20220414/Dashboard1';
