import {
  CATEGORY,
  PLATFORM,
  PLATFORM_DISPLAY_NAME,
  PLATFORM_LIST,
  SALES_PLATFORM_LOGOS,
  MANUAL_INTEGRATION_PLATFORMS,
} from '@constants/platform';

export const TAB = {
  OVERVIEW: 'overview',
  SALES: 'sales',
  PAYMENT: 'payment',
  ANALYTICS: PLATFORM.GOOGLE_ANALYTICS, // assume only one option under marketing tab
};

export const TAB_OPTIONS = [
  {
    value: TAB.OVERVIEW,
    label: 'Overview',
  },
  {
    value: TAB.SALES,
    label: 'Sales Channel',
  },
  {
    value: TAB.PAYMENT,
    label: 'Payment Gateway',
  },
  {
    value: TAB.ANALYTICS,
    label: 'Analytics Platform',
  },
];

export const VIEW_ALL_OPTION = {
  value: 'view-all',
  label: 'View All',
  platformName: 'view-all',
};

export const ENABLED_SALES_PLATFORM_OPTIONS = [
  ...SALES_PLATFORM_LOGOS.filter(({ category, enabled }) => enabled && category === CATEGORY.SALES)
    .filter(({ value }) => MANUAL_INTEGRATION_PLATFORMS.indexOf(value) === -1)
    .map(({ value, displayName }) => ({
      value,
      label: displayName,
      platformName: value,
    })),
];

export const ENABLED_PAYMENT_PLATFORM_OPTIONS = [
  ...PLATFORM_LIST.filter(({ category, enabled }) => enabled && category === CATEGORY.PAYMENT)
    .filter(({ value }) => MANUAL_INTEGRATION_PLATFORMS.indexOf(value) === -1)
    .map(({ value, displayName }) => ({
      value,
      label: displayName,
      platformName: value,
    })),
  {
    value: PLATFORM.SHOPIFY,
    label: PLATFORM_DISPLAY_NAME.SHOPIFY_PAYMENT,
    platformName: PLATFORM.SHOPIFY,
  },
];

export const ENABLED_ANALYTICS_PLATFORM_OPTIONS = [
  {
    value: VIEW_ALL_OPTION.value, // assume only one option under analytics tab
    label: PLATFORM_DISPLAY_NAME.GOOGLE_ANALYTICS,
    platformName: PLATFORM.GOOGLE_ANALYTICS,
  },
];

export const CONTENT_CONTAINER_ID = 'content-container';
