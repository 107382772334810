import { LOGIN_USER, LOGOUT_USER } from '../auth/constants';
import { SWITCH_TO_ADMIN_VIEW, SWITCH_TO_USER_VIEW } from '../UI/constants';
import {
  GET_COMPANY_REQUEST,
  GET_COMPANY_FAILURE,
  GET_COMPANY_SUCCESS,
  GET_ADMIN_COMPANIES_SUCCESS,
  SELECT_COMPANY_REQUEST,
  SELECT_COMPANY_FAILURE,
  SELECT_COMPANY_SUCCESS,
  GET_USER_COMPANY_REQUEST,
  GET_USER_COMPANY_FAILURE,
  GET_USER_COMPANY_SUCCESS,
  GET_COMPANY_OWNERS_REQUEST,
  GET_COMPANY_OWNERS_FAILURE,
  GET_COMPANY_OWNERS_SUCCESS,
  GET_FILES_BY_TYPE_SUCCESS,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS,
  GET_COMPANY_ENTITIES_REQUEST,
  GET_COMPANY_ENTITIES_FAILURE,
  GET_COMPANY_ENTITIES_SUCCESS,
  GET_COMPANY_2FA_REQUEST,
  GET_COMPANY_2FA_SUCCESS,
  GET_COMPANY_2FA_FAILURE,
} from './constants';
import { COMPANY_FILE_TYPE } from '@constants/file';

export const initialState = {
  data: {},
  owners: [],
  entities: [],
  files: {
    [COMPANY_FILE_TYPE.PNL]: [],
  },
  company2fa: [],
  isHandlingFiles: false,
  selectedCompanyId: '',
  isLoading: false,
};

const companyReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_COMPANY_REQUEST:
    case GET_USER_COMPANY_REQUEST:
    case GET_COMPANY_OWNERS_REQUEST:
    case GET_COMPANY_ENTITIES_REQUEST:
    case GET_COMPANY_2FA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_COMPANY_FAILURE:
    case SELECT_COMPANY_FAILURE:
    case GET_USER_COMPANY_FAILURE:
    case GET_COMPANY_OWNERS_FAILURE:
    case GET_COMPANY_ENTITIES_FAILURE:
    case GET_COMPANY_2FA_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_COMPANY_SUCCESS:
    case GET_USER_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedCompanyId: action.payload.id,
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
      };
    case GET_ADMIN_COMPANIES_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload,
          [state.selectedCompanyId]: state.data[state.selectedCompanyId],
        },
      };
    case SELECT_COMPANY_SUCCESS:
      return {
        ...state,
        selectedCompanyId: action.payload.id,
      };
    case GET_COMPANY_OWNERS_SUCCESS:
      return {
        ...state,
        owners: action.payload,
      };
    case GET_COMPANY_ENTITIES_SUCCESS:
      return {
        ...state,
        entities: action.payload,
      };
    case GET_FILES_BY_TYPE_SUCCESS:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.type]: action.payload.data,
        },
      };
    case UPLOAD_FILE_REQUEST:
      return {
        ...state,
        isHandlingFiles: true,
      };
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        isHandlingFiles: false,
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        isHandlingFiles: false,
      };
    case GET_COMPANY_2FA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        company2fa: [action.payload],
      };
    case SELECT_COMPANY_REQUEST:
      return {
        ...initialState,
        data: state.data,
        isLoading: true,
        selectedCompanyId: state.selectedCompanyId,
      };
    case SWITCH_TO_USER_VIEW:
      return {
        ...initialState,
        data: state.data,
        selectedCompanyId: state.selectedCompanyId,
      };
    case LOGIN_USER:
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default companyReducer;
