import React, { useState } from 'react';

import styled, { css } from 'styled-components';

import Arrow, { ARROW_DIRECTION } from '../assets/icons/Arrow';

const Wrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.grayTwo};
`;

const TitleWrapper = styled.div`
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

const Title = styled.div`
  ${(props) => props.theme.text.four};
  color: ${(props) => props.theme.colors.black};
  font-weight: bold;
  padding-top: 7px;
  margin-bottom: 10px;
`;

const Heading = styled.div`
  ${(props) => props.theme.text.four};
  color: ${(props) => props.theme.colors.black};
  font-weight: bold;
  padding-top: 7px;
  margin-bottom: 10px;
`;

const StyledArrow = styled(Arrow)`
  padding: 10px;
`;

const Paragraph = styled.div`
  margin-bottom: 15px;
`;

const CenterImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`;

const Content = styled.div`
  color: ${(props) => props.theme.colors.black};
  ${(props) => props.theme.text.five};
  max-height: 0;
  margin-right: 20px;
  overflow: hidden;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);

  ${(props) =>
    props.isOpen &&
    css`
      overflow: hidden;
      transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
      height: auto;
      max-height: 9999px;
    `}
`;

const Accordion = ({ title, contents, children, defaultIsOpen = false }) => {
  const [isOpen, toggleIsOpen] = useState(defaultIsOpen);
  const formatElements = () => {
    return (
      Array.isArray(contents) &&
      contents.map((item) => {
        if (item.type === 'text') {
          return item.content.split('\n').map((str) => <Paragraph>{str}</Paragraph>);
        } else if (item.type === 'heading') {
          return item.content.split('\n').map((str) => <Heading>{str}</Heading>);
        } else if (item.type === 'image') {
          return <CenterImage src={item.link} alt={item.alt} width={item.width} height={item.height} />;
        }
        return <Paragraph />;
      })
    );
  };

  const toggleAccordion = () => {
    toggleIsOpen(!isOpen);
  };

  return (
    <Wrapper>
      <TitleWrapper onClick={toggleAccordion}>
        <Title>{title}</Title>
        <StyledArrow direction={isOpen ? ARROW_DIRECTION.UP : ARROW_DIRECTION.DOWN} />
      </TitleWrapper>
      <Content isOpen={isOpen}>{children || formatElements()}</Content>
    </Wrapper>
  );
};

export default Accordion;
