import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import PATH from '../../../path';
import AdminPanel from './components/AdminPanel';
import MerchantPanel from './components/MerchantPanel';
import { fetchSettlements } from '@redux/modules/settlements/actions';
import { useIsAdminView, useSelectedCompanyId } from '@redux/selectors';

const ViewAllLink = styled(Link)`
  ${(props) => props.theme.text.link};
  float: right;
`;

const SHORTENED_LIST_LENGTH = 3;

const SettlementPanel = (props) => {
  const settlements = useSelector((state) => state.settlement.list);
  const isAdmin = useIsAdminView();
  const selectedCompanyId = useSelectedCompanyId();

  useEffect(() => {
    fetchSettlements(isAdmin);
  }, [isAdmin, selectedCompanyId]);

  const panelProps = {
    ...props,
    settlements: settlements.slice(0, SHORTENED_LIST_LENGTH),
  };

  return (
    <>
      {isAdmin ? <AdminPanel {...panelProps} /> : <MerchantPanel {...panelProps} />}
      {settlements.length > SHORTENED_LIST_LENGTH && <ViewAllLink to={PATH.BUSINESS_SETTLEMENTS}>Show all records</ViewAllLink>}
    </>
  );
};

export default SettlementPanel;
