import React, { ReactNode, useRef } from 'react';

import styled, { css } from 'styled-components';

import Arrow, { ARROW_DIRECTION } from '../assets/icons/Arrow';
import Button from './Button';
import Icon from './Icon';
import { useOnClickOutside, useModal } from '@hooks/UI';

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
`;

export const Menu = styled.div`
  position: absolute;
  top: 24px;
  right: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 10px 15px;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.white};
  min-width: 130px;
  text-align: right;
`;

const Control = styled.span`
  cursor: pointer;
  display: flex;
`;

export const ICON_TYPE = {
  DOTS: 'DOTS',
  ARROW: 'ARROW',
};

const Dropdown = ({
  initialIsMenuOpen = false,
  children,
  icon = '/icons/more_horizontal_black_24dp.svg',
  className = '',
  menuWidth,
  menuTextAlign
}: {
  initialIsMenuOpen?: boolean;
  children: ReactNode;
  icon: string;
  className?: string;
  menuWidth: string;
  menuTextAlign: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
}) => {
  const ref: any = useRef() || null;
  const [isMenuOpen, setMenuOpen] = useModal(initialIsMenuOpen);
  useOnClickOutside(ref, () => setMenuOpen(false));

  const handleDropdownClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <Wrapper ref={ref} className={className} onClick={handleDropdownClick}>
      <Control role="presentation">
        <Icon src={icon} alt="more" />
      </Control>
      {isMenuOpen && <Menu className="dropdown-menu" style={{ width: menuWidth, textAlign: menuTextAlign }}>{children}</Menu>}
    </Wrapper>
  );
};

export const DropdownOptionWrapper = styled.button<any>`
  ${(props) => props.theme.text.five};
  background-color: transparent;
  border: none;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 10px;
  cursor: pointer;

  ${(props) =>
    props.alignLeft &&
    css`
      text-align: left;
    `}

  ${(props) =>
    props.small &&
    css`
      ${props.theme.text.micro};
    `}

  ${(props) =>
    props.warning &&
    css`
      color: ${props.theme.colors.red};
    `}

  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.colors.grayTwo};
      cursor: not-allowed;
    `};

  :last-child {
    margin-bottom: 0;
  }
`;

export const DropdownOption = ({ children, ...restProps }: { children: ReactNode, disabled?: boolean; onClick?: () => Promise<any> }) => {
  return <DropdownOptionWrapper {...restProps}>{children}</DropdownOptionWrapper>;
};

export default Dropdown;
