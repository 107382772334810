import React, { useState } from 'react';

import countries from 'i18n-iso-countries';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../../components/Button';
import HR from '../../../../../components/HR';
import Modal from '../../../../../components/Modal';
import { hideModal } from '@redux/modules/UI/actions';

const AccountInfoHeader = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
  margin-bottom: 24px;
`;

const Title = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: 600;
  margin: 6px 0;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
`;

const CopyButton = styled.button`
  border: none;
  outline: none;
  background: transparent;

  :hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

const StyledButton = styled(Button)`
  float: right;
  margin-top: 20px;
`;

const AccountDetailsModal = ({
  account_name,
  account_number,
  country_code,
  currency,
  institution,
  routing_codes,
  swift_code,
  account_type,
}) => {
  const dispatch = useDispatch();
  return (
    <Modal hideModalOnClickOutside>
      <AccountInfoHeader>Account details</AccountInfoHeader>

      <Title>Account name</Title>
      <Container>
        {account_name}
        <CopyButton
          onClick={() => {
            navigator.clipboard.writeText(account_name);
          }}
        >
          <img src="/icons/copy-icon.svg" alt="copy" />
        </CopyButton>
      </Container>
      <Title>Bank account number</Title>
      <Container>
        {account_number}
        <CopyButton
          onClick={() => {
            navigator.clipboard.writeText(account_number);
          }}
        >
          <img src="/icons/copy-icon.svg" alt="copy" />
        </CopyButton>
      </Container>
      {account_type && (
        <>
          <Title>Account type</Title>
          <Container>
            {account_type}
            <CopyButton
              onClick={() => {
                navigator.clipboard.writeText(account_type);
              }}
            >
              <img src="/icons/copy-icon.svg" alt="copy" />
            </CopyButton>
          </Container>
        </>
      )}
      {Array.isArray(routing_codes) && routing_codes?.[0]?.type === 'bsb' ? (
        <>
          <Title>BSB code</Title>
          <Container>
            {routing_codes[0]?.value}
            <CopyButton
              onClick={() => {
                navigator.clipboard.writeText(routing_codes?.[0]?.value);
              }}
            >
              <img src="/icons/copy-icon.svg" alt="copy" />
            </CopyButton>
          </Container>
        </>
      ) : routing_codes?.[0] ? (
        <>
          <Title>{routing_codes?.[0]?.type === 'ach' ? 'ACH routing number' : 'Bank code'}</Title>
          <Container>
            {routing_codes[0]?.value}
            <CopyButton
              onClick={() => {
                navigator.clipboard.writeText(routing_codes?.[0]?.value);
              }}
            >
              <img src="/icons/copy-icon.svg" alt="copy" />
            </CopyButton>
          </Container>
        </>
      ) : null}
      {Array.isArray(routing_codes) && routing_codes?.[1]?.type === 'branch_code' && (
        <>
          <Title>Branch code</Title>
          <Container>
            {routing_codes?.[1]?.value}
            <CopyButton
              onClick={() => {
                navigator.clipboard.writeText(routing_codes?.[1]?.value);
              }}
            >
              <img src="/icons/copy-icon.svg" alt="copy" />
            </CopyButton>
          </Container>
        </>
      )}
      {swift_code && (
        <>
          <Title>SWIFT code</Title>
          <Container>
            {swift_code}
            <CopyButton
              onClick={() => {
                navigator.clipboard.writeText(swift_code);
              }}
            >
              <img src="/icons/copy-icon.svg" alt="copy" />
            </CopyButton>
          </Container>
        </>
      )}
      <Title>Account location</Title>
      <Container>
        {country_code === 'HK' ? 'Hong Kong (China)' : countries.getName(country_code, 'en', { select: 'official' })}
        <CopyButton
          onClick={() => {
            navigator.clipboard.writeText(
              country_code === 'HK' ? 'Hong Kong (China)' : countries.getName(country_code, 'en', { select: 'official' })
            );
          }}
        >
          <img src="/icons/copy-icon.svg" alt="copy" />
        </CopyButton>
      </Container>
      {institution?.name && (
        <>
          <Title>Bank name</Title>
          <Container>
            {institution?.name}
            <CopyButton
              onClick={() => {
                navigator.clipboard.writeText(institution?.name);
              }}
            >
              <img src="/icons/copy-icon.svg" alt="copy" />
            </CopyButton>
          </Container>
        </>
      )}
      {institution?.address && (
        <>
          <Title>Bank address</Title>
          <Container>
            {institution?.address}
            <CopyButton
              onClick={() => {
                navigator.clipboard.writeText(institution?.address);
              }}
            >
              <img src="/icons/copy-icon.svg" alt="copy" />
            </CopyButton>
          </Container>
        </>
      )}
      <StyledButton onClick={() => dispatch(hideModal())}>Close</StyledButton>
    </Modal>
  );
};

export default AccountDetailsModal;
