/* eslint-disable no-console */
import superagent, { Request, Response } from 'superagent';

import Logger from '../utils/logger';
import {
  ACCESS_TOKEN_NAME,
  API_AUTH_PORT,
  API_BASE_URL,
  CONTENT_TYPE,
  COOKIE_DOMAIN,
  REFRESH_TOKEN_NAME,
  TARGET_COMPANY_ID_NAME,
  USER_COMPANY_ID_NAME,
} from './constants';
import { getCookieProvider } from './cookies';
import { BASE_LOGIN_PATH } from '@/basePath';

/**
 * Append '/' before path
 */
export function formatUrl(path: string) {
  return `${API_BASE_URL}${path}`;
}
export const authUrl = `${API_AUTH_PORT}/auth/v1`;

/**
 * Formats the headers for the API request. Will take care of
 * setting token and making sure to indicate the correct
 * content-type for the request
 */
function setupRequest(
  method: string,
  path: string,
  payload?: string | Record<string, unknown> | null,
  additionalParams: {
    contentType?: string | null;
    responseType?: string | null;
  } = {
    contentType: undefined,
    responseType: null,
  }
): Request {
  const url = formatUrl(path);
  const request: Request = superagent[method]?.(url);

  const { contentType, responseType } = additionalParams;

  if (contentType === undefined) {
    // not passing = use default
    request.type(CONTENT_TYPE.APPLICATION_JSON);
  }

  if (contentType) {
    // using string = set directly
    request.type(contentType);
  }
  if (contentType === null) {
    // do not set content type because of multipart request
  }
  request.set('Accept', 'application/json');
  request.withCredentials();
  request.retry(0);

  if (responseType) {
    request.responseType(responseType);
  }

  if (process.env.REACT_APP_NODE_ENV === 'development') {
    const cookieProvider = getCookieProvider();
    const token = cookieProvider.get(ACCESS_TOKEN_NAME);
    if (token) {
      request.set('Authorization', `Bearer ${token}`);
    }
  }

  if (payload) {
    request.query(payload);
  }
  return request;
}

export function isExpectedHttpStatusCode(res: Response) {
  return res.status <= 500;
}

// separate to another function because using multipart data cannot use .send()
const attachFileAndSend = async (request: Request, files: any[]): Promise<Response> => {
  return new Promise((resolve, reject) => {
    try {
      files.forEach((file) => {
        request.attach('file', file);
      });
      request.then((res) => {
        resolve(res);
      });
    } catch (err) {
      Logger.error(err);
      reject(err);
    }
  });
};

/**
 * Handles response from backend.
 */
async function handleRequest({
  request,
  payload,
  files = [],
  isResponseBlob = false,
}: {
  request: Request;
  payload?: string | Record<string, unknown> | null;
  files?: any[];
  isResponseBlob?: boolean;
}) {
  try {
    // setup superagent to consider http status <= 500 is valid
    request.ok(isExpectedHttpStatusCode);
    // If there is payload, send with payload. Otherwise, just send.
    // It is because passing null or undefined will also send an empty object {} with body;
    let response: Response;
    if (files?.length) {
      response = await attachFileAndSend(request, files);
    } else {
      response = payload ? await request.send(payload) : await request.send();
    }

    const { statusCode, body, headers } = response;

    if (statusCode === 401) {
      // workaround to logout user, instead of using logout in /auth.js, to avoid circular dependency
      // keep it sync with /api/modules/auth.js logout
      const logout = async () => {
        const cookieProvider = getCookieProvider();
        const cookieConfig = { path: '/', domain: COOKIE_DOMAIN };
        cookieProvider.set(USER_COMPANY_ID_NAME, '', cookieConfig);
        cookieProvider.set(TARGET_COMPANY_ID_NAME, '', cookieConfig);
        cookieProvider.set(ACCESS_TOKEN_NAME, '', cookieConfig);
        cookieProvider.set(REFRESH_TOKEN_NAME, '', cookieConfig);
        const url = formatUrl(`${authUrl}/logout`);
        await fetch(url, {
          credentials: 'include',
          method: 'GET',
        });
      };

      logout();
      window.location.replace(BASE_LOGIN_PATH);
    }

    const data = isResponseBlob ? body : !!body && !!body.data ? body.data : null;
    const error = !!body && !!body.error ? body.error : null;
    return { data, error, statusCode, headers };
  } catch (err) {
    // needs to check window in here as this library will be reused in server side for rendering
    if (typeof window !== 'undefined' && !!window.windowUnloaded) {
      // suppress error if it is due to window unload
      return { errors: {} };
    }

    return { errors: err };
  }
}

/**
 * These helper methods are used throughout the application to format and make
 * asynchronous API calls to the backend.
 */
export async function getRequest(path: string, payload: string | Record<string, unknown>) {
  const request = setupRequest('get', path, payload);
  const response = await handleRequest({ request });
  return response;
}

export async function postRequest(
  path: string,
  payload: string | Record<string, unknown>,
  responseType?: string,
  isResponseBlob?: boolean
) {
  const request = setupRequest('post', path, null, { responseType });
  return await handleRequest({ request, payload, isResponseBlob });
}

export async function postFileRequest(path: string, files: any[]) {
  const request = setupRequest('post', path, null, { contentType: null });
  return await handleRequest({ request, files });
}

export async function putRequest(path: string, payload: string | Record<string, unknown>) {
  const request = setupRequest('put', path, null);
  return await handleRequest({ request, payload });
}

export async function deleteRequest(path: string, payload: string | Record<string, unknown>) {
  const request = setupRequest('delete', path, null);
  return await handleRequest({ request, payload });
}
