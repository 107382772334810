import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import Logger from '../../../../utils/logger';
import APPLICATION_PATH from '../../../Application/path';
import PATH from '../../path';
import CompanyInfoForm from './components/CompanyInfoForm';
import { createBusinessOwnerEntity, updateBusinessOwnerEntity, getBusinessOwnerEntityById } from '@api/modules/dashboard/company';
import { uploadCompanyFiles, getBusinessOwnerEntityFiles } from '@api/modules/dashboard/file';
import { FormRow } from '@components/Grid';
import { COMPANY_FILE_TYPE } from '@constants/file';
import { useModule } from '@hooks/routing';
import {
  generateUpdateCompanyPayload,
  shouldSameShippingAddressBeChecked,
  hasFileAttached,
  convertBusinessOwnerEntityResponse,
} from '@redux/modules/company/helpers';
import { useSelectedCompanyId, useIsUnableToEditUnderAdminView, useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';

const BusinessEntityForm = () => {
  const history = useHistory();
  const module = useModule();
  const { id } = useParams();
  const unableToEditAnotherCompany = useIsUnableToEditUnderAdminView();
  const companyId = useSelectedCompanyId();
  const adminIsViewingAnotherCompany = useCheckIsAdminViewingAnotherCompany();

  const [isLoading, toggleIsLoading] = useState(false);
  const [apiError, setApiError] = useState();
  const [pendingFiles, setPendingFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const noFileAttached = !hasFileAttached(pendingFiles, uploadedFiles);
  const form = useForm();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
    getValues,
    reset,
  } = form;

  const fetchFiles = async () => {
    const { data } = await getBusinessOwnerEntityFiles(id);

    if (Array.isArray(data)) {
      setUploadedFiles(data);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  useEffect(() => {
    const fetchApi = async () => {
      try {
        if (id) {
          const { data, error } = await getBusinessOwnerEntityById(companyId, id);
          if (error) {
            //
          } else if (data) {
            const convertedData = convertBusinessOwnerEntityResponse(data);
            reset({
              ...convertedData,
              isShippingSameAddress: shouldSameShippingAddressBeChecked(convertedData.shippingAddress),
            });
          }
        }
      } catch (err) {
        Logger.error(err);
      }
    };

    fetchApi();
  }, [id]);

  const onSubmit = async (formData) => {
    try {
      if (noFileAttached) {
        return;
      }
      toggleIsLoading(true);
      setApiError('');
      const payload = generateUpdateCompanyPayload(formData);
      const { data, error, statusCode } = id ? await updateBusinessOwnerEntity(id, payload) : await createBusinessOwnerEntity(payload);

      if (statusCode !== (id ? 200 : 201)) {
        throw new Error(`cannot ${id ? 'update' : 'create'} business owner`);
      }

      if (Array.isArray(pendingFiles) && pendingFiles.length > 0) {
        const fileReferenceId = id || data.business_owner_entity_id;
        await Promise.all(pendingFiles.map((file) => uploadCompanyFiles(COMPANY_FILE_TYPE.BUSINESS_OWNER_ENTITY, [file], fileReferenceId)));
        await fetchFiles();
      }

      history.push(module === 'dashboard' ? PATH.SETTINGS_COMPANY_OVERVIEW : APPLICATION_PATH.COMPANY);
    } catch (err) {
      setApiError(err.message);
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const handleCancel = () => {
    history.push(module === 'dashboard' ? PATH.SETTINGS_COMPANY_OVERVIEW : APPLICATION_PATH.COMPANY);
  };

  const onDrop = (acceptedFiles) => {
    setPendingFiles(acceptedFiles);
  };

  return (
    <FormRow>
      <CompanyInfoForm
        form={form}
        uploadedFiles={uploadedFiles}
        onSubmit={onSubmit}
        disabled={unableToEditAnotherCompany || isLoading}
        isLoading={isLoading}
        onDropFile={onDrop}
        apiError={apiError}
        noFileAttached={noFileAttached}
        onCancel={handleCancel}
        inactiveFields={['businessType']}
      />
    </FormRow>
  );
};

export default BusinessEntityForm;
