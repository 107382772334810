import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import BackButton from '../../../../../components/BackButton';
import Button from '../../../../../components/Button';
import FormTitle from '../../../../../components/FormTitle';
import Logger from '../../../../../utils/logger';
import { Card } from '../../../components';
import PATH from '../../../path';
import CompanyCardForm from '../components/CompanyCardForm';
import { generateCardPayload } from '../helpers';
import { updateWalletCard } from '@api/modules/integration/airwallex';
import { fetchWalletCards, fetchWalletCardDetail } from '@redux/modules/wallet/actions';
import { GET_WALLET_CARD_DETAIL_SUCCESS } from '@redux/modules/wallet/constants';
import { useWalletAccountId, useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';

const Form = styled.form`
  width: 50%;
  max-width: 800px;
  min-width: 400px;
`;

const ButtonWrapper = styled.div`
  ${(props) => props.theme.buttonWrapper};

  div {
    ${(props) => props.theme.text.error};
  }
`;

const CompanyCardContainer = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, toggleIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const {
    params: { id },
  } = match;
  const walletAccountId = useWalletAccountId();
  const detail = useSelector((state) => state.wallet.card.detailMap[id]);
  const isInitiating = !detail;
  const isAdminViewingAnotherCompany = useCheckIsAdminViewingAnotherCompany();

  const formProps = useForm({
    authorization_controls: {
      allowed_transaction_count: 'MULTIPLE',
      transaction_limits: {
        limits: [{ amount: undefined, interval: undefined }],
      },
    },
  });

  const {
    control,
    watch,
    handleSubmit,
    getValues,
    clearErrors,
    setError,
    reset,
    formState: { errors },
  } = formProps;

  const onSubmit = async (formData) => {
    toggleIsLoading(true);
    setApiError('');
    try {
      const { statusCode, data } = await updateWalletCard(walletAccountId, id, generateCardPayload(formData));
      if (statusCode === 200) {
        dispatch({
          type: GET_WALLET_CARD_DETAIL_SUCCESS,
          payload: { id, detail: data },
        });
        fetchWalletCards(walletAccountId);
        history.push(PATH.WALLET_CARDS);
      }
    } catch (err) {
      Logger.error(err);
      setApiError('Something went wrong');
    } finally {
      toggleIsLoading(false);
    }
  };

  const handleBackClick = (event) => {
    event.preventDefault();
    history.replace(PATH.WALLET_CARDS);
  };

  useEffect(() => {
    const fetchApi = async () => {
      toggleIsLoading(true);
      await fetchWalletCardDetail(walletAccountId, id);
      toggleIsLoading(false);
    };

    fetchApi();
  }, [id]);

  useEffect(() => {
    reset(detail);
  }, [JSON.stringify(detail)]);

  return (
    <>
      <BackButton path={PATH.WALLET_CARDS} />
      <FormTitle scale={3}>Edit card</FormTitle>
      <Card>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CompanyCardForm formProps={formProps} isInitiating={isInitiating} isLoading={isLoading} />
          {!isInitiating && (
            <ButtonWrapper>
              <Button secondary onClick={handleBackClick} disabled={isLoading}>
                Back
              </Button>
              <Button type="submit" disabled={isLoading || isAdminViewingAnotherCompany}>
                Save change
              </Button>
              {apiError && <div>{apiError}</div>}
            </ButtonWrapper>
          )}
        </Form>
      </Card>
    </>
  );
};

export default CompanyCardContainer;
