export const APPLICATION_CONFIRM_SUBMISSION = 'APPLICATION_CONFIRM_SUBMISSION';
export const APPLICATION_SUCCESS_SUBMISSION = 'APPLICATION_SUCCESS_SUBMISSION';
export const MARKETING_PLATFORM_INTEGRATION = 'MARKETING_PLATFORM_INTEGRATION';
export const MARKETING_PLATFORM_FB_INTEGRATION = 'MARKETING_PLATFORM_FB_INTEGRATION';
export const MESSAGE_MODAL = 'MESSAGE_MODAL';
export const FAQ_MODAL = 'FAQ_MODAL';
export const MANUAL_INTEGRATION_MODAL = 'MANUAL_INTEGRATION_MODAL';
export const TERM_SHEET_MODAL = 'TERM_SHEET_MODAL';
export const OFFER_ACCEPTANCE_MODAL = 'OFFER_ACCEPTANCE_MODAL';
export const MANUAL_INTEGRATION_FORM = 'MANUAL_INTEGRATION_FORM';
export const AIRWALLEX_MODAL = 'AIRWALLEX_MODAL';
export const UPLOAD_FILES_MODAL = 'UPLOAD_FILES_MODAL';
export const WOOCOMMERCE_INTEGRATION_MODAL = 'WOOCOMMERCE_INTEGRATION_MODAL';
export const SHOPLAZZA_INTEGRATION_MODAL = 'SHOPLAZZA_INTEGRATION_MODAL';
export const BANK_STATEMENT_MODAL = 'BANK_STATEMENT_MODAL';
export const CONFIRM_DIALOG = 'CONFIRM_DIALOG';
export const GA_TUTORIAL_MODAL = 'GA_TUTORIAL_MODAL';
export const FB_TUTORIAL_MODAL = 'FB_TUTORIAL_MODAL';
export const SALES_OTHERS_PLATFORM_MODAL = 'SALES_OTHERS_PLATFORM_MODAL';
export const NEW_BANK_ACCOUNT_OVERVIEW_MODAL = 'NEW_BANK_ACCOUNT_OVERVIEW_MODAL';
export const BANK_ACCOUNT_MODAL = 'BANK_ACCOUNT_MODAL';
export const SHOPIFY_INTEGRATION_MODAL = 'SHOPIFY_INTEGRATION_MODAL';
export const MAGENTO_INTEGRATION_MODAL = 'MAGENTO_INTEGRATION_MODAL';
export const REVENUE_ADJUSTMENT_MODAL = 'REVENUE_ADJUSTMENT_MODAL';
export const XERO_SETTLEMENT_MODAL = 'XERO_SETTLEMENT_MODAL';
export const BANK_SETTLEMENT_TRANSACTION_MODAL = 'BANK_SETTLEMENT_TRANSACTION_MODAL';
export const AUTO_SETTLEMENT_TRANSACTION_MODAL = 'AUTO_SETTLEMENT_TRANSACTION_MODAL';
export const INVOICE_STATUS_MODAL = 'INVOICE_STATUS_MODAL';
export const PAYMENT_SETUP_MODAL = 'PAYMENT_SETUP_MODAL';
export const PAYMENT_SETUP_REJECTED_MODAL = 'PAYMENT_SETUP_REJECTED_MODAL';
export const BALANCE_REPORT_MODAL = 'BALANCE_REPORT_MODAL';
export const ADD_FUNDS_MODAL = 'ADD_FUNDS_MODAL';
export const CREATE_GLOBAL_ACCOUNTS_MODAL = 'CREATE_GLOBAL_ACCOUNTS_MODAL';
export const GLOBAL_ACCOUNT_ACCOUNT_DETAILS = 'GLOBAL_ACCOUNT_ACCOUNT_DETAILS';
export const MULTI_FACTOR_SETUP_MODAL = 'MULTI_FACTOR_SETUP_MODAL';
export const DISABLE_MULTI_FACTOR_SETUP_MODAL = 'DISABLE_MULTI_FACTOR_SETUP_MODAL';
export const REFUND_PAYMENT_MODAL = 'REFUND_PAYMENT_MODAL';
export const REFUND_PAYMENT_SUCCESS_MODAL = 'REFUND_PAYMENT_SUCCESS_MODAL';
export const TRANSFER_2FA_MODAL = 'TRANSFER_2FA_MODAL';
export const SENSITIVE_CARD_DETAIL_MODAL = 'SENSITIVE_CARD_DETAIL_MODAL';
export const CREATE_AMAZON_ACCOUNTS_MODAL = 'CREATE_AMAZON_ACCOUNTS_MODAL';
