import React from 'react';

import styled from 'styled-components';

const StyledSvg = styled.svg`
  transform: rotate(${(props) => props.deg}deg);
  fill: ${(props) => props.theme.colors.grayThree};
  transition: all 0.35s cubic-bezier(0, 1, 0, 1);
`;

export const ARROW_DIRECTION = {
  RIGHT: 'right',
  LEFT: 'left',
  UP: 'up',
  DOWN: 'down',
};

const Arrow = ({ direction, width, height, ...restProps }) => {
  let deg = 0;
  switch (direction) {
    case ARROW_DIRECTION.RIGHT:
      deg = -90;
      break;
    case ARROW_DIRECTION.LEFT:
      deg = 90;
      break;
    case ARROW_DIRECTION.UP:
      deg = -180;
      break;
    case ARROW_DIRECTION.DOWN:
      deg = 0;
      break;
    default:
      deg = 0;
      break;
  }

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      deg={deg}
      {...restProps}
    >
      <path d="M14 6.33198381 8 12 2 6.33198381 3.41 5 8 9.3265857 12.59 5z" />
    </StyledSvg>
  );
};

Arrow.defaultProps = {
  width: 16,
  height: 16,
};

export default Arrow;
