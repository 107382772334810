import Logger from '../../../utils/logger';
import store from '../../store';
import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  CLEAN_USER_PROFILE,
  RESET_USER,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
} from './constants';
import { getAllUsers } from '@api/modules/dashboard';
import { getUser } from '@api/modules/user';
import { convertToBoolean } from '@utils/dataTypes';

export const updateUserProfile = (payload) => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload,
});

export const cleanUserProfile = () => ({
  type: CLEAN_USER_PROFILE,
});

export const resetUser = () => ({
  type: RESET_USER,
});

export const fetchUser = async () => {
  store.dispatch({ type: GET_USER_PROFILE_REQUEST });
  const { data, error } = await getUser();
  if (data) {
    store.dispatch({
      type: GET_USER_PROFILE_SUCCESS,
      payload: {
        id: data.user_id,
        firstName: data.first_name,
        lastName: data.last_name,
        mobilePhone: data.mobile_phone,
        optInEmails: convertToBoolean(data.allow_update),
        userRole: data.user_role,
        adminRole: data.admin_role || {
          name: '',
          permissions: [],
        },
        username: data.username,
        status: data.status,
        applicationSubmitted: convertToBoolean(data.submitted),
      },
    });
    return;
  }

  store.dispatch({ type: GET_USER_PROFILE_FAILURE });
};

export const fetchAllUsers = async () => {
  store.dispatch({ type: GET_ALL_USERS_REQUEST });
  try {
    const { data } = await getAllUsers();
    if (Array.isArray(data) && data.length > 0) {
      store.dispatch({ type: GET_ALL_USERS_SUCCESS, payload: data });
    }
  } catch (err) {
    store.dispatch({ type: GET_ALL_USERS_FAILURE });
    Logger.error(err);
  }
};
