import _ from 'lodash';
import moment from 'moment';

import COLORS from '../../../../styles/colors';
import { CARD_STATUS, DEFAULT_CARD_PER_TRANSACTION_LIMIT, CARD_LIMIT_INTERVAL_OPTIONS } from '../constants';
import { findFirstMatchInArray } from '@utils/dataTypes';
import { getOptionByValue } from '@utils/optionHelpers';

export const isIntervalOptionAlreadySelected = (optionValue, fields) => {
  const result = !!findFirstMatchInArray(fields, ({ interval }) => interval === optionValue);
  return result;
};

const intervalComparisonOrder = ['ALL_TIME', 'MONTHLY', 'WEEKLY', 'DAILY'];

export const validateLimit = (currentField, limits) => {
  const sortedLimits = intervalComparisonOrder.map((intervalName) => {
    return findFirstMatchInArray(limits, ({ interval }) => interval === intervalName);
  });

  let errorMessage;
  if (currentField.interval && currentField.interval !== 'PER_TRANSACTION') {
    const indexOfInterval = intervalComparisonOrder.indexOf(currentField.interval);
    const comparingLimits = sortedLimits.slice(indexOfInterval + 1);
    comparingLimits
      .filter((limit) => limit)
      .forEach((limit) => {
        if (Number(currentField.amount) < Number(limit.amount)) {
          errorMessage = `Must be more than or equal to the ${limit.interval.toLowerCase()} limit`;
        }
      });
  }

  return errorMessage;
};

export const getLimitLabelByInterval = (interval) => {
  switch (interval) {
    case 'PER_TRANSACTION':
      return 'Transaction limit';
    case 'DAILY':
      return 'Daily limit';
    case 'WEEKLY':
      return 'Weekly limit';
    case 'MONTHLY':
      return 'Monthly limit';
    case 'ALL_TIME':
      return 'All time limit';
    default:
      return '';
  }
};

// TODO: add test case
export const getCardStatusColor = (status) => {
  switch (status) {
    case CARD_STATUS.ACTIVE:
      return COLORS.green;
    case CARD_STATUS.INACTIVE:
      return COLORS.red;
    case CARD_STATUS.CLOSED:
    case CARD_STATUS.LOST:
    case CARD_STATUS.STOLEN:
    case CARD_STATUS.BLOCKED:
      return COLORS.grayTwo;
    default:
      return COLORS.black;
  }
};

// TODO: add test case
export const getMomentInterval = (interval) => {
  switch (interval) {
    case 'DAILY':
      return 'day';
    case 'WEEKLY':
      return 'week';
    case 'MONTHLY':
      return 'month';
    default:
      return '';
  }
};

export const getColorByCardStatus = (status) => {
  switch (status) {
    case 'APPROVED':
      return COLORS.green;
    case 'PENDING':
      return COLORS.orange;
    case 'FAILED':
      return COLORS.red;
    default:
      return COLORS.black;
  }
};

export const getCardLimitRefreshDescription = (interval) => {
  if (['DAILY', 'WEEKLY', 'MONTHLY'].indexOf(interval) !== -1) {
    const momentInterval = getMomentInterval(interval);
    const endTimeMoment = moment().endOf(momentInterval);
    return `Refreshes ${moment().to(endTimeMoment)}`;
  } else if (interval === 'PER_TRANSACTION') {
    return 'Refreshes every transaction';
  } else if (interval === 'ALL_TIME') {
    return 'Remaining funds available';
  }

  return '';
};

export const generateCardPayload = (formData) => {
  const result = formData;
  const pathOfLimits = 'authorization_controls.transaction_limits.limits';
  const limits = _.get(result, pathOfLimits);
  if (Array.isArray(limits) && limits.length === 0) {
    _.set(result, pathOfLimits, [{ amount: DEFAULT_CARD_PER_TRANSACTION_LIMIT, interval: 'PER_TRANSACTION' }]);
    _.set(result, 'authorization_controls.transaction_limits.currency', 'HKD');
  }

  return result;
};
