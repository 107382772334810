import moment from 'moment';

import { LOGIN_USER, LOGOUT_USER } from '../auth/constants';
import { SELECT_COMPANY_REQUEST } from '../company/constants';
import { SWITCH_TO_ADMIN_VIEW, SWITCH_TO_USER_VIEW } from '../UI/constants';
import {
  GET_REMITTANCE_STREAMS_REQUEST,
  GET_REMITTANCE_STREAMS_SUCCESS,
  GET_REMITTANCE_STREAMS_FAILURE,
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE,
  SET_SELECTED_INVOICE,
  GET_INVOICE_REQUEST,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_FAILURE,
  GET_BALANCE_TRANSACTIONS_REQUEST,
  GET_BALANCE_TRANSACTIONS_SUCCESS,
  GET_BALANCE_TRANSACTIONS_FAILURE,
  GET_FUND_BALANCE_REQUEST,
  GET_FUND_BALANCE_SUCCESS,
  GET_FUND_BALANCE_FAILURE,
} from './constants';

const initialState = {
  streams: [],
  invoices: [],
  balance: {},
  balanceTransactions: [],
  invoiceDetailMap: {},
  selectedInvoiceId: '',
  isLoading: false,
};

const remittanceReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_REMITTANCE_STREAMS_REQUEST:
    case GET_INVOICES_REQUEST:
    case GET_INVOICE_REQUEST:
    case GET_BALANCE_TRANSACTIONS_REQUEST:
    case GET_FUND_BALANCE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_REMITTANCE_STREAMS_SUCCESS:
      return {
        ...state,
        streams: action.payload,
        isLoading: false,
      };
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
      };
    case SET_SELECTED_INVOICE:
      return {
        ...state,
        selectedInvoiceId: action.payload,
      };
    case GET_INVOICE_SUCCESS:
      return {
        ...state,
        invoiceDetailMap: {
          ...state.invoiceDetailMap,
          [action.payload.id]: action.payload.data,
        },
        isLoading: false,
      };
    case GET_INVOICE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_BALANCE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        balanceTransactions: action.payload,
        isLoading: true,
      };
    case GET_FUND_BALANCE_SUCCESS:
      return {
        ...state,
        balance: action.payload,
        isLoading: false,
      };
    case GET_BALANCE_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    // case SWITCH_TO_ADMIN_VIEW:
    // case SELECT_COMPANY_REQUEST:
    case SWITCH_TO_USER_VIEW:
    case LOGIN_USER:
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default remittanceReducer;
