import React from 'react';

import styled, { keyframes } from 'styled-components';

export const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const Wrapper = styled.div`
  text-align: center;

  img {
    animation: ${rotation} 2s infinite linear;
  }
`;

const LoadingCircle = ({ className }) => (
  <Wrapper className={className}>
    <img src="/icons/loadingCircle.svg" alt="loading" />
  </Wrapper>
);

export default LoadingCircle;
