import { API_APPLICATION_PORT } from '@api/constants';
import { getRequest } from '@api/requests';

export const dashboardUrl = `${API_APPLICATION_PORT}/application/v1`;

export const getInputCurrency = async () => {
  const { data, error } = await getRequest(`${dashboardUrl}/input-currency`);
  if (data) {
    const inputCurrencyCodes = data.map(({ currency }) => currency);
    return inputCurrencyCodes;
  } else {
    return [];
  }
};
