import React, { useState, useEffect } from 'react';

import { useTable } from 'react-table';
import styled from 'styled-components';

import Modal from '../../../../components/Modal';
import Pagination from '../../../../components/Pagination';
import Logger from '../../../../utils/logger';
import { Table, Th, SummaryTableRow } from '../../Business/components';
import { autoModalTableColumns } from './constants';
import { getAutoSettlementDetailById } from '@api/modules/dashboard/settlement';
import { PLATFORM_MAP } from '@constants/platform';
import { usePagination } from '@hooks/UI';
import { formatNumber } from '@utils/priceHelpers';
import { capitalize } from '@utils/stringHelpers';

const Header = styled.div`
  ${(props) => props.theme.text.three};
`;

const StyledTable = styled(Table)`
  th {
    padding: 6px 12px;
    font-size: 12px;
  }

  td {
    padding: 4px 12px;
    font-size: 10px;
  }

  tbody {
    background-color: ${(props) => props.theme.colors.grayOne};
  }

  margin-bottom: 24px;
`;

const FieldTitle = styled.span`
  ${(props) => props.theme.text.five};
  margin-right: 8px;
`;

const FieldData = styled.span`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  font-weight: 600;
`;

const AutoSettlementTransactionModal = ({ breakdown }) => {
  const [transactions, setTransactions] = useState([]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data: transactions,
    columns: autoModalTableColumns,
  });
  const {
    pageNumber,
    pageSize,
    pageCount,
    onPageNumberChange,
    onPageSizeChange,
    changePageCountByTotalAndSize,
  } = usePagination();

  const fetchTransactions = async () => {
    if (breakdown) {
      try {
        const transaction_type = ['sales', 'payment'].indexOf(breakdown.breakdown_type) !== -1 ? 'in' : 'out';
        const { data } = await getAutoSettlementDetailById(breakdown.settlement_id, transaction_type, {
          page_no: pageNumber,
          limit: pageSize,
        });
        const trxs = Array.isArray(data?.txns) ? data.txns : [];
        const filtered = trxs.filter(({ txn_type, record_id }) => breakdown.breakdown_type === txn_type && record_id);
        changePageCountByTotalAndSize(data.total, pageSize);
        setTransactions(filtered);
      } catch (err) {
        Logger.error(err);
      }
    }
  };

  useEffect(() => {
    onPageNumberChange(1);
  }, [pageSize]);

  useEffect(() => {
    fetchTransactions();
  }, [pageNumber, pageSize]);

  return (
    <Modal width={900} showCloseButton hideModalOnClickOutside>
      <Header>{breakdown.account_id}</Header>
      <div>
        <FieldTitle>Channel:</FieldTitle>
        <FieldData>{capitalize(breakdown.channel)}</FieldData>
      </div>
      <div>
        <FieldTitle>Platform:</FieldTitle>
        <FieldData>{PLATFORM_MAP[breakdown.source]?.displayName}</FieldData>
      </div>
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return <Th className={column.className}>{column.render('Header')}</Th>;
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          <SummaryTableRow>
            {headerGroups.map((headerGroup) =>
              headerGroup.headers.map(({ id, className }, index) => {
                if (index === 0) {
                  return <td>Summary</td>;
                } else if (id === 'currency') {
                  return <td>{breakdown.currency}</td>;
                } else if (id === 'amount') {
                  return <td className={className}>{formatNumber(breakdown.amount, 2)}</td>;
                } else if (id === 'invoice_currency') {
                  return <td>USD</td>;
                } else if (id === 'amount_in_usd') {
                  return <td className={className}>{formatNumber(breakdown.amount_in_usd, 2)}</td>;
                }

                return <td>-</td>;
              }),
            )}
          </SummaryTableRow>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td className={cell.column.className} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
      <Pagination
        pageNumber={pageNumber}
        pageSize={pageSize}
        pageCount={pageCount}
        onPageNumberChange={onPageNumberChange}
        onPageSizeChange={onPageSizeChange}
      />
    </Modal>
  );
};

export default AutoSettlementTransactionModal;
