import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../components/Button';
import LoadingCircle from '../../../components/LoadingCircle';
import Logger from '../../../utils/logger';
import { Card, GrayCard, CardHeader, Description } from "../components";
import RemittanceSummaryCard from '../components/RemittanceSummaryCard';
import PATH, { getApplicationPathByApplicationStatusCode } from '../path';
import { fundingStatusText } from './text';
import { startInvestmentReview } from '@api/modules/application';
import { GridColumn } from '@components/Grid';
import { STATUS_CODE } from '@constants/application';
import { fetchApplication } from '@redux/modules/application/actions';
import { VIEW } from '@redux/modules/UI/constants';
import { useAcceptedOffer } from '@redux/selectors';
import { media } from '@styles/breakpoints';
import { isTermSheetReturned } from '@utils/applicationHelpers';
import { formatPrice, formatPercent, getPercentFromDecimal, formatRemittanceAmount } from '@utils/priceHelpers';

const StyledGrayCard = styled(GrayCard)`
  padding: 20px 32px;
`;

const StatusRow = styled.div`
  margin-bottom: 12px;
`;

const StatusLabel = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.purple};
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  line-height: 50px;
`;

const ButtonWrapper = styled.div`
  float: right;
  vertical-align: middle;
`;

const StyledButton = styled(Button)`
  float: right;
`;

const SectionHeader = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: bold;
  margin-bottom: 24px;
`;

const ColumnTitle = styled.div`
  ${(props) => props.theme.text.five};
  margin-bottom: 8px;
`;

const ColumnData = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: bold;
  margin-bottom: 8px;
`;

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.error};
  ${(props) => props.theme.text.micro};
`;

const OfferDetailColumn = styled(GridColumn)`
  padding-right: 24px;
  box-sizing: border-box;

  ${media.desktop`
    padding-right: 0;
  `};
`;

const RequestedAmountColumn = styled(GridColumn)`
  border-left: 2px solid ${(props) => props.theme.colors.grayOne};
  padding-left: 24px;
  box-sizing: border-box;

  ${media.desktop`
    border-left:none;
    padding-left: 0;
  `};
`;

const FundingCard = () => {
  const history = useHistory();
  const view = useSelector((state) => state.ui.view);
  const { data: application, isLoading: isLoadingApplication } = useSelector((state) => state.application);
  const selectedCompanyId = useSelector((state) => state.company.selectedCompanyId);
  const [isLoading, toggleIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const acceptedOffer = useAcceptedOffer();

  const startReview = async () => {
    toggleIsLoading(true);
    setApiError('');

    try {
      const { data, error, statusCode } = await startInvestmentReview(selectedCompanyId, application.applicationId);

      if (statusCode === 200) {
        await fetchApplication();
      } else {
        setApiError(error?.message || 'Something went wrong');
      }
    } catch (err) {
      Logger.error(err);
    }

    toggleIsLoading(false);
  };

  const checkTermSheet = () => {
    history.push(PATH.TERM_SHEET);
  };

  const handleAdminStatusButtonClick = () => {
    switch (application.statusCode) {
      case STATUS_CODE.RECEIVED_PRE_OFFER:
      case STATUS_CODE.REQUESTED_FUNDING_AMOUNT:
        startReview();
        break;
      case STATUS_CODE.PENDING_INVESTMENT_REVIEW:
      case STATUS_CODE.PENDING_INVESTMENT_MANAGER_REVIEW:
      default:
        checkTermSheet();
        break;
    }
  };

  const handleUserButtonClick = () => {
    let url = '';
    if (application.statusCode === STATUS_CODE.FUND_DISBURSED) {
      url = PATH.CAPITAL;
    } else {
      url = getApplicationPathByApplicationStatusCode(application.statusCode);
    }
    history.push(url);
  };

  const isReturned = isTermSheetReturned(application.history);

  const textKey = isReturned ? 'returned' : application.statusCode;
  const statusText = fundingStatusText[textKey];

  return statusText ? (
    <StyledGrayCard>
      <CardHeader>Fund Application Status</CardHeader>
      {isLoadingApplication ? (
        <LoadingCircle />
      ) : (
        <>
          {application.statusCode &&
            (() => {
              const { label, description, button } = statusText[view] ?? {};

              return (
                <>
                  <StatusRow>
                    <StatusLabel>{label}</StatusLabel>
                    {view === VIEW.ADMIN && button && (
                      <ButtonWrapper>
                        <StyledButton onClick={handleAdminStatusButtonClick} disabled={isLoading} secondary>
                          {button}
                        </StyledButton>
                        {apiError && <ErrorMessage>{apiError}</ErrorMessage>}
                      </ButtonWrapper>
                    )}
                    {view === VIEW.USER && button && (
                      <ButtonWrapper>
                        <StyledButton onClick={handleUserButtonClick}>{button}</StyledButton>
                      </ButtonWrapper>
                    )}
                  </StatusRow>
                  {description && (
                    <GridColumn lg={10} disableGutter>
                      <Description>{description}</Description>
                    </GridColumn>
                  )}
                </>
              );
            })()}
          {application.statusCode >= STATUS_CODE.PENDING_USER_SIGN_AGREEMENT ? (
            <RemittanceSummaryCard offer={acceptedOffer} />
          ) : (
            <Card>
                <OfferDetailColumn lg={8} md={12} sm={12} disableGutter>
                  <SectionHeader>My offer details</SectionHeader>
                  <GridColumn lg={4} md={6} disableGutter>
                    <ColumnTitle>Funding Amount</ColumnTitle>
                    <ColumnData>
                      {formatPrice(
                        application.offerForm.currency,
                        application.offerForm.preliminaryOffer.fundingAmount,
                      )}
                    </ColumnData>
                  </GridColumn>
                  <GridColumn lg={4} md={6} disableGutter>
                    <ColumnTitle>Total Remittance Amount</ColumnTitle>
                    <ColumnData>
                      {formatRemittanceAmount(
                        application.offerForm.currency,
                        application.offerForm.preliminaryOffer?.remittanceAmount,
                      )}
                    </ColumnData>
                  </GridColumn>
                  <GridColumn lg={4} md={12} disableGutter>
                    <ColumnTitle>Remittance Rate</ColumnTitle>
                    <ColumnData>
                      {formatPercent(getPercentFromDecimal(application.offerForm.preliminaryOffer.remittanceRate))}
                    </ColumnData>
                  </GridColumn>
                </OfferDetailColumn>
                <RequestedAmountColumn lg={4} md={6} sm={12} disableGutter>
                  <SectionHeader>My requested amount</SectionHeader>
                  <ColumnTitle>Requested Funding Amount</ColumnTitle>
                  <ColumnData>
                    {application.offerForm.fund
                      ? formatPrice(application.offerForm.currency, application.offerForm.fund)
                      : 'N/A'}
                  </ColumnData>
                </RequestedAmountColumn>
              </Card>
          )}
        </>
      )}
    </StyledGrayCard>
  ) : null;
};

export default FundingCard;
