import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import BusinessDetailForm from '../../Dashboard/Settings/Company/BusinessDetailForm';
import BusinessOwnerForm from '../../Dashboard/Settings/Company/BusinessOwnerForm';
import KeyStakeholderContainer from '../../Dashboard/Settings/Company/KeyStakeholderContainer';
import FinanceAccountsForm from '../../Dashboard/Settings/FinanceAccountsForm';
import PATH from '../path';
import SalesAccountsForm from './SalesAccountsForm';
import TermSheetForm from './TermSheetForm';

const Routes = ({ allowAllPages }) => {
  return (
    <Switch>
      <Route path={PATH.SALES_ACCOUNTS} component={SalesAccountsForm} />
      <Route path={PATH.PRELIMINARY_OFFER} render={() => <PreliminaryOfferForm allowAllPages={allowAllPages} />} />
      <Route exact path={PATH.COMPANY_BUSINESS} component={BusinessDetailForm} />
      <Route exact path={PATH.COMPANY_KEY_PERSONS} component={BusinessOwnerForm} />
      <Route exact path={`${PATH.COMPANY_KEY_PERSONS}/:id`} component={BusinessOwnerForm} />
      <Route exact path={PATH.COMPANY_KEY_ENTITY} component={BusinessOwnerForm} />
      <Route exact path={`${PATH.COMPANY_KEY_ENTITY}/:id`} component={BusinessOwnerForm} />
      <Route exact path={PATH.COMPANY_KEY_OWNER} component={KeyStakeholderContainer} />
      <Route exact path={PATH.TERM_SHEET} component={TermSheetForm} />
      <Redirect to={PATH.SALES_ACCOUNTS} />
    </Switch>
  );
};

export default Routes;
