import React from 'react';

import styled, { css } from 'styled-components';

import HR from '../../../components/HR';
import SolidTab from '../../../components/SolidTab';
import UnderlineTab from '../../../components/UnderlineTab';
import { VIEW_ALL_OPTION } from '../constants';
import { isMaskOverlayActive } from '../Home/helpers';

const PlatformTab = styled(UnderlineTab)`
  ${(props) =>
    props.selected &&
    props.active &&
    css`
      color: ${props.theme.colors.purple};
      font-weight: bold;

      ${props.underlineWhenSelected &&
      css`
        border-bottom: 2px solid ${props.theme.colors.purple};
      `}
    `};

  ${(props) =>
    props.selected &&
    !props.active &&
    css`
      color: ${props.theme.colors.grayThree};
      opacity: 0.6;
      font-weight: bold;

      ${props.underlineWhenSelected &&
      css`
        border-bottom: 2px solid ${props.theme.colors.grayThree};
      `}
    `};

  ${(props) =>
    !props.selected &&
    props.active &&
    css`
      ${props.underlineWhenSelected &&
      css`
        border-bottom: 0;
      `}
    `};

  ${(props) =>
    !props.selected &&
    !props.active &&
    css`
      color: ${props.theme.colors.grayThree};
      opacity: 0.6;
    `};
`;

const PlatformFilter = ({
  category,
  channelIntegrations,
  subTabOptions,
  onCategoryChange = () => {},
  platform,
  tabOptions,
  selectedChannelTab,
  onPlatformChange = () => {},
}) => {
  const integratedPlatforms = channelIntegrations.map(({ platformName }) => {
    return platformName;
  });

  const filteredSubTabOptions = Array.isArray(subTabOptions)
    ? subTabOptions.filter(({ platformName, value }) => {
        return integratedPlatforms.indexOf(platformName) !== -1 || value === VIEW_ALL_OPTION.value;
      })
    : [];

  return (
    <>
      {tabOptions.map(({ value, label }) => {
        return (
          <SolidTab selected={category === value} onClick={() => onCategoryChange(value)}>
            {label}
          </SolidTab>
        );
      })}
      <HR margin={8} color="grayTwo" height="1" />
      <div>
        {Array.isArray(filteredSubTabOptions) &&
          filteredSubTabOptions.length > 0 &&
          filteredSubTabOptions.map(({ value, label, platformName }) => {
            const isTabActive = isMaskOverlayActive(category, platformName, channelIntegrations);

            return (
              <PlatformTab
                key={filteredSubTabOptions.value}
                selected={selectedChannelTab === value}
                active={isTabActive}
                onClick={() => onPlatformChange(value)}
                underlineWhenSelected
              >
                {label}
              </PlatformTab>
            );
          })}
      </div>
    </>
  );
};

export default PlatformFilter;
