import React, { useState, useRef, useEffect } from 'react';

import { stringify } from 'query-string';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import LoadingCircle from '../../../../components/LoadingCircle';
import Logger from '../../../../utils/logger';
import PlatformFilter from '../../components/PlatformFilter';
import {
  VIEW_ALL_OPTION,
  TAB,
  TAB_OPTIONS,
  ENABLED_SALES_PLATFORM_OPTIONS,
  ENABLED_PAYMENT_PLATFORM_OPTIONS,
  ENABLED_ANALYTICS_PLATFORM_OPTIONS,
} from '../../constants';
import PATH from '../../path';
import { Header } from '../components';
import SalesReportOverview from './SalesReportOverview';
import SalesReportTable from './SalesReportTable';
import { getSalesReportSummary } from '@api/modules/dashboard';
import { PLATFORM } from '@constants/platform';
import { useQuery } from '@hooks/routing';
import { isBBMSLIntegrated } from '@utils/platformHelpers';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.grayOne};
  padding: 24px 18px;
`;

const Container = styled.div`
  padding: 0 12px;
`;

const SalesReport = () => {
  const history = useHistory();
  const query = useQuery();
  const [category, setCategory] = useState(TAB.OVERVIEW);
  const [platform, setPlatform] = useState(VIEW_ALL_OPTION.value);
  const [accountSummaries, setAccountSummaries] = useState([]);
  const [isLoading, toggleIsLoading] = useState(true);
  const [selectedAccountValue, setSelectedAccountValue] = useState(null);

  const integrations = useSelector((state) => state.application.integrations);

  const getPaymentOptions = () => {
    return [VIEW_ALL_OPTION, ...ENABLED_PAYMENT_PLATFORM_OPTIONS].filter((option) =>
      (isBBMSLIntegrated(integrations) ? true : option.value !== PLATFORM.BBMSL),
    );
  };

  const channelAccountSummaries = accountSummaries.filter((accountSummary) => {
    return accountSummary.channel.indexOf(category) !== -1 || category === TAB.OVERVIEW;
  });

  const OPTIONS = {
    [TAB.SALES]: [VIEW_ALL_OPTION, ...ENABLED_SALES_PLATFORM_OPTIONS],
    [TAB.PAYMENT]: getPaymentOptions(),
    [TAB.ANALYTICS]: ENABLED_ANALYTICS_PLATFORM_OPTIONS,
  };

  const subTabOptions = OPTIONS[category];

  const updateUrl = (queryPayload) => {
    const searchParams = stringify(queryPayload);
    history.push(`${PATH.BUSINESS_SALES_REPORT}?${searchParams}`);
  };

  const handleCategoryChange = (value) => {
    updateUrl({ category: value, platform: 'view-all', account: 'SHOW_ALL' });
  };

  const handlePlatformChange = (value) => {
    updateUrl({ category: query.parsed.category, platform: value, account: 'SHOW_ALL' });
  };

  const handleAccountChange = (value) => {
    updateUrl({ ...query.parsed, account: value });
  };

  useEffect(() => {
    const fetchApi = async () => {
      toggleIsLoading(true);
      try {
        const { data } = await getSalesReportSummary();
        if (Array.isArray(data) && data.length > 0) {
          setAccountSummaries(data);
        }
      } catch (err) {
        Logger.error(err);
      } finally {
        toggleIsLoading(false);
      }
    };

    fetchApi();
  }, []);

  useEffect(() => {
    if (query.search === '') {
      updateUrl({ category: TAB.OVERVIEW });
    }
  }, [query.search]);

  useEffect(() => {
    if (!query.parsed.platform) {
      setPlatform(VIEW_ALL_OPTION.value);
    }
  }, [category, query.search]);

  useEffect(() => {
    const setStatesByQuery = () => {
      setCategory(query.parsed.category);
      setPlatform(query.parsed.platform);
      setSelectedAccountValue(query.parsed.account);
    };

    setStatesByQuery();
  }, [query.search]);

  return (
    <>
      <Header>Sales Report</Header>
      <Wrapper>
        <Container>
          <PlatformFilter
            tabOptions={TAB_OPTIONS}
            channelIntegrations={integrations}
            subTabOptions={subTabOptions}
            category={category}
            selectedChannelTab={platform}
            onCategoryChange={handleCategoryChange}
            onPlatformChange={handlePlatformChange}
          />
        </Container>
        {isLoading ? (
          <LoadingCircle />
        ) : category === TAB.OVERVIEW ? (
          <SalesReportOverview accountSummaries={channelAccountSummaries} />
        ) : (
          <Container>
            <SalesReportTable
              category={category}
              platform={platform}
              selectedAccountValue={selectedAccountValue}
              onPlatformChange={handleAccountChange}
            />
          </Container>
        )}
      </Wrapper>
    </>
  );
};

export default SalesReport;
