import React, { forwardRef } from 'react';

import HR from './HR';
import Select from './Select';
import { groupedCountryOptions, allCountryOptions } from '@constants/options';
import { getOptionByValue } from '@utils/optionHelpers';
import { FormError } from './Input';

const CountrySelect = forwardRef(({ value, fieldType, hasError, options = groupedCountryOptions, ...restProps }, ref) => {
  return (
    <>
      <Select
        {...restProps}
        innerRef={ref}
        options={options}
        hasError={hasError}
        value={getOptionByValue(value, allCountryOptions)}
        formatGroupLabel={(data) => data.enableSeparator && <HR margin={1} />}
        placeholder="Select a Country"
      />
      {hasError && hasError.type == 'required' && fieldType == 'text' && <FormError>Required</FormError>}
    </>
  );
});

export default CountrySelect;
