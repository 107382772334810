import React from 'react';

import styled, { css } from 'styled-components';

import COLORS from '../styles/colors';

const RadioButtonLabel = styled.label`
  top: 18%;
  left: 5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.white};
  border: 2px solid ${(props) => props.theme.colors.grayThree};
`;

const RadioButton = styled.input`
  position: absolute;
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover ~ ${RadioButtonLabel} {
    background: ${(props) => props.theme.colors.white};

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 8px;
      height: 6px;
      margin: 6px;
      background: ${(props) => props.theme.colors.white};
    }
  }

  ${(props) =>
    props.checked &&
    css`
      &:checked + ${RadioButtonLabel} {
        background: ${props.theme.colors.white};
        border: 2px solid ${props.theme.colors.grayThree};

        &::after {
          content: '';
          display: block;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          margin: 4px;
          background: ${props.theme.colors.grayThree};
        }
      }
    `}
`;

const Radio = ({ name, value, onClick = () => {}, checked }) => {
  return (
    <>
      <RadioButton type="radio" name={name} value={value} checked={checked} onClick={onClick} />
      <RadioButtonLabel />
    </>
  );
};

export default Radio;
