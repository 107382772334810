import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Button from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';
import Logger from '../../../../../utils/logger';
import PATH from '../../../path';
import { toggleCompany2fa } from '@api/modules/dashboard/company';
import { fetchCompany2fa } from '@redux/modules/company/actions';
import { hideModal } from '@redux/modules/UI/actions';

const Title = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
  margin-bottom: 8px;
`;

const Description = styled.span`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  margin: 12px 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 20px 0;
  ${(props) => props.theme.buttonWrapper}
  justify-content: end;
`;

const DisableMultiFactorModal = () => {
  const company2fa = useSelector((state) => state.company.company2fa);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, toggleIsLoading] = useState(false);

  const handleCancel = () => {
    dispatch(hideModal());
  };

  const handleDisableTwoFactor = async () => {
    toggleIsLoading(true);
    try {
      const body = {
        transaction2faEmail: company2fa?.[0]?.transaction2faEmail,
        transaction2fa: false,
      };
      const { statusCode } = await toggleCompany2fa(body);
      if (statusCode === 200) {
        history.push(PATH.SETTINGS_USER_MANAGEMENT);
        await fetchCompany2fa();
        dispatch(hideModal());
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };
  return (
    <Modal>
      <Title>Are you sure to turn off 2FA?</Title>
      <Description>By turning off, this will remove an extra layer of security when creating a transfer.</Description>
      <ButtonWrapper>
        <Button tertiary onClick={handleCancel} width={150} disabled={isLoading}>
          Cancel
        </Button>
        <Button width={150} warning disabled={isLoading} onClick={handleDisableTwoFactor}>
          Turn Off
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default DisableMultiFactorModal;
