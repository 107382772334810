import React from 'react';

import deepEqual from 'deep-equal';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import HR from '../../components/HR';
import { PLATFORM, PLATFORM_MAP } from '@constants/platform';
import { findFirstMatchInArray } from '@utils/dataTypes';
import { formatGAAccountLabel } from '@utils/platformHelpers';

const Wrapper = styled.div`
  > *:not(:last-child) {
    margin-bottom: 30px;
  }
`;

const ChannelWrapper = styled.div`
  padding: 20px 30px;
  background-color: ${(props) => props.theme.colors.white};
`;

const ChannelHeader = styled.div`
  ${(props) => props.theme.text.four};
  font-weight: 600;
  margin-bottom: 18px;
`;

const ChannelName = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: 600;
`;

const AccountInfo = styled.ul`
  padding-inline-start: 20px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.grayThree};
`;

const ChannelSection = ({ title, channelMap, allBankAccounts = [], integrations = [] }) => {
  return (
    <div>
      <ChannelHeader>{title}</ChannelHeader>
      <HR margin={10} color="grayOne" />
      {Object.keys(channelMap).map((key) => {
        if (deepEqual(channelMap[key], [])) {
          return null;
        } else if (key === 'otherBankAccounts') {
          return (
            <>
              <ChannelName>Other Bank Accounts</ChannelName>
              {channelMap[key].map((accountId) => {
                const account = findFirstMatchInArray(allBankAccounts, ({ id }) => id === accountId);
                return (
                  <AccountInfo>
                    <li>
                      <div>{`Bank Name: ${account?.bankName}`}</div>
                      <div>{`Swift Code: ${account?.swiftBic || 'N/A'}`}</div>
                      <div>{`Account Holder Name: ${account?.accountHolderName || 'N/A'}`}</div>
                      <div>{`Account Number: ${account?.accountNumber || 'N/A'}`}</div>
                    </li>
                  </AccountInfo>
                );
              })}
              <HR margin={10} color="grayOne" />
            </>
          );
        }

        return (
          <>
            <ChannelName>{PLATFORM_MAP[key].displayName}</ChannelName>
            {channelMap[key].map((channelId) => {
              if ([PLATFORM.MAGENTO, PLATFORM.WOOCOMMERCE].indexOf(key) !== -1) {
                return (
                  <AccountInfo>
                    <li>
                      <div>{`Store URL: ${channelId}`}</div>
                    </li>
                  </AccountInfo>
                );
              } else if ([PLATFORM.SHOPIFY].indexOf(key) !== -1) {
                return (
                  <AccountInfo>
                    <li>
                      <div>{`Store name: ${channelId}`}</div>
                    </li>
                  </AccountInfo>
                );
              } else if (PLATFORM.GOOGLE_ANALYTICS === key) {
                const gaAccount = findFirstMatchInArray(integrations, ({ id }) => {
                  return channelId === id;
                });
                return (
                  <AccountInfo>
                    <li>{formatGAAccountLabel(gaAccount?.accountId, gaAccount?.accountName)}</li>
                  </AccountInfo>
                );
              } else if (PLATFORM.XERO === key) {
                const account = findFirstMatchInArray(allBankAccounts, (item) => {
                  return item.channelBankId === channelId;
                });
                return (
                  <AccountInfo>
                    <li>
                      <div>{`Bank Name: ${account?.bankName}`}</div>
                      <div>{`Account Name: ${account?.accountName}`}</div>
                      <div>{`Swift Code: ${account?.swiftBic || 'N/A'}`}</div>
                      <div>{`Account Holder Name: ${account?.accountHolderName || 'N/A'}`}</div>
                      <div>{`Account Number: ${account?.accountNumber || 'N/A'}`}</div>
                    </li>
                  </AccountInfo>
                );
              }

              const integration = findFirstMatchInArray(integrations, (item) => {
                return item.accountId === channelId;
              });
              return (
                <AccountInfo>
                  <li>
                    {integration?.accountName && <div>{`Account Name: ${integration.accountName || 'N/A'}`}</div>}
                    <div>{`Account ID: ${channelId}`}</div>
                  </li>
                </AccountInfo>
              );
            })}
            <HR margin={10} color="grayOne" />
          </>
        );
      })}
    </div>
  );
};

const RevenueChannels = ({ stream }) => {
  const allBankAccounts = useSelector((state) => state.bank.allBankAccounts);
  const integrations = useSelector((state) => state.application.integrations);
  return (
    <Wrapper>
      {!deepEqual(stream.sales_channel, {}) && (
        <ChannelWrapper>
          <ChannelSection title="Sales Channel" channelMap={stream.sales_channel} integrations={integrations} />
        </ChannelWrapper>
      )}
      {!deepEqual(stream.payment_channel, {}) && (
        <ChannelWrapper>
          <ChannelSection title="Payment Channel" channelMap={stream.payment_channel} integrations={integrations} />
        </ChannelWrapper>
      )}
      {!deepEqual(stream.bank_channel, {}) && (
        <ChannelWrapper>
          <ChannelSection title="Banks" channelMap={stream.bank_channel} allBankAccounts={allBankAccounts} />
        </ChannelWrapper>
      )}
      {!deepEqual(stream.analytic_channel, {}) && (
        <ChannelWrapper>
          <ChannelSection title="Analytic Channel" channelMap={stream.analytic_channel} integrations={integrations} />
        </ChannelWrapper>
      )}
    </Wrapper>
  );
};

export default RevenueChannels;
