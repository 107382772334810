import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import LoadingCircle from '../../../../components/LoadingCircle';
import NoOfferPage from './NoOfferPage';
import OffersPage from './OffersPage';
import { STATUS_CODE } from '@constants/application';
import { fetchUserOffers } from '@redux/modules/offer/actions';
import { isRejectedByCreditTeam } from '@utils/applicationHelpers';

const TermSheetForm = () => {
  const { userOffers, isLoading: isLoadingOffers } = useSelector((state) => state.offer);
  const { history, applicationId, statusCode } = useSelector((state) => state.application.data);

  useEffect(() => {
    const fetchApi = async () => {
      if (statusCode >= STATUS_CODE.PENDING_USER_REVIEW_TERM_SHEET || isRejectedByCreditTeam(history)) {
        await fetchUserOffers(applicationId);
      }
    };

    fetchApi();
  }, [applicationId]);

  return isLoadingOffers && userOffers.length === 0 ? (
    <LoadingCircle />
  ) : (
    <>
      {Array.isArray(userOffers) && userOffers.length > 0 ? (
        <OffersPage offers={userOffers} statusCode={statusCode} applicationHistory={history} />
      ) : (
        <NoOfferPage />
      )}
    </>
  );
};

export default TermSheetForm;
