import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { useFieldArray, Controller, useWatch } from 'react-hook-form';
import styled from 'styled-components';

import Bin from '../../../../../assets/icons/Bin';
import AlertCard from '../../../../../components/AlertCard';
import FormTitle from '../../../../../components/FormTitle';
import HR from '../../../../../components/HR';
import Input from '../../../../../components/Input';
import LoadingSkeleton from '../../../../../components/LoadingSkeleton';
import Select from '../../../../../components/Select';
import ControlledInput from '../../../../ReactHookForm/ControlledInput';
import ControlledSelect from '../../../../ReactHookForm/ControlledSelect';
import { Card, FieldWrapper } from '../../../components';
import { CARD_LIMIT_INTERVAL_OPTIONS, DEFAULT_CARD_PER_TRANSACTION_LIMIT } from '../../constants';
import { isIntervalOptionAlreadySelected, validateLimit } from '../helpers';
import { ERROR_MESSAGE_REQUIRED, ERROR_MESSAGE_EMAIL_FORMAT } from '@constants/form';
import { Currency } from '@/constants';
import { formatPrice, formatNumber } from '@utils/priceHelpers';
import { emailRegExp } from '@utils/validators';
import PhoneInput from '@/components/PhoneInput';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useSelectedCompany } from '@/redux/selectors';

const Footer = styled.div`
  ${(props) => props.theme.input.footer};
`;

const FormErrorMessage = styled.div`
  ${(props) => props.theme.text.error};
  ${(props) => props.theme.text.micro};
`;

const LinkText = styled.div`
  ${(props) => props.theme.text.link}
`;

const TransactionLimitRow = styled.div`
  margin: 12px 0;
  .numberFormatter {
    width: calc((100% - 45px) / 2 - 8px);
    display: inline-block;
  }

  .select {
    width: calc((100% - 45px) / 2 - 8px);
    margin-left: 16px;
    display: inline-block;
  }

  .bin {
    display: inline;
    width: 45px;
    text-align: center;
    cursor: pointer;
  }
`;

const CompanyCardForm = ({ isLoading, isInitiating, formProps = {} }) => {
  const {
    watch,
    control,
    setError,
    getValues,
    register,
    clearErrors,
    formState: { errors },
  } = formProps;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: 'authorization_controls.transaction_limits.limits',
  });

  const watchOfTransactionLimits = useWatch({ control, name: 'authorization_controls.transaction_limits.limits' });
  const watchOfCurrency = watch('authorization_controls.transaction_limits.currency');
  const [intervalOptions, setIntervalOptions] = useState(CARD_LIMIT_INTERVAL_OPTIONS);

  const addLimit = (event) => {
    event?.preventDefault();
    append({
      amount: '',
      interval: '',
    });
  };

  useEffect(() => {
    const refreshIntervalOptions = () => {
      const options = CARD_LIMIT_INTERVAL_OPTIONS.map(({ value, label }) => {
        return { value, label, isDisabled: isIntervalOptionAlreadySelected(value, watchOfTransactionLimits) };
      });
      setIntervalOptions(options);
    };

    refreshIntervalOptions();
  }, [JSON.stringify(watchOfTransactionLimits)]);

  const handleRemoveLimit = (index) => {
    if (!isInitiating && !isLoading) {
      remove(index);
    }
  };
  const company = useSelectedCompany();

  const [country, setCountry] = useState(company.countryOrigin);
  return (
    <div>
      <FormTitle scale={4}>Card details</FormTitle>
      <FieldWrapper>
        <FormTitle scale={5} required>
          Card Nickname
        </FormTitle>
        {isInitiating ? (
          <LoadingSkeleton height="32px" />
        ) : (
          <ControlledInput
            control={control}
            name="nick_name"
            rules={{ required: { value: true, message: ERROR_MESSAGE_REQUIRED } }}
            placeholder="Create a nickname for your card"
            footer="A card nickname helps identify your cards. The name on the card will be your company name"
            isLoading={isLoading}
            disabled={isLoading}
            full
          />
        )}
      </FieldWrapper>
      <FieldWrapper>
        <FormTitle scale={5} required>
          Card limit
        </FormTitle>
        {watchOfCurrency && <Footer>{`Card limits are set in ${watchOfCurrency}, but you can still spend in any currency.`}</Footer>}
        {/* <LinkText>Change currency</LinkText> */}
        {isInitiating ? (
          <>
            <LoadingSkeleton height="32px" />
            <LoadingSkeleton height="32px" />
          </>
        ) : (
          <>
            {Array.isArray(fields) &&
              fields.map((field, index) => {
                const namePrefix = `authorization_controls.transaction_limits.limits.${index}`;
                const amountName = `${namePrefix}.amount`;
                const intervalName = `${namePrefix}.interval`;
                const currentField = getValues(namePrefix);

                return (
                  <TransactionLimitRow key={field.id}>
                    <div>
                      <ControlledInput
                        key={amountName}
                        control={control}
                        name={amountName}
                        className="numberFormatter"
                        rules={{
                          required: true,
                          validate: () => validateLimit(currentField, watchOfTransactionLimits),
                        }}
                        type="number"
                        thousandSeparator=","
                        placeholder={formatNumber(10000)}
                        isLoading={isLoading}
                        disabled={isLoading}
                        showError={false}
                      />
                      <ControlledSelect
                        key={intervalName}
                        control={control}
                        name={intervalName}
                        className="select"
                        options={intervalOptions}
                        rules={{
                          required: true,
                        }}
                        isLoading={isLoading}
                        disabled={isLoading}
                        showError={false}
                      />
                      <Bin className="bin" onClick={() => handleRemoveLimit(index)} />
                    </div>
                    <FormErrorMessage>{_.get(errors, amountName)?.message}</FormErrorMessage>
                  </TransactionLimitRow>
                );
              })}
            {fields.length === 0 && (
              <AlertCard
                title="You have removed the custom limits on this card"
                description={`The transaction limit will be set to ${formatPrice(Currency.HKD, 500000)} by default.`}
              />
            )}
            <LinkText onClick={addLimit}>+ add a limit</LinkText>
            <div>
              {`A ${formatPrice(
                Currency.HKD,
                DEFAULT_CARD_PER_TRANSACTION_LIMIT
              )} transaction limit applies by default. To set a lower limit, click “Add a limit”. To increase
              the limit, contact your investment manager.`}
            </div>
          </>
        )}
      </FieldWrapper>
      <HR />
      <FormTitle scale={4}>Primary contact details</FormTitle>
      <FieldWrapper>
        <FormTitle scale={5}>Email</FormTitle>
        {isInitiating ? (
          <LoadingSkeleton height="32px" />
        ) : (
          <ControlledInput
            control={control}
            name="primary_contact_details.email"
            rules={{ pattern: { value: emailRegExp, message: ERROR_MESSAGE_EMAIL_FORMAT } }}
            placeholder="The email address of the primary contact of the new card"
            isLoading={isLoading}
            disabled={isLoading}
            full
          />
        )}
      </FieldWrapper>
      <FieldWrapper>
        <FormTitle scale={5}>Full name</FormTitle>
        {isInitiating ? (
          <LoadingSkeleton height="32px" />
        ) : (
          <ControlledInput
            control={control}
            name="primary_contact_details.full_name"
            placeholder="Full name of the primary contact of the new card"
            isLoading={isLoading}
            disabled={isLoading}
            full
          />
        )}
      </FieldWrapper>
      <FieldWrapper>
        <FormTitle scale={5}>Mobile number</FormTitle>
        {isInitiating ? (
          <LoadingSkeleton height="32px" />
        ) : (
          <Controller
            name="primary_contact_details.mobile_number"
            control={control}
            rules={{
              required: true,
              validate: (value) => {
                const phoneNumber = parsePhoneNumber(value, country);
                return phoneNumber?.isValid();
              },
            }}
            render={({ field }) => (
              <PhoneInput
                {...field}
                hasError={errors.primary_contact_details?.mobile_number}
                country={country}
                onCountryChange={setCountry}
                disabled={isLoading}
              />
            )}
          />
        )}
      </FieldWrapper>
    </div>
  );
};

export default CompanyCardForm;
