import store from '../../store';
import {
  GET_BANKS_REQUEST,
  GET_BANKS_SUCCESS,
  GET_BANKS_FAILURE,
} from './constants';
import { convertBankAccountResponse } from './helpers';
import { getAllBankAccounts } from '@api/modules/application';

export const fetchBankAccounts = async () => {
  store.dispatch({ type: GET_BANKS_REQUEST });

  const { data } = await getAllBankAccounts();
  if (data) {
    const allBankAccounts = data.map(convertBankAccountResponse);
    const activeBankAccounts = data
      .filter(({ status }) => status >= 0)
      .map(convertBankAccountResponse);
    store.dispatch({
      type: GET_BANKS_SUCCESS,
      payload: {
        activeBankAccounts,
        allBankAccounts,
      },
    });
  } else {
    store.dispatch({ type: GET_BANKS_FAILURE });
  }
};
