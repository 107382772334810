import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import FancyDateRangePicker from '../../../../../components/FancyDateRangePicker';
import LoadingCircle from '../../../../../components/LoadingCircle';
import SimplePagination from '../../../../../components/Pagination/SimplePagination';
import Table from '../../../../../components/Table';
import Logger from '../../../../../utils/logger';
import { Wrapper } from '../../../components';
import { transactionTypeOptions, OPTION_VALUE_ALL, currencyOptions } from '../../constants';
import { getColorByWalletStatus } from '../../helpers';
import { TR, Th, SelectWrapper, FilterLabel, StyledSelect } from '../components';
import { usePagination } from '@hooks/UI';
import { fetchWalletTransactions } from '@redux/modules/wallet/actions';
import { convertToApiDate, convertToFullDate } from '@utils/dateHelpers';
import { formatNumber } from '@utils/priceHelpers';
import { capitalize } from '@utils/stringHelpers';
import { areRecordsFewerThanPageSize } from '@utils/utilHelpers';

const FilterWrapper = styled.span`
  display: inline-block;
  margin-left: 16px;
`;

const DateFilterWrapper = styled(FilterWrapper)`
  text-align: left;
`;

const WalletTransactions = () => {
  const walletAccounts = useSelector((state) => state.wallet.walletAccounts);
  const transactions = useSelector((state) => state.wallet.transactionPageMap);
  const [isLoading, toggleIsLoading] = useState(true);
  const isTransactionsLoading = useSelector((state) => state.wallet.isLoading);
  const [currencyFilter, setCurrencyFilter] = useState(OPTION_VALUE_ALL);
  const [transactionsFilter, setTransactionsFilter] = useState(OPTION_VALUE_ALL);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateShortcutValue, setDateShortValue] = useState();

  const transactionsTableColumn = [
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ value }) => {
        return convertToFullDate(value);
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => {
        return value;
      },
      getStyle: ({ value }) => {
        return css`
          color: ${getColorByWalletStatus(value)};
        `;
      },
    },
    {
      Header: 'Type',
      accessor: 'sourceType',
      Cell: ({ value }) => {
        return capitalize(value);
      },
    },
    {
      Header: 'Details',
      accessor: 'description',
      Cell: ({ value }) => {
        return value;
      },
      getStyle: () => {
        return css`
          width: 400px;
        `;
      },
    },
    {
      Header: 'Currency',
      accessor: 'currency',
      Cell: ({ value }) => {
        return value;
      },
      getStyle: () => {
        return css`
          width: 150px;
        `;
      },
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value }) => {
        return formatNumber(value, 2);
      },
      getStyle: () => {
        return css`
          text-align: right;
          width: 100px;
        `;
      },
    },
  ];

  const { pageNumber, pageSize, pageCount, onPageNumberChange, onPageSizeChange, changePageCount } = usePagination({
    pageSize: 20,
  });

  const formatPageNavLabel = (number) => {
    return ` ${number} `;
  };

  const noNextPage = areRecordsFewerThanPageSize(transactions[pageNumber], pageSize);

  const applyCalendar = (calendarData) => {
    setStartDate(calendarData.startDate);
    setEndDate(calendarData.endDate);
    setDateShortValue(calendarData.dateShortcutSelectValue);
  };

  const searchTransactions = async () => {
    toggleIsLoading(true);
    try {
      const filters = [];
      if (currencyFilter !== OPTION_VALUE_ALL) {
        filters.push({ field: 'currency', operator: 'in', value: currencyFilter.map(({ value }) => value) });
      }

      if (transactionsFilter !== OPTION_VALUE_ALL) {
        filters.push({
          field: 'transactionType',
          operator: 'in',
          value: _.flatten(
            transactionsFilter.map(({ value }) => {
              switch (value) {
                case 'CONVERSION':
                  return ['CONVERSION_SELL', 'CONVERSION_BUY', 'CONVERSION_REVERSAL'];
                case 'DISPUTE':
                  return ['DISPUTE', 'DISPUTE_REVERSAL', 'DISPUTE_LOST'];
                case 'CARD':
                  return ['ISSUING_CAPTURE', 'ISSUING_AUTHORISATION_HOLD', 'ISSUING_AUTHORISATION_RELEASE', 'ISSUING_REFUND'];
                case 'DIRECT_DEBIT':
                  return ['DD_DEBIT'];
                case 'REFUND':
                  return ['REFUND', 'REFUND_REVERSAL', 'REFUND_FAILURE'];
                case 'PAYOUT':
                  return ['PAYOUT_FAILURE', 'PAYOUT_REVERSAL', 'PAYOUT'];
                case 'PAYMENT':
                  return ['PAYMENT_ATTEMPT', 'PAYMENT_RESERVE_HOLD', 'PAYMENT_RESERVE_RELEASE'];
                case 'CHARGE':
                  return ['DD_CREDIT'];
                case 'TRANSFER':
                  return ['DC_DEBIT'];
                default:
                  return value;
              }
            })
          ),
        });
      }
      fetchWalletTransactions(walletAccounts[0]?.account_id, {
        pageNumber,
        pageSize,
        ...(startDate && { startDate: convertToApiDate(startDate) }),
        ...(endDate && { endDate: convertToApiDate(endDate) }),
        filters,
      });
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  useEffect(() => {
    searchTransactions();
  }, [pageNumber]);

  useEffect(() => {
    searchTransactions();
  }, [currencyFilter, transactionsFilter, startDate, endDate, pageSize]);

  return isLoading ? (
    <LoadingCircle />
  ) : (
    <Wrapper>
      <SelectWrapper>
        <FilterWrapper>
          <FilterLabel>Type</FilterLabel>
          <StyledSelect
            isMulti
            width={230}
            value={transactionsFilter}
            options={transactionTypeOptions}
            onChange={setTransactionsFilter}
            disabled={isLoading}
            multiValueLabel="Types"
          />
        </FilterWrapper>
        <FilterWrapper>
          <FilterLabel>Currency</FilterLabel>
          <StyledSelect
            isMulti
            width={200}
            value={currencyFilter}
            options={currencyOptions}
            onChange={setCurrencyFilter}
            disabled={isLoading}
            multiValueLabel="Currencies"
          />
        </FilterWrapper>
        <DateFilterWrapper>
          <FancyDateRangePicker
            startDate={startDate}
            endDate={endDate}
            dateShortcutValue={dateShortcutValue}
            onDateRangeSelectValueChange={setDateShortValue}
            onApply={applyCalendar}
          />
        </DateFilterWrapper>
      </SelectWrapper>
      <Table columns={transactionsTableColumn} data={transactions[pageNumber]} isLoading={isTransactionsLoading} />
      <SimplePagination
        pageNumber={pageNumber}
        pageSize={pageSize}
        pageSizeOptions={[
          {
            value: 20,
            label: 20,
          },
          {
            value: 50,
            label: 50,
          },
          {
            value: 100,
            label: 100,
          },
        ]}
        onPageNumberChange={onPageNumberChange}
        onPageSizeChange={onPageSizeChange}
        formatPageNavLabel={formatPageNavLabel}
        disabled={isLoading}
        isLoading={isLoading}
        noNextPage={noNextPage}
      />
    </Wrapper>
  );
};

export default WalletTransactions;
