import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import CheckBox, { CheckboxText } from '../../../../components/Checkbox';
import { Description } from '../../../Application/components';
import { MANUAL_INTEGRATION_FORM } from '../../../Modal/constants';
import { GridColumn } from '@components/Grid';
import { DD_EMAIL } from '@constants/email';
import { PLATFORM, PLATFORM_DISPLAY_NAME } from '@constants/platform';
import { showModal, hideModal } from '@redux/modules/UI/actions';

const Title = styled.div`
 ${(props) => props.theme.text.two};
 margin: 10px 0 20px 0;
`;

const Link = styled.a`
 ${(props) => props.theme.text.link};
 text-decoration: underline;
 font-weight: bold;
`;

const InstructionTitle = styled.div`
 ${(props) => props.theme.text.three};
 font-weight: bold;
 margin: 20px 0;
`;

const InstructionsImage = styled.img`
 width: 90%;
 height: auto;
 margin-bottom: 20px;
`;

const AppleStore = () => {
  const dispatch = useDispatch();
  const [isChecked, toggleIsChecked] = useState(false);

  const handleIntegrationClick = () => {
    dispatch(showModal(MANUAL_INTEGRATION_FORM, {
      platformName: PLATFORM.APPLE_STORE,
    }));
  };

  return (
    <>
      <Title>
        Connect
        {' '}
        {PLATFORM_DISPLAY_NAME.APPLE_STORE}
        {' '}
        Account
      </Title>
      <Description>Please follow below steps to add access to your account</Description>
      <InstructionTitle>Step 1</InstructionTitle>
      <Description>
        Login to
        {' '}
        <Link href="https://appstoreconnect.apple.com/" target="_blank">App Store Connect</Link>
      </Description>
      <Description>
        Open
        {' '}
        <b>Users </b>
        {'>'}
        <b> Access</b>
      </Description>
      <Description>
        Click the + button in the top-left corner of the page.
      </Description>
      <InstructionTitle>Step 2</InstructionTitle>
      <Description>
        Enter the details below.
        <ul>
          <li>First name: Choco</li>
          <li>Last name: Up</li>
          <li>
            Email:
            {' '}
            {DD_EMAIL}
          </li>
        </ul>
      </Description>
      <Description>
        Please grant us with the permissions below:
        <ul>
          <li>Sales</li>
          <li>Marketing</li>
          <li>Access to Reports</li>
        </ul>
        {' '}
        <b>Click Invite</b>
      </Description>
      <InstructionsImage src="https://asset.choco-up.com/tutorial/app-store/app-store-1.png" alt="Connect App Store Account" />
      <GridColumn lg={12} md={12}>
        <CheckBox onClick={() => toggleIsChecked(!isChecked)} />
        <CheckboxText>
          Confirm I have connected with my
          {' '}
          {PLATFORM_DISPLAY_NAME.APPLE_STORE}
          {' '}
          account
        </CheckboxText>
      </GridColumn>
      <GridColumn lg={6} md={6}>
        <Button onClick={handleIntegrationClick} disabled={!isChecked} primary full>Next</Button>
      </GridColumn>
      <GridColumn lg={6} md={6}>
        <Button onClick={() => dispatch(hideModal())} tertiary full>Cancel</Button>
      </GridColumn>
    </>
  );
};

export default AppleStore;
