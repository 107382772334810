import React from 'react';

import _ from 'lodash';
import { useController } from 'react-hook-form';

import Input from '../../components/Input';
import ErrorMessage from './ErrorMessage';

const ControlledInput = ({ name, control, rules = {}, defaultValue, showError = true, ...restProps }) => {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields, errors },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const error = _.get(errors, name);
  return (
    <>
      <Input {...field} hasError={error} {...restProps} />
      {showError && error?.message && <ErrorMessage>{error?.message}</ErrorMessage>}
    </>
  );
};

export default ControlledInput;
