export const isArray = (value) => Array.isArray(value);
export const isRealObject = (value) => typeof value === 'object' && !isArray(value) && value !== null;
export const isRealNumber = (value) => typeof value === 'number' && !Number.isNaN(value);

export const convertToBooleanNumeral = (value) => {
  if (value === true) {
    return 1;
  }

  return 0;
};

export const convertToBoolean = (value) => {
  if (value === 0 || value === 1) {
    return Boolean(value);
  }

  return false;
};

export const findFirstMatchInArray = (array, callback) => {
  try {
    if (!Array.isArray(array)) {
      throw new Error('expect first param is an array');
    }
    if (typeof callback !== 'function') {
      throw new Error('expect second param is a callback');
    }

    const filtered = array.filter(callback);

    return filtered[0];
  } catch (err) {
    return err;
  }
};

export const convertArrayToObject = (array, uniqueKey) => {
  const result = {};

  if (Array.isArray(array)) {
    array.forEach((item) => {
      result[item[uniqueKey]] = item;
    });
  }

  return result;
};
