import React, { useState, useEffect, useMemo } from 'react';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../../../components/Button';
import CircularProgressBar from '../../../../../components/CircularProgressBar';
import FormTitle from '../../../../../components/FormTitle';
import HR from '../../../../../components/HR';
import ToolTip from '../../../../../components/ToolTip';
import COLORS from '../../../../../styles/colors';
import Logger from '../../../../../utils/logger';
import { FieldWrapper, Wrapper } from '../../../components';
import PATH from '../../../path';
import { getDefaultValueFromSchema, getFeeByMethod } from '../../helpers';
import { Footer, ButtonWrapper } from './components';
import { convertToFullDate, convertToFullDateAndTime } from '@utils/dateHelpers';
import { countryMap, CURRENCY_COUNTRY_FLAG_MAP } from '@/constants';
import { PURPOSE_OPTIONS, FORM_NAME, TARGET, ENTITY_FIELD_ORDER } from './constants';
import { getOptionLabelByValue } from '@utils/optionHelpers';
import { createWalletTransfer, createWalletBeneficiary, triggerTransaction2fa } from '@api/modules/integration/airwallex';
import { useWalletAccount } from '@redux/selectors';
import { convertArrayToObject } from '@utils/dataTypes';
import { fetchWalletBeneficiaries, fetchFormSchema } from '@redux/modules/wallet/actions';
import { getTitleFromSchema, formatFXRatio } from './helpers';
import { showModal } from '@redux/modules/UI/actions';
import { TRANSFER_2FA_MODAL } from '@modules/Modal/constants';
import { formatPrice } from '@utils/priceHelpers';

const TransferDetail = styled.div`
  margin-bottom: 16px;
`;

const RecipientInfoTable = styled.table`
  width: 100%;
  th,
  td {
    width: 50%;
  }

  td {
    text-align: right;
  }
`;

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.error};
`;

const CurrencyWithFlag = styled.div`
  span,
  img {
    vertical-align: middle;
  }
  span ~ img {
    margin-left: 5px;
  }
`;

const ConvertedFee = styled.div`
  > span,
  > img {
    vertical-align: middle;
  }

  span ~ img {
    margin-left: 4px;
  }
`;

const ReviewForm = ({
  targetType,
  beneficiaryId,
  selectedBeneficiary,
  getValues,
  setFormName,
  handleSubmit,
  unityCreateFlag = false, // TODO: verify whether can create bene altogether with transfer
  createdRecipientId,
  setCreatedRecipientId,
  amountOrigin,
  fxCountdownTime,
}) => {
  const transferFee = useSelector((state) => state.wallet.transferFee) || {};
  const isTransfer2faSetup = useSelector((state) => state.company.company2fa[0].transaction2fa);

  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, toggleIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const walletAccount = useWalletAccount();
  const recipientSchema = useSelector((state) => state.wallet.schema[FORM_NAME.RECIPIENT]?.fields || []);
  const foreignExchange = useSelector((state) => state.wallet.foreignExchange) || {};

  const formValue = getValues();

  const {
    sourceCurrency,
    sourceAmount,
    paymentCurrency,
    paymentAmount,
    request_id,
    reference,
    reason,
    transferDate,
    paymentMethod,
    beneficiary,
    nickname,
    swift_charge_option,
  } = formValue;

  const feeFX = foreignExchange[`${sourceCurrency}toUSD`];
  const sourceToPaymentFX = foreignExchange[`${sourceCurrency}to${paymentCurrency}`];

  const currencyBalances = useSelector((state) => state.wallet.currencyBalances);
  const currencyBalanceMap = convertArrayToObject(currencyBalances, 'currency');
  const [isVerified, toggleIsVerified] = useState(false);
  const [trxId, setTrxId] = useState('');

  const handleBack = () => {
    const nextForm = targetType === TARGET.EXISTING_USER ? FORM_NAME.TRANSFER : FORM_NAME.RECIPIENT;
    setFormName(nextForm);
  };

  const checkIsVerified = () => {
    toggleIsVerified(true);
  };

  const recipient = targetType === TARGET.EXISTING_USER ? selectedBeneficiary : { beneficiary, nickname };
  const bankFields = useMemo(
    () => recipientSchema.filter(({ path }) => path.indexOf('bank_details') !== -1),
    [JSON.stringify(recipientSchema)]
  );
  const checkSwiftCode = useMemo(
    () => bankFields.some((e) => e.path === ENTITY_FIELD_ORDER.SWIFT_CODE && e.required),
    [JSON.stringify(bankFields)]
  );

  const getBenePayload = () => {
    const methodCategory = paymentMethod === 'SWIFT' ? 'SWIFT' : 'LOCAL';
    return {
      beneficiary: {
        ...beneficiary,
        bank_details: {
          ...beneficiary.bank_details,
          account_currency: paymentCurrency,
          account_routing_type1:
            methodCategory !== 'SWIFT' ? getDefaultValueFromSchema('account_routing_type1', recipientSchema) : undefined,
          local_clearing_system: methodCategory !== 'SWIFT' ? paymentMethod : undefined,
          swift_code:
            methodCategory === 'SWIFT' || (checkSwiftCode && beneficiary?.bank_details?.swift_code)
              ? beneficiary.bank_details.swift_code
              : undefined,
        },
      },
      nickname,
      payment_methods: [methodCategory],
    };
  };

  const handleVerify2fa = async (event) => {
    event.preventDefault();
    toggleIsLoading(true);
    const benePayload = getBenePayload();
    try {
      const { data, error, statusCode } = await triggerTransaction2fa(walletAccount?.account_id, {
        transactionPayload: {
          payment_currency: formValue.paymentCurrency,
          payment_method: formValue.paymentMethod === 'SWIFT' ? 'SWIFT' : 'LOCAL',
          reason: formValue.reason,
          source_currency: formValue.sourceCurrency,
          reference: formValue.reference,
          // ...(amountOrigin === 'sourceAmount'
          //   ? {
          //       source_amount: formData.sourceAmount,
          //     }
          //   : {
          //       payment_amount: formData.paymentAmount,
          //     }),
          payment_amount: formValue.paymentAmount,
        },
        ...(targetType === TARGET.EXISTING_USER
          ? {
              beneficiaryPayload: null,
              beneficiary_id: recipient?.beneficiary_id,
            }
          : {
              beneficiaryPayload: benePayload,
              beneficiary_id: null,
            }),
      });
      setTrxId(data?.result?.transactionRecordId);

      dispatch(
        showModal(TRANSFER_2FA_MODAL, {
          trxId: data?.result?.transactionRecordId,
          checkIsVerified,
        })
      );
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const handleTransfer = async (event) => {
    event.preventDefault();
    toggleIsLoading(true);
    try {
      const { data, error, statusCode } = await createWalletTransfer(walletAccount?.account_id, {
        transactionRecordId: trxId,
        quote_id: sourceToPaymentFX?.quote_id,
      });
      if (statusCode === 200) {
        history.push(PATH.WALLET_TRANSFER_PAYOUTS);
      } else {
        setApiError('Something went wrong');
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  useEffect(() => {
    if (targetType === TARGET.EXISTING_USER) {
      fetchFormSchema(FORM_NAME.RECIPIENT, {
        ...(beneficiary.address?.country_code
          ? {
              bank_country_code: beneficiary.address.country_code,
            }
          : {
              bank_country_code: beneficiary.bank_details.bank_country_code,
            }),
        account_currency: paymentCurrency,
        entity_type: beneficiary.entity_type,
        payment_method: paymentMethod === 'SWIFT' ? 'SWIFT' : 'LOCAL',
        ...(paymentMethod !== 'SWIFT' && {
          local_clearing_system: paymentMethod,
        }),
      });
    }
  }, [targetType]);

  const onSubmit = async (formData) => {
    toggleIsLoading(true);
    setApiError('');
    try {
      const benePayload = getBenePayload();
      let apiCode;
      let transactionRecordId;
      if (unityCreateFlag) {
        const { data, error, statusCode } = await createWalletTransfer(walletAccount?.account_id, {
          ...(targetType === TARGET.EXISTING_USER
            ? {
                beneficiary_id: beneficiaryId,
              }
            : benePayload),
          payment_currency: formData.paymentCurrency,
          payment_amount: formData.paymentAmount,
          payment_method: formData.paymentMethod,
          reason: formData.reason,
          source_currency: formData.sourceCurrency,
          // source_amount: formData.sourceAmount,
          reference: formData.reference,
          // ...(formData.paymentMethod === 'SWIFT' && {
          //   swift_charge_option: formData.swift_charge_option,
          // }),
        });
        apiCode = statusCode;
      } else {
        const { data, error, statusCode } = await triggerTransaction2fa(walletAccount?.account_id, {
          transactionPayload: {
            payment_currency: formData.paymentCurrency,
            payment_method: formData.paymentMethod === 'SWIFT' ? 'SWIFT' : 'LOCAL',
            reason: formData.reason,
            source_currency: formData.sourceCurrency,
            reference: formData.reference,
            // ...(amountOrigin === 'sourceAmount'
            //   ? {
            //       source_amount: formData.sourceAmount,
            //     }
            //   : {
            //       payment_amount: formData.paymentAmount,
            //     }),
            payment_amount: formData.paymentAmount,
          },
          ...(targetType === TARGET.EXISTING_USER
            ? {
                beneficiaryPayload: null,
                beneficiary_id: recipient?.beneficiary_id,
              }
            : {
                beneficiaryPayload: benePayload,
                beneficiary_id: null,
              }),
        });

        if (statusCode === 200) {
          apiCode = statusCode;
          transactionRecordId = data?.result?.transactionRecordId;
        } else {
          setApiError('Please try again');
        }
      }

      if (apiCode === 200) {
        const { data, error, statusCode } = await createWalletTransfer(walletAccount?.account_id, {
          transactionRecordId,
          quote_id: sourceToPaymentFX?.quote_id,
        });
        if (statusCode === 200) {
          history.push(PATH.WALLET_TRANSFER_PAYOUTS);
        } else {
          setApiError('Something went wrong');
        }
      } else {
        throw new Error(`Error ${apiCode}: cannot create transfer`);
      }
    } catch (err) {
      Logger.error(err);
      setApiError(err.message);
    } finally {
      toggleIsLoading(false);
    }
  };

  const fee = getFeeByMethod(transferFee[beneficiary.bank_details.bank_country_code][paymentCurrency], paymentMethod, swift_charge_option);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TransferDetail>
        <FormTitle scale={4}>Review</FormTitle>
        <FieldWrapper>
          <FormTitle scale={5}>You send</FormTitle>
          <CurrencyWithFlag>
            <span>{formatPrice(sourceCurrency, sourceAmount)}</span>
            {CURRENCY_COUNTRY_FLAG_MAP[sourceCurrency] && (
              <img src={CURRENCY_COUNTRY_FLAG_MAP[sourceCurrency]?.logoUrl} alt={CURRENCY_COUNTRY_FLAG_MAP[sourceCurrency].displayName} />
            )}
          </CurrencyWithFlag>
          <Footer>
            {'Available balance: '}
            <b>{formatPrice(sourceCurrency, currencyBalanceMap[sourceCurrency]?.total_amount)}</b>
          </Footer>
        </FieldWrapper>
        {sourceCurrency !== paymentCurrency && (
          <FieldWrapper>
            <FormTitle scale={5}>Exchange rate</FormTitle>
            <div>
              {formatFXRatio({
                foreignExchange: sourceToPaymentFX,
                sourceCurrency,
                paymentCurrency,
              })}
            </div>
            <Footer>
              <div>{convertToFullDateAndTime(sourceToPaymentFX?.valid_from)}</div>
              <CircularProgressBar
                total={moment(sourceToPaymentFX?.valid_to).diff(sourceToPaymentFX?.valid_from, 'second')}
                lines={[{ value: fxCountdownTime, color: COLORS.purple, id: 'countdown' }]}
                strokeWidth={2}
                radius={4}
                strokeLinecap="square"
              />
            </Footer>
          </FieldWrapper>
        )}
        <FieldWrapper>
          <FormTitle scale={5}>Transfer fee</FormTitle>
          <div>
            {!feeFX || (paymentCurrency && !sourceCurrency) || sourceCurrency === 'USD' ? (
              <div>{formatPrice('USD', fee)}</div>
            ) : (
              <>
                {feeFX && (
                  <ToolTip
                    width={200}
                    tip="Actual converted fee amount to your sending currency will be based on the real-time FX rate at settlement."
                    placeholder={
                      <ConvertedFee>
                        <span>{formatPrice(sourceCurrency, fee * (feeFX.client_rate || 1))}</span>
                        <img src="/icons/tool_tip.svg" alt="toolTip" />
                      </ConvertedFee>
                    }
                  />
                )}
                <div>{`(${formatPrice('USD', fee)})`}</div>
              </>
            )}
          </div>
        </FieldWrapper>
      </TransferDetail>
      <FormTitle scale={5}>Recipient gets</FormTitle>
      <Wrapper>
        <FormTitle scale={5}>Amount</FormTitle>
        {formatPrice(paymentCurrency, paymentAmount)}
        <FormTitle scale={5}>Recipient</FormTitle>
        <div>{recipient?.nickname || recipient?.beneficiary?.company_name}</div>
        <div>{countryMap[recipient?.beneficiary?.bank_details?.bank_country_code]}</div>

        <HR height={1} />
        <RecipientInfoTable>
          {paymentMethod !== 'SWIFT' && recipient?.beneficiary?.bank_details?.account_routing_value1 && (
            <tr>
              {/* <th>{('account_routing_value1', `${paymentMethod} routing Number`, formValue)}</th> */}
              <th>{getTitleFromSchema('account_routing_value1', recipientSchema)}</th>
              <td>{recipient?.beneficiary?.bank_details?.account_routing_value1}</td>
            </tr>
          )}
          {recipient?.beneficiary?.bank_details?.swift_code && (
            <tr>
              <th>Swift code</th>
              <td>{recipient?.beneficiary?.bank_details?.swift_code}</td>
            </tr>
          )}
          {recipient?.beneficiary?.bank_details?.account_number && (
            <tr>
              <th>Account number</th>
              <td>{recipient?.beneficiary?.bank_details?.account_number}</td>
            </tr>
          )}
          {reference && (
            <tr>
              <th>Transfer reference</th>
              <td>{reference}</td>
            </tr>
          )}
          {request_id && (
            <tr>
              <th>Internal reference</th>
              <td>{request_id}</td>
            </tr>
          )}
          <tr>
            <th>Purpose</th>
            <td>{getOptionLabelByValue(reason, PURPOSE_OPTIONS)}</td>
          </tr>
          <tr>
            <th>Transfer date</th>
            <td>{convertToFullDate(transferDate)}</td>
          </tr>
        </RecipientInfoTable>
        <ButtonWrapper>
          <Button width={160} type="button" secondary onClick={handleBack} disabled={isLoading}>
            Back
          </Button>
          {isTransfer2faSetup && isVerified === false ? (
            <Button width={160} onClick={handleVerify2fa} disabled={isLoading}>
              Verify
            </Button>
          ) : isTransfer2faSetup && isVerified ? (
            <Button width={160} onClick={handleTransfer} disabled={isLoading}>
              Send Money
            </Button>
          ) : (
            <Button width={160} type="submit" disabled={isLoading}>
              Send Money
            </Button>
          )}
          {apiError && <ErrorMessage>{apiError}</ErrorMessage>}
        </ButtonWrapper>
      </Wrapper>
    </form>
  );
};

export default ReviewForm;
