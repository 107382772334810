import styled, { css } from 'styled-components';

const UnderlineTab = styled.span`
  display: inline-block;
  line-height: 40px;
  cursor: pointer;
  padding: 4px 12px;
  text-align: middle;
  color: ${(props) => props.theme.colors.black};

  ${(props) =>
    props.selected &&
    css`
      color: ${props.theme.colors.purple};
      font-weight: bold;

      border-bottom: 2px solid ${props.theme.colors.purple};
    `};
`;

export default UnderlineTab;
