import React, { useEffect } from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import PATH from '../../path';
import MultiFactorSetup from './2FA/MultiFactorSetup';
import MultiFactorOverview from './2FA/Overview';
import Overview from './Overview';
import UserInviteForm from './UserInvite/UserInviteForm';
import { fetchAllUsers } from '@redux/modules/user/actions';
import { useSelectedCompanyId } from '@redux/selectors';

const UserManagementContainer = () => {
  const companyId = useSelectedCompanyId();

  useEffect(() => {
    fetchAllUsers();
  }, [companyId]);

  return (
    <Switch>
      <Route exact path={PATH.SETTINGS_MULTI_FACTOR_OVERVIEW} component={MultiFactorOverview} />
      <Route exact path={PATH.SETTINGS_MULTI_FACTOR_SETUP} component={MultiFactorSetup} />
      <Route path={PATH.SETTINGS_USER_MANAGEMENT_FORM} component={UserInviteForm} />
      <Route path={PATH.SETTINGS_USER_MANAGEMENT} component={Overview} />
      <Redirect to={PATH.SETTINGS_USER_MANAGEMENT} />
    </Switch>
  );
};

export default UserManagementContainer;
