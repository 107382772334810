import * as Sentry from '@sentry/react';

const Logger = () => {
  const env = process.env.REACT_APP_NODE_ENV;
  const isInDebugMode = process.env.REACT_APP_NODE_ENV === 'development';

  const error = (err) => {
    if (isInDebugMode) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
    return Sentry.captureException(err, isInDebugMode ? 'debug' : undefined);
  };

  return {
    error,
  };
};

export default Logger();
