import React from 'react';

import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Button from '../../../components/Button';
import PATH from '../path';
import { media } from '@styles/breakpoints';

const ImageWrapper = styled.div`
  width: 50%;
  margin-top: 50px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 70vh;
  ${media.desktop`
    flex-direction: column;
    height: auto;
  `};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 54px;
  width: 50%;

  ${media.desktop`
    width: 100%;
    order: 2;
    margin: 40px 0;
  `};
`;

const SectionHeader = styled.div`
  ${(props) => props.theme.text.three};
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
`;

const Description = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  font-weight: 600;
  margin: 20px 0;
  width: 80%;
  line-height: 1.5;

  ${media.desktop`
    width: 100%;
  `};
`;

const OnboardImg = styled.img`
  width: 90%;
  display: block;

  ${media.desktop`
    width: 100%;
  `};
`;

const OnboardingOverview = ({ title, description, path, imgUrl }) => {
  const history = useHistory();

  return (
    <Container>
      <ContentWrapper>
        <SectionHeader>{title}</SectionHeader>
        <Description>{description}</Description>
        <Button width={155} secondary onClick={() => history.push(path)}>
          Get Started
        </Button>
      </ContentWrapper>
      <ImageWrapper>
        <OnboardImg src={imgUrl} />
      </ImageWrapper>
    </Container>
  );
};

export default OnboardingOverview;
