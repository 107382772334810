import React, { useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import Button from '../../components/Button';
import FormTitle from '../../components/FormTitle';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import Logger from '../../utils/logger';
import { API_BASE_URL, API_INTEGRATION_PORT } from '@api/constants';
import { FormRow, GridColumn } from '@components/Grid';
import { PLATFORM } from '@constants/platform';
import { useUserCompanyId } from '@redux/selectors';
import { postIntegrateAccount } from '@/api/modules/application';


const ShoplazzaDomainPlacer = styled.div`
  padding: 0 24px;
  line-height: 50px;
`;
const InputWrapper = styled.div`
  ${(props) => props.theme.input.border};
  :focus-within {
    ${(props) => props.theme.input.borderFocus};
  }
  border-radius: 5px;

  ${(props) =>
    props.hasError &&
    css`
      ${props.theme.input.borderError};
    `}

  input {
    margin: 1px;
  }

  > div:first-child > ${ShoplazzaDomainPlacer} {
    border-right: 1px solid ${(props) => props.theme.colors.grayTwo};
  }

  > div:last-child > ${ShoplazzaDomainPlacer} {
    border-left: 1px solid ${(props) => props.theme.colors.grayTwo};
  }
`;

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.error};
  ${(props) => props.theme.text.micro};
`;

const SitePreview = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grayTwo};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  text-align: left;
`;

const StoreLogo = styled.div`
  margin-right: 20px;
`;

const StoreLogoImg = styled.img`
  border-radius: 4px;
  max-width: 50px;
`;

const StoreMetaData = styled.div`
  flex-grow: 1;
`;

const StoreName = styled.div`
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 4px;
`;

const StoreUrl = styled.div`
  color: ${(props) => props.theme.colors.grayThree};
  font-size: 14px;
`;

const Hyperlink = styled.a`
  color: ${(props) => props.theme.colors.purple};
`;

const ShoplazzaIntegrationModal = ({ afterSubmit = () => {}, hideModal }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, toggleIsLoading] = useState();

  const shoplazzaAppListingUrl = 'https://apps.shoplazza.com/choco-up';

  const dispatch = useDispatch();
  const companyId = useUserCompanyId();
  const [storeInfo, setStoreInfo] = useState(undefined);
  const [storeCheckError, setStoreCheckError] = useState(undefined);
  const numOfConnections = useSelector((state) => {
    return state.application.integrations.filter(
      (integration) => integration.platformName === PLATFORM.SHOPLAZZA && integration.status === 1,
    ).length;
  });

  /*
    onSubmit sends a request to the backend to request the installation url
    to install Choco app on merchant's Shoplazza store
   */
    const onSubmit = async (formData) => {
      try {
        toggleIsLoading(true);
        dispatch(hideModal());
        const newWin = window.open(storeInfo.url, 'newWin', 'width=1200,height=800');
      } catch (err) {
        Logger.error(err);
      } finally {
        toggleIsLoading(false);
      }
    };
  
    /*
      onIntent sends a request to the backend and checks if
      the store URL is a valid one
     */
    const onIntent = async (formData) => {
      try {
        toggleIsLoading(true);
        setStoreCheckError(undefined);
  
        const integrationEndpoint = `${API_INTEGRATION_PORT}/integration/v1`;
        const url = `${API_BASE_URL}${integrationEndpoint}/${PLATFORM.SHOPLAZZA}/register`;
        const { data, error, statusCode } = await postIntegrateAccount(PLATFORM.SHOPLAZZA, {
          shopName: formData.storeName,
        });
        if (statusCode === 400) {
          throw error;
        } else if (statusCode === 200) {
          setStoreInfo(data);
        }
      } catch (err) {
        Logger.error(err);
        setStoreCheckError(err);
      } finally {
        toggleIsLoading(false);
      }
    };
  
    return (
      <Modal showCloseButton>
        {numOfConnections === 0 ? (
          <form>
            <FormTitle scale={3}>Connect the Shoplazza store of your company</FormTitle>
            {storeInfo && !storeCheckError ? (
              <FormTitle scale={5}>
                We&apos;re going to send you to your store now to give Choco Up permission to access your store&apos;s
                data.
              </FormTitle>
            ) : (
              <FormTitle scale={5}>What&apos;s your store&apos;s website?</FormTitle>
            )}
            {!storeInfo && (
              <InputWrapper hasError={errors.storeName}>
                <GridColumn lg={2} md={2} disableGutter>
                  <ShoplazzaDomainPlacer>https://</ShoplazzaDomainPlacer>
                </GridColumn>
                <GridColumn lg={6} md={6} disableGutter>
                  <Controller
                    name="storeName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <Input {...field} hasError={errors.storeName} outline={false} full />}
                  />
                </GridColumn>
                <GridColumn lg={4} md={4} disableGutter>
                  <ShoplazzaDomainPlacer>.myshoplazza.com</ShoplazzaDomainPlacer>
                </GridColumn>
              </InputWrapper>
            )}
            {storeInfo && storeInfo.url && (
              <FormRow>
                <SitePreview>
                  <StoreLogo>
                    <StoreLogoImg src={storeInfo.icon_url} />
                  </StoreLogo>
                  <StoreMetaData>
                    <StoreName>{storeInfo.title}</StoreName>
                    <StoreUrl>{storeInfo.site_url}</StoreUrl>
                  </StoreMetaData>
                </SitePreview>
              </FormRow>
            )}
            {errors.storeName && !storeCheckError && <ErrorMessage>Please enter a valid Shoplazza store</ErrorMessage>}
            {storeCheckError && <ErrorMessage>{storeCheckError}</ErrorMessage>}
            <FormRow>
              {!storeInfo && (
                <GridColumn lg={6} md={6}>
                  <Button disabled={isLoading} primary full onClick={handleSubmit(onIntent)}>
                    Add Store
                  </Button>
                </GridColumn>
              )}
              {storeInfo && !storeCheckError && (
                <GridColumn lg={6} md={6}>
                  <Button type="submit" disabled={isLoading} primary full onClick={handleSubmit(onSubmit)}>
                    Connect
                  </Button>
                </GridColumn>
              )}
              <GridColumn lg={6} md={6}>
                <Button onClick={hideModal} tertiary full>
                  Cancel
                </Button>
              </GridColumn>
            </FormRow>
          </form>
        ) : (
          <form>
            <FormTitle scale={3}>Connect the Shoplazza store of your company</FormTitle>
            <FormTitle scale={5}>
              1. For an additional store, please go to Choco Up&apos;s&nbsp;
              <Hyperlink href={shoplazzaAppListingUrl}>app listing page</Hyperlink>
              &nbsp;on Shoplazza
            </FormTitle>
            <FormTitle scale={5}>2. At Shoplazza&apos;s Login page, choose the store you want to integrate</FormTitle>
            <FormTitle scale={5}>3. Click &quot;Add App&quot; to install</FormTitle>
          </form>
        )}
      </Modal>
    );
  };
  
  export default ShoplazzaIntegrationModal;
  