import store from '../../store';
import {
  ADD_OFFER,
  EDIT_OFFER,
  DELETE_OFFER,
  SUBMIT_OFFERS_REQUEST,
  SUBMIT_OFFERS_SUCCESS,
  SUBMIT_OFFERS_FAILURE,
  GET_ADMIN_OFFERS_REQUEST,
  GET_ADMIN_OFFERS_SUCCESS,
  GET_ADMIN_OFFERS_FAILURE,
  GET_USER_OFFERS_REQUEST,
  GET_USER_OFFERS_SUCCESS,
  GET_USER_OFFERS_FAILURE,
  APPROVE_OFFER_BY_CREDIT_TEAM_REQUEST,
  APPROVE_OFFER_BY_CREDIT_TEAM_SUCCESS,
  APPROVE_OFFER_BY_CREDIT_TEAM_FAILURE,
  APPROVE_OFFERS_REQUEST,
  APPROVE_OFFERS_SUCCESS,
  APPROVE_OFFERS_FAILURE,
  REJECT_OFFERS_REQUEST,
  REJECT_OFFERS_SUCCESS,
  REJECT_OFFERS_FAILURE,
  RETURN_OFFERS_REQUEST,
  RETURN_OFFERS_SUCCESS,
  RETURN_OFFERS_FAILURE,
  GET_DRAFT_OFFERS_REQUEST,
  GET_DRAFT_OFFERS_SUCCESS,
  GET_DRAFT_OFFERS_FAILURE,
} from './constants';
import { generateOfferPayload, convertOfferResponse } from './helpers';
import {
  createOffers,
  getAllOffers,
  getApprovedOffers,
  approveFundingProposalsByManager,
  approveFundingProposalByCreditTeam,
  returnFundingProposalsByCreditTeam,
  rejectFundingProposalsByCreditTeam,
  returnFundingProposalsByManager,
  rejectFundingProposalsByManager,
} from '@api/modules/application';
import { getDraftOffers } from '@api/modules/application/offer';

export const addOffer = (id, offer) => ({
  type: ADD_OFFER,
  payload: {
    id,
    offer,
  },
});

export const editOfferById = (id, offer) => ({
  type: EDIT_OFFER,
  payload: {
    id,
    offer,
  },
});

export const deleteOfferById = (id) => ({
  type: DELETE_OFFER,
  payload: { id },
});

export const submitOffers = async (applicationId, payload) => {
  store.dispatch({ type: SUBMIT_OFFERS_REQUEST, payload: { offers: payload, applicationId } });

  const constructedPayload = payload.map((offer) => generateOfferPayload(offer, applicationId));
  const { data, error, statusCode } = await createOffers(applicationId, constructedPayload);

  if (statusCode === 201) {
    store.dispatch({
      type: SUBMIT_OFFERS_SUCCESS,
    });
  } else {
    store.dispatch({
      type: SUBMIT_OFFERS_FAILURE,
      payload: error,
    });
  }
};

export const fetchUserOffers = async (applicationId) => {
  store.dispatch({ type: GET_USER_OFFERS_REQUEST, payload: { applicationId } });
  const { data, error } = await getApprovedOffers(applicationId);

  if (Array.isArray(data)) {
    const response = data.map((offer) => convertOfferResponse(offer));
    store.dispatch({
      type: GET_USER_OFFERS_SUCCESS,
      payload: response,
    });
  } else {
    store.dispatch({
      type: GET_USER_OFFERS_FAILURE,
      payload: error,
    });
  }
};

export const approveOffers = async (applicationId, isManagerAction) => {
  store.dispatch({ type: APPROVE_OFFERS_REQUEST, payload: { applicationId } });
  const api = isManagerAction ? approveFundingProposalsByManager : approveFundingProposalByCreditTeam;
  const { error, statusCode } = await api(applicationId);

  if (statusCode === 200) {
    store.dispatch({ type: APPROVE_OFFERS_SUCCESS });
  } else {
    store.dispatch({ type: APPROVE_OFFERS_FAILURE, payload: error });
  }
};

export const returnOffers = async (applicationId, isManagerAction) => {
  store.dispatch({ type: RETURN_OFFERS_REQUEST, payload: { applicationId } });

  const api = isManagerAction ? returnFundingProposalsByManager : returnFundingProposalsByCreditTeam;
  const { error, statusCode } = await api(applicationId);

  if (statusCode === 200) {
    store.dispatch({ type: RETURN_OFFERS_SUCCESS });
  } else {
    store.dispatch({ type: RETURN_OFFERS_FAILURE, payload: error });
  }
};

export const rejectOffers = async (applicationId, isManagerAction) => {
  store.dispatch({ type: REJECT_OFFERS_REQUEST, payload: { applicationId } });

  const api = isManagerAction ? rejectFundingProposalsByManager : rejectFundingProposalsByCreditTeam;
  const { error, statusCode } = await api(applicationId);

  if (statusCode === 200) {
    store.dispatch({ type: REJECT_OFFERS_SUCCESS });
  } else {
    store.dispatch({ type: REJECT_OFFERS_FAILURE, payload: error });
  }
};

export const approveOfferByCreditTeam = async (applicationId) => {
  store.dispatch({
    type: APPROVE_OFFER_BY_CREDIT_TEAM_REQUEST,
    payload: { applicationId },
  });

  const { error, statusCode } = await approveFundingProposalByCreditTeam(applicationId);

  if (statusCode === 200) {
    store.dispatch({ type: APPROVE_OFFER_BY_CREDIT_TEAM_SUCCESS });
  } else {
    store.dispatch({
      type: APPROVE_OFFER_BY_CREDIT_TEAM_FAILURE,
      payload: error,
    });
  }
};

export const fetchAdminOffers = async (applicationId) => {
  store.dispatch({
    type: GET_ADMIN_OFFERS_REQUEST,
    payload: { applicationId },
  });
  const { data, error } = await getAllOffers(applicationId);

  if (Array.isArray(data)) {
    const response = data.map((offer) => convertOfferResponse(offer));
    store.dispatch({
      type: GET_ADMIN_OFFERS_SUCCESS,
      payload: response,
    });
  } else {
    store.dispatch({
      type: GET_ADMIN_OFFERS_FAILURE,
      payload: error,
    });
  }
};

export const fetchDraftOffers = async (applicationId) => {
  store.dispatch({
    type: GET_DRAFT_OFFERS_REQUEST,
    payload: { applicationId },
  });
  const { data, error } = await getDraftOffers(applicationId);

  if (Array.isArray(data)) {
    const response = data.map((offer) => convertOfferResponse(offer));
    store.dispatch({
      type: GET_DRAFT_OFFERS_SUCCESS,
      payload: response,
    });
  } else {
    store.dispatch({
      type: GET_DRAFT_OFFERS_FAILURE,
      payload: error,
    });
  }
};
