import React, { useEffect, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import FormTitle from '../../../../components/FormTitle';
import HR from '../../../../components/HR';
import RadioWithText from '../../../../components/RadioWithText';
import SingleDatePicker from '../../../../components/SingleDatePicker';
import Logger from '../../../../utils/logger';
import ControlledTextarea from '../../../ReactHookForm/ControlledTextarea';
import RHFMoneyInput from '../../../ReactHookForm/RHFMoneyInput';
import PATH from '../../path';
import { paymentMethodOptions, REVENUE_FORM_PATH } from './constants';
import { generateInvoicePayload } from './helpers';
import { createManualInvoice } from '@api/modules/dashboard/invoice';
import { ERROR_MESSAGE_REQUIRED } from '@constants/form';
import { fetchBalanceTransactions } from '@redux/modules/fund/actions';
import { useAcceptedOffer, useSelectedCompanyId, useActiveRevenueStream } from '@redux/selectors';
import { isRealNumber } from '@utils/dataTypes';
import { datePickerAllowDaysBeforeToday } from '@utils/dateHelpers';
import { formatPrice, formatPercent } from '@utils/priceHelpers';

const ButtonWrapper = styled.div`
  ${(props) => props.theme.buttonWrapper};

  div {
    ${(props) => props.theme.text.error};
  }
`;

const FieldWrapper = styled.div`
  ${(props) => props.theme.form.field.margin};
  margin: 15px 0;
`;

const FlowInfoWrapper = styled.div`
  margin-left: 16px;
  padding: 5px 0 0 16px;
  border-left: 1px dashed ${(props) => props.theme.colors.grayTwo};

  > div {
    position: relative;
    .circle {
      margin: 5px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.white};
      position: absolute;
      left: -35px;
      top: -4px;
      div {
        position: absolute;
        top: 25%;
        right: 25%;
        width: 9px;
        height: 9px;
        background-color: ${(props) => props.theme.colors.grayThree};
        border-radius: 50%;
      }
    }
  }
`;

const InvoiceAmountInputWrapper = styled.div`
  margin-left: 32px;
`;

const InvoiceForm = () => {
  const history = useHistory();
  const acceptedOffer = useAcceptedOffer();
  const { formType } = useParams();
  const [apiError, setApiError] = useState('');
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      paymentMethod: paymentMethodOptions[0].value,
    },
  });
  const selectedCompanyId = useSelectedCompanyId();
  const { applicationId, fundingNumber } = useSelector((state) => state.application.data);
  const activeRevenueStream = useActiveRevenueStream();

  const watchOfRevenueAmount = watch('revenueAmount');
  const watchOfPaymentMethod = watch('paymentMethod');

  const [isLoading, toggleIsLoading] = useState();

  useEffect(() => {
    if (isRealNumber(watchOfRevenueAmount)) {
      const nextInvoicedAmount = (watchOfRevenueAmount * acceptedOffer?.retrievalPercentage) / 100;
      setValue('invoicedAmount', nextInvoicedAmount);
    }
  }, [watchOfRevenueAmount]);

  useEffect(() => {
    if (acceptedOffer?.purchasedPriceCurrency) {
      setValue('invoicedCurrency', acceptedOffer.purchasedPriceCurrency);
      setValue('revenueCurrency', acceptedOffer.purchasedPriceCurrency);
    }
  }, [JSON.stringify(acceptedOffer)]);

  const onSubmit = async (formData) => {
    toggleIsLoading(true);
    setApiError('');
    try {
      if (!acceptedOffer) {
        throw new Error('No accepted offer is available');
      } else if (!activeRevenueStream) {
        throw new Error('No active revenue stream is available');
      }
      const payload = generateInvoicePayload({
        formData,
        formType,
        companyId: selectedCompanyId,
        fundingId: applicationId,
        offerId: acceptedOffer?.id,
        remittanceStreamId: activeRevenueStream?.stream_id,
      });
      const { data, statusCode, error } = await createManualInvoice(payload);

      if (statusCode === 201) {
        await fetchBalanceTransactions(fundingNumber);
        history.push(PATH.CAPITAL_BALANCE_TRANSACTIONS);
      } else {
        setApiError('Something went wrong');
      }
    } catch (err) {
      Logger.error(err);
      setApiError(err?.message);
    } finally {
      toggleIsLoading(false);
    }
  };

  const handleBackClick = () => {
    history.replace(PATH.CAPITAL_CREATE_MANUAL_INVOICE);
  };

  const invoicedAmountRules = {
    required: true,
    ...(formType === REVENUE_FORM_PATH && {
      min: {
        value: acceptedOffer?.minRemittanceAmount,
        message: `Should not be samller than offer min remittance amount ${formatPrice(
          acceptedOffer?.purchasedPriceCurrency,
          acceptedOffer?.minRemittanceAmount
        )}`,
      },
      max: {
        value: acceptedOffer?.maxRemittanceAmount,
        message: `Should not be greater than offer max remittance amount ${formatPrice(
          acceptedOffer?.purchasedPriceCurrency,
          acceptedOffer?.maxRemittanceAmount
        )}`,
      },
    }),
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {formType === REVENUE_FORM_PATH ? (
        <>
          <FormTitle scale={5} required>
            Revenue Amount
          </FormTitle>
          <RHFMoneyInput control={control} prefix="revenue" errors={errors} selectProps={{ disabled: true }} />
          <FlowInfoWrapper>
            <div>
              <div className="circle">
                <div />
              </div>
              <FormTitle scale={5}>{`Remittance rate: ${formatPercent(acceptedOffer?.retrievalPercentage)}`}</FormTitle>
            </div>
            <div>
              <div className="circle">
                <div />
              </div>
              <FormTitle scale={5}>Invoice amount:</FormTitle>
            </div>
          </FlowInfoWrapper>
          <InvoiceAmountInputWrapper>
            <RHFMoneyInput
              control={control}
              prefix="invoiced"
              errors={errors}
              selectProps={{ disabled: true }}
              inputProps={{ disabled: true }}
              amountRules={invoicedAmountRules}
            />
          </InvoiceAmountInputWrapper>
          <FormTitle scale={5} required>
            Revenue Description
          </FormTitle>
          <ControlledTextarea
            control={control}
            name="description"
            rules={{
              required: { value: true, message: ERROR_MESSAGE_REQUIRED },
              maxLength: {
                value: 1000,
                message: 'Max length is 1000 character-long',
              },
            }}
            full
          />
        </>
      ) : (
        <>
          <FormTitle scale={5} required>
            Invoice amount:
          </FormTitle>
          <RHFMoneyInput
            control={control}
            prefix="invoiced"
            errors={errors}
            selectProps={{ disabled: true }}
            amountRules={invoicedAmountRules}
          />
          <FormTitle scale={5} required>
            Invoice description:
          </FormTitle>
          <ControlledTextarea
            control={control}
            name="description"
            errors={errors}
            rules={{
              required: { value: true },
              maxLength: {
                value: 1000,
                message: 'Max length is 1000 character-long',
              },
            }}
            full
          />
        </>
      )}
      <HR />
      <FormTitle scale={5} required>
        Invoice due date
      </FormTitle>
      <Controller
        name="invoiceDueDate"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <SingleDatePicker
            {...field}
            id="invoiceDueDate"
            hasError={errors.invoiceDueDate}
            isOutsideRange={(value) => {
              return !datePickerAllowDaysBeforeToday(value);
            }}
          />
        )}
      />
      <FormTitle scale={5}>Invoice to be paid via:</FormTitle>
      {paymentMethodOptions.map((option) => (
        <RadioWithText
          key={option.value}
          {...option}
          checked={watchOfPaymentMethod === option.value}
          onClick={() => setValue('paymentMethod', option.value)}
        />
      ))}
      <ButtonWrapper>
        <Button onClick={handleBackClick} secondary>
          Back
        </Button>
        <Button disabled={isLoading}>Create</Button>
        {apiError && <div>{apiError}</div>}
      </ButtonWrapper>
    </form>
  );
};

export default InvoiceForm;
