import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../components/Button';
import DASHBOARD_PATH from '../../Dashboard/path';
import { APPLICATION_CONFIRM_SUBMISSION } from '../../Modal/constants';
import PATH, { PATH_LIST } from '../path';
import { updateApplication, applyApplication } from '@api/modules/application';
import { fetchApplication } from '@redux/modules/application/actions';
import { showModal } from '@redux/modules/UI/actions';
import { useCheckIsAdminViewingAnotherCompany, useCheckIsSignUpViaShopify } from '@redux/selectors';
import { convertToBooleanNumeral } from '@utils/dataTypes';

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: initial;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  padding: 8px 56px;
  box-sizing: border-box;
  border-top: 2px solid ${(props) => props.theme.colors.grayTwo};
`;

const PreviousStepButton = styled(Button)``;

const NextStepButton = styled(Button)`
  float: right;
`;

const BottomControl = ({ allowAllPages, canSubmit, nextStepValidator = () => {} }) => {
  const userProfile = useSelector((state) => state.user.profile);
  const {
    data: { applicationId, statusCode },
  } = useSelector((state) => state.application);
  const applicationData = useSelector((state) => state.application.data);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, toggleIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const isAdminView = useCheckIsAdminViewingAnotherCompany();
  const isSignUpViaShopify = useCheckIsSignUpViaShopify();

  const handlePreviousStepClick = () => {
    if ([PATH.COMPANY_BUSINESS, PATH.COMPANY_KEY_PERSONS].indexOf(location.pathname) !== -1) {
      history.push(PATH.ACCOUNTING);
    }

    const currentPathIndex = PATH_LIST.indexOf(location.pathname);
    if (currentPathIndex > 0) {
      history.push(PATH_LIST[currentPathIndex - 1]);
    }
  };

  // next page and save application
  const handleNextStepClick = async () => {
    if (isSignUpViaShopify) {
      history.push(DASHBOARD_PATH.HOME);
      return;
    }

    toggleIsLoading(true);

    const errors = nextStepValidator();
    if (errors) {
      toggleIsLoading(false);
      return;
    }

    switch (location.pathname) {
      case PATH.SALES_ACCOUNTS:
        if (!userProfile.applicationSubmitted) {
          if (applicationId) {
            await updateApplication(applicationId, {
              no_platform: convertToBooleanNumeral(applicationData.salesForm.noPlatform),
              monthly_revenue: applicationData.salesForm.amount,
              currency: applicationData.salesForm.currency,
              additional_information: '',
              ...(applicationData.offerForm.fund && {
                funding_amount: applicationData.offerForm.fund,
              }),
            });
          } else {
            await applyApplication({
              no_platform: convertToBooleanNumeral(applicationData.salesForm.noPlatform),
              monthly_revenue: applicationData.salesForm.amount,
              currency: applicationData.salesForm.currency,
            });
          }
        }
        break;
      default:
        break;
    }

    if (!userProfile.applicationSubmitted && location.pathname !== PATH.SALES_ACCOUNTS) {
      await fetchApplication();
    }

    const currentPathIndex = PATH_LIST.indexOf(location.pathname);
    if (currentPathIndex !== -1 && currentPathIndex < PATH_LIST.length - 1) {
      history.push(PATH_LIST[currentPathIndex + 1]);
    }

    if (currentPathIndex === PATH_LIST.length - 1) {
      history.push(DASHBOARD_PATH.HOME);
    }

    toggleIsLoading(false);
  };

  const handleSubmitClick = () => {
    const errors = nextStepValidator();
    if (errors) {
      return;
    }

    dispatch(showModal(APPLICATION_CONFIRM_SUBMISSION));
  };

  const PreviousButton = (
    <PreviousStepButton onClick={handlePreviousStepClick} disabled={isLoading} tertiary small>
      Previous Step
    </PreviousStepButton>
  );

  const NextButton = (
    <NextStepButton onClick={handleNextStepClick} disabled={isLoading} small>
      Next Step
    </NextStepButton>
  );

  const SubmitButton = (
    <NextStepButton onClick={handleSubmitClick} disabled={isLoading || isAdminView} small>
      Submit
    </NextStepButton>
  );

  return (
    <Wrapper>
      {location.pathname === PATH.SALES_ACCOUNTS && NextButton}
      {location.pathname === PATH.PRELIMINARY_OFFER && (
        <>
          {PreviousButton}
          {allowAllPages ? NextButton : null}
          {canSubmit ? SubmitButton : null}
        </>
      )}
      {[
        PATH.MARKETING_ACCOUNTS,
        PATH.BANK_ACCOUNTS,
        PATH.ACCOUNTING,
        PATH.COMPANY,
        PATH.TERM_SHEET,
        PATH.ADDITIONAL_INFO,
        PATH.AGREEMENT,
      ].indexOf(location.pathname) !== -1 && (
        <>
          {PreviousButton}
          {NextButton}
        </>
      )}
    </Wrapper>
  );
};

export default BottomControl;
