import React, { useState, useEffect } from 'react';

import deepEqual from 'deep-equal';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { Card } from '.';
import CircularProgressBar from '../../../components/CircularProgressBar';
import featureToggle from '../../../featureToggle';
import COLORS from '../../../styles/colors';
import { GridRow, GridColumn } from '@components/Grid';
import { remittancePeriodOptions } from '@constants/options';
import { media } from '@styles/breakpoints';
import { convertToFullDate } from '@utils/dateHelpers';
import { getOptionLabelByValue } from '@utils/optionHelpers';
import { formatPrice, formatPercent, getPercentFromDecimal } from '@utils/priceHelpers';
import { getFundBalanceAmountsByCurrency } from '@utils/remittanceHelpers';

const SectionHeader = styled.span`
  ${(props) => props.theme.text.four};
  line-height: 42px;
  font-weight: 600;
`;

const InfoCss = css`
  ${(props) => props.theme.text.five};
  font-weight: 600;
`;

const FundingID = styled.div`
  ${InfoCss};
  text-transform: uppercase;
`;

const ActivatedDate = styled.div`
  ${InfoCss};
  color: ${(props) => props.theme.colors.grayThree};
  padding-bottom: 18px;
`;

const NextPaymentDate = styled.div`
  ${InfoCss};
`;

const FieldTitle = styled.div`
  ${InfoCss};
  ${(props) => props.theme.text.micro};
  color: ${(props) => props.theme.colors.grayThree};
  text-transform: capitalize;
  ${(props) => props.theme.ellipsis};
`;

const StyledCircularProgressBar = styled(CircularProgressBar)`
  margin-right: 40px;
`;

const FieldData = styled.div`
  ${InfoCss};
  color: ${(props) => props.theme.colors.black};
  text-transform: capitalize;
  padding: 6px 0 24px;
`;

const AmountData = styled.div`
  ${InfoCss};
  color: ${(props) => props.theme.colors.black};
`;

const AmountWrapper = styled.div`
  margin-bottom: 12px;
`;

const Legend = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  display: inline-block;
  vertical-align: middle;
`;

const AmountInfo = styled.span`
  padding-left: 10px;
  display: inline-block;
  vertical-align: middle;
`;

const OfferColumn = styled(GridColumn)`
  padding-right: 24px;
  box-sizing: border-box;

  ${media.desktop`
    padding-right: 0;
  `};
`;

const PaymentColumn = styled(GridColumn)`
  border-left: 2px solid ${(props) => props.theme.colors.grayOne};
  padding-left: 24px;
  box-sizing: border-box;

  ${media.desktop`
    border-left:none;
    padding-left: 0;
  `};
`;

const PaymentWrapper = styled.div`
  display: inline-block;
`;

const RemittanceSummaryCard = ({ offer = {} }) => {
  const { fundingNumber } = useSelector((state) => state.application.data);
  const fundBalance = useSelector((state) => state.fund.balance);
  const [lines, setLines] = useState();
  const [fundBalanceAmounts, setFundBalanceAmounts] = useState({});
  const amountDue = fundBalanceAmounts.dueAmount + fundBalanceAmounts.pendingAmount;
  const {
    purchasedReceivablesAmount,
    purchasedReceivablesCurrency,
    actualDisbursementDate,
    purchasedPriceAmount,
    remittancePeriod,
    retrievalPercentage,
    minRemittanceAmount,
    maxRemittanceAmount,
  } = offer || {};
  const currency = purchasedReceivablesCurrency;

  const getLines = () => {
    return [
      { value: amountDue ? fundBalanceAmounts.paidAmount + amountDue : 0, color: COLORS.orange, id: 'amountDue' },
      {
        value: Math.min(fundBalanceAmounts.paidAmount, fundBalanceAmounts.fundDisbursementAmount + fundBalanceAmounts.fundingFeeAmount),
        color: COLORS.purple,
        id: 'paidAmount',
      },
    ];
  };

  const showProgressBar = !deepEqual(fundBalance, {});

  useEffect(() => {
    const linesConfig = getLines();
    setLines(linesConfig);
  }, [JSON.stringify(fundBalanceAmounts)]);

  useEffect(() => {
    if (!deepEqual(fundBalance, {})) {
      const amounts = getFundBalanceAmountsByCurrency(fundBalance);
      setFundBalanceAmounts(amounts);
    }
  }, [JSON.stringify(fundBalance)]);

  return (
    <Card>
      <FundingID>{fundingNumber || '-'}</FundingID>
      <div>
        <OfferColumn lg={showProgressBar ? 7 : 12} md={12} sm={12} disableGutter>
          <SectionHeader>
            {`Funding Amount ${purchasedReceivablesAmount ? formatPrice(currency, purchasedPriceAmount, false, 2) : '-'}`}
          </SectionHeader>
          <ActivatedDate>Activated on: {actualDisbursementDate ? convertToFullDate(actualDisbursementDate) : '-'}</ActivatedDate>
          <GridRow>
            <GridColumn lg={4} md={6} sm={6} disableGutter>
              <FieldTitle>Total remittance amount</FieldTitle>
              <FieldData>{purchasedReceivablesAmount ? formatPrice(currency, purchasedReceivablesAmount, false, 2) : '0'}</FieldData>
            </GridColumn>
            <GridColumn lg={4} md={6} sm={6} disableGutter>
              <FieldTitle>Remittance period</FieldTitle>
              <FieldData>{remittancePeriod ? getOptionLabelByValue(remittancePeriod, remittancePeriodOptions) : '-'}</FieldData>
            </GridColumn>
            <GridColumn lg={4} md={6} sm={6} disableGutter>
              <FieldTitle>Remittance rate</FieldTitle>
              <FieldData>{retrievalPercentage ? formatPercent(retrievalPercentage) : '0.00%'}</FieldData>
            </GridColumn>
            <GridColumn lg={4} md={6} sm={6} disableGutter>
              <FieldTitle>Min amount / remittance</FieldTitle>
              <FieldData>{minRemittanceAmount ? formatPrice(currency, minRemittanceAmount) : '-'}</FieldData>
            </GridColumn>
            <GridColumn lg={4} md={6} sm={6} disableGutter>
              <FieldTitle>Max amount / remittance</FieldTitle>
              <FieldData>{maxRemittanceAmount ? formatPrice(currency, maxRemittanceAmount) : '-'}</FieldData>
            </GridColumn>
          </GridRow>
        </OfferColumn>
        {showProgressBar && Array.isArray(lines) && (
          <PaymentColumn lg={5} md={12} sm={12} disableGutter>
            <SectionHeader>Remittance Summary</SectionHeader>
            {featureToggle.NEXT_PAYMENT_DATE && <NextPaymentDate>Next payment date:</NextPaymentDate>}
            <GridRow>
              <StyledCircularProgressBar
                total={fundBalanceAmounts.fundDisbursementAmount + fundBalanceAmounts.fundingFeeAmount}
                radius={40}
                lines={lines}
              />
              <PaymentWrapper>
                <AmountWrapper>
                  <Legend color={COLORS.purple} />
                  <AmountInfo>
                    <FieldTitle>Amount Paid</FieldTitle>
                    <AmountData>{formatPrice(fundBalance.currency, fundBalanceAmounts.paidAmount, false, 2)}</AmountData>
                  </AmountInfo>
                </AmountWrapper>
                <AmountWrapper>
                  <Legend color={COLORS.orange} />
                  <AmountInfo>
                    <FieldTitle>Amount Due</FieldTitle>
                    <AmountData>{formatPrice(fundBalance.currency, amountDue, false, 2)}</AmountData>
                  </AmountInfo>
                </AmountWrapper>
                <AmountWrapper>
                  <Legend color={COLORS.grayTwo} />
                  <AmountInfo>
                    <FieldTitle>Amount Outstanding</FieldTitle>
                    <AmountData>{formatPrice(fundBalance.currency, fundBalanceAmounts.outstandingAmount, false, 2)}</AmountData>
                  </AmountInfo>
                </AmountWrapper>
              </PaymentWrapper>
            </GridRow>
          </PaymentColumn>
        )}
      </div>
    </Card>
  );
};

export default RemittanceSummaryCard;
