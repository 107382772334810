import React, { useState } from 'react';

import parsePhoneNumber from 'libphonenumber-js';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../components/Button';
import Checkbox, { CheckboxText } from '../../../components/Checkbox';
import FormTitle from '../../../components/FormTitle';
import { FormRow as GridFormRow, GridColumn } from '../../../components/Grid';
import Input from '../../../components/Input';
import PasswordInput from '../../../components/PasswordInput';
import PhoneInput from '../../../components/PhoneInput';
import HINT from '../../../utils/hint';
import { useQuery } from '@hooks/routing';
import Logger from '../../../utils/logger';
import { FormRow, Form, Error, Container, ChocoUpLogo, Title, ContentDiv, CenterDiv } from '../components/styled';
import SwitchForm from '../components/SwitchForm';
import PATH from '../path';
import { registerInvitedUser, emailLogin } from '@api/modules/auth';
import { loginUser } from '@redux/modules/auth/actions';
import { fetchUser } from '@redux/modules/user/actions';
import { media } from '@styles/breakpoints';
import { convertToBooleanNumeral } from '@utils/dataTypes';
import { emailRegExp, passwordRegExp } from '@utils/validators';
import { BASE_DASHBOARD_PATH } from '@/basePath';

export const TextLink = styled.a`
  ${(props) => props.theme.text.link};
`;

export const LinkDiv = styled.div`
  padding-bottom: 18px;
`;

export const Logo = styled(ChocoUpLogo)`
  margin: 14px 0;
`;

export const FormGridColumn = styled(GridColumn)`
  padding: 10px 12px 0 12px;
  ${media.phone`
    padding: 0;
  `};
`;

const UserFormTitle = styled(Title)`
  margin-bottom: 5px;
  ${media.phone`
   line-height: 30px;
  `};
`;

const UserFormContainer = styled(Container)`
  height: 100vh;
  ${media.phone`
    height: initial;
  `};
`;

const StyledContentDiv = styled(ContentDiv)`
  height: 72%;
  width: 1050px;
  padding: 30px 0;
  margin-bottom: 20px;
`;

const InviteUserForm = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, toggleIsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      email: query.parsed.email,
      country: query.parsed.region,
    },
  });
  const [country, setCountry] = useState(query.parsed.region);
  const [registerError, setRegisterError] = useState(false);

  const onSubmit = async ({ firstName, lastName, mobileNumber, email, password, optInEmails }) => {
    toggleIsLoading(true);
    try {
      const payload = {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        mobile_phone: mobileNumber,
        allow_update: convertToBooleanNumeral(optInEmails),
        token: query.parsed.token,
      };
      const { error: signUpApiError, statusCode } = await registerInvitedUser(payload);
      if (statusCode === 201) {
        await emailLogin({
          username: email,
          password,
        });

        dispatch(loginUser());
        await fetchUser();
        history.push(BASE_DASHBOARD_PATH);
      } else {
        throw new Error(signUpApiError);
      }
    } catch (err) {
      setRegisterError(err?.message || 'Something went wrong');
      Logger.Error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  return (
    <UserFormContainer>
      <StyledContentDiv>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Logo src="/images/choco-up-black-no-title.png" alt="Choco Up Logo" />
          <UserFormTitle>Let&apos;s set up your account</UserFormTitle>
          <GridFormRow>
            <FormGridColumn lg={6} md={6} sm={12}>
              <FormTitle scale={5} required>
                Your First Name
              </FormTitle>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <Input {...field} placeholder="Joe" hasError={errors.firstName} disabled={isLoading} full />}
              />
            </FormGridColumn>
            <FormGridColumn lg={6} md={6} sm={12}>
              <FormTitle scale={5} required>
                Your Last Name
              </FormTitle>
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <Input {...field} placeholder="Sixpack" hasError={errors.lastName} disabled={isLoading} full />}
              />
            </FormGridColumn>
          </GridFormRow>

          <FormRow>
            <FormTitle scale={5} required>
              Your Mobile Number
            </FormTitle>
            <Controller
              name="mobileNumber"
              control={control}
              rules={{
                required: true,
                validate: (value) => {
                  const phoneNumber = parsePhoneNumber(value, country);
                  return phoneNumber?.isValid();
                },
              }}
              render={({ field }) => (
                <PhoneInput {...field} hasError={errors.mobileNumber} country={country} onCountryChange={setCountry} disabled={isLoading} />
              )}
            />
          </FormRow>
          <FormRow>
            <FormTitle scale={5} required>
              Work Email Address
            </FormTitle>
            <Controller
              name="email"
              control={control}
              rules={{ required: true, pattern: emailRegExp }}
              render={({ field }) => (
                <Input {...field} placeholder="joe@company.com" hasError={errors.email} autoComplete="off" disabled={isLoading} full />
              )}
            />
            {errors.email?.type === 'pattern' && <Error>Email format does not match</Error>}
          </FormRow>
          <FormRow>
            <FormTitle scale={5} tip={HINT.PASSWORD_PATTERN} required>
              Password
            </FormTitle>
            <Controller
              name="password"
              control={control}
              rules={{ required: true, pattern: passwordRegExp }}
              render={({ field }) => (
                /* Reference: solve auto fill problem https://stackoverflow.com/a/47557635 */
                <PasswordInput {...field} hasError={errors.password} autoComplete="off" disabled={isLoading} full />
              )}
            />
            {errors.password?.type === 'pattern' && <Error>{HINT.PASSWORD_PATTERN}</Error>}
          </FormRow>

          <FormRow>
            <Controller name="optInEmails" control={control} render={({ field }) => <Checkbox {...field} disabled={isLoading} />} />
            <CheckboxText>Send me offers and updates about Choco Up</CheckboxText>
          </FormRow>
          <FormRow>
            <Controller
              name="agreement"
              control={control}
              rules={{
                validate: (value) => value === true,
              }}
              render={({ field }) => <Checkbox {...field} disabled={isLoading} />}
            />
            <CheckboxText>
              I agree to Choco Up’s{' '}
              <TextLink href="https://insight.choco-up.com/terms-conditions" target="_blank">
                Terms of Use
              </TextLink>{' '}
              and{' '}
              <TextLink href="https://insight.choco-up.com/privacy-policy" target="_blank">
                Privacy Policy
              </TextLink>
              .
            </CheckboxText>
            {errors.agreement && <Error>You must agree to continue</Error>}
          </FormRow>
          {registerError && <Error>{registerError}</Error>}
          <FormRow>
            <Button type="submit" full>
              Create Account
            </Button>
          </FormRow>
          <CenterDiv>
            <SwitchForm target={PATH.LOGIN} />
          </CenterDiv>
        </Form>
      </StyledContentDiv>
    </UserFormContainer>
  );
};
export default InviteUserForm;
