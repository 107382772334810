import React from 'react';

import { Switch, Route, Redirect, generatePath } from 'react-router-dom';

import PATH from '../../../path';
import CompanyCardContainer from './CompanyCardContainer';
import Overview from './Overview';

const CreateCard = () => {
  return (
    <Switch>
      <Route path={PATH.WALLET_CARDS_CREATE_SELECT_TYPE} component={Overview} />
      <Route
        path={generatePath(PATH.WALLET_CARDS_CREATE_FORM, { type: 'company-card' })}
        component={CompanyCardContainer}
      />
      <Redirect to={PATH.WALLET_CARDS_CREATE_SELECT_TYPE} />
    </Switch>
  );
};

export default CreateCard;
