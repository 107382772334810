import styled, { css } from 'styled-components';

import COLORS from '../styles/colors';
import { isRealNumber } from '@utils/dataTypes';

const HR = styled.div`
  height: ${(props) => props.height || '1'}px;
  background-color: ${(props) => COLORS[props.color] || props.color || '#EBEBEB'};
  margin: 25px 0;

  ${(props) =>
    isRealNumber(props.margin) &&
    css`
      margin: ${props.margin}px 0;
    `}
`;

export default HR;
