import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Form, Title, ContentDiv, Tagline } from '../components/styled';
import { media } from '@styles/breakpoints';
import { loginUser } from '@redux/modules/auth/actions';
import Banner from '../components/Banner';
import { BASE_APPLICATION_PATH } from '@/basePath';

const SuccessPageTitle = styled(Title)`
  margin-bottom: 5px;
  ${media.phone`
   line-height: 30px;
  `};
`;

const SuccessPageContainer = styled(Container)`
  height: 100vh;
  ${media.phone`
    height: initial;
  `};
`;

const StyledContentDiv = styled(ContentDiv)`
  height: 50vh;
`;

const SuccessPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setTimeout(async () => {
      dispatch(loginUser());
      history.push(BASE_APPLICATION_PATH);
    }, 3000);
  });

  return (
    <>
      <Banner />
      <SuccessPageContainer>
        <StyledContentDiv>
          <Form>
            <SuccessPageTitle>Thank you. Your registration is successful.</SuccessPageTitle>
            <Tagline>You will be redirected to the dashboard in 3 seconds.</Tagline>
          </Form>
        </StyledContentDiv>
      </SuccessPageContainer>
    </>
  );
};

export default SuccessPage;
