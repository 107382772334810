import React, { useState } from 'react';

import { parse } from 'query-string';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../components/Button';
import FormTitle from '../../../components/FormTitle';
import PasswordInput from '../../../components/PasswordInput';
import HINT from '../../../utils/hint';
import Banner from '../components/Banner';
import { Form, FormRow, Tagline, Error, ContentDiv, Container, ChocoUpLogo, Title } from '../components/styled';
import PATH from '../path';
import { resetPassword } from '@api/modules/auth';
import { media } from '@styles/breakpoints';
import { passwordRegExp } from '@utils/validators';

const ResetPasswordContainer = styled(Container)`
  ${media.dablet`
    height: 100vh;
  `};
`;

const StyledContentDiv = styled(ContentDiv)`
  height: 50vh;
`;

const PasswordChangedContentDiv = styled.div`
  height: 35%;
  width: 25vw;
  ${media.dablet`
    width: 80vw;
  `};
`;

const ResetPassword = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const location = useLocation();
  const history = useHistory();
  const [didReset, setDidReset] = useState(false);
  const [apiError, setApiError] = useState();
  const [isLoading, toggleIsLoading] = useState(false);

  const onSubmit = async ({ password }) => {
    toggleIsLoading(true);
    setApiError('');
    const { token } = parse(location.search);
    if (!token) {
      setApiError('No token is provided');
      toggleIsLoading(false);
      return;
    }

    const { statusCode } = await resetPassword({
      token,
      password,
    });

    if (statusCode === 200) {
      setDidReset(true);
    } else {
      setApiError('We couldn’t find your email, please try again.');
    }

    toggleIsLoading(false);
  };

  const goToLoginPage = () => {
    history.push(PATH.LOGIN);
  };

  return (
    <>
      <Banner />
      <ResetPasswordContainer>
        {didReset ? (
          <PasswordChangedContentDiv>
              <ChocoUpLogo src="/images/choco-up-black-no-title.png" alt="Choco Up Logo" />
              <Title>Password Changed</Title>
              <Tagline>
                Your password has been changed successfully. You can now use your new password to login to your account.
              </Tagline>
              <Button onClick={goToLoginPage} full>
                Go Back
              </Button>
            </PasswordChangedContentDiv>
        ) : (
          <StyledContentDiv>
            <ChocoUpLogo src="/images/choco-up-black-no-title.png" alt="Choco Up Logo" />
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Title>Reset Password</Title>
              <Tagline>
                Please input your new password below. You old password will be invalid once reset. This reset session
                will expire 24 hours after you received the reset email.
              </Tagline>
              <FormRow>
                <FormTitle scale={5} tip={HINT.PASSWORD_PATTERN} required>
                  Enter New Password
                </FormTitle>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: true,
                    pattern: passwordRegExp,
                  }}
                  render={({ field }) => (
                    <PasswordInput {...field} hasError={errors.password} disabled={isLoading} full />
                  )}
                />
                {errors.password?.type === 'pattern' && <Error>{HINT.PASSWORD_PATTERN}</Error>}
              </FormRow>
              {apiError && <Error>{apiError}</Error>}
              <Button type="submit" disabled={isLoading} full>
                Reset
              </Button>
            </Form>
          </StyledContentDiv>
        )}
      </ResetPasswordContainer>
    </>
  );
};

export default ResetPassword;
