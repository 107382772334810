/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import Email from '../../../../components/Email';
import Header from '../../../../components/Header';
import HR from '../../../../components/HR';
import { currencyOptions as defaultCurrencyOptions } from '../../../../constants/options';
import Logger from '../../../../utils/logger';
import SalesFilesSection from '../../../Integration/SalesFilesSection';
import {
  MANUAL_INTEGRATION_MODAL,
  WOOCOMMERCE_INTEGRATION_MODAL,
  SHOPLAZZA_INTEGRATION_MODAL,
  SHOPIFY_INTEGRATION_MODAL,
  MAGENTO_INTEGRATION_MODAL,
  GA_TUTORIAL_MODAL,
  MARKETING_PLATFORM_INTEGRATION,
  MESSAGE_MODAL,
} from '../../../Modal/constants';
import SalesAccountsTable from '../../../Platform/SalesAccountsTable';
import BottomControl from '../../BottomControl';
import { Description } from '../../components';
import { checkSalesFormErrors } from '../../helpers';
import IncomeSection from './IncomeSection';
import SalesPlatformsSection from './SalesPlatformsSection';
import { integrateAccount, deleteIntegratedAccount } from '@api/modules/application';
import { INTEGRATION_STATUS } from '@/constants';
import { PLATFORM, MANUAL_INTEGRATION_PLATFORMS } from '@constants/platform';
import { salesPlatformsFilter, salesOthersPlatformsFilter } from '@utils/platformHelpers';
import { updateApplicationDraft, fetchIntegrations } from '@redux/modules/application/actions';
import { useCheckIsAdminViewingAnotherCompany, useCheckIsSignUpViaShopify } from '@redux/selectors';
import { showModal } from '@redux/modules/UI/actions';
import { findFirstMatchInArray } from '@utils/dataTypes';
import { SUPPORT_EMAIL } from '@constants/email';

export const deletePlatformModalDescription = (
  <Description>
    Your account is successfully connected and in progress of data integration. Disconnecting the account may interrupt the system
    operations. If you confirm to proceed, please contact <Email address={SUPPORT_EMAIL} />
  </Description>
);

const SalesAccountsForm = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user.profile);
  const applicationData = useSelector((state) => state.application.data);
  const inputCurrencyOptions = useSelector((state) => state.metaData.inputCurrencyCodes);
  const adminIsViewingAnotherCompany = useCheckIsAdminViewingAnotherCompany();
  const isSignUpViaShopify = useCheckIsSignUpViaShopify();
  const integrations = useSelector((state) => state.application.integrations);
  const salesAccounts = integrations.filter(({ platformName }) => salesPlatformsFilter(platformName));
  const salesOthersPlatform = integrations.filter(({ integrationType }) => salesOthersPlatformsFilter(integrationType));
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const GAAccounts = integrations.filter(({ platformName }) => platformName === PLATFORM.GOOGLE_ANALYTICS);

  const formattedInputCurrencyOptions =
    Array.isArray(inputCurrencyOptions) && inputCurrencyOptions.length > 0
      ? inputCurrencyOptions.map((value) => ({
          value,
          label: value,
        }))
      : [
          {
            value: applicationData.salesForm.currency,
            label: applicationData.salesForm.currency,
          },
        ];

  const showManualIntegrationModal = (platformName) => {
    dispatch(
      showModal(MANUAL_INTEGRATION_MODAL, {
        companyName: platformName,
      })
    );
  };

  const showWoocommerceIntegrationModal = () => {
    dispatch(showModal(WOOCOMMERCE_INTEGRATION_MODAL));
  };

  const showShoplazzaIntegrationModal = () => {
    dispatch(showModal(SHOPLAZZA_INTEGRATION_MODAL));
  };

  const afterSubmit = async () => {
    await fetchIntegrations();
  };

  const showGAIntegrationModal = (integrationId, accountId) => {
    dispatch(
      showModal(MARKETING_PLATFORM_INTEGRATION, {
        component: PLATFORM.GOOGLE_ANALYTICS,
        integrationId,
        accountId,
      })
    );
  };

  useEffect(() => {
    if (Array.isArray(GAAccounts) && GAAccounts.length > 0 && !adminIsViewingAnotherCompany) {
      const incompleteAccount = findFirstMatchInArray(GAAccounts, (account) => account.status === -2);
      if (incompleteAccount) {
        showGAIntegrationModal(incompleteAccount.id, incompleteAccount.accountId);
      }
    }
  }, [JSON.stringify(GAAccounts)]);

  useEffect(() => {
    fetchIntegrations();
  }, []);

  const registerConnection = (platformName, disabled) => async () => {
    if (disabled) {
      return;
    }

    if (!isLoading) {
      try {
        setIsLoading(true);
        if ([PLATFORM.STRIPE, PLATFORM.XERO, PLATFORM.SHOPEE, PLATFORM.LAZADA].includes(platformName)) {
          let newWin;
          if ([PLATFORM.SHOPEE, PLATFORM.LAZADA].includes(platformName)) {
            newWin = window.open('', 'newWin', 'width=1080,height=680');
          } else {
            newWin = window.open('', 'newWin', 'width=520,height=680');
          }
          const {
            data: { url },
          } = await integrateAccount(platformName);
          if (newWin && url) {
            newWin.location.href = url;
          }
        } else if (platformName === PLATFORM.SHOPIFY) {
          dispatch(showModal(SHOPIFY_INTEGRATION_MODAL, { afterSubmit }));
        } else if (platformName === PLATFORM.MAGENTO) {
          dispatch(showModal(MAGENTO_INTEGRATION_MODAL, { afterSubmit }));
        } else if (platformName === PLATFORM.WOOCOMMERCE) {
          showWoocommerceIntegrationModal();
        } else if (platformName === PLATFORM.SHOPLAZZA) {
          showShoplazzaIntegrationModal();
        } else if (MANUAL_INTEGRATION_PLATFORMS.indexOf(platformName) !== -1) {
          showManualIntegrationModal(platformName);
        } else if (platformName === PLATFORM.GOOGLE_ANALYTICS) {
          dispatch(showModal(GA_TUTORIAL_MODAL));
        }
      } catch (err) {
        Logger.error(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleInputChange = (name) => (value) => {
    const nextDraft = { ...applicationData };
    // TODO: extract and write unit test
    _.set(nextDraft, `salesForm.${name}`, value);
    dispatch(updateApplicationDraft(nextDraft));
  };

  const onDeletePlatformClick =
    ({ id, platformName, accountId, status }) =>
    async () => {
      if (MANUAL_INTEGRATION_PLATFORMS.indexOf(platformName) === -1 && status === INTEGRATION_STATUS.CONNECTED) {
        dispatch(
          showModal(MESSAGE_MODAL, {
            title: 'Disconnect account',
            description: deletePlatformModalDescription,
            showLeftButton: false,
            showRightButton: false,
            showCloseButton: true,
          })
        );
      } else {
        await deleteIntegratedAccount({
          platformName,
          integrationId: id,
          ...(platformName === PLATFORM.GOOGLE_ANALYTICS && { accountId }),
        });
        await fetchIntegrations();
      }
    };

  const checkForm = () => {
    let formErrors = {};
    if (!userProfile.applicationSubmitted) {
      formErrors = checkSalesFormErrors(
        salesAccounts,
        salesOthersPlatform,
        applicationData.salesForm.amount,
        applicationData.salesForm.currency
      );

      setErrors(formErrors);
      return formErrors.platformSection || formErrors.incomeSection;
    }

    return false;
  };

  const checkFormAfterHavingSalesOthersPlatform = () => {
    afterSubmit();
    setErrors({
      ...errors,
      platformSection: false,
    });
  };

  useEffect(() => {
    if (salesOthersPlatform.length > 0) {
      checkForm();
    }
  }, [JSON.stringify(salesOthersPlatform)]);

  return (
    <>
      <Header scale={2}>Connect your Sales Accounts</Header>
      <Description>
        {isSignUpViaShopify
          ? 'Our integration with your sales accounts help us predict your future sales.'
          : `Our integration with your sales accounts help us predict your future sales to ensure you get the best capital
        possible.`}
      </Description>
      {Array.isArray(salesAccounts) && salesAccounts.length > 0 && (
        <>
          <Header scale={3}>Connected Platforms</Header>
          <SalesAccountsTable
            accounts={salesAccounts}
            onDeletePlatformClick={onDeletePlatformClick}
            showGAIntegrationModal={showGAIntegrationModal}
            disabled={adminIsViewingAnotherCompany}
          />
          <HR />
        </>
      )}
      <SalesPlatformsSection
        salesAccounts={salesAccounts}
        onLogoClick={registerConnection}
        showError={errors.platformSection}
        disabled={adminIsViewingAnotherCompany}
        afterSubmit={checkFormAfterHavingSalesOthersPlatform}
      />
      <HR />
      <IncomeSection
        currency={applicationData.salesForm.currency === null ? defaultCurrencyOptions[2].value : applicationData.salesForm.currency}
        currencyOptions={formattedInputCurrencyOptions}
        onCurrencyChange={handleInputChange('currency')}
        amount={applicationData.salesForm.amount}
        onAmountChange={handleInputChange('amount')}
        showError={errors.incomeSection}
        disabled={userProfile.applicationSubmitted || adminIsViewingAnotherCompany}
      />
      <BottomControl nextStepValidator={checkForm} />
      {!isSignUpViaShopify && (
        <>
          <HR />
          <SalesFilesSection />
        </>
      )}
    </>
  );
};

export default SalesAccountsForm;
