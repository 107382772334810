import { LOGOUT_USER } from '../auth/constants';
import { SELECT_COMPANY_SUCCESS } from '../company/constants';
import {
  GET_WALLET_ACCOUNT_REQUEST,
  GET_WALLET_ACCOUNT_SUCCESS,
  GET_WALLET_ACCOUNT_FAILURE,
  GET_WALLET_TRANSACTIONS_REQUEST,
  GET_WALLET_TRANSACTIONS_SUCCESS,
  GET_WALLET_TRANSACTIONS_FAILURE,
  GET_GLOBAL_ACCOUNTS_REQUEST,
  GET_GLOBAL_ACCOUNTS_SUCCESS,
  GET_GLOBAL_ACCOUNTS_FAILURE,
  GET_WALLET_PAYOUTS_REQUEST,
  GET_WALLET_PAYOUTS_SUCCESS,
  GET_WALLET_PAYOUTS_FAILURE,
  GET_WALLET_PAYOUT_DETAIL_REQUEST,
  GET_WALLET_PAYOUT_DETAIL_SUCCESS,
  GET_WALLET_PAYOUT_DETAIL_FAILURE,
  GET_WALLET_BENEFICIARIES_REQUEST,
  GET_WALLET_BENEFICIARIES_SUCCESS,
  GET_WALLET_BENEFICIARIES_FAILURE,
  GET_WALLET_FORM_SCHEMA_REQUEST,
  GET_WALLET_FORM_SCHEMA_SUCCESS,
  GET_WALLET_FORM_SCHEMA_FAILURE,
  GET_GLOBAL_ACCOUNT_TRANSACTION_REQUEST,
  GET_GLOBAL_ACCOUNT_TRANSACTION_SUCCESS,
  GET_GLOBAL_ACCOUNT_TRANSACTION_FAILURE,
  GET_WALLET_CURRENCY_BALANCE_REQUEST,
  GET_WALLET_CURRENCY_BALANCE_SUCCESS,
  GET_WALLET_CURRENCY_BALANCE_FAILURE,
  GET_WALLET_TRANSFER_FEE_REQUEST,
  GET_WALLET_TRANSFER_FEE_SUCCESS,
  GET_WALLET_TRANSFER_FEE_FAILURE,
  GET_WALLET_FOREIGN_EXCHANGE_REQUEST,
  GET_WALLET_FOREIGN_EXCHANGE_SUCCESS,
  GET_WALLET_FOREIGN_EXCHANGE_FAILURE,
  GET_WALLET_CONVERSION_LIST_REQUEST,
  GET_WALLET_CONVERSION_LIST_SUCCESS,
  GET_WALLET_CONVERSION_LIST_FAILURE,
  GET_WALLET_CARDS_REQUEST,
  GET_WALLET_CARDS_SUCCESS,
  GET_WALLET_CARDS_FAILURE,
  UPDATE_WALLET_CARD_REQUEST,
  UPDATE_WALLET_CARD_SUCCESS,
  UPDATE_WALLET_CARD_FAILURE,
  GET_WALLET_CARD_TRANSACTIONS_REQUEST,
  GET_WALLET_CARD_TRANSACTIONS_SUCCESS,
  GET_WALLET_CARD_TRANSACTIONS_FAILURE,
  GET_WALLET_CARD_DETAIL_REQUEST,
  GET_WALLET_CARD_DETAIL_SUCCESS,
  GET_WALLET_CARD_DETAIL_FAILURE,
  GET_WALLET_ACCOUNT_SETTINGS_REQUEST,
  GET_WALLET_ACCOUNT_SETTINGS_SUCCESS,
  GET_WALLET_ACCOUNT_SETTINGS_FAILURE,
  UPDATE_WALLET_ACCOUNT_SETTINGS_REQUEST,
  UPDATE_WALLET_ACCOUNT_SETTINGS_SUCCESS,
  UPDATE_WALLET_ACCOUNT_SETTINGS_FAILURE,
  UPDATE_CARD_STATUS_REQUEST,
  UPDATE_CARD_STATUS_SUCCESS,
  UPDATE_CARD_STATUS_FAILURE,
} from './constants';

const initialState = {
  isInitiating: true,
  isLoading: false,
  walletAccounts: [],
  beneficiaryPageMap: {},
  currencyBalances: [],
  conversionPageMap: {
    1: [],
  },
  transactionPageMap: {
    1: [],
  },
  card: {
    detailMap: {},
    pageMap: {
      1: [],
    },
  },
  cardTransactionPageMap: {
    1: [],
  },
  foreignExchange: {},
  globalAccountsPageMap: {
    1: [],
  },
  globalAccountTransactionsPageMap: {
    1: [],
  },
  payoutPageMap: {
    1: [],
  },
  payoutDetail: {},
  transferFee: {},
  schema: {},
  settings: {},
};

const walletReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_WALLET_ACCOUNT_REQUEST:
    case GET_WALLET_TRANSACTIONS_REQUEST:
    case GET_GLOBAL_ACCOUNTS_REQUEST:
    case GET_WALLET_PAYOUTS_REQUEST:
    case GET_WALLET_PAYOUT_DETAIL_REQUEST:
    case GET_WALLET_BENEFICIARIES_REQUEST:
    case GET_WALLET_FORM_SCHEMA_REQUEST:
    case GET_GLOBAL_ACCOUNT_TRANSACTION_REQUEST:
    case GET_WALLET_CURRENCY_BALANCE_REQUEST:
    case GET_WALLET_TRANSFER_FEE_REQUEST:
    case GET_WALLET_FOREIGN_EXCHANGE_REQUEST:
    case GET_WALLET_CONVERSION_LIST_REQUEST:
    case GET_WALLET_CARDS_REQUEST:
    case UPDATE_WALLET_CARD_REQUEST:
    case GET_WALLET_CARD_TRANSACTIONS_REQUEST:
    case GET_WALLET_CARD_DETAIL_REQUEST:
    case GET_WALLET_ACCOUNT_SETTINGS_REQUEST:
    case UPDATE_WALLET_ACCOUNT_SETTINGS_REQUEST:
    case UPDATE_CARD_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_WALLET_ACCOUNT_SUCCESS:
      return {
        ...state,
        walletAccounts: action.payload,
        isInitiating: false,
        isLoading: false,
      };
    case GET_WALLET_FORM_SCHEMA_SUCCESS:
    case GET_WALLET_FORM_SCHEMA_FAILURE:
      return {
        ...state,
        schema: {
          ...state.schema,
          [action.payload.formName]: action.payload.data,
        },
        isLoading: false,
      };
    case GET_WALLET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionPageMap: {
          ...state.transactionPageMap,
          [action.payload.pageNumber]: action.payload.data,
        },
        isLoading: false,
      };
    case GET_WALLET_CONVERSION_LIST_SUCCESS:
      return {
        ...state,
        conversionPageMap: {
          ...state.conversionPageMap,
          [action.payload.pageNumber]: action.payload.data,
        },
        isLoading: false,
      };
    case GET_GLOBAL_ACCOUNTS_SUCCESS:
      return {
        ...state,
        globalAccountsPageMap: {
          ...state.globalAccountsPageMap,
          [action.payload.pageNumber]: action.payload.data,
        },
        isLoading: false,
      };
    case GET_WALLET_PAYOUTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        payoutPageMap: {
          ...state.payoutPageMap,
          [action.payload.pageNumber]: action.payload.data,
        },
      };
    case GET_WALLET_PAYOUT_DETAIL_SUCCESS:
      return {
        ...state,
        payoutDetail: action.payload,
        isLoading: false,
      };
    case GET_WALLET_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        beneficiaryPageMap: {
          ...state.beneficiaryPageMap,
          [action.payload.pageNumber]: action.payload.data,
        },
      };
    case GET_GLOBAL_ACCOUNT_TRANSACTION_SUCCESS:
      return {
        ...state,
        globalAccountTransactionsPageMap: {
          ...state.globalAccountTransactionsPageMap,
          [action.payload.pageNumber]: action.payload.data,
        },
        isLoading: false,
      };
    case GET_WALLET_CARD_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        cardTransactionPageMap: {
          ...state.cardTransactionPageMap,
          [action.payload.pageNumber]: action.payload.data,
        },
        isLoading: false,
      };
    }
    case GET_WALLET_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_WALLET_CURRENCY_BALANCE_SUCCESS:
      return {
        ...state,
        currencyBalances: action.payload,
        isLoading: false,
      };
    case GET_WALLET_TRANSFER_FEE_SUCCESS:
      return {
        ...state,
        transferFee: {
          ...state.transferFee,
          ...action.payload,
        },
        isLoading: false,
      };
    case GET_WALLET_FOREIGN_EXCHANGE_SUCCESS:
      return {
        ...state,
        foreignExchange: {
          ...state.foreignExchange,
          [`${action.payload.sell_currency}to${action.payload.buy_currency}`]: action.payload,
        },
        isLoading: false,
      };
    case GET_WALLET_CARDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        card: {
          ...state.card,
          pageMap: {
            ...state.card.pageMap,
            [action.payload.pageNumber]: action.payload.data,
          },
        },
      };
    case UPDATE_WALLET_CARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case GET_WALLET_CARD_DETAIL_SUCCESS:
      return {
        ...state,
        card: {
          ...state.card,
          detailMap: {
            ...state.card.detailMap,
            [action.payload.id]: action.payload.detail,
          },
        },
      };
    case UPDATE_WALLET_ACCOUNT_SETTINGS_SUCCESS:
    case GET_WALLET_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload,
        isLoading: false,
      };
    case UPDATE_CARD_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case GET_WALLET_CARDS_FAILURE:
    case GET_WALLET_CURRENCY_BALANCE_FAILURE:
    case GET_WALLET_TRANSACTIONS_FAILURE:
    case GET_GLOBAL_ACCOUNTS_FAILURE:
    case GET_WALLET_PAYOUTS_FAILURE:
    case GET_WALLET_BENEFICIARIES_FAILURE:
    case GET_GLOBAL_ACCOUNT_TRANSACTION_FAILURE:
    case GET_WALLET_TRANSFER_FEE_FAILURE:
    case GET_WALLET_CONVERSION_LIST_FAILURE:
    case UPDATE_WALLET_CARD_FAILURE:
    case GET_WALLET_CARD_TRANSACTIONS_FAILURE:
    case GET_WALLET_CARD_DETAIL_FAILURE:
    case GET_WALLET_ACCOUNT_SETTINGS_FAILURE:
    case UPDATE_WALLET_ACCOUNT_SETTINGS_FAILURE:
    case UPDATE_CARD_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isInitiating: false,
      };
    case LOGOUT_USER:
    case SELECT_COMPANY_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
export default walletReducer;
