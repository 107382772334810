import React, { useContext, useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useLocation, NavLink } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';

import Avatar, { AVATAR_SIZE } from '../../../components/Avatar';
import Select from '../../../components/Select';
import featureToggle from '../../../featureToggle';
import APPLICATION_PATH from '../../Application/path';
import PATH from '../path';
import { generateCompanyOptions } from './helpers';
import { STATUS_CODE } from '@constants/application';
import { PAYMENT_REGIONS, WALLET_ACCOUNT_STATUS } from '@/constants';
import { PLATFORM_DISPLAY_NAME } from '@constants/platform';
import { selectCompany } from '@redux/modules/company/actions';
import { useSelectedCompany, useIsAdminView, useCheckIsSignUpViaShopify } from '@redux/selectors';

const Wrapper = styled.div`
  float: left;
  height: 100vh;
  width: 260px;
  background-color: ${(props) => props.theme.colors.darkPurple};
  color: ${(props) => props.theme.colors.white};
  display: table;
`;

const Title = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  padding: 20px 40px 10px;
`;

const SubNavItem = styled.div`
  margin-left: 36px;
  ${(props) => props.theme.text.micro};
`;

const CompanyAvatar = styled.div`
  padding: 10px 40px;
  font-weight: bold;
  box-sizing: border-box;

  > img,
  > span {
    vertical-align: middle;
  }
  > img,
  > span,
  > div {
    :first-child {
      margin-right: 12px;
    }
  }

  .react-select__option {
    color: ${(props) => props.theme.colors.black};
    &.react-select__option--is-selected {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

const CompanyName = styled.span`
  width: 120px;
  display: inline-block;
  height: 50px;
  ${(props) => props.theme.ellipsis};

  span,
  svg {
    vertical-align: middle;
  }

  span {
    max-width: calc(100% - 30px);
    ${(props) => props.theme.ellipsis};
    padding-right: 10px;
    line-height: 50px;
  }

  svg {
    cursor: pointer;
  }
`;

const StyledNavLink = styled(NavLink)`
  &.active {
    background-color: rgba(255, 255, 255, 0.1);
  }

  display: block;
  padding: 10px 40px;
  font-weight: bold;

  > img,
  > span {
    vertical-align: middle;
  }

  > img,
  > span,
  > div {
    :first-child {
      margin-right: 12px;
    }
  }
`;

const SettingsWrapper = styled.div`
  display: table-row;
  vertical-align: bottom;
`;

const Settings = styled(StyledNavLink)``;

const StyledSelect = styled(Select)`
  vertical-align: middle;
  display: inline-block;
  width: 75%;
`;

const SideMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const company = useSelectedCompany();
  const paymentAccounts = useSelector((state) => state.payment.stripeAccounts);
  const walletAccounts = useSelector((state) => state.wallet.walletAccounts);
  const { data: companyMap } = useSelector((state) => state.company);
  const applicationData = useSelector((state) => state.application.data);
  const companyOptions = generateCompanyOptions(companyMap);
  const { colors } = useContext(ThemeContext);
  const isInAdminView = useIsAdminView();
  const isSignUpViaShopify = useCheckIsSignUpViaShopify();
  const [isStripeReady, toggleIsStripeReady] = useState(false);
  const [isWalletReady, toggleIsWalletReady] = useState(false);

  const shouldShowFundingNav =
    applicationData.statusCode === undefined /** no funding */ || applicationData.statusCode < STATUS_CODE.FUND_DISBURSED || true; // consult PM to determine final decision

  const handleSelectCompany = (id) => {
    selectCompany(id);
    history.replace(PATH.HOME);
  };

  useEffect(() => {
    if (paymentAccounts.length > 0 && paymentAccounts?.[0]?.charges_enabled === 1) {
      toggleIsStripeReady(true);
    } else {
      toggleIsStripeReady(false);
    }
  }, [JSON.stringify(paymentAccounts)]);

  useEffect(() => {
    if (
      walletAccounts?.[0]?.airwallex_account_id !== null &&
      walletAccounts?.[0]?.status === WALLET_ACCOUNT_STATUS.ACTIVE &&
      walletAccounts[0]?.airwallex_status !== null &&
      walletAccounts[0]?.airwallex_status !== 'CREATED' &&
      walletAccounts[0]?.airwallex_status !== 'SUBMITTED'
    ) {
      toggleIsWalletReady(true);
    } else {
      toggleIsWalletReady(false);
    }
  }, [JSON.stringify(walletAccounts)]);

  return (
    <Wrapper>
      <Title>Business Profile</Title>
      <Title>Tools</Title>
      <StyledNavLink to={PATH.HOME}>
        <img src="/icons/home.svg" alt="home" />
        <span>Home</span>
      </StyledNavLink>
      {!isSignUpViaShopify && shouldShowFundingNav && (
        <StyledNavLink to={APPLICATION_PATH.SALES_ACCOUNTS}>
          <img src="/icons/document.svg" alt="document" />
          <span>Application</span>
        </StyledNavLink>
      )}
      <StyledNavLink to={PATH.BUSINESS}>
        <img src="/icons/business.svg" alt="business" />
        <span>Business insight</span>
      </StyledNavLink>
      {location.pathname.indexOf(PATH.BUSINESS) !== -1 && (
        <>
          <StyledNavLink to={PATH.BUSINESS_SALES_REPORT}>
            <SubNavItem>Sales report</SubNavItem>
          </StyledNavLink>
          <StyledNavLink to={PATH.BUSINESS_BANK_TRANSACTIONS}>
            <SubNavItem>Bank transaction</SubNavItem>
          </StyledNavLink>
          {featureToggle.ACCOUNTING_REPORT && (
            <StyledNavLink to={PATH.BUSINESS_ACCOUNTING_REPORT}>
              <SubNavItem>Accounting report</SubNavItem>
            </StyledNavLink>
          )}
          <StyledNavLink to={PATH.BUSINESS_FB_PERFORMANCE}>
            <SubNavItem>{PLATFORM_DISPLAY_NAME.FACEBOOK_ADS}</SubNavItem>
          </StyledNavLink>
        </>
      )}
      <StyledNavLink to={PATH.INTEGRATION}>
        <img src="/icons/integration.svg" alt="integration" />
        <span>Integration</span>
      </StyledNavLink>
      {/* {isInAdminView && (
        <StyledNavLink to={PATH.CREDIT_RISK}>
          <img src="/icons/credit-risk.svg" alt="credit-risk" />
          <span>Credit Risk</span>
        </StyledNavLink>
      )} */}
      {/* <SettingsWrapper>
        <Settings to={PATH.SETTINGS_ACCOUNT_OVERVIEW}>
          <img src="/icons/settings.svg" alt="home" />
          <span>Settings</span>
        </Settings>
      </SettingsWrapper> */}
    </Wrapper>
  );
};

export default SideMenu;
