import React, { useState } from 'react';

import styled from 'styled-components';

import FormTitle from '../../../../components/FormTitle';
import Select from '../../../../components/Select';
import BusinessEntityForm from './BusinessEntityForm';
import BusinessOwnerForm from './BusinessOwnerForm';
import { FormRow, GridColumn } from '@components/Grid';

const options = [
  {
    value: 'PERSON',
    label: 'Add an individual',
  },
  {
    value: 'ENTITY',
    label: 'Add a business entity',
  },
];

const Header = styled.div`
  ${(props) => props.theme.text.two};
  font-weight: bold;
`;

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 20px;
  padding: 20px 50px 20px 25px;
`;

const KeyStakeholderContainer = () => {
  const [formType, setFormType] = useState('');
  return (
    <Wrapper>
      <Header>Add a Business Owner</Header>
      <FormRow>
        <GridColumn lg={8} md={8}>
          <FormTitle scale={5}>Ownership Type</FormTitle>
          <Select
            value={formType}
            options={options}
            onChange={setFormType}
            placeholder="Add an individual / Add a business entity"
          />
        </GridColumn>
        {/* <GridColumn lg={4} md={4} /> */}
      </FormRow>
      {formType === 'PERSON' && <BusinessOwnerForm />}
      {formType === 'ENTITY' && <BusinessEntityForm />}
    </Wrapper>
  );
};

export default KeyStakeholderContainer;
