import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import Header from '../../../../components/Header';
import Logger from '../../../../utils/logger';
import APPLICATION_PATH from '../../../Application/path';
import { Description } from "../../components";
import PATH from '../../path';
import CompanyInfoForm from './components/CompanyInfoForm';
import { updateCompany } from '@api/modules/dashboard/company';
import { uploadCompanyFiles, getIncorporationFiles } from '@api/modules/dashboard/file';
import { FormRow } from '@components/Grid';
import { COMPANY_FILE_TYPE } from '@constants/file';
import { useModule } from '@hooks/routing';
import { fetchCompany, fetchUserCompany } from '@redux/modules/company/actions';
import {
  generateUpdateCompanyPayload,
  shouldSameShippingAddressBeChecked,
  hasFileAttached,
} from '@redux/modules/company/helpers';
import {
  useSelectedCompany,
  useIsUnableToEditUnderAdminView,
  useCheckIsAdminViewingAnotherCompany,
  useCheckIsSignUpViaShopify,
} from '@redux/selectors';

const BusinessDetailForm = () => {
  const history = useHistory();
  const module = useModule();
  const company = useSelectedCompany();
  const unableToEditAnotherCompany = useIsUnableToEditUnderAdminView();
  const adminIsViewingAnotherCompany = useCheckIsAdminViewingAnotherCompany();
  const isSignUpViaShopify = useCheckIsSignUpViaShopify();

  const [isLoading, toggleIsLoading] = useState(false);
  const [apiError, setApiError] = useState();
  const [pendingFiles, setPendingFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const noFileAttached = !hasFileAttached(pendingFiles, uploadedFiles);
  const form = useForm({
    defaultValues: {
      ...company,
      isShippingSameAddress: shouldSameShippingAddressBeChecked(company.shippingAddress),
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
    getValues,
  } = form;

  const fetchIncorporationFiles = async () => {
    const { data } = await getIncorporationFiles();

    if (Array.isArray(data)) {
      setUploadedFiles(data);
    }
  };

  useEffect(() => {
    fetchIncorporationFiles();
  }, []);

  const onSubmit = async (formData) => {
    try {
      if (noFileAttached) {
        return;
      }
      toggleIsLoading(true);
      setApiError('');
      const { data, error: updateCompanyError, statusCode } = await updateCompany(generateUpdateCompanyPayload(formData));

      if (statusCode !== 200) {
        throw new Error('Cannot update company');
      }

      if (Array.isArray(pendingFiles) && pendingFiles.length > 0) {
        await Promise.all(pendingFiles.map((file) => uploadCompanyFiles(COMPANY_FILE_TYPE.INCORPORATION, [file])));
        await fetchIncorporationFiles();
      }

      history.push(module === 'dashboard' ? PATH.SETTINGS_COMPANY_OVERVIEW : APPLICATION_PATH.COMPANY);

      if (adminIsViewingAnotherCompany) {
        await fetchCompany(company.id);
      } else {
        fetchUserCompany();
      }
    } catch (err) {
      setApiError(err.message);
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const handleCancel = () => {
    history.push(module === 'dashboard' ? PATH.SETTINGS_COMPANY_OVERVIEW : APPLICATION_PATH.COMPANY);
  };

  const onDrop = (acceptedFiles) => {
    setPendingFiles(acceptedFiles);
  };

  return (
    <>
      <FormRow>
        <Header scale={2}>Tell us about your business</Header>
      </FormRow>
      <FormRow>
        {isSignUpViaShopify ? (
          <Description>Please fill up the form with accurate information about your business.</Description>
        ) : (
          <Description>
            We require your business information to best prepare your funding offer and agreement. Please fill up the form with accurate
            information about your business.
          </Description>
        )}
        <FormRow>
          <Header scale={3}>Key Business Information</Header>
        </FormRow>
        <CompanyInfoForm
          form={form}
          uploadedFiles={uploadedFiles}
          onSubmit={onSubmit}
          disabled={unableToEditAnotherCompany || isLoading}
          isLoading={isLoading}
          onDropFile={onDrop}
          apiError={apiError}
          noFileAttached={noFileAttached}
          onCancel={handleCancel}
        />
      </FormRow>
    </>
  );
};

export default BusinessDetailForm;
