export const toUpperCase = (value) => {
  return typeof value === 'string' ? value.toUpperCase() : '';
};

const capitalizeEachWord = (word) => {
  if (typeof word === 'string' && word.length > 0) {
    return toUpperCase(word[0]) + word.slice(1).toLowerCase();
  }

  return '';
};

const defaultParams = { titleCase: true, delimiter: ' ' };

export const capitalize = (word, params) => {
  const combinedParams = {
    ...defaultParams,
    ...params,
  };
  if (typeof word === 'string' && word.length > 0) {
    if (combinedParams.titleCase) {
      const words = word.split(combinedParams.delimiter);
      const capitalized = words.map((w) => capitalizeEachWord(w));
      return capitalized.join(combinedParams.delimiter);
    } else {
      return capitalizeEachWord(word);
    }
  }

  return '';
};

export const replaceDelimiter = (string = '', delimiter = '', targetDelimiter = '') => {
  const regex = new RegExp(delimiter, 'gi');
  return string.replace(regex, targetDelimiter);
};

export const padStart = (string = '', targetLength, padString = '•') => {
  if (typeof string === 'string' && typeof string.padStart === 'function') {
    const remainingPart = string.slice(-targetLength);
    return remainingPart.padStart(string.length, padString);
  }

  return string;
};

export const includes = (a, b = '') => {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.toLowerCase().indexOf(b.toLowerCase()) !== -1;
  }

  return false;
};
