import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import CheckBox, { CheckboxText } from '../../../../components/Checkbox';
import { Description } from '../../../Application/components';
import { MANUAL_INTEGRATION_FORM } from '../../../Modal/constants';
import { GridColumn } from '@components/Grid';
import { DD_EMAIL } from '@constants/email';
import { PLATFORM, PLATFORM_DISPLAY_NAME } from '@constants/platform';
import { showModal, hideModal } from '@redux/modules/UI/actions';

const Title = styled.div`
 ${(props) => props.theme.text.two};
 margin: 10px 0 20px 0;
`;
const InstructionTitle = styled.div`
 ${(props) => props.theme.text.three};
 font-weight: bold;
 margin: 20px 0;
`;

const InstructionsImage = styled.img`
 width: 75%;
 height: auto;
 margin-bottom: 20px;
`;

const Airwallex = () => {
  const dispatch = useDispatch();
  const [isChecked, toggleIsChecked] = useState(false);

  const handleIntegrationClick = () => {
    dispatch(showModal(MANUAL_INTEGRATION_FORM, {
      platformName: PLATFORM.AIRWALLEX,
    }));
  };
  return (
    <>
      <Title>
        Connect
        {' '}
        {PLATFORM_DISPLAY_NAME.AIRWALLEX}
        {' '}
        Account
      </Title>
      <Description>Please follow below steps to add access to your account</Description>
      <InstructionTitle>Step 1</InstructionTitle>
      <Description>
        Click
        {' '}
        <b>Account </b>
        {'>'}
        <b> User Management </b>
        {'>'}
        <b> Invite user</b>
      </Description>
      <InstructionsImage src="https://asset.choco-up.com/tutorial/airwallex/Airwallex-1.png" alt="Connect Airwallex Account" />
      <InstructionTitle>Step 2</InstructionTitle>
      <Description>
        Add
        {' '}
        <b>{DD_EMAIL}</b>
        {' '}
        to email field and set nickname field as
        {' '}
        <b>Choco Up</b>
        {' '}
        then click
        {' '}
        <b>Next</b>
      </Description>
      <InstructionsImage src="https://asset.choco-up.com/tutorial/airwallex/Airwallex-2.png" alt="Connect Airwallex Account" />
      <InstructionTitle>Step 3</InstructionTitle>
      <Description>
        Select our role as
        {' '}
        <b>View only</b>
        .
        {' '}
        This will give Choco Up view only access to your account.
        click
        {' '}
        <b>Send Invitation</b>
      </Description>
      <InstructionsImage src="https://asset.choco-up.com/tutorial/airwallex/Airwallex-5.png" alt="Connect Airwallex Account" />
      <InstructionTitle>Step 4</InstructionTitle>
      <Description>
        Click
        {' '}
        <b>Done</b>
        {' '}
        and we will receive a confirmation email.
      </Description>
      <InstructionsImage src="https://asset.choco-up.com/tutorial/airwallex/Airwallex-4.png" alt="Connect Airwallex Account" />
      <GridColumn lg={12} md={12}>
        <CheckBox onClick={() => toggleIsChecked(!isChecked)} />
        <CheckboxText>
          Confirm I have connected with my
          {' '}
          {PLATFORM_DISPLAY_NAME.AIRWALLEX}
          {' '}
          account
        </CheckboxText>
      </GridColumn>
      <GridColumn lg={6} md={6}>
        <Button onClick={handleIntegrationClick} disabled={!isChecked} primary full>Next</Button>
      </GridColumn>
      <GridColumn lg={6} md={6}>
        <Button onClick={() => dispatch(hideModal())} tertiary full>Cancel</Button>
      </GridColumn>
    </>
  );
};

export default Airwallex;
