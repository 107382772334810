import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../../../components/Button';
import LoadingCircle from '../../../../../components/LoadingCircle';
import Table from '../../../../../components/Table/index';
import Logger from '../../../../../utils/logger';
import PATH from '../../../path';
import { Wrapper } from '../../../components';
import { WALLET_COUNTRIES } from '../../constants';
import { getCompanyId } from '@api/cookies';
import { getWalletTotalBalance } from '@api/modules/integration';
import { ADD_FUNDS_MODAL } from '@modules/Modal/constants';
import { showModal } from '@redux/modules/UI/actions';
import { fetchWalletCurrentBalance } from '@redux/modules/wallet/actions';
import { useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';
import { media } from '@styles/breakpoints';
import { DATE_AND_TIME_FORMAT } from '@utils/dateHelpers';
import { formatNumber, formatPrice } from '@utils/priceHelpers';

const Description = styled.span`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  font-weight: 600;
  margin-bottom: 6px;
`;

const BalanceAmount = styled.div`
  ${(props) => props.theme.text.four};
  font-weight: 600;
  margin: 12px 0;
  flex-grow: 2;
`;

const Container = styled.div`
  display: flex;
  margin-bottom: 28px;

  ${media.dablet`
    flex-direction: column;
  `};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;

  ${media.dablet`
    display: flex;
    gap: 5px;
    justify-content: start;
  `};
`;

/* Hide for now use later */
// const DownloadLink = styled.div`
//   ${(props) => props.theme.text.link};
// `;

const FlagWrapper = styled.div`
  display: flex;
  padding: 3px;
`;

const CurrencyWrapper = styled.div`
  padding-left: 10px;
`;

const BalanceTitle = styled.td`
  font-weight: 600;
  padding: 16px;
`;

const ActiveAccountTitle = styled.td`
  font-weight: 600;
  color: ${(props) => props.theme.colors.green};
`;

const DropDownButton = styled(Button)`
  background: none;
  border: none;
  margin-top: 20px;
`;

const CurrencyHeader = styled.div`
  font-weight: 600;
`;

const WalletBalance = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const companyId = getCompanyId();
  const countryOrigin = useSelector((state) => state.company?.data[companyId]?.countryOrigin);
  const walletAccounts = useSelector((state) => state.wallet.walletAccounts);
  const [isLoading, toggleIsLoading] = useState(false);
  const isBalanceLoading = useSelector((state) => state.wallet.isLoading);
  const currencyBalances = useSelector((state) => state.wallet.currencyBalances);
  const [totalBalance, setTotalBalance] = useState([]);
  const [balanceCurrency, setBalanceCurrency] = useState();
  const disabled = useCheckIsAdminViewingAnotherCompany();
  const currentDate = moment().format(DATE_AND_TIME_FORMAT);
  const sortedCurrencyBalances = [...currencyBalances].sort((prev, curr) => prev.currency.localeCompare(curr.currency));

  const balancesTableColumn = [
    {
      Header: 'Currency',
      accessor: 'currency',
      Cell: ({ value }) => {
        return (
          <FlagWrapper>
            <img src={WALLET_COUNTRIES[value]?.logoUrl} alt="country flags" />
            <CurrencyWrapper>
              <CurrencyHeader>{value}</CurrencyHeader>
              {WALLET_COUNTRIES[value]?.displayName}
            </CurrencyWrapper>
          </FlagWrapper>
        );
      },
    },
    {
      Header: 'Global accounts',
      accessor: 'global_accounts',
      Cell: ({ value }) => {
        const activeAccounts = Array.isArray(value) ? value.filter(({ status }) => status === 'ACTIVE') : [];
        return activeAccounts.length > 0 ? <ActiveAccountTitle>{activeAccounts.length} active</ActiveAccountTitle> : null;
      },
    },
    {
      Header: 'Available Amount',
      accessor: 'available_amount',
      Cell: ({ value }) => {
        return formatNumber(value, 2);
      },
    },
  ];

  const fetchTotalBalance = async () => {
    try {
      toggleIsLoading(true);
      const { data, error } = await getWalletTotalBalance(walletAccounts?.[0]?.account_id);
      if (data) {
        setTotalBalance(data.balance);
        setBalanceCurrency(data.currency);
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const handleAddFunds = () => {
    dispatch(
      showModal(ADD_FUNDS_MODAL, {
        countryOrigin,
        id: walletAccounts?.[0]?.account_id,
      })
    );
  };

  useEffect(() => {
    fetchWalletCurrentBalance(walletAccounts?.[0]?.account_id);
    fetchTotalBalance();
  }, [JSON.stringify(walletAccounts)]);

  /* Hide for now use later */
  // const handleDownload = () => {
  //   dispatch(showModal(BALANCE_REPORT_MODAL));
  // };

  const handleAccountClick = (account) => {
    history.push(`${PATH.WALLET_BALANCES}/${account.currency}`);
  };

  const showAddTransferModal = () => {
    history.push(PATH.WALLET_TRANSFER_CREATE_PAYOUTS);
  };

  const createNewConversion = () => {
    history.push(PATH.WALLET_CONVERSION_CREATE_CONVERSION);
  };

  return isLoading && !(Array.isArray(currencyBalances) && currencyBalances.length > 0) ? (
    <LoadingCircle />
  ) : (
    <Wrapper>
      <Description>
        Your total balance estimate in {balanceCurrency} at {currentDate}
      </Description>
      <Container>
        <BalanceAmount>{formatPrice(balanceCurrency, totalBalance, false, 2)}</BalanceAmount>
        <ButtonWrapper>
          {/* Hide for now use later */}
          {/* <DownloadLink onClick={handleDownload}>Download</DownloadLink> */}
          <Button onClick={handleAddFunds} disabled={disabled} secondary>
            Add Funds
          </Button>
          <Button onClick={createNewConversion} disabled={disabled} secondary>
            Convert
          </Button>
          <Button onClick={showAddTransferModal} disabled={disabled}>
            Send Money
          </Button>
        </ButtonWrapper>
      </Container>
      <Table columns={balancesTableColumn} data={sortedCurrencyBalances} onRowClick={handleAccountClick} isLoading={isBalanceLoading} />
    </Wrapper>
  );
};

export default WalletBalance;
