import React from 'react';

import styled, { css } from 'styled-components';

const StyledTextarea = styled.textarea`
  border-radius: 5px;
  padding: 12px 16px;
  line-height: 24px;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  resize: vertical;
  box-sizing: border-box;
  ${(props) => props.theme.input.border};

  :focus-visible {
    ${(props) => props.theme.input.borderFocus};
    outline: black; // workaround for now
  }

  ${(props) =>
    props.hasError &&
    css`
      ${props.theme.input.borderError};
    `};

  ${(props) =>
    props.full &&
    css`
      width: 100%;
    `};
`;

const Textarea = ({
  value, onChange, hasError, maxLength = 2000, ...restProps
}) => {
  return <StyledTextarea value={value} onChange={onChange} hasError={hasError} maxLength={maxLength} {...restProps} />;
};

export default Textarea;
