import React, { useRef } from 'react';

import { useDispatch } from 'react-redux';
import styled, { keyframes, css } from 'styled-components';

import { useOnClickOutside } from '@hooks/UI';
import { hideModal } from '@redux/modules/UI/actions';

export const slideUp = keyframes`
    0% { margin-top: 100%; }
    100% { margin-top: 0; }
`;

export const fadeInPulse = keyframes`
    0% { opacity: 0; transform: scale(0.8) translateY(8px); }
    50% { opacity: 1; }
    70% { transform: scale(1) translateY(0); }
`;

const CloseModalButton = styled.img`
  display: flex;
  position: absolute;
  right: 20px;
  top: 20px;

  ${(props) =>
    (props.canHideModal
      ? css`
          cursor: pointer;
        `
      : css`
          cursor: not-allowed;
        `)}
`;

export const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
  }

  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  height: 100%; /* Fallback if vh is not valid in legacy browser */
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 0;
  -webkit-overflow-scrolling: touch;
`;

export const Content = styled.div`
  padding: 48px;
  transform-origin: bottom center;
  animation: ${(props) => (props.animation === 'slideUp' ? slideUp : fadeInPulse)} 0.27s forwards ease;
  width: 100%;
  position: fixed;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  max-width: 740px;
  max-height: 90%;
  overflow: auto;
  ${(props) =>
    props.width &&
    css`
      max-width: ${props.width}px;
    `}

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    animation: none;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const Modal = ({ width, children, hideModalOnClickOutside = false, showCloseButton = true, canHideModal = true }) => {
  const ref = useRef();
  const dispatch = useDispatch();

  useOnClickOutside(ref, () => {
    if (hideModalOnClickOutside) {
      dispatch(hideModal());
    }
  });

  const handleCloseClick = () => {
    if (canHideModal) {
      dispatch(hideModal());
    }
  };

  return (
    <Overlay>
      <Content ref={ref} width={width}>
        {showCloseButton && (
          <CloseModalButton
            onClick={handleCloseClick}
            canHideModal={canHideModal}
            src="/icons/close-icon.svg"
            alt="close-icon"
          />
        )}
        {children}
      </Content>
    </Overlay>
  );
};

export default Modal;
