import React from 'react';

import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';

import PATH from '../path';
import Overview from './Overview';
import RevenueStreamDetail from './RevenueStreamDetail';
import RevenueStreamForm from './RevenueStreamForm';

const Header = styled.div`
  ${(props) => props.theme.text.two};
  margin-bottom: 20px;
`;

const TermSheetContainer = () => {
  return (
    <div>
      <Header>Create Term Sheet</Header>
      <Switch>
        <Route exact path={PATH.VIEW_REVENUE_STREAM} component={RevenueStreamDetail} />
        <Route exact path={PATH.SELECT_REVENUE_STREAM} component={RevenueStreamForm} />
        <Route exact path={PATH.TERM_SHEET} component={Overview} />
      </Switch>
    </div>
  );
};

export default TermSheetContainer;
