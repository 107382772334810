import React from 'react';

import styled from 'styled-components';

import HR from '../../components/HR';
import Icon from '../../components/Icon';
import { FormRow, GridColumn } from '@components/Grid';

const Table = styled.div`
  width: 80%;
  min-width: 400px;
  max-width: 800px;
`;

const Title = styled(GridColumn)`
  font-weight: bold;
`;

const DataColumn = styled(GridColumn)`
  ${(props) => props.theme.ellipsis};
`;

const StyledIcon = styled(Icon)`
  margin-right: 12px;
  vertical-align: middle;
`;

const SmallFileTable = ({ files = {} }) => {
  return (
    <Table>
      <FormRow>
        <Title lg={8} md={8} sm={6}>
          NAME
        </Title>
        <Title lg={4} md={4} sm={6}>
          ACTION
        </Title>
      </FormRow>
      <HR margin={4} />
      {files.map((file, index) => {
        return (
          <FormRow>
            <DataColumn lg={8} md={8} sm={6}>
              {file.file_name}
            </DataColumn>
            <DataColumn lg={4} md={4} sm={6}>
              <a href={file.s3DownloadUrl}>
                <StyledIcon src="/icons/download.svg" />
              </a>
              <a href={file.s3OpenUrl} target="_blank" rel="noreferrer">
                <StyledIcon src="/icons/inspect_file.svg" />
              </a>
            </DataColumn>
          </FormRow>
        );
      })}
    </Table>
  );
};

export default SmallFileTable;
