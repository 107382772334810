import Logger from '../../../../utils/logger';
import store from '../../../store';
import {
  GET_SUPPORTED_FINVERSE_INSTITUTIONS_REQUEST,
  GET_SUPPORTED_FINVERSE_INSTITUTIONS_SUCCESS,
  GET_SUPPORTED_FINVERSE_INSTITUTIONS_FAILURE,
  GET_INTEGRATED_FINVERSE_INSTITUTIONS_REQUEST,
  GET_INTEGRATED_FINVERSE_INSTITUTIONS_SUCCESS,
  GET_INTEGRATED_FINVERSE_INSTITUTIONS_FAILURE,
} from './constants';
import {
  getIntegratedFinverseInstitutions,
  getSupportedFinverseInstitutions,
} from '@api/modules/integration/finverse';

export const fetchIntegratedFinverseInstitutions = async () => {
  store.dispatch({ type: GET_INTEGRATED_FINVERSE_INSTITUTIONS_REQUEST });
  try {
    const { data, error } = await getIntegratedFinverseInstitutions();

    if (Array.isArray(data)) {
      store.dispatch({
        type: GET_INTEGRATED_FINVERSE_INSTITUTIONS_SUCCESS,
        payload: data,
      });
    } else {
      throw new Error('Data from getIntegratedFinverseInstitutions is not array');
    }
  } catch (err) {
    store.dispatch({ type: GET_INTEGRATED_FINVERSE_INSTITUTIONS_FAILURE, error: err?.message });
    Logger.error(err);
  }
};

export const fetchSupportedFinverseInstitutions = async () => {
  store.dispatch({ type: GET_SUPPORTED_FINVERSE_INSTITUTIONS_REQUEST });
  try {
    const { data } = await getSupportedFinverseInstitutions();

    if (Array.isArray(data)) {
      store.dispatch({
        type: GET_SUPPORTED_FINVERSE_INSTITUTIONS_SUCCESS,
        payload: data,
      });
    } else {
      throw new Error('Data from getSupportedFinverseInstitutions is not array');
    }
  } catch (err) {
    store.dispatch({ type: GET_SUPPORTED_FINVERSE_INSTITUTIONS_FAILURE, error: err?.message });
    Logger.error(err);
  }
};
