import React from 'react';

import _ from 'lodash';
import styled, { css } from 'styled-components';

const Radio = styled.input`
  vertical-align: top;
  cursor: pointer;
  margin-right: 16px;
  margin-top: 8px;
  transform: scale(1.5);
`;

const Body = styled.div`
  margin-left: 34px;

  .radio-description {
    ${(props) => props.theme.text.micro};
  }
`;

const Wrapper = styled.div`
  ${(props) =>
    (props.disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `)}
`;

const RadioWithText = ({
  checked,
  label,
  description,
  value,
  children,
  onClick = () => {},
  disabled,
  ...restProps
}) => {
  const handleClick = () => {
    if (!disabled) {
      onClick(value);
    }
  };
  return (
    <Wrapper key={value} onClick={handleClick} disabled={disabled} {...restProps}>
      <Radio type="radio" checked={checked} disabled={disabled} />
      <span>{label}</span>
      <Body>
        <div className="radio-description">{description}</div>
        {children}
      </Body>
    </Wrapper>
  );
};

export default RadioWithText;
