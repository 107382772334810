import { isRealNumber } from './dataTypes';

// ref: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
// logic is reusable for formatting file size and monetary amount so it is extracted into here
export const formatNumberWithUnit = (number, decimalScale, sizeScale, units, delimiter = '') => {
  if (!isRealNumber(number) || number === 0) return '';
  const decimal = decimalScale < 0 ? 0 : decimalScale;
  const i = Math.floor(Math.log(number) / Math.log(sizeScale));

  return `${parseFloat((number / (sizeScale ** i)).toFixed(decimal))}${delimiter}${units[i]}`;
};
