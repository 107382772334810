import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../../components/Button';
import Email from '../../../../../components/Email';
import HR from '../../../../../components/HR';
import RadioWithText from '../../../../../components/RadioWithText';
import Toggle from '../../../../../components/Toggle';
import { Card } from '../../../components';
import { globalAccountsOptions, WALLET_COUNTRIES } from '../../constants';
import { SUPPORT_EMAIL } from '@constants/email';
import { MESSAGE_MODAL } from '@modules/Modal/constants';
import { showModal } from '@redux/modules/UI/actions';
import { fetchAccountSettings, updateAccountSettings } from '@redux/modules/wallet/actions';
import { useWalletAccount, useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';
import { convertToBooleanNumeral, convertToBoolean } from '@utils/dataTypes';
import { useCheckRoleHasAllPermissions } from '@utils/userHelpers';

const Header = styled.div`
  font-weight: bold;
  ${(props) => props.theme.text.body};
  margin-bottom: 16px;
`;

const StyledRadioWithText = styled(RadioWithText)`
  margin: 9px 0;
`;

const ContentWrapper = styled.div`
  margin: 16px;
`;

const FlagWrapper = styled.span`
  display: inline;
  * ~ * {
    margin-left: 8px;
  }

  > * {
    vertical-align: middle;
  }

  .currencyCode {
    font-weight: 700;
    color: ${(props) => props.theme.colors.grayThree};
  }

  .currencyName {
    font-weight: 600;
    color: ${(props) => props.theme.colors.grayThree};
  }
`;

const HomeCurrencyLabel = styled.span`
  ${(props) => props.theme.iconPlusText};
  color: ${(props) => props.theme.colors.purple};
  font-weight: 700;
`;

const Description = styled.div`
  ${(props) => props.theme.text.five};
`;

const Link = styled.a`
  ${(props) => props.theme.text.link};
`;

const ButtonWrapper = styled.div`
  ${(props) => props.theme.buttonWrapper};
`;

const Settings = () => {
  const wallet = useWalletAccount() || { currency: 'HKD' };
  const dispatch = useDispatch();
  const canUpdateSettings = useCheckRoleHasAllPermissions(['edit_wallet_card_settings']);
  const { settings, isLoading } = useSelector((state) => state.wallet);
  const { reset, setValue, watch } = useForm({
    defaultValues: {
      currency: wallet.currency,
      autoConversion: true,
    },
  });

  const watchOfCurrency = watch('currency');
  const watchOfAutoConversion = watch('autoConversion');
  const isAdminViewingAnotherCompany = useCheckIsAdminViewingAnotherCompany();

  const handleCurrencyClick = (value) => {
    setValue('currency', value);
  };

  const handleAutoConversionClick = (value) => {
    setValue('autoConversion', value);
  };

  const requestToChange = () => {
    dispatch(
      showModal(MESSAGE_MODAL, {
        title: 'Request to change setting',
        description: (
          <div>
            Should you want to update the settings, please contact us via <Email address={SUPPORT_EMAIL} />
          </div>
        ),
        showLeftButton: false,
        onConfirm: () => {},
        rightButtonProps: {
          label: 'OK',
          secondary: true,
        },
      })
    );
  };

  const updateSettings = async () => {
    await updateAccountSettings(wallet.account_id, {
      card_currency: watchOfCurrency,
      card_auto_conversion: convertToBooleanNumeral(watchOfAutoConversion),
    });
  };

  useEffect(() => {
    if (settings.card_currency && typeof settings.card_auto_conversion === 'boolean') {
      reset({
        currency: settings.card_currency,
        autoConversion: convertToBoolean(settings.card_auto_conversion),
      });
    }
  }, [JSON.stringify(settings)]);

  useEffect(() => {
    if (wallet?.account_id) {
      fetchAccountSettings(wallet?.account_id);
    }
  }, [JSON.stringify(wallet)]);

  return (
    <div>
      <Card>
        <Header>Funding</Header>
        <HR margin={10} />
        <ContentWrapper>
          <Description>
            Card transactions can only be funded using one of the ten currencies listed below. If spending in an alternative currency (not
            listed below), your selected primary currency will be used.
          </Description>
          <HR margin={10} />
          <Header>Select home currency</Header>
          {globalAccountsOptions.map(({ value, label }) => {
            return (
              <StyledRadioWithText
                key={value}
                value={value}
                checked={value === watchOfCurrency}
                disabled={!canUpdateSettings}
                onClick={handleCurrencyClick}
                label={
                  <FlagWrapper>
                    <img src={WALLET_COUNTRIES[value]?.logoUrl} alt={WALLET_COUNTRIES[value]?.displayName} />
                    <span className="currencyCode">{value}</span>
                    <span className="currencyName">{label}</span>
                    {/* {wallet.currency === value && <HomeCurrencyLabel>Home Currency</HomeCurrencyLabel>} */}
                  </FlagWrapper>
                }
              />
            );
          })}
          <HR margin={10} />
          <Header>Automatic conversions</Header>
          <Toggle checked={watchOfAutoConversion} onChange={handleAutoConversionClick} disabled={!canUpdateSettings} />
          <Description>
            With automatic-conversions on, we’ll use your home currency to fund the entire transaction (at your current rates) when you have
            insufficient funds in one of the 10 currencies above.
            <br />
            <br />
            We will first check if funds are available in your purchase currency&apos;s balance. If not, we will check whether your home
            currency&apos;s balance can cover the entire purchase amount. If it can, we will deduct it from your home currency balance at
            the prevailing FX rate. Please note that we will use funds available in one currency only.
            <br />
            <br />
            <Link href="https://help.airwallex.com/hc/en-gb/articles/900003301566-Auto-Conversions-FAQ" target="_blank">
              Learn more
            </Link>
          </Description>
          <ButtonWrapper>
            {canUpdateSettings && (
              <Button onClick={updateSettings} disabled={isLoading}>
                Update
              </Button>
            )}
            {!isAdminViewingAnotherCompany && <Button onClick={requestToChange}>Request to change</Button>}
          </ButtonWrapper>
        </ContentWrapper>
      </Card>
    </div>
  );
};

export default Settings;
