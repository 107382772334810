import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import FancyDateRangePicker from '../../../../../components/FancyDateRangePicker';
import SimplePagination from '../../../../../components/Pagination/SimplePagination';
import SearchBar from '../../../../../components/SearchBar';
import Table from "../../../../../components/Table";
import Logger from '../../../../../utils/logger';
import { Wrapper } from '../../../components';
import { globalAccountsStatusOptions, OPTION_VALUE_ALL } from '../../constants';
import { getColorByGlobalAccountTransactionStatus } from '../../helpers';
import { FilterLabel, StyledSelect } from '../components';
import { usePagination } from '@hooks/UI';
import { fetchGlobalAccountTransactions } from '@redux/modules/wallet/actions';
import { media } from '@styles/breakpoints';
import { convertToApiDate, convertToFullDate } from '@utils/dateHelpers';
import { formatNumber } from '@utils/priceHelpers';
import { areRecordsFewerThanPageSize } from '@utils/utilHelpers';

const Description = styled.span`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  font-weight: 600;
  margin-top: 6px;
`;

const Title = styled.div`
  ${(props) => props.theme.text.four};
  font-weight: 600;
`;

const Container = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;

  ${media.dablet`
    flex-direction: column;
  `};
`;

const FilterSection = styled.span`
  display: flex;
  margin: 26px 0;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 20px;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AccountActivity = () => {
  const dispatch = useDispatch();
  const { pageNumber, pageSize, pageCount, onPageNumberChange, onPageSizeChange, changePageCount } = usePagination({
    pageNumber: 1,
    pageCount: 0,
    pageSize: 20,
  });
  const walletAccounts = useSelector((state) => state.wallet.walletAccounts);
  const globalAccountsTransactions = useSelector((state) => state.wallet.globalAccountTransactionsPageMap);
  const data = globalAccountsTransactions[pageNumber];
  const isLoading = useSelector((state) => state.wallet.isLoading);
  const [statusFilter, setStatusFilter] = useState(OPTION_VALUE_ALL);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchResult, setSearchResult] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateShortcutValue, setDateShortValue] = useState();

  const applyCalendar = (calendarData) => {
    setStartDate(calendarData.startDate);
    setEndDate(calendarData.endDate);
    setDateShortValue(calendarData.dateShortcutSelectValue);
  };

  const formatPageNavLabel = (number) => {
    return ` ${number} `;
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchResult(searchKeyword), 1000);
    return () => clearTimeout(timeOutId);
  }, [searchKeyword]);

  const noNextPage = areRecordsFewerThanPageSize(globalAccountsTransactions[pageNumber], pageSize);

  const globalAccountsColumns = [
    {
      Header: 'Date',
      accessor: 'create_time',
      Cell: ({ value }) => {
        return convertToFullDate(value);
      },
    },
    {
      Header: 'status',
      accessor: 'status',
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      getStyle: ({ value }) => {
        return css`
          color: ${getColorByGlobalAccountTransactionStatus(value)};
        `;
      },
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
    },
    {
      Header: 'Details',
      accessor: 'description',
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
    },
    {
      Header: 'Name',
      accessor: 'nick_name',
      Cell: ({ value }) => {
        return value;
      },
    },
    {
      Header: 'Account Number',
      accessor: 'account_number',
      Cell: ({ value }) => {
        return value;
      },
    },
    {
      Header: 'Currency',
      accessor: 'currency',
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      getStyle: () => {
        return css`
          width: 150px;
        `;
      },
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      className: 'textRight',
      Cell: ({ value }) => {
        return formatNumber(value, 2);
      },
      getStyle: () => {
        return css`
          width: 100px;
        `;
      },
    },
  ];

  const fetchTransactions = async () => {
    try {
      const filters = [];
      if (statusFilter !== OPTION_VALUE_ALL) {
        filters.push({ field: 'status', operator: 'in', value: statusFilter.map(({ value }) => value) });
      }

      if (searchResult.length > 1) {
        filters.push({
          fields: ['account_number'],
          operator: 'like',
          value: [searchResult],
        });
      }

      // TODO: end date quick fix for now need to update in future
      fetchGlobalAccountTransactions(walletAccounts[0]?.account_id, 'all', {
        ...(startDate && { startDate: convertToApiDate(startDate) }),
        ...(endDate && { endDate: convertToApiDate(moment(endDate).add(1, 'days')) }),
        pageNumber,
        pageSize,
        filters,
      });
    } catch (err) {
      Logger.error(err);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [statusFilter, startDate, endDate, searchResult]);

  return (
    <Wrapper>
      <Container>
        <div>
          <Title>Account activity</Title>
          <Description>Transaction activities in all your global accounts</Description>
        </div>
      </Container>
      <FilterSection>
        <SearchBar
          width="450px"
          value={searchKeyword}
          onChange={(event) => setSearchKeyword(event.target.value)}
          placeholder="Search account number"
        />
        <SelectWrapper>
          <FilterLabel>Status</FilterLabel>
          <StyledSelect
            isMulti
            width={200}
            value={statusFilter}
            options={globalAccountsStatusOptions}
            onChange={setStatusFilter}
            multiValueLabel="Statuses"
          />
        </SelectWrapper>
        <SelectWrapper>
          <FancyDateRangePicker
            startDate={startDate}
            endDate={endDate}
            dateShortcutValue={dateShortcutValue}
            onDateRangeSelectValueChange={setDateShortValue}
            onApply={applyCalendar}
          />
        </SelectWrapper>
      </FilterSection>
      <Table columns={globalAccountsColumns} data={data} isLoading={isLoading} />
      <SimplePagination
        pageNumber={pageNumber}
        pageSize={pageSize}
        pageSizeOptions={[
          {
            value: 20,
            label: 20,
          },
          {
            value: 50,
            label: 50,
          },
          {
            value: 100,
            label: 100,
          },
        ]}
        onPageNumberChange={onPageNumberChange}
        onPageSizeChange={onPageSizeChange}
        formatPageNavLabel={formatPageNavLabel}
        disabled={isLoading}
        isLoading={isLoading}
        noNextPage={noNextPage}
      />
    </Wrapper>
  );
};

export default AccountActivity;
