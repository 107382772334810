import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../../../components/Button';
import FilterTag from '../../../../../components/FilterTag';
import Select from '../../../../../components/Select';
import PlatformFilter from '../../../components/PlatformFilter';
import {
  VIEW_ALL_OPTION,
  TAB,
  CONTENT_CONTAINER_ID,
  ENABLED_SALES_PLATFORM_OPTIONS,
  ENABLED_PAYMENT_PLATFORM_OPTIONS,
  ENABLED_ANALYTICS_PLATFORM_OPTIONS,
} from '../../../constants';
import PATH from '../../../path';
import { getIntegratedPlatforms } from '../../helpers';
import { GridColumn } from '@components/Grid';
import { INTEGRATION_TYPE } from '@constants/integration';
import { currencyOptions } from '@constants/options';
import { PLATFORM, PLATFORM_MAP, PLATFORM_DISPLAY_NAME } from '@constants/platform';
import { isBBMSLIntegrated, getAccountDisplayName } from '@utils/platformHelpers';

const FilterRow = styled.div`
  padding: 12px 12px 0 0;
  > div {
    vertical-align: middle;
    margin: 0 8px;
  }
`;

const StyledSelect = styled(Select)`
  width: 90px;
  float: right;
`;

const ConnectAccountDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ConnectNowCard = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px 20px;
  border-radius: 5px;
  margin: 24px 0;
`;

const ConnectCardTitle = styled.div`
  ${(props) => props.theme.text.four};
  font-weight: bold;
  padding-top: 10px;
  margin-bottom: 5px;
`;

const Filters = ({
  currency,
  onCurrencyChange,
  category,
  onCategoryChange,
  platform,
  onPlatformChange,
  tabOptions,
  connectedChannelAccounts,
}) => {
  const history = useHistory();
  const integrations = useSelector((state) => state.application.integrations);

  const handleNoDataButtonClick = () => {
    history.push(PATH.INTEGRATION);
    document.getElementById(CONTENT_CONTAINER_ID)?.scroll(0, 0);
  };

  const getPaymentOptions = () => {
    return [VIEW_ALL_OPTION, ...ENABLED_PAYMENT_PLATFORM_OPTIONS].filter((option) =>
      (isBBMSLIntegrated(integrations) ? true : option.value !== PLATFORM.BBMSL),
    );
  };

  const OPTIONS = {
    [TAB.SALES]: [VIEW_ALL_OPTION, ...ENABLED_SALES_PLATFORM_OPTIONS],
    [TAB.PAYMENT]: getPaymentOptions(),
    [TAB.ANALYTICS]: ENABLED_ANALYTICS_PLATFORM_OPTIONS,
  };

  const subTabOptions = OPTIONS[category];

  const platforms = subTabOptions.map((account) => {
    return account.platformName;
  });

  const getConnectedPlatformOptions = () => {
    return [
      {
        ...VIEW_ALL_OPTION,
        label: 'Total',
      },
      ...integrations
        .filter((integration) => {
          return platforms.indexOf(integration.platformName) !== -1 && integration.status === 1;
        })
        .map((account) => {
          return {
            platformName: account.platformName,
            value: account.id,
            label: `${
              category === TAB.PAYMENT && account.platformName === PLATFORM.SHOPIFY
                ? PLATFORM_DISPLAY_NAME.SHOPIFY_PAYMENT
                : PLATFORM_MAP[account.platformName]?.displayName
            } (${getAccountDisplayName(account)})`,
          };
        }),
    ];
  };

  const connectedPlatformOptions = getConnectedPlatformOptions();

  return (
    <>
      <FilterRow>
        <FilterTag canRemove={false}>Last 12 Months</FilterTag>
        <StyledSelect options={currencyOptions} value={currency} onChange={onCurrencyChange} />
      </FilterRow>
      {connectedChannelAccounts.length === 0 ? (
        <ConnectNowCard>
          <ConnectAccountDiv>
            <ConnectCardTitle>No connected channel</ConnectCardTitle>
            <Button secondary onClick={handleNoDataButtonClick}>
              Connect now
            </Button>
          </ConnectAccountDiv>
        </ConnectNowCard>
      ) : (
        <FilterRow>
          <PlatformFilter
            tabOptions={tabOptions}
            channelIntegrations={integrations}
            subTabOptions={connectedPlatformOptions}
            category={category}
            onCategoryChange={onCategoryChange}
            selectedChannelTab={platform}
            onPlatformChange={onPlatformChange}
          />
        </FilterRow>
      )}
    </>
  );
};

export default Filters;
