import React, { useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';

import Accordion from '../../components/Accordion';
import Button from '../../components/Button';
import FormTitle from '../../components/FormTitle';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import messages from './messages';
import { API_MAGENTO_INTEGRATION } from '@api/constants';
import { FormRow, GridColumn } from '@components/Grid';
import { useUserCompanyId } from '@redux/selectors';
import { urlValidatorRegExp } from '@utils/validators';

const InputWrapper = styled.div`
  margin-bottom: 5px;
`;

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.error};
  ${(props) => props.theme.text.micro};
`;

const MagentoIntegrationModal = ({ afterSubmit = () => {}, hideModal = () => {} }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      storeUrl: 'https://',
    },
  });

  const [isLoading, toggleIsLoading] = useState();
  const [isStoreUrlInUse, toggleIsStoreUrlInUse] = useState();
  const userCompanyId = useUserCompanyId();

  const onSubmit = async (formData) => {
    toggleIsLoading(true);
    toggleIsStoreUrlInUse(false);

    const { storeUrl, accessToken } = formData;

    const { data, error } = await fetch(`${API_MAGENTO_INTEGRATION}/v1/auth`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        store_url: storeUrl,
        access_token: accessToken,
        company_id: userCompanyId,
      }),
    })
      .then((response) => {
        if (response.status === 400) {
          // There are probably missing fields
          throw new Error('Make sure there are no missing fields.');
        } else if (response.status === 403) {
          toggleIsStoreUrlInUse(true);
          throw new Error('Store URL has already been used.');
        } else if (response.status === 500) {
          throw new Error('Internal server error');
        } else if (response.status !== 200) {
          throw new Error('Unknown error');
        }
        return response.json();
      })
      .then((res) => {
        return {
          data: res,
          error: undefined,
        };
      })
      .catch((e) => {
        return {
          data: undefined,
          error: e.toString(),
        };
      });

    if (error) {
      //
    } else {
      afterSubmit();
      hideModal();
    }

    toggleIsLoading(false);
  };

  return (
    <Modal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTitle scale={3}>Connect your Magento store</FormTitle>
        <InputWrapper>
          <Controller
            name="storeUrl"
            control={control}
            rules={{ required: true, pattern: urlValidatorRegExp }}
            render={({ field }) => <Input {...field} placeholder="Store URL" hasError={errors.storeUrl} full />}
          />
        </InputWrapper>
        {errors.storeUrl && <ErrorMessage>Please enter a valid Magento store url</ErrorMessage>}
        {isStoreUrlInUse && <ErrorMessage>Magento store url is already in use</ErrorMessage>}
        <InputWrapper>
          <Controller
            name="accessToken"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <Input {...field} placeholder="Access token" hasError={errors.accessToken} full />}
          />
        </InputWrapper>
        {errors.accessToken && <ErrorMessage>Please enter a valid Magento access token</ErrorMessage>}
        <Accordion title={messages.magentoGuide.title} contents={messages.magentoGuide.contents} />
        <FormRow>
          <GridColumn lg={6} md={6}>
            <Button type="submit" disabled={isLoading} primary full>
              Confirm
            </Button>
          </GridColumn>
          <GridColumn lg={6} md={6}>
            <Button onClick={hideModal} tertiary full>
              Cancel
            </Button>
          </GridColumn>
        </FormRow>
      </form>
    </Modal>
  );
};

export default MagentoIntegrationModal;
