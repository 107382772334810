import styled, { css } from 'styled-components';

const ActiveStyle = css`
  background-color: ${(props) => props.theme.colors.purple};
  color: ${(props) => props.theme.colors.white};
  font-weight: 600;
  border-radius: 20px;
`;

export const SolidTabStyle = css`
  margin: 0 16px 10px 6px;
  display: inline-block;
  padding: 8px 16px;
  cursor: pointer;

  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  border: 1px solid ${(props) => props.theme.colors.grayTwo};
  border-radius: 20px;
  ${(props) =>
    props.selected &&
    css`
      ${ActiveStyle}
    `}

  &.active {
    ${ActiveStyle};
  }
`;

const SolidTab = styled.span`
  ${SolidTabStyle};
`;

export default SolidTab;
