export const GET_PAYMENTS_INTENTS_REQUEST = 'GET_PAYMENTS_INTENTS_REQUEST';
export const GET_PAYMENTS_INTENTS_SUCCESS = 'GET_PAYMENTS_INTENTS_SUCCESS';
export const GET_PAYMENTS_INTENTS_FAILURE = 'GET_PAYMENTS_INTENTS_FAILURE';
export const GET_PAYMENT_INTENT_DETAIL_REQUEST = 'GET_PAYMENT_INTENT_DETAIL_REQUEST';
export const GET_PAYMENT_INTENT_DETAIL_SUCCESS = 'GET_PAYMENT_INTENT_DETAIL_SUCCESS';
export const GET_PAYMENT_INTENT_DETAIL_FAILURE = 'GET_PAYMENT_INTENT_DETAIL_FAILURE';
export const GET_PAYOUTS_REQUEST = 'GET_PAYOUTS_REQUEST';
export const GET_PAYOUTS_SUCCESS = 'GET_PAYOUTS_SUCCESS';
export const GET_PAYOUTS_FAILURE = 'GET_PAYOUTS_FAILURE';
export const GET_STRIPE_TRANSACTIONS_REQUEST = 'GET_STRIPE_TRANSACTIONS_REQUEST';
export const GET_STRIPE_TRANSACTIONS_SUCCESS = 'GET_STRIPE_TRANSACTIONS_SUCCESS';
export const GET_STRIPE_TRANSACTIONS_FAILURE = 'GET_STRIPE_TRANSACTIONS_FAILURE';
export const GET_STRIPE_ACCOUNT_REQUEST = 'GET_STRIPE_ACCOUNT_REQUEST';
export const GET_STRIPE_ACCOUNT_SUCCESS = 'GET_STRIPE_ACCOUNT_SUCCESS';
export const GET_STRIPE_ACCOUNT_FAILURE = 'GET_STRIPE_ACCOUNT_FAILURE';
