import { stringify } from 'query-string';

import { API_INTEGRATION_PORT, API_APPLICATION_PORT, REACT_APP_API_CAL_ENGINE } from '@api/constants';
import { getCompanyId } from '@api/cookies';
import { getRequest, postRequest, putRequest, deleteRequest } from '@api/requests';

const fundingEndpoint = `${REACT_APP_API_CAL_ENGINE}/v1/fundings`;

export const getFundingBalanceTransactions = async (query = {}) => {
  const companyId = getCompanyId();
  const data = await fetch(
    `${fundingEndpoint}/balance_transactions/search?${stringify({
      ...query,
      company_id: companyId,
      expand: true,
      sort: 'date_created:desc',
    })}`,
    {
      method: 'GET',
      mode: 'cors',
    },
  ).then((response) => {
    return response.json();
  });
  return { data };
};

export const getFundingBalanceSummary = async (payload) => {
  const result = await fetch(`${fundingEndpoint}/funding_balances/search?${stringify(payload)}`, {
    method: 'GET',
    mode: 'cors',
  }).then((response) => {
    return response.json();
  });

  return result;
};
