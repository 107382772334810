/* eslint-disable no-case-declarations */
import { LOGIN_USER, LOGOUT_USER } from '../auth/constants';
import { SELECT_COMPANY_REQUEST } from '../company/constants';
import { SWITCH_TO_ADMIN_VIEW, SWITCH_TO_USER_VIEW } from '../UI/constants';
import {
  UPDATE_APPLICATION_DRAFT,
  GET_APPLICATION_REQUEST,
  GET_APPLICATION_SUCCESS,
  GET_APPLICATION_FAILURE,
  GET_INTEGRATIONS_REQUEST,
  GET_INTEGRATIONS_SUCCESS,
  GET_INTEGRATIONS_FAILURE,
} from './constants';
import { Currency } from '@/constants';

export const initialState = {
  id: '',
  data: {
    salesForm: {
      amount: null,
      currency: Currency.USD,
      noPlatform: false,
    },
    offerForm: {
      fund: null,
      preliminaryOffer: {},
    },
    statusCode: 0,
  },
  integrations: [],
  isLoading: false,
};

const applicationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_APPLICATION_DRAFT:
      return {
        ...state,
        data: {
          ...state.data,
          salesForm: {
            ...state.data.salesForm,
            ...action.payload.salesForm,
          },
          offerForm: {
            ...state.data.offerForm,
            ...action.payload.offerForm,
          },
        },
      };
    case GET_APPLICATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_APPLICATION_FAILURE:
      return {
        ...initialState,
        integrations: state.integrations,
      };
    case GET_APPLICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: action.payload.status,
      };
    case GET_INTEGRATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_INTEGRATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        integrations: action.payload,
      };
    // case SWITCH_TO_ADMIN_VIEW:
    // case SELECT_COMPANY_REQUEST:
    case SWITCH_TO_USER_VIEW:
    case LOGIN_USER:
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default applicationReducer;
