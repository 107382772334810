import React from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../../../components/Button';
import { Card } from '../../../components';
import PATH from '../../../path';
import MultiFactorCard from "../2FA";
import InvitedUsersList from './InvitedUsersList';
import { useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';
import { media } from '@styles/breakpoints';

const Header = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: bold;
  margin: 10px 0;
`;

const CardTitle = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
  margin-bottom: 8px;
`;

const Container = styled.div`
  display: flex;

  ${media.desktop`
    flex-direction: column;
  `};
`;

const Description = styled.span`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  margin: 12px 0;
  flex-grow: 1;
`;

const ButtonWrapper = styled.div`
  flex-grow: 0;
`;
const Overview = () => {
  const history = useHistory();
  const users = useSelector((state) => state.user.users);

  // sort email into alphabetical order in table
  const sortedUsers = [...users].sort((a, b) => a.email.localeCompare(b.email));
  const isAdminView = useCheckIsAdminViewingAnotherCompany();

  const goToInviteUserForm = () => {
    history.push(PATH.SETTINGS_USER_MANAGEMENT_FORM);
  };

  return (
    <>
      <Header>User management</Header>
      <MultiFactorCard users={users} />
      <Card>
        <CardTitle>User Management</CardTitle>
        <Container>
          <Description>Invite users within your organization</Description>
          <ButtonWrapper>
            <Button secondary onClick={goToInviteUserForm} disabled={isAdminView}>
              Invite User
            </Button>
          </ButtonWrapper>
        </Container>
      </Card>
      <Header>User Records</Header>
      {Array.isArray(users) && <InvitedUsersList data={sortedUsers} />}
    </>
  );
};

export default Overview;
