import { useEffect } from 'react';

import { BroadcastChannel } from 'broadcast-channel';

export const useIntegrationChannel = (value, callback) => {
  let channel = value;
  useEffect(() => {
    if (!channel) {
      channel = new BroadcastChannel('integration');
      channel.onmessage = (msg) => {
        if (typeof callback === 'function') {
          callback();
        }
      };
    }
    return () => {
      // clean up
      channel?.close();
    };
  });
};
