import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import UnderlineTab from './UnderlineTab';

const Wrapper = styled.div`
  margin-bottom: 16px;
  ${(props) => props.theme.text.three};
`;

const Tabs = ({ options = [], onChange, isTabSelected, ...restProps }) => {
  const history = useHistory();
  const location = useLocation();

  const handleTabChange = (value) => () => {
    if (typeof onChange === 'function') {
      onChange(value);
    } else {
      history.push(value);
    }
  };

  const isSelected = (value) => {
    if (typeof isTabSelected === 'function') {
      return isTabSelected(value);
    } else {
      return location.pathname.indexOf(value) !== -1;
    }
  };

  return Array.isArray(options) ? (
    <Wrapper {...restProps}>
      {options.map(({ value, label }) => {
        return (
          <UnderlineTab key={value} selected={isSelected(value)} onClick={handleTabChange(value)}>
            {label}
          </UnderlineTab>
        );
      })}
    </Wrapper>
  ) : null;
};

export default Tabs;
