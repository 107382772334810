import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import ToolTip from '../../../../../../components/ToolTip';
import Logger from '../../../../../../utils/logger';
import { formatBankName } from '../../helpers';
import { submitSettlementById, confirmSettlementById } from '@api/modules/dashboard/settlement';
import { PLATFORM } from '@constants/platform';
import { REVENUE_RECORD_STATUS_LABEL, REVENUE_RECORD_STATUS_VALUE } from '@constants/remittance';
import { REVENUE_ADJUSTMENT_MODAL, XERO_SETTLEMENT_MODAL } from '@modules/Modal/constants';
import { fetchSettlements } from '@redux/modules/settlements/actions';
import { showModal } from '@redux/modules/UI/actions';
import { useIsAdminView } from '@redux/selectors';
import { formatPrice, formatNumber, alwaysPositiveNumber } from '@utils/priceHelpers';
import { replaceDelimiter, capitalize } from '@utils/stringHelpers';
import { useCanAdminHandleSettlement } from '@utils/userHelpers';

const Table = styled.table`
  width: 100%;
`;

const Cell = styled.td`
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  padding: 0 8px;
  ${(props) => props.theme.ellipsis};
  ${(props) => props.theme.text.micro};
`;

const AccountCell = styled(Cell)`
  width: 22.5%;
`;

const NameCell = styled(Cell)`
  width: 22.5%;
`;

const TransactionCell = styled(Cell)`
  width: 10%;
`;

const TotalSpentCell = styled(Cell)`
  width: 12.5%;
`;

const TotalReceivedCell = styled(Cell)`
  width: 12.5%;
`;

const StatusCell = styled(Cell)`
  width: 20%;

  ${(props) =>
    props.status === REVENUE_RECORD_STATUS_VALUE.CONFIRMED &&
    css`
      color: ${props.theme.colors.green};
    `};

  ${(props) =>
    props.status === REVENUE_RECORD_STATUS_VALUE.APPEALING &&
    css`
      color: ${props.theme.colors.red};
    `};
`;

const rowCss = css`
  font-weight: 600;
  margin-bottom: 2px;
  padding: 0 8px;
  :hover {
    background-color: ${(props) => props.theme.colors.grayOne};
  }
`;

const InfoWrapper = styled(Cell)`
  width: calc(100% - 120px);
  padding: 0;
  line-height: 49px;
`;

const TRow = styled.tr`
  ${rowCss};

  line-height: 40px;
  background-color: ${(props) => props.theme.colors.white};

  ${InfoWrapper} {
    cursor: pointer;
  }
`;

const CTAButton = styled(Cell)`
  width: 120px;
  padding: 0;
  text-align: center;

  ${(props) => props.theme.text.link};
  ${(props) => props.theme.text.button};

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const SubmittedText = styled(CTAButton)`
  color: ${(props) => props.theme.colors.grayTwo};
  ${(props) => props.theme.text.button};
`;

const THeader = styled.thead`
  ${rowCss};
  color: ${(props) => props.theme.colors.grayThree};
  font-weight: 600;
  line-height: 20px;

  ${InfoWrapper} {
    line-height: 20px;
  }
`;

const ActionButton = ({ settlementId, status, viewRecord }) => {
  const isAdmin = useIsAdminView();
  const adminCanHandleSettlement = useCanAdminHandleSettlement();

  const submitRevenueRecord = async (event) => {
    try {
      if (adminCanHandleSettlement) {
        const { statusCode } = await submitSettlementById(settlementId);
        if (statusCode === 204) {
          await fetchSettlements(isAdmin);
        }
      }
    } catch (err) {
      Logger.error(err);
    }
  };

  const confirmRevenueRecord = async (event) => {
    try {
      const { statusCode } = await confirmSettlementById(settlementId);
      if (statusCode === 204) {
        await fetchSettlements(isAdmin);
      }
    } catch (err) {
      Logger.error(err);
    }
  };

  switch (status) {
    case REVENUE_RECORD_STATUS_VALUE.DRAFT:
      return (
        <CTAButton onClick={submitRevenueRecord} disabled={!adminCanHandleSettlement}>
          Submit
        </CTAButton>
      );
    case REVENUE_RECORD_STATUS_VALUE.SUBMITTED:
      if (isAdmin) {
        return <SubmittedText>Submitted</SubmittedText>;
      } else {
        return <CTAButton onClick={confirmRevenueRecord}>Confirm</CTAButton>;
      }
    case REVENUE_RECORD_STATUS_VALUE.CONFIRMED:
    case REVENUE_RECORD_STATUS_VALUE.APPEALING:
      return <CTAButton onClick={viewRecord}>View</CTAButton>;
    default:
      return null;
  }
};

const SettlementTable = ({ items = [] }) => {
  const dispatch = useDispatch();
  const bankAccounts = useSelector((state) => state.bank.allBankAccounts);

  const getAmounts = (type, received, spent, adjustmentAmount) => {
    let spentAmount = spent;
    let receivedAmount = received;
    const isManual = type === 'MANUAL';
    if (isManual) {
      spentAmount = 0;
      receivedAmount = 0;

      if (adjustmentAmount > 0) {
        receivedAmount = alwaysPositiveNumber(adjustmentAmount);
      } else {
        spentAmount = alwaysPositiveNumber(adjustmentAmount);
      }
    }

    return { spentAmount, receivedAmount };
  };

  const viewRecord = (settlement) => (event) => {
    if (settlement.type === 'MANUAL') {
      dispatch(
        showModal(REVENUE_ADJUSTMENT_MODAL, {
          settlement,
        })
      );
    } else {
      dispatch(
        showModal(XERO_SETTLEMENT_MODAL, {
          selectedIds: settlement.xero_transaction_ids ?? [],
          settlement,
        })
      );
    }
  };

  const getAccountName = ({ account_id, source }) => {
    const bankAccountIds = bankAccounts.map(({ channelBankId, id }) => (source === PLATFORM.XERO ? channelBankId : id));
    const accountIndex = bankAccountIds.indexOf(account_id);
    const account = accountIndex !== -1 ? bankAccounts[accountIndex] : {};
    const bankAccountName = formatBankName(account);
    return bankAccountName;
  };

  return Array.isArray(items) && items.length > 0 ? (
    <Table>
      <THeader>
        <InfoWrapper>
          <AccountCell>Bank A/C</AccountCell>
          <NameCell>Record name</NameCell>
          <TransactionCell>#Transaction</TransactionCell>
          <TotalSpentCell>Total debit</TotalSpentCell>
          <TotalReceivedCell>Total credit</TotalReceivedCell>
          <StatusCell>Status</StatusCell>
        </InfoWrapper>
        <CTAButton />
      </THeader>
      <tbody>
        {items.map((settlement) => {
          const {
            settlement_id,
            source,
            name,
            status,
            received,
            spent,
            currency,
            type,
            adjustment_amount,
            xero_transaction_ids = [],
          } = settlement;
          const bankAccountName = getAccountName(settlement);
          const { spentAmount, receivedAmount } = getAmounts(type, received, spent, adjustment_amount);
          return (
            <TRow key={settlement_id}>
              <InfoWrapper onClick={viewRecord(settlement)}>
                <ToolTip tip={bankAccountName} placeholder={<AccountCell>{bankAccountName}</AccountCell>} />
                <ToolTip tip={name} placeholder={<NameCell>{name}</NameCell>} />
                <TransactionCell>{source === PLATFORM.XERO ? formatNumber(xero_transaction_ids?.length) : '-'}</TransactionCell>
                <TotalSpentCell>{spentAmount ? formatPrice(currency, spentAmount, false, 2) : '-'}</TotalSpentCell>
                <TotalReceivedCell>{receivedAmount ? formatPrice(currency, receivedAmount, false, 2) : '-'}</TotalReceivedCell>
                <StatusCell status={status}>
                  {REVENUE_RECORD_STATUS_LABEL[status] ||
                    capitalize(replaceDelimiter(status, '_', ' '), {
                      titleCase: false,
                    })}
                </StatusCell>
              </InfoWrapper>
              <ActionButton status={status} settlementId={settlement_id} viewRecord={viewRecord(settlement)} />
            </TRow>
          );
        })}
      </tbody>
    </Table>
  ) : null;
};

export default SettlementTable;
