import axios from 'axios';

import { API_BASE_URL } from '@api/constants';
import { integrationEndpoint } from '@api/modules/integration';
import { getWalletGlobalAccountStatement } from '@api/modules/integration/airwallex';

export const getGlobalAccountStatmentUrl = async (
  account_id: string,
  globalAccountId: string,
  accountType: 'GENERAL' | 'AMAZON' | 'EBAY',
  payload = {}
) => {
  switch (accountType) {
    case 'AMAZON':
      break;
    case 'EBAY':
      // TODO: handle AMAZON and EBAY account statement
      try {
        await getWalletGlobalAccountStatement(account_id, globalAccountId, accountType);
      } catch (e) {
        console.log(e);
      }
      break;
    case 'GENERAL':
      break;
    default:
      return axios.post(
        `${API_BASE_URL}${integrationEndpoint}/wallets/${account_id}/global_accounts/${globalAccountId}/statement/${accountType}`,
        payload,
        {
          withCredentials: true,
          responseType: 'blob',
        }
      );
  }
};
