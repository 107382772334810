import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import PATH from '../path';
import Balances from './Balances';
import Overview from './Overview';
import PaymentSetup from './Overview/Onboarding/PaymentSetup';
import Payments from './Payments';
import Transactions from './Transactions';

const Container = styled.div`
  .table-column-amount,
  .table-column-fee,
  .table-column-net,
  .table-column-processing_fee {
    text-align: right;
  }
`;

const Payment = () => {
  const paymentAccounts = useSelector((state) => state.payment.stripeAccounts);
  const [isStripeReady, toggleIsStripeReady] = useState(false);

  useEffect(() => {
    if (paymentAccounts.length > 0 && paymentAccounts?.[0]?.charges_enabled === 1) {
      toggleIsStripeReady(true);
    }
  }, [JSON.stringify(paymentAccounts)]);

  return (
    <Container>
      <Switch>
        <Route exact path={PATH.PAYMENT_OVERVIEW} component={Overview} />
        <Route path={PATH.PAYMENT_PAYMENTS} component={Payments} />
        <Route exact path={PATH.PAYMENT_BALANCES} component={Balances} />
        <Route exact path={PATH.PAYMENT_TRANSACTIONS} component={Transactions} />
        {!isStripeReady && <Route exact path={PATH.PAYMENT_SETUP} component={PaymentSetup} />}
        <Redirect to={PATH.PAYMENT_OVERVIEW} />
      </Switch>
    </Container>
  );
};

export default Payment;
