import styled from 'styled-components';

import { media } from '@styles/breakpoints';

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.grayOne};
  padding: 24px 30px;
`;

export const Card = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  margin: 20px 0;
  padding: 20px 50px 20px 25px;
`;

export const CardHeader = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: bold;
  margin-bottom: 16px;
`;

export const Description = styled.div`
  color: ${(props) => props.theme.colors.grayThree};
  margin-bottom: 15px;
`;

export const GrayCard = styled.div`
  background-color: ${(props) => props.theme.colors.grayOne};
  padding: 20px;
  margin: 20px 0;
`;

export const PageHeader = styled.div`
  ${(props) => props.theme.text.two};
  font-weight: bold;
  margin-bottom: 15px;
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 28px;
  border-collapse: collapse;
  background: white;
`;

export const Tr = styled.tr`
  border-bottom: 1px solid ${(props) => props.theme.colors.grayTwo};
  border-top: 1px solid ${(props) => props.theme.colors.grayTwo};
  width: ${(props) => props.width};

  &:last-child {
    border-style: none;
  }

  &:hover {
    cursor: ${(props) => props.cursor};
  }
`;

export const Th = styled.th`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  font-weight: 600;
  padding: 16px;
`;

export const FieldWrapper = styled.div`
  ${(props) => props.theme.form.field.margin};
  margin: 15px 0;
`;
