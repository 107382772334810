import React, { useState, useRef, useEffect } from 'react';

import { useTable } from 'react-table';
import styled from 'styled-components';

import FancyDateRangePicker from '../../../../components/FancyDateRangePicker';
import Pagination from '../../../../components/Pagination';
import Select from '../../../../components/Select';
import ToolTip from '../../../../components/ToolTip';
import { currencyOptions as defaultCurrencyOptions } from '../../../../constants/options';
import { VIEW_ALL_OPTION, TAB } from '../../constants';
import { Table, Th } from '../components';
import { columnConfig } from './constant';
import { getSalesAccounts, getSalesTransactionsData } from '@api/modules/dashboard';
import { Currency } from '@/constants';
import { PLATFORM } from '@constants/platform';
import { usePagination } from '@hooks/UI';
import { convertToFullDate, convertToApiDate } from '@utils/dateHelpers';
import { formatGAAccountLabel } from '@utils/platformHelpers';
import { formatNumber } from '@utils/priceHelpers';

const Row = styled.div`
  margin: 12px 0;
`;

const StyledSelect = styled(Select)`
  display: inline-block;
`;

const StoreSelect = styled(StyledSelect)`
  width: 350px;
`;

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.error};
  ${(props) => props.theme.text.micro};
`;

const TimeIntervalSelect = styled(StyledSelect)`
  width: 120px;
  vertical-align: middle;
  margin-right: 20px;
`;

const CurrencySelect = styled(Select)`
  width: 150px;
`;

const TimeIntervalWrapper = styled.div`
  float: right;
`;

const GroupByLabel = styled.span`
  vertical-align: middle;
  margin-right: 12px;
`;

const TableHeader = styled.thead`
  color: ${(props) => props.theme.colors.grayThree};
  th {
    background-color: ${(props) => props.theme.colors.white};
    border: none;
  }
`;

const SummaryTableRow = styled.tr`
  font-weight: bold;
  border: 1px solid ${(props) => props.theme.colors.grayTwo};
  background-color: ${(props) => props.theme.colors.grayOne};
`;

const TableRow = styled.tr`
  background-color: ${(props) => props.theme.colors.white};
`;

const timeIntervalOptions = [
  {
    value: 'day',
    label: 'Day',
  },
  {
    value: 'month',
    label: 'Month',
  },
];

const showAllOption = { value: 'SHOW_ALL', label: 'Show all' };

const getCurrentAccountById = (accounts, integrationId) => {
  const ids = accounts.map((account) => account.integration_id);
  const index = ids.indexOf(integrationId);
  return accounts[index] || {};
};

const SalesReportTable = ({ category, platform, selectedAccountValue, onPlatformChange }) => {
  const aovHeaderRef = useRef();
  const acvHeaderRef = useRef();
  const [timeInterval, setTimeInterval] = useState(timeIntervalOptions[0].value);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateShortcutValue, setDateShortValue] = useState();
  const [accounts, setAccounts] = useState([]);
  const currentAccount = getCurrentAccountById(accounts, selectedAccountValue);
  const [selectedCurrency, setSelectedCurrency] = useState(Currency.USD);
  const [currencyOptions, setCurrencyOptions] = useState(defaultCurrencyOptions);
  const [summary, setSummary] = useState();
  const [transactions, setTransactions] = useState([]);
  const { pageNumber, pageSize, pageCount, onPageNumberChange, onPageSizeChange, changePageCountByTotalAndSize } = usePagination({
    pageNumber: 0,
    pageSize: 50,
    pageCount: 0,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data: transactions,
    columns: columnConfig,
  });

  const generateAccountOptions = () => {
    let result =
      Array.isArray(accounts) && accounts.length > 0
        ? accounts
            .filter(({ type, platform_name }) => {
              if (category === TAB.SALES) {
                if (platform !== VIEW_ALL_OPTION.value) {
                  return platform === platform_name;
                }
                return type === 1;
              } else if (category === TAB.PAYMENT) {
                if (platform !== VIEW_ALL_OPTION.value) {
                  return platform === platform_name;
                }

                if (platform === VIEW_ALL_OPTION.value) {
                  return type === 2 || [PLATFORM.SHOPIFY, PLATFORM.BBMSL].indexOf(platform_name) !== -1;
                }
                return type === 2;
              } else if (category === TAB.ANALYTICS) {
                return platform_name === PLATFORM.GOOGLE_ANALYTICS;
              }

              return false;
            })
            .map((account) => {
              const label =
                category === TAB.ANALYTICS
                  ? formatGAAccountLabel(account.channel_account_id, account.channel_account_name)
                  : account.channel_account_name || account.channel_account_id;
              return {
                value: account.integration_id,
                label: `${label}${account.disconnected ? ' (Disconnected)' : ''}`,
                isInactive: account.disconnected,
              };
            })
        : [];

    if (Array.isArray(result) && result.length > 0) {
      result = [showAllOption, ...result];
    }

    return result;
  };

  const fetchTransactionApi = async () => {
    const { data, statusCode } = await getSalesTransactionsData(
      { page: pageNumber, pageSize },
      {
        channel: category,
        platform: platform === VIEW_ALL_OPTION.value ? '' : platform,
        channel_account_id: selectedAccountValue === showAllOption.value ? '' : currentAccount.channel_account_id,
        currency: selectedCurrency,
        group_by: timeInterval,
        ...(startDate && { start_date: convertToApiDate(startDate) }),
        ...(endDate && { end_date: convertToApiDate(endDate) }),
        ...(category === TAB.ANALYTICS && currentAccount && { view_id: currentAccount.view_id }),
      }
    );
    if (statusCode === 200) {
      setSummary(data.summary);
      setTransactions(data.transactions);
      changePageCountByTotalAndSize(data.total, pageSize);
    }
  };

  useEffect(() => {
    onPageNumberChange(1);
    fetchTransactionApi();
  }, [category, platform, selectedAccountValue, selectedCurrency, startDate, endDate, timeInterval, pageSize]);

  useEffect(() => {
    fetchTransactionApi();
  }, [pageNumber]);

  useEffect(() => {
    const fetchApi = async () => {
      const { data } = await getSalesAccounts();
      if (Array.isArray(data) && data.length > 0) {
        setAccounts(data);
      }
    };

    fetchApi();
  }, []);

  const applyCalendar = (calendarData) => {
    setStartDate(calendarData.startDate);
    setEndDate(calendarData.endDate);
    setDateShortValue(calendarData.dateShortcutSelectValue);
  };

  useEffect(() => {
    if (!selectedAccountValue || selectedAccountValue === showAllOption.value) {
      setCurrencyOptions(defaultCurrencyOptions);
    } else {
      if (Array.isArray(currentAccount.currency)) {
        const accountCurrencies = currentAccount.currency;
        const accountCurrencyOptions = Array.isArray(accountCurrencies)
          ? accountCurrencies.map((currency) => ({
              value: currency,
              label: currency,
            }))
          : [];

        setCurrencyOptions(accountCurrencyOptions);
      }
    }
  }, [selectedAccountValue]);

  const handleAccountChange = (value) => {
    onPlatformChange(value);
    setSelectedCurrency(Currency.USD);
  };

  const handleCurrencyChange = (value) => {
    setSelectedCurrency(value);
  };

  const accountOptions = generateAccountOptions();

  return (
    <>
      <Row>
        <StoreSelect value={selectedAccountValue} options={accountOptions} onChange={handleAccountChange} />
        <TimeIntervalWrapper>
          <GroupByLabel>Group by</GroupByLabel>
          <TimeIntervalSelect value={timeInterval} options={timeIntervalOptions} onChange={setTimeInterval} />
          <FancyDateRangePicker
            startDate={startDate}
            endDate={endDate}
            dateShortcutValue={dateShortcutValue}
            onDateRangeSelectValueChange={setDateShortValue}
            onApply={applyCalendar}
          />
        </TimeIntervalWrapper>
      </Row>
      <Row>
        {currentAccount.disconnected && (
          <ErrorMessage>The account has been disabled since {convertToFullDate(currentAccount.disconnected_date)}.</ErrorMessage>
        )}
        <CurrencySelect value={selectedCurrency} options={currencyOptions} onChange={handleCurrencyChange} />
      </Row>
      <Table {...getTableProps()}>
        <TableHeader>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                if (column.id === 'aov') {
                  return (
                    <Th key={column.id}>
                      <ToolTip
                        className="aov"
                        width={200}
                        innerRef={aovHeaderRef}
                        tip="Average order value (AOV) tracks the average dollar amount spent each time a customer places an order."
                        placeholder={column.render('Header')}
                      />
                    </Th>
                  );
                } else if (column.id === 'acv') {
                  return (
                    <Th key={column.id}>
                      <ToolTip
                        className="acv"
                        width={200}
                        innerRef={acvHeaderRef}
                        tip="The average customer value (ACV) is the average revenue value that each customer brings to your business during a given time frame. "
                        placeholder={column.render('Header')}
                      />
                    </Th>
                  );
                } else if (['sales', 'refunds', 'revenue'].indexOf(column.id) !== -1) {
                  return (
                    <Th key={column.id}>
                      {column.render('Header')} ({selectedCurrency})
                    </Th>
                  );
                }
                return <Th>{column.render('Header')}</Th>;
              })}
            </tr>
          ))}
        </TableHeader>
        <tbody {...getTableBodyProps()}>
          <SummaryTableRow>
            {columnConfig.map(({ accessor }, index) => {
              const value = summary ? summary[accessor] : '';
              if (index === 0) {
                return <td>Summary</td>;
              } else if (['orders', 'customers'].indexOf(accessor) !== -1) {
                return <td>{value}</td>;
              }
              return <td>{formatNumber(value, 2)}</td>;
            })}
          </SummaryTableRow>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </TableRow>
            );
          })}
        </tbody>
      </Table>
      {pageCount ? (
        <Pagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          pageCount={pageCount}
          onPageNumberChange={onPageNumberChange}
          onPageSizeChange={onPageSizeChange}
        />
      ) : null}
    </>
  );
};

export default SalesReportTable;
