import React, { useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Arrow, { ARROW_DIRECTION } from '../../assets/icons/Arrow';
import Avatar from '../../components/Avatar';
import Button from '../../components/Button';
import AuthPath from '../Authentication/path';
import DASHBOARD_PATH from '../Dashboard/path';
import { logout } from '@api/modules/auth';
import { Menu, DropdownOption } from '@components/Dropdown';
import { useOnClickOutside, useModal } from '@hooks/UI';
import { logoutUser } from '@redux/modules/auth/actions';
import { selectCompany, fetchAllCompaniesAccessedByAdmin } from '@redux/modules/company/actions';
import { switchToAdminView, switchToUserView } from '@redux/modules/UI/actions';
import { useUserCompanyId, useIsAdminView } from '@redux/selectors';
import { useUserViewAssignedCompanyInfo } from '@utils/userHelpers';

const APP_HEADER_HEIGHT = 50;

const Wrapper = styled.div`
  width: 100%;
  height: ${`${APP_HEADER_HEIGHT}px`};
  background-color: ${(props) => props.theme.colors.grayOne};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  box-sizing: border-box;
`;

const NavControllerWrapper = styled.div`
  float: right;
`;

const NavItem = styled.div`
  height: ${`${APP_HEADER_HEIGHT}px`};
  display: table-cell;
  vertical-align: middle;
  padding: 0 5px;

  > img,
  > div,
  > span,
  > svg {
    vertical-align: middle;
  }
`;

const UserName = styled.span`
  margin: 0 5px;
`;

const UserControlWrapper = styled.div`
  cursor: pointer;
`;

const StyledMenu = styled(Menu)`
  top: 55px;
  right: 35px;
`;

const UserControl = ({ userProfile }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [isMenuOpen, setMenuOpen] = useModal(false);
  useOnClickOutside(ref, () => setMenuOpen(false));
  const { firstName, lastName } = userProfile;

  const handleLogout = async () => {
    await logout();
    dispatch(logoutUser());
    history.push(AuthPath.LOGIN);
  };

  const name = `${firstName} ${lastName}`;

  return (
    <UserControlWrapper>
      <NavItem ref={ref} onClick={setMenuOpen}>
        <Avatar name={name} />
        <UserName>{name}</UserName>
        <Arrow direction={isMenuOpen ? ARROW_DIRECTION.UP : ARROW_DIRECTION.DOWN} />
        {isMenuOpen && (
          <StyledMenu>
            <DropdownOption onClick={handleLogout}>Log out</DropdownOption>
          </StyledMenu>
        )}
      </NavItem>
    </UserControlWrapper>
  );
};

const AppHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfile = useSelector((state) => state.user.profile);
  const isInAdminView = useIsAdminView();
  const companyId = useUserCompanyId();

  const handleViewClick = async () => {
    if (isInAdminView) {
      dispatch(switchToUserView());
      selectCompany(companyId);
    } else {
      await fetchAllCompaniesAccessedByAdmin(userProfile.adminRole.adminId);
      dispatch(switchToAdminView());
    }

    history.replace(DASHBOARD_PATH.HOME);
  };

  return (
    <Wrapper>
      <NavControllerWrapper>
        {useUserViewAssignedCompanyInfo() && (
          <NavItem>
            <Button onClick={handleViewClick} small>
              {isInAdminView ? 'Switch to User View' : 'Switch to Admin View'}
            </Button>
          </NavItem>
        )}
        <NavItem>
          <UserControl userProfile={userProfile} />
        </NavItem>
      </NavControllerWrapper>
    </Wrapper>
  );
};

export default AppHeader;
