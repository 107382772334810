import React from 'react';

import { useForm } from 'react-hook-form';
import { useHistory, generatePath } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import RadioWithText from '../../../../components/RadioWithText';
import PATH from '../../path';
import { formOptions, REVENUE_FORM_NAME, REVENUE_FORM_PATH, ONE_TIME_FORM_PATH } from './constants';

const ButtonWrapper = styled.div`
  ${(props) => props.theme.buttonWrapper}
`;

const FundingInfoTable = styled.table`
  td:first-child {
    width: 150px;
  }
`;

const TypeSelectionForm = () => {
  const history = useHistory();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const watchOfFormType = watch('formType');

  const onSubmit = async ({ formType }) => {
    const path = formType === REVENUE_FORM_NAME ? REVENUE_FORM_PATH : ONE_TIME_FORM_PATH;
    const url = generatePath(PATH.CAPITAL_INVOICE_FORM, { formType: path });
    history.push(url);
  };

  const handleRadioClick = (value) => {
    setValue('formType', value);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>Choose which type of invoice you want to create:</div>
      {formOptions.map((option) => {
        return <RadioWithText {...option} checked={option.value === watchOfFormType} onClick={handleRadioClick} />;
      })}
      <ButtonWrapper>
        <Button>Next</Button>
      </ButtonWrapper>
    </form>
  );
};

export default TypeSelectionForm;
