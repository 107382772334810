import React from 'react';

import styled from 'styled-components';

import Dropdown, { DropdownOption } from '../../components/Dropdown';
import { HeaderRow } from './components';
import PlatformStatusTag from './PlatformStatusTag';
import { FormRow, GridColumn } from '@components/Grid';
import { PLATFORM } from '@constants/platform';
import { getLogoByPlatformName, formatGAAccountLabel } from '@utils/platformHelpers';

const StyledGridColumn = styled(GridColumn)`
  line-height: 25px;
  vertical-align: middle;
`;

const Logo = styled.img`
  height: 25px;
  line-height: initial;
  vertical-align: middle;
`;

const StatusTag = styled.div`
  color: ${(props) => props.theme.colors.green};
`;

const RetryLabel = styled.div`
  ${(props) => props.theme.text.link};
`;

const SalesAccountsTable = ({ accounts, onDeletePlatformClick, onShopPlatformReAuth, showGAIntegrationModal, disabled }) => {
  const formatName = ({ platformName, accountId, accountName }) => {
    if (platformName === PLATFORM.GOOGLE_ANALYTICS) {
      return formatGAAccountLabel(accountId, accountName);
    }

    return accountId;
  };

  return (
    <>
      <HeaderRow>
        <StyledGridColumn lg={2} md={2}>
          Sales Channel
        </StyledGridColumn>
        <StyledGridColumn lg={4} md={4}>
          Account ID
        </StyledGridColumn>
        <StyledGridColumn lg={4} md={4}>
          Status
        </StyledGridColumn>
        <StyledGridColumn lg={2} md={2} />
      </HeaderRow>
      {accounts.map((account) => {
        const { logoUrl } = getLogoByPlatformName(account?.platformName);
        console.log({ account });
        return (
          <FormRow key={account.id}>
            <StyledGridColumn lg={2} md={2}>
              <Logo src={logoUrl} alt={account.platformName} />
            </StyledGridColumn>
            <StyledGridColumn lg={4} md={4}>
              {formatName(account)}
            </StyledGridColumn>
            <StyledGridColumn lg={4} md={4}>
              {account.platformName === PLATFORM.GOOGLE_ANALYTICS && account.status === -2 ? (
                <RetryLabel onClick={() => showGAIntegrationModal(account.id, account.accountId)}>Retry</RetryLabel>
              ) : (
                <PlatformStatusTag {...account} />
              )}
            </StyledGridColumn>
            {!disabled && account?.platformName !== PLATFORM.BBMSL && (
              <StyledGridColumn lg={2} md={2}>
                <Dropdown width={160} menuTextAlign="center">
                  {/* <DropdownOption>
                        Retry Connection
                    </DropdownOption> */}
                  {account?.platformName === PLATFORM.SHOPIFY && (
                    <DropdownOption onClick={() => onShopPlatformReAuth(account?.accountId)}>Update App</DropdownOption>
                  )}
                  <DropdownOption onClick={onDeletePlatformClick(account)} warning>
                    Delete Platform
                  </DropdownOption>
                </Dropdown>
              </StyledGridColumn>
            )}
          </FormRow>
        );
      })}
    </>
  );
};

export default SalesAccountsTable;
