export const convertBankAccountResponse = (account) => ({
  id: account.bank_id,
  country: account.country,
  bankName: account.bank_name,
  accountHolderName: account.account_holder_name,
  accountNumber: account.account_no,
  swiftBic: account.swift_bic,
  attachmentCount: Number(account.attachment_count),
  status: account.status,
  add_method: account.add_method,
  currency: account.currency,
  accountName: account.account_name,
  channelBankId: account.channel_bank_id,
  channelAccountId: account.channel_account_id,
  balance: account.balance,
});
