import React, { useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../components/Button';
import FormTitle from '../../../components/FormTitle';
import Input from '../../../components/Input';
import Banner from '../components/Banner';
import { Form, FormRow, Tagline, Error, Container, ChocoUpLogo, CenterDiv, ContentDiv } from '../components/styled';
import PATH from '../path';
import { requestResetPassword } from '@api/modules/auth';
import { media } from '@styles/breakpoints';
import { emailRegExp } from '@utils/validators';

const AlignCenterDiv = styled(CenterDiv)`
  text-align: center;
  margin-top: 24px;
  ${media.phone`
   margin-top: 10px;
  `};
`;

const StyledContainer = styled(Container)`
  ${media.dablet`
    height: 100vh;
  `};
`;

const StyledContentDiv = styled(ContentDiv)`
  height: 50vh;
`;

const BackToLoginLabel = styled(Link)`
  ${(props) => props.theme.text.link};
  font-size: 14px;
`;

const Title = styled.div`
  ${(props) => props.theme.text.two};
  margin-top: 40px;
  ${media.phone`
   margin-top: 10px;
   font-size: 24px;
  `};
`;

const ForgotPassword = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [didRequest, toggleDidRequest] = useState(false);
  const [apiError, setApiError] = useState();
  const [isLoading, toggleIsLoading] = useState();

  const onSubmit = async (data) => {
    toggleIsLoading(true);
    setApiError('');
    const { statusCode } = await requestResetPassword(data.email);

    // in case email input is not found
    if (statusCode === 200) {
      toggleDidRequest(true);
    } else {
      setApiError('We couldn’t find your email, please try again.');
    }

    toggleIsLoading(false);
  };

  return (
    <>
      <Banner />
      <StyledContainer>
        <StyledContentDiv>
          <ChocoUpLogo src="/images/choco-up-black-no-title.png" alt="Choco Up Logo" />
          <Form onSubmit={handleSubmit(onSubmit)}>
            {didRequest && (
              <FormRow>
                <Title>Please check your email for reset instructions.</Title>
                <Tagline>
                  If you don’t receive an email from us within the next few minutes, check your email’s spam or junk
                  mail folder, or try resending your request.
                </Tagline>
              </FormRow>
            )}
            {!didRequest && (
              <>
                <Title>Reset Password</Title>
                <Tagline>
                  To reset your password, please provide the email address used to register your account. Your will
                  receive an email for the next step.
                </Tagline>
                <FormRow>
                  <FormTitle scale={5} required>
                    Company Email Address
                  </FormTitle>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: true,
                      pattern: emailRegExp,
                    }}
                    render={({ field }) => <Input {...field} hasError={errors.email} disabled={isLoading} full />}
                  />
                </FormRow>
                {apiError && <Error>{apiError}</Error>}
                <Button disabled={isLoading} type="submit" full>
                  Submit
                </Button>
              </>
            )}
            <AlignCenterDiv>
              <BackToLoginLabel to={PATH.LOGIN}>Go Back To Login</BackToLoginLabel>
            </AlignCenterDiv>
          </Form>
        </StyledContentDiv>
      </StyledContainer>
    </>
  );
};

export default ForgotPassword;
