import React, { useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../../../components/Button';
import FormTitle from '../../../../../components/FormTitle';
import PasswordInput from '../../../../../components/PasswordInput';
import HINT from '../../../../../utils/hint';
import Logger from '../../../../../utils/logger';
import { Card } from "../../../components";
import PATH from '../../../path';
import { changePassword } from '@api/modules/auth';
import { FormRow, GridColumn } from '@components/Grid';
import { Error } from '@modules/Authentication/components/styled';
import { passwordRegExp } from '@utils/validators';

const CardTitle = styled.span`
  ${(props) => props.theme.text.three};
  font-weight: 600;
`;

const ChangePasswordForm = () => {
  const history = useHistory();
  const [isLoading, toggleIsLoading] = useState();
  const [passwordError, setPasswordError] = useState('');
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async ({ currentPassword, newPassword, confirmNewPassword }) => {
    try {
      toggleIsLoading(true);
      if (newPassword !== confirmNewPassword) {
        setPasswordError('Passwords must match');
        return;
      }
      const payload = {
        currentPassword,
        newPassword,
      };
      const { statusCode } = await changePassword(payload);

      if (statusCode === 200) {
        history.push(PATH.SETTINGS_ACCOUNT_OVERVIEW);
      } else if (statusCode === 400) {
        setPasswordError('Current password you entered is wrong, please try again.');
      } else {
        setPasswordError('Failed to change password');
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const handleCancel = () => {
    history.push(PATH.SETTINGS_PROFILE_INFORMATION);
  };

  return (
    <>
      <CardTitle>Change Password</CardTitle>
      <Card>
        <GridColumn lg={8} md={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormTitle scale={5} required>
              Current password
            </FormTitle>
            <Controller
              name="currentPassword"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <PasswordInput {...field} hasError={errors.currentPassword} disabled={isLoading} full />
              )}
            />
            <FormTitle scale={5} tip={HINT.PASSWORD_PATTERN} required>
              New password
            </FormTitle>
            <Controller
              name="newPassword"
              control={control}
              rules={{ required: true, pattern: passwordRegExp }}
              render={({ field }) => (
                <PasswordInput {...field} hasError={errors.newPassword} disabled={isLoading} full />
              )}
            />
            <FormTitle scale={5} tip={HINT.PASSWORD_PATTERN} required>
              Confirm new password
            </FormTitle>
            <Controller
              name="confirmNewPassword"
              control={control}
              rules={{ required: true, pattern: passwordRegExp }}
              render={({ field }) => (
                <PasswordInput {...field} hasError={errors.confirmNewPassword} disabled={isLoading} full />
              )}
            />
            {errors.newPassword?.type === 'pattern' || errors.confirmNewPassword?.type === 'pattern' ? (
              <Error>{HINT.PASSWORD_PATTERN}</Error>
            ) : (
              <Error>{passwordError}</Error>
            )}
            <FormRow>
              <GridColumn lg={6} md={6}>
                <Button type="submit" disabled={isLoading} full>
                  Change password
                </Button>
              </GridColumn>
              <GridColumn lg={6} md={6}>
                <Button onClick={handleCancel} disabled={isLoading} tertiary full>
                  Cancel
                </Button>
              </GridColumn>
            </FormRow>
          </form>
        </GridColumn>
      </Card>
    </>
  );
};

export default ChangePasswordForm;
