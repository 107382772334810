import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import OfferAcceptanceModal from '../Application/Forms/TermSheetForm/AcceptanceModal';
import FAQModal from '../Application/Header/theFAQModal';
import RevenueAdjustmentModal from '../Dashboard/Business/BankTransactions/Settlement/components/AdjustmentModal';
import SettlementModal from '../Dashboard/Business/BankTransactions/Settlement/XeroSettlementModal';
import AutoSettlementTransactionModal from '../Dashboard/Capital/InvoiceDetail/AutoSettlementTransactionModal';
import BankSettlementTransactionModal from '../Dashboard/Capital/InvoiceDetail/BankSettlementTransactionModal';
import InvoiceStatusModal from '../Dashboard/Capital/InvoiceDetail/InvoiceStatusModal';
import RefundPaymentModal from '../Dashboard/Payment/Payments/RefundModal';
import RefundPaymentSuccessModal from '../Dashboard/Payment/Payments/SuccessModal';
import DisableMultiFactorModal from '../Dashboard/Settings/UserManagement/2FA/DisableMultiFactorModal';
import MultiFactorSetupModal from '../Dashboard/Settings/UserManagement/2FA/MultiFactorSetupModal';
import TermSheetModal from '../Dashboard/TermSheet/TermSheetModal';
import SensitiveDetailModal from '../Dashboard/Wallet/Card/CardDetail/SensitiveDetailModal';
import AddFundsModal from '../Dashboard/Wallet/IntegratedOverview/AddFundsModal';
import BalanceReportModal from '../Dashboard/Wallet/IntegratedOverview/Balance/BalanceReportModal';
import AccountDetailsModal from '../Dashboard/Wallet/IntegratedOverview/GlobalAccounts/AccountDetailsModal';
import CreateAmazonAccountModal from '../Dashboard/Wallet/IntegratedOverview/GlobalAccounts/CreateAmazonAccountsModal';
import CreateGlobalAccountsModal from '../Dashboard/Wallet/IntegratedOverview/GlobalAccounts/CreateGlobalAccountsModal';
import Transfer2faModal from '../Dashboard/Wallet/Transfer/CreateTransferPage/Transfer2faModal';
import UploadFilesModal from '../File/UploadFilesModal';
import MagentoIntegrationModal from '../Integration/MagentoIntegrationModal';
import ShopifyIntegrationModal from '../Integration/ShopifyIntegrationModal';
import ShoplazzaIntegrationModal from '../Integration/ShoplazzaIntegrationForm';
import WoocommerceIntegrationModal from '../Integration/WoocommerceIntegrationForm';
import BankAccountModal from '../Platform/BankAccountFormModal';
import BankStatementModal from '../Platform/BankStatementModal';
import ManualIntegrationModal from '../Platform/ManualIntegrationModal';
import ManualIntegrationForm from '../Platform/ManualIntegrationModal/Instructions/ManualIntegrationForm';
import SalesOthersPlatformsModal from '../Platform/ManualIntegrationModal/Instructions/SalesOthersPlatformsModal';
import NewBankAccountOverviewModal from '../Platform/NewBankAccountOverviewModal';
import MessageModal from './components/MessageModal';
import {
  ADD_FUNDS_MODAL,
  APPLICATION_CONFIRM_SUBMISSION,
  APPLICATION_SUCCESS_SUBMISSION,
  AUTO_SETTLEMENT_TRANSACTION_MODAL,
  BALANCE_REPORT_MODAL,
  BANK_ACCOUNT_MODAL,
  BANK_SETTLEMENT_TRANSACTION_MODAL,
  BANK_STATEMENT_MODAL,
  CREATE_AMAZON_ACCOUNTS_MODAL,
  CREATE_GLOBAL_ACCOUNTS_MODAL,
  DISABLE_MULTI_FACTOR_SETUP_MODAL,
  FAQ_MODAL,
  FB_TUTORIAL_MODAL,
  GA_TUTORIAL_MODAL,
  GLOBAL_ACCOUNT_ACCOUNT_DETAILS,
  INVOICE_STATUS_MODAL,
  MAGENTO_INTEGRATION_MODAL,
  MANUAL_INTEGRATION_FORM,
  MANUAL_INTEGRATION_MODAL,
  MARKETING_PLATFORM_FB_INTEGRATION,
  MARKETING_PLATFORM_INTEGRATION,
  MESSAGE_MODAL,
  MULTI_FACTOR_SETUP_MODAL,
  NEW_BANK_ACCOUNT_OVERVIEW_MODAL,
  OFFER_ACCEPTANCE_MODAL,
  REFUND_PAYMENT_MODAL,
  REFUND_PAYMENT_SUCCESS_MODAL,
  REVENUE_ADJUSTMENT_MODAL,
  SALES_OTHERS_PLATFORM_MODAL,
  SENSITIVE_CARD_DETAIL_MODAL,
  SHOPIFY_INTEGRATION_MODAL,
  SHOPLAZZA_INTEGRATION_MODAL,
  TERM_SHEET_MODAL,
  TRANSFER_2FA_MODAL,
  UPLOAD_FILES_MODAL,
  WOOCOMMERCE_INTEGRATION_MODAL,
  XERO_SETTLEMENT_MODAL,
} from './constants';
import { hideModal } from '@redux/modules/UI/actions';

const MODAL_COMPONENTS = {
  [MESSAGE_MODAL]: MessageModal,
  [TERM_SHEET_MODAL]: TermSheetModal,
  [FAQ_MODAL]: FAQModal,
  [MANUAL_INTEGRATION_MODAL]: ManualIntegrationModal,
  [MANUAL_INTEGRATION_FORM]: ManualIntegrationForm,
  [OFFER_ACCEPTANCE_MODAL]: OfferAcceptanceModal,
  [UPLOAD_FILES_MODAL]: UploadFilesModal,
  [WOOCOMMERCE_INTEGRATION_MODAL]: WoocommerceIntegrationModal,
  [SHOPLAZZA_INTEGRATION_MODAL]: ShoplazzaIntegrationModal,
  [BANK_STATEMENT_MODAL]: BankStatementModal,
  [SALES_OTHERS_PLATFORM_MODAL]: SalesOthersPlatformsModal,
  [NEW_BANK_ACCOUNT_OVERVIEW_MODAL]: NewBankAccountOverviewModal,
  [BANK_ACCOUNT_MODAL]: BankAccountModal,
  [SHOPIFY_INTEGRATION_MODAL]: ShopifyIntegrationModal,
  [MAGENTO_INTEGRATION_MODAL]: MagentoIntegrationModal,
  [REVENUE_ADJUSTMENT_MODAL]: RevenueAdjustmentModal,
  [XERO_SETTLEMENT_MODAL]: SettlementModal,
  [BANK_SETTLEMENT_TRANSACTION_MODAL]: BankSettlementTransactionModal,
  [AUTO_SETTLEMENT_TRANSACTION_MODAL]: AutoSettlementTransactionModal,
  [INVOICE_STATUS_MODAL]: InvoiceStatusModal,
  [BALANCE_REPORT_MODAL]: BalanceReportModal,
  [ADD_FUNDS_MODAL]: AddFundsModal,
  [CREATE_GLOBAL_ACCOUNTS_MODAL]: CreateGlobalAccountsModal,
  [GLOBAL_ACCOUNT_ACCOUNT_DETAILS]: AccountDetailsModal,
  [MULTI_FACTOR_SETUP_MODAL]: MultiFactorSetupModal,
  [DISABLE_MULTI_FACTOR_SETUP_MODAL]: DisableMultiFactorModal,
  [REFUND_PAYMENT_MODAL]: RefundPaymentModal,
  [REFUND_PAYMENT_SUCCESS_MODAL]: RefundPaymentSuccessModal,
  [TRANSFER_2FA_MODAL]: Transfer2faModal,
  [SENSITIVE_CARD_DETAIL_MODAL]: SensitiveDetailModal,
  [CREATE_AMAZON_ACCOUNTS_MODAL]: CreateAmazonAccountModal,
};

const ModalContainer = () => {
  const dispatch = useDispatch();
  const { type, modalProps } = useSelector((state) => state.ui.modal);

  const SpecificModal = MODAL_COMPONENTS[type];

  return SpecificModal ? <SpecificModal {...modalProps} hideModal={() => dispatch(hideModal())} /> : null;
};

export default ModalContainer;
