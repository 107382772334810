import React from 'react';

const ToolTip = ({ width, height, fill, ...restProps }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.5 12C9.53757 12 12 9.53757 12 6.5C12 3.46243 9.53757 1 6.5 1C3.46243 1 1 3.46243 1 6.5C1 9.53757 3.46243 12 6.5 12Z"
        stroke="#505050"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.94998 5.96677C7.41665 5.70508 6.31665 8.58365 6.31665 8.58365C7.04998 8.84534 7.04998 8.58365 7.04998 8.58365"
        stroke="#505050"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.5 3.75H6.5055" stroke="#505050" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

ToolTip.defaultProps = {
  width: 16,
  height: 16,
  fill: '#3A0CA3',
};

export default ToolTip;
