import React, { useEffect, useState } from 'react';

import moment from 'moment';
import isAfterDay from 'react-dates/lib/utils/isAfterDay';
import reactDateIsInclusivelyAfterDay from 'react-dates/lib/utils/isInclusivelyAfterDay';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../components/Button';
import FormTitle from '../../../components/FormTitle';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import Select from '../../../components/Select';
import SingleDatePicker from '../../../components/SingleDatePicker';
import Logger from '../../../utils/logger';
import RHFMoneyInput from '../../ReactHookForm/RHFMoneyInput';
import { validateExpiryDay } from './helpers';
import { disburseFunding } from '@api/modules/application';
import { deleteOfferById, editOfferById, createOffer } from '@api/modules/application/offer';
import { getFirstInvoiceStartDate } from '@api/modules/dashboard/invoice';
import { FormRow, GridColumn } from '@components/Grid';
import { Currency } from '@/constants';
import { remittancePeriodOptions } from '@constants/options';
import { fetchApplication } from '@redux/modules/application/actions';
import { fetchFundBalance } from '@redux/modules/fund/actions';
import { generateOfferPayload } from '@redux/modules/offer/helpers';
import { hideModal } from '@redux/modules/UI/actions';
import { useSelectedCompanyId } from '@redux/selectors';
import { isRealNumber } from '@utils/dataTypes';
import { convertToApiDate } from '@utils/dateHelpers';

const ButtonWrapper = styled.div`
  margin-top: 25px;
`;

const Title = styled.div`
  ${(props) => props.theme.text.three};
`;

const Column = styled(GridColumn)`
  padding-top: 0;
  padding-bottom: 0;
`;

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.error};
  ${(props) => props.theme.text.micro};
  margin-top: 4px;
`;

const TermSheetModal = ({ index, offer, defaultValues, fetchOffers, disabled, isReadyToDisburse }) => {
  const isEdit = offer;
  const dispatch = useDispatch();
  const selectedCompanyId = useSelectedCompanyId();
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: isEdit
      ? {
          ...offer,
          retrievalPercentage: Number(offer.retrievalPercentage),
          actualDisbursementDate: offer.actualDisbursementDate || offer.disbursementDate,
        }
      : {
          ...defaultValues,
          purchasedReceivablesCurrency: Currency.USD,
        },
  });
  const [apiError, setApiError] = useState();
  const [isLoading, toggleIsLoading] = useState(false);
  const adminOffers = useSelector((state) => state.offer.adminOffers);
  const { applicationId } = useSelector((state) => state.application.data);

  const termSheetCurrencyWatch = watch('purchasedReceivablesCurrency');
  const disbursementDateWatch = watch('disbursementDate');
  const actualDisbursementDateWatch = watch('actualDisbursementDate');
  const remittancePeriodWatch = watch('remittancePeriod');

  useEffect(() => {
    setValue('purchasedPriceCurrency', termSheetCurrencyWatch);
    setValue('minRemittanceCurrency', termSheetCurrencyWatch);
    setValue('maxRemittanceCurrency', termSheetCurrencyWatch);
  }, [termSheetCurrencyWatch]);

  const onSubmit = async (data) => {
    try {
      toggleIsLoading(true);
      const { error } = isEdit
        ? await editOfferById(offer.id, generateOfferPayload(data, applicationId))
        : await createOffer(generateOfferPayload(data, applicationId));

      if (error) {
        setApiError(error);
      } else {
        fetchOffers(applicationId);
        dispatch(hideModal());
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const fetchInvoiceStartDateMoment = async (disbursementDate, frequency) => {
    const invoiceDate = await getFirstInvoiceStartDate({
      frequency,
      date: new Date(convertToApiDate(disbursementDate)),
    });

    return moment(invoiceDate);
  };

  useEffect(() => {
    const setActualFirstInvoiceDate = async () => {
      if (isReadyToDisburse && actualDisbursementDateWatch) {
        toggleIsLoading(true);
        try {
          const disbursementDate = getValues('actualDisbursementDate');
          const frequency = getValues('remittancePeriod');
          const invoiceStartDateMoment = await fetchInvoiceStartDateMoment(disbursementDate, frequency);
          setValue('actualFirstInvoiceDate', invoiceStartDateMoment);
        } catch (err) {
          Logger.error(err);
        } finally {
          toggleIsLoading(false);
        }
      }
    };

    setActualFirstInvoiceDate();
  }, [actualDisbursementDateWatch]);

  useEffect(() => {
    const setFirstRemittanceDate = async () => {
      if (!disabled && disbursementDateWatch && remittancePeriodWatch) {
        toggleIsLoading(true);
        try {
          const disbursementDate = getValues('disbursementDate');
          const invoiceStartDateMoment = await fetchInvoiceStartDateMoment(disbursementDate, remittancePeriodWatch);
          setValue('firstRemittanceDate', invoiceStartDateMoment);
        } catch (err) {
          Logger.error(err);
        } finally {
          toggleIsLoading(false);
        }
      }
    };

    setFirstRemittanceDate();
  }, [disbursementDateWatch, remittancePeriodWatch]);

  const handleDisburseFunding = async () => {
    toggleIsLoading(true);
    try {
      const formData = getValues();
      const { statusCode } = await disburseFunding(applicationId, {
        offer_id: offer.id,
        actual_disbursement_date: formData.actualDisbursementDate,
        actual_invoice_date: formData.actualFirstInvoiceDate,
      });

      if (statusCode === 200) {
        fetchOffers(applicationId);
        fetchApplication();
        dispatch(hideModal());
        fetchFundBalance({
          company_id: selectedCompanyId,
          funding_id: applicationId,
          offer_id: offer.id,
        });
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const handleDeleteOffer = async (event) => {
    toggleIsLoading(true);
    try {
      event.preventDefault();
      // eslint-disable-next-line no-alert
      const result = window.confirm('Are you sure to delete the offer?');

      if (result) {
        const { statusCode } = await deleteOfferById(offer.id);
        if (statusCode === 200) {
          fetchOffers(applicationId);
          dispatch(hideModal());
        }
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const isPurchasedReceivablesValid = (value) => {
    const purchasedPriceAmount = getValues('purchasedPriceAmount');
    if (purchasedPriceAmount) {
      return purchasedPriceAmount <= value;
    }

    return true;
  };

  const isPurchasedPriceAmountValid = (value) => {
    const purchasedReceivablesAmount = getValues('purchasedReceivablesAmount');
    if (purchasedReceivablesAmount) {
      return value <= purchasedReceivablesAmount;
    }

    return true;
  };

  const isOutsideRange = (compareDateField) => (day) => {
    const value = getValues(compareDateField);
    return value ? day < value : !isAfterDay(day, moment());
  };

  const isInclusivelyAfterDay = (compareDateField) => (value) => {
    const compareDateValue = getValues(compareDateField);
    return compareDateValue ? reactDateIsInclusivelyAfterDay(value, getValues(compareDateField)) : true;
  };

  const isMinRemittanceAmountValid = (value) => {
    const maxAmount = getValues('maxRemittanceAmount');
    if (isRealNumber(value) && maxAmount) {
      return value <= maxAmount;
    }

    return true;
  };

  const isMaxRemittanceAmountValid = (value) => {
    const minAmount = getValues('minRemittanceAmount');
    if (isRealNumber(value) && minAmount) {
      return minAmount <= value;
    }

    return true;
  };

  return (
    <Modal showCloseButton>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title>Create Term Sheet</Title>
        <FormTitle scale={5} required>
          Purchased Receivables (Total to Remit)
        </FormTitle>
        <RHFMoneyInput
          control={control}
          prefix="purchasedReceivables"
          errors={errors}
          selectProps={{ disabled }}
          inputProps={{ disabled }}
          amountRules={{
            required: true,
            min: 1,
            validate: isPurchasedReceivablesValid,
          }}
        />
        {errors.purchasedReceivablesAmount?.type === 'validate' && (
          <ErrorMessage>This should not be smaller than Purchased Prices</ErrorMessage>
        )}
        <FormTitle scale={5} required>
          Purchased Price (Amount Advanced)
        </FormTitle>
        <RHFMoneyInput
          control={control}
          prefix="purchasedPrice"
          errors={errors}
          selectProps={{ disabled: true }}
          inputProps={{ disabled }}
          amountRules={{
            required: true,
            min: 1,
            validate: isPurchasedPriceAmountValid,
          }}
        />
        {errors.purchasedPriceAmount?.type === 'validate' && (
          <ErrorMessage>This should not be larger than Purchased Receivables</ErrorMessage>
        )}
        <FormTitle scale={5} required>
          Remittance Percentage (Remittance Rate)
        </FormTitle>
        <Controller
          name="retrievalPercentage"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <Input {...field} type="number" min="0" max="100" step="0.01" hasError={errors.retrievalPercentage} disabled={disabled} full />
          )}
        />
        <FormTitle scale={5}>Min Retrieval Amount</FormTitle>
        <RHFMoneyInput
          control={control}
          prefix="minRemittance"
          errors={errors}
          selectProps={{ disabled: true }}
          inputProps={{ disabled }}
          amountRules={{ min: 0, validate: isMinRemittanceAmountValid }}
        />
        <FormTitle scale={5}>Max Retrieval Amount</FormTitle>
        <RHFMoneyInput
          control={control}
          prefix="maxRemittance"
          errors={errors}
          selectProps={{ disabled: true }}
          inputProps={{ disabled }}
          amountRules={{ min: 1, validate: isMaxRemittanceAmountValid }}
        />
        <FormTitle scale={5} required>
          Remittance Period (Settlement period)
        </FormTitle>
        <Controller
          name="remittancePeriod"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select {...field} hasError={errors.remittancePeriod} options={remittancePeriodOptions} disabled={disabled} full />
          )}
        />
        <FormRow>
          <Column lg={6} md={6}>
            <FormTitle scale={5} required>
              Fund Disbursement Date
            </FormTitle>
            <Controller
              name="disbursementDate"
              control={control}
              rules={{
                required: true,
                validate: isInclusivelyAfterDay('expiryDate'),
              }}
              render={({ field }) => (
                <SingleDatePicker
                  {...field}
                  id="disbursementDate"
                  hasError={errors.disbursementDate}
                  isOutsideRange={isOutsideRange('expiryDate')}
                  openDirection="up"
                  disabled={disabled}
                  full
                />
              )}
            />
            {errors.disbursementDate?.type === 'validate' && <ErrorMessage>This should be later or equal to expiry date</ErrorMessage>}
          </Column>
          <Column lg={6} md={6}>
            <FormTitle scale={5} required>
              First Invoice Date
            </FormTitle>
            <Controller
              name="firstRemittanceDate"
              control={control}
              rules={{
                required: true,
                validate: isInclusivelyAfterDay('disbursementDate'),
              }}
              render={({ field }) => (
                <SingleDatePicker
                  {...field}
                  id="firstRemittanceDate"
                  hasError={errors.firstRemittanceDate}
                  isOutsideRange={isOutsideRange('disbursementDate')}
                  openDirection="up"
                  disabled
                  full
                />
              )}
            />
            {errors.firstRemittanceDate?.type === 'validate' && (
              <ErrorMessage>This should be later or equal to disbursement date</ErrorMessage>
            )}
          </Column>
        </FormRow>
        {(isReadyToDisburse || offer?.actualDisbursementDate) && (
          <FormRow>
            <Column lg={6} md={6}>
              <FormTitle scale={5} required>
                Actual Disbursement Date
              </FormTitle>
              <Controller
                name="actualDisbursementDate"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <SingleDatePicker
                    {...field}
                    id="actualDisbursementDate"
                    hasError={errors.actualDisbursementDate}
                    isOutsideRange={isOutsideRange('expiryDate')}
                    openDirection="up"
                    disabled={!isReadyToDisburse}
                    full
                  />
                )}
              />
            </Column>
            <Column lg={6} md={6}>
              <FormTitle scale={5} required>
                Actual First Invoice Date
              </FormTitle>
              <Controller
                name="actualFirstInvoiceDate"
                control={control}
                render={({ field }) => (
                  <SingleDatePicker
                    {...field}
                    id="actualFirstInvoiceDate"
                    hasError={errors.actualFirstInvoiceDate}
                    disabled={disabled}
                    full
                  />
                )}
              />
              {errors.firstRemittanceDate?.type === 'validate' && (
                <ErrorMessage>This should be later or equal to disbursement date</ErrorMessage>
              )}
            </Column>
          </FormRow>
        )}
        <FormRow>
          <Column lg={6} md={6}>
            <FormTitle scale={5} required>
              Term Sheet Expiry
            </FormTitle>
            <Controller
              name="expiryDate"
              control={control}
              rules={{
                required: true,
                validate: validateExpiryDay(index, adminOffers),
              }}
              render={({ field }) => (
                <SingleDatePicker
                  {...field}
                  id="expiryDate"
                  hasError={errors.expiryDate}
                  openDirection="up"
                  disabled={disabled || index !== 0}
                  isOutsideRange={(day) => !isAfterDay(day, moment())}
                  full
                />
              )}
            />
            {errors.expiryDate?.type === 'validate' && (
              <ErrorMessage>
                Expiry date must be earlier than or equal to all offers&apos; fund disbursement dates and first remittance dates.
              </ErrorMessage>
            )}
          </Column>
          <GridColumn lg={6} md={6} />
        </FormRow>
        <ButtonWrapper>
          {isReadyToDisburse ? (
            <>
              <ErrorMessage>Once you have confirmed dates, you could no longer upload more agreement files.</ErrorMessage>
              <Button onClick={handleDisburseFunding} disabled={isLoading} full>
                Confirm dates
              </Button>
            </>
          ) : isEdit ? (
            <FormRow>
              <GridColumn lg={6} md={6}>
                <Button type="button" onClick={handleDeleteOffer} disabled={disabled || isLoading} full tertiary>
                  Delete
                </Button>
              </GridColumn>
              <GridColumn lg={6} md={6}>
                <Button type="submit" disabled={disabled || isLoading} full>
                  Confirm
                </Button>
              </GridColumn>
            </FormRow>
          ) : (
            <Button type="submit" disabled={disabled || isLoading} full>
              Confirm
            </Button>
          )}
          {apiError && apiError}
        </ButtonWrapper>
      </form>
    </Modal>
  );
};

export default TermSheetModal;
