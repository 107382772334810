import React from 'react';

const Bin = ({ width, height, fill, ...restProps }) => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        d="M15.7344 2.375H12.4688V1.78125C12.4688 0.799076 11.6697 0 10.6875 0H8.3125C7.33033 0 6.53125 0.799076 6.53125 1.78125V2.375H3.26562C2.44714 2.375 1.78125 3.04089 1.78125 3.85938V5.9375C1.78125 6.2654 2.0471 6.53125 2.375 6.53125H2.69948L3.21245 17.3035C3.25776 18.2548 4.03928 19 4.99166 19H14.0083C14.9608 19 15.7423 18.2548 15.7876 17.3035L16.3005 6.53125H16.625C16.9529 6.53125 17.2188 6.2654 17.2188 5.9375V3.85938C17.2188 3.04089 16.5529 2.375 15.7344 2.375ZM7.71875 1.78125C7.71875 1.45387 7.98512 1.1875 8.3125 1.1875H10.6875C11.0149 1.1875 11.2812 1.45387 11.2812 1.78125V2.375H7.71875V1.78125ZM2.96875 3.85938C2.96875 3.69569 3.10194 3.5625 3.26562 3.5625H15.7344C15.8981 3.5625 16.0312 3.69569 16.0312 3.85938V5.34375C15.8483 5.34375 3.72701 5.34375 2.96875 5.34375V3.85938ZM14.6014 17.247C14.5863 17.5641 14.3258 17.8125 14.0083 17.8125H4.99166C4.67419 17.8125 4.41368 17.5641 4.39861 17.247L3.88832 6.53125H15.1117L14.6014 17.247Z"
        fill={fill}
      />
      <path
        d="M9.5 16.625C9.8279 16.625 10.0938 16.3591 10.0938 16.0312V8.3125C10.0938 7.9846 9.8279 7.71875 9.5 7.71875C9.1721 7.71875 8.90625 7.9846 8.90625 8.3125V16.0312C8.90625 16.3591 9.17206 16.625 9.5 16.625Z"
        fill={fill}
      />
      <path
        d="M12.4688 16.625C12.7966 16.625 13.0625 16.3591 13.0625 16.0312V8.3125C13.0625 7.9846 12.7966 7.71875 12.4688 7.71875C12.1409 7.71875 11.875 7.9846 11.875 8.3125V16.0312C11.875 16.3591 12.1408 16.625 12.4688 16.625Z"
        fill={fill}
      />
      <path
        d="M6.53125 16.625C6.85915 16.625 7.125 16.3591 7.125 16.0312V8.3125C7.125 7.9846 6.85915 7.71875 6.53125 7.71875C6.20335 7.71875 5.9375 7.9846 5.9375 8.3125V16.0312C5.9375 16.3591 6.20331 16.625 6.53125 16.625Z"
        fill={fill}
      />
    </svg>
  );
};

Bin.defaultProps = {
  width: 16,
  height: 16,
  fill: '#3A0CA3',
};

export default Bin;
