export const STATUS_CODE = {
  REJECTED: 10,
  WITHDRAWN: 20,
  RECEIVED_PRE_OFFER: 100,
  REQUESTED_FUNDING_AMOUNT: 200,
  PENDING_INVESTMENT_REVIEW: 300,
  PENDING_INVESTMENT_MANAGER_REVIEW: 400,
  PENDING_USER_REVIEW_TERM_SHEET: 500,
  PENDING_CREDIT_TEAM_REVIEW: 600,
  PENDING_USER_ACCEPT_OFFER: 700,
  PENDING_USER_SIGN_AGREEMENT: 800,
  PENDING_FUND_DISBURSEMENT: 900,
  FUND_DISBURSED: 1000,
};

export const TRANSITION_INVESTMENT_MANAGER_DISAPPROVE_PROPOSAL = 'investment_manager_disapprove_proposal';
export const TRANSITION_CREDIT_TEAM_DISAPPROVE_PROPOSAL = 'credit_team_disapprove_proposal';
export const TRANSITION_INVESTMENT_MANAGER_REJECT_APPLICATION = 'investment_manager_reject_application';
export const TRANSITION_CREDIT_TEAM_REJECT_APPLICATION = 'credit_team_reject_application';
