import { STATUS_CODE } from '@constants/application';

const messages = {
  [STATUS_CODE.REJECTED]: 'Application rejected',
  [STATUS_CODE.PENDING_USER_REVIEW_TERM_SHEET]: 'Select an offer and agree on terms.',
  [STATUS_CODE.PENDING_CREDIT_TEAM_REVIEW]: 'Offer selected. Your application is under final due diligence review.',
  [STATUS_CODE.PENDING_USER_ACCEPT_OFFER]: 'Please confirm the revised offer.',
  [STATUS_CODE.PENDING_USER_SIGN_AGREEMENT]: 'Your funding application is approved.',
};

export default messages;
