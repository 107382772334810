// should be aligned with backend
export const COMPANY_FILE_TYPE = {
  BUSINESS_OWNER: 'business_owner',
  BUSINESS_OWNER_ENTITY: 'business_owner_entity',
  INCORPORATION: 'incorporation',
  PNL: 'pnl',
  BANK_STATEMENT: 'bank_statement',
  BANK_STATEMENT_VIA_FINVERSE: 'bank_statement_via_finverse',
  OTHER: 'other',
  SALES: 'sales',
  AGREEMENT: 'agreement',
};

export const FILE_TYPE_DISPLAY_NAME_MAP = {
  [COMPANY_FILE_TYPE.BUSINESS_OWNER]: 'Identity document',
  [COMPANY_FILE_TYPE.BUSINESS_OWNER_ENTITY]: 'Entity document',
  [COMPANY_FILE_TYPE.INCORPORATION]: 'Company document',
  [COMPANY_FILE_TYPE.PNL]: 'P&L',
  [COMPANY_FILE_TYPE.BANK_STATEMENT]: 'Bank statements',
  [COMPANY_FILE_TYPE.OTHER]: 'Other documents',
  [COMPANY_FILE_TYPE.SALES]: 'Sales reports',
  [COMPANY_FILE_TYPE.AGREEMENT]: 'Agreement',
};

export const SIZE_UNITS = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export const SIZE_SCALE = 1024; // = 2 ^ 10

export const MAX_SIZE_IN_BYTES = 10 * SIZE_SCALE * SIZE_SCALE; // 10 MB
