import React from 'react';

import styled, { css } from 'styled-components';

import { ratings } from './constants';

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60%;
  margin-bottom: 1rem;
  margin-left: auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: center; /*  center horiz. */
  align-items: stretch;
`;

const Cell = styled.div`
  min-width: 80px;
  align-items: center;
  text-align: center;
  padding-top: ${(props) => props.padding};
  padding-bottom: ${(props) => props.padding};
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  flex: ${(props) => props.grow};
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.size};
  border-right: solid 1px #ffffff;
`;

// RatingTable is a component for displaying the Choco grade reference table.
const RatingTable = () => {
  return (
    <div>
      <ListContainer>
        {ratings.map((item) => {
          return (
            <Row>
              {item.map(({ label, color, bgColor, grow, padding, weight, size }) => {
                return (
                  <Cell bgColor={bgColor} color={color} grow={grow} padding={padding} weight={weight} size={size}>
                    {label}
                  </Cell>
                );
              })}
            </Row>
          );
        })}
      </ListContainer>
    </div>
  );
};

export default RatingTable;
