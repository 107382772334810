import styled from 'styled-components';

import Input from '../../../components/Input';
import { media } from '@styles/breakpoints';

export const FormWrapper = styled.div`
  display: inline-block;
  width: calc(100% - 420px);
  height: calc(100vh - 94px);
  overflow: scroll;
  padding: 0 80px;
  box-sizing: border-box;
  z-index: 2;
  vertical-align: middle;

  ${Input} {
    margin: 8px 0 16px;
  }

  ${media.phone`
    display: block;
    padding: 20px 30px;
    height: initial;
    box-sizing: border-box;
    width: 100%;
  `};
`;

export const Form = styled.form`
  background-color: white;
  border-radius: 16px;
  width: 60%;

  ${media.desktop`
    width: 100%;
  `};

  ${media.phone`
    width: 100%;
  `};

  button {
    margin-top: 15px;
  }
`;

export const FormRow = styled.div`
  margin: 20px 0;
`;

export const Tagline = styled.div`
  color: ${(props) => props.theme.colors.grayThree};
  font-size: 20px;
  line-height: 30px;
  margin: 8px 0 20px;
`;

export const Error = styled.div`
  ${(props) => props.theme.text.error};
  margin: 8px 0;
`;

export const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  padding: 0 25px;

  ${media.dablet`
   padding: 0 29px;
   height: 100vh;
   justify-content: center;
 `};
`;

export const ContentDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 950px;

  ${media.dablet`
   flex-direction: column;
 `};
`;

export const CenterDiv = styled.div`
  text-align: center;
`;

export const ChocoUpLogo = styled.img`
  display: none;

  ${media.dablet`
   display: block;
   width: 15px;
   height: 30px;
   margin-bottom: 14px;
  `};
`;

export const Title = styled.div`
  ${(props) => props.theme.text.two};
  margin-bottom: 10px;
  ${media.phone`
  font-size: 24px;
 `};
`;
