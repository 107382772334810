import React, { useEffect, useState } from 'react';

import deepEqual from 'deep-equal';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import BackButton from '../../../../../components/BackButton';
import Button from '../../../../../components/Button';
import FormTitle from '../../../../../components/FormTitle';
import HR from '../../../../../components/HR';
import LoadingSkeleton from '../../../../../components/LoadingSkeleton';
import Logger from '../../../../../utils/logger';
import { Wrapper, Card } from '../../../components';
import { CONTENT_CONTAINER_ID } from '../../../constants';
import PATH from '../../../path';
import FormField from '../../components/FormField';
import TransferMethodSelect from '../../components/TransferMethodSelect';
import {
  getRecipientDisplayName,
  generatePaymentMethodOptions,
  convertFormSchemaResponse,
  getPaymentMethodCondition,
  generateRecipientPayload,
} from '../../helpers';
import {
  getWalletBeneficiarySchema,
  updateWalletBeneficiary,
  createWalletBeneficiary,
  getWalletBeneficiaryById,
} from '@api/modules/integration/airwallex';
import { GridRow, GridColumn } from '@components/Grid';
import { countryDetails } from '@/constants';
import { fetchWalletTransferFee, fetchWalletBeneficiaries } from '@redux/modules/wallet/actions';
import { useWalletAccountId, useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';
import { findFirstMatchInArray } from '@utils/dataTypes';
import { getOptionByValue } from '@utils/optionHelpers';

const ACCOUNT_FIELD_LIST = [
  'beneficiary.entity_type',
  'beneficiary.bank_details.bank_country_code',
  'beneficiary.bank_details.account_currency',
  // 'payment_method',
  // 'payment_methods',
];

const INFO_FIELD_BLACKLIST = [
  ...ACCOUNT_FIELD_LIST,
  'payment_method',
  'payment_methods',
  'beneficiary.bank_details.local_clearing_system',
  'beneficiary.date_of_birth',
];

const ENTITY_FIELD_ORDER = [
  'beneficiary.entity_type',
  'beneficiary.bank_details.bank_country_code',
  'beneficiary.bank_details.account_currency',
  'payment_method',
  'beneficiary.company_name',
  'beneficiary.bank_details.swift_code',
  'beneficiary.bank_details.account_routing_value1',
  'beneficiary.bank_details.bank_name',
  'beneficiary.bank_details.account_name',
  'beneficiary.bank_details.account_number',
  'first_name',
  'last_name',
  'beneficiary.address.country_code',
  'beneficiary.address.street_address',
  'beneficiary.address.city',
  'beneficiary.address.state',
  'beneficiary.address.postcode',
  'beneficiary.additional_info.personal_email',
  'beneficiary.additional_info.business_phone_number',
  'nickname',
];

const SectionTitle = styled.div`
  ${(props) => props.theme.text.body};
  font-weight: bold;
`;

const StyledLoadingSkeleton = styled(LoadingSkeleton)`
  margin-bottom: 24px;
`;

const RecipientId = styled.span`
  display: block;
  float: right;
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayTwo};
`;

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.micro};
  color: ${(props) => props.theme.colors.red};
`;

const RecipientIdRow = styled.div`
  div:first-child {
    display: inline-block;
    ${(props) => props.theme.text.four};
  }

  margin-bottom: 28px;
`;

const RecipientDisplayName = styled.div`
  ${(props) => props.theme.text.three};
  margin-bottom: 22px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const Detail = ({ match: { params = {} } }) => {
  const formProps = useForm();
  const history = useHistory();
  const {
    control,
    reset,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, touchedFields, dirtyFields },
  } = formProps;

  const walletAccountId = useWalletAccountId();
  const [isInitiating, toggleIsInitiating] = useState(true);
  const [isSubmitting, toggleIsSubmitting] = useState(false);
  const [isLoadingSchemas, toggleIsLoadingSchemas] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState();
  const [isLoadingPaymentMethod, toggleIsLoadingPaymentMethod] = useState(false);
  const [isPaymentMethodMenuOpen, toggleIsPaymentMethodMenuOpen] = useState(false);
  const [apiError, setApiError] = useState('');
  const [schemas, setSchemas] = useState([]);
  const [localClearingSystemSchema, setLocalMethodSchema] = useState();
  const [accountFields, setAccountFields] = useState([]);
  const [infoFields, setInfoFields] = useState([]);
  const [addressFields, setAddressFields] = useState([]);
  const [optionalFields, setOptionalFields] = useState([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);

  const watchOfAddressCountryCode = watch('beneficiary.address.country_code');
  const watchOfEntityType = watch('beneficiary.entity_type');
  const watchOfAccountCurrency = watch('beneficiary.bank_details.account_currency');
  const watchOfBankCountryCode = watch('beneficiary.bank_details.bank_country_code');
  const watchOfPaymentMethod = watch('paymentMethod');
  const paymentCurrencySchema = findFirstMatchInArray(schemas?.fields, ({ field }) => field.key === 'account_currency');
  const disabled = useCheckIsAdminViewingAnotherCompany();

  const paymentMethodSchema = findFirstMatchInArray(schemas?.fields || [], ({ field }) => field.key === 'payment_method');

  useEffect(() => {
    toggleIsLoadingPaymentMethod(true);
    const fetchLocalPaymentMethodSchemas = async () => {
      if (Array.isArray(paymentMethodSchema?.field?.options)) {
        const localMethod = findFirstMatchInArray(paymentMethodSchema?.field?.options, ({ value }) => value === 'LOCAL');

        if (localMethod) {
          const { data } = await getWalletBeneficiarySchema({
            bank_country_code: watchOfBankCountryCode,
            account_currency: watchOfAccountCurrency,
            entity_type: watchOfEntityType,
            payment_method: 'LOCAL',
          });

          const schema = findFirstMatchInArray(data.fields, ({ path }) => path === 'beneficiary.bank_details.local_clearing_system');
          if (schema) {
            setLocalMethodSchema(schema);
          }
        }
        toggleIsLoadingPaymentMethod(false);
      }
    };

    fetchLocalPaymentMethodSchemas();
  }, [JSON.stringify(paymentMethodSchema)]);

  useEffect(() => {
    const fetchAddressSchemas = async () => {
      try {
        const { data, error } = await getWalletBeneficiarySchema({
          bank_country_code: watchOfAddressCountryCode,
          account_currency: countryDetails[watchOfAddressCountryCode]?.currency,
          entity_type: watchOfEntityType,
          payment_method: 'LOCAL',
        });
        if (error) {
          throw new Error(error);
        }
        if (data && Array.isArray(data.fields)) {
          const addressFieldSchemas = data.fields.filter(({ path }) => path.indexOf('address') !== -1);
          setAddressFields(addressFieldSchemas);
        }
      } catch (err) {
        Logger.error(err);
      }
    };

    // fetchAddressSchemas works for common countries but api failed to return for some
    // hide it until api becomes more stable
    // fetchAddressSchemas();
  }, [watchOfAddressCountryCode]);

  useEffect(() => {
    const prefillPaymentMethod = () => {
      if (Array.isArray(paymentMethodOptions) && paymentMethodOptions.length > 0 && !watchOfPaymentMethod && !params?.id) {
        setValue('paymentMethod', paymentMethodOptions[0].value);
      }
    };

    prefillPaymentMethod();
  }, [JSON.stringify(paymentMethodOptions)]);

  useEffect(() => {
    const prefillCountryCodeByBankCountryCode = () => {
      const addressCountryCode = getValues('beneficiary.address.country_code');
      if (!touchedFields?.beneficiary?.address?.country_code) {
        setValue('beneficiary.address.country_code', watchOfBankCountryCode);
      }
    };

    prefillCountryCodeByBankCountryCode();
  }, [watchOfBankCountryCode]);

  useEffect(() => {
    const prefillPaymentCurrency = () => {
      const accountCurrency = getValues('beneficiary.bank_details.account_currency');
      const bankCountryDetail = countryDetails[watchOfBankCountryCode];
      if (!accountCurrency && bankCountryDetail && Array.isArray(paymentCurrencySchema?.field?.options)) {
        const currencies = bankCountryDetail.currency.split(',');

        for (const curr of currencies) {
          const option = getOptionByValue(curr, paymentCurrencySchema?.field?.options);

          if (option) {
            setValue('beneficiary.bank_details.account_currency', option.value);
            return;
          }
        }

        setValue('beneficiary.bank_details.account_currency', 'USD');
      }
    };

    prefillPaymentCurrency();
  }, [watchOfBankCountryCode, JSON.stringify(paymentCurrencySchema?.field?.options)]);

  useEffect(() => {
    const updateFormSchemas = async () => {
      toggleIsLoadingSchemas(true);
      try {
        const { data, error } = await getWalletBeneficiarySchema({
          bank_country_code: watchOfBankCountryCode,
          account_currency: watchOfAccountCurrency,
          entity_type: watchOfEntityType,
          ...getPaymentMethodCondition(watchOfPaymentMethod),
        });

        if (error) {
          setSchemas([]);
          setPaymentMethodOptions([]);
          throw new Error(error);
        }

        if (data) {
          toggleIsLoadingPaymentMethod(true);
          setSchemas(convertFormSchemaResponse(data, ENTITY_FIELD_ORDER));
        }
      } catch (err) {
        if (err.message === 'Provided parameters do not match any schema definition.') {
          return;
        } else {
          Logger.error(err);
        }
      } finally {
        toggleIsLoadingSchemas(false);
      }
    };

    if (!isInitiating && watchOfEntityType && watchOfBankCountryCode && watchOfAccountCurrency) {
      updateFormSchemas();
    }
  }, [watchOfEntityType, watchOfAccountCurrency, watchOfBankCountryCode, watchOfPaymentMethod]);

  useEffect(() => {
    const initiateEditForm = async () => {
      try {
        const { data: bene } = await getWalletBeneficiaryById(walletAccountId, params.id);

        if (bene) {
          setSelectedBeneficiary(bene);
          reset({
            ...bene,
            paymentMethod: bene.payment_methods[0] === 'SWIFT' ? 'SWIFT' : bene.beneficiary.bank_details.local_clearing_system || 'LOCAL',
          });
          const { data } = await getWalletBeneficiarySchema({
            bank_country_code: bene.beneficiary.bank_details.bank_country_code,
            account_currency: bene.beneficiary.bank_details.account_currency,
            entity_type: bene.beneficiary.entity_type,
            ...getPaymentMethodCondition(bene.payment_methods[0], bene.beneficiary.bank_details.local_clearing_system),
          });
          setSchemas(convertFormSchemaResponse(data, ENTITY_FIELD_ORDER));
          toggleIsInitiating(false);
        }
      } catch (err) {
        Logger.error(err);
      }
    };

    const initiateCreateForm = async () => {
      try {
        const { data } = await getWalletBeneficiarySchema({});
        setSchemas(convertFormSchemaResponse(data, ENTITY_FIELD_ORDER));
        toggleIsInitiating(false);
      } catch (err) {
        Logger.error(err);
      }
    };

    if (params?.id) {
      initiateEditForm();
    } else {
      initiateCreateForm();
    }
  }, [params?.id]);

  useEffect(() => {
    const updateFormFieldSchemas = () => {
      if (Array.isArray(schemas?.fields) && schemas?.fields.length > 0) {
        const accountFieldSchemas = schemas?.fields.filter(({ path }) => ACCOUNT_FIELD_LIST.indexOf(path) !== -1);
        setAccountFields(accountFieldSchemas);

        const infoFieldSchemas = schemas?.fields
          .filter(({ path, required }) => INFO_FIELD_BLACKLIST.indexOf(path) === -1 && path.indexOf('address') === -1 && required === true)
          .sort((a, b) => a.order - b.order);
        setInfoFields(infoFieldSchemas);

        const addressFieldSchemas = schemas?.fields.filter(({ path }) => path.indexOf('address') !== -1).sort((a, b) => a.order - b.order);
        setAddressFields(addressFieldSchemas);

        const optionalFieldSchemas = schemas?.fields
          .filter(({ path, required }) => INFO_FIELD_BLACKLIST.indexOf(path) === -1 && path.indexOf('address') === -1 && required === false)
          .sort((a, b) => a.order - b.order);
        setOptionalFields(optionalFieldSchemas);
      }
    };

    updateFormFieldSchemas();
  }, [JSON.stringify(schemas?.fields)]);

  useEffect(() => {
    fetchWalletTransferFee('US', 'USD');
  }, []);

  useEffect(() => {
    const updatePaymentMethodOptions = () => {
      if (Array.isArray(schemas?.fields)) {
        if (!isLoadingPaymentMethod) {
          const options = generatePaymentMethodOptions(paymentMethodSchema?.field?.options, localClearingSystemSchema?.field?.options);

          setPaymentMethodOptions(options);
        }
      } else {
        setPaymentMethodOptions([]);
      }
    };

    updatePaymentMethodOptions();
  }, [JSON.stringify(schemas?.fields), JSON.stringify(localClearingSystemSchema), isLoadingPaymentMethod]);

  useEffect(() => {
    const reopenPaymentMethodMenu = () => {
      if (!deepEqual(dirtyFields, {})) {
        toggleIsPaymentMethodMenuOpen(true);
      }
    };

    reopenPaymentMethodMenu();
  }, [JSON.stringify(paymentMethodOptions)]);

  useEffect(() => {
    if (!isInitiating && watchOfPaymentMethod) {
      document.getElementById(CONTENT_CONTAINER_ID)?.scroll({
        top: 150,
        behavior: 'smooth',
      });
    }
  }, [watchOfPaymentMethod]);

  const formUniqueId = watchOfBankCountryCode + watchOfAccountCurrency + watchOfAccountCurrency;

  const onSubmit = async (formData) => {
    toggleIsSubmitting(true);
    try {
      const { beneficiary_id, beneficiary, nickname, payment_method } = formData;
      const payload = generateRecipientPayload(formData, schemas.fields);
      const { statusCode, error } = await (params?.id
        ? updateWalletBeneficiary(walletAccountId, params.id, payload)
        : createWalletBeneficiary(walletAccountId, payload));

      if (statusCode === 200) {
        history.push(PATH.WALLET_CONTACT_LIST);
        fetchWalletBeneficiaries(walletAccountId, {
          pageNumber: 1,
          pageSize: 20,
        });
      } else {
        throw new Error(error || `Failed to ${params?.id ? 'update' : 'create'}`);
      }
    } catch (err) {
      setApiError(err.message);
      Logger.error(err);
    } finally {
      toggleIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper>
        <BackButton path={PATH.WALLET_CONTACT_LIST} />
        <div />
        {selectedBeneficiary && <RecipientDisplayName>{getRecipientDisplayName(selectedBeneficiary?.beneficiary)}</RecipientDisplayName>}
        <RecipientIdRow>
          <div>Recipient Details</div>
          {params?.id && <RecipientId>{`${'Recipient ID: '}${params?.id}`}</RecipientId>}
        </RecipientIdRow>
        {isInitiating ? (
          <Card>
            <GridRow>
              <GridColumn lg={6} sm={6}>
                <LoadingSkeleton />
              </GridColumn>
              <GridColumn lg={6} sm={6}>
                <StyledLoadingSkeleton />
                <StyledLoadingSkeleton />
                <StyledLoadingSkeleton />
              </GridColumn>
            </GridRow>
            <HR height={1} color="grayTwo" />
            <GridRow>
              <GridColumn lg={6} sm={6}>
                <LoadingSkeleton />
              </GridColumn>
              <GridColumn lg={6} sm={6}>
                <StyledLoadingSkeleton />
                <StyledLoadingSkeleton />
                <StyledLoadingSkeleton />
                <StyledLoadingSkeleton />
                <StyledLoadingSkeleton />
                <StyledLoadingSkeleton />
              </GridColumn>
            </GridRow>
          </Card>
        ) : (
          <Card>
            <GridRow>
              <GridColumn lg={6} sm={6} disableGutter>
                <SectionTitle>Recipient&apos;s account</SectionTitle>
              </GridColumn>
              <GridColumn lg={6} sm={6} disableGutter>
                {Array.isArray(accountFields) &&
                  accountFields.map((schema) => {
                    return <FormField key={schema?.path} schema={schema} formProps={formProps} />;
                  })}
                <FormTitle scale={5} required>
                  Payment method
                </FormTitle>
                {Array.isArray(paymentMethodOptions) && paymentMethodOptions.length > 0 ? (
                  <Controller
                    control={control}
                    name="paymentMethod"
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <TransferMethodSelect
                          {...field}
                          options={paymentMethodOptions}
                          isMenuOpen={isPaymentMethodMenuOpen}
                          toggleIsMenuOpen={toggleIsPaymentMethodMenuOpen}
                          bankCountryCode={watchOfBankCountryCode}
                          paymentCurrency={watchOfAccountCurrency}
                          hasError={errors.paymentMethod}
                          formProps={formProps}
                          full
                        />
                      );
                    }}
                  />
                ) : (
                  <div>Select recipient type, bank location and account currency to see options</div>
                )}
              </GridColumn>
            </GridRow>
            {isLoadingSchemas && watchOfPaymentMethod ? (
              <>
                <HR height={1} color="grayTwo" />
                <GridRow>
                  <GridColumn lg={6} sm={6} disableGutter>
                    <LoadingSkeleton />
                  </GridColumn>
                  <GridColumn lg={6} sm={6} disableGutter>
                    <StyledLoadingSkeleton />
                    <StyledLoadingSkeleton />
                    <StyledLoadingSkeleton />
                    <StyledLoadingSkeleton />
                    <StyledLoadingSkeleton />
                    <StyledLoadingSkeleton />
                  </GridColumn>
                </GridRow>
              </>
            ) : Array.isArray(infoFields) && infoFields.length > 0 ? (
              <>
                <HR height={1} color="grayTwo" />
                <GridRow>
                  <GridColumn lg={6} sm={6} disableGutter>
                    <SectionTitle>Recipient&apos;s information</SectionTitle>
                  </GridColumn>
                  <GridColumn lg={6} sm={6} disableGutter>
                    {Array.isArray(infoFields) &&
                      infoFields.length > 0 &&
                      infoFields.map((schema) => {
                        return <FormField key={formUniqueId + schema?.path} schema={schema} formProps={formProps} />;
                      })}

                    {Array.isArray(addressFields) && addressFields.length > 0 && (
                      <>
                        <HR height={1} color="grayTwo" />
                        {addressFields.map((schema) => {
                          return <FormField key={formUniqueId + schema?.path} schema={schema} formProps={formProps} />;
                        })}
                      </>
                    )}
                    {Array.isArray(optionalFields) && optionalFields.length > 0 && (
                      <>
                        <HR height={1} color="grayTwo" />
                        {optionalFields.map((schema) => {
                          return <FormField key={formUniqueId + schema?.path} schema={schema} formProps={formProps} />;
                        })}
                      </>
                    )}
                  </GridColumn>
                </GridRow>
                <ButtonWrapper>
                  <Button disabled={isSubmitting || disabled}>{params?.id ? 'Update Recipient' : 'Create Recipient'}</Button>
                </ButtonWrapper>
                <ButtonWrapper>{apiError && <ErrorMessage>{apiError}</ErrorMessage>}</ButtonWrapper>
              </>
            ) : null}
          </Card>
        )}
      </Wrapper>
    </form>
  );
};

export default Detail;
