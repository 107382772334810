import React from 'react';

import styled from 'styled-components';

import ToolTip from '../assets/icons/ToolTip';

const Wrapper = styled.div`
  background-color: ${(props) => props.backgroundColor || props.theme.colors.grayOne};
  border: ${({ border }) => border || 'none'};
  font-size: 14px;
  margin-top: 24px;
  margin-bottom: ${({ bottom }) => (bottom ? '24px' : '0')};
  padding: 16px 20px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  svg {
    display: flex;
    width: 32px;
    height: 32px;

    path {
      stroke: ${(props) => props.theme.colors.orange};
    }
  }
  > div {
    display: flex;
    flex-direction: column;
  }
`;

const Title = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: bold;
`;

const AlertCard = ({ title, description, backgroundColor, border, bottom = false }) => {
  return (
    <Wrapper backgroundColor={backgroundColor} border={border} bottom={bottom}>
      <ToolTip width={24} height={24} />
      <div>
        <Title scale={5}>{title}</Title>
        <div>{description}</div>
      </div>
    </Wrapper>
  );
};

export default AlertCard;
