import jwt from 'jsonwebtoken';

import { API_DASHBOARD_PORT, ACCESS_TOKEN_NAME, USER_COMPANY_ID_NAME, USER_ID_NAME } from '../constants';
import { getCookieProvider, getCompanyId } from '../cookies';
import { getRequest, postRequest, putRequest } from '../requests';

const dashboardUrl = `${API_DASHBOARD_PORT}/dashboard/v1`;

export const getUser = async () => {
  const cookieProvider = getCookieProvider();
  const userId = cookieProvider.get(USER_ID_NAME);
  if (userId) {
    return await getRequest(`${dashboardUrl}/user/${userId}`);
  }

  return { error: 'No user id attached' };
};

export const getUserCompany = async () => {
  const cookieProvider = getCookieProvider();
  const companyId = cookieProvider.get(USER_COMPANY_ID_NAME);
  const userId = cookieProvider.get(USER_ID_NAME);
  if (userId && companyId) {
    return await getRequest(`${dashboardUrl}/user/${userId}/company/${companyId}`);
  }

  return { error: 'No user id and company id attached' };
};

// for admin to get company details
export const getCompany = async (companyId) => {
  return await getRequest(`${dashboardUrl}/company/${companyId}`);
};
