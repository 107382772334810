import Logger from '../../../utils/logger';
import store from '../../store';
import {
  GET_PAYMENTS_INTENTS_REQUEST,
  GET_PAYMENTS_INTENTS_SUCCESS,
  GET_PAYMENTS_INTENTS_FAILURE,
  GET_PAYOUTS_REQUEST,
  GET_PAYOUTS_SUCCESS,
  GET_PAYOUTS_FAILURE,
  GET_STRIPE_TRANSACTIONS_REQUEST,
  GET_STRIPE_TRANSACTIONS_SUCCESS,
  GET_STRIPE_TRANSACTIONS_FAILURE,
  GET_STRIPE_ACCOUNT_REQUEST,
  GET_STRIPE_ACCOUNT_SUCCESS,
  GET_STRIPE_ACCOUNT_FAILURE,
  GET_PAYMENT_INTENT_DETAIL_REQUEST,
  GET_PAYMENT_INTENT_DETAIL_SUCCESS,
  GET_PAYMENT_INTENT_DETAIL_FAILURE,
} from './constants';
import {
  getPayments,
  getPayouts,
  getBalanceTransactions,
  getStripeConnectedAccounts,
  getPaymentIntentDetail,
} from '@api/modules/integration';

export const fetchPayments = async (criteria) => {
  store.dispatch({ type: GET_PAYMENTS_INTENTS_REQUEST });

  try {
    const { data } = await getPayments(
      {
        page: criteria.pageNumber,
        pageSize: criteria.pageSize,
      },
      {
        startDate: criteria.startDate,
        endDate: criteria.endDate,
        filters: criteria.filters,
      }
    );
    if (Array.isArray(data)) {
      store.dispatch({
        type: GET_PAYMENTS_INTENTS_SUCCESS,
        payload: { data, pageNumber: criteria.pageNumber },
      });
    } else {
      store.dispatch({ type: GET_PAYMENTS_INTENTS_FAILURE });
    }
  } catch (err) {
    store.dispatch({ type: GET_PAYMENTS_INTENTS_FAILURE });
    Logger.error(err);
  }
};

export const fetchPayouts = async (criteria) => {
  store.dispatch({ type: GET_PAYOUTS_REQUEST });

  try {
    const { data } = await getPayouts(
      {
        page: criteria.pageNumber,
        pageSize: criteria.pageSize,
      },
      {
        startDate: criteria.startDate,
        endDate: criteria.endDate,
        filters: criteria.filters,
      }
    );
    if (Array.isArray(data)) {
      store.dispatch({
        type: GET_PAYOUTS_SUCCESS,
        payload: { data, pageNumber: criteria.pageNumber },
      });
    } else {
      store.dispatch({ type: GET_PAYOUTS_FAILURE });
    }
  } catch (err) {
    store.dispatch({ type: GET_PAYOUTS_FAILURE });
    Logger.error(err);
  }
};

export const fetchTransactions = async (criteria) => {
  store.dispatch({ type: GET_STRIPE_TRANSACTIONS_REQUEST });

  try {
    const { data } = await getBalanceTransactions(
      {
        page: criteria.pageNumber,
        pageSize: criteria.pageSize,
      },
      {
        startDate: criteria.startDate,
        endDate: criteria.endDate,
        filters: criteria.filters,
      }
    );
    if (Array.isArray(data)) {
      store.dispatch({
        type: GET_STRIPE_TRANSACTIONS_SUCCESS,
        payload: { data, pageNumber: criteria.pageNumber },
      });
    } else {
      store.dispatch({ type: GET_STRIPE_TRANSACTIONS_FAILURE });
    }
  } catch (err) {
    store.dispatch({ type: GET_STRIPE_TRANSACTIONS_FAILURE });
    Logger.error(err);
  }
};

export const fetchStripeData = async () => {
  store.dispatch({ type: GET_STRIPE_ACCOUNT_REQUEST });
  try {
    const { data, error } = await getStripeConnectedAccounts();

    if (error) {
      throw error;
    }

    if (Array.isArray(data)) {
      store.dispatch({
        type: GET_STRIPE_ACCOUNT_SUCCESS,
        payload: data,
      });
    } else {
      store.dispatch({
        type: GET_STRIPE_ACCOUNT_SUCCESS,
        payload: [data],
      });
    }
  } catch (err) {
    store.dispatch({ type: GET_STRIPE_ACCOUNT_FAILURE });
    Logger.error(err);
  }
};

export const fetchPaymentIntentDetail = async (id) => {
  store.dispatch({ type: GET_PAYMENT_INTENT_DETAIL_REQUEST });
  try {
    const { data, error } = await getPaymentIntentDetail(id);

    if (error) {
      throw error;
    }

    if (data) {
      store.dispatch({
        type: GET_PAYMENT_INTENT_DETAIL_SUCCESS,
        payload: { id, data },
      });
    }
  } catch (err) {
    store.dispatch({ type: GET_PAYMENT_INTENT_DETAIL_FAILURE });
    Logger.error(err);
  }
};
