import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import PATH from '../path';
import AccountingReport from './AccountingReport';
import BankTransactions from './BankTransactions';
import Settlements from './BankTransactions/Settlement/Overview';
import FbPerformance from './FbPerformance';
import SalesReport from './SalesReport';

const BusinessContainer = () => {
  return (
    <Switch>
      <Route path={PATH.BUSINESS_ACCOUNTING_REPORT} component={AccountingReport} />
      <Route exact path={PATH.BUSINESS_SETTLEMENTS} component={Settlements} />
      <Route exact path={PATH.BUSINESS_BANK_TRANSACTIONS} component={BankTransactions} />
      <Route exact path={PATH.BUSINESS_FB_PERFORMANCE} component={FbPerformance} />
      <Route exact path={PATH.BUSINESS_SALES_REPORT} component={SalesReport} />
      <Redirect to={PATH.BUSINESS_SALES_REPORT} />
      {/* TODO */}
    </Switch>
  );
};

export default BusinessContainer;
