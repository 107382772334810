import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useLocation, Switch, Route, Redirect } from 'react-router-dom';
import styled, { css } from 'styled-components';

import UnderlineTab from '../../../../components/UnderlineTab';
import PATH from '../../path';
import CreateTransferPage from './CreateTransferPage';
import Detail from './Detail';
import List from './List';

const Tab = styled(UnderlineTab)`
  margin-bottom: 16px;
  ${(props) => props.theme.text.three};
`;

const Transfer = () => {
  return (
    <>
      <Tab selected>Summary</Tab>
      <Switch>
        <Route path={PATH.WALLET_TRANSFER_CREATE_PAYOUTS} component={CreateTransferPage} />
        <Route path={PATH.WALLET_TRANSFER_PAYOUT_DETAIL} component={Detail} />
        <Route path={PATH.WALLET_TRANSFER_PAYOUTS} component={List} />
        <Redirect to={PATH.WALLET_TRANSFER_PAYOUTS} />
      </Switch>
    </>
  );
};

export default Transfer;
