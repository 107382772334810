import React from 'react';

import Header from '../../../components/Header';
import Analytics from './Analytics';
import CompanyInfoCard from './CompanyInfoCard';
import FundingCard from './FundingCard';
import { useCheckIsSignUpViaShopify } from '@redux/selectors';

const HomeContainer = () => {
  const isSignUpViaShopify = useCheckIsSignUpViaShopify();
  return (
    <>
      <Header scale={2}>Home</Header>
      <CompanyInfoCard />
      {!isSignUpViaShopify && <FundingCard />}
      <Analytics />
    </>
  );
};

export default HomeContainer;
