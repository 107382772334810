import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import BreadCrumb from '../../../../../components/BreadCrumb';
import HR from '../../../../../components/HR';
import Toggle from '../../../../../components/Toggle';
import COLORS from '../../../../../styles/colors';
import { Card } from '../../../components';
import PATH from '../../../path';
import { DISABLE_MULTI_FACTOR_SETUP_MODAL } from '@modules/Modal/constants';
import { fetchCompany2fa } from '@redux/modules/company/actions';
import { showModal } from '@redux/modules/UI/actions';
import { media } from '@styles/breakpoints';

const Header = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
  margin-bottom: 8px;
`;

const Wrapper = styled.div`
  margin: 20px 0;
`;

const Container = styled.div`
  display: flex;
  margin: 10px 0;
  justify-content: space-between;

  ${media.desktop`
    flex-direction: column;
  `};
`;

const Title = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: 600;
  margin-bottom: 8px;

  ${(props) =>
    props.disabled &&
    css`
      color: ${COLORS.grayTwo};
      cursor: not-allowed;
    `}
`;

const ContentWrapper = styled.div`
  width: 80%;
`;

const Description = styled.div`
  color: ${(props) => props.theme.colors.grayThree};
  margin-bottom: 15px;

  ${(props) =>
    props.disabled &&
    css`
      color: ${COLORS.grayTwo};
      cursor: not-allowed;
    `}
`;

const Banner = styled.div`
  background: ${(props) => props.theme.colors.grayOne};
  padding: 18px 15px;
  border-radius: 5px;
  display: flex;
`;

const BannerLabel = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: 600;
  color: ${(props) => props.theme.colors.grayThree};
`;

const BannerContent = styled.div`
  margin-left: 25px;
`;

const SetupMultiFactorOverview = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const company2fa = useSelector((state) => state.company.company2fa);
  const login2fa = Array.isArray(company2fa) ? company2fa[0]?.login2fa : false;
  const transaction2fa = Array.isArray(company2fa) ? company2fa[0]?.transaction2fa : false;
  const [isChecked, toggleIsChecked] = useState(transaction2fa);

  useEffect(() => {
    toggleIsChecked(transaction2fa);
  }, [JSON.stringify(company2fa)]);

  useEffect(() => {
    fetchCompany2fa();
  }, []);

  const handleChange = (checked) => {
    toggleIsChecked(checked);
    if (checked) {
      history.push(PATH.SETTINGS_MULTI_FACTOR_SETUP);
    } else {
      dispatch(showModal(DISABLE_MULTI_FACTOR_SETUP_MODAL));
    }
  };

  return (
    <>
      <BreadCrumb
        items={[
          { value: PATH.SETTINGS_USER_MANAGEMENT, label: 'User management' },
          { label: 'Account-wide Two Factor Authentication (2FA)' },
        ]}
      />
      <Card>
        <Header>Account-wide Two Factor Authentication (2FA)</Header>
        <HR margin={10} color="grayOne" />
        <Wrapper>
          <Title>Require all users to set up and use 2FA for:</Title>
          <Container>
            <ContentWrapper>
              <Title disabled>Logging in</Title>
              <Description disabled>Require all users to setup and use 2FA when logging in their account</Description>
            </ContentWrapper>
            <Toggle disabled />
          </Container>
          <Container>
            <ContentWrapper>
              <Title>Creating a transfer</Title>
              <Description>
                Assign a specific email address to receive 2FA code, and require all users to use this code when creating a transfer
              </Description>
            </ContentWrapper>
            <Toggle onChange={handleChange} checked={isChecked} />
          </Container>
          <Banner>
            <div>
              <BannerLabel>2FA authentication method</BannerLabel>
              <BannerLabel>Email</BannerLabel>
            </div>
            <BannerContent>
              <BannerLabel>Email address</BannerLabel>
              <BannerLabel>
                {Array.isArray(company2fa) && transaction2fa === false && login2fa === false
                  ? 'Setup required'
                  : company2fa?.[0]?.transaction2faEmail}
              </BannerLabel>
            </BannerContent>
          </Banner>
        </Wrapper>
      </Card>
    </>
  );
};

export default SetupMultiFactorOverview;
