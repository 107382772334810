import styled from 'styled-components';
import Layout from '../Layout';

const Section = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 390px) {
    font-size: 14px;
  }
`;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
`;
const Block = styled.div`
  padding-right: 100px;
  padding-left: 100px;
  font-family: Poppins, sans-serif;
  color: #27272e;
  font-weight: 400;
  @media (max-width: 500px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`;
const Title = styled.h3`
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
`;
const Paragraph = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
`;
const PrivacyPolicy = () => {
  return (
    <Layout>
      <Section>
        <Container>
          <Block>
            <Title>This Privacy Policy was last updated on May, 12, 2021.</Title>
            <Paragraph>&zwj;</Paragraph>
            <Paragraph>
              This Privacy Policy describes the information Happy CP Company Limited. (“Company”, “we”, “us” and/or “our”) collects about
              you, how we use and share that information, and the privacy choices we offer. This Privacy Policy also describes the choices
              available to you regarding our use of your personal information and how you can access and update such information. This
              policy applies to information (including personally identifiable data such as contact information and IP addresses (“Personal
              Data”)) we collect when you register, access or use our website, mobile applications, products, and services, or when you
              otherwise interact with us (collectively, the “Services”). As used in this Privacy Policy, the term “Site” includes: all
              websites and all devices or applications operated by the Company that collect personal information from you; pages within each
              such website, device, or application, any equivalent, mirror, replacement, substitute, or backup website, device, or
              application; and pages that are associated with each such website, device, or application. A portion of the Personal Data we
              collect, use, share, and store may be sensitive in nature.
            </Paragraph>
            <Paragraph>
              Your access to and use of the Services is also subject to the CONTRACT FOR THE SALE AND PURCHASE OF FUTURE RECEIVABLES, as
              applicable (the “Business Agreement”), when executed, and Terms of Service at
              insight.choco-up.com/terms-conditions/index.html. If there is any contradiction between Privacy Policy and another executed
              agreement you enter into applicable to specific aspects of the Service, the other agreement shall take precedence in relation
              to the specific aspects of the Service to which it applies.
            </Paragraph>
            <Paragraph>
              As used in this Privacy Policy, the words “you” and “your” refer to you, the user of the Company’s website, device, or
              applications, as the party agreeing to the Privacy Policy. By visiting and using the Site, you accept the practices described
              in the Privacy Policy. The use of the word “including” in this Privacy Policy to refer to specific examples will be construed
              to mean “including, without limitation” or “including but not limited to” and will not be construed to mean that the examples
              given are an exclusive list of the topics covered.
            </Paragraph>
            <Paragraph>
              PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY ACCESSING OR USING THE SITE OR THE SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ,
              UNDERSTOOD AND AGREE TO BE BOUND BY THE PRIVACY POLICY. IF YOU ARE ENTERING INTO THE PRIVACY POLICY ON BEHALF OF A COMPANY,
              BUSINESS OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY AND AFFILIATES TO THE PRIVACY
              POLICY, IN WHICH CASE THE TERMS “YOU” OR “YOUR” SHALL REFER TO SUCH ENTITY AND ITS AFFILIATES. IF YOU DO NOT HAVE SUCH
              AUTHORITY, OR IF YOU DO NOT AGREE TO BE BOUND BY THE PRIVACY POLICY, YOU MAY NOT ACCESS OR USE THE SITE OR THE SERVICES.
            </Paragraph>
            <h4>Collection of information</h4>
            <Paragraph>
              The Company may collect personal information (including Personal Data) and other information about you, including but not
              limited to the following:
            </Paragraph>
            <ul role="list">
              <li>
                Registration Information: When you register for a Service, you will be asked for basic registration information, such as an
                email address and password.
              </li>
              <li>
                Identification and Banking Information: You may also be asked to provide identification information to confirm your
                identity, including your first and last name, zip code, social security number, business name and phone number, as well as
                bank statements, credit card statements, banking history and payment and transaction history.
              </li>
              <li>
                Business Information: You may also be asked to provide business information and documentation, including company size,
                financial statements, business type, organizational documents and business plans, as well as beneficial ownership
                information.
              </li>
              <li>
                Third-Party Authorization: You may also enter in certain account information for third-party sites and Internet services
                (“Third-Party Sites”).
              </li>
              <li>
                Information from Third-Party Sites: In order to display information to you or to fulfill your requests for certain products
                and services through a Service, we may collect, on your behalf, your account and other personal information from Third Party
                Sites that you register under your account via the Service. We may also collect information about you from mailing list
                providers, publicly available sources, and other third parties, including to process and manage your application and
                transactions.
              </li>
              <li>
                Information Collected by Cookies and Web Beacons: We use various technologies to collect information, and this may include
                sending cookies to your computer or mobile device. Cookies are small data files that are stored on your hard drive or in
                device memory by a website. Among other things, cookies support the integrity of our registration process, retain your
                preferences and account settings, and help evaluate and compile aggregated statistics about user activity. We may also
                collect information using web beacons. Web beacons are electronic images that may be used in our Services or emails. We may
                use web beacons to deliver cookies, count visits, understand usage, and determine whether an email has been opened and acted
                upon.{' '}
              </li>
              <li>
                Technical and Navigational Information: We may collect your computer browser type, Internet protocol address, pages visited,
                and average time spent on our Site. This information may be used, for example, to alert you to software compatibility
                issues, or it may be analyzed to improve our web design and functionality.
              </li>
              <li>
                Device ID: When you use the mobile versions of our Site, we may collect the unique device identifier assigned to that device
                by phone makers, carriers, or makers of the operating system (the 'Device ID'). Device IDs allow app developers, and others
                to uniquely identify your device for purposes of storing application preferences and other data.
              </li>
              <li>
                We may, and we may allow third party service providers to, use cookies (as noted above) or other tracking technologies to
                collect information about your browsing activities over time and across different websites following your use of the Site.
              </li>
            </ul>
            <Paragraph>
              Our Site currently does not respond to “Do Not Track” (DNT) signals and operates as described in this Privacy Policy whether
              or not a DNT signal is received. If we do so in the future, we will describe how we do so in this Privacy Policy.
            </Paragraph>
            <Paragraph>
              We take reasonable steps designed to ensure that your Personal Data that we process are limited to the Personal Data
              reasonably required in connection with the purposes set out in this Policy. We do not seek to collect or process Personal Data
              about race or ethnicity, political opinions, religious or philosophical beliefs, trade union membership, physical or mental
              health, or sexual life. Nor do we collect any information about criminal convictions and offense. Where we need to collect
              Personal Data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we
              may not be able to perform the contract we have or are trying to enter into (for example, to provide our client with
              Services). In this case, we may have to cancel a product or service but we will notify you if this is the case at the time.
            </Paragraph>
            <h4>Use of your information</h4>
            <Paragraph>
              We may use, save and analyze the information you provide about yourself and about your Third-Party Sites (together “Data
              Processing”). Such Data Processing is necessary to perform our Services to you, including in connection with advancing under
              (or in anticipation of entering) the Business Agreement and Terms of Service, pursuant to our legal obligations (e.g. “Know
              Your Client” and fraud protection) and our legitimate business interests, including:
            </Paragraph>
            <ul role="list">
              <li>to fulfill your requests for our products, programs and Services and process and analyze your application,</li>
              <li>to respond to your inquiries about our Services and verify your identity,</li>
              <li>to provide customer support,</li>
              <li>to offer you other products, programs, or services that we believe may be of interest to you,</li>
              <li>to verify the existence and condition of your accounts and conduct due diligence,</li>
              <li>to fulfill legal requirements,</li>
              <li>to protect against potentially prohibited or illegal activities,</li>
              <li>
                to improve (including through data analytics), monitor and personalize the Services and conduct our business and to provide
                targeted marketing and advertising,
              </li>
              <li>to measure the performance of and improve the Site or mobile application functionality,</li>
              <li>to manage, administer and protect our information technology infrastructure and Site security generally; and</li>
              <li>to otherwise assist with a transaction. </li>
            </ul>
            <Paragraph>
              If information is aggregated or de-identified so it is no longer reasonably associated with an identified or identifiable
              natural person, we may use it for any business purpose.
            </Paragraph>
            <Paragraph>
              We will not reuse your Personal Data for a new purpose other than the original one(s) for which it was collected, unless one
              or more of the following apply:
            </Paragraph>
            <ul role="list">
              <li>the new use is compatible with the original use such that you would reasonably expect such a similar use;</li>
              <li>we have notified you of the new use and given you an opportunity to authorize it; or</li>
              <li>the new use is otherwise permitted or required by law.</li>
            </ul>
            <Paragraph>
              By providing us with your information (including Personal Data and any data that may be subject a privacy regime of any
              jurisdictions), you are explicitly consenting to our use, collection, monitoring and processing of it in accordance with this
              Privacy Policy. You acknowledge and agree that such Personal Data may be transferred from your current location to the offices
              and servers of the Company and the authorized third parties referred to herein located in the United States and Canada.
            </Paragraph>
            <Paragraph>
              This Privacy Policy does not apply to any personal information collected by the Company other than personal information
              collected through the Services. This Privacy Policy shall not apply to any unsolicited information you provide to the Company
              through the Services or through any other means. This includes, but is not limited to, information posted to any public areas
              of the Services, such as forums, any ideas for new products or modifications to existing products, and other unsolicited
              submissions (collectively, “Unsolicited Information”). All Unsolicited Information shall be deemed to be non-confidential and
              not personal information and the Company shall be free to reproduce, use, disclose, and distribute such Unsolicited
              Information to others without limitation or attribution.
            </Paragraph>
            <Paragraph>
              This Privacy Policy applies only to the Services. The Services may contain links to other web sites and services not operated
              or controlled by the Company (the “Linked Sites”). The policies and procedures we described here do not apply to the Linked
              Sites. The links from the Services do not imply that the Company endorses or has reviewed the Linked Sites, we are not
              responsible for actions of these Linked Sites. We suggest contacting those sites directly for information on their privacy
              policies.
            </Paragraph>
            <Paragraph>
              In an ongoing effort to better understand and serve the users of the Services and improve data analytics, research and
              analysis may be compiled and analyzed on an anonymized basis, and the Company may share this anonymized data with its
              affiliates, agents and business or financing partners. This aggregate information does not identify you personally.
            </Paragraph>
            <Paragraph>
              Some of our pages utilize framing or white-labeling techniques to serve content from our partners while preserving the look
              and feel of our Site. Please be aware that in these instances you may be providing your personal information to these third
              parties and not to the Company.
            </Paragraph>
            <h4>Sharing of information</h4>
            <Paragraph>We may share personal information about you as follows and you hereby consent to such sharing:</Paragraph>
            <ul role="list">
              <li>With our subsidiaries and affiliates for purposes consistent with this Privacy Policy;</li>
              <li>
                With our service providers, agents, consultants and related third parties to perform certain business-related functions such
                as offering customer service, processing diligence or secure data storage, in each case only as necessary to provide these
                types of services to us;
              </li>
              <li>
                With third parties (including insurance providers, financing sources and platforms) to provide, maintain, and improve our
                Services, to help us with our business activities (such as performing due diligence, risk analysis, and know your customer
                compliance), and to better understand our users;
              </li>
              <li>
                In connection with, or during the negotiation of, any merger, sale of company stock or assets, financing, acquisition,
                reorganization, divestiture, or dissolution of all or a portion of our business;
              </li>
              <li>
                Basic information (name, company name, phone number, and email address) with one of our business partners to market their
                services to you;
              </li>
              <li>To respond to a request of a governmental entity, subpoenas, court orders, or legal process;</li>
              <li>To respond to claims that any posting or other content violates the rights of third parties;</li>
              <li>If required to do so by law or in the good faith belief that such action is necessary to:</li>
            </ul>
            <ul role="list">
              <li>comply with any legal obligation;</li>
              <li>
                investigate, prevent, defend against, or take other action regarding violations of our Terms of Service, our Business
                Agreement, illegal activities, suspected fraud, or situations involving potential threats to the legal rights or physical
                safety of any person or the security of our network, Sites or Services;
              </li>
              <li>generally protect against legal liability or otherwise protect our rights; or</li>
              <li>to protect the health and safety of our Sites' users or the general public in an urgent or emergency; or</li>
            </ul>
            <ul role="list">
              <li>To any other third party with your consent.</li>
            </ul>
            <Paragraph>
              The Company may use third parties to gather user’s data from financial institutions. By using our service, you grant our
              third-party providers the right, power, and authority to act on your behalf to access and transmit your personal and financial
              information from the relevant financial institution according to terms of our third-party provider’s privacy policy.
            </Paragraph>
            <Paragraph>
              We may display personal testimonials of customers on our Site. We may post your testimonial along with your name. If you wish
              to update or delete your testimonial, you can contact us by email at support@choco-up.com.
            </Paragraph>
            <h4>Your rights and data retention</h4>
            <Paragraph>
              You have the right to ask us not to process your personal information for marketing purposes. You can do this at any time by
              updating your email preferences in your account or by clicking on the “unsubscribe” link in any marketing email we send you.
              You can also opt out of marketing by emailing support@choco-up.com .
            </Paragraph>
            <Paragraph>
              Data protection law may give you the right to access your personal information, to object to the use of your personal
              information for certain purposes, and the right to erase or restrict your personal information. Further, you may request the
              transfer of your personal data to you, or (if technically feasible) to a third party in a machine-readable format. Note that
              these rights only apply to personal data which you initially provided us. You can make any such requests by emailing
              support@choco-up.com or via your user account. Certain personal information will need to be retained for administrative,
              legal, security or other legitimate business purposes.
            </Paragraph>
            <Paragraph>
              You can easily access personal information that you have submitted to us and request the correction of any inaccurate
              information you have submitted to us, via your user account. Alternatively, you can send an email that includes your contact
              information to support@choco-up.com to request any corrections, or access, to your personal information.
            </Paragraph>
            <Paragraph>
              We will handle your requests and rights in accordance with the law. This means there may be legal reasons why we cannot
              fulfill all requests. We may need to request specific information from you to help us confirm your identity and ensure your
              right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that
              personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further
              information in relation to your request to speed up our response.
            </Paragraph>
            <Paragraph>
              You will not have to pay a fee to access your Personal Data (or to exercise any of the other rights). However, we may charge a
              reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your
              request in these circumstances.
            </Paragraph>
            <Paragraph>
              We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request
              is particularly complex or you have made a number of requests. In this case, we will notify you and keep you
              updated.&nbsp;&nbsp;
            </Paragraph>
            <Paragraph>
              You may have the right to lodge complaints with a data protection authority regarding the processing of your Personal Data, we
              would, however, appreciate the chance to deal with your concerns beforehand, so please contact us in the first instance.
            </Paragraph>
            <Paragraph>Retention:</Paragraph>
            <Paragraph>
              We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the
              purposes of satisfying any legal, administrative, security, compliance, accounting, or reporting requirements. We will need to
              retain your information for as long as your account is active and for legitimate business interests (including if any amounts,
              including Specified Amounts (as defined in a Business Agreement) are outstanding). We will retain and use your information as
              necessary to comply with (and demonstrate compliance with) our legal obligations, resolve disputes, collect on amounts owing
              and enforce our agreements. In some circumstances we may anonymize your Personal Data (so that it can no longer be associated
              with you) for research or statistical purposes in which case we may use this information indefinitely without further notice
              to you.
            </Paragraph>
            <h4>Security</h4>
            <Paragraph>
              We take reasonable measures, including firewall barriers, SSL encryption techniques, and authentication procedures, to help
              protect personal information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction.
              However, no Internet or email transmission is ever fully secure or error free. In particular, email sent to or from the
              Services may not be secure. Therefore, you should take special care in deciding what information you send to us via email.
              Please keep this in mind when disclosing any personal information to the Company via the Internet. If you have any questions
              regarding the security of your personal information, you can contact us by email at support@choco-up.com .
            </Paragraph>
            <h4>Email communications</h4>
            <Paragraph>
              We may provide our registered customers with summaries of their account information and email alerts. We may also allow users
              to subscribe to email newsletters and from time to time may transmit emails promoting the Company or third-party goods or
              services.
            </Paragraph>
            <Paragraph>
              You have the ability to opt out of receiving our promotional emails and to terminate their newsletter subscriptions by
              following the instructions in the emails or by contacting us by email at support@choco-up.com . Opting out in this manner will
              not end transmission of service-related emails, such as email alerts.
            </Paragraph>
            <h4>Changes to privacy policy</h4>
            <Paragraph>
              We reserve the right, at our discretion, to make changes to this Privacy Policy from time to time without notice, so please
              review it frequently. You may review updates to our Terms of Service and Privacy Policy at any time via links on
              insight.choco-up.com . You agree to accept electronic communications and/or postings of revised versions of this Privacy
              Policy on insight.choco-up.com and agree that such electronic communications or postings constitute notice to you of the
              revised version of this Privacy Policy. Changes take effect immediately upon posting. Your continued use of the Services after
              any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy.
            </Paragraph>
            <h4>Children</h4>
            <Paragraph>
              The Company does not knowingly collect Personal Data from persons under the age of 18. If you are under the age of 18, please
              do not submit any Personal Data through the Services. We encourage parents and legal guardians to monitor their children’s
              Internet usage and to help enforce our Privacy Policy by instructing their children never to provide Personal Data on the
              Services without their permission. If you have reason to believe that a person under the age of 18 has provided Personal Data
              to the Company through the Services, please contact us, and we will endeavor to delete that information from our databases.
            </Paragraph>
            <h4>Other</h4>
            <Paragraph>
              The laws and regulations in different countries may impose different (and even lower or conflicting) requirements on the
              Internet and data protection. The servers that make the Site available are located in the Singapore and Hong Kong. Please note
              that any information you provide may be transferred to Singapore and Hong Kong, and by using the Site or providing the Company
              personal information (including Personal Data), you authorize this transfer.
            </Paragraph>
            <h4>Got Questions?</h4>
            <Paragraph>
              If you have questions or concerns regarding this Policy, or if you have any questions or suggestions, please contact
              support@choco-up.com .
            </Paragraph>
          </Block>
        </Container>
      </Section>
    </Layout>
  );
};

export default PrivacyPolicy;
