import { LOGOUT_USER } from '../auth/constants';
import { SELECT_COMPANY_REQUEST } from '../company/constants';
import { SWITCH_TO_ADMIN_VIEW, SWITCH_TO_USER_VIEW } from '../UI/constants';
import {
  GET_XERO_BANK_TRANSACTION_REQUEST,
  GET_XERO_BANK_TRANSACTION_FAILURE,
  GET_XERO_BANK_TRANSACTION_SUCCESS,
} from './constants';

const initialState = {
  totalNumber: 0,
  filter: {},
  dataPageMap: {
    1: [],
  },
  isSelectingTrx: false,
};

const bankTransactionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_XERO_BANK_TRANSACTION_SUCCESS:
      return {
        ...state,
        totalNumber: action.payload.totalNumber,
        dataPageMap: {
          ...state.dataPageMap,
          [action.payload.pageNumber]: action.payload.transactions,
        },
      };
    // case SWITCH_TO_ADMIN_VIEW:
    // case SELECT_COMPANY_REQUEST:
    case SWITCH_TO_USER_VIEW:
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default bankTransactionReducer;
