export const REVENUE_RECORD_STATUS_LABEL = {
  DRAFT: 'Pending submit',
  SUBMITTED: 'Pending confirmation',
  CONFIRMED: 'User confirmed',
  APPEALING: 'Under Appeal',
  FULLY_USED: 'Fully used',
  PARTIALLY_USED: 'Partially used',
  TXN_PARTIALLY_USED: 'Transactions partially used',
};

export const REVENUE_RECORD_STATUS_VALUE = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  CONFIRMED: 'CONFIRMED',
  APPEALING: 'APPEALING',
  FULLY_USED: 'FULLY_USED',
  PARTIALLY_USED: 'PARTIALLY_USED',
  TXN_PARTIALLY_USED: 'TXN_PARTIALLY_USED',
};

export const INVOICE_PAYMENT_METHOD = {
  WHATEVER: 'WHATEVER',
  BANK: 'BANK',
  AIRWALLEX: 'AIRWALLEX_B',
};
