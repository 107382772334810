export const generateCompanyOptions = (companyMap) => {
  const companies = Object.values(companyMap);
  return companies
    .filter((company) => !!company)
    .map((company) => ({
      value: company.id,
      label: company.companyName,
    }))
    .sort((prev, next) => prev.label?.localeCompare(next.label));
};
