import { useEffect, useState } from 'react';

const defaultUseScriptOptions = { isAsync: true };

export const useScript = (url, options) => {
  useEffect(() => {
    const { condition, isAsync } = {
      ...defaultUseScriptOptions,
      ...options,
    };

    const script = document.createElement('script');

    script.src = url;
    script.async = isAsync;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export const useDebounceSearch = (searchKeyword) => {
  const [debounceSearchKeyword, setDebounceSearchKeyword] = useState('');
  useEffect(() => {
    const timeOutId = setTimeout(() => setDebounceSearchKeyword(searchKeyword), 500);
    return () => clearTimeout(timeOutId);
  }, [searchKeyword]);
  return { debounceSearchKeyword };
};
