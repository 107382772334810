import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import Email from '../../components/Email';
import PlatformLogo from '../../components/PlatformLogo';
import { MANUAL_INTEGRATION_MODAL, UPLOAD_FILES_MODAL, MESSAGE_MODAL } from '../Modal/constants';
import AccountingTable from './AccountingTable';
import { PLModalTitle, PLModalDescription, PLAcceptFileType } from './PLModalComponents';
import { deleteIntegratedAccount, integrateAccount } from '@api/modules/application';
import { uploadCompanyFiles } from '@api/modules/dashboard/file';
import { FileRow } from '@components/FileDropzone';
import { SUPPORT_EMAIL } from '@constants/email';
import { COMPANY_FILE_TYPE } from '@constants/file';
import { ACCOUNTING_PLATFORM_LOGOS, MANUAL_INTEGRATION_PLATFORMS } from '@constants/platform';
import { fetchIntegrations } from '@redux/modules/application/actions';
import { fetchFilesByType } from '@redux/modules/company/actions';
import { showModal } from '@redux/modules/UI/actions';
import { useIsUnableToEditUnderAdminView } from '@redux/selectors';
import { accountingPlatformsFilter } from '@utils/platformHelpers';

const AccountingHeader = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: bold;
  margin: 10px 0;
`;

const Description = styled.div`
  color: ${(props) => props.theme.colors.grayThree};
  margin-bottom: 15px;
`;

const UploadPNLLabel = styled.span`
  ${(props) => props.theme.text.link};
`;

export const deletePlatformModalDescription = (
  <Description>
      Your account is successfully connected and in progress of data integration. Disconnecting the account may interrupt the system
      operations. If you confirm to proceed, please contact <Email address={SUPPORT_EMAIL} />
    </Description>
);

const AccountingSection = () => {
  const dispatch = useDispatch();
  const unableToEditAnotherCompany = useIsUnableToEditUnderAdminView();
  const integrations = useSelector((state) => state.application.integrations);
  const accountingAccounts = integrations.filter(({ platformName }) => accountingPlatformsFilter(platformName));

  const pnlFiles = useSelector((state) => state.company.files[COMPANY_FILE_TYPE.PNL]);
  const [isLoading, toggleIsLoading] = useState(false);

  const showManualIntegrationModal = (platformName) => {
    dispatch(
      showModal(MANUAL_INTEGRATION_MODAL, {
        companyName: platformName,
      })
    );
  };

  const onDeletePlatformClick = (platformName, integrationId) => async () => {
    if (MANUAL_INTEGRATION_PLATFORMS.indexOf(platformName) === -1) {
      dispatch(
        showModal(MESSAGE_MODAL, {
          title: 'Disconnect account',
          description: deletePlatformModalDescription,
          showLeftButton: false,
          showRightButton: false,
          showCloseButton: true,
        })
      );
    } else {
      await deleteIntegratedAccount({
        platformName,
        integrationId,
      });
      await fetchIntegrations();
    }
  };

  const integrateAccounting = (value, disabled) => async () => {
    if (!isLoading && !disabled) {
      toggleIsLoading(true);
      if (MANUAL_INTEGRATION_PLATFORMS.indexOf(value) !== -1) {
        showManualIntegrationModal(value);
      } else {
        const newWin = window.open('', 'newWin', 'width=520,height=680');
        const { data } = await integrateAccount(value);
        if (data && data.url) {
          newWin.location.href = data.url;
        }
      }
    }

    toggleIsLoading(false);
  };

  const handleSubmitPLFiles = async (files) => {
    if (Array.isArray(files) && files.length > 0) {
      await Promise.all(files.map((file) => uploadCompanyFiles(COMPANY_FILE_TYPE.PNL, [file])));
      await fetchFilesByType(COMPANY_FILE_TYPE.PNL);
    }
  };

  const showPLModal = () => {
    dispatch(
      showModal(UPLOAD_FILES_MODAL, {
        title: PLModalTitle,
        description: PLModalDescription,
        uploadedFiles: pnlFiles,
        onConfirmClick: handleSubmitPLFiles,
        acceptFileType: PLAcceptFileType,
        disabled: unableToEditAnotherCompany,
      })
    );
  };

  useEffect(() => {
    fetchIntegrations();
    fetchFilesByType(COMPANY_FILE_TYPE.PNL);
  }, []);

  return (
    <>
      <AccountingHeader>Accounting</AccountingHeader>
      <Description>Connect your accounting platform</Description>
      {Array.isArray(accountingAccounts) && accountingAccounts.length > 0 && (
        <AccountingTable
          accounts={accountingAccounts}
          disabled={unableToEditAnotherCompany}
          onDeletePlatformClick={onDeletePlatformClick}
        />
      )}
      {ACCOUNTING_PLATFORM_LOGOS.map(({ value, logoUrl, displayName, disabled, width }) => {
        const isDisabled = disabled || unableToEditAnotherCompany;

        return (
          <PlatformLogo
            value={value}
            disabled={isDisabled}
            onClick={integrateAccounting(value, isDisabled)}
            logoUrl={logoUrl}
            displayName={displayName}
            width={width}
          />
        );
      })}
      <div>
        Don’t see your accounting software above? <UploadPNLLabel onClick={showPLModal}>Upload P&L manually</UploadPNLLabel>
      </div>
      {Array.isArray(pnlFiles) && pnlFiles.length > 0 && (
        <div>
          Uploaded P&L documents
          {pnlFiles.map((f) => (
            <FileRow key={f.attachment_id}>{f.file_name}</FileRow>
          ))}
        </div>
      )}
    </>
  );
};

export default AccountingSection;
