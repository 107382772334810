import { useSelector } from 'react-redux';

import { useIsUserAssignedAdmin } from '@redux/selectors';

const useCheckRoleHasSomePermissions = (permissions) => {
  const adminRole = useSelector((state) => state.user.profile.adminRole);
  return permissions.some((permission) => adminRole.permissions?.indexOf(permission) !== -1);
};

export const useCheckRoleHasAllPermissions = (permissions) => {
  const adminRole = useSelector((state) => state.user.profile.adminRole);
  return permissions.every((permission) => adminRole.permissions?.indexOf(permission) !== -1);
};

export const useUserViewAssignedCompanyInfo = () => {
  return useCheckRoleHasSomePermissions(['view_assigned_company_info', 'view_all_company_info']);
};

export const useCanAssignCompanyToAdmin = () => {
  const adminRole = useSelector((state) => state.user.profile.adminRole);

  return adminRole.permissions?.indexOf('assign_company_to_admin_user') !== -1;
};

export const useCanUserJudgeFundingOffer = () => {
  return useCheckRoleHasSomePermissions(['approve_funding_offer', 'decline_funding_offer']);
};

export const useCanUserJudgeFundingProposal = () => {
  return useCheckRoleHasSomePermissions(['approve_funding_proposal', 'decline_funding_proposal']);
};

export const useCanUserSubmitFundingProposal = () => {
  return useCheckRoleHasAllPermissions(['submit_funding_proposal']);
};

export const useCanUserApproveFundingProposal = () => {
  return useCheckRoleHasAllPermissions(['approve_funding_proposal']);
};

// be noted that an ordinary merchant account does not have permissions in adminRole
// which leads to a falsy result of this question
export const useCanUserEditCompanyInfo = () => {
  return useCheckRoleHasAllPermissions(['edit_company_info']);
};

export const useCanUserDeclineFundingProposal = () => {
  return useCheckRoleHasAllPermissions(['decline_funding_proposal']);
};

export const useIsUserSalesAnalyst = () => {
  const adminRoleName = useSelector((state) => state.user.profile.adminRole.name);
  return adminRoleName === 'Sales analyst';
};

export const useCanAdminHandleSettlement = () => {
  const checkRoleHasAllPermissions = useCheckRoleHasAllPermissions(['handle_settlement']);
  const isUserAssignedAdmin = useIsUserAssignedAdmin();

  return checkRoleHasAllPermissions && isUserAssignedAdmin;
};
