import React, { ForwardedRef, forwardRef } from 'react';

import styled, { css } from 'styled-components';

import COLORS from '../styles/colors';
import { FormError } from './Input';

type StyledCheckboxContainerProps = {
  textSide?: string;
};

export const StyledCheckboxContainer = styled.div`
  ${(props: StyledCheckboxContainerProps) =>
    props.textSide &&
    css`
      display: flex;
    `}
`;

const StyledCheckbox = styled.input`
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid ${COLORS.black};
  border-radius: 3px;
  background-color: ${COLORS.grayFour};
  ${(props) =>
    props.hasError &&
    css`
      border: 2px solid ${COLORS.red};
    `}
  margin: 0;
  :checked {
    border: 2px solid ${COLORS.black};
    background-image: url('/icons/check-mark.svg');
    background-position: center center;
    background-repeat: no-repeat;
  }
  ${(props: Pick<StyledCheckboxContainerProps, 'textSide'>) =>
    props.textSide &&
    css`
      margin-top: 5px;
      flex: 0 0 20px;
    `}
`;

export const CheckboxText = styled.span`
  vertical-align: middle;
`;

const Checkbox = forwardRef(
  ({ value, onClick = () => {}, hasError, textSide, disabled, className, ...restProps }, ref: ForwardedRef<any>) => {
    return (
      <StyledCheckboxContainer textSide={textSide}>
        <StyledCheckbox
          className={className}
          type="checkbox"
          hasError={hasError}
          checked={value}
          onChange={onClick}
          disabled={disabled}
          ref={ref}
          textSide={textSide}
          {...restProps}
        />
        {textSide && (
          <div style={{ marginLeft: '10px' }}>
            <CheckboxText onClick={onClick}>
              <strong>{textSide}</strong>
            </CheckboxText>
            <div>{hasError && hasError.type === 'required' && <FormError>Required</FormError>}</div>
          </div>
        )}
      </StyledCheckboxContainer>
    );
  }
);

export default Checkbox;
