export const messages = [
  {
    title: 'How much funding can I get?',
    // content: 'How much funding you get is decided by our proprietary data-driven risk management technologies and algorithms.\nThe decision is based on multiple factors related to your unit economics, monthly revenue, and monthly transaction rate.\nFeel queasy about robots? No worries! Each decision is thoroughly reviewed by risk management experts before indicative term sheets are finalized.',
    contents: [
      {
        type: 'text',
        content: 'How much funding you get is decided by our proprietary data-driven risk management technologies and algorithms.\nThe decision is based on multiple factors related to your unit economics, monthly revenue, and monthly transaction rate.\nFeel queasy about robots? No worries! Each decision is thoroughly reviewed by risk management experts before indicative term sheets are finalized.',
      },
      {
        type: 'image',
        link: '',
        width: '',
        height: '',
      },
    ],
  },
  {
    title: 'How long does it take to be funded?',
    contents: [
      {
        type: 'text',
        content: 'Indicative term sheets can be given out in as fast as 48 hours. You will receive funding within 24 hours of agreeing to the set terms.',
      },
    ],
  },
  {
    title: 'Am I getting a loan?',
    contents: [
      {
        type: 'text',
        content: 'Choco Up does not give out loans.\nWe use a unique revenue share model in order to fund our partners. We do not require equity. Our flexible repayment scheme means that there is no additional interest accrued.',
      },
    ],
    content: 'Choco Up does not give out loans.\nWe use a unique revenue share model in order to fund our partners. We do not require equity. Our flexible repayment scheme means that there is no additional interest accrued.',
  },
  {
    title: 'How do I qualify for funding',
    contents: [
      {
        type: 'text',
        content: 'We are sector agnostic and provide funding to any types of businesses. Our minimum eligibility criteria includes: 6+ months of company operating history and revenue greater than USD 10k/month.',
      },
    ],
    content: 'We are sector agnostic and provide funding to any types of businesses. Our minimum eligibility criteria includes: 6+ months of company operating history and revenue greater than USD 10k/month.',
  },
  {
    title: 'What is the application process?',
    contents: [
      {
        type: 'text',
        content: 'It’s easy!\nFirst fill out the application form linked above for your product of choice and connect your sales and marketing accounts. We use this data to determine your funding offers and eligibility. If you are eligible for funding you will be asked to attend an exploratory meeting with one of our investment managers.\nOnce terms have been discussed, you will be guided to sign your contract, fill in your business details and connect your bank account. After a short review by our diligence team, your funds will be available to you to spend. ',
      },
    ],
    content: 'It’s easy!\nFirst fill out the application form linked above for your product of choice and connect your sales and marketing accounts. We use this data to determine your funding offers and eligibility. If you are eligible for funding you will be asked to attend an exploratory meeting with one of our investment managers.\nOnce terms have been discussed, you will be guided to sign your contract, fill in your business details and connect your bank account. After a short review by our diligence team, your funds will be available to you to spend. ',
  },
  {
    title: 'Why do I need to connect my sales accounts',
    contents: [
      {
        type: 'text',
        content: 'We take the bias out of traditional funding using a data-driven approach to generate funding offers. No pitches, no stress, our offers are built purely on your successes. We use your sales data to determine your funding offers in order for you to get funded.',
      },
    ],
    content: 'We take the bias out of traditional funding using a data-driven approach to generate funding offers. No pitches, no stress, our offers are built purely on your successes. We use your sales data to determine your funding offers in order for you to get funded.',
  },
  {
    title: 'Are there any fees?',
    contents: [
      {
        type: 'text',
        content: 'Choco Up fees have no periodic nor compounding interest so you will know exactly how much you are paying up front before you accept the offer and that fee will never increase throughout the repayment process.',
      },
    ],
    content: 'Choco Up fees have no periodic nor compounding interest so you will know exactly how much you are paying up front before you accept the offer and that fee will never increase throughout the repayment process.',
  },
  {
    title: 'How does payment work?',
    contents: [
      {
        type: 'text',
        content: 'Payments begin as soon as your advance is active and have no correlation to your spending. Payments are automatically debited from your bank account and are a percentage of your revenues from your connected sales accounts. This percentage is predetermined when you select your funding offer and will remain consistent throughout your advance. The frequency of payment depends on which product you are using and which sales platforms you sell on.',
      },
    ],
    content: 'Payments begin as soon as your advance is active and have no correlation to your spending. Payments are automatically debited from your bank account and are a percentage of your revenues from your connected sales accounts. This percentage is predetermined when you select your funding offer and will remain consistent throughout your advance. The frequency of payment depends on which product you are using and which sales platforms you sell on.',
  },
  {
    title: 'Is my data safe?',
    contents: [
      {
        type: 'text',
        content: 'All information you share with Choco Up is secured during transport and at rest. We are committed to protecting your data with military grade encryption (RSA-4096 and AES-256). We also limit what we have access to. We can never see your login information for any of your connected accounts, we only have “read” level access to the data you share with us – in other words, we are never able to change or modify in any way your shared data. We have securely connected more than 25,000 customer data sources.',
      },
    ],
    content: 'All information you share with Choco Up is secured during transport and at rest. We are committed to protecting your data with military grade encryption (RSA-4096 and AES-256). We also limit what we have access to. We can never see your login information for any of your connected accounts, we only have “read” level access to the data you share with us – in other words, we are never able to change or modify in any way your shared data. We have securely connected more than 25,000 customer data sources.',
  },
  {
    title: 'Where can I find help with my advance?',
    contents: [
      {
        type: 'text',
        content: 'Our success team is standing by to help you with anything that may arise during your time with Choco Up. You can reach us by phone or email. We also have our blog full of articles, videos and how-tos. Additionally, our post-investment relationship managers are professionally trained to handle your account.',
      },
    ],
  },
  {
    title: 'Can I receive more funding?',
    contents: [
      {
        type: 'text',
        content: 'Since we are for founders, by founders, we want to stick with you throughout your entire business lifecycle. This means when you grow, we want to grow with you.\nWe have made it even easier now to receive additional funding when you need it most.\nYour Choco Up advance is a one-time advance of funds available to you to grow your business. As you draw closer to spending the entirety of your advance you may need more capital. A top up is a brand new advance of funds that takes into account your recent business growth, as well as any outstanding balance. This means that when you take a top-up, your previous remaining balance will be rolled into the new advance, so you can receive more funding while still paying for your previous advance.',
      },
    ],
  },
];
