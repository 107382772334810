import { stringify } from 'query-string';

import { integrationEndpoint } from '..';
import { dashboardUrl } from '../../dashboard';
import { getCompanyId } from '@api/cookies';
import { postRequest, getRequest } from '@api/requests';

export const generateFinverseUrl = async (payload) => {
  const companyId = getCompanyId();
  return await postRequest(`${integrationEndpoint}/finverse/company/${companyId}/auth/customer/token`, payload);
};

export const getFinverseFilterValues = async () => {
  const companyId = getCompanyId();
  return await getRequest(`${dashboardUrl}/company/${companyId}/finverse/filters`);
};

export const getFinverseTransactionsByAccount = async (query, payload) => {
  const companyId = getCompanyId();
  return await postRequest(
    `${dashboardUrl}/company/${companyId}/finverse/bank-transactions?${stringify(query)}`,
    payload,
  );
};

export const getIntegratedFinverseInstitutions = async () => {
  const companyId = getCompanyId();
  return await getRequest(`${dashboardUrl}/company/${companyId}/finverse/institutions`);
};

export const getSupportedFinverseInstitutions = async () => {
  return await getRequest(`${integrationEndpoint}/finverse/institutions`);
};

export const refreshFinverseAccount = async (institutionId) => {
  const companyId = getCompanyId();
  return await getRequest(`${integrationEndpoint}/finverse/company/${companyId}/institution/${institutionId}/refresh`);
};

export const getFinverseAccountBalanceHistory = async (payload) => {
  const companyId = getCompanyId();
  return await postRequest(`${dashboardUrl}/company/${companyId}/finverse/balance-history`, payload);
};
