import React from 'react';

import FormTitle from '../../../../components/FormTitle';
import MoneyInput from '../../../../components/MoneyInput';
import { Description, ErrorMessage } from '../../components';

const IncomeSection = ({
  currency,
  currencyOptions,
  onCurrencyChange,
  amount,
  onAmountChange,
  showError,
  disabled,
}) => (
  <>
    <FormTitle required>Your most recent monthly revenue</FormTitle>
    <Description>
      Please indicate your total revenue from the last month, from all channels, including the accounts you have linked
      above.
    </Description>
    <MoneyInput
      menuPlacement="bottom"
      currency={currency}
      currencyOptions={currencyOptions}
      amount={amount}
      onCurrencyChange={onCurrencyChange}
      onAmountChange={onAmountChange}
      selectProps={{ isDisabled: disabled }}
      inputProps={{ disabled }}
    />
    {showError && <ErrorMessage>Please provide your total monthly revenue.</ErrorMessage>}
  </>
);

export default IncomeSection;
