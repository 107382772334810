import { applicationEndpoint } from '.';
import { getCompanyId } from '@api/cookies';
import { getRequest, postRequest, putRequest, deleteRequest } from '@api/requests';

export const deleteOfferById = async (id) => {
  const companyId = getCompanyId();
  return await deleteRequest(`${applicationEndpoint}/company/${companyId}/offer/${id}`);
};

export const editOfferById = async (id, payload) => {
  const companyId = getCompanyId();
  return await putRequest(`${applicationEndpoint}/company/${companyId}/offer/${id}`, payload);
};

export const createOffer = async (payload) => {
  const companyId = getCompanyId();
  return await postRequest(`${applicationEndpoint}/company/${companyId}/offer`, payload);
};

export const getDraftOffers = async (applicationId) => {
  const companyId = getCompanyId();
  return await getRequest(`${applicationEndpoint}/company/${companyId}/application/${applicationId}/offers/draft`);
};
