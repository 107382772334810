export const BANK_STATUS = {
  INACTIVE: -1,
  ACTIVE: 0,
};

export const BANK_SOURCE = {
  XERO: 'xero',
  MANUAL: 'manual',
  FINVERSE: 'finverse',
};
