import React, { useState, useEffect } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import Button from '../../../../components/Button';
import FormTitle from '../../../../components/FormTitle';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import { integrateAccount } from '@api/modules/application';
import { FormRow, GridColumn } from '@components/Grid';
import { fetchIntegrations } from '@redux/modules/application/actions';
import { hideModal } from '@redux/modules/UI/actions';

const ErrorMessage = styled.div`
 ${(props) => props.theme.text.error};
 ${(props) => props.theme.text.micro};
`;

const ManualIntegrationForm = ({ platformName }) => {
  const { control, handleSubmit, formState: { errors } } = useForm();
  const [isLoading, toggleIsLoading] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchIntegrations();
  }, []);

  const onSubmit = async (formData) => {
    toggleIsLoading(true);
    const companyName = formData.companyName;

    const { data, error } = await integrateAccount(platformName, {
      channel_account_id: companyName,
    });

    if (error) {
      //
    } else {
      await fetchIntegrations();
      dispatch(hideModal());
    }

    toggleIsLoading(false);
  };

  return (
    <Modal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTitle scale={3}>
          Please enter company name of your account
        </FormTitle>
        <Controller
          name="companyName"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              hasError={errors.companyName}
              placeholder="Company name"
              full
            />
          )}
        />
        {errors.companyName && (
          <ErrorMessage>
            Please enter your company name
          </ErrorMessage>
        )}
        <FormRow>
          <GridColumn lg={6} md={6}>
            <Button type="submit" disabled={isLoading} primary full>Confirm</Button>
          </GridColumn>
          <GridColumn lg={6} md={6}>
            <Button onClick={() => dispatch(hideModal())} tertiary full>Cancel</Button>
          </GridColumn>
        </FormRow>
      </form>
    </Modal>
  );
};

export default ManualIntegrationForm;
