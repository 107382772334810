import { LOGIN_USER, LOGOUT_USER } from '../auth/constants';
import { SELECT_COMPANY_REQUEST } from '../company/constants';
import { SWITCH_TO_ADMIN_VIEW, SWITCH_TO_USER_VIEW } from '../UI/constants';
import {
  ADD_OFFER,
  DELETE_OFFER,
  EDIT_OFFER,
  SUBMIT_OFFERS_REQUEST,
  SUBMIT_OFFERS_SUCCESS,
  SUBMIT_OFFERS_FAILURE,
  GET_ADMIN_OFFERS_REQUEST,
  GET_ADMIN_OFFERS_SUCCESS,
  GET_ADMIN_OFFERS_FAILURE,
  GET_USER_OFFERS_REQUEST,
  GET_USER_OFFERS_SUCCESS,
  GET_USER_OFFERS_FAILURE,
  APPROVE_OFFERS_REQUEST,
  APPROVE_OFFERS_SUCCESS,
  APPROVE_OFFERS_FAILURE,
  REJECT_OFFERS_REQUEST,
  REJECT_OFFERS_SUCCESS,
  REJECT_OFFERS_FAILURE,
  RETURN_OFFERS_REQUEST,
  RETURN_OFFERS_SUCCESS,
  RETURN_OFFERS_FAILURE,
  GET_DRAFT_OFFERS_REQUEST,
  GET_DRAFT_OFFERS_SUCCESS,
  GET_DRAFT_OFFERS_FAILURE,
} from './constants';
import { getNextOffersAfterEdit, getNextOffersAfterDelete } from './helpers';

const initialState = {
  isLoading: false,
  isAccepting: false,
  adminOffers: [],
  userOffers: [],
  error: null,
};

const offerReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_OFFER:
      return {
        ...state,
        adminOffers: [
          ...state.adminOffers,
          {
            id: action.payload.id,
            ...action.payload.offer,
          },
        ],
      };
    case EDIT_OFFER:
      return {
        ...state,
        adminOffers: getNextOffersAfterEdit(state, action),
      };
    case DELETE_OFFER:
      return {
        ...state,
        adminOffers: getNextOffersAfterDelete(state, action),
      };
    case SUBMIT_OFFERS_REQUEST:
    case GET_ADMIN_OFFERS_REQUEST:
    case GET_USER_OFFERS_REQUEST:
    case APPROVE_OFFERS_REQUEST:
    case REJECT_OFFERS_REQUEST:
    case RETURN_OFFERS_REQUEST:
    case GET_DRAFT_OFFERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SUBMIT_OFFERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SUBMIT_OFFERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case GET_ADMIN_OFFERS_SUCCESS:
    case GET_DRAFT_OFFERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminOffers: action.payload,
        error: null,
      };
    case GET_USER_OFFERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userOffers: action.payload,
        error: null,
      };
    case GET_ADMIN_OFFERS_FAILURE:
    case GET_USER_OFFERS_FAILURE:
    case GET_DRAFT_OFFERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case APPROVE_OFFERS_SUCCESS:
    case REJECT_OFFERS_SUCCESS:
    case RETURN_OFFERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case APPROVE_OFFERS_FAILURE:
    case REJECT_OFFERS_FAILURE:
    case RETURN_OFFERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    // case SWITCH_TO_ADMIN_VIEW:
    // case SELECT_COMPANY_REQUEST:
    case SWITCH_TO_USER_VIEW:
    case LOGIN_USER:
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default offerReducer;
