import { LOGIN_USER, LOGOUT_USER } from '../auth/constants';
import {
  SWITCH_TO_ADMIN_VIEW, SWITCH_TO_USER_VIEW, VIEW, SHOW_MODAL, HIDE_MODAL,
} from './constants';

const initialState = {
  view: VIEW.USER,
  modal: {
    type: '',
    modalProps: {},
  },
};

const uiReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SWITCH_TO_ADMIN_VIEW:
      return {
        ...state,
        view: VIEW.ADMIN,
      };
    case SWITCH_TO_USER_VIEW:
      return {
        ...state,
        view: VIEW.USER,
      };
    case LOGIN_USER:
    case LOGOUT_USER:
      return initialState;
    case SHOW_MODAL:
      return {
        ...state,
        modal: {
          type: action.payload.type,
          modalProps: action.payload.modalProps,
        },
      };
    case HIDE_MODAL:
      return {
        ...state,
        modal: initialState.modal,
      };
    default:
      return state;
  }
};

export default uiReducer;
