import {
  GET_CURRENCY_REQUEST,
  GET_CURRENCY_SUCCESS,
  GET_CURRENCY_FAILURE,
} from './constants';

export const initialState = {
  inputCurrencyCodes: [],
  isLoading: false,
};

const metaDataReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CURRENCY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CURRENCY_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_CURRENCY_SUCCESS:
      return {
        ...state,
        inputCurrencyCodes: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default metaDataReducer;
