import Logger from '../../../utils/logger';
import store from '../../store';
import {
  GET_SETTLEMENTS_REQUEST,
  GET_SETTLEMENTS_FAILURE,
  GET_SETTLEMENTS_SUCCESS,
  SET_XERO_BANK_ACCOUNTS,
  START_TRANSACTIONS_SELECTION,
  END_TRANSACTIONS_SELECTION,
} from './constants';
import { convertSettlementsResponse } from './helpers';
import { getSettlements } from '@api/modules/dashboard/settlement';

export const fetchSettlements = async (is_admin) => {
  store.dispatch({ type: GET_SETTLEMENTS_REQUEST });
  try {
    const { data } = await getSettlements({ is_admin, sort: 'date_created' });
    if (Array.isArray(data)) {
      const converted = convertSettlementsResponse(data);
      store.dispatch({
        type: GET_SETTLEMENTS_SUCCESS,
        payload: converted.reverse(),
      });
    } else {
      store.dispatch({ type: GET_SETTLEMENTS_FAILURE });
    }
  } catch (err) {
    store.dispatch({ type: GET_SETTLEMENTS_FAILURE });
    Logger.error(err);
  }
};

export const setXeroBankAccounts = (payload) => ({
  type: SET_XERO_BANK_ACCOUNTS,
  payload,
});

export const startTransactionsSelection = () => ({
  type: START_TRANSACTIONS_SELECTION,
});

export const endTransactionsSelection = () => ({
  type: END_TRANSACTIONS_SELECTION,
});
