import styled, { css } from 'styled-components';

export const TD = styled.td`
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;

export const PageHeader = styled.div`
  ${(props) => props.theme.text.two};
  font-weight: 600;
  margin-bottom: 12px;
`;
