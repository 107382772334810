import React, { useEffect, useState } from 'react';

import { Controller } from 'react-hook-form';
import styled from 'styled-components';

import Select from '../../../../components/Select';
import { ChannelMap, CATEGORY_BANK } from './constants';
import { generateOtherBankOption, generateXeroBankOption } from './helpers';
import { BANK_SOURCE } from '@constants/bank';
import { PLATFORM } from '@constants/platform';
import { formatGAAccountLabel } from '@utils/platformHelpers';

const Header = styled.div`
  font-weight: 600;
`;

const StoresWrapper = styled.div`
  padding: 8px 16px;
  width: 50%;
`;

const StreamSelection = ({ channelCategory, integrations, activeBankAccounts, control, prefix, disabled }) => {
  const [integrationMap, setIntegrationMap] = useState({});

  useEffect(() => {
    const result = {};
    integrations.forEach((integration) => {
      if (integration.platformName === PLATFORM.XERO) {
        return;
      }

      const option =
        integration.platformName === PLATFORM.GOOGLE_ANALYTICS
          ? {
              value: integration.id,
              label: formatGAAccountLabel(integration.accountId, integration.accountName),
            }
          : {
              value: integration.accountId ?? integration.accountName,
              label: integration.accountName ?? integration.accountId,
            };

      if (Array.isArray(result[integration.platformName])) {
        result[integration.platformName].push(option);
      } else if (!Array.isArray(result[integration.platformName])) {
        result[integration.platformName] = [option];
      }
    });

    if (channelCategory === CATEGORY_BANK) {
      result.otherBankAccounts = activeBankAccounts
        .filter(({ add_method }) => add_method === BANK_SOURCE.MANUAL)
        .map(generateOtherBankOption);
      result.xero = activeBankAccounts.filter(({ add_method }) => add_method === BANK_SOURCE.XERO).map(generateXeroBankOption);
    }
    setIntegrationMap(result);
  }, [JSON.stringify(integrations)]);

  return Object.keys(integrationMap).map((platform) => (
    <StoresWrapper key={platform}>
      <Header>{ChannelMap[platform].displayName}</Header>
      <Controller
        control={control}
        name={`${channelCategory}.${platform}`}
        render={({ field }) => {
          return <Select {...field} options={integrationMap[platform]} disabled={disabled} backspaceRemovesValue isMulti />;
        }}
      />
    </StoresWrapper>
  ));
};

export default StreamSelection;
