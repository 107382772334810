/* eslint-disable guard-for-in */
import { CATEGORY_BANK, CATEGORY_OTHER_BANK, ChannelMap } from './constants';
import { PLATFORM_MAP, CATEGORY } from '@constants/platform';
import { formatBankAccountLabel } from '@utils/platformHelpers';
import { getPercentFromDecimal } from '@utils/priceHelpers';

export const constructSelectedChannelAccountObject = (formData) => {
  let sales = {};
  let payment = {};
  let bank = {};
  let analytic = {};

  for (const category in formData) {
    for (const platformName in formData[category]) {
      const selectedOptions = formData[category][platformName];
      const integrationIds =
        Array.isArray(selectedOptions) && selectedOptions.length > 0 ? selectedOptions.map(({ value }) => value) : [];

      switch (category) {
        case CATEGORY.SALES:
          sales = { ...sales, [platformName]: integrationIds };
          break;
        case CATEGORY.PAYMENT:
          payment = { ...payment, [platformName]: integrationIds };
          break;
        case CATEGORY.ANALYTICS:
          analytic = { ...analytic, [platformName]: integrationIds };
          break;
        case CATEGORY_BANK:
          bank = { ...bank, [platformName]: integrationIds };
          break;
        default:
          break;
      }
    }
  }

  return {
    sales,
    payment,
    bank,
    analytic,
  };
};

export const generateCreateRemittanceStreamPayload = (applicationId, offer, formData) => {
  const salesChannel = {};
  const paymentChannel = {};
  const bankChannel = {};
  const analyticsChannel = {};

  for (const property in formData) {
    const platform = ChannelMap[property] ?? {};
    const integrationIds = Array.isArray(formData[property]) ? formData[property].map(({ value }) => value) : [];
    if (platform?.category === CATEGORY.ANALYTICS) {
      analyticsChannel[property] = integrationIds;
    } else if (platform?.category === CATEGORY.SALES) {
      salesChannel[property] = integrationIds;
    } else if (platform?.category === CATEGORY.PAYMENT) {
      paymentChannel[property] = integrationIds;
    } else if ([CATEGORY_BANK, CATEGORY_OTHER_BANK].indexOf(platform?.category) !== -1) {
      bankChannel[property] = integrationIds;
    }
  }

  return {
    funding_id: applicationId,
    offer_id: offer.id,
    percentage: Number(offer.retrievalPercentage),
    frequency: offer.remittancePeriod,
    type: 'GENERAL',
    payment_method: 'whatever',
    sales_channel: salesChannel,
    payment_channel: paymentChannel,
    bank_channel: bankChannel,
  };
};

export const generateOtherBankOption = ({ id, bankName, accountHolderName, accountNumber }) => {
  const labels = [bankName, accountHolderName, accountNumber].filter((value) => value);

  return {
    value: id,
    label: labels.join(' / '),
  };
};

export const generateXeroBankOption = (account) => {
  return {
    value: account.channelBankId,
    label: formatBankAccountLabel([account.bankName, account.accountName, account.accountNumber]),
  };
};
