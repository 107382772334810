import { countries as countryList } from 'countries-list';
import countries from 'i18n-iso-countries'; // TODO: retire with countries-list
import en from 'i18n-iso-countries/langs/en.json';


export const countryDetails = {};

// eslint-disable-next-line guard-for-in
for (const key in countryList) {
  const detail = countryList[key];
  countryDetails[key] = {
    ...detail,
    currency: detail.currency.split(',')[0],
    allCurrencyCodes: detail.currency.split(','),
  };
}

export const Currency = {
  HKD: 'HKD',
  USD: 'USD',
  SGD: 'SGD',
  AFN: 'AFN',
  ALL: 'ALL',
  AMD: 'AMD',
  ANG: 'ANG',
  AOA: 'AOA',
  ARS: 'ARS',
  AUD: 'AUD',
  AWG: 'AWG',
  AZN: 'AZN',
  BAM: 'BAM',
  BBD: 'BBD',
  BDT: 'BDT',
  BGN: 'BGN',
  BHD: 'BHD',
  BIF: 'BIF',
  BMD: 'BMD',
  BND: 'BND',
  BOB: 'BOB',
  BRL: 'BRL',
  BSD: 'BSD',
  BTC: 'BTC',
  BTN: 'BTN',
  BWP: 'BWP',
  BYR: 'BYR',
  BZD: 'BZD',
  CAD: 'CAD',
  CDF: 'CDF',
  CHF: 'CHF',
  CLP: 'CLP',
  CNY: 'CNY',
  COP: 'COP',
  CRC: 'CRC',
  CUC: 'CUC',
  CUP: 'CUP',
  CVE: 'CVE',
  CZK: 'CZK',
  DJF: 'DJF',
  DKK: 'DKK',
  DOP: 'DOP',
  DZD: 'DZD',
  EGP: 'EGP',
  ERN: 'ERN',
  ETB: 'ETB',
  EUR: 'EUR',
  FJD: 'FJD',
  FKP: 'FKP',
  GBP: 'GBP',
  GEL: 'GEL',
  GGP: 'GGP',
  GHS: 'GHS',
  GIP: 'GIP',
  GMD: 'GMD',
  GNF: 'GNF',
  GTQ: 'GTQ',
  GYD: 'GYD',
  HNL: 'HNL',
  HRK: 'HRK',
  HTG: 'HTG',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  IMP: 'IMP',
  INR: 'INR',
  IQD: 'IQD',
  IRR: 'IRR',
  ISK: 'ISK',
  JEP: 'JEP',
  JMD: 'JMD',
  JOD: 'JOD',
  JPY: 'JPY',
  KES: 'KES',
  KGS: 'KGS',
  KHR: 'KHR',
  KMF: 'KMF',
  KPW: 'KPW',
  KRW: 'KRW',
  KWD: 'KWD',
  KYD: 'KYD',
  KZT: 'KZT',
  LAK: 'LAK',
  LBP: 'LBP',
  LKR: 'LKR',
  LRD: 'LRD',
  LSL: 'LSL',
  LYD: 'LYD',
  MAD: 'MAD',
  MDL: 'MDL',
  MGA: 'MGA',
  MKD: 'MKD',
  MMK: 'MMK',
  MNT: 'MNT',
  MOP: 'MOP',
  MRO: 'MRO',
  MUR: 'MUR',
  MVR: 'MVR',
  MWK: 'MWK',
  MXN: 'MXN',
  MYR: 'MYR',
  MZN: 'MZN',
  NAD: 'NAD',
  NGN: 'NGN',
  NIO: 'NIO',
  NOK: 'NOK',
  NPR: 'NPR',
  NZD: 'NZD',
  OMR: 'OMR',
  PAB: 'PAB',
  PEN: 'PEN',
  PGK: 'PGK',
  PHP: 'PHP',
  PKR: 'PKR',
  PLN: 'PLN',
  PYG: 'PYG',
  QAR: 'QAR',
  RON: 'RON',
  RSD: 'RSD',
  RUB: 'RUB',
  RWF: 'RWF',
  SAR: 'SAR',
  SBD: 'SBD',
  SCR: 'SCR',
  SDG: 'SDG',
  SEK: 'SEK',
  SHP: 'SHP',
  SLL: 'SLL',
  SOS: 'SOS',
  SPL: 'SPL',
  SRD: 'SRD',
  STD: 'STD',
  SVC: 'SVC',
  SYP: 'SYP',
  SZL: 'SZL',
  THB: 'THB',
  TJS: 'TJS',
  TMT: 'TMT',
  TND: 'TND',
  TOP: 'TOP',
  TRY: 'TRY',
  TTD: 'TTD',
  TVD: 'TVD',
  TWD: 'TWD',
  TZS: 'TZS',
  UAH: 'UAH',
  UGX: 'UGX',
  UYU: 'UYU',
  UZS: 'UZS',
  VEF: 'VEF',
  VND: 'VND',
  VUV: 'VUV',
  WST: 'WST',
  XAF: 'XAF',
  XCD: 'XCD',
  XDR: 'XDR',
  XOF: 'XOF',
  XPF: 'XPF',
  YER: 'YER',
  ZAR: 'ZAR',
  ZMW: 'ZMW',
  ZWD: 'ZWD',
};

export const COUNTRY_CURRENCY_MAP = {
  HK: Currency.HKD,
  SG: Currency.SGD,
  US: Currency.USD,
  CA: Currency.CAD,
  CH: Currency.CHF,
  AU: Currency.AUD,
  CN: Currency.CNY,
  GB: Currency.GBP,
  JP: Currency.JPY,
  NZ: Currency.NZD,
};

export const EU_COUNTRIES = [
  'AT',
  'BE',
  'CY',
  'EE',
  'ES',
  'FI',
  'FR',
  'DE',
  'GR',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PT',
  'SI',
  'SK',
];

export const PAYMENT_REGIONS = ['HK', 'SG'];

export const Language = {
  en: 'en',
};

export const DB_STATUS = {
  ACTIVE: 'A',
  INACTIVE: 'I',
};

countries.registerLocale(en);
export const countryMap = {
  ...countries.getNames('en', { select: 'official' }),
  HK: 'Hong Kong SAR',
  MO: 'Macao SAR',
  TW: 'Taiwan',
};

export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
};

export const INTEGRATION_STATUS = {
  PENDING_ACTION: -2, // for GA
  PENDING: -1,
  CREATED: 0,
  CONNECTED: 1,
  PENDING_FETCHING: 2,
  FETCHING_DATA: 3,
  FETCHED: 4,
};

/* cSpell:disable */
export const DD_USER_ID = 'Chocodd888'; // DD = Due Diligence
/* cSpell:enable */

export const UUID_FAKER = '3bc9c718-c2f4-4d17-90b2-91804a147158';

export const PERIOD = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

export const INVOICE_STATUS = {
  PAID: 'PAID',
  VOID: 'VOID',
  UNCOLLECTIBLE: 'UNCOLLECTIBLE',
};

export const PAYMENT_ACCOUNT_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  INACTIVE: 'INACTIVE',
};

export const WALLET_ACCOUNT_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  INACTIVE: 'INACTIVE',
};

export const CURRENCY_COUNTRY_FLAG_MAP = {
  USD: {
    displayName: 'United States Dollar',
    logoUrl: '/icons/us-flag.svg',
  },
  HKD: {
    displayName: 'Hong Kong Dollar',
    logoUrl: '/icons/hk-flag.svg',
  },
  SGD: {
    displayName: 'Singapore Dollar',
    logoUrl: '/icons/sg-flag.svg',
  },
  AUD: {
    displayName: 'Australian Dollar',
    logoUrl: '/icons/au-flag.svg',
  },
  CAD: {
    displayName: 'Canadian Dollar',
    logoUrl: '/icons/ca-flag.svg',
  },
  CNY: {
    displayName: 'Chinese Yuan',
    logoUrl: '/icons/cn-flag.svg',
  },
  CHF: {
    displayName: 'Swiss franc',
    logoUrl: '/icons/swiss-flag.svg',
  },
  EUR: {
    displayName: 'Euro',
    logoUrl: '/icons/eu-flag.svg',
  },
  GBP: {
    displayName: 'Great Britain Pound',
    logoUrl: '/icons/gb-flag.svg',
  },
  JPY: {
    displayName: 'Japanese Yen',
    logoUrl: '/icons/jp-flag.svg',
  },
  NZD: {
    displayName: 'New Zealand Dollar',
    logoUrl: '/icons/nz-flag.svg',
  },
};
