import React, { useState, useEffect } from 'react';

import styled, { css } from 'styled-components';

import { CURRENCY_COUNTRY_FLAG_MAP } from '@/constants';
import { currencyOptions as defaultCurrencyOptions } from '../constants/options';
import NumberFormatter from './NumberFormatter';
import Select from './Select';
import { roundDownNumber } from '@utils/priceHelpers';

const StyledSelect = styled(Select)`
  min-width: 100px;
  display: inline-block;
  vertical-align: middle;
  ${(props) =>
    props.full &&
    css`
      box-sizing: border-box;
      width: calc(35% - 20px);
    `}

  span, img {
    vertical-align: middle;
  }

  img ~ span {
    margin-left: 5px;
  }
`;

const StyledNumberFormat = styled(NumberFormatter)`
  margin-left: 20px;
`;

const Footer = styled.div`
  text-align: right;
`;

const defaultSelectProps = {
  showFlag: false,
};

const MoneyInput = ({
  currency,
  currencyOptions = defaultCurrencyOptions,
  amount,
  menuPlacement = 'bottom',
  selectProps = defaultSelectProps,
  inputProps = {},
  full,
  footer,
  onCurrencyChange = () => {},
  onAmountChange = () => {},
}) => {
  const { onBlur = () => {}, ...restInputProps } = inputProps;
  /**
    {
      formattedValue: '$23,234,235.56', // value after applying formatting
      value: '23234235.56', // non formatted value as numeric string 23234235.56
      floatValue: 23234235.56 // floating point representation.
    }
   */
  const handleAmountChange = ({ floatValue }) => {
    onAmountChange(floatValue);
  };

  const onAmountBlur = () => {
    const money = amount || inputProps.value;
    if (money && inputProps.step) {
      const step = inputProps.step || 1;
      const roundedValue = roundDownNumber(money, step);
      const result = roundedValue < inputProps.min ? inputProps.min : roundedValue;
      onAmountChange(result);
      onBlur(result);
    }
  };

  const getOptionLabel = ({ value }) => {
    return (
      <div>
        {CURRENCY_COUNTRY_FLAG_MAP[value]?.logoUrl && <img src={CURRENCY_COUNTRY_FLAG_MAP[value]?.logoUrl} alt="cur" />}
        <span>{value}</span>
      </div>
    );
  };

  const combinedSelectProps = {
    ...defaultSelectProps,
    ...selectProps,
  };

  return (
    <>
      <div>
        <StyledSelect
          value={currency}
          onChange={onCurrencyChange}
          options={currencyOptions}
          placeholder=""
          menuPlacement={menuPlacement}
          full={full}
          {...combinedSelectProps}
          {...(combinedSelectProps.showFlag && { getOptionLabel })}
        />
        <StyledNumberFormat
          thousandSeparator=","
          value={amount}
          placeholder={inputProps.placeholder || 'E.g. 100,000'}
          onValueChange={handleAmountChange}
          onBlur={onAmountBlur}
          full={full}
          {...restInputProps}
        />
      </div>
      {footer && <Footer>{footer}</Footer>}
    </>
  );
};

export default MoneyInput;
