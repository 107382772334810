/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';

import { BroadcastChannel } from 'broadcast-channel';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

import { integrationSuccess } from '@api/modules/application';
import { updateWalletAccount } from '@api/modules/integration/airwallex';
import { PLATFORM } from '@constants/platform';
import { BASE_INTEGRATION_PATH } from '@/basePath';

const IntegrationContainer = () => {
  const { pathname, search } = useLocation();
  const [msg, setMsg] = useState('Account connected!');
  useEffect(() => {
    const fetchApi = async () => {
      const value = pathname.replace(`${BASE_INTEGRATION_PATH}/`, '');
      const { code, shop_id, main_account_id, hmac, shop, state, error } = qs.parse(search.replace(/^\?/, ''));
      const channel = new BroadcastChannel('integration');

      if (error) {
        if (error === 'woocommerce-special-handling') {
          setMsg(''); // workaround solution because for unknown reason the page cannot act for woocommerce. Don't abuse this approach
        } else {
          setMsg('Integration failed');
        }
        channel.postMessage(state);
        setTimeout(() => window.close(), 2000);
        return;
      }

      if (value === 'payment-signup') {
        setMsg('Successfully signed up');
        channel.postMessage(state);
        setTimeout(() => window.close(), 2000);
        return;
      }

      if (value === 'wallet-setup-success') {
        channel.postMessage(state);
        const { data, statusCode } = await updateWalletAccount({
          action: 'SIGNUP_SUCCESS',
        });
        if (statusCode < 400) {
          window.close();
        }
      }

      const payload: {
        code: string;
        shop_id?: string;
        main_account_id?: string;
        hmac?: string;
        shop?: string;
      } = { code };
      if (value === PLATFORM.SHOPEE) {
        payload.shop_id = shop_id;
        payload.main_account_id = main_account_id;
      } else if (value === PLATFORM.SHOPLAZZA) {
        payload.hmac = hmac;
        payload.shop = shop;
      }

      const { data, statusCode } = await integrationSuccess(value, payload);

      channel.postMessage(state);
      if (statusCode < 400) {
        window.close();
      }
    };

    fetchApi();
  }, []);

  return (
    <center>
      <h3>{msg}</h3>
    </center>
  );
};

export default IntegrationContainer;
