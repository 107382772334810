import React, { ReactNode } from 'react';

import styled, { css } from 'styled-components';

import ToolTip from './ToolTip';

type WrapperProps = {
  scale?: number;
  theme?: any;
};
const Wrapper = styled.div`
  margin: 10px 0;
  ${(props: WrapperProps) => props.theme.formTitle};
  color: ${(props) => props.theme.colors.black};

  ${(props: WrapperProps) =>
    props.scale === 2 &&
    css`
      ${props.theme.text.two};
    `}

  ${(props: WrapperProps) =>
    props.scale === 3 &&
    css`
      ${props.theme.text.three};
    `}

  ${(props: WrapperProps) =>
    props.scale === 4 &&
    css`
      ${props.theme.text.four};
    `}

  ${(props: WrapperProps) =>
    props.scale === 4.5 &&
    css`
      ${props.theme.text.body};
      font-weight: 600;
    `}


  ${(props: WrapperProps) =>
    props.theme === 5 &&
    css`
      ${props.theme.text.five};
    `}
`;

const StyledToolTip = styled(ToolTip)`
  margin-left: 8px;
  display: inline;
  vertical-align: middle;
`;

const Required = styled.span`
  color: ${(props) => props.theme.colors.red};
`;

interface FormTitleProps {
  scale: number;
  required: boolean;
  children?: ReactNode;
  tip?: any;
}

const FormTitle = ({ scale = 4, required, children, tip }: FormTitleProps) => (
  <Wrapper scale={scale}>
    {children} {required && <Required>*</Required>}
    {tip && <StyledToolTip tip={tip} />}
  </Wrapper>
);

export default FormTitle;
