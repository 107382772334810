import React, { useRef, useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../../../../components/Button';
import PATH from '../../../../path';
import { Menu, DropdownOption } from '@components/Dropdown';
import { useOnClickOutside, useModal } from '@hooks/UI';
import { REVENUE_ADJUSTMENT_MODAL } from '@modules/Modal/constants';
import { startTransactionsSelection } from '@redux/modules/settlements/actions';
import { showModal } from '@redux/modules/UI/actions';

const DropdownWrapper = styled.div`
  position: relative;
  float: right;
`;

const StyledMenu = styled(Menu)`
  top: 60px;
  width: 250px;
`;

const CreateButton = ({ disabled }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const ref = useRef();
  const [isDropdownShown, toggleDropdown] = useModal(false);

  useOnClickOutside(ref, () => toggleDropdown(false));

  const openAdjustmentModal = () => {
    toggleDropdown();
    dispatch(showModal(REVENUE_ADJUSTMENT_MODAL));
  };

  const handleSelectionClick = () => {
    dispatch(startTransactionsSelection());
    if (location.pathname !== PATH.BUSINESS_BANK_TRANSACTIONS) {
      history.push(PATH.BUSINESS_BANK_TRANSACTIONS);
    }
  };

  return (
    <DropdownWrapper ref={ref}>
      <Button onClick={toggleDropdown} disabled={disabled}>
        Create Revenue
      </Button>
      {isDropdownShown && (
        <StyledMenu>
          <DropdownOption onClick={handleSelectionClick}>Select from bank transactions</DropdownOption>
          <DropdownOption onClick={openAdjustmentModal}>Add a manual record</DropdownOption>
        </StyledMenu>
      )}
    </DropdownWrapper>
  );
};

export default CreateButton;
