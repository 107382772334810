import { combineReducers } from 'redux';

import applicationReducer from './modules/application/reducer';
import bankReducer from './modules/bank/reducer';
import bankTransactionReducer from './modules/bankTransaction/reducer';
import companyReducer from './modules/company/reducer';
import creditModelReducer from './modules/creditModel/reducer';
import fundReducer from './modules/fund/reducer';
import integrationReducer from './modules/integration/reducer';
import metaDataReducer from './modules/metadata/reducer';
import offerReducer from './modules/offer/reducer';
import paymentReducer from './modules/payment/reducer';
import settlementReducer from './modules/settlements/reducer';
import uiReducer from './modules/UI/reducer';
import userReducer from './modules/user/reducer';
import walletReducer from './modules/wallet/reducer';

export default combineReducers({
  // by alphabetic order
  application: applicationReducer,
  bank: bankReducer,
  bankTransaction: bankTransactionReducer,
  creditModel: creditModelReducer,
  company: companyReducer,
  fund: fundReducer,
  integration: integrationReducer,
  offer: offerReducer,
  metaData: metaDataReducer,
  payment: paymentReducer,
  settlement: settlementReducer,
  ui: uiReducer,
  user: userReducer,
  wallet: walletReducer,
});
