import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import Logger from '../../../../utils/logger';
import { UPLOAD_FILES_MODAL } from '../../../Modal/constants';
import ControlledSelect from '../../../ReactHookForm/ControlledSelect';
import { Card } from '../../components';
import Countdown from '../Countdown';
import RatingTable from '../RatingTable';
import { updateCreditRiskModel } from '@api/modules/dashboard';
import { UPLOAD_FILE_FAILURE, UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS } from '@redux/modules/company/constants';
import { fetchIntegrationProgress, fetchCreditModelReport } from '@redux/modules/creditModel/actions';
import { showModal, hideModal } from '@redux/modules/UI/actions';
import { useSelectedCompanyId } from '@redux/selectors';
import { convertToFullDate } from '@utils/dateHelpers';
import { formatPrice } from '@utils/priceHelpers';

const Form = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const CompanyInfo = styled.div`
  flex-grow: 1;
`;

const Control = styled.div`
  flex-grow: 2;
`;

const FieldTitle = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: 600;
  margin-bottom: 4px;
`;

const FundingAmount = styled(FieldTitle)`
  margin-bottom: 16px;
`;

const FieldWrapper = styled.div`
  margin: 16px 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 36px;
  text-align: right;
`;

const ConnectedAccountId = styled.div`
  width: 10%;
  min-width: 200px;
  ${(props) => props.theme.text.micro};
  display: inline-block;
`;

const ProgressStatus = styled.div`
  vertical-align: top;
  display: inline-block;
  width: 10%;
  min-width: 200px;
  font-size: 10px;
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors[props.colorName]};
  }

  div {
    display: inline-block;
    vertical-align: middle;
  }

  div ~ div {
    margin-left: 4px;
  }
  color: ${(props) => props.theme.colors[props.colorName]};
`;

const SuccessMessage = styled.div`
  color: ${(props) => props.theme.colors.green};
  ${(props) => props.theme.text.micro};
`;

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.red};
  ${(props) => props.theme.text.micro};
`;

const SOURCE_TYPE_OPTIONS = [
  {
    value: 'INTEGRATION',
    label: 'System Integration',
    isDisabled: false,
  },
  {
    value: 'UPLOAD_CSV',
    label: 'Upload Reports',
    isDisabled: false,
  },
];

const CHANNEL_TYPE_OPTIONS = [
  {
    value: 'all',
    label: 'All channels',
  },
  {
    value: 'sales',
    label: 'Sales channels',
  },
  {
    value: 'payment',
    label: 'Payment channels',
  },
];

const SUCCESS_VALUE = 'SUCCESS';
const FAILURE_VALUE = 'FAILURE';

// ReportInitiation is a component for running system flow for the credit risk model,
// it lets user choose a revenue channel.
const ReportInitiation = ({ formProps, isLoading, toggleIsLoading, refreshTableau, tableauViz }) => {
  const dispatch = useDispatch();
  const { control, handleSubmit, watch } = formProps;
  const watchOfSourceType = watch('sourceType');
  const watchOfIntegrationType = watch('integrationType');

  const applicationData = useSelector((state) => state.application.data);
  const integrations = useSelector((state) => state.application.integrations);
  const integrationProgressMap = useSelector((state) => state.creditModel.integrationProgressMap);

  const [filteredChannelTypeOptions, setFilteredChannelTypeOptions] = useState([]);

  const selectedCompanyId = useSelectedCompanyId();

  const [apiStatus, setAPIStatus] = useState(''); // '' or 'SUCCESS' or 'FAILURE'

  const uploadCsv = (data) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'text/csv');
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: data,
      redirect: 'follow',
    };
    return fetch(
      `https://j46yffjbz4.execute-api.ap-southeast-1.amazonaws.com/Credit_Risk_Production?company_id=${selectedCompanyId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => console.log(result));
  };

  const submitOtherFiles = async (pendingFiles) => {
    try {
      dispatch({ type: UPLOAD_FILE_REQUEST });
      if (Array.isArray(pendingFiles) && pendingFiles.length > 0) {
        await Promise.all(pendingFiles.map((file) => uploadCsv(file)));
        dispatch(hideModal());
      }
      dispatch({ type: UPLOAD_FILE_SUCCESS });
    } catch (err) {
      dispatch({ type: UPLOAD_FILE_FAILURE });
    }
  };

  const showFileModal = (event) => {
    dispatch(
      showModal(UPLOAD_FILES_MODAL, {
        title: 'Upload CSV File',
        description: 'Please upload the CSV to configure the model.',
        acceptFileType: 'text/csv',
        onConfirmClick: (data) => submitOtherFiles(data),
      })
    );
  };

  // TODO: (from Kevin) this is subjected to be changed
  // once we decided to support more than just Shopify and Stripe
  useEffect(() => {
    if (Array.isArray(integrations)) {
      // Count number of Shopify integrations
      const salesChannelCount = integrations.filter((integration) => integration.platformName === 'shopify').length;
      // Count number of Stripe integrations
      const paymentChannelCount = integrations.filter((integration) => integration.platformName === 'stripe').length;
      // Set Filter Options
      let filteredOptions = [];
      if (salesChannelCount > 0 && paymentChannelCount > 0) {
        filteredOptions = CHANNEL_TYPE_OPTIONS;
      } else if (salesChannelCount > 0) {
        filteredOptions = [CHANNEL_TYPE_OPTIONS[1]];
      } else if (paymentChannelCount > 0) {
        filteredOptions = [CHANNEL_TYPE_OPTIONS[2]];
      }
      setFilteredChannelTypeOptions(filteredOptions);
      // Handle integrations
      integrations.forEach(({ accountId, platformName, id }) => {
        // TODO: (from Kevin) later this will have to be supported by payment too
        if (platformName === 'shopify') {
          fetchIntegrationProgress(id, {
            status: `in:${['created', 'failed', 'completed'].join(',')}`,
            task_key: `dump_${platformName}_tender_transactions`,
            source: accountId,
            platform: platformName,
            channel: 'sales',
            sort: 'created_date:desc',
            limit: 1,
            page_no: 1,
          });
        } else if (platformName === 'stripe') {
          fetchIntegrationProgress(id, {
            status: `in:${['created', 'failed', 'completed'].join(',')}`,
            task_key: `dump_${platformName}_transaction`,
            source: accountId,
            platform: platformName,
            channel: 'payment',
            sort: 'created_date:desc',
            limit: 1,
            page_no: 1,
          });
        }
      });
    }
  }, [JSON.stringify(integrations)]);

  const getDisplayProgressDetail = (connectedAccount) => {
    switch (connectedAccount.status) {
      case 'completed':
        return {
          colorName: 'purple',
          label: `Completed (Last updated ${convertToFullDate(connectedAccount.updated_date)})`,
        };
      case 'created':
        return {
          colorName: 'red',
          label: 'In Progress',
        };
      case 'failed':
        return {
          colorName: 'red',
          label: 'Failed',
        };
      default:
        return {};
    }
  };

  const onSubmit = async () => {
    toggleIsLoading(true);
    setAPIStatus('');
    try {
      const { statusCode } = await updateCreditRiskModel({
        mode: 'system_flow',
        chosen_revenue_channel: watchOfIntegrationType,
      });
      if (statusCode < 300) {
        setAPIStatus(SUCCESS_VALUE);
      } else {
        setAPIStatus(FAILURE_VALUE);
      }
    } catch (err) {
      // TODO: use logger after api is stable
      setAPIStatus(FAILURE_VALUE);
      Logger.error(err);
    } finally {
      // Update the tableau report regardless
      refreshTableau(tableauViz);
      fetchCreditModelReport();
      toggleIsLoading(false);
    }
  };

  return (
    <Card>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CompanyInfo>
          <FundingAmount>
            {`Requested Funding Amount: ${formatPrice(applicationData.offerForm.currency, applicationData.offerForm.fund)}`}
          </FundingAmount>
          <FieldTitle>Historical Data Source</FieldTitle>
          <FieldWrapper>
            <ControlledSelect control={control} name="sourceType" rules={{ required: true }} options={SOURCE_TYPE_OPTIONS} width={200} />
          </FieldWrapper>
          <FieldWrapper>
            <ControlledSelect
              control={control}
              name="integrationType"
              rules={{ required: true }}
              disabled={filteredChannelTypeOptions.length === 0}
              options={filteredChannelTypeOptions}
              width={200}
            />
          </FieldWrapper>
          {watchOfSourceType === 'INTEGRATION' && (
            <>
              {Object.values(integrationProgressMap).map((platform) => {
                return Object.values(platform)
                  .filter(({ channel }) => {
                    if (watchOfIntegrationType === 'all') {
                      return true;
                    } else {
                      return channel === watchOfIntegrationType;
                    }
                  })
                  .map((connectedAccount) => {
                    const displayDetail = getDisplayProgressDetail(connectedAccount);
                    return (
                      <FieldWrapper>
                        <ConnectedAccountId>
                          <div>{connectedAccount.platform}</div>
                          <div>({connectedAccount.source})</div>
                        </ConnectedAccountId>
                        <ProgressStatus colorName={displayDetail.colorName}>
                          <div className="circle" />
                          <div>{displayDetail.label}</div>
                        </ProgressStatus>
                      </FieldWrapper>
                    );
                  });
              })}
            </>
          )}
          {watchOfSourceType === 'UPLOAD_CSV' && (
            <ButtonWrapper>
              <Button tertiary onClick={showFileModal}>
                Upload CSV
              </Button>
            </ButtonWrapper>
          )}
        </CompanyInfo>
        <Control>
          <RatingTable />
          <ButtonWrapper>
            <Button disabled={isLoading}>Run Initial Report</Button>
            {apiStatus === SUCCESS_VALUE && (
              <SuccessMessage>Successfully updated. Click refresh button at the bottom of Tableau</SuccessMessage>
            )}
            {apiStatus === FAILURE_VALUE && <ErrorMessage>Something went wrong</ErrorMessage>}
            {apiStatus === FAILURE_VALUE && <Countdown refreshTableau={refreshTableau} tableauViz={tableauViz} />}
          </ButtonWrapper>
        </Control>
      </Form>
    </Card>
  );
};

export default ReportInitiation;
