import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Button from '../../../../../components/Button';
import SimplePagination from '../../../../../components/Pagination/SimplePagination';
import Table from '../../../../../components/Table';
import Tabs from '../../../../../components/Tabs';
import PATH from '../../../path';
import { CARD_TYPE, CARD_OPTIONS, CARD_STATUS_LABEL, EMPLOYEE_CARD_CONFIG } from '../../constants';
import CardSettingsMenu from '../components/CardSettingsMenu';
import { getCardStatusColor } from '../helpers';
import { usePagination } from '@hooks/UI';
import { fetchWalletCards } from '@redux/modules/wallet/actions';
import { useWalletAccountId } from '@redux/selectors';
import { getOptionLabelByValue } from '@utils/optionHelpers';

const StyledTabs = styled(Tabs)`
  ${(props) => props.theme.text.five};

  > span {
    line-height: 20px;
  }
`;

const LearnMoreLink = styled.a`
  ${(props) => props.theme.text.link};
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const CTACard = styled.div`
  text-align: center;
  background-color: ${(props) => props.theme.colors.white};
  padding: 24px 12px;

  > * {
    margin-top: 16px;
  }

  h3 {
    ${(props) => props.theme.text.three};
    margin: 0;
  }

  div {
    ${(props) => props.theme.text.five};
    color: ${(props) => props.theme.colors.grayThree};
  }
`;

const TypeWrapper = styled.div`
  img {
    width: 50px;
    margin: 4px 0;
  }

  img ~ span {
    margin-left: 8px;
  }

  img,
  span {
    vertical-align: middle;
  }
`;

const companyCardColumns = [
  {
    Header: 'Type',
    Cell: () => {
      return (
        <TypeWrapper>
          <img src="/images/virtual-cards/company-card-small.svg" alt="card" />
          <span>Virtual</span>
        </TypeWrapper>
      );
    },
  },
  {
    Header: 'Nickname',
    accessor: 'nick_name',
  },
  {
    Header: 'Number',
    accessor: 'card_number',
    Cell: ({ value }) => {
      return value;
    },
  },
  {
    Header: 'Status',
    accessor: 'card_status',
    Cell: ({ value }) => {
      return CARD_STATUS_LABEL[value] || value;
    },
    getStyle: ({ value }) => css`
      color: ${getCardStatusColor(value)};
    `,
  },
  {
    Header: '',
    accessor: 'card_id',
    applyOnRowClick: false,
    Cell: ({ row }) => <CardSettingsMenu cardDetail={row} />,
  },
];

const employeeCardColumns = [
  {
    Header: 'Employee',
  },
  {
    Header: 'No. of card',
  },
  {
    Header: 'Cardholder status',
  },
];

const Summary = () => {
  const history = useHistory();
  const walletAccountId = useWalletAccountId();
  const isLoading = useSelector((state) => state.wallet.isLoading);
  const cardPageMap = useSelector((state) => state.wallet.card.pageMap);
  const { pageNumber, pageSize, onPageNumberChange, onPageSizeChange, changePageCount } = usePagination({
    pageSize: 100,
  });

  const [cardType, setCardType] = useState(CARD_OPTIONS[0].value);
  const cardTypeLabel = getOptionLabelByValue(cardType, CARD_OPTIONS).toLowerCase();

  const isTabSelected = (value) => cardType === value;

  const { data, columns } =
    cardType === CARD_TYPE.COMPANY_CARD
      ? {
          data: Array.isArray(cardPageMap[pageNumber]) ? cardPageMap[pageNumber].filter(({ cardholder_id }) => !cardholder_id) : [],
          columns: companyCardColumns,
        }
      : {
          // data: cardPageMap[pageNumber].filter(({ cardholder_id }) => cardholder_id),
          data: [],
          columns: employeeCardColumns,
        };

  const handleCreateClick = () => {
    history.push(PATH.WALLET_CARDS_CREATE);
  };

  const handleCreateCompanyCardClick = () => {
    history.push(generatePath(PATH.WALLET_CARDS_CREATE_FORM, { type: 'company-card' }));
  };

  const handleRowClick = (rowData) => {
    history.push(generatePath(PATH.WALLET_CARDS_CARD_DETAIL, { id: rowData?.card_id }));
  };

  useEffect(() => {
    if (walletAccountId) {
      fetchWalletCards(walletAccountId, { pageNumber, pageSize });
    }
  }, [pageNumber, pageSize, walletAccountId]);

  const hasData = (Array.isArray(data) && data.length > 0) || pageNumber !== 1;

  return (
    <div>
      <HeaderWrapper>
        <StyledTabs options={CARD_OPTIONS} onChange={setCardType} isTabSelected={isTabSelected} />
        <Button small onClick={handleCreateClick}>
          Create Card
        </Button>
      </HeaderWrapper>
      {hasData && (
        <>
          <Table data={data} columns={columns} isLoading={isLoading} onRowClick={handleRowClick} />
          <SimplePagination
            pageNumber={pageNumber}
            pageSize={pageSize}
            pageSizeOptions={[
              {
                value: 20,
                label: 20,
              },
              {
                value: 50,
                label: 50,
              },
              {
                value: 100,
                label: 100,
              },
            ]}
            onPageNumberChange={onPageNumberChange}
            onPageSizeChange={onPageSizeChange}
            disabled={isLoading}
            isLoading={isLoading}
            noNextPage={data.length < pageSize}
          />
        </>
      )}
      {!hasData && (
        <>
          {cardType === CARD_TYPE.COMPANY_CARD ? (
              <CTACard>
                <h3>{`Create your first ${cardTypeLabel}`}</h3>
                <div>A list of your cards will appear here.</div>
                <Button onClick={handleCreateCompanyCardClick}>{`Create ${cardTypeLabel}`}</Button>
              </CTACard>
            ) : (
              <CTACard>
                <img src={EMPLOYEE_CARD_CONFIG.imgUrl} alt={EMPLOYEE_CARD_CONFIG.type} />
                <h3>Coming Soon</h3>
                <div>{EMPLOYEE_CARD_CONFIG.description}</div>
                <LearnMoreLink
                  href="https://help.airwallex.com/hc/en-gb/articles/4409923250969-When-should-I-use-a-company-card-vs-an-employee-card-"
                  target="_blank"
                >
                  Learn more
                </LearnMoreLink>
              </CTACard>
            )}
          {isLoading && <Table data={[]} columns={columns} isLoading onRowClick={handleRowClick} />}
        </>
      )}
    </div>
  );
};

export default Summary;
