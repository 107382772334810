/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import styled, { css } from 'styled-components';

import { MMM_YYYY_FORMAT, convertDate, checkSameMonth } from '@utils/dateHelpers';
import { formatNumber } from '@utils/priceHelpers';

const StyledTable = styled.table`
  width: 100%;

  ${(props) => props.theme.table};
`;

const StyledTD = styled.td`
  padding: 10px 16px;
  border-top: 1px solid transparent !important;
`;

const TD = styled.td`
  padding: 10px 16px;
  float: right;
  border-top: 1px solid transparent !important;
`;

const SubItemsHeader = styled.div`
  padding-left: 24px;
`;

const SubItemsValue = styled.div`
  float: right;
  padding-right: 18px;
`;

const SubTotalText = styled.td`
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
`;

const Title = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: 600;
  color: ${(props) => props.theme.colors.black};
  border: 1px solid transparent;
`;

const DateHeader = styled.div`
  text-align: right;
  padding: 10px 28px;
  font-weight: 600;
`;

const StyledTr = styled.tr`
  border-top: 2px solid rgb(231, 234, 236);
  border-bottom: 2px solid rgb(231, 234, 236);
  background: ${(props) => props.theme.colors.grayOne};
`;

const TotalHeader = styled.div`
  padding-left: 24px;
`;

const SubTotalHeader = styled.div`
  ${(props) => props.theme.text.micro};
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
  text-align: right;
  padding-right: 32px;
  padding-top: 8px;
`;

const ProfitLossTable = ({ data, endDate, startDate }) => {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const keys =
      Array.isArray(data) && data.length > 0
        ? Object.keys(data[0]).filter((key) => {
            return key;
          })
        : [];

    const columnData = keys.map((key) => {
      return {
        Header: key,
        showTotal: true,
        accessor: key,
        Cell: ({ value }) => <TD>{formatNumber(value, 2)}</TD>,
      };
    });

    setColumns(columnData);
  }, [JSON.stringify(data)]);

  const isSameMonth = checkSameMonth(endDate, startDate);
  return (
    <StyledTable>
      <tbody>
        <tr>
          {isSameMonth ? (
            <>
              <StyledTD />
              <DateHeader>{convertDate(endDate, MMM_YYYY_FORMAT)}</DateHeader>
            </>
          ) : (
            <>
              <StyledTD />
              <DateHeader>
                {convertDate(startDate, MMM_YYYY_FORMAT)}
                <br />
                {convertDate(endDate, MMM_YYYY_FORMAT)}
              </DateHeader>
            </>
          )}
        </tr>
        {columns.map(({ Header, accessor, Cell, showTotal }) => {
          return (
            <>
              <tr>
                <StyledTD>
                  <Title>{Header}</Title>
                </StyledTD>
                {Array.isArray(data) &&
                  data.map((datum) => {
                    const rowData = _.get(datum, accessor);
                    if (rowData) {
                      return (
                        <StyledTD>
                          <Title>{Cell(rowData)}</Title>
                        </StyledTD>
                      );
                    }
                  })}
              </tr>
              {(() => {
                const firstDataItems = _.get(data[0], `${accessor}.items`);
                return (
                  Array.isArray(firstDataItems) &&
                  firstDataItems.map((item) => {
                    return (
                      <tr>
                        <StyledTD>
                          <SubItemsHeader>{item.name}</SubItemsHeader>
                        </StyledTD>
                        {Array.isArray(data) &&
                          data.map((datum) => {
                            return (
                              <StyledTD>
                                <SubItemsValue>{formatNumber(item.value, 2)}</SubItemsValue>
                              </StyledTD>
                            );
                          })}
                      </tr>
                    );
                  })
                );
              })()}
              {(() => {
                const subTotalValues = _.get(data[0], `${accessor}.value`);
                const subTotalItems = _.get(data[0], `${accessor}.items`);
                return showTotal && subTotalValues !== null && Array.isArray(subTotalItems) && subTotalItems.length > 0 ? (
                  <StyledTr>
                    {accessor.indexOf('Total') !== -1 ? (
                      <SubTotalText>
                        <TotalHeader>{accessor}</TotalHeader>
                      </SubTotalText>
                    ) : (
                      <SubTotalText>
                        <TotalHeader>
                          Total&nbsp;
                          {accessor}
                        </TotalHeader>
                      </SubTotalText>
                    )}
                    <SubTotalHeader>{formatNumber(subTotalValues, 2)}</SubTotalHeader>
                  </StyledTr>
                ) : null;
              })()}
            </>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

export default ProfitLossTable;
