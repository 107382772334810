import { currencyOptions } from '../../../constants';

export const conversionStatusOptions = [
  {
    value: 'AWAITING_FUNDS',
    label: 'Awaiting funds',
  },
  {
    value: 'CANCELLED',
    label: 'Cancelled',
  },
  {
    value: 'PENDING_SETTLEMENT',
    label: 'Pending settlement',
  },
  {
    value: 'SETTLED',
    label: 'Settled',
  },
  {
    value: 'ROLLOVER_REQUESTED',
    label: 'rollover requested',
  },
  {
    value: 'PENDING_ROLLOVER',
    label: 'Pending rollover',
  },
];

export const conversionCurrencyOptions = [
  {
    value: 'ALL',
    label: 'All',
  },
  ...currencyOptions,
];
