import React, { useEffect } from 'react';

import { logout } from '@api/modules/auth';
import { GridRow, GridColumn } from '@components/Grid';
import { COMPANY_FILE_TYPE } from '@constants/file';
import { useIntegrationChannel } from '@hooks/channel';
import { fetchIntegrations } from '@redux/modules/application/actions';
import { logoutUser } from '@redux/modules/auth/actions';
import { fetchBankAccounts } from '@redux/modules/bank/actions';
import { fetchBusinessOwners, fetchFilesByType } from '@redux/modules/company/actions';
import { showModal } from '@redux/modules/UI/actions';
import { useCheckIsSignUpViaShopify } from '@redux/selectors';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Button from '../../components/Button';
import AuthPath from '../Authentication/path';
import DASHBOARD_PATH from '../Dashboard/path';
import { FAQ_MODAL } from '../Modal/constants';
import BottomControl from './BottomControl';
import FormRoutes from './Forms';
import PATH, { areAllPagesAllowed } from './path';
import SideMenu from './SideMenu';

const TopBar = styled.div`
  padding: 20px 0;
  display: table;
  width: 100%;
`;

const LogoWrapper = styled.div`
  width: 25%;
  display: table-cell;
`;

const Logo = styled.img`
  height: 60px;
  margin: 0 auto;
  display: block;
`;

const MenuColumn = styled(GridColumn)`
  padding-top: 20px;
  overflow: scroll;
  height: calc(100vh - 103px - 44px);
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const FormColumn = styled(GridColumn)<{ bottomOffset: boolean; }>`
  padding-top: 20px;

  ${(props) =>
    props.bottomOffset
      ? css`
          height: calc(100vh - 103px - 44px);
        `
      : css`
          height: calc(100vh - 103px);
        `}
  overflow: scroll;
  padding-bottom: 150px;
`;

const FormWrapper = styled.div`
  max-width: 1000px;
`;

const Header = styled.div`
  margin-top: 20px;
  width: 75%;
  /* display: inline-block; */
  vertical-align: middle;
  display: table-cell;
`;

const FAQsWrapper = styled.div`
  font-weight: 600;
  line-height: 30px;
  color: #9e9e9e;
  display: inline-block;
`;

const ControlWrapper = styled.div`
  float: right;
`;

const ModuleSwitch = styled(Link)<{ isActive?: boolean; }>`
  margin-right: 30px;
  line-height: 30px;
  display: inline-block;
  font-weight: 600;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      border-bottom: 1px solid ${props.theme.colors.purple};
      color: ${props.theme.colors.purple};
    `}
`;

const LogOutButton = styled(Button)`
  margin-right: 30px;
`;

const FAQLabel = styled.span`
  ${(props) => props.theme.text.link};
`;

const channel = null;

const ApplicationContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { data: applicationData } = useSelector(({ application }) => application);
  const isSignUpViaShopify = useCheckIsSignUpViaShopify();

  const allowAllPages = areAllPagesAllowed(applicationData.statusCode);

  useEffect(() => {
    fetchIntegrations();
    fetchBankAccounts();
    fetchBusinessOwners();
    fetchFilesByType(COMPANY_FILE_TYPE.OTHER);
    fetchFilesByType(COMPANY_FILE_TYPE.SALES);
  }, []);

  useIntegrationChannel(channel, fetchIntegrations);

  const handleLogout = async () => {
    await logout();
    dispatch(logoutUser());
    history.push(AuthPath.LOGIN);
  };

  const showFAQModal = () => {
    dispatch(showModal(FAQ_MODAL));
  };

  const showBottomControl =
    [
      PATH.MARKETING_ACCOUNTS,
      PATH.COMPANY,
      PATH.COMPANY_BUSINESS,
      PATH.COMPANY_KEY_PERSONS,
      PATH.BANK_ACCOUNTS,
      PATH.ACCOUNTING,
      PATH.TERM_SHEET,
      PATH.ADDITIONAL_INFO,
      PATH.AGREEMENT,
    ].indexOf(location.pathname) !== -1;

  return (
    <>
      <TopBar>
        <LogoWrapper>
          <Logo src="/images/choco-up-with-title-tagline.png" alt="logo" />
        </LogoWrapper>
        <Header>
          {!isSignUpViaShopify && (
            <FAQsWrapper>
              Have a question? <FAQLabel onClick={showFAQModal}>Check our FAQs here</FAQLabel>
            </FAQsWrapper>
          )}
          <ControlWrapper>
            {!isSignUpViaShopify  && (<ModuleSwitch isActive to={null}>Application</ModuleSwitch>)}
            <ModuleSwitch to={DASHBOARD_PATH.HOME}>Dashboard</ModuleSwitch>
            <LogOutButton onClick={handleLogout} secondary small>
              Log out
            </LogOutButton>
          </ControlWrapper>
        </Header>
      </TopBar>
      <GridRow>
        <MenuColumn lg={3} md={3} sm={3} lgOffset={0} mdOffset={0} smOffset={0} disableGutter>
          <SideMenu allowAllPages={allowAllPages} />
        </MenuColumn>
        <FormColumn lg={9} md={9} sm={9} lgOffset={0} mdOffset={0} smOffset={0}  bottomOffset={showBottomControl} disableGutter>
          <FormWrapper>
            <FormRoutes allowAllPages={allowAllPages} />
          </FormWrapper>
        </FormColumn>
      </GridRow>
      {showBottomControl && <BottomControl />}
    </>
  );
};

export default ApplicationContainer;
