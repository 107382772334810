import React, { useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import FormTitle from '../../../../components/FormTitle';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import Logger from '../../../../utils/logger';
import { Description } from '../../../Application/components';
import { salesOthersPlatformsIntegration } from '@api/modules/application';
import { GridColumn, FormRow } from '@components/Grid';
import { DD_EMAIL } from '@constants/email';
import { fetchIntegrations } from '@redux/modules/application/actions';

const Title = styled.div`
  ${(props) => props.theme.text.two};
  margin: 10px 0 20px 0;
`;

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.error};
  ${(props) => props.theme.text.micro};
`;

const SalesOthersPlatformsModal = ({ afterSubmit, hideModal }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [isLoading, toggleIsLoading] = useState();

  const onSubmit = async (data) => {
    try {
      toggleIsLoading(true);
      const salesOthersPlatform = data.platformName;
      const { statusCode } = await salesOthersPlatformsIntegration(salesOthersPlatform);
      if (statusCode === 201) {
        await afterSubmit();
        hideModal();
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  return (
    <Modal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title>Other Sales Platform</Title>
        <Description>
          If the platform you are using isn&apos;t listed here, please send us an invitation with <b>View Only</b> access to{' '}
          <b>{DD_EMAIL}</b>.
        </Description>
        <FormTitle scale={5}>Let us know which sales platform you are currently using.</FormTitle>
        <Controller
          name="platformName"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <Input {...field} full placeholder="Sales platform name" hasError={errors.platformName} />}
        />
        {errors.platformName && <ErrorMessage>Please enter name of sales platform</ErrorMessage>}
        <FormRow>
          <GridColumn lg={6} md={6}>
            <Button type="submit" disabled={isLoading} primary full>
              Submit
            </Button>
          </GridColumn>
          <GridColumn lg={6} md={6}>
            <Button onClick={() => dispatch(hideModal())} tertiary full>
              Cancel
            </Button>
          </GridColumn>
        </FormRow>
      </form>
    </Modal>
  );
};

export default SalesOthersPlatformsModal;
