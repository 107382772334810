import React, { useState } from 'react';

import styled, { css } from 'styled-components';

import HR from '../../../../../components/HR';
import LoadingSkeleton from '../../../../../components/LoadingSkeleton';
import Modal from '../../../../../components/Modal';

const StyledIframe = styled.iframe`
  height: 200px;
  border: none;

  ${(props) =>
    props.isLoading &&
    css`
      display: none;
    `}
`;

const SensitiveDetailModal = ({ cardId, url }) => {
  const [isLoading, toggleIsLoading] = useState(true);

  const finishLoading = () => {
    toggleIsLoading(false);
  };

  return (
    <Modal width={400}>
      {isLoading && (
        <>
          <LoadingSkeleton />
          <LoadingSkeleton />
          <HR />
          <LoadingSkeleton />
          <LoadingSkeleton />
          <HR />
          <LoadingSkeleton />
          <LoadingSkeleton />
        </>
      )}
      {url && (
        <StyledIframe
          id={`card-${cardId}`}
          src={url}
          title="card details"
          onLoad={finishLoading}
          isLoading={isLoading}
        />
      )}
    </Modal>
  );
};

export default SensitiveDetailModal;
