import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import styled, { css } from 'styled-components';

import UnderlineTab from '../../../../components/UnderlineTab';
import PATH from '../../path';
import Detail from './Detail';
import List from './List';

const Tab = styled(UnderlineTab)`
  margin-bottom: 16px;
  ${(props) => props.theme.text.three};
`;

const ContactContainer = () => {
  return (
    <>
      <Tab selected>Recipients</Tab>
      <Switch>
        <Route path={PATH.WALLET_CONTACT_LIST} component={List} />
        <Route path={PATH.WALLET_CONTACT_NEW_RECIPIENT} component={Detail} />
        <Route path={`${PATH.WALLET_CONTACT_RECIPIENT}/:id`} component={Detail} />
        <Redirect to={PATH.WALLET_CONTACT_LIST} />
      </Switch>
    </>
  );
};

export default ContactContainer;
