import React from 'react';

import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';

import Button from '../../../components/Button';
import CountrySelect from '../../../components/CountrySelect';
import FormTitle from '../../../components/FormTitle';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { FormRow, Form, Error, CenterDiv, ContentDiv, Container, ChocoUpLogo, Title } from '../components/styled';
import SwitchForm from '../components/SwitchForm';
import PATH from '../path';
import { monthlyRevenueOptions, businessTypeOptions } from '@constants/options';
import { useCheckIsSignUpViaShopify } from '@redux/selectors';
import { media } from '@styles/breakpoints';
import { webAddressRegExp } from '@utils/validators';

const StyledContentDiv = styled(ContentDiv)`
  width: 1050px;
  margin-top: 10px;
  height: 72%;
  ${media.dablet`
   padding: 20px 0;
  `};
`;

const SignUpTitle = styled(Title)`
  margin-bottom: 22px;
  ${media.phone`
   line-height: 30px;
  `};
`;

const BusinessForm = ({ onNext, formData, isLoading }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: formData.businessForm,
  });
  const isSignUpViaShopify = useCheckIsSignUpViaShopify();

  return (
    <Container>
      <StyledContentDiv>
        <Form onSubmit={handleSubmit(onNext)}>
          <ChocoUpLogo src="/images/choco-up-black-no-title.png" alt="Choco Up Logo" />
          <SignUpTitle>{isSignUpViaShopify ? 'Analyse your business with Choco Up' : 'Fund your business with Choco Up'}</SignUpTitle>
          <FormRow>
            <FormTitle scale={5} required>
              Your Company Name
            </FormTitle>
            <Controller
              name="companyName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input {...field} hasError={errors.companyName} placeholder="The Nugget Company" disabled={isLoading} full />
              )}
            />
          </FormRow>
          <FormRow>
            <FormTitle scale={5} required>
              Website of Business
            </FormTitle>
            <Controller
              name="websiteAddress"
              control={control}
              rules={{ required: true, pattern: webAddressRegExp }}
              render={({ field }) => <Input {...field} hasError={errors.websiteAddress} placeholder="http://" disabled={isLoading} full />}
            />
            {errors.websiteAddress?.type === 'pattern' && <Error>Url format does not match.</Error>}
          </FormRow>
          <FormRow>
            <FormTitle scale={5} required>
              Country of Origin
            </FormTitle>
            <Controller
              name="country"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return <CountrySelect {...field} hasError={errors.country} disabled={isLoading} />;
              }}
            />
          </FormRow>
          <FormRow>
            <FormTitle scale={5} required>
              Monthly Revenue (USD)
            </FormTitle>
            <Controller
              name="monthlyRevenue"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select {...field} options={monthlyRevenueOptions} hasError={errors.monthlyRevenue} disabled={isLoading} />
              )}
            />
          </FormRow>
          <FormRow>
            <FormTitle scale={5} required>
              Business Type
            </FormTitle>
            <Controller
              name="businessType"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select {...field} options={businessTypeOptions} hasError={errors.businessType} disabled={isLoading} />
              )}
            />
          </FormRow>
          <FormRow>
            <Button type="submit" full>
              Sign Up
            </Button>
          </FormRow>
          <CenterDiv>
            <SwitchForm target={PATH.LOGIN} />
          </CenterDiv>
        </Form>
      </StyledContentDiv>
    </Container>
  );
};

export default BusinessForm;
