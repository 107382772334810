import { dashboardUrl } from ".";
import { getCompanyId } from '@api/cookies';
import {
  getRequest, putRequest, postRequest, deleteRequest,
} from '@api/requests';

const FUNDING = 'funding';
const OFFERS = 'offers';

const getComments = async (type, type_id, sub_type) => {
  const companyId = getCompanyId();
  return await getRequest(`${dashboardUrl}/company/${companyId}/messages/${type}/${type_id}/${sub_type}`);
};

export const getOffersComments = async (application_id) => {
  return await getComments(FUNDING, application_id, OFFERS);
};

const createComment = async (payload, type, type_id, sub_type) => {
  const companyId = getCompanyId();
  return await postRequest(`${dashboardUrl}/company/${companyId}/messages/${type}/${type_id}/${sub_type}`, payload);
};

export const createOffersComment = async (application_id, payload) => {
  return await createComment(payload, FUNDING, application_id, OFFERS);
};

export const deleteMessageById = async (id) => {
  const companyId = getCompanyId();
  return deleteRequest(`${dashboardUrl}/company/${companyId}/messages/${id}`);
};

export const editMessageById = async (id, payload) => {
  const companyId = getCompanyId();
  return putRequest(`${dashboardUrl}/company/${companyId}/messages/${id}`, payload);
};
