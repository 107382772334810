import React from 'react';

import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';

import { MAX_SIZE_IN_BYTES } from '@constants/file';

const Dropzone = styled.div`
  border: 1px dashed ${(props) => props.theme.colors.grayTwo};
  ${(props) => props.hasError && css`
    border: 2px dashed ${props.theme.colors.red};
  `};
  padding: 32px;
  text-align: center;
  margin-bottom: 32px;

  :hover {
    cursor: pointer;
    border: 1px dashed ${(props) => props.theme.colors.black};
    box-shadow: dashed 1px 0 ${(props) => props.theme.colors.black}, inset 0 1px ${(props) => props.theme.colors.black}, inset -1px 0 ${(props) => props.theme.colors.black}, inset 0 -1px ${(props) => props.theme.colors.black};
  }
`;

export const FileRow = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin: 4px 0;
`;

const FileDropzone = ({
  onDrop, acceptFileType, uploadedFiles, hasError,
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: acceptFileType,
    onDrop,
  });

  return (
    <div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Dropzone hasError={hasError} maxSize={MAX_SIZE_IN_BYTES}>
          Drag and drop some files here, or click to select files
        </Dropzone>
      </div>
      {Array.isArray(acceptedFiles) && acceptedFiles.length > 0 && (
        <div>
          Pending
          {acceptedFiles.map((file) => (
            <FileRow>
              {file.name}
              {' '}
            </FileRow>
          ))}
        </div>
      )}
      {Array.isArray(uploadedFiles) && uploadedFiles.length > 0 && (
        <div>
          Uploaded
          {uploadedFiles.map((file) => (
            <FileRow>
              {file.file_name}
            </FileRow>
          ))}
        </div>
      )}
    </div>
  );
};

export default FileDropzone;
