import React from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { OFFER_ACCEPTANCE_MODAL } from '../../../Modal/constants';
import OfferCard from '../../../Offer/OfferCard';
import { Container } from '../../components';
import messages from './messages';
import { OFFER_CONDITION } from '@constants/offer';
import { showModal } from '@redux/modules/UI/actions';

const Header = styled.div`
  ${(props) => props.theme.text.two};
  margin-bottom: 24px;
`;

const Instruction = styled.div`
  ${(props) => props.theme.text.four};
  margin-bottom: 25px;
`;

const OffersPage = ({ offers = [], statusCode, applicationHistory }) => {
  const dispatch = useDispatch();

  const handleCardClick = (offer) => () => {
    dispatch(showModal(OFFER_ACCEPTANCE_MODAL, { offer }));
  };

  return (
    <>
      <Header>{`You are qualified for below funding offer${offers.length > 1 ? 's' : ''}.`}</Header>
      <Container>
        <Instruction>{messages[statusCode]}</Instruction>
        <div>
          {offers.map((offer, index) => {
            return (
              <OfferCard
                key={offer.id}
                offer={offer}
                index={index}
                applicationHistory={applicationHistory}
                buttonLabel={offer.condition === OFFER_CONDITION.PENDING ? 'Select' : 'View'}
                onButtonClick={handleCardClick(offer, offer.condition)}
              />
            );
          })}
        </div>
      </Container>
    </>
  );
};

export default OffersPage;
