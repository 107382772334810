import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';

import { Wrapper, Card } from '../../../../components';
import { FORM_NAME } from './constant';
import ConversionForm from './ConversionForm';
import ReviewConversionForm from './ReviewConversionForm';
import { useWalletAccount } from '@redux/selectors';

const CreateNewConversion = () => {
  const walletAccount = useWalletAccount();
  const [formName, setFormName] = useState(FORM_NAME.CONVERSION);

  const formDefaultValue = {
    sourceCurrency: walletAccount?.currency,
    convertCurrency: 'USD',
    conversionDate: moment(),
  };

  const reactHookForm = useForm({
    defaultValues: formDefaultValue,
  });

  const {
    handleSubmit,
    watch,
    setError,
    setValue,
    control,
    getValues,
    clearErrors,
    formState: { errors },
  } = reactHookForm;

  useEffect(() => {
    setValue('sourceCurrency', walletAccount?.currency);
  }, [JSON.stringify(walletAccount)]);

  const handleBack = () => {
    setFormName(FORM_NAME.CONVERSION);
  };

  return (
    <Wrapper>
      {(() => {
        switch (formName) {
          case FORM_NAME.CONVERSION:
            return <ConversionForm formProps={reactHookForm} setFormName={setFormName} />;
          case FORM_NAME.REVIEW:
            return (
              <ReviewConversionForm onClick={handleBack} formProps={reactHookForm} />
            );
          default:
            return null;
        }
      })()}
    </Wrapper>
  );
};

export default CreateNewConversion;
