import styled, { css } from 'styled-components';

export const Description = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  margin: 10px 0 30px 0;
`;

export const Logo = styled.img`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  width: 150px;
  ${(props) => props.width && css`
    width: ${props.width}px;
  `};
`;

export const ErrorMessage = styled.div`
  margin-top: 15px;
  font-size: 15px;
  line-height: 25px;
  color: ${(props) => props.theme.colors.red};
`;

export const LogoWrapper = styled.div`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 200px;
  height: 80px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.grayTwo};
  border-radius: 5px;
  margin: 0 25px 25px 0;

  ${(props) => (props.disabled ? css`
    cursor: not-allowed;
  ` : css`
    :hover {
      box-shadow: inset 1px 0 ${props.theme.colors.grayTwo}, inset 0 1px ${props.theme.colors.grayTwo}, inset -1px 0 ${props.theme.colors.grayTwo}, inset 0 -1px ${props.theme.colors.grayTwo};
    }
 `)}
`;

export const Container = styled.div`
  background-color: #F9F9F9;
  padding: 18px 36px;
`;
