import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useTable } from 'react-table';
import styled from 'styled-components';

import Modal from '../../../../components/Modal';
import Logger from '../../../../utils/logger';
import { Table, Th, SummaryTableRow } from '../../Business/components';
import { bankModalTableColumns } from './constants';
import { getSettlementNames, getBankSettlementDetailById } from '@api/modules/dashboard/settlement';
import { useAcceptedOffer, useIsAdminView } from '@redux/selectors';
import { findFirstMatchInArray } from '@utils/dataTypes';
import { formatNumber } from '@utils/priceHelpers';

const Header = styled.div`
  ${(props) => props.theme.text.three};
`;

const StyledTable = styled(Table)`
  th {
    padding: 6px 12px;
    font-size: 12px;
  }

  td {
    padding: 4px 12px;
    font-size: 10px;
  }

  tbody {
    background-color: ${(props) => props.theme.colors.grayOne};
  }

  margin-bottom: 24px;
`;

const ButtonWrapper = styled.div`
  text-align: right;
`;

const FieldTitle = styled.span`
  ${(props) => props.theme.text.five};
  margin-right: 16px;
`;

const FieldData = styled.span`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  font-weight: 600;
`;

const BankSettlementTransactionModal = ({ breakdown, type, invoiceDetail }) => {
  const dispatch = useDispatch();
  const [transactions, setTransactions] = useState([]);
  const dataPageMap = useSelector((state) => state.bankTransaction.dataPageMap);
  const allBankAccounts = useSelector((state) => state.bank.allBankAccounts);
  const streams = useSelector((state) => state.fund.streams);
  const activeStream = streams[0];
  const isAdmin = useIsAdminView();

  const [settlementNames, setSettlementNames] = useState([]);
  const [settlementDetail, setSettlementDetail] = useState();
  const { id: userId } = useSelector((state) => state.user.profile);
  const { applicationId } = useSelector((state) => state.application.data);
  const acceptedOffer = useAcceptedOffer();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data: transactions,
    columns: bankModalTableColumns,
  });

  const fetchTransactions = async () => {
    if (breakdown?.settlement_id) {
      try {
        const { data } = await getBankSettlementDetailById(breakdown.settlement_id);
        const trxs = Array.isArray(data?.xero_transactions) ? data.xero_transactions : [];
        const filtered = trxs.filter(({ Type }) => Type.indexOf(type) !== -1);
        setTransactions(filtered);
        setSettlementDetail(data);
      } catch (err) {
        Logger.error(err);
      }
    }
  };

  const selectedBankAccount = findFirstMatchInArray(
    allBankAccounts,
    ({ id, channelBankId }) => breakdown.account_id === id || breakdown.account_id === channelBankId
  );

  const fetchSettlementNames = async () => {
    try {
      const result = await getSettlementNames();
      if (Array.isArray(result)) {
        setSettlementNames([...new Set(result)]);
      }
    } catch (err) {
      Logger.error(err);
    }
  };

  useEffect(() => {
    fetchSettlementNames();
    fetchTransactions();
  }, []);

  return (
    <Modal width={900} showCloseButton hideModalOnClickOutside>
      <Header>{selectedBankAccount?.accountName || breakdown.account_id}</Header>
      <div>
        <FieldTitle>Invoice ID:</FieldTitle>
        <FieldData>{`#${invoiceDetail.invoice_number}`}</FieldData>
      </div>
      {settlementDetail?.name && (
        <div>
          <FieldTitle>Settlement Name:</FieldTitle>
          <FieldData>{settlementDetail?.name}</FieldData>
        </div>
      )}
      <div>
        <FieldTitle>Bank Name:</FieldTitle>
        <FieldData>{selectedBankAccount?.bankName}</FieldData>
      </div>
      <div>
        <FieldTitle>Account Holder Name:</FieldTitle>
        <FieldData>{selectedBankAccount?.accountHolderName}</FieldData>
      </div>
      <div>
        <FieldTitle>Account Number:</FieldTitle>
        <FieldData>{selectedBankAccount?.accountNumber}</FieldData>
      </div>
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return <Th className={column.className}>{column.render('Header')}</Th>;
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          <SummaryTableRow>
            {bankModalTableColumns.map(({ accessor, className }, index) => {
              if (settlementDetail?.type === 'MANUAL') {
                if (breakdown?.amount === breakdown?.amount_in_usd && invoiceDetail?.currency !== 'USD') {
                  if (['CurrencyCode', 'amount'].indexOf(accessor) !== -1) {
                    return <td>-</td>;
                  }
                }
              }
              if (index === 0) {
                return <td>Summary</td>;
              } else if (accessor === 'CurrencyCode') {
                return <td>{invoiceDetail.currency}</td>;
              } else if (accessor === 'amount') {
                return <td className={className}>{formatNumber(breakdown.amount, 2)}</td>;
              } else if (accessor === 'invoice_currency') {
                return <td>USD</td>;
              } else if (accessor === 'amount_in_usd') {
                return <td className={className}>{formatNumber(breakdown.amount_in_usd, 2)}</td>;
              }

              return <td>-</td>;
            })}
          </SummaryTableRow>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td className={cell.column.className} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </Modal>
  );
};

export default BankSettlementTransactionModal;
