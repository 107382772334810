import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Button from '../../../components/Button';
import StepIndicator from '../../../components/StepIndicator';
import Logger from '../../../utils/logger';
import SmallFileTable from '../../File/SmallFileTable';
import { UPLOAD_FILES_MODAL } from '../../Modal/constants';
import PATH from '../path';
import { signAgreement } from '@api/modules/application';
import { uploadCompanyFiles } from '@api/modules/dashboard/file';
import { STATUS_CODE } from '@constants/application';
import { COMPANY_FILE_TYPE } from '@constants/file';
import { FUNDING_STATUS } from '@i18n/copywrite';
import { fetchApplication } from '@redux/modules/application/actions';
import { fetchFilesByType, startUploadFiles, endUploadFiles } from '@redux/modules/company/actions';
import { showModal } from '@redux/modules/UI/actions';
import { useIsUserAssignedAdmin } from '@redux/selectors';
import { convertToFullDate } from '@utils/dateHelpers';

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

const Header = styled.div`
  ${(props) => props.theme.text.four};
  font-weight: 600;
`;

const InstructionWrapper = styled.div`
  padding: 10px 24px;
  margin-top: 24px;
  background-color: ${(props) => props.theme.colors.white};
`;

const StepWrapper = styled.div`
  margin: 4px 0;
`;

const ContentWrapper = styled.div`
  margin-left: 40px;
  button {
    display: block;
  }
  div:first-child {
    vertical-align: top;
  }
`;

const Instruction = styled.span`
  vertical-align: middle;
  margin-left: 20px;
`;

const ModalDescription = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  margin: 10px 0 20px 0;
`;

const UploadDescriptionHeader = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: bold;
`;

const CheckDetailLabel = styled(Link)`
  vertical-align: middle;
  ${(props) => props.theme.text.link};
  margin-left: 8px;
`;

const FieldTitle = styled.span`
  color: ${(props) => props.theme.colors.grayThree};
`;

const FieldData = styled.span`
  margin-left: 8px;
`;

const FileModalDescription = (
  <>
    <ModalDescription>
      Please upload signed agreement to process to next step. Once submitted, the agreement will be finalized.
    </ModalDescription>
    <UploadDescriptionHeader scale={5}>Upload requirements:</UploadDescriptionHeader>
    <ModalDescription>
      <ul>
        <li>Upload PDF</li>
      </ul>
    </ModalDescription>
  </>
);

const RemittanceActivation = ({ viewOffer, acceptedOffer, applicationData = {} }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const agreementFiles = useSelector((state) => state.company.files[COMPANY_FILE_TYPE.AGREEMENT]);
  const streams = useSelector((state) => state.fund.streams);
  const isAssignedAdmin = useIsUserAssignedAdmin();

  const fetchAgreementFiles = async () => {
    await fetchFilesByType(COMPANY_FILE_TYPE.AGREEMENT, applicationData.applicationId);
  };

  useEffect(() => {
    fetchAgreementFiles();
  }, []);

  const updateApplication = async () => {
    await signAgreement(applicationData.applicationId);
    await fetchApplication();
  };

  useEffect(() => {
    if (
      Array.isArray(agreementFiles) &&
      agreementFiles.length > 0 &&
      applicationData.statusCode === STATUS_CODE.PENDING_USER_SIGN_AGREEMENT
    ) {
      updateApplication();
    }
  }, [JSON.stringify(agreementFiles)]);

  const goToRevenueStreamSelection = () => {
    history.push(PATH.SELECT_REVENUE_STREAM);
  };

  const showFilesModal = () => {
    const submitAgreementFiles = async (files) => {
      try {
        dispatch(startUploadFiles());
        if (Array.isArray(files) && files.length > 0) {
          await Promise.all(files.map((file) => uploadCompanyFiles(COMPANY_FILE_TYPE.AGREEMENT, [file], applicationData.applicationId)));
          await fetchAgreementFiles();
        }

        dispatch(endUploadFiles());
      } catch (err) {
        Logger.error(err);
      }
    };

    dispatch(
      showModal(UPLOAD_FILES_MODAL, {
        title: 'Upload Signed Agreement',
        description: FileModalDescription,
        onConfirmClick: submitAgreementFiles,
        acceptFileType: 'application/pdf',
        disabled: false,
      })
    );
  };

  return (
    <Wrapper>
      <Header>{`Status: ${FUNDING_STATUS[applicationData.statusCode]}`}</Header>
      <InstructionWrapper>
        <Header>To do:</Header>
        {(() => {
          const isActive = applicationData.statusCode === STATUS_CODE.PENDING_USER_SIGN_AGREEMENT && streams.length === 0;
          const isCompleted = applicationData.statusCode > STATUS_CODE.PENDING_USER_SIGN_AGREEMENT || streams.length > 0;
          return (
            <>
              <StepWrapper>
                <StepIndicator stepNumber={1} showStepNumber highlight={isActive || isCompleted} showTick={isCompleted} />
                <Instruction>Select revenue channels</Instruction>
                {Array.isArray(streams) && streams.length > 0 && (
                  <>
                    <CheckDetailLabel to={PATH.SELECT_REVENUE_STREAM}>
                      {applicationData.statusCode === STATUS_CODE.PENDING_USER_SIGN_AGREEMENT && isAssignedAdmin && 'Edit'}
                    </CheckDetailLabel>
                    <CheckDetailLabel to={PATH.VIEW_REVENUE_STREAM}>Check Details</CheckDetailLabel>
                  </>
                )}
              </StepWrapper>
              {isActive && (
                <ContentWrapper>
                  <Button onClick={goToRevenueStreamSelection} disabled={!isAssignedAdmin}>
                    Select revenue channels
                  </Button>
                </ContentWrapper>
              )}
            </>
          );
        })()}
        {(() => {
          const isActive = applicationData.statusCode < STATUS_CODE.FUND_DISBURSED && streams.length > 0;
          const isCompleted = applicationData.statusCode > STATUS_CODE.PENDING_USER_SIGN_AGREEMENT;
          return (
            <>
              <StepWrapper>
                <StepIndicator stepNumber={2} showStepNumber highlight={isActive || isCompleted} showTick={isCompleted} />
                <Instruction>Upload signed agreement</Instruction>
              </StepWrapper>
              {isActive && (
                <ContentWrapper>
                  <Button onClick={showFilesModal} disabled={!isAssignedAdmin}>
                    Upload Signed Agreement
                  </Button>
                </ContentWrapper>
              )}
              {isCompleted && Array.isArray(agreementFiles) && agreementFiles.length > 0 && (
                <ContentWrapper>
                  <SmallFileTable files={agreementFiles} />
                </ContentWrapper>
              )}
            </>
          );
        })()}
        {(() => {
          const isActive = applicationData.statusCode === STATUS_CODE.PENDING_FUND_DISBURSEMENT;
          const isCompleted = applicationData.statusCode >= STATUS_CODE.FUND_DISBURSED;
          return (
            <>
              <StepWrapper>
                <StepIndicator stepNumber={3} showStepNumber highlight={isActive || isCompleted} showTick={isCompleted} />
                <Instruction>Enter actual fund disbursement date and invoice date</Instruction>
              </StepWrapper>
              {isActive && (
                <ContentWrapper>
                  <Button onClick={viewOffer(acceptedOffer)} disabled={!isAssignedAdmin}>
                    Go To Offer Details
                  </Button>
                </ContentWrapper>
              )}
              {isCompleted && (
                <ContentWrapper>
                  <div>
                    <FieldTitle>Actual Disbursement Date:</FieldTitle>
                    <FieldData>{convertToFullDate(acceptedOffer?.actualDisbursementDate)}</FieldData>
                  </div>
                  <div>
                    <FieldTitle>Actual First Invoice Date:</FieldTitle>
                    <FieldData>{convertToFullDate(acceptedOffer?.actualFirstInvoiceDate)}</FieldData>
                  </div>
                </ContentWrapper>
              )}
            </>
          );
        })()}
      </InstructionWrapper>
    </Wrapper>
  );
};

export default RemittanceActivation;
