import styled from 'styled-components';

import { media } from '@styles/breakpoints';

export const GridRow = styled.div`
  :after {
    clear: both;
    content: '';
    display: block;
  }
`;

// restricted to the row that elements are always kept in one line
export const FormRow = styled(GridRow)`
  margin: 4px 0;
  > {
    :first-child {
      padding-left: 0;
    }

    :last-child {
      padding-right: 0;
    }
  }
`;

const calculateWidth = (colSpan: number) => (colSpan / 12) * 100;
export const calculateGutter = (gutterWidth: number) => gutterWidth / 2;

export const GridColumn = styled.div<{ lg: number; md: number; sm: number; disableGutter: boolean; lgOffset: number; mdOffset: number; smOffset: number }>`
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  padding: ${(props) => (props.disableGutter ? 0 : calculateGutter(props.theme.gutter))}px;
  width: ${(props) => calculateWidth(props.lg)}%;
  margin-left: ${(props) => calculateWidth(props.lgOffset)}%;

  ${media.desktop`
    padding: ${(props) => (props.disableGutter ? 0 : calculateGutter(props.theme.gutter))}px;
    width: ${(props) => calculateWidth(props.md)}%;
    margin-left: ${(props) => calculateWidth(props.mdOffset)}%;
  `};

  ${media.phone`
    padding: ${(props) =>
    (props.disableGutter
      ? 0
      : `${calculateGutter(props.theme.mobile.gutter / 2)}px ${calculateGutter(props.theme.mobile.gutter)}px`)};
    width: ${(props) => calculateWidth(props.sm)}%;
    margin-left: ${(props) => calculateWidth(props.smOffset)}%;
  `};
`;

GridColumn.defaultProps = {
  lg: 12,
  lgOffset: 0,
  md: 12,
  mdOffset: 0,
  sm: 12,
  smOffset: 0,
  disableGutter: false,
};
