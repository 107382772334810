import moment from 'moment';
import { stringify } from 'query-string';

import { REACT_APP_API_CAL_ENGINE } from '@api/constants';
import { getCompanyId } from '@api/cookies';
import { getRequest, postRequest } from '@api/requests';

const remittanceEndpoint = `${REACT_APP_API_CAL_ENGINE}/v1/remittance_streams`;

export const createRemittanceStream = async (payload) => {
  const companyId = getCompanyId();
  const { status } = await fetch(`${remittanceEndpoint}`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      ...payload,
      company_id: companyId,
    }),
  });

  return {
    statusCode: status,
  };
};

export const getRemittanceStreams = async (payload) => {
  const companyId = getCompanyId();
  const query = { ...payload, company_id: companyId };
  const data = await fetch(`${remittanceEndpoint}/search?${stringify(query)}`, {
    method: 'GET',
    mode: 'cors',
  }).then((response) => {
    return response.json();
  });

  return {
    data,
  };
};

export const getInvoices = async () => {
  return {
    status: 400,
    data: [],
  };
};

export const setRemittanceStreamChannel = async (payload) => {
  const { status } = await fetch(`${remittanceEndpoint}/set_channel_accts`, {
    method: 'PUT',
    mode: 'cors',
    body: JSON.stringify(payload),
  });

  return {
    statusCode: status,
  };
};
