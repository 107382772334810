import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import BackButton from '../../../../../../components/BackButton';
import HR from '../../../../../../components/HR';
import Logger from '../../../../../../utils/logger';
import { Wrapper, Card } from '../../../../components';
import PATH from '../../../../path';
import { getConversionById } from '@api/modules/integration/airwallex';
import { useWalletAccount } from '@redux/selectors';
import { isRealObject } from '@utils/dataTypes';
import { convertToFullDate, convertToFullDatePlusTime } from '@utils/dateHelpers';
import { formatPrice } from '@utils/priceHelpers';

const Title = styled.div`
  ${(props) => props.theme.text.four};
  margin-bottom: 16px;
`;

const StyledBackButton = styled(BackButton)`
  margin-bottom: 24px;
`;

const StyledCard = styled(Card)`
  padding: 40px 20px;
`;

const TR = styled.tr`
  color: ${(props) => props.theme.colors.grayThree};
  td:first-child {
    width: ${(props) => props.cellWidth}px;
  }
  font-weight: 600;
`;

const ConversionDetails = () => {
  const dispatch = useDispatch();
  const walletAccount = useWalletAccount();
  const { conversionId } = useParams();
  const [conversionData, setConversionData] = useState({});
  const {
    client_rate,
    status,
    buy_amount,
    buy_currency,
    conversion_date,
    sell_amount,
    sell_currency,
    short_reference_id,
    settlement_cutoff_time,
    conversion_id,
  } = conversionData;

  useEffect(() => {
    const fetchConversionById = async () => {
      try {
        const { data } = await getConversionById(walletAccount?.account_id, conversionId);
        if (isRealObject(data)) {
          setConversionData(data);
        }
      } catch (err) {
        Logger.error(err);
      }
    };

    fetchConversionById();
  }, [JSON.stringify(walletAccount)]);

  return (
    <Wrapper>
      <StyledBackButton text="All conversions" path={PATH.WALLET_CONVERSION} />
      <Title>
        Conversion of
        {' '}
        {sell_currency}
        {' '}
        to
        {' '}
        {buy_currency}
      </Title>
      <TR cellWidth={150}>
        <td>Short reference ID:</td>
        <td>{short_reference_id}</td>
      </TR>
      <TR cellWidth={150}>
        <td>Status:</td>
        <td>{status}</td>
      </TR>
      <StyledCard>
        <Title>Conversion details</Title>
        <HR color="grayOne" />
        <table>
          <tbody>
            <TR cellWidth={180}>
              <td>Sell amount :</td>
              <td>{sell_currency === undefined ? '' : formatPrice(sell_currency, sell_amount, false, 2)}</td>
            </TR>
            <TR>
              <td>Exchange rate :</td>
              <td>{client_rate === undefined ? '' : client_rate}</td>
            </TR>
            <TR>
              <td>Buy amount :</td>
              <td>{buy_currency === undefined ? '' : formatPrice(buy_currency, buy_amount, false, 2)}</td>
            </TR>
          </tbody>
        </table>
        <HR color="grayOne" />
        <table>
          <tbody>
            <TR cellWidth={180}>
              <td>Short reference ID :</td>
              <td>{short_reference_id}</td>
            </TR>
            <TR>
              <td>Conversion ID :</td>
              <td>{conversion_id}</td>
            </TR>
          </tbody>
        </table>
      </StyledCard>
      <StyledCard>
        <Title>Conversion dates</Title>
        <HR color="grayOne" />
        <table>
          <tbody>
            <TR cellWidth={180}>
              <td>Conversion booked :</td>
              <td>{convertToFullDatePlusTime(settlement_cutoff_time)}</td>
            </TR>
            <TR>
              <td>Settlement cutoff time :</td>
              <td>{convertToFullDatePlusTime(settlement_cutoff_time)}</td>
            </TR>
            <TR>
              <td>Settlement date:</td>
              <td>{convertToFullDate(conversion_date)}</td>
            </TR>
          </tbody>
        </table>
      </StyledCard>
    </Wrapper>
  );
};

export default ConversionDetails;
