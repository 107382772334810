import { Currency } from '@/constants';
import { formatPrice } from '@utils/priceHelpers';

export const DEFAULT_OFFER_AMOUNT = 500000;

// for both slider and input
export const CALCULATOR_CONFIG = {
  min: 10000,
  max: 2000000,
  step: 1000,
};

// for slider ui only
export const SLIDER_CONFIG = {
  [CALCULATOR_CONFIG.min]: formatPrice(Currency.USD, CALCULATOR_CONFIG.min, true, 0),
  1000000: formatPrice(Currency.USD, 1000000, true, 0),
  [CALCULATOR_CONFIG.max]: formatPrice(Currency.USD, CALCULATOR_CONFIG.max, true, 0),
};
