import { css } from 'styled-components';

import { OPTION_VALUE_ALL } from '@constants/options';

export const paymentStatusOptions = [
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: 'succeeded',
    label: 'Succeeded',
  },
  {
    value: 'requires_action',
    label: 'Requires action',
  },
  {
    value: 'requires_payment_method',
    label: 'Requires payment method',
  },
  {
    value: 'requires_confirmation',
    label: 'Requires confirmation',
  },
  {
    value: 'PENDING',
    label: 'Pending',
  },
  {
    value: 'processing',
    label: 'Processing',
  },
  {
    value: 'canceled',
    label: 'Cancelled',
  },
];

export const paymentMethodOptions = [
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: 'BANK_TRANSFER',
    label: 'Bank transfer',
  },
  {
    value: 'DIRECT_DEBIT',
    label: 'Direct debit',
  },
  {
    value: 'CHEQUE',
    label: 'Cheque',
  },
  {
    value: 'WIRE',
    label: 'Wire',
  },
  {
    value: 'CARD_STRIPE',
    label: 'Card (stripe)',
  },
  {
    value: '3D_SECURE',
    label: '3D secure',
  },
  {
    value: 'AIRWALLET',
    label: 'Airwallet',
  },
];

export const paymentStatus = [];

export const refundReasonOptions = [
  {
    value: 'duplicate',
    label: 'Duplicate',
  },
  {
    value: 'fraudulent',
    label: 'Fraudulent',
  },
  {
    value: 'requested_by_customer',
    label: 'Requested by customer',
  },
];
