import { LOGOUT_USER } from '../auth/constants';
import { SELECT_COMPANY_REQUEST } from '../company/constants';
import { SWITCH_TO_ADMIN_VIEW, SWITCH_TO_USER_VIEW } from '../UI/constants';
import {
  GET_SETTLEMENTS_REQUEST,
  GET_SETTLEMENTS_FAILURE,
  GET_SETTLEMENTS_SUCCESS,
  SET_XERO_BANK_ACCOUNTS,
  START_TRANSACTIONS_SELECTION,
  END_TRANSACTIONS_SELECTION,
} from './constants';

const initialState = {
  list: [],
  xeroBankAccounts: [],
  isLoading: false,
};

const settlementReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_SETTLEMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SETTLEMENTS_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case GET_SETTLEMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SET_XERO_BANK_ACCOUNTS:
      return {
        ...state,
        xeroBankAccounts: action.payload,
      };
    case START_TRANSACTIONS_SELECTION:
      return {
        ...state,
        isSelectingTrx: true,
      };
    case END_TRANSACTIONS_SELECTION:
      return {
        ...state,
        isSelectingTrx: false,
      };
    // case SWITCH_TO_ADMIN_VIEW:
    // case SELECT_COMPANY_REQUEST:
    case SWITCH_TO_USER_VIEW:
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default settlementReducer;
