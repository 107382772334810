export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST';
export const GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';

export const GET_COMPANY_OWNERS_REQUEST = 'GET_COMPANY_OWNERS_REQUEST';
export const GET_COMPANY_OWNERS_FAILURE = 'GET_COMPANY_OWNERS_FAILURE';
export const GET_COMPANY_OWNERS_SUCCESS = 'GET_COMPANY_OWNERS_SUCCESS';

export const GET_COMPANY_ENTITIES_REQUEST = 'GET_COMPANY_ENTITIES_REQUEST';
export const GET_COMPANY_ENTITIES_FAILURE = 'GET_COMPANY_ENTITIES_FAILURE';
export const GET_COMPANY_ENTITIES_SUCCESS = 'GET_COMPANY_ENTITIES_SUCCESS';

export const GET_USER_COMPANY_REQUEST = 'GET_USER_COMPANY_REQUEST';
export const GET_USER_COMPANY_FAILURE = 'GET_USER_COMPANY_FAILURE';
export const GET_USER_COMPANY_SUCCESS = 'GET_USER_COMPANY_SUCCESS';

export const GET_ADMIN_COMPANIES_REQUEST = 'GET_ADMIN_COMPANIES_REQUEST';
export const GET_ADMIN_COMPANIES_FAILURE = 'GET_ADMIN_COMPANIES_FAILURE';
export const GET_ADMIN_COMPANIES_SUCCESS = 'GET_ADMIN_COMPANIES_SUCCESS';

export const SELECT_COMPANY_REQUEST = 'SELECT_COMPANY_REQUEST';
export const SELECT_COMPANY_FAILURE = 'SELECT_COMPANY_FAILURE';
export const SELECT_COMPANY_SUCCESS = 'SELECT_COMPANY_SUCCESS';

export const GET_FILES_BY_TYPE_REQUEST = 'GET_FILES_BY_TYPE_REQUEST';
export const GET_FILES_BY_TYPE_SUCCESS = 'GET_FILES_BY_TYPE_SUCCESS';
export const GET_FILES_BY_TYPE_FAILURE = 'GET_FILES_BY_TYPE_FAILURE';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';

export const GET_COMPANY_2FA_REQUEST = 'GET_COMPANY_2FA_REQUEST';
export const GET_COMPANY_2FA_SUCCESS = 'GET_COMPANY_2FA_SUCCESS';
export const GET_COMPANY_2FA_FAILURE = 'GET_COMPANY_2FA_FAILURE';
