import styled, { css } from 'styled-components';

import Select from '../../../../components/Select';

// TODO: use Dashboard/components
export const Table = styled.table`
  width: 100%;
  margin-top: 28px;
  border-collapse: collapse;
  background: white;
`;

export const Th = styled.th`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  font-weight: 600;
  padding: 14px;
`;

export const TR = styled.tr`
  border-bottom: 1px solid ${(props) => props.theme.colors.grayTwo};
  border-top: 1px solid ${(props) => props.theme.colors.grayTwo};
  width: ${(props) => props.width};

  &:last-child {
    border-style: none;
  }

  &:hover {
    cursor: ${(props) => props.cursor};
  }
`;

export const TD = styled.td`
  ${(props) => props.theme.text.micro};
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
  padding: 16px;
  font-weight: 600;
`;

export const FlagWrapper = styled.div`
  display: flex;
  padding: 16px;
`;

export const CurrencyWrapper = styled.div`
  padding-left: 10px;
`;

export const BalanceTitle = styled.td`
  font-weight: 600;
`;

export const FilterLabel = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  margin-bottom: 4px;
  font-weight: 600;
  text-align: left;
`;

export const SelectWrapper = styled.div`
  margin-bottom: 36px;
  text-align: right;
`;

export const StyledSelect = styled(Select)`
  width: ${(props) => props.width};
  font-weight: 600;
  min-width: 150px;
`;
