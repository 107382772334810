import { LOGOUT_USER } from '../auth/constants';
import { SELECT_COMPANY_REQUEST } from '../company/constants';
import { SWITCH_TO_ADMIN_VIEW, SWITCH_TO_USER_VIEW } from '../UI/constants';
import { GET_BANKS_REQUEST, GET_BANKS_SUCCESS, GET_BANKS_FAILURE } from './constants';

const initialState = {
  activeBankAccounts: [],
  allBankAccounts: [],
  selectedBankId: '', // TODO: put editing bank id here
  isLoading: false,
};

const bankReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_BANKS_REQUEST:
      return {
        ...state,
        isLoading: false,
      };
    case GET_BANKS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_BANKS_SUCCESS:
      return {
        ...state,
        activeBankAccounts: action.payload.activeBankAccounts,
        allBankAccounts: action.payload.allBankAccounts,
        isLoading: false,
      };
    // case SWITCH_TO_ADMIN_VIEW:
    // case SELECT_COMPANY_REQUEST:
    case SWITCH_TO_USER_VIEW:
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default bankReducer;
