import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Email from '../../../../components/Email';

const Header = styled.div`
  ${(props) => props.theme.text.two};
  margin-bottom: 24px;
`;

const NoOfferPage = () => {
  return (
    <>
      <Header>Offer not available</Header>
      <div>
        Your application is under review by our investment team.
        <br />
        <br />
        Your investment manager should contact you in 24 hours to discuss with your about further information and document required. If you have not received an invitation, please contact us at
        {' '}
        <Email address="hello@choco-up.com" />
        .
      </div>
    </>
  );
};

export default NoOfferPage;
