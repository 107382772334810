import store from '../../store';
import {
  GET_CURRENCY_REQUEST,
  GET_CURRENCY_SUCCESS,
  GET_CURRENCY_FAILURE,
} from './constants';
import { formatInputCurrencyOptions } from './helpers';
import { getInputCurrency } from '@api/modules/metadata/currency';

export const fetchInputCurrency = async () => {
  store.dispatch({ type: GET_CURRENCY_REQUEST });

  const data = await getInputCurrency();

  if (Array.isArray(data)) {
    store.dispatch({
      type: GET_CURRENCY_SUCCESS,
      payload: formatInputCurrencyOptions(data),
    });
  } else {
    store.dispatch({ type: GET_CURRENCY_FAILURE });
  }
};
