import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import BackButton from '../../../../../components/BackButton';
import Button from '../../../../../components/Button';
import LoadingCircle from '../../../../../components/LoadingCircle';
import ToolTip from '../../../../../components/ToolTip';
import { Wrapper } from '../../../components';
import PATH from '../../../path';
import { WALLET_COUNTRIES, TOOLTIP_DESCRIPTIONS } from '../../constants';
import BalanceTable from './BalanceTable';
import { getCompanyId } from '@api/cookies';
import { ADD_FUNDS_MODAL } from '@modules/Modal/constants';
import { showModal } from '@redux/modules/UI/actions';
import { fetchWalletCurrentBalance } from '@redux/modules/wallet/actions';
import { useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';
import { media } from '@styles/breakpoints';
import { findFirstMatchInArray } from '@utils/dataTypes';
import { formatPrice } from '@utils/priceHelpers';

const Title = styled.div`
  ${(props) => props.theme.text.four};
  font-weight: 600;
`;

const SubTitle = styled.span`
  ${(props) => props.theme.text.five};
  font-weight: 600;
  margin-bottom: 6px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.dablet`
    flex-direction: column;
  `};
`;

const HeaderWrapper = styled.div`
  display: flex;
`;

const StyledToolTip = styled(ToolTip)`
  margin-left: 8px;
  display: inline;
  vertical-align: middle;
`;

const Label = styled.span`
  ${(props) => props.theme.text.five};
  font-weight: 600;
`;

const LabelWrapper = styled.div``;

const SmallLabelWrapper = styled.div`
  margin: 1px 0px 0px 26px;
`;

const SmallLabel = styled.span`
  ${(props) => props.theme.text.micro};
  font-weight: 600;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.dablet`
    display: flex;
    gap: 5px;
    justify-content: start;
  `};
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
`;

const TableSection = styled.div`
  margin-top: 28px;
`;

export const TD = styled.td`
  ${(props) => props.theme.text.micro};
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
  padding: 16px;
  font-weight: 600;
`;

const FlagWrapper = styled.div`
  margin: 20px 26px 0px 0px;
`;

const WalletBalanceCurrency = () => {
  const { currency } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const companyId = getCompanyId();
  const walletAccounts = useSelector((state) => state.wallet.walletAccounts);
  const countryOrigin = useSelector((state) => state.company?.data[companyId]?.countryOrigin);
  const currencyBalances = useSelector((state) => state.wallet.currencyBalances);
  const [isLoading, toggleIsLoading] = useState(false);
  const [accountBalance, setAccountBalance] = useState('');
  const [availableBalance, setAvailableBalance] = useState('');
  const [reservedBalance, setReservedBalance] = useState('');
  const disabled = useCheckIsAdminViewingAnotherCompany();

  useEffect(() => {
    if (Array.isArray(walletAccounts)) {
      fetchWalletCurrentBalance(walletAccounts[0]?.account_id);
    }
  }, [JSON.stringify(walletAccounts)]);

  useEffect(() => {
    if (Array.isArray(currencyBalances)) {
      const selectedCurrencyBalance = findFirstMatchInArray(currencyBalances, (balance) => {
        return balance.currency === currency;
      });

      if (selectedCurrencyBalance) {
        setAccountBalance(selectedCurrencyBalance.total_amount);
        setReservedBalance(selectedCurrencyBalance.reserved_amount);
        setAvailableBalance(selectedCurrencyBalance.available_amount);
      }
    }
  }, [JSON.stringify(currencyBalances)]);

  useEffect(() => {
    if (Array.isArray(currencyBalances)) {
      const selectedCurrencyBalance = findFirstMatchInArray(currencyBalances, (balance) => {
        return balance.currency === currency;
      });

      if (selectedCurrencyBalance) {
        setAccountBalance(selectedCurrencyBalance.total_amount);
        setReservedBalance(selectedCurrencyBalance.reserved_amount);
        setAvailableBalance(selectedCurrencyBalance.available_amount);
      }
    }
  }, [JSON.stringify(currencyBalances)]);

  const handleAddFunds = () => {
    dispatch(
      showModal(ADD_FUNDS_MODAL, {
        countryOrigin,
        id: walletAccounts?.[0]?.account_id,
      })
    );
  };

  const showAddTransferModal = () => {
    history.push(PATH.WALLET_TRANSFER_CREATE_PAYOUTS);
  };

  return isLoading ? (
    <LoadingCircle />
  ) : (
    <Wrapper>
      <BackButton text="All Currencies" path={PATH.WALLET_BALANCES} />
      <Container>
        <HeaderWrapper>
          <FlagWrapper>
            <img src={WALLET_COUNTRIES[currency].logoUrl} alt="country flags" />
          </FlagWrapper>
          <LabelWrapper>
            <Label>Available balance</Label>
            <StyledToolTip place="top" tip={TOOLTIP_DESCRIPTIONS.AVAILABLE_BALANCE} width={300} />
            <div>
              <Title>{formatPrice(currency, availableBalance, false, 2)}</Title>
            </div>
          </LabelWrapper>
          <SmallLabelWrapper>
            <SmallLabel>Account balance</SmallLabel>
            <StyledToolTip place="top" tip={TOOLTIP_DESCRIPTIONS.ACCOUNT_BALANCE} width={300} />
            <div>
              <SubTitle>{formatPrice(currency, accountBalance, false, 2)}</SubTitle>
            </div>
          </SmallLabelWrapper>
          <SmallLabelWrapper>
            <SmallLabel>Reserved balance</SmallLabel>
            <StyledToolTip place="top" tip={TOOLTIP_DESCRIPTIONS.RESERVED_BALANCE} width={300} />
            <div>
              <SubTitle>{formatPrice(currency, reservedBalance, false, 2)}</SubTitle>
            </div>
          </SmallLabelWrapper>
        </HeaderWrapper>
        <ButtonWrapper>
          <StyledButton onClick={handleAddFunds} secondary disabled={isLoading || disabled}>
            Add Funds
          </StyledButton>
          <StyledButton onClick={showAddTransferModal} disabled={isLoading || disabled}>
            Send Money
          </StyledButton>
        </ButtonWrapper>
      </Container>
      <TableSection>
        <Title>{currency} Transactions</Title>
        <BalanceTable id={walletAccounts?.[0]?.account_id} currency={currency} />
      </TableSection>
    </Wrapper>
  );
};
export default WalletBalanceCurrency;
