import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import Button from '../../../components/Button';
import Select from '../../../components/Select';
import { instruction } from './text';
import CommentSection from './CommentSection';
import { useIsUserAssignedAdmin, useAcceptedOffer } from '@redux/selectors';
import { isRejectedByCreditTeam } from '@utils/applicationHelpers';
import RemittanceActivation from './RemittanceActivation';
import featureToggle from '../../../featureToggle';
import { TERM_SHEET_MODAL } from '../../Modal/constants';
import OfferCard, { Wrapper as OfferCardWrapper } from '../../Offer/OfferCard';
import { Wrapper } from '../components';
import { CONTENT_CONTAINER_ID } from '../constants';
import { GridColumn } from '@components/Grid';
import { STATUS_CODE } from '@constants/application';
import { offerReviewOptions, offerReturnOptions } from '@constants/options';
import { fetchApplication } from '@redux/modules/application/actions';
import { submitOffers, fetchAdminOffers, fetchDraftOffers, approveOffers, returnOffers, rejectOffers } from '@redux/modules/offer/actions';
import { showModal } from '@redux/modules/UI/actions';
import { useCanUserSubmitFundingProposal, useCanUserJudgeFundingProposal, useCanUserJudgeFundingOffer } from '@utils/userHelpers';

const Instruction = styled.div`
  ${(props) => props.theme.text.four};
  margin-bottom: 25px;
`;

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.error};
`;

const AddOfferCard = styled(OfferCardWrapper)`
  position: relative;
  cursor: pointer;

  ::after,
  ::before {
    content: '';
    position: absolute;
    background-color: ${(props) => props.theme.colors.grayTwo};
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}

  ::after {
    width: 2px;
    height: 77px;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
  }

  ::before {
    height: 2px;
    width: 77px;
    left: 50%;
    transform: translateX(-50%);
    top: 50%;
  }
`;

const ButtonWrapper = styled.div`
  text-align: right;
`;

const Overview = () => {
  const dispatch = useDispatch();

  const { adminOffers, error: offersError, isLoading } = useSelector((state) => state.offer);
  const acceptedOffer = useAcceptedOffer();
  const { data: applicationData } = useSelector((state) => state.application);
  const isUserTheAssignedAdmin = useIsUserAssignedAdmin();
  const hasPermissionToSubmitProposal = useCanUserSubmitFundingProposal();
  const hasPermissionToJudgeProposal = useCanUserJudgeFundingProposal();
  const hasPermissionToJudgeFinalOffer = useCanUserJudgeFundingOffer();
  const isReadyToDisburse = applicationData.statusCode === STATUS_CODE.PENDING_FUND_DISBURSEMENT;
  const isAbleToCreateOffers =
    applicationData.statusCode === STATUS_CODE.PENDING_INVESTMENT_REVIEW && hasPermissionToSubmitProposal && isUserTheAssignedAdmin;
  const [reviewDecision, setReviewDecision] = useState();

  const fetchOffers = async () => {
    if (applicationData.applicationId) {
      if (isAbleToCreateOffers) {
        await fetchDraftOffers(applicationData.applicationId);
      } else {
        await fetchAdminOffers(applicationData.applicationId);
      }
    }
  };

  useEffect(() => {
    fetchApplication();
  }, []);

  useEffect(() => {
    fetchOffers();
  }, [applicationData.applicationId]);

  const viewOffer = (offer, index) => () => {
    dispatch(
      showModal(TERM_SHEET_MODAL, {
        offer,
        index,
        disabled: !isAbleToCreateOffers,
        fetchOffers,
        isReadyToDisburse,
      })
    );
  };

  const createOffer = () => {
    if (isAbleToCreateOffers) {
      dispatch(
        showModal(TERM_SHEET_MODAL, {
          ...(adminOffers.length > 0 && {
            defaultValues: { expiryDate: adminOffers[0].expiryDate },
          }),
          index: adminOffers.length,
          fetchOffers,
        })
      );
    }
  };

  const handleSubmit = async () => {
    await submitOffers(applicationData.applicationId, adminOffers);
    await fetchApplication();
  };

  const submitReviewDecision = async () => {
    const isManagerAction =
      [STATUS_CODE.PENDING_INVESTMENT_MANAGER_REVIEW, STATUS_CODE.PENDING_USER_REVIEW_TERM_SHEET].indexOf(applicationData.statusCode) !==
      -1;
    const params = [applicationData.applicationId, isManagerAction];
    switch (reviewDecision) {
      case 'APPROVE':
        await approveOffers(...params);
        break;
      case 'RETURN':
        await returnOffers(...params);
        break;
      case 'REJECT':
        await rejectOffers(...params);
        break;
      default:
        break;
    }

    setReviewDecision();
    fetchOffers();
    fetchApplication();
    document.getElementById(CONTENT_CONTAINER_ID)?.scroll(0, 0);
  };

  const getInstruction = () => {
    const message = instruction[applicationData.statusCode];

    const identity = hasPermissionToJudgeFinalOffer
      ? 'credit'
      : hasPermissionToJudgeProposal
      ? 'manager'
      : hasPermissionToSubmitProposal
      ? 'analyst'
      : '';
    if (message && identity) {
      const result = message[identity];
      if (STATUS_CODE.REJECTED === applicationData.statusCode) {
        return result.replace('{whom}', isRejectedByCreditTeam(applicationData.history) ? 'credit team' : 'manager');
      }
      return result;
    }

    return '';
  };

  const shouldShowButtonForManager =
    [STATUS_CODE.PENDING_INVESTMENT_MANAGER_REVIEW, STATUS_CODE.PENDING_USER_REVIEW_TERM_SHEET].indexOf(applicationData.statusCode) !==
      -1 && hasPermissionToJudgeProposal;
  const shouldShowButtonForCreditTeam =
    applicationData.statusCode === STATUS_CODE.PENDING_CREDIT_TEAM_REVIEW && hasPermissionToJudgeFinalOffer;

  const displayOffers =
    applicationData.statusCode < STATUS_CODE.PENDING_USER_SIGN_AGREEMENT || !featureToggle.REMITTANCE
      ? adminOffers
      : acceptedOffer
      ? [acceptedOffer]
      : [];

  const getReviewOptions = () => {
    switch (applicationData.statusCode) {
      case STATUS_CODE.PENDING_INVESTMENT_MANAGER_REVIEW:
      case STATUS_CODE.PENDING_CREDIT_TEAM_REVIEW:
        return offerReviewOptions;
      case STATUS_CODE.PENDING_USER_REVIEW_TERM_SHEET:
        return offerReturnOptions;
      default:
        return [];
    }
  };

  const reviewOptions = getReviewOptions();

  return (
    <Wrapper>
      {applicationData.statusCode < STATUS_CODE.PENDING_USER_SIGN_AGREEMENT ? (
        <Instruction>{getInstruction()}</Instruction>
      ) : featureToggle.REMITTANCE ? (
        <RemittanceActivation viewOffer={viewOffer} acceptedOffer={acceptedOffer} applicationData={applicationData} />
      ) : null}
      <div>
        {Array.isArray(adminOffers) && adminOffers.length < 3 && applicationData.statusCode === STATUS_CODE.PENDING_INVESTMENT_REVIEW && (
          <AddOfferCard onClick={createOffer} disabled={!isAbleToCreateOffers} />
        )}
        {Array.isArray(displayOffers) &&
          displayOffers.length > 0 &&
          displayOffers.map((offer, index) => (
            <OfferCard
              key={offer.id}
              offer={offer}
              index={index}
              statusCode={applicationData.statusCode}
              onButtonClick={viewOffer(offer, index)}
            />
          ))}
      </div>
      <ButtonWrapper>
        {offersError && <ErrorMessage>{offersError}</ErrorMessage>}
        {/* analytics button */}
        {isAbleToCreateOffers && (
          <Button
            onClick={handleSubmit}
            disabled={(Array.isArray(adminOffers) && adminOffers.length === 0) || isLoading || !hasPermissionToSubmitProposal}
          >
            Submit
          </Button>
        )}
        {/* Investment manager buttons */}
        {(shouldShowButtonForManager || shouldShowButtonForCreditTeam) && (
          <>
            <GridColumn lg={4} md={6} sm={12}>
              <Select value={reviewDecision} options={reviewOptions} onChange={setReviewDecision} />
            </GridColumn>
            <GridColumn lg={12}>
              <Button onClick={submitReviewDecision} disabled={isLoading || !reviewDecision}>
                Confirm
              </Button>
            </GridColumn>
          </>
        )}
      </ButtonWrapper>
      <CommentSection applicationId={applicationData.applicationId} />
    </Wrapper>
  );
};

export default Overview;
