import styled from 'styled-components';

import styles from './Insights.module.scss';

const InsightsWrapper = styled.div`
  margin-top: 90px;
  margin-bottom: 90px;
`;
const Insights = () => {
  const reports: { url: string; name: string }[] = [
    {
      url: 'https://uploads-ssl.webflow.com/62abd75c30087cf5eaaabbf2/62abd75c30087c879baabeaf_website_icon_sales%20report.png',
      name: 'Sales report',
    },
    {
      url: 'https://uploads-ssl.webflow.com/62abd75c30087cf5eaaabbf2/62abd75c30087c393baabeb3_website_icon_mkt%20report.png',
      name: 'Marketing report',
    },
    {
      url: 'https://uploads-ssl.webflow.com/62abd75c30087cf5eaaabbf2/62abd75c30087c50cbaabeb4_website_icon_accounting%20report.png',
      name: 'Accounting report',
    },
  ];

  const reportElements: JSX.Element[] = reports.map((report) => {
    return (
      <div key={report.name} className={styles['insights__report']}>
        <img src={report.url} alt={report.name} width="96" height="96" className={styles['insights__report--img']}></img>
        <h3 className={styles['insights__report--heading']}>{report.name}</h3>
      </div>
    );
  });

  return (
    <InsightsWrapper>
      <div className={styles['insights__container']}>
        <div className={styles['insights__content']}>
          <div>
            <h2 className={styles['insights__content--heading']}>Insights of various dimensions</h2>
            <div className={styles['insights__content--body']}>Cross analysis among different operation data to get the whole picture</div>
          </div>
          <div className={styles['insights__content--layout']}>{reportElements}</div>
        </div>
      </div>
    </InsightsWrapper>
  );
};

export default Insights;
