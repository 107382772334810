import Logger from '../../../utils/logger';
import store from '../../store';
import {
  GET_CREDIT_MODEL_REPORT_REQUEST,
  GET_CREDIT_MODEL_REPORT_FAILURE,
  GET_CREDIT_MODEL_REPORT_SUCCESS,
  GET_INTEGRATION_PROGRESS_REQUEST,
  GET_INTEGRATION_PROGRESS_FAILURE,
  GET_INTEGRATION_PROGRESS_SUCCESS,
} from './constants';
import { getCreditReport, getIntegrationTasks } from '@api/modules/dashboard';

export const fetchCreditModelReport = async () => {
  store.dispatch({ type: GET_CREDIT_MODEL_REPORT_REQUEST });

  try {
    const { data } = await getCreditReport();

    if (Array.isArray(data) && data.length > 0) {
      store.dispatch({
        type: GET_CREDIT_MODEL_REPORT_SUCCESS,
        payload: data[0],
      });
      return;
    }

    throw new Error('Cannot find report');
  } catch (err) {
    store.dispatch({ type: GET_CREDIT_MODEL_REPORT_FAILURE });
    Logger.error(err);
  }
};

export const fetchIntegrationProgress = async (integrationId, query) => {
  store.dispatch({ type: GET_INTEGRATION_PROGRESS_REQUEST });

  try {
    const { data } = await getIntegrationTasks(query);

    if (Array.isArray(data.results) && data.results.length > 0) {
      store.dispatch({
        type: GET_INTEGRATION_PROGRESS_SUCCESS,
        payload: { progress: data.results[0], integrationId, platform: query.platform },
      });
      return;
    }
  } catch (err) {
    store.dispatch({ type: GET_INTEGRATION_PROGRESS_FAILURE, payload: query });
    Logger.error(err);
  }
};
