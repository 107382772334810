import React, { useState } from 'react';

import styled, { css } from 'styled-components';

import COLORS from '../styles/colors';

const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grayTwo};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px 16px;
  font-size: 15px;
  line-height: 24px;
  background: white;
  width: ${(props) => props.width};

  ${(props) =>
    props.full &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.disabled &&
    css`
      background-color: rgba(239, 239, 239, 0.3);
    `}

  :focus-within {
    border: 1px solid ${COLORS.black};
    box-shadow: inset 1px 0 ${COLORS.black}, inset 0 1px ${COLORS.black}, inset -1px 0 ${COLORS.black},
      inset 0 -1px ${COLORS.black};
  }
`;

const Input = styled.input`
  border: none;
  display: inline;
  vertical-align: middle;
  width: calc(100% - 42px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  :focus-visible {
    outline: none;
  }

  :disabled {
    background-color: transparent;
  }

  ::placeholder {
    color: ${(props) => props.theme.colors.grayTwo};
    ${(props) => props.theme.text.five};
    font-weight: 500;
  }

  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type='number'] {
    -moz-appearance: textfield;
  }
`;

const SearchIcon = styled.img`
  display: inline;
  float: left;
  vertical-align: middle;
  margin-right: 12px;
`;
const SearchBar = ({ placeholder, onChange, disabled, value, width }) => {
  return (
    <Wrapper width={width}>
      <SearchIcon src="/icons/search-icon.svg" />
      <Input disabled={disabled} value={value} type="text" placeholder={placeholder} onChange={onChange} />
    </Wrapper>
  );
};

export default SearchBar;
