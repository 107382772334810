import styled from 'styled-components';

import styles from './Featured.module.scss';

const FeaturedWrapper = styled.div`
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(199, 193, 215, 0.05);

  @media screen and (max-width: 991px) {
    display: none;
  }
`;
const Featured = () => {
  const images: { url: string; width: number }[] = [
    {
      url: 'https://uploads-ssl.webflow.com/62abd75c30087cf5eaaabbf2/62abd75c30087c3a67aabd3d_dealstreet.png',
      width: 166,
    },
    {
      url: 'https://uploads-ssl.webflow.com/62abd75c30087cf5eaaabbf2/62abd75c30087c72c5aabdbf_techinasia.svg',
      width: 226,
    },
    {
      url: 'https://uploads-ssl.webflow.com/62abd75c30087cf5eaaabbf2/62abd75c30087c785caabe41_technobusiness.png',
      width: 294,
    },
    {
      url: 'https://uploads-ssl.webflow.com/62abd75c30087cf5eaaabbf2/62abd75c30087c6475aabd35_business-today-logo.svg',
      width: 216,
    },
    {
      url: 'https://uploads-ssl.webflow.com/62abd75c30087cf5eaaabbf2/62abd75c30087ccc20aabd2e_e27.svg',
      width: 117,
    },
    {
      url: 'https://uploads-ssl.webflow.com/62abd75c30087cf5eaaabbf2/62abd75c30087c27e2aabe4e_image%205.png',
      width: 133,
    },
  ];

  const imageElements: JSX.Element[] = images.map((image, index) => {
    return (
      <img src={image.url} key={index} alt="choco up logo" className={styles['featured__image']} width={image.width} height="70"></img>
    );
  });

  return (
    <FeaturedWrapper>
      <div className={styles['featured']}>
        <div className={styles['featured__container']}>
          <div className={styles['featured__title']}>
            <h2 className={styles['featured__title--heading']}>We are featured</h2>
          </div>
          <div style={{ display: 'block' }}>
            <div style={{ display: 'flex' }}>{imageElements}</div>
          </div>
        </div>
      </div>
    </FeaturedWrapper>
  );
};

export default Featured;
