import moment from 'moment';

import { MESSAGE_MODAL } from '../../../Modal/constants';
import { BANK_STATUS } from '@constants/bank';
import { showModal } from '@redux/modules/UI/actions';
import { findFirstMatchInArray } from '@utils/dataTypes';
import { checkSameDay } from '@utils/dateHelpers';
import { getOptionLabelByValue } from '@utils/optionHelpers';

export const generateXeroBankAccountOption = (account) => ({
  value: account.BankAccountID,
  label: account.BankAccountName,
});

export const getPreviouslySelectedTransactionIds = (transactions) => {
  return transactions.filter(({ used }) => used).map(({ transaction_id }) => transaction_id);
};

export const showConfirmWithdrawModal = (withdrawSettlement = () => {}) =>
  showModal(MESSAGE_MODAL, {
    title: 'Confirm to withdraw this draft settlement selection record?',
    description: 'All selected items will not go to remittance record.',
    onConfirm: withdrawSettlement,
    leftButtonProps: {
      secondary: true,
      tertiary: false,
      warning: true,
    },
    rightButtonProps: {
      warning: true,
      label: 'Withdraw',
    },
  });

export const showConfirmAppealModal = (appealSettlement = () => {}) =>
  showModal(MESSAGE_MODAL, {
    title: 'Confirm to appeal this settlement selection record?',
    description: 'Our sales manager will get to you soon',
    onConfirm: appealSettlement,
    leftButtonProps: {
      secondary: true,
      tertiary: false,
      warning: true,
    },
    rightButtonProps: {
      warning: true,
      label: 'Appeal',
    },
  });

export const formatBankName = (account) => {
  const elements = [account.bankName, account.accountName, account.accountNumber].filter((ele) => !!ele);

  return `${elements.join(' - ')}${account.status === BANK_STATUS.INACTIVE ? ' (Removed)' : ''}`;
};

export const combineTransactionsWithBalanceHistories = (transactions = [], balanceHistories = []) => {
  const converted = transactions.map((transaction, index) => {
    const balanceAmount = findFirstMatchInArray(balanceHistories, ({ balance_date }) => {
      return checkSameDay(transaction.posted_date, balance_date);
    })?.amount;
    const previousTransaction = transactions[index - 1];
    const nextTransaction = transactions[index + 1];

    return {
      ...transaction,
      posted_date:
        previousTransaction?.posted_date && checkSameDay(previousTransaction?.posted_date, transaction.posted_date)
          ? ''
          : transaction.posted_date,
      deposit: transaction.amount >= 0 ? transaction.amount : NaN,
      withdraw: transaction.amount < 0 ? transaction.amount : NaN,
      balanceAmount:
        nextTransaction?.posted_date && checkSameDay(nextTransaction?.posted_date, transaction.posted_date)
          ? ''
          : balanceAmount,
    };
  });
  return converted;
};
