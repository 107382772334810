import React from 'react';

import styled from 'styled-components';

import Dropdown, { DropdownOption } from '../../components/Dropdown';
import HR from '../../components/HR';
import { HeaderRow } from './components';
import PlatformStatusTag from './PlatformStatusTag';
import { FormRow, GridColumn } from '@components/Grid';
import { PLATFORM } from '@constants/platform';
import { getLogoByPlatformName, formatGAAccountLabel } from '@utils/platformHelpers';

const StyledGridColumn = styled(GridColumn)`
  line-height: 25px;
  vertical-align: middle;

  div {
    vertical-align: middle;
  }
`;

const Logo = styled.img`
  height: 25px;
  line-height: initial;
  vertical-align: middle;
`;

const RetryLabel = styled.div`
  ${(props) => props.theme.text.link};
`;

const MarketingAccountsTable = ({
  accounts,
  onDeletePlatformClick,
  disabled,
  showFBIntegrationModal,
  fbAdAcctLookup,
}) => {
  const formatName = ({ platformName, accountId, accountName, accountUserId }) => {
    if (platformName === PLATFORM.FACEBOOK_ADS) {
      if (fbAdAcctLookup[accountUserId] !== undefined) {
        return `${accountName} > ${fbAdAcctLookup[accountUserId]}`;
      }
      return accountName;
    }
    return accountId;
  };

  return (
    <>
      <HeaderRow>
        <StyledGridColumn lg={2} md={2}>
          Marketing Channel
        </StyledGridColumn>
        <StyledGridColumn lg={6} md={6}>
          Name
        </StyledGridColumn>
        <StyledGridColumn lg={2} md={2}>
          Status
        </StyledGridColumn>
        <StyledGridColumn lg={2} md={2} />
      </HeaderRow>
      {accounts.map((account) => {
        const { logoUrl } = getLogoByPlatformName(account?.platformName);
        return (
          <FormRow key={account.id}>
            <StyledGridColumn lg={2} md={2}>
              <Logo src={logoUrl} alt={account.platformName} />
            </StyledGridColumn>
            <StyledGridColumn lg={6} md={6}>
              {formatName(account)}
            </StyledGridColumn>
            <StyledGridColumn lg={2} md={2}>
              {account.platformName === PLATFORM.FACEBOOK_ADS && !account.accountUserId ? (
                <RetryLabel onClick={() => showFBIntegrationModal(account.id, account.accountId, account.accountName)}>
                  Choose Ad Account
                </RetryLabel>
              ) : (
                <PlatformStatusTag {...account} />
              )}
            </StyledGridColumn>
            {!disabled && (
              <StyledGridColumn lg={2} md={2}>
                {account.platformName === PLATFORM.FACEBOOK_ADS ? (
                  <Dropdown width={10}>
                    <DropdownOption
                      onClick={() => showFBIntegrationModal(account.id, account.accountId, account.accountName)}
                      warning
                    >
                      Settings
                    </DropdownOption>
                  </Dropdown>
                ) : (
                  <Dropdown width={160}>
                    {/* <DropdownOption>
                        Retry Connection
                    </DropdownOption> */}
                    <DropdownOption onClick={onDeletePlatformClick(account)} warning>
                      Delete Platform
                    </DropdownOption>
                  </Dropdown>
                )}
              </StyledGridColumn>
            )}
          </FormRow>
        );
      })}
      <HR />
    </>
  );
};

export default MarketingAccountsTable;
