import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import SimplePagination from '../../../../../components/Pagination/SimplePagination';
import Table from '../../../../../components/Table';
import { walletBalanceCurrencyColumns } from '../../constants';
import { usePagination } from '@hooks/UI';
import { fetchWalletTransactions } from '@redux/modules/wallet/actions';
import { convertToApiDate } from '@utils/dateHelpers';
import { formatPrice } from '@utils/priceHelpers';
import { areRecordsFewerThanPageSize } from '@utils/utilHelpers';

const StyledTable = styled(Table)`
  width: 100%;
  margin-top: 28px;
  border-collapse: collapse;
  background: white;

  th {
    ${(props) => props.theme.text.five};
    color: ${(props) => props.theme.colors.grayThree};
    font-weight: 600;
  }

  td {
    ${(props) =>
      props.color &&
      css`
        color: ${props.color};
      `}
    font-weight: 600;
  }
`;

const BalanceTable = ({ id, currency }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const transactions = useSelector((state) => state.wallet.transactionPageMap);
  const isLoading = useSelector((state) => state.wallet.isLoading);

  const { pageNumber, pageSize, pageCount, onPageNumberChange, onPageSizeChange, changePageCount } = usePagination({
    pageSize: 20,
  });

  const noNextPage = areRecordsFewerThanPageSize(transactions[pageNumber], pageSize);

  const formatPageNavLabel = (number) => {
    return ` ${number} `;
  };

  const getWalletTransactions = async () => {
    const filters = [{ field: 'currency', operator: 'in', value: [currency] }];
    fetchWalletTransactions(id, {
      pageNumber,
      pageSize,
      ...(startDate && { startDate: convertToApiDate(startDate) }),
      ...(endDate && { endDate: convertToApiDate(endDate) }),
      filters,
    });
  };

  useEffect(() => {
    if (currency) {
      getWalletTransactions();
    }
  }, [pageNumber, pageSize, currency]);

  return (
    <>
      <StyledTable data={transactions[pageNumber]} columns={walletBalanceCurrencyColumns} isLoading={isLoading} pagination={{ pageSize }} />
      <SimplePagination
        pageNumber={pageNumber}
        pageSize={pageSize}
        pageSizeOptions={[
          {
            value: 20,
            label: 20,
          },
          {
            value: 50,
            label: 50,
          },
          {
            value: 100,
            label: 100,
          },
        ]}
        onPageNumberChange={onPageNumberChange}
        onPageSizeChange={onPageSizeChange}
        formatPageNavLabel={formatPageNavLabel}
        noNextPage={noNextPage}
      />
    </>
  );
};
export default BalanceTable;
