import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import CheckBox, { CheckboxText } from '../../../../components/Checkbox';
import { Description } from '../../../Application/components';
import { MANUAL_INTEGRATION_FORM } from '../../../Modal/constants';
import { GridColumn } from '@components/Grid';
import { DD_EMAIL } from '@constants/email';
import { PLATFORM, PLATFORM_DISPLAY_NAME } from '@constants/platform';
import { showModal, hideModal } from '@redux/modules/UI/actions';

const Title = styled.div`
  ${(props) => props.theme.text.two};
  margin: 10px 0 20px 0;
`;
const InstructionTitle = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: bold;
  margin: 20px 0;
`;

const InstructionsImage = styled.img`
  width: 75%;
  height: auto;
  margin-bottom: 20px;
`;

const QuickBooks = () => {
  const dispatch = useDispatch();
  const [isChecked, toggleIsChecked] = useState(false);

  const handleIntegrationClick = () => {
    dispatch(
      showModal(MANUAL_INTEGRATION_FORM, {
        platformName: PLATFORM.QUICKBOOKS,
      })
    );
  };
  return (
    <>
      <Title>Connect {PLATFORM_DISPLAY_NAME.QUICKBOOKS} Account</Title>
      <Description>Please follow below steps to add access to your account</Description>
      <InstructionTitle>Step 1</InstructionTitle>
      <Description>Sign in to your QuickBooks Online Account</Description>
      <InstructionTitle>Step 2</InstructionTitle>
      <Description>
        Select
        <b> Settings </b>
        {'>'}
        <b> Manage Users</b>
      </Description>
      <InstructionTitle>Step 3</InstructionTitle>
      <Description>
        Select <b>Add user</b> then select <b>Reports only </b>
        and set email as <b>{DD_EMAIL}</b>
      </Description>
      <InstructionTitle>Step 4</InstructionTitle>
      <Description>
        Click <b>Save</b> and we will receive a confirmation email.
      </Description>
      <GridColumn lg={12} md={12}>
        <CheckBox onClick={() => toggleIsChecked(!isChecked)} />
        <CheckboxText>Confirm I have connected with my {PLATFORM_DISPLAY_NAME.QUICKBOOKS} account</CheckboxText>
      </GridColumn>
      <GridColumn lg={6} md={6}>
        <Button onClick={handleIntegrationClick} disabled={!isChecked} primary full>
          Next
        </Button>
      </GridColumn>
      <GridColumn lg={6} md={6}>
        <Button onClick={() => dispatch(hideModal())} tertiary full>
          Cancel
        </Button>
      </GridColumn>
    </>
  );
};

export default QuickBooks;
