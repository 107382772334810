import featureToggle from '../../featureToggle';
import { STATUS_CODE } from '@constants/application';
import { BASE_APPLICATION_PATH } from '@/basePath';

const PATH = {
  SALES_ACCOUNTS: `${BASE_APPLICATION_PATH}/sales`,
  PRELIMINARY_OFFER: `${BASE_APPLICATION_PATH}/offers`,
  MARKETING_ACCOUNTS: `${BASE_APPLICATION_PATH}/marketing`,
  COMPANY: `${BASE_APPLICATION_PATH}/company`,
  COMPANY_BUSINESS: `${BASE_APPLICATION_PATH}/company/business-details`,
  COMPANY_KEY_PERSONS: `${BASE_APPLICATION_PATH}/company/key-persons`,
  COMPANY_KEY_ENTITY: `${BASE_APPLICATION_PATH}/company/key-entity`,
  COMPANY_KEY_OWNER: `${BASE_APPLICATION_PATH}/company/key-owner`,
  BANK_ACCOUNTS: `${BASE_APPLICATION_PATH}/bank`,
  ACCOUNTING: `${BASE_APPLICATION_PATH}/accounting`,
  TERM_SHEET: `${BASE_APPLICATION_PATH}/term-sheet`,
  ADDITIONAL_INFO: `${BASE_APPLICATION_PATH}/additional-information`,
  AGREEMENT: `${BASE_APPLICATION_PATH}/agreement`,
  AGREEMENT_REVENUE_CHANNELS: `${BASE_APPLICATION_PATH}/agreement/revenue-channels`,
};

export const PATH_OPTIONS = [
  {
    value: PATH.SALES_ACCOUNTS,
    label: 'Sales Accounts',
  },
];

export const areAllPagesAllowed = (statusCode) => {
  return statusCode && [STATUS_CODE.RECEIVED_PRE_OFFER].indexOf(statusCode) === -1;
};

export const getPathOptions = (allowAllPages, isSignUpViaShopify) => {
  if (isSignUpViaShopify) {
    return PATH_OPTIONS.slice(0, 1);
  } else if (allowAllPages) {
    const result = PATH_OPTIONS.map((option) => ({
      ...option,
      alwaysAllowed: true,
    }));
    return result;
  } else {
    return PATH_OPTIONS.slice(0, 2);
  }
};

export const PATH_LIST = PATH_OPTIONS.map((option) => option.value);

export default PATH;
