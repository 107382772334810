import React, { useEffect, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import BackButton from '../../../../../components/BackButton';
import BreadCrumb from '../../../../../components/BreadCrumb';
import Button from '../../../../../components/Button';
import FormTitle from '../../../../../components/FormTitle';
import HR from '../../../../../components/HR';
import COLORS from '../../../../../styles/colors';
import Logger from '../../../../../utils/logger';
import ControlledSelect from '../../../../ReactHookForm/ControlledSelect';
import PATH from '../../../path';
import { FormRow, GridColumn } from '@components/Grid';
import { MULTI_FACTOR_SETUP_MODAL } from '@modules/Modal/constants';
import { showModal, hideModal } from '@redux/modules/UI/actions';
import { media } from '@styles/breakpoints';

const Header = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
  margin-bottom: 8px;
`;

const Wrapper = styled.div`
  margin: 20px 0;
`;

const Title = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: 600;
  margin-bottom: 8px;

  ${(props) =>
    props.disabled &&
    css`
      color: ${COLORS.grayTwo};
      cursor: not-allowed;
    `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 20px 0;
  justify-content: start;
`;

const MultiFactorSetup = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user.users);
  const [isLoading, toggleIsLoading] = useState(false);

  const userOptions = users.map((userInfo) => {
    return {
      value: userInfo.email,
      label: `${userInfo.email} (${userInfo.first_name} ${userInfo.last_name}) ${userInfo.role_name}`,
    };
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const onSubmit = async (formData) => {
    toggleIsLoading(true);
    try {
      dispatch(
        showModal(MULTI_FACTOR_SETUP_MODAL, {
          userEmail: formData.userEmail,
          title: 'Verify 2FA code',
        })
      );
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  return (
    <>
      <BreadCrumb
        items={[
          { value: PATH.SETTINGS_MULTI_FACTOR_OVERVIEW, label: '2FA setup' },
          { label: 'Account-wide Two Factor Authentication (2FA)' },
        ]}
      />
      <Header>Assign an email address to receive 2FA code</Header>
      <HR margin={10} color="grayTwo" />
      <Wrapper>
        <Title>Assign a specific email address to receive 2FA code, and require all users to use this code when creating a transfer</Title>
        <GridColumn lg={6} md={6} disableGutter>
          <FormRow>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormTitle scale={5} required>
                Email address from current users
              </FormTitle>
              <ControlledSelect
                name="userEmail"
                control={control}
                rules={{
                  required: true,
                }}
                options={userOptions}
                disabled={isLoading}
              />
              <ButtonWrapper>
                <Button disable={isLoading} type="submit">
                  Send 2FA code
                </Button>
              </ButtonWrapper>
            </form>
          </FormRow>
        </GridColumn>
      </Wrapper>
    </>
  );
};

export default MultiFactorSetup;
