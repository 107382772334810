import { API_INTEGRATION_PORT, API_APPLICATION_PORT, REACT_APP_API_CAL_ENGINE } from '@api/constants';
import { getCompanyId } from '@api/cookies';
import { getRequest, postRequest, putRequest, deleteRequest } from '@api/requests';

// TODO: separate into multiple files
const integrationEndpoint = `${API_INTEGRATION_PORT}/integration/v1`;
export const applicationEndpoint = `${API_APPLICATION_PORT}/application/v1`;

export const integrateAccount = async (value, payload = {}) => {
  return await getRequest(`${integrationEndpoint}/${value}/register`, payload);
};

export const postIntegrateAccount = async (value, payload = {}) => {
  return await postRequest(`${integrationEndpoint}/${value}/register`, payload);
};

export const salesOthersPlatformsIntegration = async (value) => {
  return await postRequest(`${integrationEndpoint}/sales-others/register`, {
    platforms: [value],
  });
};

export const integrationSuccess = async (value, payload) =>
  await postRequest(`${integrationEndpoint}/${value}/register/success`, payload);

// TODO: restructure due to it covers both sales and accounting platform
export const getIntegratedAccounts = async () => {
  return await getRequest(`${integrationEndpoint}/all`);
};

export const deleteIntegratedAccount = async (payload) => {
  return await putRequest(`${integrationEndpoint}/${payload.platformName}/${payload.integrationId}/status`, {
    status: -1,
    ...(payload.accountId && { account_id: payload.accountId }),
  });
};

export const saveBank = async (payload) => {
  return await postRequest(`${applicationEndpoint}/bank`, payload);
};

export const getAllBankAccounts = async () => {
  const companyId = getCompanyId();
  return await getRequest(`${applicationEndpoint}/company/${companyId}/bank`);
};

export const createBankAccount = async (payload) => {
  const companyId = getCompanyId();
  return await postRequest(`${applicationEndpoint}/company/${companyId}/bank`, payload);
};

export const updateBankAccountById = async ({ bank_id, ...restPayload }) => {
  const companyId = getCompanyId();
  return await putRequest(`${applicationEndpoint}/company/${companyId}/bank/${bank_id}`, restPayload);
};

export const deleteBankAccountById = async (bankId) => {
  const companyId = getCompanyId();
  return await deleteRequest(`${applicationEndpoint}/company/${companyId}/bank/${bankId}`);
};

export const getOffer = async (applicationId, payload) => {
  return await getRequest(`${applicationEndpoint}/application/${applicationId}/offer`, payload);
};

export const getApplication = async () => {
  const companyId = getCompanyId();
  return await getRequest(`${applicationEndpoint}/company/${companyId}/application`);
};

export const applyApplication = async (payload) => {
  const companyId = getCompanyId();
  return await postRequest(`${applicationEndpoint}/company/${companyId}/application`, payload);
};

export const updateApplication = async (applicationId, payload) => {
  const companyId = getCompanyId();
  return await putRequest(`${applicationEndpoint}/company/${companyId}/application/${applicationId}`, payload);
};

export const submitApplication = async (applicationId, payload) => {
  const companyId = getCompanyId();
  return await putRequest(`${applicationEndpoint}/company/${companyId}/application/${applicationId}/submit`, payload);
};

export const startInvestmentReview = async (companyId, applicationId) => {
  return await postRequest(
    `${applicationEndpoint}/company/${companyId}/application/${applicationId}/investment-review`,
  );
};

export const createOffers = async (applicationId, offers) => {
  const companyId = getCompanyId();
  return await postRequest(`${applicationEndpoint}/company/${companyId}/application/${applicationId}/offers`, offers);
};

export const getApprovedOffers = async (applicationId) => {
  const companyId = getCompanyId();
  return await getRequest(`${applicationEndpoint}/company/${companyId}/application/${applicationId}/approvedOffers`);
};

export const getAllOffers = async (applicationId) => {
  const companyId = getCompanyId();
  return await getRequest(`${applicationEndpoint}/company/${companyId}/application/${applicationId}/offers`);
};

export const approveFundingProposalsByManager = async (applicationId) => {
  const companyId = getCompanyId();
  return await postRequest(`${applicationEndpoint}/company/${companyId}/application/${applicationId}/offers/approve`);
};

export const returnFundingProposalsByManager = async (applicationId) => {
  const companyId = getCompanyId();
  return await postRequest(`${applicationEndpoint}/company/${companyId}/application/${applicationId}/offers/return`);
};

export const rejectFundingProposalsByManager = async (applicationId) => {
  const companyId = getCompanyId();
  return await postRequest(`${applicationEndpoint}/company/${companyId}/application/${applicationId}/offers/reject`);
};

export const approveFundingProposalByCreditTeam = async (applicationId) => {
  const companyId = getCompanyId();
  return await postRequest(
    `${applicationEndpoint}/company/${companyId}/application/${applicationId}/offer/credit-form/approve`,
  );
};

export const returnFundingProposalsByCreditTeam = async (applicationId) => {
  const companyId = getCompanyId();
  return await postRequest(
    `${applicationEndpoint}/company/${companyId}/application/${applicationId}/offers/credit-form/return`,
  );
};

export const rejectFundingProposalsByCreditTeam = async (applicationId) => {
  const companyId = getCompanyId();
  return await postRequest(
    `${applicationEndpoint}/company/${companyId}/application/${applicationId}/offers/credit-form/reject`,
  );
};

export const acceptOfferById = async (applicationId, offerId) => {
  const companyId = getCompanyId();
  return await postRequest(
    `${applicationEndpoint}/company/${companyId}/application/${applicationId}/offer/${offerId}/accept`,
  );
};

export const disburseFunding = async (applicationId, payload) => {
  const companyId = getCompanyId();
  return await putRequest(
    `${applicationEndpoint}/company/${companyId}/application/${applicationId}/fund-disbursement`,
    payload,
  );
};

export const signAgreement = async (applicationId) => {
  const companyId = getCompanyId();
  return await putRequest(`${applicationEndpoint}/company/${companyId}/application/${applicationId}/sign-agreement`);
};
