import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import CheckBox, { CheckboxText } from '../../../../components/Checkbox';
import { Description } from '../../../Application/components';
import { MANUAL_INTEGRATION_FORM } from '../../../Modal/constants';
import { GridColumn } from '@components/Grid';
import { DD_EMAIL } from '@constants/email';
import { PLATFORM, PLATFORM_DISPLAY_NAME } from '@constants/platform';
import { showModal, hideModal } from '@redux/modules/UI/actions';

const Title = styled.div`
  ${(props) => props.theme.text.two};
  margin: 10px 0 20px 0;
`;

const Link = styled.a`
  ${(props) => props.theme.text.link};
  text-decoration: underline;
  font-weight: bold;
`;
const InstructionTitle = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: bold;
  margin: 20px 0;
`;

const InstructionsImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

const PlayStore = () => {
  const dispatch = useDispatch();
  const [isChecked, toggleIsChecked] = useState(false);

  const handleIntegrationClick = () => {
    dispatch(
      showModal(MANUAL_INTEGRATION_FORM, {
        platformName: PLATFORM.GOOGLE_PLAY,
      })
    );
  };

  return (
    <>
      <Title>Connect {PLATFORM_DISPLAY_NAME.GOOGLE_PLAY} Account</Title>
      <Description>Please follow below steps to add access to your account</Description>
      <InstructionTitle>Step 1</InstructionTitle>
      <Description>
        Navigate to{' '}
        <Link href="https://play.google.com/apps/publish/" target="_blank">
          Google Play developer console
        </Link>
      </Description>
      <Description>
        then go to <b>Settings </b>
        {'>'}
        <b> Users & permissions </b>
      </Description>
      <Description>
        Please use information given below to fill in the new user form
        <ul>
          <li>Email: {DD_EMAIL}</li>
        </ul>{' '}
        <b>Click Next</b>
      </Description>
      <InstructionsImage src="https://asset.choco-up.com/tutorial/google-play/Google-Play-1.png" alt="Connect Play Store Account" />
      <InstructionTitle>Step 2</InstructionTitle>
      <Description>
        Please grant us with the permissions below:
        <ul>
          <li>View financial data</li>
          <li>View app information (read-only)</li>
        </ul>{' '}
        <b>Click Send Invitation</b>
      </Description>
      <InstructionsImage src="https://asset.choco-up.com/tutorial/google-play/Google-Play-2.png" alt="Connect Play Store Account" />
      <GridColumn lg={12} md={12}>
        <CheckBox onClick={() => toggleIsChecked(!isChecked)} />
        <CheckboxText>Confirm I have connected with my {PLATFORM_DISPLAY_NAME.GOOGLE_PLAY} account</CheckboxText>
      </GridColumn>
      <GridColumn lg={6} md={6}>
        <Button onClick={handleIntegrationClick} disabled={!isChecked} primary full>
          Next
        </Button>
      </GridColumn>
      <GridColumn lg={6} md={6}>
        <Button onClick={() => dispatch(hideModal())} tertiary full>
          Cancel
        </Button>
      </GridColumn>
    </>
  );
};

export default PlayStore;
