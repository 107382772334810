import React from 'react';

import { Switch, NavLink, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import Header from '../../../components/Header';
import featureToggle from '../../../featureToggle';
import { Wrapper } from '../components';
import PATH from '../path';
import Account from './Account';
import BusinessDetailForm from './Company/BusinessDetailForm';
import BusinessEntityForm from './Company/BusinessEntityForm';
import BusinessOwnerForm from './Company/BusinessOwnerForm';
import KeyStakeholderContainer from './Company/KeyStakeholderContainer';
import CompanyOverview from './Company/Overview';
import FileManagement from './FileManagement';
import FinanceAccountsForm from './FinanceAccountsForm';
import ChangePasswordForm from './ProfileInformation/ChangePassword/ChangePasswordForm';
import ProfileInformation from './ProfileInformation/ProfileInformation';
import UserManagement from './UserManagement';
import UserInviteForm from './UserManagement/UserInvite/UserInviteForm';

const StyledHeader = styled(Header)`
  padding: 10px 16px;
`;

const TabWrapper = styled.div`
  width: 200px;
  display: inline-block;
  vertical-align: top;
  margin-right: 16px;
`;

const Tab = styled(NavLink)`
  display: block;
  padding: 10px 16px;
  font-size: 15px;
  line-height: 25px;
  box-sizing: border-box;
  font-weight: 600;

  &.active {
    background-color: rgba(58, 12, 163, 0.1);
    color: ${(props) => props.theme.colors.black};
  }
`;

const Content = styled.div`
  box-sizing: border-box;
  width: calc(100% - 200px - 16px);
  display: inline-block;
`;

const SettingsContainer = () => {
  return (
    <>
      <StyledHeader scale={2}>Settings</StyledHeader>
      <div>
        <TabWrapper>
          <Tab to={PATH.SETTINGS_ACCOUNT_OVERVIEW}>Account Information</Tab>
          <Tab to={PATH.SETTINGS_PROFILE_INFORMATION}>Profile Information</Tab>
          <Tab to={PATH.SETTINGS_COMPANY_OVERVIEW}>Company Information</Tab>
          <Tab to={PATH.SETTINGS_FINANCE}>Bank Accounts</Tab>
          <Tab to={PATH.SETTINGS_USER_MANAGEMENT}>User Management</Tab>
          {featureToggle.FILE_MANAGEMENT && <Tab to={PATH.SETTINGS_UPLOADS}>Uploads</Tab>}
        </TabWrapper>
        <Content>
          <Wrapper>
            <Switch>
              <Route exact path={PATH.SETTINGS_ACCOUNT_OVERVIEW} component={Account} />
              <Route exact path={PATH.SETTINGS_CHANGE_PASSWORD} component={ChangePasswordForm} />
              <Route path={PATH.SETTINGS_USER_MANAGEMENT} component={UserManagement} />
              <Route exact path={PATH.SETTINGS_PROFILE_INFORMATION} component={ProfileInformation} />
              <Route path={PATH.SETTINGS_FINANCE} component={FinanceAccountsForm} />
              <Route exact path={PATH.SETTINGS_COMPANY_OVERVIEW} component={CompanyOverview} />
              <Route exact path={PATH.SETTINGS_COMPANY_BUSINESS} component={BusinessDetailForm} />
              <Route exact path={PATH.SETTINGS_COMPANY_KEY_PERSONS} component={BusinessOwnerForm} />
              <Route exact path={`${PATH.SETTINGS_COMPANY_KEY_PERSONS}/:id`} component={BusinessOwnerForm} />
              <Route exact path={PATH.SETTINGS_COMPANY_KEY_ENTITY} component={BusinessEntityForm} />
              <Route exact path={`${PATH.SETTINGS_COMPANY_KEY_ENTITY}/:id`} component={BusinessEntityForm} />
              <Route exact path={PATH.SETTINGS_COMPANY_KEY_OWNER} component={KeyStakeholderContainer} />
              <Route exact path={PATH.SETTINGS_UPLOADS} component={FileManagement} />
              <Redirect to={PATH.SETTINGS_ACCOUNT_OVERVIEW} />
            </Switch>
          </Wrapper>
        </Content>
      </div>
    </>
  );
};

export default SettingsContainer;
