import React from 'react';

import styled from 'styled-components';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import { formatPrice } from '@utils/priceHelpers';

const Header = styled.div`
  ${(props) => props.theme.text.three}
  font-weight: bold;
`;

const Body = styled.div`
  margin: 15px 0 30px;
`;

const SuccessModal = ({ hideModal, refund = {} }) => {
  return (
    <Modal>
      <Header>
        {refund.amount && `${formatPrice(refund.currency, refund.amount)} `}
        refund initiated due to customer request
      </Header>
      <Body>
        An Acquirer Reference Number (ARN) will be available in 1–3 business days. It may take 5–10 business days for funds to settle.
      </Body>
      <Button onClick={hideModal} full>
        Close
      </Button>
    </Modal>
  );
};

export default SuccessModal;
