import { ReactNode } from 'react';

import styled from 'styled-components';

import styles from './Layout.module.scss';

const HeaderWrapper = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  bottom: auto;
  z-index: 10000;
  max-width: 1300px;
  min-width: 100%;
  padding: 10px 0;
  clear: none;
  background-color: #fff;
  opacity: 1;
  outline-offset: 0;
  outline: #333 3px;
  font-family: Poppins, sans-serif;
  background-clip: border-box;
  mix-blend-mode: normal;

  @media screen and (max-width: 991px) {
    display: flex;
    height: 85px;
    padding-right: 3%;
    padding-left: 3%;
    border-bottom: 1px solid rgba(68, 85, 103, 0.11);
  }
`;

const FooterWrapper = styled.div`
  display: block;
  width: 100%;
  padding-top: 0;
  flex-direction: row;
  align-items: center;
  background-color: #4e4e5c;
`;

const Layout = ({ children }: { children: ReactNode }) => {
  const headerLogo = 'https://uploads-ssl.webflow.com/62abd75c30087cf5eaaabbf2/62abd75c30087c3e63aabd23_Chocoup-logo-new.svg';
  const footerLogo = 'https://uploads-ssl.webflow.com/62abd75c30087cf5eaaabbf2/62abd75c30087c500aaabd27_Frame%205.svg';

  return (
    <>
      <HeaderWrapper>
        <div className={styles['navigation']}>
          <a href="/home">
            <img src={headerLogo} alt="logo" width="200" height="50" />
          </a>
          <a href="/login" className={styles['nav-link']}>
            Login
          </a>
        </div>
      </HeaderWrapper>
      <div>{children}</div>
      <FooterWrapper>
        <div className={styles['footer']}>
          <div className={styles['footer__top']}>
            <a href="/home">
              <img src={footerLogo} alt="choco up logo" width="140" height="50" />
            </a>
          </div>
          <div className={styles['footer__bottom']}>
            <p className={styles['footer__bottom--legal-info']}>© Copyright 2022 Choco Up. All rights reserved.</p>
            <div className={styles['footer__bottom--link']}>
              <a href="/privacy-policy" className={styles['footer--link']}>
                Privacy
              </a>
              <a href="/terms-conditions" className={styles['footer--link']}>
                Terms and Conditions
              </a>
            </div>
          </div>
        </div>
      </FooterWrapper>
    </>
  );
};

export default Layout;
