import React, { useState, useEffect } from 'react';

import { parse } from 'query-string';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

import Logger from '../../../utils/logger';
import Banner from '../components/Banner';
import PATH from '../path';
import BusinessForm from './BusinessForm';
import InviteUserForm from './InviteUserForm';
import UserForm from './UserForm';
import { register, emailLogin } from '@api/modules/auth';
import { BBMSL_SIGN_UP, XERO_SIGN_UP, SHOPIFY_SIGN_UP, SHOPLAZZA_SIGN_UP } from '@constants/campaign';
import { PLATFORM } from '@constants/platform';
import { useQuery } from '@hooks/routing';
import { fetchUser } from '@redux/modules/user/actions';
import { convertToBooleanNumeral } from '@utils/dataTypes';

const Registration = () => {
  const history = useHistory();
  const { parsed, search } = useQuery();
  const [formData, setFormData] = useState({});
  const [registerError, setRegisterError] = useState(false);
  const isBBMSLSignUp = parsed.utm_campaign === BBMSL_SIGN_UP;
  const isXEROSignUp = parsed.utm_campaign === XERO_SIGN_UP;
  const isShopifySignUp = parsed.utm_campaign === SHOPIFY_SIGN_UP;
  const isShoplazzaSignUp = parsed.utm_campaign === SHOPLAZZA_SIGN_UP;
  const isUserFormAllowed = formData.businessForm || isBBMSLSignUp;
  const [isLoading, toggleIsLoading] = useState(false);

  const submitBusinessForm = (data) => {
    const nextFormData = {
      ...formData,
      businessForm: data,
    };
    setFormData(nextFormData);
    history.push(`${PATH.REGISTER_USER_INFO}${search}`);
  };

  const onUserFormClick = (data) => {
    const nextFormData = {
      ...formData,
      userForm: data,
    };
    setFormData(nextFormData);
  };

  const submitUserForm = async (userForm) => {
    toggleIsLoading(true);
    try {
      const { email, password } = userForm;
      const nextFormData = {
        ...formData,
        userForm,
      };
      setFormData(nextFormData);
      const payload = {
        // for integration campaign
        agent_id: nextFormData.agentId,
        referrer: nextFormData.referrer,
        // for shopify
        shopify_store_name: nextFormData.userForm.shopify_store_name,
        // for public app registeration
        platform: nextFormData.userForm.platform,
        store_id: nextFormData.userForm.store_id,

        // businessForm
        company_name: nextFormData.businessForm.companyName,
        business_website: nextFormData.businessForm.websiteAddress,
        monthly_revenue_range: nextFormData.businessForm.monthlyRevenue,
        business_type: nextFormData.businessForm.businessType,
        country_origin: nextFormData.businessForm.country,
        // userForm
        email: nextFormData.userForm.email.toLowerCase(),
        password: nextFormData.userForm.password,
        first_name: nextFormData.userForm.firstName,
        last_name: nextFormData.userForm.lastName,
        mobile_phone: nextFormData.userForm.mobileNumber,
        allow_update: convertToBooleanNumeral(nextFormData.userForm.optInEmails),
        lead_source_type: nextFormData.userForm.leadSourceType,
        lead_source_detail: nextFormData.userForm.leadSourceDetail,
        admin_code: parsed.admin,
        marketing_code: parsed.marketing,
        partner_code: parsed.partner,
      };
      const { error: signUpApiError, statusCode } = await register(payload);
      if (statusCode === 201) {
        await emailLogin({
          username: email,
          password,
        });
        await fetchUser();
        history.push(PATH.REGISTRATION_SUCCESS);
      } else {
        throw new Error(signUpApiError);
      }
    } catch (err) {
      setRegisterError(err?.message || 'Something went wrong');
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  // handle BBMSL
  useEffect(() => {
    try {
      if (isBBMSLSignUp && parsed.info) {
        const decodedString = decodeURIComponent(escape(window.atob(parsed.info)));
        const { name, agentId } = parse(decodedString);
        setFormData({
          ...formData,
          businessForm: {
            companyName: name,
            country: 'HK',
          },
          agentId,
          referrer: parsed.utm_campaign.toLowerCase(),
        });
      }
    } catch (err) {
      history.replace(PATH.REGISTER_BUSINESS_INFO);
      Logger.error(err);
    }
  }, []);

  // handle xero
  useEffect(() => {
    try {
      if (isXEROSignUp && parsed.info) {
        const decodedString = decodeURIComponent(escape(window.atob(parsed.info)));

        // handle stringified object. Don't use parse provided by lib query-string
        const { email, first_name, last_name } = JSON.parse(decodedString);

        setFormData({
          ...formData,
          userForm: {
            email,
            firstName: first_name,
            lastName: last_name,
          },
          referrer: parsed.utm_campaign.toLowerCase(),
        });
      }
    } catch (err) {
      Logger.error(err);
    }
  }, []);

  // handle shopify
  useEffect(() => {
    try {
      if (isShopifySignUp && parsed.info) {
        const decodedString = decodeURIComponent(escape(window.atob(parsed.info)));

        // handle stringified object. Don't use parse provided by lib query-string
        const { email, full_name, shopify_store_name } = JSON.parse(decodedString);

        setFormData({
          ...formData,
          userForm: {
            email,
            firstName: full_name,
            shopify_store_name,
          },
          referrer: parsed.utm_campaign.toLowerCase(),
        });
      }
    } catch (err) {
      Logger.error(err);
    }
  }, []);

  // handle shoplazza
  useEffect(() => {
    try {
      if (isShoplazzaSignUp && parsed.info) {
        const decodedString = decodeURIComponent(escape(window.atob(parsed.info)));

        // handle stringified object. Don't use parse provided by lib query-string
        const { email, full_name, store_id } = JSON.parse(decodedString);

        setFormData({
          ...formData,
          userForm: {
            email,
            firstName: full_name,
            platform: PLATFORM.SHOPLAZZA,
            store_id,
          },
          referrer: parsed.utm_campaign.toLowerCase(),
        });
      }
    } catch (err) {
      Logger.error(err);
    }
  }, []);

  return (
    <>
      <Banner />
      <Switch>
        <Route path={PATH.INVITE_NEW_USER} component={InviteUserForm} />
        <Route
          path={PATH.REGISTER_BUSINESS_INFO}
          component={() => <BusinessForm onNext={submitBusinessForm} formData={formData} isLoading={isLoading} />}
        />
        {isUserFormAllowed && (
          <Route
            path={PATH.REGISTER_USER_INFO}
            component={() => (
              <UserForm
                onNext={submitUserForm}
                formData={formData}
                registerError={registerError}
                bypassBusinessForm={isBBMSLSignUp}
                onUserFormClick={onUserFormClick}
                isLoading={isLoading}
              />
            )}
          />
        )}
        <Redirect to={`${PATH.REGISTER_BUSINESS_INFO}${search}`} />
      </Switch>
    </>
  );
};

export default Registration;
