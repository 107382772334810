import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import Button from '../../../../../../components/Button';
import CircularProgressBar from '../../../../../../components/CircularProgressBar';
import FormTitle from '../../../../../../components/FormTitle';
import SingleDatePicker from '../../../../../../components/SingleDatePicker';
import COLORS from '../../../../../../styles/colors';
import RHFMoneyInput from '../../../../../ReactHookForm/RHFMoneyInput';
import { Card } from '../../../../components';
import { globalAccountsOptions } from '../../../constants';
import { generateSourceCurrencyOptions, isSourceAmountValid } from '../../../helpers';
import { Footer } from '../../../Transfer/CreateTransferPage/components';
import { calculateToAmount, formatFXRatio } from '../../../Transfer/CreateTransferPage/helpers';
import { FORM_NAME } from './constant';
import { CURRENCY_COUNTRY_FLAG_MAP } from '@/constants';
import { useCountdown } from '@hooks/UI';
import { fetchForeignExchange } from '@redux/modules/wallet/actions';
import { media } from '@styles/breakpoints';
import { convertToFullDateAndTime } from '@utils/dateHelpers';
import { isRealObject, isRealNumber, convertArrayToObject } from '@utils/dataTypes';
import { formatPrice, getNumberFromFormatted, getCurrencyDecimalPosition } from '@utils/priceHelpers';
import { useWalletAccount, useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';

const Title = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 24px;
`;

const FieldWrapper = styled.div`
  margin: 5px 0;
`;

const FormWrapper = styled(Card)`
  width: 66%;
  box-sizing: border-box;
  ${media.desktop`
    width: 100%;
  `}
`;

const SourceCurrencyOption = styled.div`
  display: flex;
  gap: 14px;
  .currency_code {
    font-weight: 600;
  }
`;

export const FormErrorMessage = styled.div`
  margin-top: 4px;
  color: ${(props) => props.theme.colors.red};
  ${(props) => props.theme.text.micro};
`;

const Description = styled.div`
  margin-top: 4px;
  color: ${(props) => props.theme.colors.grayThree};
  ${(props) => props.theme.text.micro};
`;

const AvailableAmount = styled.div`
  margin-top: 5px;
  text-align: right;
  color: ${(props) => props.theme.colors.grayThree};
  ${(props) => props.theme.text.micro};
`;

const TransferFlowInfoPadding = css`
  padding: 0px 0 20px 16px;
`;

const TransferFlowInfoWrapper = styled.div`
  margin-left: 20px;
  ${TransferFlowInfoPadding};
  border-left: 1px dashed ${(props) => props.theme.colors.grayTwo};

  ${FieldWrapper} {
    position: relative;
    .circle {
      margin: 5px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.white};
      position: absolute;
      left: -31px;
      top: -5px;
      div {
        position: absolute;
        top: 25%;
        right: 25%;
        width: 9px;
        height: 9px;
        background-color: ${(props) => props.theme.colors.grayThree};
        border-radius: 50%;
      }
    }
  }
`;

const ConversionForm = ({ formProps, setFormName }) => {
  const walletAccount = useWalletAccount();
  const [showSourceToPaymentFX, toggleShowSourceToPaymentFX] = useState(false);
  const {
    control,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
    handleSubmit,
    formState: { errors },
  } = formProps;
  const watchOfSourceCurrency = watch('sourceCurrency');
  const watchOfSourceAmount = watch('sourceAmount');
  const watchOfConvertCurrency = watch('convertCurrency');
  const watchOfConvertAmount = watch('convertAmount');
  const currencyBalances = useSelector((state) => state.wallet.currencyBalances);
  const foreignExchange = useSelector((state) => state.wallet.foreignExchange) || {};
  const currencyBalanceMap = convertArrayToObject(currencyBalances, 'currency');
  const [amount, setAmount] = useState();
  const [amountOrigin, setAmountOrigin] = useState('sourceAmount');
  const [currencyOptions, setCurrencyOptions] = useState();
  const isAdminViewingAnotherCompany = useCheckIsAdminViewingAnotherCompany();

  const sourceToPaymentFX = foreignExchange[`${watchOfSourceCurrency}to${watchOfConvertCurrency}`];

  const { remainingTime } = useCountdown({
    startTime: sourceToPaymentFX?.valid_from,
    endTime: sourceToPaymentFX?.valid_to,
  });

  const selectedSourceBalance = currencyBalanceMap[watchOfSourceCurrency];
  const selectedConvertBalance = currencyBalanceMap[watchOfConvertCurrency];

  const getSourceCurrencyOptionLabel = ({ value, label }, config) => {
    const balance = currencyBalanceMap[value];
    const logoUrl = CURRENCY_COUNTRY_FLAG_MAP[value]?.logoUrl;

    return config.context === 'menu' ? (
      <SourceCurrencyOption>
        {logoUrl && <img src={logoUrl} alt={value} />}
        <div>
          <div className="currency_code">{value}</div>
          <div>{formatPrice(value, balance?.total_amount || 0)}</div>
        </div>
      </SourceCurrencyOption>
    ) : (
      label
    );
  };

  useEffect(() => {
    setCurrencyOptions(generateSourceCurrencyOptions(currencyBalances, globalAccountsOptions));
  }, [JSON.stringify(currencyBalances), JSON.stringify(globalAccountsOptions)]);

  useEffect(() => {
    if (watchOfSourceCurrency && watchOfConvertCurrency && watchOfSourceCurrency !== watchOfConvertCurrency) {
      fetchForeignExchange(walletAccount?.account_id, {
        fromCurrency: watchOfSourceCurrency,
        toCurrency: watchOfConvertCurrency,
        amount: 10000,
      });
      fetchForeignExchange(walletAccount?.account_id, {
        fromCurrency: watchOfConvertCurrency,
        toCurrency: watchOfSourceCurrency,
        amount: 10000,
      });
    }
  }, [watchOfSourceCurrency, watchOfConvertCurrency]);

  useEffect(() => {
    // sets error if conditions below are met in conversion form
    const setAmountError = () => {
      const { convertAmount, convertCurrency, sourceAmount, sourceCurrency } = getValues();

      if (sourceAmount > selectedSourceBalance?.total_amount) {
        setError('sourceAmount', {
          type: 'manual',
          message: 'Amount exceeds your available balance, please change the funding currency or top up',
        });
      } else if (convertCurrency === sourceCurrency) {
        setError('sourceAmount', {
          type: 'manual',
          message: 'Currencies cannot be the same value',
        });
      } else {
        clearErrors('sourceAmount');
      }
    };

    setAmountError();
  }, [watchOfConvertAmount, watchOfSourceAmount, watchOfSourceCurrency]);

  useEffect(() => {
    if (amount && isRealObject(foreignExchange)) {
      const { fromAmount, fromCurrency, toCurrency, toAmountFieldKey } =
        amountOrigin === 'sourceAmount'
          ? {
              fromAmount: amount,
              fromCurrency: watchOfSourceCurrency,
              toCurrency: watchOfConvertCurrency,
              toAmountFieldKey: 'convertAmount',
            }
          : {
              fromAmount: amount,
              fromCurrency: watchOfConvertCurrency,
              toCurrency: watchOfSourceCurrency,
              toAmountFieldKey: 'sourceAmount',
            };
      const fee = 0;

      const result = calculateToAmount({
        fromAmount,
        fromCurrency,
        toCurrency,
        amountOrigin,
        foreignExchange,
        fee,
      });

      const nextFromAmount = fromAmount;
      setValue(amountOrigin, nextFromAmount);

      const toCurrencyPrecision = getCurrencyDecimalPosition(toCurrency);
      if (isRealNumber(result.toAmount)) {
        const nextToAmount = result.toAmount.toFixed(toCurrencyPrecision.toFixedNumber);
        setValue(toAmountFieldKey, nextToAmount);
        return;
      }

      setValue(toAmountFieldKey, '');
    }
  }, [
    amount,
    amountOrigin,
    watchOfConvertAmount,
    watchOfConvertCurrency,
    watchOfSourceCurrency,
    JSON.stringify(foreignExchange),
    JSON.stringify(sourceToPaymentFX),
  ]);

  useEffect(() => {
    if (remainingTime === 0 && watchOfSourceCurrency && watchOfConvertCurrency && watchOfSourceCurrency !== watchOfConvertCurrency) {
      fetchForeignExchange(walletAccount.account_id, {
        fromCurrency: watchOfSourceCurrency,
        toCurrency: watchOfConvertCurrency,
        amount: 10000,
      });
      fetchForeignExchange(walletAccount.account_id, {
        fromCurrency: watchOfConvertCurrency,
        toCurrency: watchOfSourceCurrency,
        amount: 10000,
      });
    }
  }, [remainingTime]);

  useEffect(() => {
    if (isRealObject(sourceToPaymentFX) && sourceToPaymentFX.quote_id && watchOfSourceCurrency !== watchOfConvertCurrency) {
      toggleShowSourceToPaymentFX(true);
    } else {
      toggleShowSourceToPaymentFX(false);
    }
  }, [JSON.stringify(sourceToPaymentFX), watchOfSourceCurrency, watchOfConvertCurrency]);

  const onSubmit = (formData) => {
    setFormName(FORM_NAME.REVIEW);
  };

  return (
    <>
      <Title>New Conversion</Title>
      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FieldWrapper>
            <FormTitle scale={5} required>
              From
            </FormTitle>
            <RHFMoneyInput
              control={control}
              errors={errors}
              prefix="source"
              selectProps={{
                placeholder: 'Select Currency',
                options: currencyOptions,
                formatOptionLabel: getSourceCurrencyOptionLabel,
              }}
              inputProps={{
                alwaysPositiveNumber: true,
                rules: {
                  required: true,
                  min: 10,
                  max: 1000,
                },
                onChange: (event) => {
                  setAmount(getNumberFromFormatted(event.target.value));
                  setAmountOrigin('sourceAmount');
                },
                hasError: watchOfSourceAmount && !isSourceAmountValid(watchOfSourceAmount, selectedSourceBalance?.total_amount),
              }}
              full
            />
          </FieldWrapper>
          <TransferFlowInfoWrapper>
            <FieldWrapper />
            {errors.sourceAmount?.message && <FormErrorMessage>{errors.sourceAmount?.message}</FormErrorMessage>}
            {selectedSourceBalance && (
              <AvailableAmount>
                {'Available balance: '}
                <b>{formatPrice(watchOfSourceCurrency, selectedSourceBalance?.total_amount)}</b>
              </AvailableAmount>
            )}
            {showSourceToPaymentFX && isRealObject(sourceToPaymentFX) && (
              <FieldWrapper>
                <div className="circle">
                  <div />
                </div>
                <FormTitle scale={5}>
                  {formatFXRatio({
                    foreignExchange: sourceToPaymentFX,
                    sourceCurrency: watchOfSourceCurrency,
                    paymentCurrency: watchOfConvertCurrency,
                  })}
                </FormTitle>
                <Footer>
                  <div>{convertToFullDateAndTime(sourceToPaymentFX?.valid_from)}</div>
                  <CircularProgressBar
                    total={moment(sourceToPaymentFX?.valid_to).diff(sourceToPaymentFX?.valid_from, 'second')}
                    lines={[
                      {
                        value: remainingTime,
                        color: COLORS.purple,
                        id: 'countdown',
                      },
                    ]}
                    strokeWidth={2}
                    radius={4}
                    strokeLinecap="square"
                  />
                </Footer>
              </FieldWrapper>
            )}
          </TransferFlowInfoWrapper>
          <FieldWrapper>
            <FormTitle scale={5} required>
              To
            </FormTitle>
            <RHFMoneyInput
              control={control}
              errors={errors}
              prefix="convert"
              full
              selectProps={{
                placeholder: 'Select Currency',
                options: currencyOptions,
                formatOptionLabel: getSourceCurrencyOptionLabel,
                width: 300,
                showFlag: true,
              }}
              inputProps={{
                rules: {
                  required: true,
                  min: 0,
                  max: 1000,
                },
                onChange: (event) => {
                  setAmount(getNumberFromFormatted(event.target.value));
                  setAmountOrigin('convertAmount');
                },
              }}
            />
            {errors.convertAmount?.message && <FormErrorMessage>{errors.convertAmount?.message}</FormErrorMessage>}
            {selectedConvertBalance && (
              <AvailableAmount>
                {'Available balance: '}
                <b>{formatPrice(watchOfConvertCurrency, selectedConvertBalance?.total_amount)}</b>
              </AvailableAmount>
            )}
          </FieldWrapper>
          <FormTitle scale={5} required>
            Conversion date
          </FormTitle>
          <Description>Choose the date you would like Airwallex to process this conversion</Description>
          <FieldWrapper>
            <Controller
              name="conversionDate"
              control={control}
              rules={{ required: true }}
              render={({ field }) => <SingleDatePicker {...field} hasError={errors.conversionDate} />}
            />
          </FieldWrapper>
          <ButtonWrapper>
            <Button width={160} type="submit" disabled={isAdminViewingAnotherCompany}>
              Next
            </Button>
          </ButtonWrapper>
        </form>
      </FormWrapper>
    </>
  );
};

export default ConversionForm;
