import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Bin from '../../../../assets/icons/Bin';
import Button from '../../../../components/Button';
import Header from '../../../../components/Header';
import featureToggle from '../../../../featureToggle';
import { Description } from '../../../Application/components';
import APPLICATION_PATH from '../../../Application/path';
import { MESSAGE_MODAL } from '../../../Modal/constants';
import { Card } from '../../components';
import PATH from '../../path';
import { deleteBusinessOwner, deleteBusinessOwnerEntity } from '@api/modules/dashboard/company';
import { FormRow, GridColumn } from '@components/Grid';
import { useModule } from '@hooks/routing';
import { fetchBusinessOwners, fetchBusinessEntities } from '@redux/modules/company/actions';
import { showModal } from '@redux/modules/UI/actions';
import { useSelectedCompany, useIsUnableToEditUnderAdminView } from '@redux/selectors';

const InfoCardWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.grayOne};
  padding: 20px;
  position: relative;

  :first-child > :first-child {
    font-weight: bold;
    display: inline-block;
    ${(props) => props.theme.ellipsis};
  }
`;

const CompanyInfoHeader = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: bold;
`;

const SupplementInfo = styled.div`
  ${(props) => props.theme.text.micro};
  ${(props) => props.theme.ellipsis};

  ${(props) =>
    props.error &&
    css`
      color: ${props.theme.colors.red};
    `}
`;

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.error};
  ${(props) => props.theme.text.micro};
  margin: 8px 0;
`;

const HeaderWrapper = styled.span`
  > *:first-child {
    display: inline-block;
    vertical-align: middle;
  }
`;

const IconWrapper = styled.span`
  position: absolute;
  right: 20px;
  > * {
    vertical-align: middle;
    cursor: pointer;
  }
`;

const Edit = styled.img``;

const StyledBin = styled(Bin)`
  width: 22px;
  height: 22px;
  cursor: pointer;
  path {
    fill: ${(props) => props.theme.colors.black};
  }

  margin-left: 8px;
`;

const Overview = () => {
  const history = useHistory();
  const module = useModule();
  const dispatch = useDispatch();
  const company = useSelectedCompany();
  const businessOwners = useSelector((state) => state.company.owners);
  const businessEntities = useSelector((state) => state.company.entities);
  const unableToEditAnotherCompany = useIsUnableToEditUnderAdminView();

  useEffect(() => {
    fetchBusinessOwners();
    fetchBusinessEntities();
  }, [company.id]);

  const deleteKeyPerson = (id) => async () => {
    await deleteBusinessOwner(id);
    fetchBusinessOwners();
  };

  const deleteKeyEntity = (id) => async () => {
    await deleteBusinessOwnerEntity(id);
    fetchBusinessEntities();
  };

  const handleDeleteClick = (entityName, callback) => async () => {
    dispatch(
      showModal(MESSAGE_MODAL, {
        title: 'Are you sure to delete this key business owner?',
        description: `You are trying to delete ${entityName}. Click No if you do not want to do so.`,
        rightButtonProps: {
          label: 'Yes, I want to delete',
        },
        leftButtonProps: {
          label: 'No',
        },
        onConfirm: callback,
      })
    );
  };

  const edit = (path) => () => {
    history.push(path);
  };

  return (
    <>
      <CompanyInfoHeader>Company Information</CompanyInfoHeader>
      {featureToggle.HIDE_FOR_SHOPIFY ? (
        <Description>Please ensure you provide correct company information below.</Description>
      ) : (
        <Description>
          Please ensure you provide correct company information below for us to prepare the final funding offer. This information will
          appear on your Choco Up Agreement.
        </Description>
      )}
      <Card>
        <FormRow>
          <Header scale={3}>Business Details</Header>
        </FormRow>
        <FormRow>
          {featureToggle.HIDE_FOR_SHOPIFY ? (
            <Description>Please provide accurate information about your business.</Description>
          ) : (
            <Description>Please provide accurate information about your business for the funding agreement.</Description>
          )}
        </FormRow>
        <FormRow>
          {company.registerNumber ? (
            <GridColumn lg={5} md={5} disableGutter>
              <InfoCardWrapper>
                <HeaderWrapper>
                  <span>{company.companyName}</span>
                  <IconWrapper>
                    <Edit
                      src="/icons/edit.svg"
                      onClick={edit(module === 'dashboard' ? PATH.SETTINGS_COMPANY_BUSINESS : APPLICATION_PATH.COMPANY_BUSINESS)}
                      alt="edit"
                    />
                  </IconWrapper>
                </HeaderWrapper>
                <SupplementInfo>{`Reg No: ${company.registerNumber}`}</SupplementInfo>
              </InfoCardWrapper>
            </GridColumn>
          ) : (
            <GridColumn lg={4} md={6}>
              <NavLink to={module === 'dashboard' ? PATH.SETTINGS_COMPANY_BUSINESS : APPLICATION_PATH.COMPANY_BUSINESS}>
                <Button disabled={unableToEditAnotherCompany} tertiary full>
                  Add Business Details
                </Button>
              </NavLink>
              <ErrorMessage>Missing Information</ErrorMessage>
            </GridColumn>
          )}
        </FormRow>
      </Card>
      <Card>
        <FormRow>
          <Header scale={3}>Management and Ownerships</Header>
        </FormRow>
        <FormRow>
          <Description>
            Please add any individual or entity who owns at least 25% of your business. (Please add the key personnel eg. CEO or Managing
            Director if no shareholder holds more than 25% of shares)
          </Description>
        </FormRow>
        {Array.isArray(businessOwners) &&
          businessOwners.length > 0 &&
          businessOwners.map(({ id, firstName, lastName, jobTitle }) => {
            const title = `${firstName} ${lastName}`;
            const editPagePath = `${
              module === 'dashboard' ? PATH.SETTINGS_COMPANY_KEY_PERSONS : APPLICATION_PATH.COMPANY_KEY_PERSONS
            }/${id}`;
            return (
              <FormRow key={id}>
                <GridColumn lg={5} md={7} disableGutter>
                  <InfoCardWrapper>
                    <HeaderWrapper>
                      <div>Key Person</div>
                      <IconWrapper>
                        <Edit src="/icons/edit.svg" onClick={edit(editPagePath)} alt="edit" />
                        <StyledBin onClick={handleDeleteClick(title, deleteKeyPerson(id))} />
                      </IconWrapper>
                    </HeaderWrapper>
                    <div>{title}</div>
                    <SupplementInfo>{jobTitle}</SupplementInfo>
                  </InfoCardWrapper>
                </GridColumn>
              </FormRow>
            );
          })}
        {Array.isArray(businessEntities) &&
          businessEntities.length > 0 &&
          businessEntities.map(({ business_owner_entity_id, legal_company_name, company_reg_no }) => {
            const editPagePath = `${
              module === 'dashboard' ? PATH.SETTINGS_COMPANY_KEY_ENTITY : APPLICATION_PATH.COMPANY_KEY_ENTITY
            }/${business_owner_entity_id}`;
            const supplementInfo = `Reg No: ${company_reg_no}`;
            return (
              <FormRow key={business_owner_entity_id}>
                <GridColumn lg={5} md={7} disableGutter>
                  <InfoCardWrapper>
                    <HeaderWrapper>
                      <span>Key Entity</span>
                      <IconWrapper>
                        <Edit src="/icons/edit.svg" onClick={edit(editPagePath)} alt="edit" />
                        <StyledBin onClick={handleDeleteClick(legal_company_name, deleteKeyEntity(business_owner_entity_id))} />
                      </IconWrapper>
                    </HeaderWrapper>
                    <div>{legal_company_name}</div>
                    <SupplementInfo>{supplementInfo}</SupplementInfo>
                  </InfoCardWrapper>
                </GridColumn>
              </FormRow>
            );
          })}
        <FormRow>
          <GridColumn lg={4} md={6} sm={12}>
            <NavLink to={module === 'dashboard' ? PATH.SETTINGS_COMPANY_KEY_OWNER : APPLICATION_PATH.COMPANY_KEY_OWNER}>
              <Button disabled={unableToEditAnotherCompany} tertiary full>
                Add Business Owners
              </Button>
            </NavLink>
            {Array.isArray(businessOwners) &&
              businessOwners.length === 0 &&
              Array.isArray(businessEntities) &&
              businessEntities.length === 0 && <ErrorMessage>Missing Information</ErrorMessage>}
          </GridColumn>
        </FormRow>
      </Card>
    </>
  );
};

export default Overview;
