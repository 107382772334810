import _ from 'lodash';

import { adjustStripeAmount } from '@utils/priceHelpers';

export const generateRefundPayload = (formData) => {
  return {
    payment_intent: formData.payment_intent,
    amount: formData.refundAmount,
    refund_application_fee: formData.refund_application_fee,
    reverse_transfer: formData.reverse_transfer,
  };
};

export const checkIsAbleToRefund = (status) => {
  return ['Partial refund', 'succeeded'].indexOf(status) !== -1;
};

export const getAvailableRefundAmount = (payment) => {
  const refundData = _.get(payment, 'charges.data[0].refunds.data');
  if (Array.isArray(refundData) && refundData.length > 0) {
    const sum = refundData
      .map(({ amount }) => amount)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    return adjustStripeAmount(payment.amount - sum);
  } else {
    return adjustStripeAmount(payment.amount);
  }
};

export const getRefundedAmount = (payment) => {
  const availableRefundAmount = getAvailableRefundAmount(payment);

  return adjustStripeAmount(payment.amount) - availableRefundAmount;
};
