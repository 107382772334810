import { VIEW_ALL_OPTION, TAB, ENABLED_SALES_PLATFORM_OPTIONS, ENABLED_PAYMENT_PLATFORM_OPTIONS } from '../constants';
import { INTEGRATION_STATUS } from '@/constants';
import { INTEGRATION_TYPE } from '@constants/integration';
import { leadSourceTypeOptions } from '@constants/options';
import { PLATFORM, PLATFORM_DISPLAY_NAME, ENABLED_PLATFORMS, PLATFORM_MAP } from '@constants/platform';
import { isRealNumber } from '@utils/dataTypes';
import { convertToMonthYear } from '@utils/dateHelpers';
import { getOptionLabelByValue } from '@utils/optionHelpers';
import { roundUpNumber, roundDownNumber, formatAmountWithUnit, alwaysPositiveNumber } from '@utils/priceHelpers';

export const formatAnalyticsData = (value, decimalScale = 0) => {
  return value.map(({ date, all, ...restData }) => {
    return {
      date: convertToMonthYear(date),
      Total: isRealNumber(all) ? all.toFixed(decimalScale) : all, // keep Uppercase because key is display label
      ...restData,
    };
  });
};

export const priceTickFormatter = (value) => {
  return formatAmountWithUnit(value, 2);
};

export const getYAxisDomain = (yAxisData) => {
  const highest = Math.max(...yAxisData, 0);
  const lowest = Math.min(...yAxisData, 0);
  const highestInteger = roundUpNumber(highest);
  const lowestInteger = roundUpNumber(lowest);

  const step = 10 ** (highestInteger.toString().length - 1);
  const lowestIntegerStep = 10 ** (alwaysPositiveNumber(lowestInteger).toString().length - 1);

  let roundedNumber = roundUpNumber(highestInteger, step);

  const lowestRoundedNumber = roundDownNumber(lowestInteger, lowestIntegerStep);

  if (roundedNumber === 0 && lowestRoundedNumber === 0) {
    // it is because y axis is evenly divided into 4 levels by the library.
    // Top domain is 4 so that label is integer $1, $2, $3, $4 for aesthetics
    roundedNumber = 4;
  }

  return [lowestRoundedNumber, roundedNumber];
};

export const isMaskOverlayActive = (category, platform, integrations) => {
  const activePlatformNames = integrations
    .filter((integration) => {
      if (category === TAB.ANALYTICS) {
        return integration.platformName === PLATFORM.GOOGLE_ANALYTICS && integration.status !== INTEGRATION_STATUS.PENDING_ACTION;
      } else {
        if (integration.platformName === PLATFORM.SHOPIFY) {
          return integration.status === INTEGRATION_STATUS.CONNECTED;
        }

        if (category === TAB.SALES) {
          return ENABLED_SALES_PLATFORM_OPTIONS.map(({ value }) => value).indexOf(integration.platformName) !== -1;
        } else if (category === TAB.PAYMENT) {
          if (integration.platformName === PLATFORM.BBMSL) {
            return true;
          } else {
            return ENABLED_PAYMENT_PLATFORM_OPTIONS.map(({ value }) => value).indexOf(integration.platformName) !== -1;
          }
        }
        return false;
      }
    })
    .map(({ platformName }) => platformName);

  if (activePlatformNames.indexOf(platform) !== -1) {
    return true;
  } else if (platform === VIEW_ALL_OPTION.value) {
    return activePlatformNames.length > 0;
  } else {
    return false;
  }
};

export const getIntegratedPlatforms = (integrations) => {
  return integrations.filter((value) => {
    return (
      (ENABLED_PLATFORMS.indexOf(value.platformName) !== -1 &&
        value.status === 1 &&
        value.integrationType !== INTEGRATION_TYPE.SALES_OTHERS_PLATFORM) ||
      INTEGRATION_TYPE.MANUAL_INTEGRATION
    );
  });
};

export const formatLeadSourceInfo = (leadSourceType, leadSourceDetail) => {
  return `${getOptionLabelByValue(leadSourceType, leadSourceTypeOptions)}${leadSourceType === 'OTHER' ? ` - ${leadSourceDetail}` : ''}`;
};
