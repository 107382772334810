import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const StyledLink = styled(Link)`
  ${(props) => props.theme.text.link};
  display: inline-block;
`;

const CurrentNav = styled.span``;

const Chevron = styled.span`
  margin: 0 8px;
`;

const BreadCrumb = ({ items = [], className }) => {
  return (
    <Wrapper className={className}>
      {Array.isArray(items) &&
        items.length > 0 &&
        items.map(({ value, label }, index) => {
          return (
            <span key={value + label}>
              {index < items.length - 1 ? (
                <StyledLink to={value || ''}>{label}</StyledLink>
              ) : (
                <CurrentNav>{label}</CurrentNav>
              )}
              {index < items.length - 1 && <Chevron>{'>'}</Chevron>}
            </span>
          );
        })}
    </Wrapper>
  );
};

export default BreadCrumb;
