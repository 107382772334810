import store from '../../store';
import { GET_XERO_BANK_TRANSACTION_SUCCESS } from './constants';
import { getXeroBankTransactions } from '@api/modules/dashboard';

export const fetchXeroTransactionsApi = async (query, filter) => {
  const { data } = await getXeroBankTransactions(query, { filter });
  if (data) {
    const { data: transactions, total } = data;
    store.dispatch({
      type: GET_XERO_BANK_TRANSACTION_SUCCESS,
      payload: {
        transactions: transactions || [],
        pageNumber: query.page,
        totalNumber: total,
      },
    });
  }
};
