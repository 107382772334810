import React, { useCallback, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { createAmazonAccountStatement } from '@api/modules/integration/airwallex';
import Button from '@components/Button';
import Checkbox from '@components/Checkbox';
import CountrySelect from '@components/CountrySelect';
import FormTitle from '@components/FormTitle';
import { FormRow, GridColumn } from '@components/Grid';
import Header from '@components/Header';
import HR from '@components/HR';
import Input from '@components/Input';
import Modal from '@components/Modal';
import { ERROR_MESSAGES } from '@constants/errors';
import { Description } from '@modules/Application/components';
import { MESSAGE_MODAL } from '@modules/Modal/constants';
import { showModal } from '@redux/modules/UI/actions';
import { useWalletAccount } from '@redux/selectors';
import { convertDate } from '@utils/dateHelpers';
import { emailRegExp } from '@utils/validators';

const ButtonWrapper = styled(GridColumn)`
  justify-content: right;
  display: flex;
`;

const FormItem = styled(FormRow)`
  &:not(.with-linebreak) {
    margin-bottom: 2rem;
  }
`;

type CreateAmazonAccountModalProps = {
  globalAccountId: string;
  accountType: string;
};

const CreateAmazonAccountModal = ({ globalAccountId, accountType }: CreateAmazonAccountModalProps) => {
  const dispatch = useDispatch();
  const walletAccount = useWalletAccount();
  const fieldNames = {
    country: 'registerAddress.country',
    zipCode: 'registerAddress.zipCode',
    addressLine1: 'registerAddress.addressLine1',
    addressLine2: 'registerAddress.addressLine2',
    state: 'registerAddress.state',
    city: 'registerAddress.city',
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isSavingInfo, toggleIsSavingInfo] = useState(false);

  const createAmazonAccount = async (formData) => {
    try {
      const {
        checkBox,
        address,
        city,
        registerAddress: { country },
        zipCode,
        state,
        amazonRegisteredEmail,
        amazonCompanyName,
      } = formData;
      const { data, statusCode, headers } = await createAmazonAccountStatement(walletAccount?.account_id, globalAccountId, accountType, {
        agreement: checkBox,
        registered_address: {
          address: address,
          city: city,
          country: country,
          postcode: zipCode,
          state: state,
        },
        registered_email: amazonRegisteredEmail,
        registered_name: amazonCompanyName,
      });

      // success
      if (statusCode === 200) {
        const disposition = headers['content-disposition']; // attachment; filename="[filename]"
        const extractWord = /attachment; filename="(.*)"/;
        const fileName =
          extractWord.exec(disposition)?.[1] || `AirwallexGlobalAccountStatement-${convertDate(new Date(), 'MMMM Do YYYY')}.pdf`;
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        onExportSuccess();
      } else {
        // Error
        // TODO: Consider moving this logic into interceptor
        if (statusCode === 400) {
          // RequestType = blob, response body is blob
          const message: string = await new Blob([data]).text();
          toast.error(message);
        } else {
          // TODO: Define a better response with BE.
          toast.error(ERROR_MESSAGES.REQUEST_FAILED);
        }
      }
    } catch (err) {
      console.error(err);
      // toast.error(ERROR_MESSAGES.REQUEST_FAILED);
      toast.error('Something went wrong. Please try again later');
    } finally {
      toggleIsSavingInfo(false);
    }
  };

  const onSubmit = (data) => {
    toggleIsSavingInfo(true);
    createAmazonAccount(data);
  };

  const onExportSuccess = useCallback(() => {
    dispatch(
      showModal(MESSAGE_MODAL, {
        title: 'Exported successfully',
        description: <Description>Amazon account statement exported successfully!</Description>,
        showLeftButton: false,
        showRightButton: false,
        showCloseButton: true,
      })
    );
  }, [dispatch]);

  const getError = useCallback((path) => _.get(errors, path), []);

  return (
    <Modal>
      <Header scale={2}>Amazon account statement</Header>
      <Header scale={4} style={{ fontWeight: 700, marginTop: '1.5rem' }}>
        Input the details below and export your account statement for Amazon.
      </Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HR />
        <FormItem>
          <FormTitle scale={4.5} required>
            Amazon registerd email
          </FormTitle>
          <Controller
            name="amazonRegisteredEmail"
            control={control}
            rules={{ required: true, pattern: emailRegExp }}
            render={({ field }) => (
              <Input
                {...field}
                fieldType="text"
                hasError={errors.amazonRegisteredEmail}
                placeholder="example@choco-up.com"
                errorMsg="Incorrect email format"
                full
              />
            )}
          />
        </FormItem>

        <FormItem>
          <FormTitle scale={4.5} required>
            Amazon registered owned/company name
          </FormTitle>
          <Controller
            name="amazonCompanyName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <Input {...field} fieldType="text" hasError={errors.amazonCompanyName} full />}
          />
        </FormItem>

        <FormItem>
          <FormTitle scale={4.5} required>
            Country or region
          </FormTitle>
          <Controller
            name={fieldNames.country}
            control={control}
            rules={{ required: true }}
            render={({ field }) => <CountrySelect {...field} fieldType="text" hasError={getError(fieldNames.country)} full />}
          />
        </FormItem>

        <FormItem>
          <FormTitle scale={4.5} required>
            Address
          </FormTitle>
          <Controller
            name="address"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <Input {...field} fieldType="text" hasError={errors.address} full />}
          />
        </FormItem>
        <FormItem>
          <FormTitle scale={4.5} required>
            City
          </FormTitle>
          <Controller
            name="city"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <Input {...field} fieldType="text" hasError={errors.city} full />}
          />
        </FormItem>

        <FormItem>
          <FormTitle scale={4.5} required>
            State
          </FormTitle>
          <Controller
            name="state"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <Input {...field} fieldType="text" hasError={errors.state} full />}
          />
        </FormItem>
        <FormItem className="with-linebreak">
          <FormTitle scale={4.5} required>
            ZIP code
          </FormTitle>
          <Controller
            name="zipCode"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <Input {...field} fieldType="text" hasError={errors.zipCode} full />}
          />
        </FormItem>
        <HR />
        <FormItem>
          <Controller
            name="checkBox"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Checkbox
                {...field}
                textSide="I, as the authorized representative of the registered seller, unequivocally confirm that the above details filled in by me regarding the registered settler are accurate, complete, truthful and correspond to the details provided to and appear on the Amazon seller central. I acknowledge and agree that Airwallex shall rely on the information and this confirmation in all aspects relating to the account for this registered seller, and in connection with the account statements that are being issued for Amazon. I further acknowledge, agree and confirm that I shall not modify, alter, add, remove or in any way change the form or any part of the contents of the account statement provided for Amazon."
                hasError={errors.checkBox}
              />
            )}
          />
        </FormItem>
        <FormItem>
          <ButtonWrapper>
            <Button type="submit" disabled={isSavingInfo}>
              Export
            </Button>
          </ButtonWrapper>
        </FormItem>
      </form>
    </Modal>
  );
};

export default CreateAmazonAccountModal;
