import { findFirstMatchInArray } from './dataTypes';
import { INTEGRATION_TYPE } from '@constants/integration';
import {
  PLATFORM,
  SALES_PLATFORMS,
  ENABLED_PLATFORMS,
  PAYMENT_PLATFORMS,
  ACCOUNTING_PLATFORMS,
  MARKETING_PLATFORMS,
  ALL_LOGOS,
} from '@constants/platform';

export const salesPlatformsFilter = (value) => {
  return SALES_PLATFORMS.indexOf(value) !== -1;
};

export const salesOthersPlatformsFilter = (integrationType) => {
  return integrationType === INTEGRATION_TYPE.SALES_OTHERS_PLATFORM;
};

export const accountingPlatformsFilter = (value) => {
  return ACCOUNTING_PLATFORMS.indexOf(value) !== -1;
};

export const marketingPlatformsFilter = (value) => {
  return MARKETING_PLATFORMS.indexOf(value) !== -1;
};

export const getLogoByPlatformName = (platformName) => {
  return findFirstMatchInArray(ALL_LOGOS, (platform) => platform?.value === platformName) || {};
};

export const isBBMSLIntegrated = (integratedAccounts) => {
  const integratedPlatforms = integratedAccounts.map((account) => account.platformName);
  return integratedPlatforms.indexOf(PLATFORM.BBMSL) !== -1;
};

export const isPlatformDisabled = (platform) => {
  return ENABLED_PLATFORMS.indexOf(platform) === -1;
};

export const filterSalesAccountLogos = (logos, accounts) => {
  if (Array.isArray(logos) && logos.length > 0) {
    const hasBBMSLAccount = isBBMSLIntegrated(accounts);

    return logos.filter((logo) => {
      if (PAYMENT_PLATFORMS.indexOf(logo.value) !== -1) {
        return !hasBBMSLAccount;
      }

      return true;
    });
  }

  return logos;
};

export const formatGAAccountLabel = (accountId, accountName) => {
  try {
    const parsed = JSON.parse(accountName);
    if (Array.isArray(parsed) && parsed.length === 3) {
      const names = parsed.map((item) => item.name);
      return names.join(' > ');
    }

    return accountId;
  } catch (err) {
    return accountId;
  }
};

export const formatBankAccountLabel = (nameElements) => {
  return Array.isArray(nameElements) ? nameElements.filter((item) => item).join(' > ') : nameElements;
};

export const getAccountDisplayName = (account) => {
  if (account?.platformName === PLATFORM.GOOGLE_ANALYTICS) {
    return formatGAAccountLabel(account?.accountId, account?.accountName);
  }
  return account?.accountName || account?.accountId;
};

export const isShopifyIntegrationDisabled = (integrations, isSignUpViaShopify) => {
  if (isSignUpViaShopify) {
    const integrationValues = integrations.map(({ platformName }) => platformName);
    return integrationValues.indexOf(PLATFORM.SHOPIFY) !== -1;
  }

  return false;
};

export const convertIntegrationToMap = (integrations) => {
  const result = {};

  if (Array.isArray(integrations)) {
    integrations.forEach((integration) => {
      result[integration.platformName] = {
        ...(result[integration.platformName] || {}),
        [integration.id]: integration,
      };
    });
  }

  return result;
};
