/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';
import styled, { css } from 'styled-components';

import HR from '../../../../../components/HR';
import { isRealObject } from '@utils/dataTypes';
import { MMM_YYYY_FORMAT, convertDate } from '@utils/dateHelpers';
import { formatNumber } from '@utils/priceHelpers';

const StyledTable = styled.table`
  width: 100%;

  ${(props) => props.theme.table};
`;

const StyledTD = styled.td`
  padding: 10px 16px;
  border-top: 1px solid transparent !important;

  &.subcategoryItemSubItem {
    color: ${(props) => props.theme.colors.grayThree};
    padding-left: 60px;
  }

  &.subcategoryItem {
    color: ${(props) => props.theme.colors.grayThree};
    padding-left: 40px;
    font-weight: 600;
  }
`;

const TD = styled.td`
  padding: 10px 16px;
  float: right;
  border-top: 1px solid transparent !important;

  &.subCategoryValue {
    padding-right: 32px;
  }
`;

const TotalHeaders = styled.td`
  padding: 10px 16px;
  float: right;
  font-weight: 600;
  border-top: 1px solid transparent !important;
`;

const SubTitle = styled.div`
  ${(props) => props.theme.text.micro};
  color: ${(props) => props.theme.colors.grayThree};
  font-weight: 600;
  border: 1px solid transparent;
  margin-left: 10px;
`;

const Title = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: 600;
  color: ${(props) => props.theme.colors.black};
  border: 1px solid transparent;
`;

const DateHeader = styled.div`
  text-align: right;
  padding: 10px 28px;
  font-weight: 600;
`;

const StyledTr = styled.tr`
  border-top: 2px solid rgb(231, 234, 236);
  border-bottom: 2px solid rgb(231, 234, 236);
  background: ${(props) => props.theme.colors.grayOne};
`;

const SubTotalHeader = styled.div`
  ${(props) => props.theme.text.micro};
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
  text-align: right;
  padding-right: 15px;
`;

const BalanceSheetTable = ({ data, endDate, startDate }) => {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const keys =
      Array.isArray(data) && data.length > 0
        ? Object.keys(data[0]).filter((key) => {
            return key;
          })
        : [];
    const columnData = keys.map((key) => {
      return {
        Header: key,
        showTotal: true,
        accessor: key,
        Cell: ({ value }) => <TD>{formatNumber(value, 2)}</TD>,
      };
    });

    setColumns(columnData);
  }, [JSON.stringify(data)]);

  return (
    <StyledTable>
      <tbody>
        <tr>
          <StyledTD />
          <DateHeader>{convertDate(endDate, MMM_YYYY_FORMAT)}</DateHeader>
        </tr>
        {Array.isArray(columns) &&
          columns.map(({ Header, accessor, Cell }) => {
            return (
              <>
                <tr>
                  <StyledTD>
                    <Title>{Header}</Title>
                  </StyledTD>
                  {Array.isArray(data) &&
                    data.length > 0 &&
                    data.map((datum) => {
                      const item = _.get(datum, accessor);
                      return <TotalHeaders>{item !== undefined && Cell(item)}</TotalHeaders>;
                    })}
                </tr>
                {Array.isArray(data) &&
                  data.length > 0 &&
                  data.map((datum) => {
                    const item = _.get(datum, accessor);

                    if (item) {
                      const subcategories = Object.values(item).filter((i) => isRealObject(i));
                      return subcategories.map((subcategory) => {
                        return (
                          <>
                            <tr className="subcategory">
                              <StyledTD>
                                <SubTitle>{subcategory?.name}</SubTitle>
                              </StyledTD>
                              <TD className="subCategoryValue">{formatNumber(subcategory?.value, 2)}</TD>
                            </tr>
                            {Array.isArray(subcategory.items) &&
                              subcategory.items.map((subcategoryItem) => {
                                return (
                                  <>
                                    <tr>
                                      <StyledTD className="subcategoryItem">{subcategoryItem?.name}</StyledTD>
                                      <StyledTD>{Cell(subcategoryItem)}</StyledTD>
                                    </tr>
                                    {Array.isArray(subcategoryItem.items) &&
                                      subcategoryItem.items.map((subItem) => {
                                        return (
                                          <tr>
                                            <StyledTD className="subcategoryItemSubItem">{subItem?.name}</StyledTD>
                                            <StyledTD>{Cell(subItem)}</StyledTD>
                                          </tr>
                                        );
                                      })}
                                  </>
                                );
                              })}
                            <StyledTr>
                              <StyledTD>
                                <SubTitle>
                                  Total&nbsp;
                                  {subcategory?.name}
                                </SubTitle>
                              </StyledTD>
                              <SubTotalHeader>{Cell(subcategory)}</SubTotalHeader>
                            </StyledTr>
                          </>
                        );
                      });
                    }
                  })}
              </>
            );
          })}
      </tbody>
    </StyledTable>
  );
};

export default BalanceSheetTable;
