import { useSelector } from 'react-redux';

import { VIEW } from './modules/UI/constants';
import { OFFER_CONDITION } from '@constants/offer';
import { PLATFORM } from '@constants/platform';
import { findFirstMatchInArray } from '@utils/dataTypes';

export const useSelectedCompany = () =>
  useSelector((state) => {
    return state.company.data[state.company.selectedCompanyId] || {};
  });

export const useSelectedCompanyId = () =>
  useSelector((state) => {
    return state.company.selectedCompanyId;
  });

export const useUserCompanyId = () =>
  useSelector((state) => {
    return state.user.profile.companyId;
  });

export const useIsAdminView = () =>
  useSelector((state) => {
    return state.ui.view === VIEW.ADMIN;
  });

export const useCheckIsAdminViewingAnotherCompany = () => {
  const userCompanyId = useUserCompanyId();
  const selectedCompanyId = useSelectedCompanyId();

  return useSelector((state) => {
    return state.ui.view === VIEW.ADMIN && userCompanyId !== selectedCompanyId;
  });
};

export const useIsUnableToEditUnderAdminView = () =>
  useSelector((state) => {
    const adminRole = state.user.profile.adminRole;
    return state.ui.view === VIEW.ADMIN && adminRole?.permissions.indexOf('edit_company_info') === -1;
  });

export const useIsUserAssignedAdmin = () => {
  const { admins } = useSelectedCompany();

  return useSelector((state) => {
    const userAdminId = state.user.profile.adminRole.adminId;
    if (Array.isArray(admins) && admins.length > 0) {
      const adminIds = admins.map(({ admin_id }) => admin_id);
      return adminIds.indexOf(userAdminId) !== -1;
    } else {
      return false;
    }
  });
};

export const useAcceptedOffer = () => {
  const isAdmin = useIsAdminView();
  return useSelector((state) => {
    const offers = isAdmin ? state.offer.adminOffers : state.offer.userOffers;
    if (Array.isArray(offers) && offers.length > 0) {
      const conditions = offers.map(({ condition }) => condition);
      const index = conditions.indexOf(OFFER_CONDITION.ACCEPTED);
      if (offers[index]) {
        return offers[index];
      }
    }
    const userOffers = state.offer.userOffers;
    if (Array.isArray(userOffers) && userOffers.length > 0) {
      const conditions = userOffers.map(({ condition }) => condition);
      const index = conditions.indexOf(OFFER_CONDITION.ACCEPTED);
      if (userOffers[index]) {
        return userOffers[index];
      }
    }

    return null;
  });
};

export const useWalletAccount = () =>
  useSelector((state) => {
    const walletAccounts = state.wallet.walletAccounts;
    if (Array.isArray(walletAccounts) && walletAccounts.length > 0) {
      return walletAccounts[0];
    }

    return null;
  });

export const useWalletAccountId = () =>
  useSelector((state) => {
    const walletAccounts = state.wallet.walletAccounts;
    if (Array.isArray(walletAccounts) && walletAccounts.length > 0) {
      return walletAccounts[0].account_id;
    }

    return null;
  });

export const useCheckIsSignUpViaShopify = () => {
  return true;
};

export const useActiveRevenueStream = () => {
  return useSelector((state) => {
    const streams = state.fund.streams;
    return findFirstMatchInArray(streams, (stream) => stream.status === 'ONGOING');
  });
};
