import React from 'react';

import styled, { css } from 'styled-components';

import COLORS from '../styles/colors';

const ButtonWrapper = styled.button`
  cursor: pointer;
  border-radius: 5px;
  padding: 11.5px 21px;
  ${(props) => props.theme.button};
  font-weight: 600;
  text-align: center;

  // primary
  background-color: ${(props) => (props.warning ? COLORS.red : COLORS.purple)};
  border: 2px solid ${(props) => (props.warning ? COLORS.red : COLORS.purple)};
  color: ${COLORS.white};

  ${(props) =>
    props.secondary &&
    css`
      background-color: transparent;
      color: ${props.warning ? COLORS.red : COLORS.purple};
      border: 2px solid ${props.warning ? COLORS.red : COLORS.purple};
    `}

  ${(props) =>
    props.tertiary &&
    css`
      background-color: transparent;
      color: ${COLORS.black};
      border: 2px solid ${COLORS.black};
    `}

    ${(props) =>
    props.disabled &&
    css`
      background-color: ${COLORS.grayTwo};
      border: 2px solid ${COLORS.grayTwo};
      color: ${COLORS.white};
      cursor: not-allowed;
    `}

    ${(props) =>
    props.small &&
    css`
      padding: 5px 16px;
      ${props.theme.text.micro};
    `}

    ${(props) =>
    props.full &&
    css`
      width: 100%;
    `}

    ${(props) =>
    props.half &&
    css`
      width: 50%;
    `}

    ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
`;

const Button = ({ text, children, ...restProps }) => <ButtonWrapper {...restProps}>{text || children}</ButtonWrapper>;

export default Button;
