import React from 'react';

import _ from 'lodash';
import { useController } from 'react-hook-form';

import Textarea from '../../components/Textarea';
import ErrorMessage from './ErrorMessage';

const ControlledTextarea = ({ name, control, rules = {}, showError = true, defaultValue, ...restProps }) => {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields, errors },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const error = _.get(errors, name);
  return (
    <>
      <Textarea {...field} hasError={error} {...restProps} />
      {error && showError && <ErrorMessage>{error.message}</ErrorMessage>}
    </>
  );
};

export default ControlledTextarea;
