import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Button from '../../../../../../components/Button';
import FancyDateRangePicker from '../../../../../../components/FancyDateRangePicker';
import SimplePagination from '../../../../../../components/Pagination/SimplePagination';
import SearchBar from '../../../../../../components/SearchBar';
import Table from '../../../../../../components/Table';
import Logger from '../../../../../../utils/logger';
import { Wrapper } from '../../../../components';
import PATH from '../../../../path';
import { OPTION_VALUE_ALL } from '../../../constants';
import { FilterLabel, StyledSelect } from '../../components';
import { conversionStatusOptions, conversionCurrencyOptions } from './constants';
import { useDebounceSearch } from '@/hooks';
import { usePagination } from '@hooks/UI';
import { fetchWalletConversionList } from '@redux/modules/wallet/actions';
import { useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';
import { media } from '@styles/breakpoints';
import { convertToFullDate, convertToApiDate } from '@utils/dateHelpers';
import { formatPrice } from '@utils/priceHelpers';
import { replaceDelimiter, capitalize } from '@utils/stringHelpers';
import { areRecordsFewerThanPageSize } from '@utils/utilHelpers';

const StyledFancyDateRangePicker = styled(FancyDateRangePicker)`
  .portal {
    right: initial;
  }
`;

const Container = styled.div`
  display: flex;
  margin: 20px 0;

  ${media.dablet`
    flex-direction: column;
  `};
`;

const PageInfo = styled.div`
  flex-grow: 2;

  div:first-child {
    ${(props) => props.theme.text.four};
  }
`;

const FilterSection = styled.span`
  display: flex;
  margin: 26px 0;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;

  ${media.dablet`
    display: flex;
    gap: 5px;
    justify-content: start;
  `};
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const conversionTableColumns = [
  {
    Header: 'Created Date',
    accessor: 'created_at',
    Cell: ({ value }) => {
      return convertToFullDate(value);
    },
    getStyle: () => {
      return css`
        width: 150px;
      `;
    },
  },
  {
    Header: 'Conversion Date',
    accessor: 'conversion_date',
    Cell: ({ value }) => {
      return convertToFullDate(value);
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => {
      return capitalize(replaceDelimiter(value, '_', ' '));
    },
  },
  {
    Header: 'Short reference',
    accessor: 'short_reference_id',
    Cell: ({ value }) => {
      return value;
    },
  },
  {
    Header: 'Rate',
    accessor: 'client_rate',
    Cell: ({ value }) => {
      return value;
    },
  },
  {
    Header: 'From',
    accessor: 'sell_currency',
    Cell: ({ value, row }) => {
      return formatPrice(value, row.sell_amount, false, 2);
    },
    getStyle: () => {
      return css`
        text-align: right;
        width: 150px;
      `;
    },
  },
  {
    Header: 'To',
    accessor: 'buy_currency',
    Cell: ({ value, row }) => {
      return formatPrice(value, row.buy_amount, false, 2);
    },

    getStyle: () => {
      return css`
        text-align: right;
        width: 150px;
      `;
    },
  },
];

const ConversionActivity = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pageNumber, pageSize, pageCount, onPageNumberChange, onPageSizeChange, changePageCount } = usePagination({
    pageNumber: 1,
    pageCount: 0,
    pageSize: 20,
  });
  const isLoading = useSelector((state) => state.wallet.isLoading);
  const walletAccounts = useSelector((state) => state.wallet.walletAccounts);
  const walletConversionList = useSelector((state) => state.wallet.conversionPageMap);
  const data = walletConversionList[pageNumber];
  const [searchKeyword, setSearchKeyword] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateShortcutValue, setDateShortValue] = useState();
  const [statusFilter, setStatusFilter] = useState(OPTION_VALUE_ALL);
  const [buyCurrencyFilter, setBuyCurrencyFilter] = useState(OPTION_VALUE_ALL);
  const [sellCurrencyFilter, setSellCurrencyFilter] = useState(OPTION_VALUE_ALL);
  const isAdminViewingAnotherCompany = useCheckIsAdminViewingAnotherCompany();

  const { debounceSearchKeyword } = useDebounceSearch(searchKeyword);

  const applyCalendar = (calendarData) => {
    setStartDate(calendarData.startDate);
    setEndDate(calendarData.endDate);
    setDateShortValue(calendarData.dateShortcutSelectValue);
  };

  const fetchConversionList = async () => {
    try {
      const filters = [];

      if (sellCurrencyFilter !== OPTION_VALUE_ALL) {
        filters.push({
          field: 'sell_currency',
          operator: 'in',
          value: [sellCurrencyFilter],
        });
      }

      if (buyCurrencyFilter !== OPTION_VALUE_ALL) {
        filters.push({
          field: 'buy_currency',
          operator: 'in',
          value: [buyCurrencyFilter],
        });
      }

      if (statusFilter !== OPTION_VALUE_ALL) {
        filters.push({
          field: 'status',
          operator: 'in',
          value: statusFilter.map(({ value }) => value),
        });
      }

      if (debounceSearchKeyword.length > 1) {
        filters.push({
          fields: ['short_reference_id', 'conversion_id'],
          operator: 'like',
          value: [debounceSearchKeyword],
        });
      }

      fetchWalletConversionList(walletAccounts[0]?.account_id, {
        ...(startDate && { startDate: convertToApiDate(startDate) }),
        ...(endDate && {
          endDate: convertToApiDate(moment(endDate).add(1, 'days')),
        }),
        pageNumber,
        pageSize,
        filters,
      });
    } catch (err) {
      Logger.error(err);
    }
  };

  const noNextPage = areRecordsFewerThanPageSize(walletConversionList[pageNumber], pageSize);

  useEffect(() => {
    fetchConversionList();
  }, [statusFilter, buyCurrencyFilter, sellCurrencyFilter, startDate, endDate, debounceSearchKeyword, pageNumber]);

  useEffect(() => {
    fetchConversionList();
  }, [JSON.stringify(walletAccounts), pageSize]);

  const handleClickByConversion = (value) => {
    history.push(
      generatePath(PATH.WALLET_CONVERSION_VIEW_CONVERSION_ID, {
        conversionId: value.conversion_id,
      })
    );
  };

  return (
    <Wrapper>
      <Container>
        <PageInfo>
          <div>Conversion</div>
        </PageInfo>
        <ButtonWrapper>
          <Button disabled={isAdminViewingAnotherCompany} onClick={() => history.push(PATH.WALLET_CONVERSION_CREATE_CONVERSION)}>
            New conversion
          </Button>
        </ButtonWrapper>
      </Container>
      <FilterSection>
        <SearchBar
          width="100%"
          placeholder="Search by short reference or conversion id"
          value={searchKeyword}
          onChange={(event) => setSearchKeyword(event.target.value)}
        />
      </FilterSection>
      <FilterSection>
        <SelectWrapper>
          <FilterLabel>Status</FilterLabel>
          <StyledSelect
            isMulti
            width={270}
            value={statusFilter}
            options={conversionStatusOptions}
            onChange={setStatusFilter}
            multiValueLabel="Statuses"
          />
        </SelectWrapper>
        <SelectWrapper>
          <StyledFancyDateRangePicker
            startDate={startDate}
            endDate={endDate}
            dateShortcutValue={dateShortcutValue}
            onDateRangeSelectValueChange={setDateShortValue}
            onApply={applyCalendar}
          />
        </SelectWrapper>
        <SelectWrapper>
          <FilterLabel>From Currency</FilterLabel>
          <StyledSelect width={200} value={sellCurrencyFilter} options={conversionCurrencyOptions} onChange={setSellCurrencyFilter} />
        </SelectWrapper>
        <SelectWrapper>
          <FilterLabel>To Currency</FilterLabel>
          <StyledSelect width={200} value={buyCurrencyFilter} options={conversionCurrencyOptions} onChange={setBuyCurrencyFilter} />
        </SelectWrapper>
      </FilterSection>
      <Table columns={conversionTableColumns} data={data} isLoading={isLoading} onRowClick={handleClickByConversion} />
      <SimplePagination
        pageSize={pageSize}
        pageNumber={pageNumber}
        onPageNumberChange={onPageNumberChange}
        onPageSizeChange={onPageSizeChange}
        noNextPage={noNextPage}
      />
    </Wrapper>
  );
};

export default ConversionActivity;
