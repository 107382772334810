import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Logger from '../../utils/logger';
import { Description } from '../Application/components';
import { UPLOAD_FILES_MODAL } from '../Modal/constants';
import { uploadCompanyFiles } from '@api/modules/dashboard/file';
import { FileRow } from '@components/FileDropzone';
import { COMPANY_FILE_TYPE } from '@constants/file';
import { fetchFilesByType } from '@redux/modules/company/actions';
import { UPLOAD_FILE_REQUEST, UPLOAD_FILE_FAILURE, UPLOAD_FILE_SUCCESS } from '@redux/modules/company/constants';
import { showModal, hideModal } from '@redux/modules/UI/actions';
import { useIsUnableToEditUnderAdminView } from '@redux/selectors';

const Header = styled.div`
  ${(props) => props.theme.text.three};
`;

const UploadLabel = styled.span`
  ${(props) => props.theme.text.link};
`;

const SalesFilesSection = () => {
  const dispatch = useDispatch();
  const unableToSubmitFile = useIsUnableToEditUnderAdminView();
  const files = useSelector((state) => state.company.files[COMPANY_FILE_TYPE.SALES]);

  const submitFiles = async (pendingFiles) => {
    try {
      dispatch({ type: UPLOAD_FILE_REQUEST });
      if (Array.isArray(pendingFiles) && pendingFiles.length > 0) {
        await Promise.all(pendingFiles.map((file) => uploadCompanyFiles(COMPANY_FILE_TYPE.SALES, [file])));
        await fetchFilesByType(COMPANY_FILE_TYPE.SALES);
        dispatch(hideModal());
      }

      dispatch({ type: UPLOAD_FILE_SUCCESS });
    } catch (err) {
      dispatch({ type: UPLOAD_FILE_FAILURE });
      Logger.error(err);
    }
  };

  const handleClick = () => {
    dispatch(
      showModal(UPLOAD_FILES_MODAL, {
        title: 'Upload Sales Reports',
        description:
          'Provide the sales reports (eg. POS/ Payment gateways etc.) with records of last 6 months, more if possible. If you cannot connect other sales or payments channels from our list, we advise you to upload those monthly statements here for funding assessments.',
        onConfirmClick: (data) => submitFiles(data),
        disabled: unableToSubmitFile,
      })
    );
  };

  return (
    <div>
      <Header>Other sales documents</Header>
      <Description>
        Please upload monthly sales reports from other channels. <UploadLabel onClick={handleClick}>Upload report manually</UploadLabel>
      </Description>
      {Array.isArray(files) && files.length > 0 && (
        <div>
          Uploaded documents
          {files.map((f) => (
            <FileRow key={f.attachment_id}>{f.file_name}</FileRow>
          ))}
        </div>
      )}
    </div>
  );
};

export default SalesFilesSection;
