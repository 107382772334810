import React, { useState } from 'react';

import styled, { css } from 'styled-components';

import COLORS from '../styles/colors';

const Wrapper = styled.div`
  border: 1px solid ${COLORS.grayTwo};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px 16px;
  font-size: 15px;
  line-height: 24px;

  ${(props) =>
    props.full &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.hasError &&
    css`
      border: 1px solid ${COLORS.red};
      box-shadow: inset 1px 0 ${COLORS.red}, inset 0 1px ${COLORS.red}, inset -1px 0 ${COLORS.red},
        inset 0 -1px ${COLORS.red};
      color: ${COLORS.red};
    `}

  ${(props) =>
    props.disabled &&
    css`
      background-color: rgba(239, 239, 239, 0.3);
    `}

  :focus-within {
    border: 1px solid ${COLORS.black};
    box-shadow: inset 1px 0 ${COLORS.black}, inset 0 1px ${COLORS.black}, inset -1px 0 ${COLORS.black},
      inset 0 -1px ${COLORS.black};
    color: ${COLORS.black};
  }
`;

const Input = styled.input`
  border: none;
  display: inline;
  vertical-align: middle;
  padding: 0;
  width: calc(100% - 50px);
  box-sizing: border-box;
  box-shadow: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  :focus-visible {
    outline: none;
  }

  :disabled {
    background-color: transparent;
  }

  ::placeholder {
    color: ${COLORS.grayTwo};
  }

  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type='number'] {
    -moz-appearance: textfield;
  }
`;

const Toggle = styled.div`
  display: inline;
  float: right;
  cursor: pointer;
  vertical-align: middle;
  color: ${COLORS.black};
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
`;

const PasswordInput = ({ hasError, disabled, ...restProps }) => {
  const [isVisible, toggleIsVisible] = useState(false);

  const toggle = () => {
    toggleIsVisible(!isVisible);
  };

  return (
    <Wrapper hasError={hasError} disabled={disabled}>
      <Input {...restProps} disabled={disabled} type={isVisible ? 'text' : 'password'} />
      <Toggle onClick={toggle}>{isVisible ? 'Hide' : 'Show'}</Toggle>
    </Wrapper>
  );
};

export default PasswordInput;
