import React from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import FormTitle from '../../../../components/FormTitle';
import PlatformLogo, { LogoWrapper } from '../../../../components/PlatformLogo';
import { SALES_OTHERS_PLATFORM_MODAL } from '../../../Modal/constants';
import { Description, ErrorMessage } from '../../components';
import { SALES_PLATFORM_LOGOS, PLATFORM_DISPLAY_NAME, PLATFORM } from '@constants/platform';
import { showModal } from '@redux/modules/UI/actions';
import { useCheckIsSignUpViaShopify } from '@redux/selectors';
import { filterSalesAccountLogos, isShopifyIntegrationDisabled } from '@utils/platformHelpers';

const SalesOthersPlatformTitle = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => props.theme.text.five};
  font-weight: bold;
`;

const SalesPlatformsSection = ({ salesAccounts, onLogoClick, showError, disabled, afterSubmit }) => {
  const dispatch = useDispatch();
  const isSignUpViaShopify = useCheckIsSignUpViaShopify();

  const showSalesOthersPlatformModal = () => {
    if (!disabled) {
      dispatch(
        showModal(SALES_OTHERS_PLATFORM_MODAL, {
          afterSubmit,
        })
      );
    }
  };

  return (
    <>
      <FormTitle>Your Sales Platforms</FormTitle>
      <Description>
        You will be brought to the respective platforms you have selected to login and link your account to Choco Up.
      </Description>
      {filterSalesAccountLogos(SALES_PLATFORM_LOGOS, salesAccounts).map((platform) => {
        const isDisabled = !platform.enabled || disabled;
        return (
          <PlatformLogo
            value={platform.value}
            disabled={isDisabled}
            onClick={onLogoClick(platform.value, isDisabled)}
            logoUrl={platform.logoUrl}
            message={platform.message}
            displayName={platform.displayName}
          />
        );
      })}
      <LogoWrapper onClick={showSalesOthersPlatformModal} disabled={disabled}>
        <SalesOthersPlatformTitle>{PLATFORM_DISPLAY_NAME.SALES_OTHERS}</SalesOthersPlatformTitle>
      </LogoWrapper>
      <div>
        {showError && (
          <ErrorMessage>Please choose at least one platform from above or tell us your other platforms by selecting “Others”.</ErrorMessage>
        )}
      </div>
    </>
  );
};

export default SalesPlatformsSection;
