import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../../../components/Button';
import FormTitle from '../../../../../../components/FormTitle';
import HR from '../../../../../../components/HR';
import Input from '../../../../../../components/Input';
import Modal from '../../../../../../components/Modal';
import Select from '../../../../../../components/Select';
import CreatableSelect from '../../../../../../components/Select/Creatable';
import Textarea from '../../../../../../components/Textarea';
import Logger from '../../../../../../utils/logger';
import ControlledTextarea from '../../../../../ReactHookForm/ControlledTextarea';
import RHFMoneyInput from '../../../../../ReactHookForm/RHFMoneyInput';
import { generateXeroBankAccountOption, showConfirmWithdrawModal, showConfirmAppealModal, formatBankName } from '../../helpers';
import { getXeroFilterValues } from '@api/modules/dashboard';
import {
  createManualAdjustment,
  editManualAdjustment,
  withdrawSettlementById,
  getSettlementNames,
  appealSettlementById,
} from '@api/modules/dashboard/settlement';
import { FormRow, GridColumn } from '@components/Grid';
import { BANK_SOURCE } from '@constants/bank';
import { AllCurrencyOptions } from '@constants/options';
import { PLATFORM, PLATFORM_DISPLAY_NAME } from '@constants/platform';
import { REVENUE_RECORD_STATUS_VALUE } from '@constants/remittance';
import { setXeroBankAccounts, fetchSettlements } from '@redux/modules/settlements/actions';
import { useIsAdminView, useAcceptedOffer } from '@redux/selectors';
import { findFirstMatchInArray } from '@utils/dataTypes';
import { convertToFullDate } from '@utils/dateHelpers';
import { alwaysPositiveNumber } from '@utils/priceHelpers';
import { useCanAdminHandleSettlement } from '@utils/userHelpers';

const Header = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
  margin-bottom: 14px;
`;

const Instruction = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  margin-bottom: 16px;
`;

const FieldWrapper = styled.div`
  padding: 10px 0;
`;

const accountSourceOptions = [
  { value: PLATFORM.XERO, label: PLATFORM_DISPLAY_NAME.XERO },
  { value: 'OTHER_ACCOUNTS', label: 'Other bank accounts' },
];

const balanceTypeOptions = [
  { value: 'debit', label: 'Debit' },
  { value: 'credit', label: 'Credit' },
];

const AdjustmentModal = ({ settlement = {}, hideModal }) => {
  const dispatch = useDispatch();
  const hasSettlement = !!settlement.settlement_id;
  const [readOnly, toggleReadOnly] = useState(hasSettlement);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      accountSource: settlement.source,
      account: settlement.account_id,
      settlementName: settlement.name,
      adjustmentCurrency: settlement.currency,
      adjustmentAmount: alwaysPositiveNumber(settlement.adjustment_amount),
      comment: settlement.adjustment_note,
      balanceType: settlement.balanceType,
    },
  });
  const [isLoading, toggleIsLoading] = useState(false);
  const [accountOptions, setAccountOptions] = useState([]);
  const [settlementNames, setSettlementNames] = useState([]);
  const isAdmin = useIsAdminView();
  const allBankAccounts = useSelector((state) => state.bank.allBankAccounts);
  const xeroBankAccounts = useSelector((state) => state.settlement.xeroBankAccounts);
  const otherBankAccounts = useSelector((state) => (readOnly ? state.bank.allBankAccounts : state.bank.activeBankAccounts)).filter(
    (value) => value.add_method === BANK_SOURCE.MANUAL
  );
  const userId = useSelector((state) => state.user.profile.id);
  const { applicationId } = useSelector((state) => state.application.data);
  const streams = useSelector((state) => state.fund.streams);
  const activeStream = streams[0];
  const acceptedOffer = useAcceptedOffer();
  const disabled = isLoading || readOnly;
  const accountSourceWatch = watch('accountSource');
  const accountWatch = watch('account');
  const bankAccount = findFirstMatchInArray(allBankAccounts, ({ id }) => accountWatch === id);
  const adminCanHandleSettlement = useCanAdminHandleSettlement();

  const fetchSettlementNames = async () => {
    try {
      const result = await getSettlementNames();
      if (Array.isArray(result)) {
        setSettlementNames([...new Set(result)]);
      }
    } catch (err) {
      Logger.error(err);
    }
  };

  useEffect(() => {
    const fetchApi = async () => {
      if (Array.isArray(xeroBankAccounts) && xeroBankAccounts.length === 0) {
        const { data } = await getXeroFilterValues();
        if (data) {
          dispatch(setXeroBankAccounts(data.BankAccount));
        }
      }
    };

    fetchApi();
  }, []);

  useEffect(() => {
    if (!readOnly) {
      setValue('account', null);
    }
  }, [accountSourceWatch]);

  useEffect(() => {
    const result =
      accountSourceWatch === PLATFORM.XERO
        ? xeroBankAccounts.map(generateXeroBankAccountOption)
        : accountSourceWatch === accountSourceOptions[1].value
        ? otherBankAccounts.map((account) => ({
            value: account.id,
            label: formatBankName(account),
          }))
        : [];
    setAccountOptions(result);
  }, [accountSourceWatch]);

  useEffect(() => {
    const setCurrencyByAccountCurrency = () => {
      if (readOnly) {
        return;
      }
      if (bankAccount?.currency) {
        setValue('adjustmentCurrency', bankAccount?.currency);
        return;
      } else if (accountSourceWatch === PLATFORM.XERO) {
        const xeroAccount = findFirstMatchInArray(xeroBankAccounts, ({ BankAccountID }) => accountWatch === BankAccountID);
        setValue('adjustmentCurrency', xeroAccount?.currency);
        return;
      }

      setValue('adjustmentCurrency', null);
    };

    setCurrencyByAccountCurrency();
  }, [accountSourceWatch, accountWatch]);

  useEffect(() => {
    fetchSettlementNames();
  }, []);

  const handleCreateSettlement = (inputValue) => {
    setValue('settlementName', inputValue);
    setSettlementNames([inputValue, ...settlementNames]);
  };

  const onSubmit = async ({ account, adjustmentAmount, adjustmentCurrency, accountSource, settlementName, comment, balanceType }) => {
    try {
      if (!acceptedOffer) {
        throw new Error('No accepted offer, not ready to create revenue record');
      }
      toggleIsLoading(true);
      if (hasSettlement) {
        const { statusCode } = await editManualAdjustment(settlement.settlement_id, {
          name: settlementName,
          source: accountSource,
          account_id: account,
          adjustment_amount: balanceType === 'credit' ? adjustmentAmount : -adjustmentAmount,
          adjustment_note: comment,
        });

        if (statusCode === 201) {
          await fetchSettlements(isAdmin);
          hideModal();
        }
      } else {
        const { statusCode, ...restProps } = await createManualAdjustment({
          name: settlementName,
          source: accountSource,
          funding_id: applicationId,
          offer_id: acceptedOffer.id,
          stream_id: activeStream?.stream_id,
          account_id: account,
          created_by: userId,
          currency: adjustmentCurrency,
          adjustment_amount: balanceType === 'credit' ? adjustmentAmount : -adjustmentAmount,
          adjustment_note: comment,
        });

        if (statusCode === 201) {
          await fetchSettlements(isAdmin);
          hideModal();
        }
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const withdrawSettlement = async () => {
    try {
      toggleIsLoading(true);

      const { statusCode } = await withdrawSettlementById(settlement.settlement_id);

      if (statusCode === 204) {
        await fetchSettlements(isAdmin);
        hideModal();
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const appealSettlement = async () => {
    try {
      toggleIsLoading(true);

      const appealNote = getValues('appealNote');
      const { statusCode } = await appealSettlementById(settlement.settlement_id, { appeal_note: appealNote ?? '' });

      if (statusCode === 204) {
        await fetchSettlements(isAdmin);
        hideModal();
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const handleWithdrawClick = async (event) => {
    dispatch(showConfirmWithdrawModal(withdrawSettlement));
  };

  const handleCancel = async (event) => {
    if (hasSettlement) {
      toggleReadOnly(true);
    } else {
      hideModal();
    }
  };

  const editManualRecord = (event) => {
    event.preventDefault();
    toggleReadOnly(false);
  };

  const handleAppealClick = async (event) => {
    dispatch(showConfirmAppealModal(appealSettlement));
  };

  return (
    <Modal>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Header>Add a manual revenue record</Header>
          {isAdmin && (
            <Instruction>
              Revenue settlement records will be sent to the client for review. The record will be confirmed automatically after 2 days if
              there is not dispute.
            </Instruction>
          )}
          <FieldWrapper>
            <FormTitle scale={5} required>
              Bank Account Source
            </FormTitle>
            <Controller
              name="accountSource"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return <Select {...field} options={accountSourceOptions} hasError={errors.accountSource} disabled={disabled} />;
              }}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FormTitle scale={5} required>
              Bank Account
            </FormTitle>
            <Controller
              name="account"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return <Select {...field} options={accountOptions} hasError={errors.account} disabled={disabled} />;
              }}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FormTitle scale={5} required>
              Revenue Record Name
            </FormTitle>
            <Controller
              name="settlementName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                if (readOnly) {
                  return <Input {...field} disabled={disabled} full />;
                }
                return (
                  <CreatableSelect
                    {...field}
                    options={settlementNames.map((name) => ({
                      value: name,
                      label: name,
                    }))}
                    onCreateOption={handleCreateSettlement}
                    hasError={errors.settlementName}
                    disabled={disabled}
                    placeholder="Create a record name"
                  />
                );
              }}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FormTitle scale={5} required>
              Type
            </FormTitle>
            <Controller
              name="balanceType"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return <Select {...field} options={balanceTypeOptions} hasError={errors.balanceType} disabled={disabled} />;
              }}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FormTitle scale={5} required>
              Amount
            </FormTitle>
            <RHFMoneyInput
              control={control}
              prefix="adjustment"
              selectProps={{
                options: AllCurrencyOptions,
                disabled: disabled || bankAccount?.currency !== null,
              }}
              inputProps={{
                disabled,
              }}
              amountRules={{ required: true, min: 0 }}
              errors={errors}
              full
            />
          </FieldWrapper>
          <FieldWrapper>
            <FormTitle scale={5}>Comment</FormTitle>
            <Controller
              name="comment"
              control={control}
              render={({ field }) => {
                return (
                  <Textarea
                    {...field}
                    placeholder={disabled ? '' : 'E.g. Extra revenue'}
                    hasError={errors.comment}
                    disabled={disabled}
                    full
                  />
                );
              }}
            />
          </FieldWrapper>
          {readOnly ? (
            <FormRow>
              {settlement.status === REVENUE_RECORD_STATUS_VALUE.DRAFT && isAdmin && adminCanHandleSettlement && (
                <>
                  <GridColumn lg={2} md={6} sm={6}>
                    <Button onClick={handleWithdrawClick} secondary warning>
                      Withdraw
                    </Button>
                  </GridColumn>
                  <GridColumn lg={2} md={6} sm={6}>
                    <Button onClick={editManualRecord} tertiary>
                      Edit
                    </Button>
                  </GridColumn>
                </>
              )}
              {settlement.status === REVENUE_RECORD_STATUS_VALUE.APPEALING && isAdmin && adminCanHandleSettlement && (
                <GridColumn lg={2} md={6} sm={6}>
                  <Button onClick={handleWithdrawClick} secondary warning>
                    Withdraw
                  </Button>
                </GridColumn>
              )}
              {settlement.status === REVENUE_RECORD_STATUS_VALUE.SUBMITTED && !isAdmin && (
                <>
                  <HR margin={12} />

                  <FieldWrapper>
                    <FormTitle scale={5} required>
                      Appeal note
                    </FormTitle>
                    <Instruction>
                      A 2-day window for appealing is allowed.
                      <br />
                      {`The record was created on ${convertToFullDate(
                        settlement.date_created
                      )} so that it will confirmed automatically`}{' '}
                      <b>{`${moment(settlement.date_created).add(2, 'day').from(settlement.date_created)}`}</b>
                    </Instruction>
                    <ControlledTextarea
                      control={control}
                      name="appealNote"
                      rules={{ maxLength: 1000 }}
                      placeholder="Write the reason why you want to appeal the settlement"
                      full
                    />
                  </FieldWrapper>
                  <Button onClick={handleAppealClick} secondary warning>
                    Appeal
                  </Button>
                </>
              )}
              <GridColumn lg={3} md={6} sm={6} />
            </FormRow>
          ) : (
            <FormRow>
              <GridColumn lg={6} md={3} sm={6}>
                <Button onClick={handleCancel} disabled={isLoading} secondary full>
                  Cancel
                </Button>
              </GridColumn>
              <GridColumn lg={6} md={3} sm={6}>
                <Button type="submit" disabled={isLoading} full>
                  Confirm
                </Button>
              </GridColumn>
            </FormRow>
          )}
        </form>
      </Modal>
  );
};

export default AdjustmentModal;
