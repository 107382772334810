import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import CheckBox, { CheckboxText } from '../../../../components/Checkbox';
import { Description } from '../../../Application/components';
import { MANUAL_INTEGRATION_FORM } from '../../../Modal/constants';
import { GridColumn } from '@components/Grid';
import { DD_EMAIL } from '@constants/email';
import { PLATFORM, PLATFORM_DISPLAY_NAME } from '@constants/platform';
import { showModal, hideModal } from '@redux/modules/UI/actions';

const Title = styled.div`
  ${(props) => props.theme.text.two};
  margin: 10px 0 20px 0;
`;

const Link = styled.a`
  ${(props) => props.theme.text.link};
  text-decoration: underline;
  font-weight: bold;
`;
const InstructionTitle = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: bold;
  margin: 20px 0;
`;

const InstructionsImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

const ZOHOBOOKS = () => {
  const dispatch = useDispatch();
  const [isChecked, toggleIsChecked] = useState(false);

  const handleIntegrationClick = () => {
    dispatch(
      showModal(MANUAL_INTEGRATION_FORM, {
        platformName: PLATFORM.ZOHOBOOKS,
      })
    );
  };

  return (
    <>
      <Title>Connect {PLATFORM_DISPLAY_NAME.ZOHOBOOKS} Account</Title>
      <Description>Please follow below steps to add access to your account</Description>
      <InstructionTitle>Step 1</InstructionTitle>
      <Description>
        Sign in to your{' '}
        <Link href="https://books.zoho.com/app" target="_blank">
          Zoho Books Online Account
        </Link>
      </Description>
      <Description>
        Click on the <b>Settings</b> icon and select <b>Users & Roles</b>
      </Description>
      <InstructionsImage
        src="https://choco-up-asset.s3.ap-southeast-1.amazonaws.com/tutorial/Zohobooks/Zohobooks-1.png"
        alt="Connect Zoho Books Account"
      />
      <InstructionTitle>Step 2</InstructionTitle>
      <Description>
        Select <b>Roles</b> tab and create <b>New role</b>
      </Description>
      <InstructionsImage
        src="https://choco-up-asset.s3.ap-southeast-1.amazonaws.com/tutorial/Zohobooks/Zohobooks-2.png"
        alt="Connect Zoho Books Account"
      />
      <InstructionTitle>Step 3</InstructionTitle>
      <Description>
        For Role Name please set as <b>View Only</b>
      </Description>
      <InstructionsImage
        src="https://choco-up-asset.s3.ap-southeast-1.amazonaws.com/tutorial/Zohobooks/Zohobooks-3.png"
        alt="Connect Zoho Books Account"
      />
      <Description>
        For <b>Contacts,</b> please uncheck Full Access for all fields
      </Description>
      <InstructionsImage
        src="https://choco-up-asset.s3.ap-southeast-1.amazonaws.com/tutorial/Zohobooks/Zohobooks-4.png"
        alt="Connect Zoho Books Account"
      />
      <Description>
        For <b>Items,</b> please uncheck Full Access and check <b>View</b>
      </Description>
      <InstructionsImage
        src="https://choco-up-asset.s3.ap-southeast-1.amazonaws.com/tutorial/Zohobooks/Zohobooks-5.png"
        alt="Connect Zoho Books Account"
      />
      <Description>
        For <b>Banking,</b> please uncheck Full Access and check <b>View</b>
      </Description>
      <InstructionsImage
        src="https://choco-up-asset.s3.ap-southeast-1.amazonaws.com/tutorial/Zohobooks/Zohobooks-6.png"
        alt="Connect Zoho Books Account"
      />
      <Description>
        For <b>Sales,</b> please uncheck Full Access and check <b>View</b>
      </Description>
      <InstructionsImage
        src="https://choco-up-asset.s3.ap-southeast-1.amazonaws.com/tutorial/Zohobooks/Zohobooks-7.png"
        alt="Connect Zoho Books Account"
      />
      <Description>
        For <b>Purchases,</b> please uncheck Full Access and check <b>View</b>
      </Description>
      <InstructionsImage
        src="https://choco-up-asset.s3.ap-southeast-1.amazonaws.com/tutorial/Zohobooks/Zohobooks-15.png"
        alt="Connect Zoho Books Account"
      />
      <Description>
        For <b>Accountant,</b> please uncheck full access and check <b>View</b>
      </Description>
      <InstructionsImage
        src="https://choco-up-asset.s3.ap-southeast-1.amazonaws.com/tutorial/Zohobooks/Zohobooks-8.png"
        alt="Connect Zoho Books Account"
      />
      <Description>
        For <b>Timesheets,</b> please uncheck Full Access
      </Description>
      <InstructionsImage
        src="https://choco-up-asset.s3.ap-southeast-1.amazonaws.com/tutorial/Zohobooks/Zohobooks-9.png"
        alt="Connect Zoho Books Account"
      />
      <Description>
        For <b>Documents,</b> please only check <b>View Documents</b>
      </Description>
      <InstructionsImage
        src="https://choco-up-asset.s3.ap-southeast-1.amazonaws.com/tutorial/Zohobooks/Zohobooks-10.png"
        alt="Connect Zoho Books Account"
      />
      <Description>
        For <b>Settings,</b> please only check <b>Export data</b>
      </Description>
      <InstructionsImage
        src="https://choco-up-asset.s3.ap-southeast-1.amazonaws.com/tutorial/Zohobooks/Zohobooks-11.png"
        alt="Connect Zoho Books Account"
      />
      <Description>
        For <b>Dashboard,</b> please check all boxes
      </Description>
      <InstructionsImage
        src="https://choco-up-asset.s3.ap-southeast-1.amazonaws.com/tutorial/Zohobooks/Zohobooks-12.png"
        alt="Connect Zoho Books Account"
      />
      <Description>
        For <b>Enable Full Access for All Reports,</b> please uncheck Full Access. Under <b>Business Overview & Sales</b> dropdown, please
        check all boxes for <b>View & Export,</b> <b>click Save</b>
      </Description>
      <InstructionsImage
        src="https://choco-up-asset.s3.ap-southeast-1.amazonaws.com/tutorial/Zohobooks/Zohobooks-13.png"
        alt="Connect Zoho Books Account"
      />
      <Description>
        Once you return to the Roles tab, you should see <b>View Only</b> as one of the roles listed
      </Description>
      <InstructionsImage
        src="https://choco-up-asset.s3.ap-southeast-1.amazonaws.com/tutorial/Zohobooks/Zohobooks-16.png"
        alt="Connect Zoho Books Account"
      />
      <InstructionTitle>Step 4</InstructionTitle>
      <Description>
        Click <b>All Users</b> tab, and select <b>New User</b>
      </Description>
      <Description>
        Please use information below to fill in new user form
        <ul>
          <li>Email: {DD_EMAIL}</li>
          <li>Name: Choco Up</li>
          <li>Role: View Only</li>
        </ul>
        <b>Click Send</b>
      </Description>
      <InstructionsImage
        src="https://choco-up-asset.s3.ap-southeast-1.amazonaws.com/tutorial/Zohobooks/Zohobooks-14.png"
        alt="Connect Zoho Books Account"
      />
      <GridColumn lg={12} md={12}>
        <CheckBox onClick={() => toggleIsChecked(!isChecked)} />
        <CheckboxText>Confirm I have connected with my {PLATFORM_DISPLAY_NAME.ZOHOBOOKS} account</CheckboxText>
      </GridColumn>
      <GridColumn lg={6} md={6}>
        <Button onClick={handleIntegrationClick} disabled={!isChecked} primary full>
          Next
        </Button>
      </GridColumn>
      <GridColumn lg={6} md={6}>
        <Button onClick={() => dispatch(hideModal())} tertiary full>
          Cancel
        </Button>
      </GridColumn>
    </>
  );
};

export default ZOHOBOOKS;
