import {
  SWITCH_TO_ADMIN_VIEW,
  SWITCH_TO_USER_VIEW,
  SHOW_MODAL,
  HIDE_MODAL,
} from './constants';

export const switchToAdminView = () => ({
  type: SWITCH_TO_ADMIN_VIEW,
});

export const switchToUserView = () => ({
  type: SWITCH_TO_USER_VIEW,
});

export const showModal = (type, modalProps?: any) => ({
  type: SHOW_MODAL,
  payload: {
    type,
    modalProps,
  },
});

export const hideModal = () => ({
  type: HIDE_MODAL,
});
