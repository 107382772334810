import React from 'react';

import styled, { css } from 'styled-components';

const NavIndexWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: ${(props) => (props.highlight ? props.theme.colors.purple : props.theme.colors.grayTwo)};
  color: ${(props) => props.theme.colors.white};
`;

const NavIndexContainer = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
`;

const NavIndex = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
`;

const StepIndicator = ({ highlight, stepNumber, showTick, showStepNumber, className }) => {
  return (
    <NavIndexWrapper highlight={highlight} className={className}>
      <NavIndexContainer>
        <NavIndex>
          {showTick ? <img src="/icons/tick.svg" alt={stepNumber} /> : showStepNumber && stepNumber ? stepNumber : null}
        </NavIndex>
      </NavIndexContainer>
    </NavIndexWrapper>
  );
};

export default StepIndicator;
