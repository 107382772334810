import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import Arrow from '../../assets/icons/Arrow';
import Button from '../../components/Button';
import Dropdown, { DropdownOption } from '../../components/Dropdown';
import Table from '../../components/Table';
import { BANK_ACCOUNT_MODAL, MESSAGE_MODAL, CONFIRM_DIALOG } from '../Modal/constants';
import { deleteBankAccountById } from '@api/modules/application';
import { generateFinverseUrl, refreshFinverseAccount } from '@api/modules/integration/finverse';
import { COMPANY_FILE_TYPE } from '@constants/file';
import { fetchBankAccounts } from '@redux/modules/bank/actions';
import { showModal, hideModal } from '@redux/modules/UI/actions';

const columns = [
  {
    Header: 'Institution',
    accessor: 'channel_institution_name',
    Cell: ({ value }) => {
      return value;
    },
  },
  {
    Header: 'Portal name',
    accessor: 'channel_portal_name',
    Cell: ({ value }) => {
      return value;
    },
  },
  {
    Header: 'Countries',
    accessor: 'countries',
    Cell: ({ value }) => {
      return value.join(', ');
    },
  },
  {
    Header: 'Bank Statements',
    accessor: 'statements',
    Cell: ({ value }) => {
      return Array.isArray(value) && value.length > 0 ? 'Uploaded' : 'Missing Statements';
    },
    getStyle: ({ value }) => css`
      &.td {
        color: ${Array.isArray(value) && value.length > 0
          ? (props) => props.theme.colors.green
          : (props) => props.theme.colors.red};
      }
    `,
  },
  // {
  //   Header: 'Upload',
  //   accessor: 'bank_statements',
  //   Cell: ({ value }) => {
  //     return value;
  //   },
  // },
  {
    // Header: 'Update All',
    accessor: 'institution_id',
    Cell: ({ value }) => {
      const connectFinverse = async () => {
        await refreshFinverseAccount(value);
      };

      return (
        <Button onClick={connectFinverse} small>
          Update
        </Button>
      );
    },
    getStyle: () => css`
      &.th {
        color: ${(props) => props.theme.colors.orange};
      }
    `,
  },
  // {
  //   Header: '',
  //   accessor: 'collapse',
  //   Cell: ({ value }) => {
  //     return <Arrow />;
  //   },
  //   getStyle: () => css`
  //     /* width: 5px; */
  //   `,
  // },
];

const accountColumns = [
  {
    Header: '',
    accessor: 'index',
    Cell: ({ value, index }) => {
      return `${index + 1}.`;
    },
    getStyle: () => css`
      width: 5px;
    `,
  },
  {
    Header: 'Account name',
    accessor: 'accountName',
  },
  {
    Header: 'Account no.',
    accessor: 'accountNumber',
  },
  {
    Header: 'Currency',
    accessor: 'currency',
    getStyle: () => css`
      width: 100px;
    `,
  },
  {
    Header: 'Balance',
    accessor: 'balance',
    getStyle: () => css`
      width: 100px;
      text-align: right;
    `,
  },
  {
    Header: 'Manage',
    accessor: 'id',
    getStyle: () => css`
      > div {
        vertical-align: middle;
      }
    `,
    Cell: ({ value, row }) => {
      const dispatch = useDispatch();
      const handleEdit = () => {
        dispatch(
          showModal(BANK_ACCOUNT_MODAL, {
            isEditingBank: true,
            editingBank: row,
            disabled: true,
          }),
        );
      };

      const handleDelete = () => {
        const deleteAccount = async () => {
          await deleteBankAccountById(value);
          fetchBankAccounts();
          dispatch(hideModal());
        };

        dispatch(
          showModal(MESSAGE_MODAL, {
            type: CONFIRM_DIALOG,
            title: 'Are you sure to delete the account?',
            rightButtonProps: {
              label: 'Yes, I want to delete',
            },
            leftButtonProps: {
              label: 'No',
            },
            onConfirm: deleteAccount,
          }),
        );
      };

      return (
        <Dropdown>
          <DropdownOption onClick={handleEdit}>View details</DropdownOption>
          {/* <DropdownOption onClick={handleDelete} warning>
            Delete
          </DropdownOption> */}
        </Dropdown>
      );
    },
  },
];

const AccountTableWrapper = styled.div`
  /* margin: 0 0 0 24px; */
`;

const StyledTable = styled(Table)`
  > tbody {
    background-color: ${(props) => props.theme.colors.grayOne};

    > tr > td {
      padding: 8px 16px;
    }
  }
`;

const AccountTable = styled(Table)`
  th {
    ${(props) => props.theme.text.micro};
  }
`;

const FinverseBankAccountTable = () => {
  const integratedInstitutions = useSelector((state) => state.integration.finverse.integratedInstitutions);
  const activeBankAccounts = useSelector((state) => state.bank.activeBankAccounts);
  const files = useSelector((state) => state.company.files[COMPANY_FILE_TYPE.BANK_STATEMENT_VIA_FINVERSE]) || [];

  const data = integratedInstitutions.map((institution) => {
    return {
      ...institution,
      accounts: activeBankAccounts.filter(
        ({ channelAccountId }) => channelAccountId === institution.channel_institution_id,
      ),
      statements: files.filter(({ custom_id }) => custom_id === institution.channel_account_id),
    };
  });

  const CollapseContent = ({ accounts }) => {
    return (
      <AccountTableWrapper>
        {/* <Arrow /> */}
        <AccountTable data={accounts} columns={accountColumns} />
      </AccountTableWrapper>
    );
  };
  return <StyledTable data={data} columns={columns} CollapseContent={CollapseContent} />;
};

export default FinverseBankAccountTable;
