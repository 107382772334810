import React from 'react';

import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import isAfterDay from 'react-dates/lib/utils/isAfterDay';
import styled, { css } from 'styled-components';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import COLORS from '../styles/colors';
import { useModal } from '@hooks/UI';

const Wrapper = styled.div`
  ${(props) =>
    props.full &&
    css`
      > div {
        width: 100%;
      }

      .SingleDatePickerInput_1,
      .DateInput {
        width: 100%;
      }
      .DateInput_input_1 {
        box-sizing: border-box;
      }
    `}

  input {
    border: 1px solid ${COLORS.grayTwo};
    border-radius: 5px;
    font-size: 14px;
    padding: 12px 16px 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;

    ::placeholder {
      color: ${COLORS.grayTwo};
    }
  }

  ${(props) =>
    props.hasError &&
    css`
      input {
        border: 1px solid ${COLORS.red};
        box-shadow: inset 1px 0 ${COLORS.red}, inset 0 1px ${COLORS.red}, inset -1px 0 ${COLORS.red}, inset 0 -1px ${COLORS.red};
        color: ${COLORS.red};
      }
    `}
  ${(props) =>
    props.highlight &&
    css`
      input {
        border: 1px solid ${COLORS.green};
        box-shadow: inset 1px 0 ${COLORS.green}, inset 0 1px ${COLORS.green}, inset -1px 0 ${COLORS.green}, inset 0 -1px ${COLORS.green};
      }
    `}

  input:focus {
    border: 1px solid ${COLORS.black};
    box-shadow: inset 1px 0 ${COLORS.black}, inset 0 1px ${COLORS.black}, inset -1px 0 ${COLORS.black}, inset 0 -1px ${COLORS.black};
    color: ${COLORS.black};
  }

  div {
    :focus-visible {
      outline: none;
    }
  }

  .CalendarDay__selected {
    background: ${(props) => props.theme.colors.purple};
    border: 1px double ${(props) => props.theme.colors.purple};
  }

  .DayPickerKeyboardShortcuts_buttonReset {
    display: none;
  }
`;

const DatePicker = ({
  value,
  onChange,
  full = true,
  hasError,
  highlight,
  placeholder = 'DD/MM/YYYY',
  displayFormat = 'DD/MM/YYYY',
  ...restProps
}) => {
  const [focused, toggle] = useModal();
  return (
    <Wrapper full={full} hasError={hasError} highlight={highlight}>
      <SingleDatePicker
        date={value}
        onDateChange={onChange}
        numberOfMonths={1}
        focused={focused}
        onFocusChange={toggle}
        placeholder={placeholder}
        displayFormat={displayFormat}
        noBorder
        {...restProps}
      />
    </Wrapper>
  );
};

export default DatePicker;
