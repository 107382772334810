import React, { useState, useEffect, useContext } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import Dropdown, { DropdownOption } from '../../components/Dropdown';
import Icon from '../../components/Icon';
import Logger from '../../utils/logger';
import { MESSAGE_MODAL, CONFIRM_DIALOG, BANK_STATEMENT_MODAL } from '../Modal/constants';
import { deleteBankAccountById } from '@api/modules/application';
import { uploadCompanyFiles } from '@api/modules/dashboard/file';
import { FormRow, GridColumn } from '@components/Grid';
import { BANK_SOURCE } from '@constants/bank';
import { COMPANY_FILE_TYPE } from '@constants/file';
import { UPLOAD_FILE_REQUEST, UPLOAD_FILE_FAILURE, UPLOAD_FILE_SUCCESS } from '@redux/modules/company/constants';
import { showModal, hideModal } from '@redux/modules/UI/actions';

const Wrapper = styled.div`
  margin-bottom: 30px;
  line-height: 24px;
`;

const HeaderRow = styled(FormRow)`
  color: ${(props) => props.theme.colors.grayTwo};
`;

const BankRow = styled(FormRow)`
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
`;

const MissingStatementStatus = styled.div`
  color: ${(props) => props.theme.colors.red};
`;

const UploadedStatus = styled.div`
  color: ${(props) => props.theme.colors.green};
`;

const BankStatementStatus = ({ status }) => {
  switch (status) {
    case 'UPLOADED':
      return <UploadedStatus>Uploaded</UploadedStatus>;
    default:
      return <MissingStatementStatus>Missing Statements</MissingStatementStatus>;
  }
};

const Label = styled.div`
  ${(props) => props.theme.text.micro};
  ${(props) => props.theme.ellipsis};
`;

const BankAccountRow = ({ account, onEditClick, onRemoveClick, showFileUploadModal, disabled }) => {
  return (
    <BankRow>
        <GridColumn lg={3} md={2}>
          <Label>{account.add_method === BANK_SOURCE.XERO ? account.accountName : account.bankName}</Label>
        </GridColumn>
        <GridColumn lg={3} md={4}>
          <Label>{account.accountNumber || account.accountName}</Label>
        </GridColumn>
        <GridColumn lg={4} md={4}>
          <Label>
            <BankStatementStatus status={account.attachmentCount > 0 ? 'UPLOADED' : account.status} />
          </Label>
        </GridColumn>
        <GridColumn lg={1} md={1}>
          <Icon src="/icons/upload_cloud.svg" alt="upload" onClick={showFileUploadModal(account.id)} />
        </GridColumn>
        <GridColumn lg={1} md={1}>
          <Dropdown>
            <DropdownOption onClick={() => onEditClick(account.id)}>Edit Account</DropdownOption>
            {account.add_method === BANK_SOURCE.MANUAL && (
              <DropdownOption onClick={() => onRemoveClick(account.id)} warning>
                Delete Account
              </DropdownOption>
            )}
          </Dropdown>
        </GridColumn>
      </BankRow>
  );
};

const BankAccountDetailsTable = ({ accounts, onEditClick, fetchBankAccounts, disabled }) => {
  const dispatch = useDispatch();
  const bankAccounts = useSelector((state) => state.bank.activeBankAccounts);
  const [uploadedFiles, setUploadedFiles] = useState({});

  const handleSubmitFiles = (id) => async (files) => {
    try {
      dispatch({ type: UPLOAD_FILE_REQUEST });
      const nextFiles = files.concat(uploadedFiles[id]).filter((f) => !!f);
      await Promise.all(
        files.map(async (file) => await uploadCompanyFiles(COMPANY_FILE_TYPE.BANK_STATEMENT, [file], id)),
      );
      setUploadedFiles({
        ...uploadedFiles,
        [id]: nextFiles,
      });

      await fetchBankAccounts();
      dispatch({ type: UPLOAD_FILE_SUCCESS });
    } catch (err) {
      dispatch({ type: UPLOAD_FILE_FAILURE });
      Logger.error(err);
    }
  };

  const showFileUploadModal = (id) => () => {
    dispatch(
      showModal(BANK_STATEMENT_MODAL, {
        bankId: id,
        onSubmitClick: handleSubmitFiles(id),
        disabled,
      }),
    );
  };

  const confirmRemoveBank = (id) => {
    const deleteAccount = async () => {
      await deleteBankAccountById(id);
      fetchBankAccounts();
      dispatch(hideModal());
    };

    dispatch(
      showModal(MESSAGE_MODAL, {
        type: CONFIRM_DIALOG,
        title: 'Are you sure to delete the account?',
        rightButtonProps: {
          label: 'Yes, I want to delete',
          disabled,
        },
        leftButtonProps: {
          label: 'No',
        },
        onConfirm: deleteAccount,
      }),
    );
  };

  return (
    <Wrapper>
      <HeaderRow>
        <GridColumn lg={3} md={2}>
          Bank
        </GridColumn>
        <GridColumn lg={3} md={4}>
          Account No./Name
        </GridColumn>
        <GridColumn lg={4} md={4}>
          Bank Statements
        </GridColumn>
        <GridColumn lg={1} md={1}>
          Upload
        </GridColumn>
        <GridColumn lg={1} md={1}>
          Manage
        </GridColumn>
      </HeaderRow>
      {accounts.map((account) => (
        <BankAccountRow
          key={account.id}
          account={account}
          uploadedFiles={uploadedFiles[account.id]}
          onEditClick={onEditClick}
          onRemoveClick={confirmRemoveBank}
          showFileUploadModal={showFileUploadModal}
          disabled={disabled}
        />
      ))}
    </Wrapper>
  );
};

export default BankAccountDetailsTable;
