import { INVOICE_PAYMENT_METHOD } from '@constants/remittance';

export const paymentMethodOptions = [
  {
    value: INVOICE_PAYMENT_METHOD.BANK,
    label: 'Bank transfer',
  },
  {
    value: INVOICE_PAYMENT_METHOD.AIRWALLEX,
    label: 'AirWallex',
  },
];

export const REVENUE_FORM_PATH = 'revenue-based-invoice';
export const ONE_TIME_FORM_PATH = 'one-time-invoice';

export const REVENUE_FORM_NAME = 'REVENUE_BASED';
export const ONE_TIME_FORM_NAME = 'ONE_TIME_BASED';

export const formOptions = [
  {
    value: REVENUE_FORM_NAME,
    label: 'Revenue based invoice',
    description: 'Add a revenue amount and the system will generate the invoice amount based on the remittance rate.',
    path: REVENUE_FORM_PATH,
  },
  {
    value: ONE_TIME_FORM_NAME,
    label: 'One time invoice',
    description: 'Add a invoice amount directly.',
    path: ONE_TIME_FORM_PATH,
  },
];
