import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import Logger from '../../../../utils/logger';
import { acceptOfferById } from '@api/modules/application';
import { FormRow, GridColumn } from '@components/Grid';
import { STATUS_CODE } from '@constants/application';
import { OFFER_CONDITION } from '@constants/offer';
import { fetchApplication } from '@redux/modules/application/actions';
import { hideModal } from '@redux/modules/UI/actions';
import { useSelectedCompany, useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';
import { convertToFullDate } from '@utils/dateHelpers';
import { formatPrice, formatPercent } from '@utils/priceHelpers';
import { fetchUserOffers } from '@redux/modules/offer/actions';

const Header = styled.div`
  ${(props) => props.theme.text.three};
  margin-bottom: 40px;
`;

const AmountText = styled.div`
  ${(props) => props.theme.text.four};
  margin-bottom: 40px;
`;

const Amount = styled.span`
  font-weight: 700;
  color: ${(props) => props.theme.colors.purple};
`;

const FundingTermsLabel = styled.div`
  ${(props) => props.theme.text.button};
`;

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.error};
`;

const Paragraph = styled.div`
  margin-bottom: 40px;
`;

const AcceptanceModal = ({ offer }) => {
  const isAdminViewingAnotherCompany = useCheckIsAdminViewingAnotherCompany();
  const dispatch = useDispatch();
  const company = useSelectedCompany();
  const applicationData = useSelector((state) => state.application.data);
  const [isLoading, toggleIsLoading] = useState(false);
  const [apiError, setApiError] = useState();
  const disabled =
    applicationData.statusCode !== STATUS_CODE.PENDING_USER_REVIEW_TERM_SHEET ||
    offer.condition !== OFFER_CONDITION.PENDING ||
    isAdminViewingAnotherCompany;

  const handleAccept = async () => {
    try {
      toggleIsLoading(true);
      const { error, statusCode } = await acceptOfferById(applicationData.applicationId, offer.id);
      if (statusCode !== 200) {
        throw new Error(error || 'Something went wrong');
      }
      await fetchUserOffers(applicationData.applicationId);
      await dispatch(hideModal());
    } catch (err) {
      setApiError(err?.message);
      Logger.error(err);
    } finally {
      await fetchApplication();
      toggleIsLoading(false);
    }
  };

  const handleCancel = () => {
    dispatch(hideModal());
  };

  return (
    <Modal canHideModal={!isLoading} hideModalOnClickOutside>
      <>
        <Header>Review and accept terms</Header>
        <AmountText>
          You are requesting <Amount>{formatPrice(offer.purchasedPriceCurrency, offer.purchasedPriceAmount, false)}</Amount> in funding from
          Choco Up.
        </AmountText>
        <FundingTermsLabel>Funding Terms</FundingTermsLabel>
        <ul>
          <li>
            {'I confirm and agree that if my funding request for '}
            <b>{formatPrice(offer.purchasedPriceCurrency, offer.purchasedPriceAmount, false)}</b>
            {' is approved, I will remit back a total of '}
            <b>{formatPrice(offer.purchasedReceivablesCurrency, offer.purchasedReceivablesAmount, false)}</b>
            {' to Choco Up.'}
          </li>
          <li>
            {'I understand that each month, '}
            <b>{formatPercent(offer.retrievalPercentage, 2)}</b>
            {' of my business’s total revenue will be remitted to Choco Up’s designated bank account, with the first remittance made on '}
            <b>{convertToFullDate(offer.firstRemittanceDate)}</b>.
          </li>
          <li>
            I confirm and agree that the bank account provided for receiving funding and for remittance is a business bank account, and that
            it is used for business purpose only. I also confirm and agree that I will use the funding for business purposes only.
          </li>
          <li>
            I am authorized to enter into the Sales and Purchase Agreement with Choco Up, and am the owner of{' '}
            <b>{company.legalCompanyName || company.companyName}</b>.
          </li>
          <li>
            I confirm and agree that the proposal set out in this document does not constitute an offer of or invitation for an offer of
            servicing facilities, the provision of which is subject to our due diligence and internal approval procedures. A formal offer
            contract will only be issued after such approval has been obtained.
          </li>
        </ul>
        <Paragraph>
          By selecting “Accept Terms” I confirm that I have read and agree to above terms and submit the application for the preparation of
          agreement.
        </Paragraph>
        {apiError && <ErrorMessage>{apiError}</ErrorMessage>}
        <FormRow>
          <GridColumn lg={6} md={6}>
            <Button onClick={handleAccept} disabled={disabled} full>
              Accept Terms
            </Button>
          </GridColumn>
          <GridColumn lg={6} md={6}>
            <Button onClick={handleCancel} secondary full>
              Cancel
            </Button>
          </GridColumn>
        </FormRow>
      </>
    </Modal>
  );
};

export default AcceptanceModal;
