import React from 'react';

import styled, { css } from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const ContentWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Overlay = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.colors.black};
  opacity: 0.5;
  width: 100%;
  height: 100%;
`;

const Mask = ({ children, Placeholder, active }) => {
  return (
    <Container>
        {!active && (
          <>
            <ContentWrapper>{Placeholder}</ContentWrapper>
            <Overlay />
          </>
        )}
        {children}
      </Container>
  );
};

export default Mask;
