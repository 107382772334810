import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Logger from '../../../../utils/logger';
import FileTable from '../../../File/FileTable';
import { UPLOAD_FILES_MODAL } from '../../../Modal/constants';
import { Card } from "../../components";
import { getAllCompanyFiles, uploadCompanyFiles, deleteFileById } from '@api/modules/dashboard/file';
import { COMPANY_FILE_TYPE } from '@constants/file';
import { useSelectRow } from '@hooks/UI';
import { UPLOAD_FILE_REQUEST, UPLOAD_FILE_FAILURE, UPLOAD_FILE_SUCCESS } from '@redux/modules/company/constants';
import { showModal } from '@redux/modules/UI/actions';
import { useIsUnableToEditUnderAdminView } from '@redux/selectors';

const UploadedDocumentsHeader = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: bold;
`;

const FileManagement = () => {
  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);
  const [apiError, setApiError] = useState('');
  const unableToSubmitFile = useIsUnableToEditUnderAdminView();
  const isDisabled = unableToSubmitFile;
  const allFileIds = files.map(({ attachment_id }) => attachment_id);
  const { selectedIds, areAllChecked, toggleSelectAll, toggleSelectRow } = useSelectRow(allFileIds);

  const fetchAllCompanyFiles = async () => {
    const { statusCode, data } = await getAllCompanyFiles();

    if (statusCode === 200 && Array.isArray(data)) {
      setFiles(data);
    }
  };

  useEffect(() => {
    fetchAllCompanyFiles();
  }, []);

  const submitOtherFiles = async (otherFiles) => {
    try {
      dispatch({ type: UPLOAD_FILE_REQUEST });
      if (Array.isArray(otherFiles) && otherFiles.length > 0) {
        await Promise.all(otherFiles.map((file) => uploadCompanyFiles(COMPANY_FILE_TYPE.OTHER, [file])));
        await fetchAllCompanyFiles();
      }

      dispatch({ type: UPLOAD_FILE_SUCCESS });
    } catch (err) {
      dispatch({ type: UPLOAD_FILE_FAILURE });
      setApiError(err);
      Logger.error(err);
    }
  };

  const deleteFile = async (id) => {
    try {
      const { statusCode } = await deleteFileById(id);
      if (statusCode === 200) {
        await fetchAllCompanyFiles();
      }
    } catch (err) {
      setApiError(err);
      Logger.error(err);
    }
  };

  const showFileModal = () => {
    dispatch(
      showModal(UPLOAD_FILES_MODAL, {
        title: 'Upload other documents',
        description:
          'Please upload any additional documents that can help our investment team better understand your business and company operations, eg. organization chart, shareholdings structure, memorandum of associations (company articles), business plans, projections, key agreements, investment deck etc.',
        onConfirmClick: (data) => submitOtherFiles(data),
        disabled: isDisabled,
      })
    );
  };

  return (
    <>
      <UploadedDocumentsHeader>Your Uploaded Documents</UploadedDocumentsHeader>
      <Card>
        <FileTable files={files} fetchFiles={fetchAllCompanyFiles} disabled={isDisabled} title="Manage Uploads" />
      </Card>
    </>
  );
};

export default FileManagement;
