import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useTable } from 'react-table';
import styled from 'styled-components';

import Arrow, { ARROW_DIRECTION } from '../../../../assets/icons/Arrow';
import Checkbox from '../../../../components/Checkbox';
import Pagination from '../../../../components/Pagination';
import UnderlineTab from '../../../../components/UnderlineTab';
import Logger from '../../../../utils/logger';
import { Wrapper, PageHeader } from '../../components';
import { Table, Th } from '../components';
import { getPreviouslySelectedTransactionIds } from './helpers';
import SearchFilter from './SearchFilter';
import { getXeroBankTransactions, getXeroFilterValues } from '@api/modules/dashboard';
import { useModal, usePagination, useSelectRow } from '@hooks/UI';
import SettlementPanel from './Settlement/Panel';
import { xeroColumnConfig, BANK_PLATFORM_OPTIONS } from './constants';
import { fetchXeroTransactionsApi } from '@redux/modules/bankTransaction/actions';
import { setXeroBankAccounts, startTransactionsSelection, endTransactionsSelection } from '@redux/modules/settlements/actions';
import { useIsAdminView } from '@redux/selectors';
import SelectionControl from './Settlement/components/SelectionControl';
import { getAccountUsedTransactions } from '@api/modules/dashboard/settlement';
import { PLATFORM } from '@constants/platform';
import FinverseTransactions from './FinverseTransactions';
import { findFirstMatchInArray } from '@utils/dataTypes';
import { convertToApiDate } from '@utils/dateHelpers';

const StyledUnderlineTab = styled(UnderlineTab)`
  margin: 0 ${(props) => props.theme.gutter / 2}px;
`;

const StyledArrow = styled(Arrow)`
  vertical-align: middle;
  margin-left: 4px;
`;

const BankTransactions = () => {
  const dispatch = useDispatch();
  const isInAdminView = useIsAdminView();
  const [bankPlatform, setBankPlatform] = useState(BANK_PLATFORM_OPTIONS[0].value);
  const xeroBankAccounts = useSelector((state) => state.settlement.xeroBankAccounts);
  const { dataPageMap, totalNumber } = useSelector((state) => state.bankTransaction);
  const { pageNumber, pageSize, pageCount, onPageNumberChange, onPageSizeChange, changePageCountByTotalAndSize } = usePagination({
    pageNumber: 1,
    pageCount: 0,
    pageSize: 50,
  });
  const transactionData = dataPageMap && dataPageMap[pageNumber] ? dataPageMap[pageNumber] : [];
  const [isLoading, toggleIsLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState();
  const [selectedChannelAccount, setSelectedChannelAccount] = useState();
  const [filterInEffect, setFilterInEffect] = useState({});
  const [filterOptions, setFilterOptions] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [dateShortcutValue, setDateShortValue] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [usedTrxIds, setUsedTrxIds] = useState([]);
  const [isExpanded, toggleIsExpanded] = useModal();
  const transactionIds = transactionData.map(({ transaction_id }) => transaction_id);
  const previouslySelectedIds = getPreviouslySelectedTransactionIds(transactionData);
  const selectableRowIds = transactionIds.filter((id) => previouslySelectedIds.indexOf(id) === -1);
  const { selectedIds, areAllChecked, toggleSelectAll, toggleSelectRow, clearSelection } = useSelectRow(selectableRowIds);
  const [columns, setColumns] = useState(xeroColumnConfig);
  const isSelectingTrx = useSelector((state) => state.settlement.isSelectingTrx);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data: transactionData,
    columns,
  });

  useEffect(() => {
    if (selectedAccount !== undefined) {
      const selectedXeroAccount = findFirstMatchInArray(xeroBankAccounts, ({ BankAccountID }) => selectedAccount === BankAccountID);

      setSelectedCurrency(selectedXeroAccount.currency);
    }
  }, [selectedAccount]);

  const toggleIsSelectingTrx = (nextValue) => {
    if (nextValue) {
      dispatch(startTransactionsSelection());
    } else {
      dispatch(endTransactionsSelection());
    }
  };

  useEffect(() => {
    toggleIsSelectingTrx(false);
  }, []);

  const generateFilterPayload = () => {
    const filterPayload = [];
    if (selectedAccount) {
      filterPayload.push({
        field: 'BankAccountID',
        value: selectedAccount,
        operator: 'eq',
      });
    }
    if (startDate) {
      filterPayload.push({
        field: 'Date',
        value: convertToApiDate(startDate),
        operator: 'ge',
      });
    }
    if (endDate) {
      filterPayload.push({
        field: 'Date',
        value: convertToApiDate(endDate),
        operator: 'le',
      });
    }
    if (filterInEffect.selectedStatus) {
      filterPayload.push({
        field: 'Status',
        value: filterInEffect.selectedStatus,
        operator: 'eq',
      });
    }
    if (filterInEffect.selectedType) {
      filterPayload.push({
        field: 'Type',
        value: filterInEffect.selectedType,
        operator: 'eq',
      });
    }
    if (filterInEffect.contactNameKeyword) {
      filterPayload.push({
        field: 'ContactName',
        value: filterInEffect.contactNameKeyword,
        operator: 'like',
      });
    }
    if (filterInEffect.referenceKeyword) {
      filterPayload.push({
        field: 'Reference',
        value: filterInEffect.referenceKeyword,
        operator: 'like',
      });
    }

    return filterPayload;
  };

  const fetchTransactionApi = async (download) => {
    if (!selectedAccount) {
      return;
    }

    toggleIsLoading(true);
    try {
      const query = {
        ...(!download && { page: pageNumber, pageSize }),
        filter: [],
        sortField: 'Date',
        sortDirection: 'DESC',
        download,
      };

      const filterPayload = generateFilterPayload();

      if (download) {
        const response = await getXeroBankTransactions(query, {
          filter: filterPayload,
        });

        const { data } = response;
        const link = document.createElement('a');
        link.href = `data:text/csv,${encodeURIComponent(data)}`;
        link.download = 'xero_bank_transaction.csv';
        link.click();
      } else {
        await fetchXeroTransactionsApi(query, filterPayload);
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const fetchFilterValuesApi = async () => {
    toggleIsLoading(true);
    try {
      const { data } = await getXeroFilterValues();
      if (data) {
        dispatch(setXeroBankAccounts(data.BankAccount));
        setFilterOptions(data);
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const { data } = await getAccountUsedTransactions(selectedAccount);
        setUsedTrxIds(data);
      } catch (err) {
        Logger.error(err);
      }
    };
    if (selectedAccount && isSelectingTrx) {
      fetchApi();
    }
  }, [selectedAccount, isSelectingTrx]);

  useEffect(() => {
    if (isSelectingTrx) {
      setColumns([{ accessor: 'checkbox' }, ...xeroColumnConfig]);
    } else {
      setColumns(xeroColumnConfig);
    }
  }, [isSelectingTrx]);

  useEffect(() => {
    changePageCountByTotalAndSize(totalNumber, pageSize);
  }, [totalNumber, pageSize]);

  useEffect(() => {
    fetchFilterValuesApi();
  }, []);

  useEffect(() => {
    onPageNumberChange(1);
    fetchTransactionApi();
  }, [pageSize, selectedAccount, startDate, endDate, JSON.stringify(filterInEffect)]);

  useEffect(() => {
    fetchTransactionApi();
  }, [pageNumber]);

  const handleFilterChange = (data) => {
    setFilterInEffect(data);
  };

  const handleAccountChange = (value) => {
    setSelectedAccount(value);
  };

  const handleDateFilterChange = (value) => {
    setStartDate(value.startDate);
    setEndDate(value.endDate);
    setDateShortValue(value.dateShortcutSelectValue);
  };

  const handleChannelAccountChange = (value) => {
    setSelectedChannelAccount(value);
  };

  const downloadTransactions = () => {
    fetchTransactionApi(true);
  };

  return (
    <>
      <PageHeader>Bank Transactions</PageHeader>
      <Wrapper>
        {!isSelectingTrx && (
          <SettlementPanel selectedAccount={selectedAccount} bankAccounts={filterOptions.BankAccount} isSelectingTrx={isSelectingTrx} />
        )}
        {BANK_PLATFORM_OPTIONS.map(({ value, label }) => (
          <StyledUnderlineTab selected={bankPlatform === value} onClick={() => setBankPlatform(value)}>
            {label}
          </StyledUnderlineTab>
        ))}
        {bankPlatform === PLATFORM.XERO && (
          <>
            <SearchFilter
              isLoading={isLoading}
              transactionData={transactionData}
              downloadTransactions={downloadTransactions}
              filterInEffect={filterInEffect}
              filterOptions={filterOptions}
              onApply={handleFilterChange}
              selectedAccount={selectedAccount}
              selectedChannelAccount={selectedChannelAccount}
              onAccountChange={handleAccountChange}
              onDateChange={handleDateFilterChange}
              onChannelAccountChange={handleChannelAccountChange}
              isExpanded={isExpanded}
              toggleIsExpanded={toggleIsExpanded}
              startDate={startDate}
              endDate={endDate}
              dateShortcutValue={dateShortcutValue}
            />
            {isInAdminView && isSelectingTrx && (
              <SelectionControl
                selectedIds={selectedIds}
                selectedAccountId={selectedAccount}
                toggleIsSelectingTrx={toggleIsSelectingTrx}
                clearAll={clearSelection}
              />
            )}
            <>
              <Table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => {
                        if (column.id === 'checkbox') {
                          return (
                            <Th>
                              <Checkbox onClick={toggleSelectAll} value={areAllChecked} />
                            </Th>
                          );
                        } else if (['spentAmount', 'receivedAmount'].indexOf(column.id) !== -1) {
                          return <Th className={column.className}>{column.render('Header')}</Th>;
                        }
                        return (
                          <Th isSortable={column.isSortable} {...column.getHeaderProps(column.isSortable && column.getSortByToggleProps())}>
                            {column.render('Header')}
                            {column.isSorted ? (
                              <StyledArrow direction={column.isSortedDesc ? ARROW_DIRECTION.DOWN : ARROW_DIRECTION.UP} />
                            ) : (
                              ''
                            )}
                          </Th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.length > 0 ? (
                    rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            if (cell.column.id === 'checkbox') {
                              const rowId = cell.row.original.transaction_id;
                              const isPreviouslySelected = Array.isArray(previouslySelectedIds)
                                ? previouslySelectedIds.indexOf(rowId) !== -1
                                : false;
                              const isUsed = Array.isArray(usedTrxIds) ? usedTrxIds.indexOf(rowId) !== -1 : false;
                              const isCurrentlySelected = Array.isArray(selectedIds) ? selectedIds.indexOf(rowId) !== -1 : false;
                              const isChecked = isCurrentlySelected || isPreviouslySelected || isUsed;
                              const isDisabled = isUsed || isPreviouslySelected;
                              return (
                                <td>
                                  <Checkbox onClick={toggleSelectRow(rowId)} value={isChecked} disabled={isDisabled} />
                                </td>
                              );
                            }
                            return (
                              <td {...cell.getCellProps()} className={cell.column.className}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      {columns.map(() => {
                        return <td>-</td>;
                      })}
                    </tr>
                  )}
                </tbody>
              </Table>
              {pageCount ? (
                <Pagination
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  pageCount={pageCount}
                  onPageNumberChange={onPageNumberChange}
                  onPageSizeChange={onPageSizeChange}
                />
              ) : null}
            </>
          </>
        )}
        {bankPlatform === PLATFORM.FINVERSE && <FinverseTransactions />}
      </Wrapper>
    </>
  );
};

export default BankTransactions;
