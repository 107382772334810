const messages = {
  magentoGuide: {
    title: 'How to get an access token',
    contents: [
      {
        type: 'text',
        content: 'To make the integration successful, you will have to fill in the Store URL and Access Token.',
      },
      {
        type: 'heading',
        content: 'Step 1 - Add New Integration',
      },
      {
        type: 'text',
        content: 'In the Magento admin dashboard, go to SYSTEM → Integrations.',
      },
      {
        type: 'image',
        link: 'https://asset.choco-up.com/tutorial/Magento/magento-01.png',
        width: '450px',
        height: '550px',
        alt: '',
      },
      {
        type: 'text',
        content: 'At the Integration page, click the "Add New Integration" button.',
      },
      {
        type: 'image',
        link: 'https://asset.choco-up.com/tutorial/Magento/magento-02.png',
        width: '700px',
        height: '350px',
        alt: '',
      },
      {
        type: 'text',
        content: 'In the New Integration Page, fill in the name "Choco Up" and your own password. Leave the other fields blank.',
      },
      {
        type: 'image',
        link: 'https://asset.choco-up.com/tutorial/Magento/magento-03.png',
        width: '710px',
        height: '550px',
        alt: '',
      },
      {
        type: 'text',
        content: 'Then click the "API" tab on the left, and select the checkboxes for Sales, Stores, and Customers.',
      },
      {
        type: 'text',
        content: 'Sales',
      },
      {
        type: 'image',
        link: 'https://asset.choco-up.com/tutorial/Magento/magento-04.png',
        width: '710px',
        height: '550px',
        alt: '',
      },
      {
        type: 'text',
        content: 'Stores',
      },
      {
        type: 'image',
        link: 'https://asset.choco-up.com/tutorial/Magento/magento-05.png',
        width: '230px',
        height: '190px',
        alt: '',
      },
      {
        type: 'text',
        content: 'Customers',
      },
      {
        type: 'image',
        link: 'https://asset.choco-up.com/tutorial/Magento/magento-06.png',
        width: '210px',
        height: '150px',
        alt: '',
      },
      {
        type: 'text',
        content: 'Click the "Save" button to continue.',
      },
      {
        type: 'image',
        link: 'https://asset.choco-up.com/tutorial/Magento/magento-07.png',
        width: '600px',
        height: '350px',
        alt: '',
      },
      {
        type: 'heading',
        content: 'Step 2 - Activation Integration',
      },
      {
        type: 'text',
        content: 'Click on the "Activate" link for the newly created Choco Up integration entry.',
      },
      {
        type: 'image',
        link: 'https://asset.choco-up.com/tutorial/Magento/magento-08.png',
        width: '450px',
        height: '250px',
        alt: '',
      },
      {
        type: 'text',
        content: 'Click the "Allow" button to continue and the credentials will be created.',
      },
      {
        type: 'image',
        link: 'https://asset.choco-up.com/tutorial/Magento/magento-09.png',
        width: '400px',
        height: '380px',
        alt: '',
      },
      {
        type: 'text',
        content: 'Copy the access token (The third row)',
      },
      {
        type: 'heading',
        content: 'Step 3 - Finish Integration',
      },
      {
        type: 'text',
        content: 'Back to this dialog, fill in your store page along with the access token. Click "Confirm" to proceed.',
      },
      {
        type: 'image',
        link: 'https://asset.choco-up.com/tutorial/Magento/magento-10.png',
        width: '500px',
        height: '250px',
        alt: '',
      },
      {
        type: 'text',
        content: 'And you are done!',
      },
    ],
  },
};

export default messages;
