import React, { useState, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../../components/Button';
import RadioWithText from '../../../../../components/RadioWithText';
import Logger from '../../../../../utils/logger';
import { Wrapper, Card } from '../../../components';
import { CONTENT_CONTAINER_ID } from '../../../constants';
import { getFeeByMethod, getPaymentMethodCondition } from '../../helpers';
import { ButtonWrapper } from './components';
import RecipientSelect from './components/RecipientSelect';
import ReviewForm from './ReviewForm';
import TransferForm from './TransferForm';
import { useQuery } from '@hooks/routing';
import { media } from '@styles/breakpoints';
import { findFirstMatchInArray, isRealObject, isRealNumber } from '@utils/dataTypes';
import { FORM_NAME, TARGET, TARGET_TYPE_OPTIONS } from './constants';
import NewRecipientForm from './NewRecipientForm';
import { fetchWalletCurrentBalance, fetchForeignExchange } from '@redux/modules/wallet/actions';
import { getCurrencyDecimalPosition } from '@utils/priceHelpers';
import { useWalletAccount, useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';
import { calculateToAmount, convertBenePageMapToArray } from './helpers';
import { useCountdown } from '@hooks/UI';

const Title = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
`;

const Description = styled.div`
  font-weight: 600;
  margin: 24px 0 16px;
`;

const StyledRadioWithText = styled(RadioWithText)`
  margin: 8px 0;
`;

const FormWrapper = styled(Card)`
  width: 66%;
  box-sizing: border-box;
  ${media.desktop`
    width: 100%;
  `}
`;

const Radios = styled.div`
  ${RecipientSelect} {
    padding-left: 30px;
  }
`;

const CreateTransferPage = () => {
  const email = useSelector((state) => state.user.profile.username);
  const { beneficiaryPageMap, isLoading } = useSelector((state) => state.wallet);
  const beneficiaries = useMemo(() => convertBenePageMapToArray(beneficiaryPageMap), [JSON.stringify(beneficiaryPageMap)]);

  const transferFee = useSelector((state) => state.wallet.transferFee) || {};
  const foreignExchange = useSelector((state) => state.wallet.foreignExchange) || {};
  const disabled = useCheckIsAdminViewingAnotherCompany() && email !== 'lewis@choco-up.com'; // TODO: remove 'lewis@choco-up.com' after debug

  const { parsed } = useQuery();
  const walletAccount = useWalletAccount();

  const formDefaultValue = {
    sourceCurrency: walletAccount?.currency,
    swift_charge_option: 'SHA',
    beneficiary: {
      entity_type: 'COMPANY',
      bank_details: {
        bank_country_code: 'US',
      },
    },
  };

  const reactHookForm = useForm({
    defaultValues: formDefaultValue,
  });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = reactHookForm;

  const [createdRecipientId, setCreatedRecipientId] = useState();
  const [targetType, setTargetType] = useState();
  const [beneficiaryId, setRecipientId] = useState(parsed?.recipientId);
  const [formName, setFormName] = useState(FORM_NAME.OVERVIEW);
  const [recipientSchema, setRecipientSchema] = useState();
  const [transferSchema, setTransferSchema] = useState();

  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
  const watchOfSourceCurrency = watch('sourceCurrency');
  const watchOfSourceAmount = watch('sourceAmount');
  const watchOfPaymentCurrency = watch('paymentCurrency');
  const watchOfPaymentAmount = watch('paymentAmount');
  const watchOfBankCountryCode = watch('beneficiary.bank_details.bank_country_code');
  const watchOfEntityType = watch('beneficiary.entity_type');
  const watchOfPaymentMethod = watch('paymentMethod');
  const watchOfSwiftChargeOption = watch('swift_charge_option');

  const [amount, setAmount] = useState(watchOfPaymentAmount);
  const [amountOrigin, setAmountOrigin] = useState('paymentAmount');
  const sourceToPaymentFX = foreignExchange[`${watchOfSourceCurrency}to${watchOfPaymentCurrency}`];
  const { remainingTime } = useCountdown({
    startTime: sourceToPaymentFX?.valid_from,
    endTime: sourceToPaymentFX?.valid_to,
  });
  const feeFX = foreignExchange[`${watchOfSourceCurrency}toUSD`];

  const selectedBeneficiary = findFirstMatchInArray(beneficiaries, ({ beneficiary_id }) => beneficiary_id === beneficiaryId);

  const handleTargetTypeChange = (value) => () => {
    setTargetType(value);
  };

  useEffect(() => {
    fetchWalletCurrentBalance(walletAccount?.account_id);
  }, [JSON.stringify(walletAccount)]);

  useEffect(() => {
    const resetForm = () => {
      setRecipientId();
      reset(formDefaultValue);
      setTransferSchema();
      setRecipientSchema();
    };

    resetForm();
  }, [targetType]);

  useEffect(() => {
    const values = getValues();
    if (Array.isArray(beneficiaries) && beneficiaries.length > 0 && beneficiaryId) {
      const bene = findFirstMatchInArray(beneficiaries, ({ beneficiary_id }) => beneficiary_id === beneficiaryId);
      const paymentMethodCondition = getPaymentMethodCondition(bene.payment_methods[0], bene.bank_details?.local_clearing_system);
      reset({
        ...values,
        paymentMethod: paymentMethodCondition.local_clearing_system || paymentMethodCondition.payment_method,
        beneficiary: bene.beneficiary,
        paymentCurrency: bene.beneficiary.bank_details.account_currency,
        paymentAmount: '',
        sourceAmount: '',
      });
    } else {
      reset({ ...values });
    }
  }, [JSON.stringify(beneficiaries), beneficiaryId]);

  useEffect(() => {
    if (parsed?.recipientId) {
      setTargetType(TARGET.EXISTING_USER);
      setFormName(FORM_NAME.TRANSFER);
      setRecipientId(parsed?.recipientId);
    }
  }, [parsed?.recipientId]);

  useEffect(() => {
    try {
      // check amount avoid showing fee amount in the initial rendering
      if (
        amount &&
        isRealObject(foreignExchange) &&
        isRealObject(transferFee) &&
        transferFee[watchOfBankCountryCode] &&
        transferFee[watchOfBankCountryCode][watchOfPaymentCurrency]
      ) {
        const { fromAmount, fromCurrency, toCurrency, toAmountFieldKey } =
          amountOrigin === 'sourceAmount'
            ? {
                fromAmount: amount,
                fromCurrency: watchOfSourceCurrency,
                toCurrency: watchOfPaymentCurrency,
                toAmountFieldKey: 'paymentAmount',
              }
            : {
                fromAmount: amount,
                fromCurrency: watchOfPaymentCurrency,
                toCurrency: watchOfSourceCurrency,
                toAmountFieldKey: 'sourceAmount',
              };
        const fee = getFeeByMethod(
          transferFee[watchOfBankCountryCode][watchOfPaymentCurrency],
          watchOfPaymentMethod,
          watchOfSwiftChargeOption
        );

        const result = calculateToAmount({
          fromAmount,
          fromCurrency,
          toCurrency,
          amountOrigin,
          foreignExchange,
          fee,
        });
        const nextFromAmount = fromAmount;
        setValue(amountOrigin, nextFromAmount);

        const toCurrencyPrecision = getCurrencyDecimalPosition(toCurrency);
        if (isRealNumber(result.toAmount)) {
          const nextToAmount = result.toAmount.toFixed(toCurrencyPrecision.toFixedNumber);
          setValue(toAmountFieldKey, nextToAmount);
          return;
        }

        setValue(toAmountFieldKey, '');
      }
    } catch (err) {
      Logger.error(err);
    }
  }, [
    amount,
    amountOrigin,
    beneficiaryId,
    JSON.stringify(foreignExchange),
    JSON.stringify(sourceToPaymentFX),
    JSON.stringify(feeFX),
    JSON.stringify(transferFee),
    watchOfSourceCurrency,
    watchOfPaymentCurrency,
    watchOfPaymentMethod,
    watchOfSwiftChargeOption,
  ]);

  const handleNext = () => {
    setFormName(FORM_NAME.TRANSFER);
  };

  useEffect(() => {
    document.getElementById(CONTENT_CONTAINER_ID)?.scroll(-100, -100);
  }, [formName]);

  useEffect(() => {
    if (remainingTime === 0 && watchOfSourceCurrency && watchOfPaymentCurrency && watchOfSourceCurrency !== watchOfPaymentCurrency) {
      fetchForeignExchange(walletAccount.account_id, {
        fromCurrency: watchOfSourceCurrency,
        toCurrency: watchOfPaymentCurrency,
        amount: 10000,
      });
      fetchForeignExchange(walletAccount.account_id, {
        fromCurrency: watchOfPaymentCurrency,
        toCurrency: watchOfSourceCurrency,
        amount: 10000,
      });
    }
  }, [remainingTime]);

  return (
    <Wrapper>
      <Title>Send Money</Title>
      <FormWrapper>
        {formName !== FORM_NAME.OVERVIEW ? (
          <>
            {(() => {
              switch (formName) {
                case FORM_NAME.TRANSFER:
                  return (
                    <TransferForm
                      beneficiaryId={beneficiaryId}
                      selectedBeneficiary={selectedBeneficiary}
                      components={{
                        Recipient: <RecipientSelect beneficiaryId={beneficiaryId} setRecipientId={setRecipientId} disabled />,
                      }}
                      targetType={targetType}
                      setFormName={setFormName}
                      isLoading={isLoading}
                      setAmount={setAmount}
                      setAmountOrigin={setAmountOrigin}
                      formProps={reactHookForm}
                      fxCountdownTime={remainingTime}
                    />
                  );
                case FORM_NAME.RECIPIENT:
                  return <NewRecipientForm targetType={targetType} setFormName={setFormName} {...reactHookForm} />;
                case FORM_NAME.REVIEW:
                  return (
                    <ReviewForm
                      targetType={targetType}
                      beneficiaryId={beneficiaryId}
                      selectedBeneficiary={selectedBeneficiary}
                      setFormName={setFormName}
                      createdRecipientId={createdRecipientId}
                      setCreatedRecipientId={setCreatedRecipientId}
                      amountOrigin={amountOrigin}
                      fxCountdownTime={remainingTime}
                      {...reactHookForm}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </>
        ) : (
          <Radios>
            <Description>Who are you transferring to?</Description>
            {TARGET_TYPE_OPTIONS.map(({ value, label, description }) => {
              return (
                <StyledRadioWithText
                    key={value}
                    checked={value === targetType}
                    label={label}
                    description={description}
                    onClick={handleTargetTypeChange(value)}
                  >
                    {targetType === TARGET.EXISTING_USER && value === TARGET.EXISTING_USER && (
                      <RecipientSelect beneficiaryId={beneficiaryId} setRecipientId={setRecipientId} />
                    )}
                  </StyledRadioWithText>
              );
            })}
            <ButtonWrapper>
              <Button
                width={160}
                onClick={handleNext}
                disabled={targetType === undefined || (targetType === TARGET.EXISTING_USER && !beneficiaryId) || disabled}
              >
                Next
              </Button>
            </ButtonWrapper>
          </Radios>
        )}
      </FormWrapper>
    </Wrapper>
  );
};

export default CreateTransferPage;
