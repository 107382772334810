import { STATUS_CODE } from '@constants/application';

export const fundingStatusText = {
  [STATUS_CODE.REJECTED]: {
    user: {
      label: 'Application Rejected',
      description:
        'We are sorry that your application does not meet our funding requirements. Please contact us if you can provide further revenue information at support@choco-up.com .',
    },
    admin: {
      label: 'Application Rejected',
      description: '',
      button: 'View Term Sheet',
    },
  },
  [STATUS_CODE.RECEIVED_PRE_OFFER]: {
    user: {
      label: 'Received Preliminary Offer',
      description: 'Preliminary offer was made. Please proceed to decide the requested funding amount for this application.',
      button: 'Proceed',
    },
    admin: {
      label: 'Received Preliminary Offer',
      description: 'Please review the application and contact the applicant for further information.',
      button: 'Start Review',
    },
  },
  [STATUS_CODE.REQUESTED_FUNDING_AMOUNT]: {
    user: {
      label: 'Pending Investment Review',
      description:
        'Your application is under review. To get a term sheet, please proceed to provide further business and financial information for revenue validations.',
      button: 'Proceed',
    },
    admin: {
      label: 'Funding Amount Requested',
      description: 'Please review the application and contact the applicant for further information.',
      button: 'Start Review',
    },
  },
  [STATUS_CODE.PENDING_INVESTMENT_REVIEW]: {
    user: {
      label: 'Under Investment Review',
      description:
        'Your application is under review. To get a term sheet, please proceed to provide further business and financial information for revenue validations.',
      button: 'Proceed',
    },
    admin: {
      label: 'Under Investment Review',
      description: 'Please create term sheet offer once the users provide sufficient information to validate revenues.',
      button: 'Create Term Sheet',
    },
  },
  returned: {
    user: {
      label: 'Under Investment Review',
      description:
        'Your application is under review. To get a term sheet, please proceed to provide further business and financial information for revenue validations.',
      button: 'Proceed',
    },
    admin: {
      label: 'Under Investment Review',
      description: 'Please create term sheet offer once the users provide sufficient information to validate revenues.',
      button: 'Edit Term Sheet',
    },
  },
  [STATUS_CODE.PENDING_INVESTMENT_MANAGER_REVIEW]: {
    user: {
      label: 'Under Investment Review',
      description:
        'Your application is under review. To get a term sheet, please proceed to provide further business and financial information for revenue validations.',
      button: 'Proceed',
    },
    admin: {
      label: 'Under Manager Review',
      description: 'Investment manager to review the proposed offers and verify the revenue data.',
      button: 'View Term Sheet',
    },
  },
  [STATUS_CODE.PENDING_USER_REVIEW_TERM_SHEET]: {
    user: {
      label: 'Received Term Sheet',
      description: 'Funding offer is ready. Please review and confirm.',
      button: 'Proceed',
    },
    admin: {
      label: 'Pending Accept Term Sheet',
      description: 'Waiting for the user to accept offer.',
      button: 'View Term Sheet',
    },
  },
  [STATUS_CODE.PENDING_CREDIT_TEAM_REVIEW]: {
    user: {
      label: 'Due Diligence Review',
      description:
        'Your application is under due diligence review. Please complete company details, and provide additional information as required for agreement preparation.',
      button: 'Proceed',
    },
    admin: {
      label: 'Under Credit Review',
      description: 'Credit team to review the the funding offers and all information needed for due diligence.',
      button: 'View Term Sheet',
    },
  },
  [STATUS_CODE.PENDING_USER_ACCEPT_OFFER]: {
    user: {
      label: 'Pending Accept Final Offer',
      description: 'Your funding offer is amended as below. Please confirm and proceed to agreement preparation.',
      button: 'Proceed',
    },
    admin: {
      label: 'Pending Accept Final Offer',
      description: 'Waiting for user to confirm the final funding offer.',
      button: 'View Term Sheet',
    },
  },
  [STATUS_CODE.PENDING_USER_SIGN_AGREEMENT]: {
    user: {
      label: 'Pending Sign Agreement',
      description: 'Your funding application is approved. Your agreement will be ready shortly.',
      button: 'Proceed',
    },
    admin: {
      label: 'Pending Sign Agreement',
      description: 'The funding offer is approved. Please prepare for agreement for signing.',
      button: 'View Term Sheet',
    },
  },
  [STATUS_CODE.PENDING_FUND_DISBURSEMENT]: {
    user: {
      label: 'Pending Fund Disbursement',
      description: '',
      button: '',
    },
    admin: {
      label: 'Pending Fund Disbursement',
      description: '',
      button: 'View Term Sheet',
    },
  },
  [STATUS_CODE.FUND_DISBURSED]: {
    user: {
      label: 'Fund Disbursed',
      description: 'The application is approved and fund disbursed.',
      button: 'View Capital',
    },
    admin: {
      label: 'Fund Disbursed',
      description: 'The application is approved and fund disbursed.',
      button: 'View Term Sheet',
    },
  },
};
