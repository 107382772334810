import { Currency } from '@/constants';
import { isRealNumber } from './dataTypes';
import { formatNumberWithUnit } from './unitHelpers';

const thousandSeparatorRegExp = [/(\d)(?=(\d{3})+(?!\d))/g, '$1,'];

export const alwaysPositiveNumber = (number) => {
  if (isRealNumber(number)) {
    return number >= 0 ? number : -number;
  }

  return number;
};

export const formatNumber = (value, decimalScale) => {
  const num = Number(value);
  if (num) {
    if (isRealNumber(decimalScale)) {
      const parts = num.toFixed(decimalScale).toString().split('.');
      parts[0] = parts[0].replace(...thousandSeparatorRegExp);
      return parts.join('.');
    }

    return isRealNumber(num) ? num.toString().replace(...thousandSeparatorRegExp) : '';
  }

  return value;
};

export const getNumberFromFormatted = (num) => {
  if (typeof num === 'string') {
    const trimmed = num.replace(/,/g, '');
    return Number(trimmed);
  }

  return num;
};

export const formatShortFormCurrency = (currency) => {
  switch (currency) {
    case Currency.HKD:
    case Currency.USD:
    case Currency.SGD:
    default:
      return '$';
  }
};

export const formatPrice = (currency = '', number = 0, useShortForm = false, decimalScale = 2) =>
  `${number < 0 ? '-' : ''}${
    useShortForm ? formatShortFormCurrency(currency) : `${currency.toUpperCase()}\u00A0` // \u00A0 = non breakable space
  }${formatNumber(alwaysPositiveNumber(number), decimalScale)}`;

export const roundDownNumber = (number, step = 1) => {
  return Math.floor(number / step) * step;
};

export const roundUpNumber = (number, step = 1) => {
  return Math.ceil(number / step) * step;
};

export const roundNumber = (number) => {
  return Math.round(number);
};

export const getPercentFromDecimal = (decimalNumber, decimalScale) => {
  if (isRealNumber(decimalNumber)) {
    let result = decimalNumber * 100;
    if (isRealNumber(decimalScale)) {
      result = result.toFixed(decimalScale);
    }
    return result;
  }

  return decimalNumber;
};

export const formatPercent = (percent, decimalScale = 2, roundCallback = roundNumber) => {
  if (isRealNumber(Number(percent))) {
    roundCallback = typeof roundCallback === 'function' ? roundCallback : (value) => value;
    return `${formatNumber(roundCallback(percent * 10 ** decimalScale) / 10 ** decimalScale, decimalScale)}%`;
  }

  return percent;
};

export const formatRemittanceAmount = (currency, remittanceAmount) => {
  if (Array.isArray(remittanceAmount) && remittanceAmount.length === 3) {
    return `${formatPrice(currency, remittanceAmount[0])} ~ ${formatPrice(currency, remittanceAmount[2])}`;
  }

  return '';
};

export const formatAmountWithUnit = (number, decimalScale) => {
  return formatNumberWithUnit(number, decimalScale, 1000, ['', 'K', 'M', 'B']);
};

export const adjustStripeAmount = (value) => {
  if (isRealNumber(value)) {
    return value / 100;
  }

  return NaN;
};

export const getCurrencyDecimalPosition = (currency) => {
  switch (currency) {
    case 'JPY':
    case 'WON':
      return {
        toFixedNumber: 0,
        step: 1,
      };
    default:
      return {
        toFixedNumber: 2,
        step: 0.01,
      };
  }
};
