import React from 'react';

import { useHistory, useLocation, Switch, Route, Redirect } from 'react-router-dom';
import styled, { css } from 'styled-components';

import HR from '../../../../../components/HR';
import SolidTab from '../../../../../components/SolidTab';
import UnderlineTab from '../../../../../components/UnderlineTab';

const TabsFilter = ({ category, onCategoryChange = () => {}, tabOptions, selected }) => {
  const location = useLocation();
  const history = useHistory();
  return (
    <>
      {tabOptions.map(({ value, label }) => {
        return (
          <SolidTab selected={location.pathname.indexOf(value) !== -1} onClick={() => history.push(value)}>
              {label}
            </SolidTab>
        );
      })}
      <HR margin={8} color="grayTwo" height="1" />
    </>
  );
};

export default TabsFilter;
