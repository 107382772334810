import { LOGOUT_USER } from '../../auth/constants';
import {
  GET_SUPPORTED_FINVERSE_INSTITUTIONS_REQUEST,
  GET_SUPPORTED_FINVERSE_INSTITUTIONS_SUCCESS,
  GET_SUPPORTED_FINVERSE_INSTITUTIONS_FAILURE,
  GET_INTEGRATED_FINVERSE_INSTITUTIONS_REQUEST,
  GET_INTEGRATED_FINVERSE_INSTITUTIONS_SUCCESS,
  GET_INTEGRATED_FINVERSE_INSTITUTIONS_FAILURE,
} from './constants';

const initialState = {
  isLoading: false,
  supportedInstitutions: [], // institutions supported by finverse
  integratedInstitutions: [], // institutions that a client has integrated into Choco up
};

const finverseReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_SUPPORTED_FINVERSE_INSTITUTIONS_REQUEST:
    case GET_INTEGRATED_FINVERSE_INSTITUTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SUPPORTED_FINVERSE_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        supportedInstitutions: action.payload,
        isLoading: false,
      };
    case GET_INTEGRATED_FINVERSE_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        integratedInstitutions: action.payload,
        isLoading: false,
      };
    case GET_SUPPORTED_FINVERSE_INSTITUTIONS_FAILURE:
    case GET_INTEGRATED_FINVERSE_INSTITUTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default finverseReducer;
