import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import Button from '../../components/Button';
import FileDropzone from '../../components/FileDropzone';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { Description } from '../Application/components';
import { getBankStatementFiles } from '@api/modules/dashboard/file';
import { GridRow, GridColumn } from '@components/Grid';

const BankStatementModal = ({
  disabled,
  bankId,
  onSubmitClick = () => {},
  onCancelClick = () => {},
  hideModal,
}) => {
  const isLoading = useSelector((state) => state.company.isHandlingFiles);
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  useEffect(() => {
    const fetchApi = async () => {
      const { data } = await getBankStatementFiles(bankId);
      setUploadedFiles(data);
    };

    fetchApi();
  }, []);

  const handleConfirm = async () => {
    await onSubmitClick(files);
    hideModal();
  };

  const handleCancel = () => {
    onCancelClick();
    hideModal();
  };

  return (
    <Modal>
      <Header scale={3}>Upload Bank Statements</Header>
      <Description>Please upload the statements of the correspondent bank</Description>
      <Header scale={5}>Upload requirements:</Header>
      <Description>
        <ul>
          <li>
            Please ensure that your files are in pdf format
          </li>
          <li>
            Provide statements of at least last 6 months, or more if possible.
          </li>
        </ul>
      </Description>
      <FileDropzone
        onDrop={onDrop}
        acceptFileType="application/pdf"
        uploadedFiles={uploadedFiles}
      />
      <GridRow>
        <GridColumn lg={6} md={6}>
          <Button
            onClick={handleConfirm}
            disabled={!(Array.isArray(files) && files.length > 0) || disabled || isLoading}
            full
          >
            Submit
          </Button>
        </GridColumn>
        <GridColumn lg={6} md={6}>
          <Button onClick={handleCancel} tertiary full>Cancel</Button>
        </GridColumn>
      </GridRow>
    </Modal>
  );
};

export default BankStatementModal;
