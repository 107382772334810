export const FORM_NAME = {
  OVERVIEW: 'OVERVIEW',
  TRANSFER: 'TRANSFER',
  RECIPIENT: 'RECIPIENT',
  REVIEW: 'REVIEW',
};
export const ENTITY_FIELD_ORDER = {
  SWIFT_CODE: 'beneficiary.bank_details.swift_code',
};

export const PURPOSE_OPTIONS = [
  { label: 'Business expenses', value: 'business_expenses' },
  { label: 'Construction', value: 'construction' },
  {
    label: 'Donation / charitable contribution',
    value: 'donation_charitable_contribution',
  },
  { label: 'Education / training', value: 'education_training' },
  { label: 'Freight', value: 'freight' },
  { label: 'Goods purchased', value: 'goods_purchased' },
  { label: 'Investment capital', value: 'investment_capital' },
  { label: 'Investment proceeds', value: 'investment_proceeds' },
  { label: 'Living expenses', value: 'living_expenses' },
  { label: 'Loan / credit repayment', value: 'loan_credit_repayment' },
  { label: 'Medical Services', value: 'medical_services' },
  { label: 'Other Services', value: 'other_services' },
  { label: 'Pension', value: 'pension' },
  {
    label: 'Professional / Business Services',
    value: 'professional_business_services',
  },
  { label: 'Real Estate', value: 'real_estate' },
  { label: 'Taxes', value: 'taxes' },
  { label: 'Technical Services', value: 'technical_services' },
  { label: 'Transfer to own account', value: 'transfer_to_own_account' },
  { label: 'Travel', value: 'travel' },
  { label: 'Wages / salary', value: 'wages_salary' },
  { label: 'Audiovisual services', value: 'audio_visual_services' },
];

export const TARGET = {
  EXISTING_USER: 'EXISTING_USER',
  NEW_USER: 'NEW_USER',
};

export const TARGET_TYPE_OPTIONS = [
  {
    value: TARGET.EXISTING_USER,
    label: 'Existing recipient',
    description: 'Pick someone from your contact list',
  },
  {
    value: TARGET.NEW_USER,
    label: 'Someone new',
    description: 'start with transfer details and provide recipient information later',
  },
];

export const BUSINESS_TYPE = {
  COMPANY: 'COMPANY',
  PERSONAL: 'PERSONAL',
};

export const BUSINESS_TYPE_OPTIONS = [
  {
    value: 'COMPANY',
    label: 'Business',
  },
  {
    value: 'PERSONAL',
    label: 'Individual',
  },
];

export const SWIFT_FEE_OPTIONS = [
  {
    value: 'SHA',
    label: 'Fees are shared (SHA)',
  },
  {
    value: 'OUR',
    label: 'Senders pay fee (OUR)',
  },
];

export const MINIMUM_PAYMENT_AMOUNT_IN_USE = 10;

export const TRANSACTION_STATUS = {
  PENDING: 'PENDING', // transaction not finish, required 2FA verification
  APPROVED: 'APPROVED', // transaction not finish, pending confirm transaction
  TRANSFERRED: 'TRANSFERRED', // transaction finished
};
