import styled, { css } from 'styled-components';

export const Header = styled.div`
  ${(props) => props.theme.text.two};
  margin-bottom: 15px;
`;

export const Table = styled.table`
  width: 99.2%;

  border-collapse: collapse;

  th {
    padding: 8px 16px;
    font-size: 14px;
  }

  td {
    padding: 4px 16px;
    font-size: 12px;
  }
`;

export const Th = styled.th`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.grayThree};
`;

export const SummaryTableRow = styled.tr`
  font-weight: bold;
  border: 1px solid ${(props) => props.theme.colors.grayTwo};
  background-color: ${(props) => props.theme.colors.grayOne};
`;
