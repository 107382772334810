import React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Avatar, { AVATAR_SIZE } from '../../../components/Avatar';
import Header from '../../../components/Header';
import { GrayCard } from '../components';
import AdminSelect from './components/AdminSelect';
import { formatLeadSourceInfo } from './helpers';
import { GridRow, GridColumn } from '@components/Grid';
import { countryMap } from '@/constants';
import { businessTypeOptions } from '@constants/options';
import { useSelectedCompany, useIsAdminView } from '@redux/selectors';
import { convertDate, DD_MMM_YYYY_FORMAT } from '@utils/dateHelpers';
import { getOptionLabelByValue } from '@utils/optionHelpers';

const StyledAvatar = styled(Avatar)`
  float: left;
  margin-right: 20px;
`;

const FieldData = styled.div`
  ${(props) => props.theme.ellipsis};
`;

const CompanyName = styled(Header)`
  ${(props) => props.theme.ellipsis};
  line-height: 50px;
`;

const CompanyInfoCard = () => {
  const view = useSelector((state) => state.ui.view);
  const { id, companyName, website, businessType, countryOrigin, registerAddress, admins, createdDate, leadSourceType, leadSourceDetail } =
    useSelectedCompany();
  const isInAdminView = useIsAdminView();

  const salesAdminId = Array.isArray(admins) && admins.length > 0 ? admins[0].admin_id : null;

  return (
    <GrayCard>
      <GridRow>
        <GridColumn lg={12} md={12}>
          <StyledAvatar size={AVATAR_SIZE.LARGE} name={companyName} />
          <CompanyName scale={2}>{companyName}</CompanyName>
        </GridColumn>
        <GridColumn lg={4} md={6} sm={12}>
          <Header scale={5} highlight>
            Website
          </Header>
          <FieldData>{website}</FieldData>
        </GridColumn>
        <GridColumn lg={4} md={6} sm={12}>
          <Header scale={5} highlight>
            Business Type
          </Header>
          <FieldData>{getOptionLabelByValue(businessType, businessTypeOptions)}</FieldData>
        </GridColumn>
        <GridColumn lg={4} md={6} sm={12}>
          <Header scale={5} highlight>
            Country
          </Header>
          <FieldData>{countryMap[registerAddress?.country] ?? countryMap[countryOrigin]}</FieldData>
        </GridColumn>
        <GridColumn lg={4} md={6} sm={12}>
          <Header scale={5} highlight>
            Joined
          </Header>
          <FieldData>{convertDate(createdDate, DD_MMM_YYYY_FORMAT)}</FieldData>
        </GridColumn>
        {isInAdminView && (
          <>
            {leadSourceType && (
              <GridColumn lg={4} md={6} sm={12}>
                <Header scale={5} highlight>
                  Referral
                </Header>
                <FieldData>{formatLeadSourceInfo(leadSourceType, leadSourceDetail)}</FieldData>
              </GridColumn>
            )}
            <GridColumn lg={3} md={6} sm={12}>
              <Header scale={5} highlight>
                Assigned To
              </Header>
              <AdminSelect value={salesAdminId} companyId={id} />
            </GridColumn>
          </>
        )}
      </GridRow>
    </GrayCard>
  );
};

export default CompanyInfoCard;
