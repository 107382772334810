import React from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../../../components/Button';
import { Card } from '../../../components';
import PATH from '../../../path';
import { USER_ROLE } from '../../constants';
import { useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';
import { media } from '@styles/breakpoints';
import { findFirstMatchInArray } from '@utils/dataTypes';

const Container = styled.div`
  display: flex;
  margin-top: 10px;

  ${media.desktop`
    flex-direction: column;
  `};
`;

const Description = styled.span`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  margin: 12px 0;
  flex-grow: 1;
  width: 60%;
`;

const CardTitle = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
  margin-bottom: 8px;
`;

const EnabledMessage = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.green};
  font-weight: 600;
`;

const MultiFactorCard = ({ users }) => {
  const isAdminView = useCheckIsAdminViewingAnotherCompany();
  const history = useHistory();
  const company2fa = useSelector((state) => state.company.company2fa);
  const currentUserId = useSelector((state) => state.user.profile.id);
  const currentUserRole = findFirstMatchInArray(users, (user) => user.user_id === currentUserId);

  const goToSetupMultiFactor = () => {
    history.push(PATH.SETTINGS_MULTI_FACTOR_OVERVIEW);
  };

  return (
    <Card>
      <CardTitle>Account-wide Two Factor Authentication (2FA)</CardTitle>
      <Container>
        <Description>
          To enable account-wide 2FA, please set up your own 2FA first. Once set up, 2FA will be required for each login
          {Array.isArray(company2fa) && company2fa?.[0]?.transaction2fa && (
            <Container>
              Creating a transfer:&nbsp;
              <EnabledMessage> Enabled</EnabledMessage>
            </Container>
          )}
        </Description>
        <div>
          <Button
            secondary
            onClick={goToSetupMultiFactor}
            width={120}
            disabled={isAdminView || currentUserRole?.role_name !== USER_ROLE.OWNER}
          >
            {Array.isArray(company2fa) && company2fa?.[0]?.transaction2fa ? 'Edit' : 'Setup 2FA'}
          </Button>
        </div>
      </Container>
    </Card>
  );
};

export default MultiFactorCard;
