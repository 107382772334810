export const GET_WALLET_ACCOUNT_REQUEST = 'GET_WALLET_ACCOUNT_REQUEST';
export const GET_WALLET_ACCOUNT_SUCCESS = 'GET_WALLET_ACCOUNT_SUCCESS';
export const GET_WALLET_ACCOUNT_FAILURE = 'GET_WALLET_ACCOUNT_FAILURE';
export const GET_WALLET_TRANSACTIONS_REQUEST = 'GET_WALLET_TRANSACTIONS_REQUEST';
export const GET_WALLET_TRANSACTIONS_SUCCESS = 'GET_WALLET_TRANSACTIONS_SUCCESS';
export const GET_WALLET_TRANSACTIONS_FAILURE = 'GET_WALLET_TRANSACTIONS_FAILURE';
export const GET_GLOBAL_ACCOUNTS_REQUEST = 'GET_GLOBAL_ACCOUNTS_REQUEST';
export const GET_GLOBAL_ACCOUNTS_SUCCESS = 'GET_GLOBAL_ACCOUNTS_SUCCESS';
export const GET_GLOBAL_ACCOUNTS_FAILURE = 'GET_GLOBAL_ACCOUNTS_FAILURE';
export const GET_WALLET_PAYOUTS_REQUEST = 'GET_WALLET_PAYOUTS_REQUEST';
export const GET_WALLET_PAYOUTS_SUCCESS = 'GET_WALLET_PAYOUTS_SUCCESS';
export const GET_WALLET_PAYOUTS_FAILURE = 'GET_WALLET_PAYOUTS_FAILURE';
export const GET_WALLET_PAYOUT_DETAIL_REQUEST = 'GET_WALLET_PAYOUT_DETAIL_REQUEST';
export const GET_WALLET_PAYOUT_DETAIL_SUCCESS = 'GET_WALLET_PAYOUT_DETAIL_SUCCESS';
export const GET_WALLET_PAYOUT_DETAIL_FAILURE = 'GET_WALLET_PAYOUT_DETAIL_FAILURE';
export const GET_WALLET_BENEFICIARIES_REQUEST = 'GET_WALLET_BENEFICIARIES_REQUEST';
export const GET_WALLET_BENEFICIARIES_SUCCESS = 'GET_WALLET_BENEFICIARIES_SUCCESS';
export const GET_WALLET_BENEFICIARIES_FAILURE = 'GET_WALLET_BENEFICIARIES_FAILURE';
export const GET_WALLET_FORM_SCHEMA_REQUEST = 'GET_WALLET_FORM_SCHEMA_REQUEST';
export const GET_WALLET_FORM_SCHEMA_SUCCESS = 'GET_WALLET_FORM_SCHEMA_SUCCESS';
export const GET_WALLET_FORM_SCHEMA_FAILURE = 'GET_WALLET_FORM_SCHEMA_FAILURE';
export const GET_GLOBAL_ACCOUNT_TRANSACTION_REQUEST = 'GET_GLOBAL_ACCOUNT_TRANSACTION_REQUEST';
export const GET_GLOBAL_ACCOUNT_TRANSACTION_SUCCESS = 'GET_GLOBAL_ACCOUNT_TRANSACTION_SUCCESS';
export const GET_GLOBAL_ACCOUNT_TRANSACTION_FAILURE = 'GET_GLOBAL_ACCOUNT_TRANSACTION_FAILURE';
export const GET_WALLET_CURRENCY_BALANCE_REQUEST = 'GET_WALLET_CURRENCY_BALANCE_REQUEST';
export const GET_WALLET_CURRENCY_BALANCE_SUCCESS = 'GET_WALLET_CURRENCY_BALANCE_SUCCESS';
export const GET_WALLET_CURRENCY_BALANCE_FAILURE = 'GET_WALLET_CURRENCY_BALANCE_FAILURE';
export const GET_WALLET_TRANSFER_FEE_REQUEST = 'GET_WALLET_TRANSFER_FEE_REQUEST';
export const GET_WALLET_TRANSFER_FEE_SUCCESS = 'GET_WALLET_TRANSFER_FEE_SUCCESS';
export const GET_WALLET_TRANSFER_FEE_FAILURE = 'GET_WALLET_TRANSFER_FEE_FAILURE';
export const GET_WALLET_FOREIGN_EXCHANGE_REQUEST = 'GET_WALLET_FOREIGN_EXCHANGE_REQUEST';
export const GET_WALLET_FOREIGN_EXCHANGE_SUCCESS = 'GET_WALLET_FOREIGN_EXCHANGE_SUCCESS';
export const GET_WALLET_FOREIGN_EXCHANGE_FAILURE = 'GET_WALLET_FOREIGN_EXCHANGE_FAILURE';
export const DELETE_WALLET_BENEFICIARY_REQUEST = 'DELETE_WALLET_BENEFICIARY_REQUEST';
export const DELETE_WALLET_BENEFICIARY_SUCCESS = 'DELETE_WALLET_BENEFICIARY_SUCCESS';
export const DELETE_WALLET_BENEFICIARY_FAILURE = 'DELETE_WALLET_BENEFICIARY_FAILURE';
export const GET_WALLET_CONVERSION_LIST_REQUEST = 'GET_WALLET_CONVERSION_LIST_REQUEST';
export const GET_WALLET_CONVERSION_LIST_SUCCESS = 'GET_WALLET_CONVERSION_LIST_SUCCESS';
export const GET_WALLET_CONVERSION_LIST_FAILURE = 'GET_WALLET_CONVERSION_LIST_FAILURE';
export const GET_WALLET_CARDS_REQUEST = 'GET_WALLET_CARDS_REQUEST';
export const GET_WALLET_CARDS_SUCCESS = 'GET_WALLET_CARDS_SUCCESS';
export const GET_WALLET_CARDS_FAILURE = 'GET_WALLET_CARDS_FAILURE';
export const GET_WALLET_CARD_DETAIL_REQUEST = 'GET_WALLET_CARD_DETAIL_REQUEST';
export const GET_WALLET_CARD_DETAIL_SUCCESS = 'GET_WALLET_CARD_DETAIL_SUCCESS';
export const GET_WALLET_CARD_DETAIL_FAILURE = 'GET_WALLET_CARD_DETAIL_FAILURE';
export const UPDATE_WALLET_CARD_REQUEST = 'UPDATE_WALLET_CARD_REQUEST';
export const UPDATE_WALLET_CARD_SUCCESS = 'UPDATE_WALLET_CARD_SUCCESS';
export const UPDATE_WALLET_CARD_FAILURE = 'UPDATE_WALLET_CARD_FAILURE';
export const GET_WALLET_CARD_TRANSACTIONS_REQUEST = 'GET_WALLET_CARD_TRANSACTIONS_REQUEST';
export const GET_WALLET_CARD_TRANSACTIONS_SUCCESS = 'GET_WALLET_CARD_TRANSACTIONS_SUCCESS';
export const GET_WALLET_CARD_TRANSACTIONS_FAILURE = 'GET_WALLET_CARD_TRANSACTIONS_FAILURE';
export const GET_WALLET_ACCOUNT_SETTINGS_REQUEST = 'GET_WALLET_ACCOUNT_SETTINGS_REQUEST';
export const GET_WALLET_ACCOUNT_SETTINGS_SUCCESS = 'GET_WALLET_ACCOUNT_SETTINGS_SUCCESS';
export const GET_WALLET_ACCOUNT_SETTINGS_FAILURE = 'GET_WALLET_ACCOUNT_SETTINGS_FAILURE';
export const UPDATE_WALLET_ACCOUNT_SETTINGS_REQUEST = 'UPDATE_WALLET_ACCOUNT_SETTINGS_REQUEST';
export const UPDATE_WALLET_ACCOUNT_SETTINGS_SUCCESS = 'UPDATE_WALLET_ACCOUNT_SETTINGS_SUCCESS';
export const UPDATE_WALLET_ACCOUNT_SETTINGS_FAILURE = 'UPDATE_WALLET_ACCOUNT_SETTINGS_FAILURE';
export const UPDATE_CARD_STATUS_REQUEST = 'UPDATE_CARD_STATUS_REQUEST';
export const UPDATE_CARD_STATUS_SUCCESS = 'UPDATE_CARD_STATUS_SUCCESS';
export const UPDATE_CARD_STATUS_FAILURE = 'UPDATE_CARD_STATUS_FAILURE';
