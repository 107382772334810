import React from 'react';

import _ from 'lodash';
import { useController } from 'react-hook-form';

import MoneyInput from '../../components/MoneyInput';
import ErrorMessage from './ErrorMessage';
import { alwaysPositiveNumber } from '@utils/priceHelpers';

const RHFMoneyInput = ({
  control,
  prefix,
  currencyFieldName,
  amountFieldName,
  errors,
  currencyOptions,
  selectProps = {},
  inputProps = {},
  currencyRules = { required: true },
  amountRules = { required: true, min: 1 },
}) => {
  const currencyName = currencyFieldName || `${prefix}Currency`;
  const amountName = amountFieldName || `${prefix}Amount`;

  const {
    field: { onChange: onCurrencyChange, ...restSelectProps },
  } = useController({
    name: currencyName,
    control,
    rules: currencyRules,
  });

  const {
    field: { onChange: onAmountChange, ...restInputProps },
  } = useController({
    name: amountName,
    control,
    rules: amountRules,
  });

  const currencyError = _.get(errors, currencyName);
  const amountError = _.get(errors, amountName);

  return (
    <>
      <MoneyInput
        onCurrencyChange={onCurrencyChange}
        onAmountChange={onAmountChange}
        selectProps={{
          hasError: currencyError,
          ...restSelectProps,
          ...selectProps,
        }}
        inputProps={{
          ...restInputProps,
          ...inputProps,
          value: inputProps.alwaysPositiveNumber ? alwaysPositiveNumber(restInputProps.value) : restInputProps.value,
          hasError: amountError,
        }}
        full
      />
      <ErrorMessage>{currencyError?.message || amountError?.message}</ErrorMessage>
    </>
  );
};

export default RHFMoneyInput;
