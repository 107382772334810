import { formatPrice } from '@utils/priceHelpers';

export const configOfCharts = [
  {
    title: 'Gross volume',
    type: 'gross_volume',
    formatSumLabel: ({ currency, value }) =>
      formatPrice(currency, value, false, 2),
  },
  {
    title: 'New customers',
    type: 'new_customers',
  },
  {
    title: 'Successful payments',
    type: 'successful_payment',
  },
  {
    title: 'Net volume from sales',
    type: 'net_volume',
    formatSumLabel: ({ currency, value }) =>
      formatPrice(currency, value, false, 2),
  },
];
