import React from 'react';

import styled from 'styled-components';

import Accordion from '../../../components/Accordion';
import Modal from '../../../components/Modal';
import { messages } from './FAQ/messages';

const ModalWrapper = styled.div`
  margin-bottom: 1rem;
  color: ${(props) => props.theme.colors.black};
  display: flex;
  justify-content: space-between;
`;

const ModalHeader = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: bold;
  margin: 10px 0;
`;

const FAQModal = () => {
  return (
    <Modal hideModalOnClickOutside>
      <ModalWrapper>
        <ModalHeader>Frequently Asked Questions</ModalHeader>
      </ModalWrapper>
      {messages.map((item) => (
        <Accordion title={item.title} contents={item.contents} />
      ))}
    </Modal>
  );
};
export default FAQModal;
