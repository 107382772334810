import React, { useEffect, useState } from 'react';

import { useHistory, useLocation, Switch, Route, Redirect } from 'react-router-dom';

import Tabs from '../../../../components/Tabs';
import { Wrapper } from '../../components';
import PATH from '../../path';
import { CARD_TAB_OPTIONS } from '../constants';
import CardDetail from './CardDetail';
import CreateCard from './CreateCard';
import EditCompanyCardContainer from './EditCard/CompanyCardContainer';
import ReviewCard from './ReviewCard';
import Settings from './Settings';
import Summary from './Summary';
import Transactions from './Transactions';
import { fetchWalletCards } from '@redux/modules/wallet/actions';
import { useWalletAccountId } from '@redux/selectors';

const CardContainer = () => {
  const location = useLocation();
  const walletAccountId = useWalletAccountId();

  const showTabs =
    [PATH.WALLET_CARDS, PATH.WALLET_CARDS_TRANSACTIONS, PATH.WALLET_CARDS_SETTINGS].indexOf(location.pathname) !== -1;

  useEffect(() => {
    if (walletAccountId) {
      fetchWalletCards(walletAccountId, { pageNumber: 1, pageSize: 100 });
    }
  }, [walletAccountId]);

  const isTabSelected = (value) => {
    return location.pathname === value;
  };

  return (
    <>
      {showTabs && <Tabs options={CARD_TAB_OPTIONS} isTabSelected={isTabSelected} />}
      <Switch>
        <Wrapper>
          <Route path={PATH.WALLET_CARDS_CARD_DETAIL} component={CardDetail} />
          <Route path={PATH.WALLET_CARDS_CARD_REVIEW} component={ReviewCard} />
          <Route path={PATH.WALLET_CARDS_CREATE} component={CreateCard} />
          <Route path={PATH.WALLET_CARDS_SETTINGS} component={Settings} />
          <Route path={PATH.WALLET_CARDS_TRANSACTIONS} component={Transactions} />
          <Route exact path={PATH.WALLET_CARDS} component={Summary} />
          <Route path={PATH.WALLET_CARDS_EDIT_COMPANY_CARD} component={EditCompanyCardContainer} />
          <Redirect to={PATH.WALLET_CARDS} />
        </Wrapper>
      </Switch>
    </>
  );
};

export default CardContainer;
