import { formatNumber, formatPrice } from '@utils/priceHelpers';

// Table column config
const columnConfig = [
  {
    Header: 'Campaign Objectives',
    accessor: 'objective',
  },
  {
    Header: 'Active Campaigns',
    accessor: 'campaign_name', // may be "name" too?
  },
  {
    Header: 'Spends',
    accessor: 'spend',
    Cell: ({ value, row }) => {
      return value === null ? '-' : formatPrice(row.original.account_currency, value, true);
    },
  },
  {
    Header: 'Impressions',
    accessor: 'impressions',
    Cell: ({ value }) => {
      return value === null ? '-' : formatNumber(value);
    },
  },
  {
    Header: 'Clicks',
    accessor: 'clicks',
    Cell: ({ value }) => {
      return value === null ? '-' : formatNumber(value);
    },
  },
  {
    Header: 'Converted Product Value',
    accessor: 'converted_product_values',
    Cell: ({ value, row }) => {
      return value === null ? '-' : formatPrice(row.original.account_currency, value, true, 2);
    },
  },
  {
    Header: 'Attributed Conversions',
    accessor: 'attributed_conversions',
    Cell: ({ value }) => {
      return value === null ? '-' : formatNumber(value);
    },
  },
  // {
  //   Header: 'Converted Revenue',
  //   accessor: 'ContactName',
  // },
  // {
  //   Header: 'Conversion Rate',
  //   accessor: 'Status',
  // },
  {
    Header: 'ROAS',
    accessor: 'total_purchased_roas',
    Cell: ({ value }) => {
      return value === null ? '-' : formatNumber(value, 2);
    },
  },
  {
    Header: 'CPC',
    accessor: 'cpc',
    Cell: ({ value }) => {
      return value === null ? '-' : formatNumber(value, 2);
    },
  },
];

export default columnConfig;
