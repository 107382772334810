import React from 'react';

import OnboardingOverview from '../../../components/OnboardingOverview';
import PATH from '../../../path';

const Onboarding = () => {
  const PAYMENT_ONBOARDING_CONTENT = {
    title: 'Accept global payments for your online business',
    description:
      'Collect payments from international customers for 130+ currencies via mainstream cards, wallets and local payment methods, with a fee starting from 2% per transactions.',
    path: PATH.PAYMENT_SETUP,
    imgUrl: 'https://asset.choco-up.com/tutorial/payment/choco-payment-onboard.png',
  };
  return <OnboardingOverview {...PAYMENT_ONBOARDING_CONTENT} />;
};

export default Onboarding;
