import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import CheckBox, { CheckboxText } from '../../../../components/Checkbox';
import { Description } from '../../../Application/components';
import { MANUAL_INTEGRATION_FORM } from '../../../Modal/constants';
import { GridColumn } from '@components/Grid';
import { DD_EMAIL } from '@constants/email';
import { PLATFORM, PLATFORM_DISPLAY_NAME } from '@constants/platform';
import { showModal, hideModal } from '@redux/modules/UI/actions';

const Title = styled.div`
 ${(props) => props.theme.text.two};
 margin: 10px 0 20px 0;
`;

const InstructionTitle = styled.div`
 ${(props) => props.theme.text.three};
 font-weight: bold;
 margin: 20px 0;
`;

const InstructionsImage = styled.img`
 width: 100%;
 height: auto;
 margin-bottom: 20px;
`;

const Ebay = () => {
  const dispatch = useDispatch();
  const [isChecked, toggleIsChecked] = useState(false);

  const handleIntegrationClick = () => {
    dispatch(showModal(MANUAL_INTEGRATION_FORM, {
      platformName: PLATFORM.EBAY,
    }));
  };

  return (
    <>
      <Title>
        Connect
        {' '}
        {PLATFORM_DISPLAY_NAME.EBAY}
        {' '}
        Account
      </Title>
      <Description>Please follow below steps to add access to your account</Description>
      <InstructionTitle>Step 1</InstructionTitle>
      <Description>
        Go to
        {' '}
        <b>My </b>
        {' '}
        <b>
          {PLATFORM_DISPLAY_NAME.EBAY}
        </b>
        {' '}
        {'>'}
        <b> Summary </b>
        {'>'}
        <b> Account</b>
      </Description>
      <InstructionTitle>Step 2</InstructionTitle>
      <Description>
        Please use information given below to fill in form to add Choco Up as new user
        <ul>
          <li>First name: Choco</li>
          <li>Last name: Up</li>
          <li>
            Email:
            {' '}
            {DD_EMAIL}
          </li>
        </ul>
      </Description>
      <Description>
        Please grant us with the permissions below:
        <ul>
          <li>View orders</li>
          <li>Download order report</li>
        </ul>
        <b>Click Add user</b>
      </Description>
      <InstructionsImage src="https://asset.choco-up.com/tutorial/ebay/Ebay-1.jpg" alt="Connect ebay Account" />
      <GridColumn lg={12} md={12}>
        <CheckBox onClick={() => toggleIsChecked(!isChecked)} />
        <CheckboxText>
          Confirm I have connected with my
          {' '}
          {PLATFORM_DISPLAY_NAME.EBAY}
          {' '}
          account
        </CheckboxText>
      </GridColumn>
      <GridColumn lg={6} md={6}>
        <Button onClick={handleIntegrationClick} disabled={!isChecked} primary full>Next</Button>
      </GridColumn>
      <GridColumn lg={6} md={6}>
        <Button onClick={() => dispatch(hideModal())} tertiary full>Cancel</Button>
      </GridColumn>
    </>
  );
};

export default Ebay;
