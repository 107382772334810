import moment from 'moment';
import isAfterDay from 'react-dates/lib/utils/isAfterDay';

import { OFFER_CONDITION } from '@constants/offer';
import { convertToApiDate } from '@utils/dateHelpers';
import { getNumberFromFormatted, getPercentFromDecimal } from '@utils/priceHelpers';

const getOfferCondition = (offer) => {
  if (offer.condition === OFFER_CONDITION.ACCEPTED) {
    return OFFER_CONDITION.ACCEPTED;
  }
  const isExpired = isAfterDay(offer.expiry_date, moment());
  if (isExpired) {
    return OFFER_CONDITION.EXPIRED;
  }

  return offer.condition;
};

export const generateOfferPayload = (offer, applicationId) => {
  const serviceFeeAmount = offer.purchasedReceivablesAmount - offer.purchasedPriceAmount;
  return {
    purchased_receivables_currency: offer.purchasedReceivablesCurrency,
    purchased_receivables_amount: getNumberFromFormatted(offer.purchasedReceivablesAmount),
    purchased_price_currency: offer.purchasedPriceCurrency,
    purchased_price_amount: getNumberFromFormatted(offer.purchasedPriceAmount),
    retrieval_percentage: Number(offer.retrievalPercentage) / 100,
    remittance_period: offer.remittancePeriod,
    fund_disbursement_date: convertToApiDate(offer.disbursementDate),
    expiry_date: convertToApiDate(offer.expiryDate),
    first_remittance_date: convertToApiDate(offer.firstRemittanceDate),
    application_id: applicationId,
    min_remittance_amount: offer.minRemittanceAmount,
    max_remittance_amount: offer.maxRemittanceAmount,
    service_fee_amount: serviceFeeAmount,
    service_fee_percentage: Number((serviceFeeAmount / offer.purchasedPriceAmount).toFixed(4)),
  };
};

export const convertOfferResponse = (offer) => {
  return {
    id: offer.id,
    purchasedReceivablesCurrency: offer.purchased_receivables_currency,
    purchasedReceivablesAmount: offer.purchased_receivables_amount,
    purchasedPriceAmount: offer.purchased_price_amount,
    purchasedPriceCurrency: offer.purchased_price_currency,
    minRemittanceAmount: offer.min_remittance_amount,
    maxRemittanceAmount: offer.max_remittance_amount,
    retrievalPercentage: getPercentFromDecimal(offer.retrieval_percentage, 2),
    remittancePeriod: offer.remittance_period,
    disbursementDate: moment(offer.fund_disbursement_date),
    expiryDate: moment(offer.expiry_date),
    firstRemittanceDate: moment(offer.first_remittance_date),
    condition: getOfferCondition(offer),
    actualDisbursementDate: offer.actual_disbursement_date ? moment(offer.actual_disbursement_date) : null,
    actualFirstInvoiceDate: offer.actual_invoice_date ? moment(offer.actual_invoice_date) : null,
    serviceFeeAmount: offer.service_fee_amount,
    serviceFeePercentage: offer.service_fee_percentage,
  };
};

export const getNextOffersAfterEdit = (state, action) => {
  const ids = state.adminOffers.map((offer) => offer.id);
  const index = ids.indexOf(action.payload.id);
  let nextOffers = state.adminOffers;
  if (index !== -1) {
    nextOffers[index] = action.payload.offer;
  }

  if (index === 0) {
    nextOffers = nextOffers.map((offer) => ({
      ...offer,
      expiryDate: nextOffers[0].expiryDate,
    }));
  }

  return nextOffers;
};

export const getNextOffersAfterDelete = (state, action) => {
  const ids = state.adminOffers.map((offer) => offer.id);
  const index = ids.indexOf(action.payload.id);
  const nextOffers = state.adminOffers;
  if (index !== -1) {
    nextOffers.splice(index, 1);
  }

  return nextOffers;
};
