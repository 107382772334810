import COLORS from '../../../styles/colors';

export const getColorByStripeStatus = (status) => {
  switch (status) {
    case 'paid':
    case 'succeeded':
      return COLORS.green;
    case 'failed':
      return COLORS.red;
    case 'canceled':
      return COLORS.grayThree;
    case 'Partial refund':
    case 'Refunded':
    default:
      return COLORS.black;
  }
};
