import { BASE_DASHBOARD_PATH } from '@/basePath';
import APPLICATION_PATH from '../Application/path';
import { STATUS_CODE } from '@constants/application';

const PATH = {
  HOME: `${BASE_DASHBOARD_PATH}/home`,
  SETTINGS: `${BASE_DASHBOARD_PATH}/settings`,
  INTEGRATION: `${BASE_DASHBOARD_PATH}/integration`,
  SETTINGS_FINANCE: `${BASE_DASHBOARD_PATH}/settings/finance-accounts`,
  SETTINGS_ACCOUNT_OVERVIEW: `${BASE_DASHBOARD_PATH}/settings/account`,
  SETTINGS_CHANGE_PASSWORD: `${BASE_DASHBOARD_PATH}/settings/profile-information/change-password`,
  SETTINGS_USER_MANAGEMENT: `${BASE_DASHBOARD_PATH}/settings/user-management`,
  SETTINGS_USER_MANAGEMENT_FORM: `${BASE_DASHBOARD_PATH}/settings/user-management/invite-user-form`,
  SETTINGS_MULTI_FACTOR_OVERVIEW: `${BASE_DASHBOARD_PATH}/settings/user-management/2fa-overview`,
  SETTINGS_MULTI_FACTOR_SETUP: `${BASE_DASHBOARD_PATH}/settings/user-management/2fa-setup`,
  SETTINGS_COMPANY_OVERVIEW: `${BASE_DASHBOARD_PATH}/settings/company`,
  SETTINGS_COMPANY_BUSINESS: `${BASE_DASHBOARD_PATH}/settings/company/business-details`,
  SETTINGS_COMPANY_KEY_PERSONS: `${BASE_DASHBOARD_PATH}/settings/company/key-persons`,
  SETTINGS_COMPANY_KEY_ENTITY: `${BASE_DASHBOARD_PATH}/settings/company/key-entity`,
  SETTINGS_COMPANY_KEY_OWNER: `${BASE_DASHBOARD_PATH}/settings/company/key-owner`,
  SETTINGS_UPLOADS: `${BASE_DASHBOARD_PATH}/settings/uploads`,
  SETTINGS_PROFILE_INFORMATION: `${BASE_DASHBOARD_PATH}/settings/profile-information`,
  TERM_SHEET: `${BASE_DASHBOARD_PATH}/term-sheet`,
  CREDIT_RISK: `${BASE_DASHBOARD_PATH}/credit-risk`,
  SELECT_REVENUE_STREAM: `${BASE_DASHBOARD_PATH}/term-sheet/edit-revenue-stream`,
  VIEW_REVENUE_STREAM: `${BASE_DASHBOARD_PATH}/term-sheet/revenue-stream`,
  BUSINESS: `${BASE_DASHBOARD_PATH}/business`,
  BUSINESS_BANK_TRANSACTIONS: `${BASE_DASHBOARD_PATH}/business/bank-transactions`,
  BUSINESS_SETTLEMENTS: `${BASE_DASHBOARD_PATH}/business/bank-transactions/settlements`,
  BUSINESS_SALES_REPORT: `${BASE_DASHBOARD_PATH}/business/sales-report`,
  BUSINESS_PERFORMANCE: `${BASE_DASHBOARD_PATH}/business/performance`,
  BUSINESS_FB_PERFORMANCE: `${BASE_DASHBOARD_PATH}/business/fb-performance`,
  BUSINESS_ACCOUNTING_REPORT: `${BASE_DASHBOARD_PATH}/business/accounting-report`,
  BUSINESS_ACCOUNTING_REPORT_PROFIT_LOSS: `${BASE_DASHBOARD_PATH}/business/accounting-report/profit-and-loss`,
  BUSINESS_ACCOUNTING_REPORT_BALANCE_SHEET: `${BASE_DASHBOARD_PATH}/business/accounting-report/balance-sheet`,
  CAPITAL: `${BASE_DASHBOARD_PATH}/capital`,
  CAPITAL_BALANCE_TRANSACTIONS: `${BASE_DASHBOARD_PATH}/capital/balance-transactions`,
  CAPITAL_INVOICE_DETAIL: `${BASE_DASHBOARD_PATH}/capital/invoice/:id`,
  CAPITAL_PAST_FUNDING: `${BASE_DASHBOARD_PATH}/capital/past-funding`,
  CAPITAL_MANUAL_INVOICE: `${BASE_DASHBOARD_PATH}/capital/manual-invoice`,
  CAPITAL_CREATE_MANUAL_INVOICE: `${BASE_DASHBOARD_PATH}/capital/manual-invoice/create`,
  CAPITAL_INVOICE_FORM: `${BASE_DASHBOARD_PATH}/capital/manual-invoice/form/:formType`,
  PAYMENT: `${BASE_DASHBOARD_PATH}/payment`,
  PAYMENT_OVERVIEW: `${BASE_DASHBOARD_PATH}/payment/overview`,
  PAYMENT_PAYMENTS: `${BASE_DASHBOARD_PATH}/payment/payments`,
  PAYMENT_DETAIL: `${BASE_DASHBOARD_PATH}/payment/payments/detail/:id`,
  PAYMENT_BALANCES: `${BASE_DASHBOARD_PATH}/payment/balance`,
  PAYMENT_TRANSACTIONS: `${BASE_DASHBOARD_PATH}/payment/transactions`,
  PAYMENT_SETUP: `${BASE_DASHBOARD_PATH}/payment/setup`,
  WALLET: `${BASE_DASHBOARD_PATH}/wallet`,
  WALLET_OVERVIEW: `${BASE_DASHBOARD_PATH}/wallet/overview`,
  WALLET_BALANCES: `${BASE_DASHBOARD_PATH}/wallet/overview/balances`,
  WALLET_BALANCES_CURRENCY: `${BASE_DASHBOARD_PATH}/wallet/overview/balances/:currency`,
  WALLET_TRANSACTIONS: `${BASE_DASHBOARD_PATH}/wallet/overview/transactions`,
  WALLET_GLOBAL_ACCOUNTS: `${BASE_DASHBOARD_PATH}/wallet/global-accounts`,
  WALLET_GLOBAL_ACCOUNTS_OVERVIEW: `${BASE_DASHBOARD_PATH}/wallet/global-accounts/overview`,
  WALLET_GLOBAL_ACCOUNT_ID: `${BASE_DASHBOARD_PATH}/wallet/global-accounts/overviews/accounts/:globalAccountId`,
  WALLET_GLOBAL_ACCOUNT_ACTIVITY: `${BASE_DASHBOARD_PATH}/wallet/global-accounts/account-activity`,
  WALLET_CARDS: `${BASE_DASHBOARD_PATH}/wallet/cards`,
  WALLET_CARDS_TRANSACTIONS: `${BASE_DASHBOARD_PATH}/wallet/cards/transactions`,
  WALLET_CARDS_SETTINGS: `${BASE_DASHBOARD_PATH}/wallet/cards/settings`,
  WALLET_CARDS_CREATE: `${BASE_DASHBOARD_PATH}/wallet/cards/create`,
  WALLET_CARDS_CREATE_SELECT_TYPE: `${BASE_DASHBOARD_PATH}/wallet/cards/create/select`,
  WALLET_CARDS_CREATE_FORM: `${BASE_DASHBOARD_PATH}/wallet/cards/create/:type`,
  WALLET_CARDS_CARD_REVIEW: `${BASE_DASHBOARD_PATH}/wallet/cards/review/card/:id`,
  WALLET_CARDS_CARD_DETAIL: `${BASE_DASHBOARD_PATH}/wallet/cards/card/:id`,
  WALLET_CARDS_EDIT_COMPANY_CARD: `${BASE_DASHBOARD_PATH}/wallet/cards/edit-company-card/:id`,
  WALLET_SETUP: `${BASE_DASHBOARD_PATH}/wallet/overview/setup`,
  WALLET_TRANSFER: `${BASE_DASHBOARD_PATH}/wallet/transfer`,
  WALLET_TRANSFER_PAYOUTS: `${BASE_DASHBOARD_PATH}/wallet/transfer/payouts`,
  WALLET_TRANSFER_CREATE_PAYOUTS: `${BASE_DASHBOARD_PATH}/wallet/transfer/payouts/create`,
  WALLET_TRANSFER_PAYOUT_DETAIL: `${BASE_DASHBOARD_PATH}/wallet/transfer/payouts/item/:paymentId`,
  WALLET_CONTACT: `${BASE_DASHBOARD_PATH}/wallet/contact`,
  WALLET_CONTACT_LIST: `${BASE_DASHBOARD_PATH}/wallet/contact/all`,
  WALLET_CONTACT_RECIPIENT: `${BASE_DASHBOARD_PATH}/wallet/contact/recipient`,
  WALLET_CONTACT_NEW_RECIPIENT: `${BASE_DASHBOARD_PATH}/wallet/contact/recipient/create`,
  WALLET_CONVERSION: `${BASE_DASHBOARD_PATH}/wallet/conversions`,
  WALLET_CONVERSION_VIEW_CONVERSION_ID: `${BASE_DASHBOARD_PATH}/wallet/conversions/:conversionId`,
  WALLET_CONVERSION_CREATE_CONVERSION: `${BASE_DASHBOARD_PATH}/wallet/conversions/new`,
};

export const getApplicationPathByApplicationStatusCode = (statusCode) => {
  if (statusCode >= STATUS_CODE.PENDING_USER_ACCEPT_OFFER) {
    return APPLICATION_PATH.AGREEMENT;
  } else if (statusCode === STATUS_CODE.PENDING_CREDIT_TEAM_REVIEW) {
    return APPLICATION_PATH.COMPANY;
  } else if (statusCode === STATUS_CODE.PENDING_USER_REVIEW_TERM_SHEET) {
    return APPLICATION_PATH.TERM_SHEET;
  } else {
    return APPLICATION_PATH.SALES_ACCOUNTS;
  }
};

export default PATH;
