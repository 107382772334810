import React, { useEffect } from 'react';

import _ from 'lodash';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Button from '../../../../../components/Button';
import FormTitle from '../../../../../components/FormTitle';
import HR from '../../../../../components/HR';
import Input from '../../../../../components/Input';
import Select from '../../../../../components/Select';
import Logger from '../../../../../utils/logger';
import { Wrapper, FieldWrapper } from '../../../components';
import { Footer, ButtonWrapper } from './components';
import { PURPOSE_OPTIONS, FORM_NAME, BUSINESS_TYPE } from './constants';
import { countryMap } from '@/constants';
import { fetchFormSchema } from '@redux/modules/wallet/actions';
import { isRealObject } from '@utils/dataTypes';

const FIELD_BLACK_LIST = [
  'beneficiary.entity_type',
  'beneficiary.bank_details.bank_country_code',
  'beneficiary.bank_details.account_currency',
  'payment_method',
  'beneficiary.bank_details.local_clearing_system',
  'beneficiary.date_of_birth',
];

const ENTITY_FIELD_ORDER = [
  'company_name',
  'first_name',
  'last_name',
  'nickname',
  'personal_email',
  'country_code',
  'street_address',
  'city',
  'state',
  'postcode',
];

const customFooter = {};

const NewRecipientForm = ({ control = {}, setFormName, handleSubmit, formState: { errors }, getValues, watch }) => {
  const watchOfPaymentMethod = watch('paymentMethod');
  const watchOfPaymentCurrency = watch('paymentCurrency');
  const watchOfBankCountryCode = watch('beneficiary.bank_details.bank_country_code');
  const watchOfBusinessType = watch('beneficiary.entity_type');
  const watchOfAddressCountryCode = watch('beneficiary.address.country_code');
  const recipientSchema = useSelector((state) => state.wallet.schema[FORM_NAME.RECIPIENT]);

  useEffect(() => {
    if (watchOfPaymentCurrency && watchOfBankCountryCode && watchOfBusinessType) {
      const { paymentMethod, paymentCurrency, beneficiary } = getValues();
      fetchFormSchema(FORM_NAME.RECIPIENT, {
        ...(beneficiary.address?.country_code
          ? {
              bank_country_code: beneficiary.address.country_code,
            }
          : {
              bank_country_code: beneficiary.bank_details.bank_country_code,
            }),
        account_currency: paymentCurrency,
        entity_type: beneficiary.entity_type,
        payment_method: paymentMethod === 'SWIFT' ? 'SWIFT' : 'LOCAL',
        ...(paymentMethod !== 'SWIFT' && {
          local_clearing_system: paymentMethod,
        }),
      });
    }
  }, [watchOfPaymentCurrency, watchOfBankCountryCode, watchOfBusinessType, watchOfAddressCountryCode]);

  const {
    beneficiary: { entity_type },
  } = getValues();

  if (!isRealObject(recipientSchema)) {
    return null;
  }

  const whiteListOfRecipientFields = recipientSchema.fields.filter(({ path }) => FIELD_BLACK_LIST.indexOf(path) === -1);

  const getFooter = (field) => {
    return customFooter[field.key] || field.description;
  };

  const getFields = () => {
    const bankFields = whiteListOfRecipientFields.filter(({ path }) => path.indexOf('bank_details') !== -1);
    const entityFields = whiteListOfRecipientFields
      .filter(({ path }) => path.indexOf('bank_details') === -1)
      .map((schema) => {
        const order = ENTITY_FIELD_ORDER.indexOf(schema.field.key);
        return { ...schema, order };
      })
      .sort((a, b) => a.order - b.order);

    return {
      bankFields,
      entityFields,
    };
  };

  const { bankFields, entityFields } = getFields();

  const renderField = ({ enabled, field, path, required, rule }) => {
    if (enabled !== true) {
      return null;
    }

    const footer = getFooter(field);

    return (
      <FieldWrapper key={path}>
        <FormTitle scale={5} required={required}>
          {field.label === 'Aba' ? 'Routing number' : field.label}
        </FormTitle>
        {(() => {
          const controllerProps = {
            control,
            name: path,
            rules: {
              required,
              ...(rule?.pattern && { pattern: new RegExp(rule.pattern) }),
            },
          };

          const hasError = _.get(errors, path);
          switch (field.type) {
            case 'INPUT':
              return (
                <Controller
                  {...controllerProps}
                  render={({ field: controllerField }) => {
                    return <Input key={path} {...controllerField} hasError={hasError} full />;
                  }}
                />
              );
            case 'SELECT':
              return (
                <Controller
                  {...controllerProps}
                  render={({ field: controllerField }) => {
                    return (
                      <Select
                        key={path}
                        {...controllerField}
                        hasError={hasError}
                        options={field.options}
                        full
                        {...(field.key === 'country_code' && { disabled: true })}
                      />
                    );
                  }}
                />
              );
            default:
              return null;
          }
        })()}
        {footer && <Footer>{footer}</Footer>}
      </FieldWrapper>
    );
  };

  const handleBack = (event) => {
    event.preventDefault();
    setFormName(FORM_NAME.TRANSFER);
  };

  const onSubmit = (data) => {
    setFormName(FORM_NAME.REVIEW);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormTitle scale={4.5}>Bank account details</FormTitle>
      {bankFields.map(renderField)}
      <HR color="grayTwo" height={1} />
      <FormTitle scale={4.5}>{entity_type === BUSINESS_TYPE.COMPANY ? 'Business details' : 'Personal details'}</FormTitle>
      {entityFields.map(renderField)}
      <ButtonWrapper>
        <Button width={160} type="button" secondary onClick={handleBack}>
          Back
        </Button>
        <Button width={160} type="submit">
          Next
        </Button>
      </ButtonWrapper>
    </form>
  );
};

export default NewRecipientForm;
