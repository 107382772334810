import React, { useRef } from 'react';

import moment from 'moment';
import styled, { css } from 'styled-components';

import Portal from './Portal';
import { calendarShortcutOptions } from '@constants/options';
import { useOnClickOutside, useModal } from '@hooks/UI';
import { convertDate, SLASH_DD_MM_YYYY_FORMAT } from '@utils/dateHelpers';
import { getOptionLabelByValue } from '@utils/optionHelpers';

const Wrapper = styled.div<{ disabled: boolean }>`
  position: relative;
  display: inline-block;

  .tag {
    ${(props) => props.theme.input.border};
    display: inline-block;
    padding: 11px 16px;
    background-color: ${(props) => props.theme.colors.white};
    cursor: pointer;

    img,
    span {
      vertical-align: middle;
    }

    span {
      margin-left: 8px;
    }
    ${(props) =>
      props.disabled &&
      css`
        cursor: not-allowed;
        background-color: ${props.theme.colors.grayOne} !important;
      `};
  }
`;

const Tag = styled.div<{ type: string }>``;

const DateRangePlaceholder = styled.span`
  color: ${(props) => props.theme.colors.grayTwo};
`;

const FancyDateRangePicker = ({
  className,
  startDate,
  endDate,
  dateShortcutValue,
  onApply,
  disabled,
  includeToday,
  displayFormat = SLASH_DD_MM_YYYY_FORMAT,
}: {
  className?: string;
  startDate: string;
  endDate: string;
  dateShortcutValue?: string;
  onApply?: ((calendarData: Record<string, unknown>) => void) | (() => void);
  disabled?: boolean;
  includeToday?: boolean;
  displayFormat: string;
}) => {
  const ref: React.RefObject<HTMLDivElement> = useRef();
  const [isCalendarShown, toggleIsCalendarShown] = useModal(false);
  const dateShortcutDisplayLabel = dateShortcutValue
    ? getOptionLabelByValue(dateShortcutValue, calendarShortcutOptions)
    : startDate && endDate
    ? `${convertDate(startDate, displayFormat)} - ${convertDate(endDate, displayFormat)}`
    : '';

  const togglePortal = () => {
    if (!disabled) {
      toggleIsCalendarShown(!isCalendarShown);
    }
  };

  useOnClickOutside(ref, () => toggleIsCalendarShown(false));

  return (
    <Wrapper ref={ref} className={className} disabled={disabled}>
      <Tag type="button" onClick={togglePortal} className="tag">
        <img src="/icons/calendar.svg" alt="toolTip" />
        {dateShortcutDisplayLabel ? <span>{dateShortcutDisplayLabel}</span> : <DateRangePlaceholder>{displayFormat}</DateRangePlaceholder>}
      </Tag>
      {isCalendarShown && (
        <Portal
          idPrefix="fancy_date_range_picker"
          startDate={startDate}
          endDate={endDate}
          dateShortcutValue={dateShortcutValue}
          togglePortal={togglePortal}
          onApply={onApply}
          className="portal"
          includeToday={includeToday}
          displayFormat={displayFormat}
          innerRef={undefined}
        />
      )}
    </Wrapper>
  );
};

export default FancyDateRangePicker;
