import {
  STATUS_CODE,
  TRANSITION_INVESTMENT_MANAGER_REJECT_APPLICATION,
  TRANSITION_CREDIT_TEAM_REJECT_APPLICATION,
  TRANSITION_INVESTMENT_MANAGER_DISAPPROVE_PROPOSAL,
  TRANSITION_CREDIT_TEAM_DISAPPROVE_PROPOSAL,
} from '@constants/application';

export const isRejectedByCreditTeam = (applicationHistory = []) => {
  const states = applicationHistory.map(({ to_state }) => to_state);
  const isRejected = states.indexOf('rejected') !== -1;
  if (isRejected && Array.isArray(applicationHistory) && applicationHistory.length > 0) {
    const transitions = applicationHistory.map(({ transition }) => transition);
    return transitions.indexOf(TRANSITION_CREDIT_TEAM_REJECT_APPLICATION) !== -1;
  }

  return false;
};

export const isTermSheetReturned = (applicationHistory = []) => {
  if (Array.isArray(applicationHistory) && applicationHistory.length > 0) {
    return (
      [TRANSITION_INVESTMENT_MANAGER_DISAPPROVE_PROPOSAL, TRANSITION_CREDIT_TEAM_DISAPPROVE_PROPOSAL].indexOf(
        applicationHistory[applicationHistory.length - 1].transition,
      ) !== -1
    );
  }

  return false;
};
