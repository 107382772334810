import React from 'react';

import Creatable from 'react-select/creatable';
import styled from 'styled-components';

import { getSelectedOptionValue } from './helpers';
import { dynamicStyles, baseSelectStyles } from './styles';

const StyledSelect = styled(Creatable)`
  ${baseSelectStyles};
`;

const CreatableSelect = ({ value, options, disabled, onChange = () => {}, innerRef, components, ...restProps }) => {
  const selectedOption = getSelectedOptionValue(value, options);

  const handleChange = (option) => {
    onChange(option?.value ?? null);
  };

  return (
    <StyledSelect
      ref={innerRef}
      classNamePrefix="react-select"
      components={{
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
        ...components,
      }}
      value={selectedOption}
      options={options}
      onChange={handleChange}
      isDisabled={disabled}
      styles={dynamicStyles}
      isCreatable
      {...restProps}
    />
  );
};

export default CreatableSelect;
