import React, { useState, useEffect } from 'react';

import countries from 'i18n-iso-countries';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import HR from '../../../../../components/HR';
import Logger from '../../../../../utils/logger';
import { WALLET_COUNTRIES, GlobalAccountBankAddress } from '../../constants';
import { getGlobalAccountById } from '@api/modules/integration/airwallex';
import { isRealObject } from '@utils/dataTypes';

const Header = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
`;

const Description = styled.div`
  margin: 20px 0;
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
`;

const FlagWrapper = styled.div`
  display: flex;
  margin: 12px 0;
`;

const CurrencyWrapper = styled.div`
  padding-left: 14px;
`;

const CurrencyHeader = styled.div`
  font-weight: 600;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CopyButton = styled.button`
  border: none;
  background: white;

  :hover {
    background: ${(props) => props.theme.colors.greyTwo};
    opacity: 0.5;
    cursor: pointer;
  }
`;

const Title = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  font-weight: 600;
  margin: 6px 0;
`;

const Li = styled.li`
  ${(props) => props.theme.text.five};
  font-weight: 600;
  margin: 10px 0;
`;

const InfoText = styled.div`
  ${(props) => props.theme.text.five};
`;

const CreateGlobalAccountSuccess = ({ id, accountDetails }) => {
  return (
    <>
      <Header>{accountDetails?.currency} Global Account is created</Header>
      <Description>
        To receive {accountDetails?.currency}, simply input these details into your payment gateway (e.g. Stripe) or provide them to a 3rd
        party
      </Description>
      <FlagWrapper>
        <img src={WALLET_COUNTRIES[accountDetails?.currency]?.logoUrl} alt="country flags" />
        <CurrencyWrapper>
          <CurrencyHeader>{accountDetails?.currency}</CurrencyHeader>
          {WALLET_COUNTRIES[accountDetails?.currency]?.displayName}
        </CurrencyWrapper>
      </FlagWrapper>
      <div>
        <Title>Account name</Title>
        <Container>
          {accountDetails?.account_name}
          <CopyButton
            onClick={() => {
              navigator.clipboard.writeText(accountDetails?.account_name);
            }}
          >
            <img src="/icons/copy-icon.svg" alt="copy" />
          </CopyButton>
        </Container>
        <Title>Bank account number</Title>
        <Container>
          {accountDetails?.account_number}
          <CopyButton
            onClick={() => {
              navigator.clipboard.writeText(accountDetails?.account_number);
            }}
          >
            <img src="/icons/copy-icon.svg" alt="copy" />
          </CopyButton>
        </Container>
        {accountDetails?.iban && (
          <>
            <Title>IBAN</Title>
            <Container>
              {accountDetails?.iban}
              <CopyButton
                onClick={() => {
                  navigator.clipboard.writeText(accountDetails?.iban);
                }}
              >
                <img src="/icons/copy-icon.svg" alt="copy" />
              </CopyButton>
            </Container>
          </>
        )}
        {Array.isArray(accountDetails?.routing_codes) && accountDetails?.routing_codes?.[1]?.value && (
          <>
            <Title>{accountDetails?.routing_codes?.[0]?.type === 'ach' ? 'ACH routing number' : 'Bank code'}</Title>
            <Container>
              {accountDetails?.routing_codes?.[0]?.value}
              <CopyButton
                onClick={() => {
                  navigator.clipboard.writeText(accountDetails?.routing_codes?.[0]?.value);
                }}
              >
                <img src="/icons/copy-icon.svg" alt="copy" />
              </CopyButton>
            </Container>
          </>
        )}
        {Array.isArray(accountDetails?.routing_codes) && accountDetails?.routing_codes?.[0]?.type === 'bsb' ? (
          <>
            <Title>BSB code</Title>
            <Container>
              {accountDetails?.routing_codes?.[0]?.value}
              <CopyButton
                onClick={() => {
                  navigator.clipboard.writeText(accountDetails?.routing_codes?.[0]?.value);
                }}
              >
                <img src="/icons/copy-icon.svg" alt="copy" />
              </CopyButton>
            </Container>
          </>
        ) : Array.isArray(accountDetails?.routing_codes) && accountDetails?.routing_codes.length !== 0 ? (
          <>
            <Title>{accountDetails?.routing_codes?.[1]?.type === 'fedwire' ? 'Fedwire routing number' : 'Branch code'}</Title>
            <Container>
              {accountDetails?.routing_codes?.[1]?.value}
              <CopyButton
                onClick={() => {
                  navigator.clipboard.writeText(accountDetails?.routing_codes?.[1]?.value);
                }}
              >
                <img src="/icons/copy-icon.svg" alt="copy" />
              </CopyButton>
            </Container>
          </>
        ) : null}
        {Array.isArray(accountDetails?.capability?.payment_methods) && accountDetails?.capability?.payment_methods.indexOf('SWIFT') !== -1 && (
          <>
            <Title>Swift code</Title>
            <Container>
              {accountDetails?.swift_code}
              <CopyButton
                onClick={() => {
                  navigator.clipboard.writeText(accountDetails?.swift_code);
                }}
              >
                <img src="/icons/copy-icon.svg" alt="copy" />
              </CopyButton>
            </Container>
          </>
        )}
        <Title>Account location</Title>
        <Container>
          {accountDetails?.country_code === 'HK'
            ? 'Hong Kong (China)'
            : countries.getName(accountDetails?.country_code, 'en', { select: 'official' })}
        </Container>
      </div>
      <HR color="grayOne" />
      <Title>Bank name</Title>
      <Container>{accountDetails?.institution?.name}</Container>
      {accountDetails?.institution?.address && (
        <>
          <Title>Bank address</Title>
          <Container>
            {accountDetails?.institution?.address}
            <CopyButton
              onClick={() => {
                navigator.clipboard.writeText(accountDetails?.institution?.address);
              }}
            >
              <img src="/icons/copy-icon.svg" alt="copy" />
            </CopyButton>
          </Container>
        </>
      )}
      <HR color="grayOne" />
      {Array.isArray(accountDetails?.capability?.clearing_systems) && (
        <>
          <Title>Account capabilities</Title>
          <Description>Accept funds via</Description>
          <ul>
            {accountDetails?.capability?.clearing_systems.map((paymentType) => {
              return <Li>{paymentType}</Li>;
            })}
            {accountDetails?.country_code === 'HK' && <Li>International SWIFT Transfer</Li>}
          </ul>
        </>
      )}
    </>
  );
};

export default CreateGlobalAccountSuccess;
