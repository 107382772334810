import React from 'react';

import styled from 'styled-components';

import { pageSizeOptions as PAGE_SIZE_OPTIONS } from '../../constants/options';
import { baseStyle } from './styles';

const Wrapper = styled.div`
  ${baseStyle};
`;

const EndLabel = styled.div`
  text-align: center;
`;

const SimplePagination = ({
  pageNumber,
  pageSize,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  onPageNumberChange = () => {},
  onPageSizeChange = () => {},
  formatPageNavLabel = (number) => {
    return ` ${number} `;
  },
  disabled,
  isLoading,
  noNextPage,
}) => {
  const inFirstPage = pageNumber === 1;

  const handlePageSizeChange = (event) => {
    onPageSizeChange(Number(event.target.value));
  };

  const goToPreviousPage = () => {
    onPageNumberChange(pageNumber - 1);
  };

  const goToNextPage = () => {
    onPageNumberChange(pageNumber + 1);
  };
  return (
    <Wrapper>
      {noNextPage && !isLoading && <EndLabel>End</EndLabel>}
      <button type="button" onClick={goToPreviousPage} disabled={inFirstPage || disabled}>
        {'<'}
      </button>
      <span>{formatPageNavLabel(pageNumber)}</span>
      <button type="button" disabled={disabled || noNextPage} onClick={goToNextPage}>
        {'>'}
      </button>
      <select onChange={handlePageSizeChange} value={pageSize} disabled={disabled}>
        {pageSizeOptions.map((option) => {
          return (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </Wrapper>
  );
};

export default SimplePagination;
