import React, { useState } from 'react';

import parsePhoneNumber from 'libphonenumber-js';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../components/Button';
import Checkbox, { CheckboxText, StyledCheckboxContainer } from '../../../components/Checkbox';
import FormTitle from '../../../components/FormTitle';
import { FormRow as GridFormRow, GridColumn } from '../../../components/Grid';
import Input from '../../../components/Input';
import PasswordInput from '../../../components/PasswordInput';
import PhoneInput from '../../../components/PhoneInput';
import Select from '../../../components/Select';
import HINT from '../../../utils/hint';
import { FormRow, Form, Error, Container, ChocoUpLogo, Title, ContentDiv, CenterDiv } from '../components/styled';
import SwitchForm from '../components/SwitchForm';
import PATH from '../path';
import { leadSourceTypeOptions } from '@constants/options';
import { useQuery } from '@hooks/routing';
import { media } from '@styles/breakpoints';
import { emailRegExp, passwordRegExp } from '@utils/validators';

export const TextLink = styled.a`
  ${(props) => props.theme.text.link};
`;

export const PreviousStep = styled(Link)`
  ${(props) => props.theme.text.link};
  font-size: 20px;
  line-height: 30px;
`;

export const LinkDiv = styled.div`
  padding-bottom: 18px;
`;

export const Logo = styled(ChocoUpLogo)`
  margin: 14px 0;
`;

export const FormGridColumn = styled(GridColumn)`
  padding: 10px 12px 0 12px;
  ${media.phone`
    padding: 0;
  `};
`;

const UserFormTitle = styled(Title)`
  margin-bottom: 5px;
  ${media.phone`
   line-height: 30px;
  `};
`;

const UserFormContainer = styled(Container)`
  height: 100vh;
  ${media.phone`
    height: initial;
  `};
`;

const StyledContentDiv = styled(ContentDiv)`
  height: 72%;
  width: 1050px;
  padding: 30px 0;
  margin-bottom: 20px;
`;

const UserForm = ({ formData, onNext, registerError, bypassBusinessForm, onUserFormClick, isLoading }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    defaultValues: formData.userForm,
  });
  const query = useQuery();
  const watchAllFields = watch();
  const leadSourceType = getValues('leadSourceType');
  const [country, setCountry] = useState(formData.businessForm?.country);
  const [checkCountry, setCheckCountry] = useState(formData.businessForm?.country);

  return (
    <UserFormContainer>
      <StyledContentDiv>
        <Form onSubmit={handleSubmit(onNext)}>
          <LinkDiv>
            {!bypassBusinessForm && (
              <PreviousStep
                to={`${PATH.REGISTER_BUSINESS_INFO}${query.search}`}
                onClick={() => onUserFormClick(watchAllFields)}
                disabled={isLoading}
              >
                {'< '}
                Previous Step
              </PreviousStep>
            )}
          </LinkDiv>
          <Logo src="/images/choco-up-black-no-title.png" alt="Choco Up Logo" />
          <UserFormTitle>Almost done with registration</UserFormTitle>
          <GridFormRow>
            <FormGridColumn lg={6} md={6} sm={12}>
              <FormTitle scale={5} required>
                Your First Name
              </FormTitle>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <Input {...field} placeholder="Joe" hasError={errors.firstName} disabled={isLoading} full />}
              />
            </FormGridColumn>
            <FormGridColumn lg={6} md={6} sm={12}>
              <FormTitle scale={5} required>
                Your Last Name
              </FormTitle>
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <Input {...field} placeholder="Sixpack" hasError={errors.lastName} disabled={isLoading} full />}
              />
            </FormGridColumn>
          </GridFormRow>

          <FormRow>
            <FormTitle scale={5} required>
              Your Mobile Number
            </FormTitle>
            <Controller
              name="mobileNumber"
              control={control}
              rules={{
                required: true,
                validate: (value) => {
                  const phoneNumber = parsePhoneNumber(value, checkCountry);
                  return phoneNumber?.isValid();
                },
              }}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  hasError={errors.mobileNumber}
                  country={country}
                  onCountryChange={setCheckCountry}
                  disabled={isLoading}
                />
              )}
            />
          </FormRow>
          <FormRow>
            <FormTitle scale={5} required>
              Work Email Address
            </FormTitle>
            <Controller
              name="email"
              control={control}
              rules={{ required: true, pattern: emailRegExp }}
              render={({ field }) => <Input {...field} placeholder="joe@company.com" hasError={errors.email} disabled={isLoading} full />}
            />
            {errors.email?.type === 'pattern' && <Error>Email format does not match</Error>}
          </FormRow>
          <FormRow>
            <FormTitle scale={5} tip={HINT.PASSWORD_PATTERN} required>
              Password
            </FormTitle>
            <Controller
              name="password"
              control={control}
              rules={{ required: true, pattern: passwordRegExp }}
              render={({ field }) => (
                /* Reference: solve auto fill problem https://stackoverflow.com/a/47557635 */
                <PasswordInput {...field} hasError={errors.password} autoComplete="new-password" disabled={isLoading} full />
              )}
            />
            {errors.password?.type === 'pattern' && <Error>{HINT.PASSWORD_PATTERN}</Error>}
          </FormRow>
          <FormRow>
            <FormTitle scale={5} required>
              Where do you hear about us?
            </FormTitle>
            <Controller
              name="leadSourceType"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select {...field} options={leadSourceTypeOptions} hasError={errors.leadSourceType} disabled={isLoading} />
              )}
            />
          </FormRow>
          {leadSourceType === 'OTHER' && (
            <FormRow>
              <FormTitle scale={5} required>
                Other
              </FormTitle>
              <Controller
                name="leadSourceDetail"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input {...field} placeholder="Enter a source name" hasError={errors.leadSourceDetail} maxLength={1000} full />
                )}
              />
            </FormRow>
          )}

          <FormRow>
            <Controller
              name="optInEmails"
              control={control}
              render={({ field }) => <Checkbox {...field} textSide="Send me offers and updates about Choco Up" disabled={isLoading} />}
            />
          </FormRow>
          <FormRow>
            <Controller
              name="agreement"
              control={control}
              rules={{
                validate: (value) => value === true,
              }}
              render={({ field }) => (
                <>
                  <StyledCheckboxContainer textSide=" ">
                    <Checkbox {...field} textSide=" " disabled={isLoading} />
                    <CheckboxText>
                      <strong>I agree to Choco Up’s</strong>
                      <TextLink href="https://insight.choco-up.com/terms-conditions" target="_blank">
                        <strong> Terms of Use</strong>
                      </TextLink>{' '}
                      <strong>and</strong>{' '}
                      <TextLink href="https://insight.choco-up.com/privacy-policy" target="_blank">
                        <strong>Privacy Policy</strong>
                      </TextLink>
                      <strong>.</strong>
                    </CheckboxText>
                  </StyledCheckboxContainer>
                </>
              )}
            />

            {errors.agreement && <Error>You must agree to continue</Error>}
          </FormRow>
          {registerError && <Error>{registerError}</Error>}
          <FormRow>
            <Button type="submit" disabled={isLoading} full>
              Create Account
            </Button>
          </FormRow>
          <CenterDiv>
            <SwitchForm target={PATH.LOGIN} />
          </CenterDiv>
        </Form>
      </StyledContentDiv>
    </UserFormContainer>
  );
};

export default UserForm;
