import React from 'react';

import parsePhoneNumber from 'libphonenumber-js';
import ReactPhoneInput from 'react-phone-input-2';
import styled, { css } from 'styled-components';

import 'react-phone-input-2/lib/style.css';
import COLORS from '../styles/colors';
import { toUpperCase } from '@utils/stringHelpers';

const Wrapper = styled.div`
  box-sizing: border-box;
  border-radius: 5px;
  line-height: 24px;
  background-color: ${(props) => props.theme.colors.white};

  .react-tel-input {
    .form-control {
      padding-left: 56px;
      width: 100%;
      padding: 24px 14px 24px 72px;
      border: 1px solid ${COLORS.grayTwo};
      background-color: transparent;

      ${(props) =>
        props.hasError &&
        css`
          border: 1px solid ${COLORS.red};
          box-shadow: inset 1px 0 ${COLORS.red}, inset 0 1px ${COLORS.red}, inset -1px 0 ${COLORS.red}, inset 0 -1px ${COLORS.red};
          color: ${COLORS.red};
        `}

      :focus {
        border: 1px solid ${COLORS.black};
        box-shadow: inset 1px 0 ${COLORS.black}, inset 0 1px ${COLORS.black}, inset -1px 0 ${COLORS.black}, inset 0 -1px ${COLORS.black};
        color: ${COLORS.black};
      }
    }

    /* .react-tel-input .flag-dropdown.open .selected-flag  */
    .flag-dropdown {
      border: none !important;
      padding: 2px; // offset input border width
      background-color: transparent !important;
    }

    .selected-flag {
      width: 52px;
      padding: 0 0 0 24px;

      .arrow {
        left: 29px;
        margin-top: -1px;
      }
    }

    // align with input
    ${(props) =>
      props.disabled &&
      css`
        .form-control,
        .flag-dropdown {
          background-color: rgba(239, 239, 239, 0.3) !important;
          cursor: initial;
        }
      `};
  }
`;

const PhoneInput = ({ hasError, onCountryChange, onChange, country, disabled, ...restProps }) => {
  return (
    <Wrapper hasError={hasError} disabled={disabled}>
      <ReactPhoneInput
        country={typeof country === 'string' ? country.toLowerCase() : ''}
        disabled={disabled}
        onChange={(value, countryObj, event, formattedValue) => {
          onChange(value, countryObj, event, formattedValue);

          // hack to check number; do not abuse further more
          onCountryChange(toUpperCase(countryObj?.countryCode));
        }}
        {...restProps}
      />
    </Wrapper>
  );
};

export default PhoneInput;
