import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

const Container = styled.div`
  display: ${(props) => (props.visible === true ? 'block' : 'none')};
`;

const timeRemaining = 45;

// Countdown is a component that is used for counting down and then
// refresh the Tableau embedded report.
const Countdown = ({ refreshTableau, tableauViz }) => {
  const [remainingTime, setRemainingTime] = useState(timeRemaining);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (remainingTime !== 0) {
        setRemainingTime((prev) => prev - 1);
      }
    }, 1000);

    if (remainingTime === 0) {
      setVisible(false);
      refreshTableau(tableauViz);
    }

    return () => clearTimeout(timer);
  }, [remainingTime]);

  return (
    <Container visible={visible}>
      Refreshing Tableau in &nbsp;
      <span>{remainingTime}</span>
      &nbsp; seconds
    </Container>
  );
};

export default Countdown;
