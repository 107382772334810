import React from 'react';

import styled from 'styled-components';

import Button from '../../../../../components/Button';
import FancyDateRangePicker from '../../../../../components/FancyDateRangePicker';
import Modal from '../../../../../components/Modal';
import Select from '../../../../../components/Select';

const Wrapper = styled.div`
  height: 60vh;
`;

const SectionHeader = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
  margin-bottom: 12px;
`;

const StyledFancyDateRangePicker = styled(FancyDateRangePicker)`
  .portal {
    right: initial;
  }
`;

const ModalHeader = styled.div`
  ${(props) => props.theme.text.five};
  margin-bottom: 4px;
`;

const StyledSelect = styled(Select)`
  width: 350px;
  font-weight: 600;
`;

const Section = styled.div`
  margin: 30px 0;
`;

const BalanceReportModal = () => {
  return (
    <Modal showCloseButton>
      <Wrapper>
        <SectionHeader>Download balance activity report</SectionHeader>
        <Section>
          <StyledFancyDateRangePicker />
        </Section>
        <Section>
          <ModalHeader>Type</ModalHeader>
          <StyledSelect />
        </Section>
        <Section>
          <ModalHeader>Currency</ModalHeader>
          <StyledSelect />
        </Section>
        <Section>
          <ModalHeader>File format</ModalHeader>
        </Section>
        <Button half>Download</Button>
      </Wrapper>
    </Modal>
  );
};

export default BalanceReportModal;
