import React from 'react';

import Layout from '../Layout';

import Contact from './Contact';
import Featured from './Featured';
import FeaturedMobile from './FeaturedMobile';
import Insights from './Insights';
import Questions from './Questions';

const Home = () => {
  return (
    <Layout>
      <Contact />
      <FeaturedMobile />
      <Insights />
      <Featured />
      <Questions />
    </Layout>
  );
};

export default Home;
