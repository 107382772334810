import styles from "./Questions.module.scss"
const Questions = () => {
  const questions: {name: string, answer: string}[] = [
    {
      name: 'Why do I have to link my accounts?',
      answer: 'In order to minimise your work of providing documents, you can simply click a button to connect your analytics or online merchant platform accounts to us so you can save your time on more important matters.'
    },
    {
      name: 'Is my data safe?',
      answer: 'All information you share with Choco Up is secured during transport and at rest. We are committed to protecting your data with military grade encryption (RSA-4096 and AES-256). We also limit what we have access to. We can never see your login information for any of your connected accounts, we only have “read” level access to the data you share with us – in other words, we are never able to change or modify in any way your shared data. We have securely connected more than 25,000 customer data sources.'
    },
    {
      name: 'Where can I find help or find further growth advices?',
      answer: 'Our success team is standing by to help you with anything that may arise during your time with Choco Up. You can reach us by phone or email (support@choco-up.com). We also have our blog full of articles, videos and how-tos.'
    }
  ];

  const questionElements: JSX.Element[] = questions.map((question, index) => {
    return (
      <div key={index}>
        <div className={styles['questions__item']}>
          <div>
            <div className={styles['questions__accordion']}>
              <div className={styles['questions__accordion--subtitle']}>{question.name}</div>
            </div>
            <div className={styles['questions__accordion--pane']}>
              <div className={styles['questions__accordion--pane-content']}>
                <div className={styles['questions__accordion--pane-text']}>
                  <p>{question.answer}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  })

  return (
    <div>
      <h1 className={styles['questions__heading']}>Frequently asked questions</h1>
      <div className={styles['questions__container']}>
        <div>{questionElements}</div>
      </div>
    </div>
  )
}

export default Questions
