import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Button from '../../../../../components/Button';
import Dropdown, { DropdownOption } from '../../../../../components/Dropdown';
import SimplePagination from '../../../../../components/Pagination/SimplePagination';
import Table from '../../../../../components/Table';
import { Wrapper } from '../../../components';
import PATH from '../../../path';
import { getRecipientDisplayName } from '../../helpers';
import { deleteWalletBeneficiary } from '@api/modules/integration/airwallex';
import { usePagination } from '@hooks/UI';
import { MESSAGE_MODAL } from '@modules/Modal/constants';
import { showModal } from '@redux/modules/UI/actions';
import { fetchWalletBeneficiaries } from '@redux/modules/wallet/actions';
import { useWalletAccountId, useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';
import { media } from '@styles/breakpoints';
import { areRecordsFewerThanPageSize } from '@utils/utilHelpers';

const SummaryContainer = styled.div`
  display: flex;

  ${media.dablet`
    flex-direction: column;
  `};

  div:first-child {
    flex-grow: 2;

    div:first-child {
      ${(props) => props.theme.text.four};
    }
  }

  margin-bottom: 28px;
`;

export const contactColumns = [
  {
    Header: '',
    accessor: 'beneficiary.entity_type',
    Cell: ({ value }) => {
      return <img src={`/icons/${value === 'COMPANY' ? 'office-building' : 'user'}.svg`} alt={value} />;
    },
    getStyle: () => css`
      width: 27px;
      img {
        vertical-align: middle;
      }
    `,
  },
  {
    Header: 'Recipient name',
    accessor: 'beneficiary',
    Cell: ({ value }) => {
      return getRecipientDisplayName(value);
    },
  },
  {
    Header: 'Nickname',
    accessor: 'nickname',
    Cell: ({ value }) => {
      return value;
    },
  },
  {
    Header: 'Account currency',
    accessor: 'beneficiary.bank_details.account_currency',
    Cell: ({ value }) => {
      return value;
    },
  },
  {
    Header: 'Bank country / region',
    accessor: 'beneficiary.bank_details.bank_country_code',
    Cell: ({ value }) => {
      return value;
    },
  },
  {
    Header: 'Account identifier',
    accessor: 'beneficiary.bank_details',
    Cell: ({ value }) => {
      return value.account_number ? (
        <div>
          <div>{value.account_number}</div>
          <div>Account number</div>
        </div>
      ) : (
        ''
      );
    },
  },
  {
    Header: '',
    accessor: 'beneficiary_id',
    applyOnRowClick: false,
    Cell: ({ value, row, parentProps, disabled }) => {
      const dispatch = useDispatch();
      const history = useHistory();
      return (
        <Dropdown>
          <DropdownOption
            onClick={() => {
              if (!disabled) {
                dispatch(
                  showModal(MESSAGE_MODAL, {
                    title: `Do you confirm to delete ${getRecipientDisplayName(row.beneficiary)}`,
                    description: 'This action is irreversible.',
                    onConfirm: async () => {
                      const { statusCode } = await deleteWalletBeneficiary(parentProps.accountId, value);
                      fetchWalletBeneficiaries(parentProps.accountId, {
                        pageNumber: parentProps.pageNumber || 1,
                        pageSize: parentProps.pageSize,
                      });
                    },
                    rightButtonProps: {
                      label: 'Delete',
                    },
                  })
                );
              }
            }}
            warning
            disabled={disabled}
          >
            Delete
          </DropdownOption>
        </Dropdown>
      );
    },
  },
];

const List = () => {
  const history = useHistory();
  const { beneficiaryPageMap, isLoading } = useSelector((state) => state.wallet);
  const accountId = useWalletAccountId();
  const { pageNumber, pageSize, onPageNumberChange, onPageSizeChange } = usePagination({
    pageNumber: 1,
    pageSize: 20,
  });
  const data = beneficiaryPageMap[pageNumber];
  const disabled = useCheckIsAdminViewingAnotherCompany();

  const handleRowClick = (rowData) => {
    history.push(`${PATH.WALLET_CONTACT_RECIPIENT}/${rowData.beneficiary_id}`);
  };

  const noNextPage = areRecordsFewerThanPageSize(data, pageSize);

  useEffect(() => {
    if (pageNumber === 1) {
      fetchWalletBeneficiaries(accountId, {
        pageNumber,
        pageSize,
      });
    } else {
      onPageNumberChange(1);
    }
  }, [pageSize]);

  useEffect(() => {
    fetchWalletBeneficiaries(accountId, {
      pageNumber,
      pageSize,
    });
  }, [pageNumber]);

  return (
    <Wrapper>
      <SummaryContainer>
        <div>
          <div>Recipients</div>
          <div>Send money to your recipient</div>
        </div>
        <Button onClick={() => history.push(PATH.WALLET_CONTACT_NEW_RECIPIENT)} disabled={disabled}>
          New Recipient
        </Button>
      </SummaryContainer>
      <Table
        data={data}
        columns={contactColumns}
        onRowClick={handleRowClick}
        isLoading={isLoading}
        parentProps={{
          accountId,
          pageNumber,
          pageSize,
        }}
      />
      <SimplePagination
        pageNumber={pageNumber}
        pageSizeOptions={[{ value: 20, label: 20 }]}
        pageSize={pageSize}
        noNextPage={noNextPage}
        onPageNumberChange={onPageNumberChange}
        onPageSizeChange={onPageSizeChange}
      />
    </Wrapper>
  );
};

export default List;
