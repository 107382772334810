import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, generatePath } from 'react-router-dom';
import styled from 'styled-components';

import BackButton from '../../../../../components/BackButton';
import Button from '../../../../../components/Button';
import FormTitle from '../../../../../components/FormTitle';
import HR from '../../../../../components/HR';
import Logger from '../../../../../utils/logger';
import { Card } from '../../../components';
import PATH from '../../../path';
import { COMPANY_CARD_CONFIG } from '../../constants';
import { getLimitLabelByInterval } from '../helpers';
import { getWalletCardDetail, getCardSensitiveDetailLink } from '@api/modules/integration/airwallex';
import { SENSITIVE_CARD_DETAIL_MODAL } from '@modules/Modal/constants';
import { showModal } from '@redux/modules/UI/actions';
import { fetchWalletCardDetail } from '@redux/modules/wallet/actions';
import { useWalletAccountId } from '@redux/selectors';
import { formatPrice } from '@utils/priceHelpers';

const CardTitle = styled.div`
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  > div {
  }

  .cardStatus {
    color: ${(props) => props.theme.colors.green};
  }
`;

const GlobalSettingsDescription = styled.div`
  color: ${(props) => props.theme.colors.grayTwo};
`;

const ButtonWrapper = styled.div`
  ${(props) => props.theme.buttonWrapper};
`;

const StyledTable = styled.table`
  border-spacing: 0;

  td {
    font-weight: 700;
    width: 150px;
  }
`;

const CardBasicInfoWrapper = styled.div`
  text-align: center;
`;

const CardBasicInfo = styled.span`
  display: inline-block;
  line-height: 16.8px;
  font-size: 14px;
`;

const ReviewCard = () => {
  const history = useHistory();
  const { id } = useParams();
  const walletAccountId = useWalletAccountId();
  const detail = useSelector((state) => state.wallet.card.detailMap[id]);
  const [isLoading, toggleIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [urlExpiryTime, setUrlExpiryTime] = useState();
  const [url, setUrl] = useState();

  useEffect(() => {
    fetchWalletCardDetail(walletAccountId, id);
  }, [id]);

  const handleEditSettingsClick = () => {
    history.push(PATH.WALLET_CARDS_SETTINGS);
  };

  const handleEditCardClick = () => {
    history.push(generatePath(PATH.WALLET_CARDS_EDIT_COMPANY_CARD, { id }));
  };

  const handleCreateCardClick = () => {
    history.push(PATH.WALLET_CARDS_CREATE_SELECT_TYPE);
  };

  const handleViewDetailClick = async () => {
    toggleIsLoading(true);
    try {
      let link;
      if (moment().isAfter(urlExpiryTime)) {
        const { data } = await getCardSensitiveDetailLink(walletAccountId, id);
        setUrlExpiryTime(moment().add(1, 'minute'));
        setUrl(data);
        link = data;
      } else if (url) {
        link = url;
      } else {
        const { data } = await getCardSensitiveDetailLink(walletAccountId, id);
        setUrlExpiryTime(moment().add(1, 'minute'));
        setUrl(data);
        link = data;
      }
      dispatch(showModal(SENSITIVE_CARD_DETAIL_MODAL, { cardId: id, url: link }));
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  return (
    <>
      <BackButton path={PATH.WALLET_CARDS} />
      <FormTitle scale={3}>You have successfully created a company card!</FormTitle>
      It&apos;s active and ready when you are.
      <Card>
        <CardBasicInfoWrapper>
          <img src={COMPANY_CARD_CONFIG.imgUrlWithoutNumber} alt={COMPANY_CARD_CONFIG.type} />
          <CardBasicInfo>
            <div>Card Number</div>
            <div>{detail?.card_number}</div>
            <HR color="#ECECEC" margin={6} />
            <div>Expiry Date</div>
            <div>**/****</div>
            <HR color="#ECECEC" margin={6} />
            <div>Security Code</div>
            <div>***</div>
          </CardBasicInfo>
        </CardBasicInfoWrapper>
        <ButtonWrapper>
          <Button onClick={handleViewDetailClick} disabled={isLoading} secondary>
            View Card Details
          </Button>
        </ButtonWrapper>
      </Card>
      <Card>
        <CardTitle>
          <div>Company card summary</div>
          <span className="cardStatus">In Use</span>
        </CardTitle>
        <HR color="#ECECEC" margin={14} />
        <table>
          <tbody>
            <tr>
              <td>Nickname: </td>
              <td>{detail?.nick_name}</td>
            </tr>
            {Array.isArray(detail?.authorization_controls?.transaction_limits?.limits) &&
              detail?.authorization_controls?.transaction_limits?.limits.map(({ amount, interval }) => {
                return (
                  <tr>
                    <td>
                      {getLimitLabelByInterval(interval)}
                      :
                    </td>
                    <td>{formatPrice(detail?.authorization_controls?.transaction_limits.currency, amount)}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <ButtonWrapper>
          <Button onClick={handleCreateCardClick} secondary>
            Create Another Card
          </Button>
          <Button onClick={handleEditCardClick}>Edit Card</Button>
        </ButtonWrapper>
      </Card>
      <Card>
        <CardTitle>
          <div>Global card settings</div>
        </CardTitle>
        <HR color="#ECECEC" margin={14} />
        <StyledTable>
          <tbody>
            <tr>
              <td>Home currency:</td>
              <td> HKD</td>
            </tr>
            <tr>
              <td>Auto-conversion:</td>
              <td>On</td>
            </tr>
          </tbody>
        </StyledTable>
        <GlobalSettingsDescription>
          Card transactions can only be funded using the following ten currencies: AUD, CAD, CHF, EUR, GBP, HKD, JPY,
          NZD, SGD, USD. When spending in another currency, your home currency will be used to fund the transaction.
        </GlobalSettingsDescription>
        <ButtonWrapper>
          <Button onClick={handleEditSettingsClick} secondary>
            Edit Global Settings
          </Button>
        </ButtonWrapper>
      </Card>
    </>
  );
};

export default ReviewCard;
