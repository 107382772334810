import { css } from 'styled-components';

import PATH from '../path';
import { getColorByWalletStatus } from './helpers';
import { convertToApiDate } from '@utils/dateHelpers';
import { formatNumber } from '@utils/priceHelpers';
import { capitalize } from '@utils/stringHelpers';

export const walletBalanceCurrencyColumns = [
  {
    Header: 'Date',
    accessor: 'createdAt',
    Cell: ({ value }) => {
      return convertToApiDate(value);
    },
    getStyle: ({ value }) => {
      return css`
        width: 120px;
      `;
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => {
      return capitalize(value);
    },
    getStyle: ({ value }) => {
      return css`
        color: ${getColorByWalletStatus(value)};
      `;
    },
  },
  {
    Header: 'Type',
    accessor: 'sourceType',
    Cell: ({ value }) => {
      return capitalize(value);
    },
    getStyle: ({ value }) => {
      return css`
        width: 10%;
      `;
    },
  },
  {
    Header: 'Details',
    accessor: 'description',
    Cell: ({ value }) => {
      return value;
    },
  },
  {
    Header: 'Currency',
    accessor: 'currency',
    Cell: ({ value }) => {
      return value;
    },
    getStyle: ({ value }) => {
      return css`
        width: 100px;
      `;
    },
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    className: 'textRight',
    Cell: ({ value }) => {
      return formatNumber(value, 2);
    },
    getStyle: ({ value }) => {
      return css`
        max-width: 150px;
      `;
    },
  },
];

export const WALLET_TAB = {
  BALANCE: PATH.WALLET_BALANCES,
  TRANSACTION: PATH.WALLET_TRANSACTIONS,
};

export const WALLET_TAB_OPTIONS = [
  {
    value: WALLET_TAB.BALANCE,
    label: 'Balances',
  },
  {
    value: WALLET_TAB.TRANSACTION,
    label: 'Transactions',
  },
];

export const GLOBAL_ACCOUNTS_TAB = {
  OVERVIEW: PATH.WALLET_GLOBAL_ACCOUNTS_OVERVIEW,
  ACCOUNT_ACTIVITY: PATH.WALLET_GLOBAL_ACCOUNT_ACTIVITY,
};

export const GLOBAL_ACCOUNTS_TAB_OPTIONS = [
  {
    value: GLOBAL_ACCOUNTS_TAB.OVERVIEW,
    label: 'Overview',
  },
  {
    value: GLOBAL_ACCOUNTS_TAB.ACCOUNT_ACTIVITY,
    label: 'Account activity',
  },
];

export const CARD_TAB_OPTIONS = [
  {
    value: PATH.WALLET_CARDS,
    label: 'Summary',
  },
  {
    value: PATH.WALLET_CARDS_TRANSACTIONS,
    label: 'Transactions',
  },
  {
    value: PATH.WALLET_CARDS_SETTINGS,
    label: 'Settings',
  },
];

export const CARD_TYPE = {
  COMPANY_CARD: 'COMPANY_CARD',
  EMPLOYEE_CARD: 'EMPLOYEE_CARD',
};

export const CARD_OPTIONS = [
  {
    value: CARD_TYPE.COMPANY_CARD,
    label: 'Company card',
  },
  {
    value: CARD_TYPE.EMPLOYEE_CARD,
    label: 'Employee card',
  },
];

export const WALLET_COUNTRIES = {
  USD: {
    displayName: 'United States Dollar',
    logoUrl: '/icons/us-flag.svg',
  },
  HKD: {
    displayName: 'Hong Kong Dollar',
    logoUrl: '/icons/hk-flag.svg',
  },
  SGD: {
    displayName: 'Singapore Dollar',
    logoUrl: '/icons/sg-flag.svg',
  },
  AUD: {
    displayName: 'Australian Dollar',
    logoUrl: '/icons/au-flag.svg',
  },
  CAD: {
    displayName: 'Canadian Dollar',
    logoUrl: '/icons/ca-flag.svg',
  },
  CNY: {
    displayName: 'Chinese Yuan',
    logoUrl: '/icons/cn-flag.svg',
  },
  CHF: {
    displayName: 'Swiss franc',
    logoUrl: '/icons/swiss-flag.svg',
  },
  EUR: {
    displayName: 'Euro',
    logoUrl: '/icons/eu-flag.svg',
  },
  GBP: {
    displayName: 'Great Britain Pound',
    logoUrl: '/icons/gb-flag.svg',
  },
  JPY: {
    displayName: 'Japanese Yen',
    logoUrl: '/icons/jp-flag.svg',
  },
  NZD: {
    displayName: 'New Zealand Dollar',
    logoUrl: '/icons/nz-flag.svg',
  },
};

export const globalAccountsOptions = [
  {
    value: 'USD',
    label: WALLET_COUNTRIES.USD.displayName,
  },
  {
    value: 'HKD',
    label: WALLET_COUNTRIES.HKD.displayName,
  },
  {
    value: 'SGD',
    label: WALLET_COUNTRIES.SGD.displayName,
  },
  {
    value: 'CNY',
    label: WALLET_COUNTRIES.CNY.displayName,
  },
  {
    value: 'CAD',
    label: WALLET_COUNTRIES.CAD.displayName,
  },
  {
    value: 'CHF',
    label: 'Swiss Franc',
  },
  {
    value: 'EUR',
    label: WALLET_COUNTRIES.EUR.displayName,
  },
  {
    value: 'GBP',
    label: WALLET_COUNTRIES.GBP.displayName,
  },
  {
    value: 'JPY',
    label: WALLET_COUNTRIES.JPY.displayName,
  },
  {
    value: 'AUD',
    label: WALLET_COUNTRIES.AUD.displayName,
  },
  {
    value: 'NZD',
    label: WALLET_COUNTRIES.NZD.displayName,
  },
];

export const OPTION_VALUE_ALL = 'ALL';

export const transactionTypeOptions = [
  {
    value: 'ADJUSTMENT',
    label: 'Adjustment',
  },
  {
    value: 'CARD',
    label: 'Card',
  },
  {
    value: 'CHARGE',
    label: 'Charge',
  },
  {
    value: 'CONVERSION',
    label: 'Conversion',
  },
  {
    value: 'DEPOSIT',
    label: 'Deposit',
  },
  {
    value: 'DISPUTE',
    label: 'Dispute',
  },
  {
    value: 'FEE',
    label: 'Fee',
  },
  {
    value: 'PAYMENT',
    label: 'Payin',
  },
  {
    value: 'PAYOUT',
    label: 'Payout',
  },
  {
    value: 'TRANSFER',
    label: 'Transfer',
  },
];

export const currencyOptions = [
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'HKD',
    label: 'HKD',
  },
  {
    value: 'SGD',
    label: 'SGD',
  },
  {
    value: 'AUD',
    label: 'AUD',
  },
  {
    value: 'BDT',
    label: 'BDT',
  },
  {
    value: 'BHD',
    label: 'BHD',
  },
  {
    value: 'CAD',
    label: 'CAD',
  },
  {
    value: 'CHF',
    label: 'CHF',
  },
  {
    value: 'CNY',
    label: 'CNY',
  },
  {
    value: 'CZK',
    label: 'CZK',
  },
  {
    value: 'DKK',
    label: 'DKK',
  },
  {
    value: 'EGP',
    label: 'EGP',
  },
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'GBP',
    label: 'GBP',
  },
  {
    value: 'HRK',
    label: 'HRK',
  },
  {
    value: 'HUF',
    label: 'HUF',
  },
  {
    value: 'IDR',
    label: 'IDR',
  },
  {
    value: 'ILS',
    label: 'ILS',
  },
  {
    value: 'INR',
    label: 'INR',
  },
  {
    value: 'JPY',
    label: 'JPY',
  },
  {
    value: 'KRW',
    label: 'KRW',
  },
  {
    value: 'LKR',
    label: 'LKR',
  },
  {
    value: 'MXN',
    label: 'MXN',
  },
  {
    value: 'MYR',
    label: 'MYR',
  },
  {
    value: 'NOK',
    label: 'NOK',
  },
  {
    value: 'NPR',
    label: 'NPR',
  },
  {
    value: 'NZD',
    label: 'NZD',
  },
  {
    value: 'PHP',
    label: 'PHP',
  },
  {
    value: 'PKR',
    label: 'PKR',
  },
  {
    value: 'PLN',
    label: 'PLN',
  },
  {
    value: 'RON',
    label: 'RON',
  },
  {
    value: 'SAR',
    label: 'SAR',
  },
  {
    value: 'SEK',
    label: 'SEK',
  },
  {
    value: 'THB',
    label: 'THB',
  },
  {
    value: 'TRY',
    label: 'TRY',
  },
  {
    value: 'VND',
    label: 'VND',
  },
  {
    value: 'ZAR',
    label: 'ZAR',
  },
];

export const globalAccountsCurrencyOptions = [
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'HKD',
    label: 'HKD',
  },
  {
    value: 'SGD',
    label: 'SGD',
  },
  {
    value: 'AUD',
    label: 'AUD',
  },
  {
    value: 'CAD',
    label: 'CAD',
  },
  {
    value: 'CHF',
    label: 'CHF',
  },
  {
    value: 'CNY',
    label: 'CNY',
  },
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'GBP',
    label: 'GBP',
  },
  {
    value: 'JPY',
    label: 'JPY',
  },
  {
    value: 'NZD',
    label: 'NZD',
  },
];

export const globalAccountsBankOptions = [
  {
    value: 'US',
    label: 'United States',
  },
  {
    value: 'HK',
    label: 'Hong Kong (China)',
  },
  {
    value: 'SG',
    label: 'Singapore',
  },
  {
    value: 'DE',
    label: 'Germany (Europe)',
  },
  {
    value: 'GB',
    label: 'United Kingdom',
  },
  {
    value: 'JP',
    label: 'Japan',
  },
  {
    value: 'AU',
    label: 'Australia',
  },
];

export const globalAccountsStatusOptions = [
  {
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    value: 'PROCESSED',
    label: 'Processed',
  },
  {
    value: 'INACTIVE',
    label: 'Closed',
  },
];

export const TOOLTIP_DESCRIPTIONS = {
  AVAILABLE_BALANCE: 'Available balance is the amount of money that is available to cover transactions.',
  ACCOUNT_BALANCE:
    'Account balance is the total amount of funds in your wallet. The Account balance equals the sum of your Available balance and Reserve balance.',
  RESERVED_BALANCE:
    'Reserve balance includes all funds that have been temporarily held, include pending card transactions and pending refunds from your online payments acquired with wallet.',
};

export const globalAccountsRegions = [
  {
    value: 'HK',
    label: 'Hong Kong (China)',
    description: 'in EUR/GBP/SGD/HKD/CNY/JPY/AUD/CHF/CAD/NZD/USD',
    currencyCode: 'HKD',
  },
  {
    value: 'SG',
    label: 'Singapore',
    description: 'in SGD',
    currencyCode: 'SGD',
  },
  {
    value: 'US',
    label: 'United States',
    description: 'in USD',
    currencyCode: 'USD',
  },
  {
    value: 'AU',
    label: 'Australia',
    description: 'in AUD',
    currencyCode: 'AUD',
  },
  {
    value: 'DE',
    label: 'Europe',
    description: 'in EUR',
    currencyCode: 'EUR',
  },
  {
    value: 'GB',
    label: 'United Kingdom',
    description: 'in GBP',
    currencyCode: 'GBP',
  },
  {
    value: 'JP',
    label: 'Japan',
    description: 'in JPY',
    currencyCode: 'JPY',
  },
];

export const GlobalAccountTransactionsOptions = [
  {
    value: 'PENDING',
    label: 'Pending',
  },
  {
    value: 'PROCESSED',
    label: 'Settled',
  },
  {
    value: 'REJECTED',
    label: 'Rejected',
  },
  {
    value: 'CANCELLED',
    label: 'Cancelled',
  },
];

export const TransferStatusOptions = [
  {
    value: 'NEW',
    label: 'New',
  },
  {
    value: 'CANCELLED',
    label: 'Cancelled',
  },
  {
    value: 'READY_FOR_DISPATCH',
    label: 'Ready for dispatch',
  },
  {
    value: 'IN_REVIEW',
    label: 'In review',
  },
  {
    value: 'SUSPENDED',
    label: 'Suspended',
  },
  {
    value: 'DISPATCHED',
    label: 'Dispatched',
  },
  {
    value: 'FAILED',
    label: 'Failed',
  },
  {
    value: 'CANCELLED_REQUESTED',
    label: 'Cancellation requested',
  },
  {
    value: 'RETRIED',
    label: 'Retired',
  },
];

// when airwallex update their API, this will be redundant in the future

export const GlobalAccountBankAddress = {
  /* cSpell:disable */
  'DBS Bank (Hong Kong) Limited': {
    address: '18th Floor, The Center, 99 Queen’s Road Central, Central',
  },
  'Standard Chartered Bank (Hong Kong) Ltd': {
    address: '32nd Floor, 4-4A Des Voeux Road Central, Hong Kong (China)',
  },
  'Australia and New Zealand Banking Group Limited': {
    address: '833 Collins Street, Melbourne, Victoria, 3000, Australia',
  },
  'Silicon Valley Bank': {
    address: '3003 Tasman Drive, Santa Clara, CA, 95054, United States',
  },
  'Community Federal Savings Bank': {
    address: '89-16 Jamaica Ave, Woodhaven, NY, 11421, United States',
  },
  'East West Bank': {
    address: '9300 Flair Drive, 4th Fl., El Monte, CA, 91731, United States',
  },
  'Evolve Bank & Trust': {
    address: '6070 Poplar Avenue Suite 200, Memphis, TN, 38119, United States',
  },
  'MUFG BANK, LTD.': {
    address: '7-1 Marunouchi 2-Chome, Chiyoda-Ku, Tokyo, Japan',
  },
  'Deutsche Bank AG': {
    address: 'Level 2, Marunouchi Nijubashi Building, 3-2-2 Marunouchi, Chiyoda-ku, Tokyo, Japan',
  },
  'AS LHV PANK': {
    address: 'Old Street Yard, London, EC1Y 8AF, United Kingdom',
  },
  'Banking Circle S.A.': {
    address: 'Chilehaus A Fischertwiete 2, Hamburg, 20095, Germany',
  },
  'Banking Circle S.A': {
    address: '125 Old Broad Street, London, EC2N 1AR, United Kingdom',
  },
  'DBS Bank Ltd': {
    address: '18th Floor, The Center, 99 Queen’s Road Central, Central',
  },
  /* cSpell:enable */
};

export const FIELD_BLACK_LIST = [
  'beneficiary.entity_type',
  'beneficiary.bank_details.bank_country_code',
  'beneficiary.bank_details.account_currency',
  'payment_method',
  'beneficiary.bank_details.local_clearing_system',
  'beneficiary.date_of_birth',
];

export const ENTITY_FIELD_ORDER = [
  'company_name',
  'first_name',
  'last_name',
  'nickname',
  'personal_email',
  'country_code',
  'street_address',
  'city',
  'state',
  'postcode',
];

export const CARD_LIMIT_INTERVAL_OPTIONS = [
  {
    value: 'PER_TRANSACTION',
    label: 'Per transaction',
  },
  {
    value: 'DAILY',
    label: 'Daily',
  },
  {
    value: 'WEEKLY',
    label: 'Weekly',
  },
  {
    value: 'MONTHLY',
    label: 'Monthly',
  },
  {
    value: 'ALL_TIME',
    label: 'All time',
  },
];

export const CARD_STATUS = {
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
  PENDING: 'PENDING',
  LOST: 'LOST',
  STOLEN: 'STOLEN',
  BLOCKED: 'BLOCKED',
};

export const CARD_STATUS_LABEL = {
  INACTIVE: 'Freezed',
  ACTIVE: 'In use',
  CLOSED: 'Closed',
  PENDING: 'Pending',
  LOST: 'Lost',
  STOLEN: 'stolen',
  BLOCKED: 'blocked',
};

export const CARD_STATUSES_OPTIONS = [
  {
    value: 'APPROVED',
    label: 'Approved',
  },
  {
    value: 'PENDING',
    label: 'Pending',
  },
  {
    value: 'FAILED',
    label: 'Failed',
  },
];

export const COMPANY_CARD_CONFIG = {
  type: 'company-card',
  title: 'Company Card',
  description: 'Virtual cards for shared team purchases such as subscriptions or office supplies.',
  imgUrl: '/images/virtual-cards/company-card.svg',
  imgUrlWithoutNumber: '/images/virtual-cards/company-card-without-number.svg',
};

export const EMPLOYEE_CARD_CONFIG = {
  type: 'employee-card',
  title: 'Employee Card',
  description: 'Virtual or physical cards for individual employee purchases such as travel or employee benefits.',
  imgUrl: '/images/virtual-cards/employee-card.svg',
  disabled: true,
};

export const DEFAULT_CARD_PER_TRANSACTION_LIMIT = 500000;
