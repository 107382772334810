import React from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import featureToggle from '../../../../featureToggle';
import APPLICATION_PATH from '../../../Application/path';
import { Wrapper, Card, PageHeader } from '../../components';
import RemittanceSummaryCard from '../../components/RemittanceSummaryCard';
import PATH, { getApplicationPathByApplicationStatusCode } from '../../path';
import BalanceTransactionTable from '../BalanceTransaction/components/BalanceTransactionTable';
import { STATUS_CODE } from '@constants/application';
import { useAcceptedOffer, useIsAdminView } from '@redux/selectors';
import { media } from '@styles/breakpoints';

const WrapperHeader = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: 600;
`;

const SectionHeader = styled.span`
  ${(props) => props.theme.text.four};
  line-height: 42px;
  font-weight: 600;
`;

const ButtonWrapper = styled.div`
  text-align: right;
  margin-right: 18px;
  button {
    margin-left: 24px;
  }
`;

const StyledButton = styled(Button)`
  ${media.desktop`
    margin: 12px 0;
    width : 215px;
  `};

  ${media.phone`
    width : 115px;
  `};
`;

const CurrentFundingCard = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px 20px;
  border-radius: 5px;
  margin: 24px 0;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.desktop`
    flex-direction: column;
  `};

  ${media.phone`
    overflow: hidden;
  `};
`;

const TransactionsCard = styled(Card)`
  padding: 0;
`;

const Overview = () => {
  const history = useHistory();
  const { statusCode, applicationId } = useSelector((state) => state.application.data);
  const { selectedCompanyId } = useSelector((state) => state.company);
  const acceptedOffer = useAcceptedOffer();
  const balanceTransactions = useSelector((state) => state.fund.balanceTransactions);
  const isAdmin = useIsAdminView();

  const viewApplication = () => {
    const url = getApplicationPathByApplicationStatusCode(statusCode);
    history.push(url);
  };

  const viewTerms = () => {
    history.push(isAdmin ? PATH.TERM_SHEET : APPLICATION_PATH.TERM_SHEET);
  };

  const viewPastFunding = () => {
    history.push(PATH.CAPITAL_PAST_FUNDING);
  };

  const viewTransactions = () => {
    history.push(PATH.CAPITAL_BALANCE_TRANSACTIONS);
  };

  const shouldShowRemittanceSummaryCard = statusCode >= STATUS_CODE.PENDING_USER_SIGN_AGREEMENT;

  return (
    <>
      <PageHeader>Capital</PageHeader>
      <Wrapper>
        <WrapperHeader>Current Funding</WrapperHeader>
        {!shouldShowRemittanceSummaryCard && (
          <CurrentFundingCard>
            <Container>
              <SectionHeader>No Current Funding</SectionHeader>
              <StyledButton onClick={viewApplication}>Continue Your Application</StyledButton>
            </Container>
          </CurrentFundingCard>
        )}
        <RemittanceSummaryCard offer={shouldShowRemittanceSummaryCard ? acceptedOffer : {}} />
        <ButtonWrapper>
          <Button onClick={viewTerms} secondary>
            View Term Sheet
          </Button>
          {/* <Button onClick={viewPastFunding} secondary>
              View Past Fundings
            </Button> */}
        </ButtonWrapper>
      </Wrapper>
      <Wrapper>
        <WrapperHeader>Transactions</WrapperHeader>
        <TransactionsCard>
          {!shouldShowRemittanceSummaryCard ? (
            <Card>
              <SectionHeader>No transactions</SectionHeader>
            </Card>
          ) : (
            <BalanceTransactionTable data={balanceTransactions.slice(0, 5)} />
          )}
        </TransactionsCard>
        {featureToggle.INVOICE && balanceTransactions.length > 0 && (
          <ButtonWrapper>
            <Button onClick={viewTransactions} secondary>
              View All
            </Button>
          </ButtonWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default Overview;
