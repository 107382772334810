import { stringify } from 'query-string';
import { toast } from 'react-toastify';

import { integrationEndpoint } from '.';
import { deleteRequest, getRequest, postRequest, putRequest } from '@api/requests';

export const getWalletPayouts = async (accountId: string, query: string, payload: Record<string, unknown>) => {
  return postRequest(`${integrationEndpoint}/wallets/${accountId}/payments/search?${stringify(query)}`, payload);
};

export const getGlobalAccounts = async (accountId: string, query: string, body = {}) => {
  return postRequest(`${integrationEndpoint}/wallets/${accountId}/global_accounts/search?${stringify(query)}`, body);
};
export const getWalletPayoutById = async (accountId: string, payoutId: string) => {
  return getRequest(`${integrationEndpoint}/wallets/${accountId}/payments/${payoutId}`);
};

export const createGlobalAccounts = async (accountId: string, payload = {}) => {
  return postRequest(`${integrationEndpoint}/wallets/${accountId}/global_accounts`, payload);
};

export const getWalletBeneficiaries = async (accountId: string, query: string) => {
  return getRequest(`${integrationEndpoint}/wallets/${accountId}/beneficiaries?${stringify(query)}`);
};

export const getWalletBeneficiaryById = async (accountId: string, beneficiaryId: string) => {
  return getRequest(`${integrationEndpoint}/wallets/${accountId}/beneficiaries/${beneficiaryId}`);
};

export const deleteWalletBeneficiary = async (accountId: string, beneficiaryId: string) => {
  return deleteRequest(`${integrationEndpoint}/wallets/${accountId}/beneficiaries/${beneficiaryId}`);
};

export const createWalletTransfer = async (accountId: string, payload: Record<string, unknown>) => {
  return postRequest(`${integrationEndpoint}/wallets/${accountId}/payouts`, payload);
};

export const getGlobalAccountById = async (accountId: string, globalAccountId: string) => {
  return getRequest(`${integrationEndpoint}/wallets/${accountId}/global_accounts/${globalAccountId}`);
};

export const createAmazonAccountStatement = async (accountId: string, globalAccountId: string, type: string, payload = {}) => {
  const sendRequest = postRequest(
    `${integrationEndpoint}/wallets/${accountId}/global_accounts/${globalAccountId}/statement/${type}`,
    payload,
    'blob',
    true
  );

  // More info on https://fkhadra.github.io/react-toastify/promise
  await toast.promise(sendRequest, {
    pending: 'Downloading account statement...',
    error: 'Something is wrong!',
  });

  return sendRequest;
};

export const getWalletFX = async (accountId: string, fromCurrency: string, toCurrency: string, amount: string) => {
  return getRequest(
    `${integrationEndpoint}/wallets/${accountId}/lockfx?${stringify({
      sell_currency: fromCurrency,
      sell_amount: amount,
      buy_currency: toCurrency,
      validity: 'MIN_1',
    })}`
  );
};

export const createWalletBeneficiary = async (accountId: string, payload: Record<string, unknown>) => {
  return postRequest(`${integrationEndpoint}/wallets/${accountId}/beneficiaries`, payload);
};

export const updateWalletBeneficiary = async (accountId: string, beneficiaryId: string, payload: Record<string, unknown>) => {
  return putRequest(`${integrationEndpoint}/wallets/${accountId}/beneficiaries/${beneficiaryId}`, payload);
};

export const getGlobalAccountTransactionsById = async (
  accountId: string,
  globalAccountId: string,
  startDate: string,
  endDate: string,
  body = {}
) => {
  return postRequest(
    `${integrationEndpoint}/wallets/${accountId}/global_accounts/${globalAccountId}/transactions?${stringify({
      from_created_at: `${startDate}`,
      to_created_at: `${endDate}`,
    })}`,
    body
  );
};

export const getGlobalAccountTransactions = async (accountId: string, globalAccountId: string, query: string, body = {}) => {
  return postRequest(
    `${integrationEndpoint}/wallets/${accountId}/global_accounts/${globalAccountId}/transactions?${stringify(query)}`,
    body
  );
};

export const getWalletBeneficiarySchema = async (payload: string) => {
  return postRequest(`${integrationEndpoint}/wallets/beneficiary_schemas`, payload);
};

export const getWalletCurrentBalance = async (accountId: string) => {
  return getRequest(`${integrationEndpoint}/wallets/${accountId}/balances/current`);
};

export const getWalletGlobalAccountStatement = async (accountId: string, globalAccountId: string, type: string) => {
  return await postRequest(`${integrationEndpoint}/wallets/${accountId}/global_accounts/${globalAccountId}/statement/${type}`);
};

export const getWalletTransferFee = async (bankCountryCode: string, beneficiaryCurrencyCode: string) => {
  return getRequest(
    `${integrationEndpoint}/wallets/payment-methods?${stringify({
      country_code: bankCountryCode,
      currency: beneficiaryCurrencyCode,
    })}`
  );
};

export const updateWalletAccount = async (body: Record<string, unknown>) => {
  return putRequest(`${integrationEndpoint}/wallets/accounts`, body);
};

export const getWalletConversionList = async (accountId: string, query: string, body = {}) => {
  return postRequest(`${integrationEndpoint}/wallets/${accountId}/conversions/search?${stringify(query)}`, body);
};

export const createWalletConversion = async (accountId: string, body: Record<string, unknown>) => {
  return postRequest(`${integrationEndpoint}/wallets/${accountId}/conversions`, body);
};

export const getConversionById = async (accountId: string, id: string) => {
  return getRequest(`${integrationEndpoint}/wallets/${accountId}/conversions/${id}`);
};

export const getWalletAllCards = async (accountId: string, query: string) => {
  return postRequest(`${integrationEndpoint}/wallets/${accountId}/cards/search?${stringify(query)}`);
};

export const getWalletCardDetail = async (accountId: string, card_id: string) => {
  return getRequest(`${integrationEndpoint}/wallets/${accountId}/cards/card/${card_id}`);
};

export const createWalletCard = async (accountId: string, payload: Record<string, unknown>) => {
  return postRequest(`${integrationEndpoint}/wallets/${accountId}/cards`, payload);
};

export const updateWalletCard = async (accountId: string, card_id: string, payload: Record<string, unknown>) => {
  return postRequest(`${integrationEndpoint}/wallets/${accountId}/cards/card/${card_id}`, payload);
};

export const getCardTransactions = async (accountId: string, query: Record<string, unknown>, body: Record<string, unknown>) => {
  return postRequest(`${integrationEndpoint}/wallets/${accountId}/cards-transactions/search?${stringify(query)}`, body);
};

export const triggerTransaction2fa = async (accountId: string, body: Record<string, unknown>) => {
  return postRequest(`${integrationEndpoint}/wallets/${accountId}/save-payouts`, body);
};

export const getCardTransactionsById = async (
  accountId: string,
  transaction_id: string,
  _query: Record<string, unknown>,
  body: Record<string, unknown>
) => {
  return getRequest(`${integrationEndpoint}/wallets/${accountId}/cards-transactions/${transaction_id}`, body);
};

export const getAccountSettings = async (accountId: string) => {
  return getRequest(`${integrationEndpoint}/wallets/${accountId}/cards/settings`);
};

export const updateWalletAccountSettings = async (accountId: string, payload: string) => {
  return postRequest(`${integrationEndpoint}/wallets/${accountId}/cards/settings`, payload);
};

export const getCardSensitiveDetailLink = async (accountId: string, card_id: string) => {
  return getRequest(`${integrationEndpoint}/wallets/${accountId}/card-details/${card_id}`);
};

export const getCardRemainingLimits = async (accountId: string, card_id: string) => {
  return getRequest(`${integrationEndpoint}/wallets/${accountId}/cards/${card_id}/limits`);
};
