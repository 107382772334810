import React, { useState, useEffect, useContext } from 'react';

import { parse } from 'query-string';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation, NavLink, Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../components/Button';
import LoadingSkeleton from '../../../components/LoadingSkeleton';
import ControlledInput from '../../ReactHookForm/ControlledInput';
import Banner from '../components/Banner';
import { Form, FormRow, Tagline, Error, Container, ContentDiv } from '../components/styled';
import { AppContext } from '@/context';
import { confirmEmail, resendEmailVerification } from '@api/modules/auth';
import { ERROR_MESSAGE_EMAIL_FORMAT } from '@constants/form';
import { fetchUser } from '@redux/modules/user/actions';
import { media } from '@styles/breakpoints';
import { emailRegExp } from '@utils/validators';

const StyledContainer = styled(Container)`
  ${media.dablet`
    height: 100vh;
  `};
`;

const StyledContentDiv = styled(ContentDiv)`
  height: 50vh;
`;

const Title = styled.div`
  ${(props) => props.theme.text.two};
  margin-top: 40px;
  ${media.phone`
   margin-top: 10px;
   font-size: 24px;
  `};
`;

const LoadingContainer = styled.div`
  width: 40%;
  min-width: 200px;

  ${LoadingSkeleton} ~ ${LoadingSkeleton} {
    margin: 16px 0;
  }
`;

const ConfirmEmail = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { homeUrl } = useContext(AppContext);
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const [isLoading, toggleIsLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const verifyEmail = async () => {
      toggleIsLoading(true);
      const { token } = parse(location.search);
      const { statusCode } = await confirmEmail(token);
      if (statusCode === 200) {
        setIsVerified(true);
        fetchUser();
      }
      toggleIsLoading(false);
    };

    verifyEmail();
  }, []);

  const onSubmit = async (formData) => {
    toggleIsLoading(true);
    try {
      await resendEmailVerification(formData.email);
    } finally {
      toggleIsLoading(false);
    }
  };

  return (
    <>
      <Banner />
      <StyledContainer>
        <StyledContentDiv>
          {isLoading ? (
            <LoadingContainer>
              <LoadingSkeleton height="40px" />
              <LoadingSkeleton height="16px" />
              <LoadingSkeleton height="16px" />
              <LoadingSkeleton height="16px" />
            </LoadingContainer>
          ) : isVerified ? (
            <Form>
              <Title>Email Verification</Title>
              <Tagline>You have verified your email.</Tagline>
              <Link to={homeUrl}>
                <Button full>Get started</Button>
              </Link>
            </Form>
          ) : (
            <Form>
              <Title>Email Verification</Title>
              <Error>You have not verified your email yet. Something went wrong. Please try later.</Error>

              <form onSubmit={handleSubmit(onSubmit)}>
                <ControlledInput
                  name="email"
                  control={control}
                  rules={{ required: true, pattern: { value: emailRegExp, message: ERROR_MESSAGE_EMAIL_FORMAT } }}
                  placeholder="Email"
                  full
                />
                <Button full>Resend verification</Button>
              </form>
            </Form>
          )}
        </StyledContentDiv>
      </StyledContainer>
    </>
  );
};

export default ConfirmEmail;
