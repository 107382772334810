import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import Button from '../../../../components/Button';
import FancyDateRangePicker from '../../../../components/FancyDateRangePicker';
import FormTitle from '../../../../components/FormTitle';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import { generateXeroBankAccountOption } from './helpers';
import { GridColumn } from '@components/Grid';

const ButtonWrapper = styled(GridColumn)`
  > button {
    float: right;
    margin-left: 12px;
  }
`;

const DateWrapper = styled.div`
  float: right;
  margin: 30px 15px;
`;

const AccountWrapper = styled(GridColumn)`
  padding-top: 0;
`;

const SearchButtonWrapper = styled(ButtonWrapper)`
  background-color: ${(props) => props.theme.colors.grayOne};
  margin: 24px 0 0 0;
  padding: 12px 24px;
`;

const Row = styled.div`
  margin: 12px 0;
`;

const SearchFilter = ({
  isLoading,
  transactionData,
  filterInEffect,
  filterOptions,
  onApply,
  selectedAccount,
  selectedChannelAccount,
  onAccountChange,
  onDateChange,
  onChannelAccountChange,
  isExpanded,
  startDate,
  endDate,
  dateShortcutValue,
  toggleIsExpanded = () => {},
  downloadTransactions = () => {},
}) => {
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedType, setSelectedType] = useState();
  const [accountOptions, setAccountOptions] = useState();
  const [channelAccountOptions, setChannelAccountOptions] = useState();
  const [statusOptions, setStatusOptions] = useState();
  const [typeOptions, setTypeOptions] = useState();
  const [contactNameKeyword, setContactNameKeyword] = useState('');
  const [referenceKeyword, setReferenceKeyword] = useState('');
  const userProfile = useSelector((state) => state.user.profile);

  const handleCancel = () => {
    toggleIsExpanded();
    onApply(filterInEffect);
  };

  const handleContactNameChange = (event) => {
    setContactNameKeyword(event.target.value);
  };

  const handleReferenceChange = (event) => {
    setReferenceKeyword(event.target.value);
  };

  const handleClear = () => {
    onApply({
      selectedStatus: null,
      selectedType: null,
      contactNameKeyword: '',
      referenceKeyword: '',
    });
  };

  const handleApply = () => {
    onApply({
      selectedStatus,
      selectedType,
      contactNameKeyword: typeof contactNameKeyword === 'string' ? contactNameKeyword.trim() : contactNameKeyword,
      referenceKeyword: typeof referenceKeyword === 'string' ? referenceKeyword.trim() : referenceKeyword,
    });
    toggleIsExpanded();
  };

  const matchPropsToState = () => {
    setSelectedStatus(filterInEffect.selectedStatus);
    setSelectedType(filterInEffect.selectedType);
    setContactNameKeyword(filterInEffect.contactNameKeyword);
    setReferenceKeyword(filterInEffect.referenceKeyword);
  };

  useEffect(() => {
    matchPropsToState();
  }, [JSON.stringify(filterInEffect)]);

  useEffect(() => {
    if (isExpanded) {
      matchPropsToState();
    }
  }, [isExpanded]);

  useEffect(() => {
    const { BankAccount, Status, Type } = filterOptions;

    if (Array.isArray(BankAccount)) {
      const formatChannelName = [...new Set(BankAccount.map(({ channel_account_name }) => channel_account_name))];
      const channelAccountName = formatChannelName.map((str) => ({ value: str, label: str }));
      if (Array.isArray(channelAccountName) && channelAccountName.length > 0) {
        setChannelAccountOptions(channelAccountName);
        onChannelAccountChange(channelAccountName[0].value);
      }
    }

    if (Array.isArray(BankAccount) && Array.isArray(Status) && Array.isArray(Type)) {
      const accountOpts = BankAccount.map(generateXeroBankAccountOption);

      setAccountOptions(accountOpts);

      const statusOpts = Status.map((status) => ({ value: status, label: status }));
      setStatusOptions(statusOpts);
      const typeOpts = Type.map((type) => ({ value: type, label: type }));
      setTypeOptions(typeOpts);
    }
  }, [JSON.stringify(filterOptions)]);

  useEffect(() => {
    if (filterOptions.BankAccount) {
      const filteredXeroAccount = filterOptions.BankAccount.filter(
        ({ channel_account_name }) => channel_account_name === selectedChannelAccount
      );
      const accountOpts = filteredXeroAccount.map(generateXeroBankAccountOption);

      setAccountOptions(accountOpts);
    }
  }, [selectedChannelAccount, JSON.stringify(filterOptions.BankAccount)]);

  useEffect(() => {
    if (Array.isArray(accountOptions) && accountOptions.length > 0) {
      onAccountChange(accountOptions[0].value);
    }
  }, [JSON.stringify(accountOptions)]);

  return (
    <div>
      <AccountWrapper lg={3} md={6} sm={6}>
        <FormTitle scale={5}>Account</FormTitle>
        <Row>
          <Select value={selectedChannelAccount} options={channelAccountOptions} onChange={onChannelAccountChange} />
        </Row>
        <Row>
          <Select value={selectedAccount} options={accountOptions} onChange={onAccountChange} />
        </Row>
      </AccountWrapper>
      <DateWrapper>
        <GridColumn>
          <FormTitle scale={5}>Date Range</FormTitle>
          <FancyDateRangePicker
            id={`SearchFilter_${Date.now()}`}
            startDate={startDate}
            endDate={endDate}
            dateShortcutValue={dateShortcutValue}
            onApply={onDateChange}
            full
          />
        </GridColumn>
      </DateWrapper>
      {isExpanded ? (
        <div>
          <GridColumn lg={5} md={5} sm={6}>
            <FormTitle scale={5}>Status</FormTitle>
            <Select value={selectedStatus} options={statusOptions} onChange={setSelectedStatus} />
          </GridColumn>
          <GridColumn lg={5} md={5} sm={6}>
            <FormTitle scale={5}>Type</FormTitle>
            <Select value={selectedType} options={typeOptions} onChange={setSelectedType} />
          </GridColumn>
          <GridColumn lg={5} md={5} sm={6}>
            <FormTitle scale={5}>Search contact name</FormTitle>
            <Input value={contactNameKeyword} onChange={handleContactNameChange} full />
          </GridColumn>
          <GridColumn lg={5} md={5} sm={6}>
            <FormTitle scale={5}>Search reference</FormTitle>
            <Input value={referenceKeyword} onChange={handleReferenceChange} full />
          </GridColumn>
          <ButtonWrapper>
            <Button width={100} disabled={isLoading} onClick={handleCancel} tertiary small>
              Cancel
            </Button>
            <Button width={100} disabled={isLoading} onClick={handleClear} tertiary small>
              Clear
            </Button>
            <Button width={100} disabled={isLoading} onClick={handleApply} small>
              Apply
            </Button>
          </ButtonWrapper>
        </div>
      ) : (
        <SearchButtonWrapper>
          <Button small onClick={toggleIsExpanded} secondary>
            Search
          </Button>
          {userProfile.adminRole?.name && (
            <Button
              small
              onClick={downloadTransactions}
              secondary
              disabled={isLoading || !Array.isArray(transactionData) || transactionData.length === 0}
            >
              Download
            </Button>
          )}
        </SearchButtonWrapper>
      )}
    </div>
  );
};

export default SearchFilter;
