import { isRealNumber } from '@utils/dataTypes';

export const checkSalesFormErrors = (salesAccounts, salesOthersPlatform, amount, currency) => {
  const hasConnectedAccount =
    (Array.isArray(salesAccounts) && salesAccounts.length > 0) ||
    (Array.isArray(salesOthersPlatform) && salesOthersPlatform.length > 0);

  const formErrors = {
    platformSection: !hasConnectedAccount,
    incomeSection: !(isRealNumber(amount) && amount > 0) || !currency,
  };

  return formErrors;
};

export const checkOfferFormErrors = (fund) => {
  const formErrors = {
    fund: !(isRealNumber(fund) && fund > 0),
  };

  return formErrors;
};

export const checkFinanceFormErrors = (bankAccounts, accountingAccounts, PLStatements) => {
  const hasBankAccount = Array.isArray(bankAccounts) && bankAccounts.length > 0;
  const hasAccountingAccount = Array.isArray(accountingAccounts) && accountingAccounts.length > 0;
  const hasPLFile = Array.isArray(PLStatements) && PLStatements.length > 0;
  const formErrors = {
    bank: !hasBankAccount,
    accounting: !hasAccountingAccount && !hasPLFile,
  };

  return formErrors;
};

export const checkBankFormErrors = (bankAccounts) => {
  const hasBankAccount = Array.isArray(bankAccounts) && bankAccounts.length > 0;
  let missingFile = true;
  if (hasBankAccount) {
    missingFile = bankAccounts
      .map((account) => account.attachmentCount)
      .some((attachmentCount) => attachmentCount === 0);
  }

  return {
    bank: !hasBankAccount || missingFile,
  };
};
