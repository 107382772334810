import jwt from 'jsonwebtoken';

import {
  ACCESS_TOKEN_NAME,
  REFRESH_TOKEN_NAME,
  USER_COMPANY_ID_NAME,
  TARGET_COMPANY_ID_NAME,
  COOKIE_DOMAIN,
  API_AUTH_PORT,
} from '../constants';
import { getCookieProvider } from '../cookies';
import { formatUrl, postRequest } from '../requests';

export const authUrl = `${API_AUTH_PORT}/auth/v1`;

// eslint-disable-next-line consistent-return
export const emailLogin = async (payload) => {
  const url = formatUrl(`${authUrl}/login`);
  const { username, password } = payload;
  const response = await fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: username.toLowerCase(),
      password,
    }),
  });

  if (response.status === 401) {
    throw new Error('Either email or password is invalid');
  }

  return await response.json();
};

// keep it sync with how to handle logout in case of un-authentication
// check /api/requests.js handleRequest
export const logout = async () => {
  const cookieProvider = getCookieProvider();
  const cookieConfig = { path: '/', domain: COOKIE_DOMAIN };
  cookieProvider.set(USER_COMPANY_ID_NAME, '', cookieConfig);
  cookieProvider.set(TARGET_COMPANY_ID_NAME, '', cookieConfig);
  cookieProvider.set(ACCESS_TOKEN_NAME, '', cookieConfig);
  cookieProvider.set(REFRESH_TOKEN_NAME, '', cookieConfig);
  const url = formatUrl(`${authUrl}/logout`);
  await fetch(url, {
    credentials: 'include',
    method: 'GET',
  });
};

export const register = async (payload) => {
  return await postRequest(`${authUrl}/register`, payload);
};

export const requestResetPassword = async (email) => {
  return await postRequest(`${authUrl}/user-action`, {
    action: 'REQUEST_RESET',
    email,
  });
};

export const resetPassword = async (payload) => {
  return await postRequest(`${authUrl}/user-action`, {
    action: 'RESET',
    token: payload.token,
    password: payload.password,
  });
};

export const confirmEmail = async (token) => {
  return await postRequest(`${authUrl}/user-action`, {
    action: 'CONFIRM',
    token,
  });
};

export const resendEmailVerification = async (email) => {
  return await postRequest(`${authUrl}/verify-email`, {
    email,
  });
};

export const changePassword = async (payload) => {
  return await postRequest(`${authUrl}/changePassword`, payload);
};

export const switchCompany = async (companyId) => {
  return postRequest(`${authUrl}/switchCompany`, { company_id: companyId });
};

export const registerInvitedUser = async (payload) => {
  return postRequest(`${authUrl}/user`, payload);
};
