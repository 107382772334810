import { convertToFullDate, convertToFullDateAndTime, adjustStripeTimeStamp } from '@utils/dateHelpers';
import { formatNumber, adjustStripeAmount } from '@utils/priceHelpers';
import { capitalize, toUpperCase } from '@utils/stringHelpers';

export const tableColumns = [
  {
    Header: 'Arrival Date',
    accessor: 'arrival_date',
    Cell: ({ value }) => {
      return convertToFullDate(adjustStripeTimeStamp(value));
    },
  },
  {
    Header: 'Created Date',
    accessor: 'created',
    Cell: ({ value }) => {
      return convertToFullDateAndTime(adjustStripeTimeStamp(value));
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => capitalize(value),
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Currency',
    accessor: 'currency',
    Cell: ({ value }) => toUpperCase(value),
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    className: 'textRight',
    Cell: ({ value }) => {
      return formatNumber(adjustStripeAmount(value), 2);
    },
  },
];

export const balanceStatusOptions = [
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'in_transit',
    label: 'In Transit',
  },
  {
    value: 'paid',
    label: 'Paid',
  },
  {
    value: 'failed',
    label: 'Failed',
  },
  {
    value: 'canceled',
    label: 'Cancelled',
  },
];
