export const onlyDigitRegExp = new RegExp(/^\d+$/g);
export const isPureDigit = (value) => onlyDigitRegExp.test(`${value}`);

export const onlyCharRegExp = new RegExp(/^[A-Za-z]+$/);

// ref: https://www.veem.com/library/what-is-a-bic-code/
const SwiftOrBICRegExp = /^[A-Za-z]{6}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/;
export const isSwiftOrBIC = (value) => SwiftOrBICRegExp.test(value);

// ref: https://regexr.com/37i6s
export const webAddressRegExp = new RegExp(
  /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$/,
);

// ref: https://www.regular-expressions.info/email.html
export const emailRegExp = new RegExp(/^[a-zA-Z0-9._%+-\\!\\/]+@[a-zA-Z0-9.+-]+\.[a-zA-Z]{2,}$/);

// must be at least 8 characters long and have at least one uppercase letter and one number.
export const passwordRegExp = new RegExp(/^(?=.*[A-Z])(?=.*\d).{8,}$/);

// checks if url is valid or not ref: https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
export const urlValidatorRegExp = new RegExp(
  '^https?:\\/\\/' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$',
  'i',
);
