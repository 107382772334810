import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import BreadCrumb from '../../../../components/BreadCrumb';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import { Wrapper } from '../../components';
import PATH from '../../path';
import BalanceTransactionTable from './components/BalanceTransactionTable';
import { OPTION_VALUE_ALL, balanceStatusOptions, balanceSourceTypeOptions } from '@constants/options';
import { findFirstMatchInArray } from '@utils/dataTypes';
import { convertToMonthYear, convertDate, checkSameMonth } from '@utils/dateHelpers';
import { formatPrice } from '@utils/priceHelpers';

const SelectWrapper = styled.div`
  margin-bottom: 36px;
  text-align: right;
`;

const FilterWrapper = styled.span`
  display: inline-block;
  margin-left: 16px;
`;

const Title = styled.div`
  ${(props) => props.theme.text.five};
  text-align: left;
`;

const ButtonWrapper = styled.div`
  ${(props) => props.theme.buttonWrapper};
  margin-bottom: 16px;
`;

const BalanceTransactionList = () => {
  const history = useHistory();
  const balanceTransactions = useSelector((state) => state.fund.balanceTransactions);
  const [monthYear, setMonthYear] = useState(OPTION_VALUE_ALL);
  const [monthYearOptions, setMonthYearOptions] = useState([]);
  const [status, setStatue] = useState(OPTION_VALUE_ALL);
  const [sourceType, setSourceType] = useState(OPTION_VALUE_ALL);
  const [filteredTransactions, setFilteredTransactions] = useState(balanceTransactions);

  const getFundingOptions = () => {
    if (Array.isArray(balanceTransactions)) {
      const ids = balanceTransactions.map(({ application_id }) => application_id);
      const idSet = [...new Set(ids)];
      return idSet.map((id) => {
        const transaction = findFirstMatchInArray(balanceTransactions, ({ application_id }) => application_id === id);
        const label = `#${id?.split('-')[0]} ${formatPrice(transaction.currency, transaction.amount)}`;
        return { value: id, label };
      });
    }

    return [];
  };

  const fundingOptions = getFundingOptions();

  const getUniqueMonthYearValues = (dates) => {
    const result = dates.map((date) => convertToMonthYear(date));
    return [...new Set(result)];
  };

  useEffect(() => {
    const generateMonthYearOptions = () => {
      const dates = balanceTransactions.map(({ date_created }) => convertDate(date_created));
      const sortedDates = dates.sort().reverse();
      const uniqueMonthYearValues = getUniqueMonthYearValues(sortedDates);
      const options = uniqueMonthYearValues.map((value) => ({ value, label: value }));
      setMonthYearOptions([{ value: OPTION_VALUE_ALL, label: 'All' }, ...options]);
    };

    generateMonthYearOptions();
  }, [JSON.stringify(balanceTransactions)]);

  useEffect(() => {
    const filterTransaction = () => {
      let result = balanceTransactions;

      if (status !== OPTION_VALUE_ALL) {
        result = result.filter((trx) => trx.status.toLowerCase() === status.toLowerCase());
      }

      if (sourceType !== OPTION_VALUE_ALL) {
        result = result.filter((trx) => trx.source_type.toLowerCase() === sourceType.toLowerCase());
      }

      if (monthYear !== OPTION_VALUE_ALL) {
        result = result.filter((trx) => {
          return checkSameMonth(trx.date_created, monthYear);
        });
      }

      setFilteredTransactions(result);
    };

    filterTransaction();
  }, [status, sourceType, monthYear, JSON.stringify(balanceTransactions)]);

  const handleCreateInvoiceClick = () => {
    history.push(PATH.CAPITAL_CREATE_MANUAL_INVOICE);
  };

  return (
    <div>
      <BreadCrumb
        items={[
          {
            value: PATH.CAPITAL,
            label: 'Capital',
          },
          {
            label: 'Transactions',
          },
        ]}
      />
      <Wrapper>
        <SelectWrapper>
          <FilterWrapper>
            <Title>Month/Year</Title>
            <Select value={monthYear} onChange={setMonthYear} options={monthYearOptions} width={150} />
          </FilterWrapper>
          <FilterWrapper>
            <Title>Status</Title>
            <Select value={status} onChange={setStatue} options={balanceStatusOptions} width={150} />
          </FilterWrapper>
          <FilterWrapper>
            <Title>Type</Title>
            <Select value={sourceType} onChange={setSourceType} options={balanceSourceTypeOptions} width={210} />
          </FilterWrapper>
        </SelectWrapper>
        <ButtonWrapper>
          <Button onClick={handleCreateInvoiceClick} secondary>
            Create Invoice
          </Button>
        </ButtonWrapper>
        <BalanceTransactionTable data={filteredTransactions} />
      </Wrapper>
    </div>
  );
};

export default BalanceTransactionList;
