import React from 'react';

import styled from 'styled-components';

import Dropdown, { DropdownOption } from '../../components/Dropdown';
import HR from '../../components/HR';
import { HeaderRow } from './components';
import PlatformStatusTag from './PlatformStatusTag';
import { FormRow, GridColumn } from '@components/Grid';
import { getLogoByPlatformName } from '@utils/platformHelpers';

const Wrapper = styled.div`
  line-height: 24px;
`;

const ContentRow = styled(FormRow)`
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
`;

const AccountName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Logo = styled.img`
  height: 25px;
  line-height: initial;
`;

const StatusTag = styled.div`
  color: ${(props) => props.theme.colors.green};
`;

const AccountingTable = ({ accounts, onDeletePlatformClick, disabled }) => {
  return (
    <Wrapper>
      <HeaderRow>
        <GridColumn lg={2} md={2}>
          Platform
        </GridColumn>
        <GridColumn lg={3} md={3}>
          Name
        </GridColumn>
        <GridColumn lg={3} md={3}>
          Account ID
        </GridColumn>
        <GridColumn lg={2} md={2}>
          Status
        </GridColumn>
      </HeaderRow>
      {accounts.map((account) => {
        const { logoUrl } = getLogoByPlatformName(account?.platformName);
        return (
          <ContentRow>
            <GridColumn lg={2} md={2}>
              <Logo src={logoUrl} alt={account.platformName} />
            </GridColumn>
            <GridColumn lg={3} md={3}>
              <AccountName>{account.accountName}</AccountName>
            </GridColumn>
            <GridColumn lg={3} md={3}>
              {account.accountId}
            </GridColumn>
            <GridColumn lg={2} md={2}>
              <PlatformStatusTag {...account} />
            </GridColumn>
            {!disabled && (
              <GridColumn lg={2} md={2}>
                <Dropdown>
                  <DropdownOption onClick={onDeletePlatformClick(account.platformName, account.id)} warning>
                    Delete Platform
                  </DropdownOption>
                </Dropdown>
              </GridColumn>
            )}
          </ContentRow>
        );
      })}
      <HR />
    </Wrapper>
  );
};

export default AccountingTable;
