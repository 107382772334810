import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import BreadCrumb from '../../../../../components/BreadCrumb';
import Button from '../../../../../components/Button';
import HR from '../../../../../components/HR';
import StepIndicator from '../../../../../components/StepIndicator';
import Logger from '../../../../../utils/logger';
import { Wrapper, Card } from '../../../components';
import PATH from '../../../path';
import { PageHeader } from '../../components';
import { paymentSignUpRequest, paymentOnboarding, resumeOnboarding } from '@api/modules/integration';
import { PAYMENT_ACCOUNT_STATUS } from '@/constants';
import { useIntegrationChannel } from '@hooks/channel';
import { PAYMENT_SETUP_MODAL, PAYMENT_SETUP_REJECTED_MODAL, MESSAGE_MODAL } from '@modules/Modal/constants';
import { fetchStripeData } from '@redux/modules/payment/actions';
import { showModal } from '@redux/modules/UI/actions';
import { media } from '@styles/breakpoints';

const SectionHeader = styled.div`
  ${(props) => props.theme.text.three};
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
  margin-bottom: 12px;
`;

const Title = styled.div`
  ${(props) => props.theme.text.four};
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
  margin-bottom: 12px;
`;

const Description = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  font-weight: 600;
  margin: 20px 0;
  width: 80%;

  ${media.desktop`
    width: 100%;
  `};
`;

const ButtonWrapper = styled.span`
  float: right;
  margin-left: 10px;

  ${media.desktop`
    margin-left: 40px
  `};
`;

const StepsDescription = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.desktop`
    flex-direction: column;
    align-items: start;
  `};
`;

const StepsWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 12px 0;
`;

const CardHeader = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  padding-bottom: 6px;
  font-weight: 600;
`;

const PaymentSetup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const paymentAccounts = useSelector((state) => state.payment.stripeAccounts);
  const isInitiating = useSelector((state) => state.payment.isInitiating);
  const [isLoading, toggleIsLoading] = useState(false);
  const [isEligible, toggleIsEligible] = useState(Array.isArray(paymentAccounts) && paymentAccounts.length > 0);
  const [isApplicationRejected, toggleIsApplicationRejected] = useState(false);
  const [isAccountActive, toggleIsAccountActive] = useState(false);

  const channel = null;

  const reloadPage = () => {
    history.go(0);
  };

  useIntegrationChannel(channel, reloadPage);

  // step 1 sends email and awaits approval from admin
  const fetchSignUpRequestApi = async () => {
    toggleIsLoading(true);
    try {
      const { statusCode, error } = await paymentSignUpRequest();
      if (statusCode === 201) {
        toggleIsEligible(true);
      }
      if (error) {
        throw new Error(error);
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const viewPaymentSetupProgress = async () => {
    await fetchSignUpRequestApi();
    await fetchStripeData();
    dispatch(
      showModal(MESSAGE_MODAL, {
        title: 'Your request was sent to our team successfully',
        description:
          'We will get back to you once we confirm the eligibility of activating the account, subject to the business model and your operating countries (regions)',
        rightButtonProps: {
          label: 'Notify me once it’s available',
        },
        showRightButton: false,
        showLeftButton: false,
      })
    );
  };

  const viewRejection = () => {
    dispatch(
      showModal(MESSAGE_MODAL, {
        title: 'Your business is not eligible to setup wallet account',
        description:
          'Unfortunately, your business is not eligible to setup wallet account at the moment. The availability may vary due to your business model and the country you operated in.',
        rightButtonProps: {
          label: 'Notify me once it’s available',
        },
        leftButtonProps: {
          label: 'Close',
        },
      })
    );
  };

  // step 2 Onboard users and redirects them to Stripe KYC
  const onboardPayment = async () => {
    toggleIsLoading(true);
    try {
      const api = paymentAccounts[0].stripe_account_id ? resumeOnboarding : paymentOnboarding;

      const { data, error } = await api();
      const windowId = 'Stripe';
      const newWin = window.open('', windowId, 'fullscreen');
      const url = data;
      newWin.location.href = url;
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  useEffect(() => {
    if (paymentAccounts?.[0]?.status && isEligible === false) {
      toggleIsEligible(true);
    }

    if (paymentAccounts[0]?.status === PAYMENT_ACCOUNT_STATUS.ACTIVE) {
      toggleIsAccountActive(true);
    }

    if (paymentAccounts[0]?.status === PAYMENT_ACCOUNT_STATUS.REJECTED) {
      toggleIsApplicationRejected(true);
    }
  }, [JSON.stringify(paymentAccounts)]);

  return (
    <>
      <BreadCrumb
        items={[
          {
            value: PATH.PAYMENT_OVERVIEW,
            label: 'Overview',
          },
          {
            label: 'Setup payment account',
          },
        ]}
      />
      <PageHeader>Setup payment account</PageHeader>
      <Wrapper>
        <SectionHeader>Setup payment account</SectionHeader>
        <Card>
          <Title>Setup Guide:</Title>
          <Description>Accept global payments for your online business in 2 simple steps.</Description>
          <HR color="grayOne" />
          <Container>
            <StepsWrapper>
              <div>
                <StepIndicator highlight stepNumber={1} showTick={isAccountActive} showStepNumber />
              </div>
              <div>
                <CardHeader>Check if your business is eligible to setup payment account</CardHeader>
                <StepsDescription>
                  The availability may vary due to your business model and the country (region) of your operations.
                </StepsDescription>
              </div>
            </StepsWrapper>
            <ButtonWrapper>
              {isApplicationRejected ? (
                <Button width={155} full onClick={viewRejection}>
                  View Result
                </Button>
              ) : (
                <Button width={155} full onClick={viewPaymentSetupProgress} disabled={isEligible || isLoading || isInitiating}>
                  {isEligible ? 'Request Sent' : 'Send Request'}
                </Button>
              )}
            </ButtonWrapper>
          </Container>
          <HR color="grayOne" />
          <Container>
            <StepsWrapper>
              <div>
                <StepIndicator highlight={isAccountActive} stepNumber={2} showStepNumber />
              </div>
              <div>
                <CardHeader>Setup payment account</CardHeader>
                <StepsDescription>Connect to payment account</StepsDescription>
              </div>
            </StepsWrapper>
            <ButtonWrapper>
              <Button width={155} full onClick={onboardPayment} disabled={!isAccountActive || isLoading}>
                Connect now
              </Button>
            </ButtonWrapper>
          </Container>
        </Card>
      </Wrapper>
    </>
  );
};

export default PaymentSetup;
