import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import FancyDateRangePicker from '../../../../../components/FancyDateRangePicker';
import SimplePagination from '../../../../../components/Pagination/SimplePagination';
import Table from '../../../../../components/Table';
import Logger from '../../../../../utils/logger';
import { CARD_STATUSES_OPTIONS, OPTION_VALUE_ALL, currencyOptions } from '../../constants';
import { TR, Th, SelectWrapper, FilterLabel, StyledSelect } from '../../IntegratedOverview/components';
import { getColorByCardStatus } from '../helpers';
import { usePagination } from '@hooks/UI';
import { fetchCardTransactions } from '@redux/modules/wallet/actions';
import { convertToApiDate, convertToFullDate } from '@utils/dateHelpers';
import { formatNumber } from '@utils/priceHelpers';
import { replaceDelimiter, capitalize } from '@utils/stringHelpers';
import { areRecordsFewerThanPageSize } from '@utils/utilHelpers';

const FilterWrapper = styled.span`
  display: inline-block;
  margin-left: 16px;
`;

const DateFilterWrapper = styled(FilterWrapper)`
  text-align: left;
`;

const columns = [
  {
    Header: 'Date',
    accessor: 'transaction_date',
    Cell: ({ value }) => {
      return convertToFullDate(value);
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => {
      return capitalize(replaceDelimiter(value, '_', ' ') || '-');
    },
    getStyle: ({ value }) => {
      return css`
        color: ${getColorByCardStatus(value)};
      `;
    },
  },
  {
    Header: 'Card name',
    accessor: 'card_nickname',
  },
  {
    Header: 'Card number',
    accessor: 'masked_card_number',
  },
  {
    Header: 'Details',
    accessor: 'merchant',
    Cell: ({ value }) => {
      return <>{[value.name, value.city, value.country].filter((name) => name).join(', ')}</>;
    },
  },
  {
    Header: 'Currency',
    accessor: 'transaction_currency',
  },
  {
    Header: 'Amount',
    accessor: 'transaction_amount',
    Cell: ({ value }) => {
      return formatNumber(value, 2);
    },
    getStyle: () => {
      return css`
        text-align: right;
        width: 150px;
      `;
    },
  },
];

const Transactions = () => {
  const { id } = useParams();
  const { pageNumber, pageSize, pageCount, onPageNumberChange, onPageSizeChange, changePageCount } = usePagination({
    pageSize: 20,
  });
  const walletAccounts = useSelector((state) => state.wallet.walletAccounts);
  const cardTransactionsList = useSelector((state) => state.wallet.cardTransactionPageMap);
  const data = cardTransactionsList[pageNumber];
  const isLoading = useSelector((state) => state.wallet.isLoading);
  const [currencyFilter, setCurrencyFilter] = useState(OPTION_VALUE_ALL);
  const [statusFilter, setStatusFilter] = useState(OPTION_VALUE_ALL);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateShortcutValue, setDateShortValue] = useState();

  const fetchCardTransactionList = async () => {
    try {
      const filters = [];

      if (statusFilter !== OPTION_VALUE_ALL) {
        filters.push({ field: 'status', operator: 'in', value: statusFilter.map(({ value }) => value) });
      }

      if (currencyFilter !== OPTION_VALUE_ALL) {
        filters.push({
          field: 'transaction_currency',
          operator: 'in',
          value: currencyFilter.map(({ value }) => value),
        });
      }

      if (id) {
        filters.push({
          field: 'card_id',
          operator: 'in',
          value: [id],
        });
      }

      fetchCardTransactions(walletAccounts[0]?.account_id, {
        ...(startDate && { startDate: convertToApiDate(startDate) }),
        ...(endDate && { endDate: convertToApiDate(moment(endDate).add(1, 'days')) }),
        ...(id && { card_id: id }),
        pageNumber,
        pageSize,
        filters,
      });
    } catch (err) {
      Logger.error(err);
    }
  };

  const noNextPage = areRecordsFewerThanPageSize(cardTransactionsList[pageNumber], pageSize);

  const applyCalendar = (calendarData) => {
    setStartDate(calendarData.startDate);
    setEndDate(calendarData.endDate);
    setDateShortValue(calendarData.dateShortcutSelectValue);
  };

  useEffect(() => {
    if (pageNumber !== 1) {
      onPageNumberChange(1);
    } else {
      fetchCardTransactionList();
    }
  }, [currencyFilter, statusFilter, startDate, endDate, pageSize]);

  useEffect(() => {
    fetchCardTransactionList();
  }, [pageNumber]);

  const columnsInContext = id
    ? columns.filter(({ accessor }) => ['card_nickname', 'masked_card_number'].indexOf(accessor) === -1)
    : columns;

  return (
    <>
      <SelectWrapper>
        <FilterWrapper>
          <FilterLabel>Status</FilterLabel>
          <StyledSelect
            isMulti
            width={230}
            value={statusFilter}
            options={CARD_STATUSES_OPTIONS}
            onChange={setStatusFilter}
            disabled={isLoading}
            multiValueLabel="Statuses"
          />
        </FilterWrapper>
        <FilterWrapper>
          <FilterLabel>Currency</FilterLabel>
          <StyledSelect
            isMulti
            width={230}
            value={currencyFilter}
            options={currencyOptions}
            onChange={setCurrencyFilter}
            disabled={isLoading}
            multiValueLabel="Currencies"
          />
        </FilterWrapper>
        <DateFilterWrapper>
          <FancyDateRangePicker
            startDate={startDate}
            endDate={endDate}
            disabled={isLoading}
            dateShortcutValue={dateShortcutValue}
            onDateRangeSelectValueChange={setDateShortValue}
            onApply={applyCalendar}
          />
        </DateFilterWrapper>
      </SelectWrapper>
      <Table columns={columnsInContext} data={data} isLoading={isLoading} />
      <SimplePagination
        pageNumber={pageNumber}
        pageSize={pageSize}
        pageSizeOptions={[
          {
            value: 20,
            label: 20,
          },
          {
            value: 50,
            label: 50,
          },
          {
            value: 100,
            label: 100,
          },
        ]}
        onPageNumberChange={onPageNumberChange}
        onPageSizeChange={onPageSizeChange}
        disabled={isLoading}
        isLoading={isLoading}
        noNextPage={noNextPage}
      />
    </>
  );
};

export default Transactions;
