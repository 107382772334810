import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Dropdown, { DropdownOption } from '../../../../../components/Dropdown';
import FancyDateRangePicker from '../../../../../components/FancyDateRangePicker';
import SimplePagination from '../../../../../components/Pagination/SimplePagination';
import Select from '../../../../../components/Select';
import Table from '../../../../../components/Table';
import ToolTip from '../../../../../components/ToolTip';
import { Wrapper } from '../../../components';
import PATH from '../../../path';
import { PageHeader } from '../../components';
import { getColorByStripeStatus } from '../../helpers';
import { paymentStatusOptions } from '../constants';
import { checkIsAbleToRefund, getAvailableRefundAmount, getRefundedAmount } from '../helpers';
import { OPTION_VALUE_ALL } from '@constants/options';
import { usePagination } from '@hooks/UI';
import { REFUND_PAYMENT_MODAL } from '@modules/Modal/constants';
import { fetchPayments } from '@redux/modules/payment/actions';
import { showModal } from '@redux/modules/UI/actions';
import { useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';
import { convertToApiDate, convertToFullDateAndTime } from '@utils/dateHelpers';
import { getOptionLabelByValue } from '@utils/optionHelpers';
import { adjustStripeAmount, formatNumber, formatPrice } from '@utils/priceHelpers';
import { capitalize } from '@utils/stringHelpers';
import { areRecordsFewerThanPageSize } from '@utils/utilHelpers';

const SelectWrapper = styled.div`
  margin-bottom: 36px;
  text-align: right;
`;

const FilterWrapper = styled.span`
  display: inline-block;
  margin-left: 16px;
`;

const Title = styled.div`
  ${(props) => props.theme.text.five};
  text-align: left;
`;

const BaseSelect = styled(Select)`
  width: 250px;
`;

const DateFilterWrapper = styled(FilterWrapper)`
  text-align: left;
`;

const StyledDropdown = styled(Dropdown)`
  .dropdown-menu {
    width: 160px;
  }
`;

const StatusWrapper = styled.div`
  > * {
    vertical-align: middle;
  }
  > span:first-child {
    margin-right: 4px;
  }
`;

const tableColumns = [
  {
    Header: 'Date',
    accessor: 'created',
    Cell: ({ value }) => {
      return convertToFullDateAndTime(value * 1000);
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value, row }) => {
      const isPartialRefunded = value === 'Partial refund';
      const refundedAmount = getRefundedAmount(row);
      const availableAmount = getAvailableRefundAmount(row);
      const status = capitalize(getOptionLabelByValue(value, paymentStatusOptions) || value);
      return isPartialRefunded ? (
        <ToolTip
          tip={`${formatPrice(row.currency, refundedAmount)} has been refunded. You could still refund all of part of  ${formatPrice(
            row.currency,
            availableAmount
          )}.`}
          placeholder={
            <StatusWrapper>
              <span>{status}</span>
              <img src="/icons/tool_tip.svg" alt="toolTip" />
            </StatusWrapper>
          }
          width={250}
        />
      ) : (
        status
      );
    },
    getStyle: ({ value }) => css`
      color: ${getColorByStripeStatus(value)};
    `,
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Customer',
    accessor: 'customer',
  },
  {
    Header: 'Currency',
    accessor: 'currency',
    Cell: ({ value }) => {
      return typeof value === 'string' ? value.toUpperCase() : '';
    },
    getStyle: () => css`
      width: 50px;
    `,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value }) => {
      return formatNumber(adjustStripeAmount(value), 2);
    },
    getStyle: () => css`
      text-align: right;
      width: 150px;
    `,
  },
  {
    Header: '',
    accessor: 'payments_id',
    Cell: ({ value, row }) => {
      const dispatch = useDispatch();
      const isAdminViewingAnotherCompany = useCheckIsAdminViewingAnotherCompany();
      const handleRefundClick = () => {
        dispatch(showModal(REFUND_PAYMENT_MODAL, { payment: row }));
      };

      const handleCopyIdClick = () => {
        navigator.clipboard.writeText(value);
      };

      return (
        <StyledDropdown>
          <DropdownOption onClick={handleRefundClick} disabled={!checkIsAbleToRefund(row) || isAdminViewingAnotherCompany}>
            Refund payment
          </DropdownOption>
          <DropdownOption onClick={handleCopyIdClick}>Copy payment ID</DropdownOption>
          <DropdownOption disabled>Send receipt</DropdownOption>
        </StyledDropdown>
      );
    },
  },
];

const List = () => {
  const history = useHistory();
  const { isLoading, paymentIntentPageMap } = useSelector((state) => state.payment);
  const [statusFilter, setStatusFilter] = useState(OPTION_VALUE_ALL);
  const [methodFilter, setMethodFilter] = useState(OPTION_VALUE_ALL);
  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');
  const [dateShortcutValue, setDateShortcutValue] = useState('');
  const { pageNumber, pageSize, onPageNumberChange, onPageSizeChange, changePageCount } = usePagination({
    pageSize: 20,
  });

  const formatPageNavLabel = (number) => {
    return ` ${number} `;
  };

  const applyCalendar = (calendarData) => {
    setStartDateFilter(calendarData.startDate);
    setEndDateFilter(calendarData.endDate);
    setDateShortcutValue(calendarData.dateShortcutSelectValue);
  };

  useEffect(() => {
    changePageCount(pageNumber + 1);
  }, [pageNumber]);

  const searchPayments = () => {
    const filters = [];
    if (statusFilter && statusFilter !== OPTION_VALUE_ALL) {
      filters.push({ field: 'status', operator: 'eq', value: statusFilter });
    }
    fetchPayments({
      pageNumber,
      pageSize,
      ...(startDateFilter && { startDate: convertToApiDate(startDateFilter) }),
      ...(endDateFilter && { endDate: convertToApiDate(endDateFilter) }),
      filters,
    });
  };

  useEffect(() => {
    searchPayments();
  }, [pageNumber]);

  useEffect(() => {
    searchPayments();
    onPageNumberChange(1);
  }, [statusFilter, methodFilter, startDateFilter, endDateFilter, pageSize]);

  const noNextPage = areRecordsFewerThanPageSize(paymentIntentPageMap[pageNumber], pageSize);

  const onItemClick = ({ payments_id }) => {
    history.push(generatePath(PATH.PAYMENT_DETAIL, { id: payments_id }));
  };

  return (
    <>
      <PageHeader>Payments</PageHeader>
      <Wrapper>
        <SelectWrapper>
          <FilterWrapper>
            <Title>Status</Title>
            <BaseSelect value={statusFilter} onChange={setStatusFilter} options={paymentStatusOptions} />
          </FilterWrapper>
          {/* <FilterWrapper>
           <Title>Payment method</Title>
           <BaseSelect value={methodFilter} onChange={setMethodFilter} options={paymentMethodOptions} />
         </FilterWrapper> */}
          <DateFilterWrapper>
            <Title> </Title>
            <FancyDateRangePicker
              startDate={startDateFilter}
              endDate={endDateFilter}
              dateShortcutValue={dateShortcutValue}
              onDateRangeSelectValueChange={setDateShortcutValue}
              onApply={applyCalendar}
            />
          </DateFilterWrapper>
        </SelectWrapper>
        <Table
          data={paymentIntentPageMap[pageNumber]}
          columns={tableColumns}
          isLoading={isLoading}
          refreshData={searchPayments}
          onRowClick={onItemClick}
        />
        <SimplePagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          pageSizeOptions={[
            {
              value: 10,
              label: 10,
            },
            {
              value: 20,
              label: 20,
            },
            {
              value: 50,
              label: 50,
            },
            {
              value: 100,
              label: 100,
            },
          ]}
          onPageNumberChange={onPageNumberChange}
          onPageSizeChange={onPageSizeChange}
          formatPageNavLabel={formatPageNavLabel}
          disabled={isLoading}
          isLoading={isLoading}
          noNextPage={noNextPage}
        />
      </Wrapper>
    </>
  );
};

export default List;
