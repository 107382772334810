import styled, { css } from 'styled-components';

export const Footer = styled.div`
  color: ${(props) => props.theme.colors.grayTwo};
  ${(props) => props.theme.text.micro};
  div {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const FormErrorMessage = styled.div`
  margin-top: 4px;
  color: ${(props) => props.theme.colors.red};
  ${(props) => props.theme.text.micro};
`;

export const ButtonWrapper = styled.div`
  margin-top: 8px;
  text-align: right;
  button ~ button {
    margin-left: 16px;
  }
`;
