import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useSortBy, useTable } from 'react-table';
import styled from 'styled-components';

import Arrow, { ARROW_DIRECTION } from '../../../../assets/icons/Arrow';
import Select from '../../../../components/Select';
import Logger from '../../../../utils/logger';
import { Wrapper } from '../../components';
import { Header, Table, Th } from '../components';
import columnConfig from './tableColConfig';
import { API_FB_INTEGRATION } from '@api/constants';
import { GridColumn } from '@components/Grid';
import { FBDatePresetOptions } from '@constants/options';
import { PLATFORM } from '@constants/platform';
import { fetchIntegrations } from '@redux/modules/application/actions';
import { useSelectedCompanyId } from '@redux/selectors';
import { isRealNumber } from '@utils/dataTypes';
import { formatPrice, formatNumber } from '@utils/priceHelpers';

const StyledGridColumn = styled(GridColumn)`
  line-height: 25px;
  vertical-align: middle;

  div {
    vertical-align: middle;
  }
`;

const SelectContainer = styled.div`
  margin-right: 9px;
`;

const InfoPanel = styled.div`
  padding: 16px 0px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`;

const InfoItem = styled.div`
  flex: 1 1 0px;
  margin: 8px 12px 0 0;
  padding: 14px 20px;
  position: relative;
  background: ${(props) => props.theme.colors.white};
`;

const InfoTitle = styled.div`
  font-weight: bold;
  ${(props) => props.theme.text.micro};
  padding-bottom: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const InfoNumber = styled.div`
  ${(props) => props.theme.text.four};
  overflow-wrap: break-word;
  text-align: left;
`;

const StyledArrow = styled(Arrow)`
  vertical-align: middle;
  margin-left: 4px;
`;

const PageController = styled.div`
  margin: 12px 0;
  text-align: center;
  span {
    line-height: 22px;
  }

  select {
    margin-left: 16px;
  }

  select,
  button {
    height: 22px;
  }

  button {
    margin: 0 4px;
  }
`;

const calendarIconStyle = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundImage: 'url("/icons/calendar.svg")',
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 23,
    width: 23,
  },
});

const dotStyle = {
  placeholder: (styles) => ({ ...styles, ...calendarIconStyle() }),
  singleValue: (styles, { data }) => ({ ...styles, ...calendarIconStyle() }),
};

const FbPerformance = () => {
  const companyId = useSelectedCompanyId();
  const integrations = useSelector((state) => state.application.integrations);

  // TODO: apply latest usePagination after bank transaction
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [pageCount, setPageCount] = useState(0);
  const [campaignData, setCampaignData] = useState([]);
  const [campaignSummary, setCampaignSummary] = useState({
    spend: 0,
    revenue: 0,
    roas: 0,
    clicks: 0,
    activeCampaigns: 0,
  });
  const [campaignDataChunks, setCampaignDataChunks] = useState([]);
  const [dataToBeShown, setDataToBeShown] = useState([]);

  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState();
  const [selectedDatePreset, setSelectedDatePreset] = useState();
  const [fbUserAcctId, setFbUserAcctId] = useState();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      data: dataToBeShown,
      columns: columnConfig,
    },
    useSortBy
  );

  /**
   * getPerformance gets the performance data for a Facebook Ad Account.
   * @param adAcctId Facebook ad account id
   * @param datePreset the date range selected
   * @return {Promise<any>}
   */
  const getPerformance = (adAcctId, datePreset) => {
    return fetch(`${API_FB_INTEGRATION}/v1/performance/${companyId}/${adAcctId}/${datePreset}`)
      .then((response) => {
        if (response.status === 500) {
          throw new Error('Internal Server Error.');
        }
        return response.json();
      })
      .then((data) => {
        setCampaignData(data.data);
      })
      .catch((e) => {
        Logger.error(e);
      });
  };

  /**
   * getAcctSummary retrieves the Facebook account summary based upon company id and the Facebook profile id.
   * @param chosenCompanyId the chosen company id
   * @param acctId the Facebook profile id
   * @return {Promise<any>}
   */
  const getAcctSummary = (chosenCompanyId, acctId) => {
    fetch(`${API_FB_INTEGRATION}/v1/acct-summary/${chosenCompanyId}/${acctId}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data) {
          return;
        }
        const accountData = data.data;
        if (!Array.isArray(accountData)) {
          return;
        }
        if (Array.isArray(accountData) && accountData.length > 0) {
          setAccounts(
            accountData
              .filter((acct) => acct.selected === true)
              .map((acct) => {
                return {
                  id: acct.id,
                  name: acct.id,
                  label: acct.name,
                  value: acct.id,
                  icon: '/icons/business/svg',
                };
              })
          );
        }
      });
  };

  /**
   * onAccountChange changes the ad account to be chosen to show and gets the
   * performance from the backend.
   * @param value the ad account id
   * @return {Promise<void>}
   */
  const onAccountChange = async (value) => {
    setSelectedAccount(value);
    if (value && value !== '' && selectedDatePreset && selectedDatePreset !== '') {
      await getPerformance(value, selectedDatePreset);
    }
  };

  /**
   * onDatePresetChange changes the date preset to be chosen to show and gets the
   * performance from the backend.
   * @param value the chosen date preset value
   * @return {Promise<void>}
   */
  const onDatePresetChange = async (value) => {
    setSelectedDatePreset(value);
    if (value && value !== '' && selectedAccount && selectedAccount !== '') {
      await getPerformance(selectedAccount, value);
    }
  };

  useEffect(() => {
    fetchIntegrations();
  }, []);

  useEffect(() => {
    if (companyId && Array.isArray(integrations)) {
      const fbUserAccounts = integrations.filter(({ platformName, status }) => platformName === PLATFORM.FACEBOOK_ADS && status === 1);
      if (Array.isArray(fbUserAccounts) && fbUserAccounts.length > 0) {
        setFbUserAcctId(fbUserAccounts[0].accountId);
        // Get acct summary
        getAcctSummary(companyId, fbUserAccounts[0].accountId);
      }
    }
  }, [companyId, integrations]);

  useEffect(() => {
    if (Array.isArray(accounts) && accounts.length > 0) {
      setSelectedAccount(accounts[0].value);
      setSelectedDatePreset(FBDatePresetOptions[0].value);
      getPerformance(accounts[0].value, FBDatePresetOptions[0].value);
    }
  }, [accounts]);

  useEffect(() => {
    if (Array.isArray(campaignData) && campaignData.length > 0) {
      // Create Campaign Summary
      const summary = campaignData.reduce(
        (prev, curr, idx, arr) => {
          // Handle spend
          let currentSpend = 0;
          if (curr.spend) {
            const spend = parseFloat(curr.spend);
            if (!isRealNumber(spend)) {
              currentSpend = spend;
            }
          } else {
            // side effect: change spend from undefined/null to 0
            campaignData[idx].spend = 0;
          }

          // Handle clicks
          let currentClicks = 0;
          if (curr.clicks) {
            const clicks = parseFloat(curr.clicks);
            if (!Number.isNaN(clicks)) {
              currentClicks = clicks;
            }
          } else {
            // side effect: change clicks from undefined/null to 0
            campaignData[idx].clicks = 0;
          }

          // Handle ROAS
          let currentROAS = 0;
          if (curr.purchase_roas && curr.purchase_roas.length > 0) {
            curr.purchase_roas.forEach((item, itemIdx) => {
              const purchaseROAS = parseFloat(item.value);
              if (!Number.isNaN(purchaseROAS)) {
                currentROAS += purchaseROAS;
              } else {
                // side effect: change ROAS value from undefined/null to 0
                campaignData[idx][itemIdx].value = 0;
              }
            });
          }
          campaignData[idx].total_purchased_roas = currentROAS;

          // Handle attributed conversions
          let conversionValues = 0;
          if (curr.conversion_values && curr.conversion_values.length > 0) {
            conversionValues = curr.conversion_values.reduce((prevItem, currItem) => {
              const val = parseFloat(currItem.value);
              if (!Number.isNaN(val)) {
                return prev + val;
              }
              return prev;
            }, 0);
          }
          campaignData[idx].attributed_conversions = conversionValues;

          // Handle converted product value
          // change it from string to float if not null/undefined
          let convertedProductValue = 0;
          if (campaignData[idx].converted_product_value) {
            convertedProductValue = curr.converted_product_value.reduce((prevItem, currItem) => {
              const val = parseFloat(currItem.value);
              if (!Number.isNaN(val)) {
                return prev + val;
              }
              return prev;
            }, 0);
          }
          campaignData[idx].converted_product_values = convertedProductValue;

          // Handle cpc
          if (campaignData[idx].cpc) {
            campaignData[idx].cpc = parseFloat(campaignData[idx].cpc);
          }

          // Handle impressions
          if (campaignData[idx].impressions) {
            campaignData[idx].impressions = parseFloat(campaignData[idx].impressions);
          }

          return {
            spend: prev.spend + currentSpend,
            revenue: prev.revenue + convertedProductValue,
            roas: prev.roas + currentROAS,
            clicks: prev.clicks + currentClicks,
          };
        },
        {
          spend: 0,
          revenue: 0,
          roas: 0,
          clicks: 0,
        }
      );
      summary.activeCampaigns = campaignData.length;
      setCampaignSummary(summary);

      // Split campaign data into chunks
      const result = campaignData.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / pageSize);

        if (!resultArray[chunkIndex]) {
          // start a new chunk
          resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);

      setPageCount(result.length);
      setCampaignDataChunks(result);
      setDataToBeShown(result[0]);
    } else {
      setCampaignSummary({
        spend: 0,
        revenue: 0,
        roas: 0,
        clicks: 0,
      });
      setPageCount(0);
      setCampaignDataChunks([]);
      setDataToBeShown([]);
    }
  }, [campaignData]);

  useEffect(() => {
    if (Array.isArray(campaignDataChunks) && campaignDataChunks.length > pageIndex) {
      setDataToBeShown(campaignDataChunks[pageIndex]);
    }
  }, [pageIndex, campaignDataChunks]);

  return (
    <>
      <Header>Facebook Ads</Header>
      <Wrapper>
        <SelectContainer>
          <StyledGridColumn lg={3} md={3} disableGutter>
            <Select value={selectedAccount} options={accounts} onChange={onAccountChange} />
          </StyledGridColumn>
          <StyledGridColumn lg={6} md={4} />
          <StyledGridColumn lg={3} md={5} disableGutter>
            <Select styles={dotStyle} value={selectedDatePreset} options={FBDatePresetOptions} onChange={onDatePresetChange} />
          </StyledGridColumn>
        </SelectContainer>
        <InfoPanel>
          <InfoItem>
            <InfoTitle>Spend</InfoTitle>
            {/* TODO: use currency */}
            <InfoNumber>{formatPrice('USD', campaignSummary.spend, false, 2)}</InfoNumber>
          </InfoItem>
          <InfoItem>
            <InfoTitle>Revenue</InfoTitle>
            {/* TODO: use currency */}
            <InfoNumber>{formatPrice('USD', campaignSummary.revenue, false, 2)}</InfoNumber>
          </InfoItem>
          <InfoItem>
            <InfoTitle>ROAS</InfoTitle>
            <InfoNumber>{formatNumber(campaignSummary.roas, 2)}</InfoNumber>
          </InfoItem>
          <InfoItem>
            <InfoTitle>Clicks</InfoTitle>
            <InfoNumber>{formatNumber(campaignSummary.clicks)}</InfoNumber>
          </InfoItem>
          <InfoItem>
            <InfoTitle>Active Campaigns</InfoTitle>
            <InfoNumber>{formatNumber(campaignSummary.activeCampaigns)}</InfoNumber>
          </InfoItem>
        </InfoPanel>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <Th isSortable={column.isSortable} {...column.getHeaderProps(column.isSortable && column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {column.isSorted ? <StyledArrow direction={column.isSortedDesc ? ARROW_DIRECTION.DOWN : ARROW_DIRECTION.UP} /> : ''}
                    </Th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                {columnConfig.map(() => {
                  return <td>-</td>;
                })}
              </tr>
            )}
          </tbody>
        </Table>
        {pageCount ? (
          <PageController>
            <button type="button" disabled={pageIndex === 0} onClick={() => setPageIndex(0)}>
              {'<<'}
            </button>
            <button type="button" onClick={() => setPageIndex(pageIndex - 1)} disabled={pageIndex === 0}>
              {'<'}
            </button>
            <span>{` ${pageIndex + 1} of ${pageCount} `}</span>
            <button type="button" disabled={pageIndex + 1 === pageCount} onClick={() => setPageIndex(pageIndex + 1)}>
              {'>'}
            </button>
            <button type="button" disabled={pageIndex + 1 === pageCount} onClick={() => setPageIndex(pageCount - 1)}>
              {'>>'}
            </button>
          </PageController>
        ) : null}
      </Wrapper>
    </>
  );
};

export default FbPerformance;
