import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import styled from 'styled-components';

import Button from '../Button';
import HR from '../HR';
import Select from '../Select';
import { calendarShortcutOptions } from '@constants/options';
import { getDateMomentsByShortcut, datePickerAllowDaysBeforeToday } from '@utils/dateHelpers';

const WRAPPER_PADDING_PX = 21;

const Wrapper = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  z-index: 1;
  padding: 16px 0;
  background-color: ${(props) => props.theme.colors.white};
  min-width: 130px;
`;

const InputWrapper = styled.div`
  padding: 0 ${WRAPPER_PADDING_PX}px;
`;

const DateShortcutSelect = styled(Select)`
  margin-bottom: 16px;
`;

const PickerWrapper = styled.div`
  .DateInput {
    width: 275px !important;

    .DateInput_input {
      box-sizing: border-box;
      padding: 11px 11px 9px 16px;
      ${(props) => props.theme.input.border};
    }
  }

  input::placeholder {
    font-size: 14px;
    color: ${(props) => props.theme.colors.grayTwo};
  }

  input {
    ${(props) => props.theme.text.five};
  }

  .DateInput_fang {
    display: none;
  }
  .DayPicker__withBorder {
    box-shadow: none;
  }
  .DateRangePicker_picker {
    top: initial;
    left: initial;
    position: initial;
  }

  .DateRangePicker {
    display: block;
  }

  .DateRangePicker:first-child {
    .DateRangePicker_picker {
      display: none;
    }
  }

  .DateRangePicker:first-child {
    margin: 0 ${WRAPPER_PADDING_PX}px;
    box-sizing: border-box;
  }

  .DateRangePickerInput_arrow {
    display: none;
  }

  .DateRangePicker:last-child {
    .DateInput,
    .DayPickerKeyboardShortcuts_buttonReset {
      display: none;
    }
  }

  #fancy_date_range_picker_end_date {
    margin-left: 26px;
  }

  .CalendarDay__selected_start,
  .CalendarDay__selected_end {
    background: ${(props) => props.theme.colors.purple};
    border: 1px double ${(props) => props.theme.colors.purple};
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span {
    color: ${(props) => props.theme.colors.purple};
    background: ${(props) => props.theme.colors.lightPurple};
    border: 1px double ${(props) => props.theme.colors.lightPurple};
  }
`;

const ButtonWrapper = styled.div`
  padding: 0 ${WRAPPER_PADDING_PX}px;

  button:last-child {
    float: right;
  }
`;

const Portal = ({
  className,
  idPrefix,
  innerRef,
  startDate,
  endDate,
  dateShortcutValue,
  includeToday,
  displayFormat,
  togglePortal = () => {},
  onApply = () => {},
}) => {
  const [focusedInput, setFocusedInput] = useState('startDate');
  const [_startDate, setStartDate] = useState(startDate);
  const [_endDate, setEndDate] = useState(endDate);
  const [_dateShortcutValue, setDateShortValue] = useState(dateShortcutValue);

  useEffect(() => {
    if (_dateShortcutValue) {
      const { startDateMoment, endDateMoment } = getDateMomentsByShortcut(_dateShortcutValue, includeToday);
      setStartDate(startDateMoment);
      setEndDate(endDateMoment);
    }
  }, [_dateShortcutValue]);

  useEffect(() => {
    if (_startDate && _endDate) {
      const { startDateMoment, endDateMoment } = getDateMomentsByShortcut(_dateShortcutValue, includeToday);
      const didStartDateChange =
        startDateMoment &&
        !(
          _startDate.isSame(startDateMoment, 'year') &&
          _startDate.isSame(startDateMoment, 'month') &&
          _startDate.isSame(startDateMoment, 'day')
        );
      const didEndDateChange =
        endDateMoment &&
        !(_endDate.isSame(endDateMoment, 'year') && _endDate.isSame(endDateMoment, 'month') && _endDate.isSame(endDateMoment, 'day'));
      if (didStartDateChange || didEndDateChange) {
        setDateShortValue(null);
      }
    }
  }, [_startDate, _endDate]);

  const handleApply = () => {
    setDateShortValue(_dateShortcutValue);
    onApply({
      startDate: _startDate,
      endDate: _endDate,
      dateShortcutSelectValue: _dateShortcutValue,
    });
    togglePortal();
  };

  const handleDatesChange = (data) => {
    setStartDate(data.startDate);
    setEndDate(data.endDate);
  };

  const Picker = (
    <DateRangePicker
      startDate={_startDate}
      startDateId={`${idPrefix}_start_date`}
      endDate={_endDate}
      endDateId={`${idPrefix}_end_date`}
      focusedInput={focusedInput}
      onFocusChange={(_focusedInput) => {
        if (!_focusedInput) {
          return;
        }
        setFocusedInput(_focusedInput);
      }}
      onDatesChange={handleDatesChange}
      numberOfMonths={2}
      isOutsideRange={datePickerAllowDaysBeforeToday}
      keepOpenOnDateSelect
      displayFormat={displayFormat}
      noBorder
    />
  );

  return (
    <Wrapper ref={innerRef} className={className}>
      <InputWrapper>
        <DateShortcutSelect
          value={_dateShortcutValue}
          options={calendarShortcutOptions}
          onChange={setDateShortValue}
          placeholder="Date range"
        />
      </InputWrapper>
      <PickerWrapper>
        {Picker}
        {React.cloneElement(Picker)}
      </PickerWrapper>
      <HR margin={WRAPPER_PADDING_PX} color="grayOne" />
      <ButtonWrapper>
        <Button onClick={togglePortal} tertiary>
          Cancel
        </Button>
        <Button disabled={!_startDate || !_endDate} onClick={handleApply}>
          Apply
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Portal;
