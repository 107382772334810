import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';
import styled from 'styled-components';

import BackButton from '../../../../../components/BackButton';
import Button from '../../../../../components/Button';
import FormTitle from '../../../../../components/FormTitle';
import Logger from '../../../../../utils/logger';
import { Card } from '../../../components';
import PATH from '../../../path';
import CompanyCardFormContent from '../components/CompanyCardForm';
import { generateCardPayload } from '../helpers';
import { createWalletCard } from '@api/modules/integration/airwallex';
import { fetchWalletCards } from '@redux/modules/wallet/actions';
import { useWalletAccount, useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';

const Form = styled.form`
  width: 50%;
  max-width: 800px;
  min-width: 400px;
`;

const ButtonWrapper = styled.div`
  ${(props) => props.theme.buttonWrapper};

  div {
    ${(props) => props.theme.text.error};
  }
`;

const CompanyCardContainer = () => {
  const history = useHistory();
  const walletAccount = useWalletAccount();
  const [isLoading, toggleIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const { username } = useSelector((state) => {
    return state.user.profile;
  });
  const isAdminViewingAnotherCompany = useCheckIsAdminViewingAnotherCompany();

  const formProps = useForm({
    defaultValues: {
      authorization_controls: {
        allowed_transaction_count: 'MULTIPLE',
        transaction_limits: {
          currency: walletAccount.currency,
          limits: [{}],
        },
      },
      created_by: `Choco up client - ${username} `,
      form_factor: 'VIRTUAL',
      issue_to: 'ORGANISATION',
    },
  });

  const {
    control,
    watch,
    handleSubmit,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = formProps;

  const onSubmit = async (formData) => {
    toggleIsLoading(true);
    setApiError('');
    try {
      const { statusCode, error, data } = await createWalletCard(walletAccount.account_id, generateCardPayload(formData));
      if (statusCode === 200) {
        history.push(generatePath(PATH.WALLET_CARDS_CARD_REVIEW, { id: data.card_id }));
        fetchWalletCards(walletAccount.account_id, {
          pageNumber: 1,
          pageSize: 100,
        });
      } else {
        throw new Error(error || 'Cannot create card');
      }
    } catch (err) {
      Logger.error(err);
      setApiError('Something went wrong');
    } finally {
      toggleIsLoading(false);
    }
  };

  const handleBackClick = (event) => {
    event.preventDefault();
    history.replace(PATH.WALLET_CARDS_CREATE_SELECT_TYPE);
  };

  return (
    <>
      <BackButton path={PATH.WALLET_CARDS_CREATE_SELECT_TYPE} disabled={isLoading} />
      <FormTitle scale={3}>Create a company card</FormTitle>
      <Card>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CompanyCardFormContent formProps={formProps} />
          <ButtonWrapper>
            <Button secondary onClick={handleBackClick} disabled={isLoading}>
              Back
            </Button>
            <Button type="submit" disabled={isLoading || isAdminViewingAnotherCompany}>
              Create Card
            </Button>
            {apiError && <div>{apiError}</div>}
          </ButtonWrapper>
        </Form>
      </Card>
    </>
  );
};

export default CompanyCardContainer;
