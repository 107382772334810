import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';

export const useQuery = () => {
  const location = useLocation();
  return {
    search: location.search,
    parsed: parse(location.search.replace('?', '')),
  };
};

export const useModule = () => {
  const location = useLocation();
  return location.pathname.split('/')[1];
};
