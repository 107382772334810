import { dashboardUrl } from ".";
import { getRequest, putRequest, postRequest } from '@api/requests';

export const getAllCompaniesAccessedByAdmin = (adminId) => {
  return getRequest(`${dashboardUrl}/admin/${adminId}/companies`);
};

export const getAdmins = () => {
  return getRequest(`${dashboardUrl}/admins`);
};

export const assignAdminsToCompany = async (payload) => {
  return await postRequest(`${dashboardUrl}/admin/assignCompany`, payload);
};
