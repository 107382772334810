import React from 'react';

import OnboardingOverview from '../../components/OnboardingOverview';
import PATH from '../../path';

const Onboarding = () => {
  const WALLET_ONBOARDING_CONTENT = {
    title: 'Create your free borderless account',
    description:
      'Open domestic and foreign currency business accounts in minutes to start collecting payments. Make fast, low-cost transfers domestically and internationally. Sell globally by accepting international and local payment methods.',
    path: PATH.WALLET_SETUP,
    imgUrl: 'https://asset.choco-up.com/tutorial/airwallex/airwallex-onboarding-v3.png',
  };

  return <OnboardingOverview {...WALLET_ONBOARDING_CONTENT} />;
};

export default Onboarding;
