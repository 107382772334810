import React from 'react';

import styled from 'styled-components';

const DownloadTemplateLabel = styled.span`
  ${(props) => props.theme.text.link};
`;

const UploadDescriptionHeader = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: bold;
`;

const Description = styled.div`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  margin: 10px 0 30px 0;
`;

const downloadTemplate = () => {
  window.open('https://asset.choco-up.com/document/chocoup_profit_loss_template.xlsx', '_blank');
};

export const PLModalTitle = 'Upload P&L Document';

export const PLModalDescription = (
  <>
    <Description>
      Please upload your Profit & Loss statements if you cannot connect your accounting software.
    </Description>
    <UploadDescriptionHeader scale={5}>Upload requirements:</UploadDescriptionHeader>
    <Description>
      <ul>
        <li>Provide the P&L with data of last 12 months, more if possible</li>
        <li>Monthly breakdowns</li>
        <li>Upload editable files such as .xls, xlsx, or csv</li>
      </ul>
      Not sure what detail to include?
      {' '}
      <DownloadTemplateLabel onClick={downloadTemplate}>Download our .xlsx template</DownloadTemplateLabel>
    </Description>
    {' '}
  </>
);

export const PLAcceptFileType = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
].join(', ');
