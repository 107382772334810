import React, { useEffect } from 'react';

import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: inline-block;
`;

const Circle = styled.circle`
  fill: none;
  stroke: ${(props) => props.color || props.theme.colors.grayTwo};
  stroke-width: ${(props) => props.strokeWidth};
  transition: stroke-dashoffset 0.7s;
  stroke-linecap: ${(props) => props.strokeLinecap};
`;

const CircularProgressBar = ({
  lines = [], // bigger indexed lines will be on top of smaller indexed lines
  total = 100,
  radius = 50,
  padding = 10,
  strokeWidth = 10,
  strokeLinecap = 'round',
  className,
}) => {
  const configCircleProgressBar = (paidAmount = 0, totalAmount, progressBarClassName) => {
    const progressBar = document.querySelector(progressBarClassName);
    if (progressBar) {
      const length = Math.PI * 2 * radius;
      const offset = length - (length * paidAmount) / totalAmount;
      progressBar.style.strokeDasharray = length;
      progressBar.style.strokeDashoffset = `${offset}px`;
    }
  };

  useEffect(() => {
    if (Array.isArray(lines)) {
      // eslint-disable-next-line guard-for-in
      for (const index in lines) {
        const { value } = lines[index];
        configCircleProgressBar(value, total, `.progress-${index}`);
      }
    }
  }, [JSON.stringify(lines)]);

  const viewBoxAdjustment = radius * 2 + padding;
  const viewBox = `0 0 ${viewBoxAdjustment} ${viewBoxAdjustment}`;
  return (
    <Wrapper className={className}>
      <svg width={viewBoxAdjustment + radius * 0.8} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
        <g transform={`translate(${viewBoxAdjustment / 2},${viewBoxAdjustment / 2})`}>
          <Circle r={radius} strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} />
          <g transform="rotate(-90)">
            {Array.isArray(lines) &&
              lines.map(({ id, color }, index) => {
                return (
                  <Circle
                    key={id}
                    r={radius}
                    color={color}
                    className={`progress-${index}`}
                    strokeWidth={strokeWidth}
                    strokeLinecap={strokeLinecap}
                  />
                );
              })}
          </g>
        </g>
      </svg>
    </Wrapper>
  );
};

export default CircularProgressBar;
