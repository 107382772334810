import moment from 'moment';
import isInclusivelyAfterDay from 'react-dates/lib/utils/isInclusivelyAfterDay';

import { getNumberFromFormatted, getPercentFromDecimal } from '@utils/priceHelpers';

export const validateExpiryDay = (offerIndex, offers) => (value) => {
  if (offerIndex === 0) {
    // only check first offer
    if (Array.isArray(offers) && offers.length > 0) {
      const result = offers.every((data, index) => {
        if (index === 0) {
          return true;
        }
        const momented = moment(value);
        return (
          (isInclusivelyAfterDay(moment(data.disbursementDate), momented) &&
            isInclusivelyAfterDay(moment(data.firstRemittanceDate), momented)) === true
        );
      });
      return result;
    }
  }

  return true;
};

export const getActiveStepNumber = (completeness = []) => {
  return completeness.indexOf(false) + 1;
};
