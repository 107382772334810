import React, { useState, useEffect } from 'react';

import { stringify } from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import HR from '../../../../components/HR';
import { MARKETING_PLATFORM_INTEGRATION } from '../../../Modal/constants';
import {
  TAB,
  ENABLED_SALES_PLATFORM_OPTIONS,
  ENABLED_PAYMENT_PLATFORM_OPTIONS,
  CONTENT_CONTAINER_ID,
  VIEW_ALL_OPTION,
} from '../../constants';
import PATH from '../../path';
import { GridColumn } from '@components/Grid';
import { Currency } from '@/constants';
import { PLATFORM, PLATFORM_DISPLAY_NAME, PLATFORM_MAP } from '@constants/platform';
import { showModal } from '@redux/modules/UI/actions';
import { findFirstMatchInArray } from '@utils/dataTypes';
import { isBBMSLIntegrated, formatGAAccountLabel, getAccountDisplayName } from '@utils/platformHelpers';
import { formatPrice } from '@utils/priceHelpers';

const Card = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px 20px;
  border-radius: 5px;
  margin-top: 10px;
`;

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 67px;
`;

const PlatformInfoDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardTitle = styled.div`
  ${(props) => props.theme.text.three};
  font-weight: bold;
  margin-bottom: 16px;
`;

const ChannelCategory = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: bold;
  margin-bottom: 16px;
`;

const PlatformTitle = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: bold;
  color: ${(props) => props.theme.colors.grayThree};
`;

const ConnectedPlatformStatus = styled.span`
  ${(props) => props.theme.text.five};
  font-weight: bold;
  color: ${(props) => props.theme.colors.green};
  vertical-align: middle;
`;

const PendingPlatformStatus = styled.span`
  ${(props) => props.theme.text.five};
  font-weight: bold;
  color: ${(props) => props.theme.colors.red};
`;

const CardDescription = styled.div`
  ${(props) => props.theme.text.micro};
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 68px;
`;

const ConnectedDot = styled.div`
  background-color: ${(props) => props.theme.colors.green};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: 4px;
  display: inline-block;
`;

const IntegrateLink = styled.span`
  ${(props) => props.theme.text.link};
`;

const ViewReportLink = styled(Link)`
  ${(props) => props.theme.text.link};
`;

const IntegratedAccountName = styled.div`
  ${(props) => props.theme.text.micro};
  color: ${(props) => props.theme.colors.grayThree};
  font-weight: bold;
  margin-bottom: 10px;
`;

const StoreNameTitle = styled.div`
  ${(props) => props.theme.text.micro};
  font-weight: bold;
  color: ${(props) => props.theme.colors.grayThree};
  margin: -5px 0 22px 0;
`;

const AccountSummaryInfo = styled.div`
  ${(props) => props.theme.text.micro};
  font-weight: bold;
  color: ${(props) => props.theme.colors.grayThree};
  margin-bottom: 10px;
`;

const SalesReportOverview = ({ accountSummaries = [] }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const integrations = useSelector((state) => state.application.integrations);

  const getPaymentOptions = () => {
    return ENABLED_PAYMENT_PLATFORM_OPTIONS.filter((option) => (isBBMSLIntegrated(integrations) ? true : option.value !== PLATFORM.BBMSL));
  };

  const showGAIntegrationModal = (integrationId, accountId) => {
    dispatch(
      showModal(MARKETING_PLATFORM_INTEGRATION, {
        component: PLATFORM.GOOGLE_ANALYTICS,
        integrationId,
        accountId,
      })
    );
  };

  const OPTIONS = {
    [TAB.SALES]: ENABLED_SALES_PLATFORM_OPTIONS,
    [TAB.PAYMENT]: getPaymentOptions(),
    [TAB.ANALYTICS]: [{ value: PLATFORM.GOOGLE_ANALYTICS, label: PLATFORM_DISPLAY_NAME.GOOGLE_ANALYTICS }],
  };

  const salesPlatformValues = OPTIONS[TAB.SALES].map((options) => {
    return options.value;
  });

  const integratedSalesPlatforms = integrations.filter((value) => {
    return salesPlatformValues.indexOf(value.platformName) !== -1;
  });

  const notYetSalesPlatforms = salesPlatformValues.filter((value) => {
    const integratedSalesPlatformValues = integratedSalesPlatforms.map(({ platformName }) => platformName);
    return integratedSalesPlatformValues.indexOf(value) === -1;
  });

  const paymentPlatformValues = OPTIONS[TAB.PAYMENT].map((options) => {
    return options.value;
  });

  const integratedPaymentPlatforms = integrations.filter((value) => {
    return paymentPlatformValues.indexOf(value.platformName) !== -1;
  });

  const notYetPaymentPlatforms = paymentPlatformValues.filter((value) => {
    const integratedPaymentPlatformValues = integratedPaymentPlatforms.map(({ platformName }) => platformName);
    return integratedPaymentPlatformValues.indexOf(value) === -1;
  });

  const analyticPlatform = integrations.filter((value) => {
    return PLATFORM.GOOGLE_ANALYTICS.indexOf(value.platformName) !== -1;
  });

  const handleNoDataButtonClick = () => {
    history.push(PATH.INTEGRATION);
    document.getElementById(CONTENT_CONTAINER_ID)?.scroll(0, 0);
  };

  const handleViewReport = (category, platform, channelId) => {
    const selectedPlatform = findFirstMatchInArray(integrations, (account) => account.accountId === channelId);
    if (selectedPlatform) {
      const platformValue = category === TAB.ANALYTICS ? VIEW_ALL_OPTION.value : platform;
      return stringify({ category, platform: platformValue, account: selectedPlatform.id });
    } else {
      return '';
    }
  };

  return (
    <>
      <GridColumn lg={8} md={12} disableGutter>
        {accountSummaries.length > 0 &&
          accountSummaries.map((accountSummary) => {
            const selectedPlatform = findFirstMatchInArray(
              integrations,
              (account) => account.accountId === accountSummary.channel_account_id
            );
            return (
              <GridColumn lg={6} md={6}>
                <Card>
                  <PlatformInfoDiv>
                    <CardTitle>{PLATFORM_MAP[accountSummary.platform].displayName}</CardTitle>
                    <ViewReportLink
                      to={`${PATH.BUSINESS_SALES_REPORT}?${handleViewReport(
                        accountSummary.channel,
                        accountSummary.platform,
                        accountSummary.channel_account_id
                      )}`}
                    >
                      View report
                    </ViewReportLink>
                  </PlatformInfoDiv>
                  <StoreNameTitle>{getAccountDisplayName(selectedPlatform)}</StoreNameTitle>
                  <>
                    <PlatformInfoDiv>
                      <AccountSummaryInfo>Orders</AccountSummaryInfo>
                      <AccountSummaryInfo>{accountSummary.summary.orders}</AccountSummaryInfo>
                    </PlatformInfoDiv>
                    <PlatformInfoDiv>
                      <AccountSummaryInfo>Customers</AccountSummaryInfo>
                      <AccountSummaryInfo>{accountSummary.summary.customers}</AccountSummaryInfo>
                    </PlatformInfoDiv>
                    <PlatformInfoDiv>
                      <AccountSummaryInfo>Total Sales</AccountSummaryInfo>
                      <AccountSummaryInfo>{formatPrice(Currency.USD, accountSummary.summary.sales)}</AccountSummaryInfo>
                    </PlatformInfoDiv>
                    <PlatformInfoDiv>
                      <AccountSummaryInfo>Refunds</AccountSummaryInfo>
                      <AccountSummaryInfo>{formatPrice(Currency.USD, accountSummary.summary.refunds)}</AccountSummaryInfo>
                    </PlatformInfoDiv>
                    <PlatformInfoDiv>
                      <AccountSummaryInfo>Revenue</AccountSummaryInfo>
                      <AccountSummaryInfo>{formatPrice(Currency.USD, accountSummary.summary.revenue)}</AccountSummaryInfo>
                    </PlatformInfoDiv>
                    <PlatformInfoDiv>
                      <AccountSummaryInfo>AOV</AccountSummaryInfo>
                      <AccountSummaryInfo>
                        {accountSummary.summary.aov === null ? '-' : formatPrice(Currency.USD, accountSummary.summary.aov, false, 2)}
                      </AccountSummaryInfo>
                    </PlatformInfoDiv>
                  </>
                </Card>
              </GridColumn>
            );
          })}
        <GridColumn lg={6} md={6}>
          <Card>
            <CardTitle>Connect Channel</CardTitle>
            <CardDescription>
              Connect a new channel to view your revenue easily and <b>explore growth opportunities with data insights.</b>
            </CardDescription>
            <CenterDiv>
              <Button secondary onClick={handleNoDataButtonClick}>
                Connect now
              </Button>
            </CenterDiv>
          </Card>
        </GridColumn>
      </GridColumn>
      <GridColumn lg={4} md={6}>
        <Card>
          <CardTitle>Integration status</CardTitle>
          <HR color="grayOne" />
          <ChannelCategory>Sales Channel</ChannelCategory>
          {integratedSalesPlatforms.map((account) => {
            return (
              <>
                <PlatformInfoDiv>
                  <PlatformTitle>{PLATFORM_MAP[account.platformName].displayName}</PlatformTitle>
                  {account.status === 1 && (
                    <div>
                      <ConnectedDot />
                      <ConnectedPlatformStatus>Connected</ConnectedPlatformStatus>
                    </div>
                  )}
                  {account.status === 0 && (
                    <div>
                      <PendingPlatformStatus>Pending</PendingPlatformStatus>
                    </div>
                  )}
                </PlatformInfoDiv>
                <IntegratedAccountName>({account.accountId})</IntegratedAccountName>
              </>
            );
          })}
          {notYetSalesPlatforms.length > 0 &&
            notYetSalesPlatforms.map((account) => {
              return (
                <PlatformInfoDiv>
                  <PlatformTitle>{PLATFORM_MAP[account].displayName}</PlatformTitle>
                  <IntegrateLink onClick={handleNoDataButtonClick}>Connect Now</IntegrateLink>
                </PlatformInfoDiv>
              );
            })}
          <HR color="grayOne" />
          <ChannelCategory>Payment Channel</ChannelCategory>
          {integratedPaymentPlatforms.map((account) => {
            return (
              <>
                <PlatformInfoDiv>
                  <PlatformTitle>
                    {account?.platformName === PLATFORM.SHOPIFY
                      ? PLATFORM_DISPLAY_NAME.SHOPIFY_PAYMENT
                      : PLATFORM_MAP[account.platformName]?.displayName}
                  </PlatformTitle>
                  {account.status === 1 && (
                    <div>
                      <ConnectedDot />
                      <ConnectedPlatformStatus>Connected</ConnectedPlatformStatus>
                    </div>
                  )}
                  {account.status === 0 && (
                    <div>
                      <PendingPlatformStatus>Pending</PendingPlatformStatus>
                    </div>
                  )}
                </PlatformInfoDiv>
                <IntegratedAccountName>({account.accountId})</IntegratedAccountName>
              </>
            );
          })}
          {notYetPaymentPlatforms.length > 0 &&
            notYetPaymentPlatforms.map((account) => {
              return (
                <PlatformInfoDiv>
                  <PlatformTitle>
                    {account === PLATFORM.SHOPIFY ? PLATFORM_DISPLAY_NAME.SHOPIFY_PAYMENT : PLATFORM_MAP[account].displayName}
                  </PlatformTitle>
                  <IntegrateLink onClick={handleNoDataButtonClick}>Connect Now</IntegrateLink>
                </PlatformInfoDiv>
              );
            })}
          <HR color="grayOne" />
          <ChannelCategory>Analytics Platform</ChannelCategory>
          {analyticPlatform.length === 0 && (
            <PlatformInfoDiv>
              <PlatformTitle>{PLATFORM_DISPLAY_NAME.GOOGLE_ANALYTICS}</PlatformTitle>
              <IntegrateLink onClick={handleNoDataButtonClick}>Connect Now</IntegrateLink>
            </PlatformInfoDiv>
          )}
          {analyticPlatform.map((account) => {
            return (
              <>
                <PlatformInfoDiv>
                  <PlatformTitle>{PLATFORM_DISPLAY_NAME.GOOGLE_ANALYTICS}</PlatformTitle>
                  {account.status === -2 ? (
                    <IntegrateLink onClick={() => showGAIntegrationModal(account.id, account.accountId)}>Retry</IntegrateLink>
                  ) : (
                    <div>
                      <ConnectedDot />
                      <ConnectedPlatformStatus>Connected</ConnectedPlatformStatus>
                    </div>
                  )}
                </PlatformInfoDiv>
                <IntegratedAccountName>({formatGAAccountLabel(account.accountId, account.accountName)})</IntegratedAccountName>
              </>
            );
          })}
        </Card>
      </GridColumn>
    </>
  );
};

export default SalesReportOverview;
