import React from 'react';

import _ from 'lodash';
import { Controller } from 'react-hook-form';

import CountrySelect from '../../../components/CountrySelect';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { FormRow, GridColumn } from '@components/Grid';
import { groupedCountryOptions } from '@constants/options';

const AddressForm = ({
  control, errors, fieldNames, required = true, disabled,
}) => {
  const getError = (path) => _.get(errors, path);

  return (
    <>
      <FormRow>
        <GridColumn lg={4} md={6}>
          <Controller
            name={fieldNames.country}
            control={control}
            rules={{ required }}
            render={({ field }) => (
              <CountrySelect {...field} hasError={getError(fieldNames.country)} disabled={disabled} full />
            )}
          />
        </GridColumn>
        <GridColumn lg={4} md={6}>
          <Controller
            name={fieldNames.zipCode}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                hasError={getError(fieldNames.zipCode)}
                placeholder="Postal Code"
                disabled={disabled}
                full
              />
            )}
          />
        </GridColumn>
      </FormRow>
      <FormRow>
        <GridColumn lg={8}>
          <Controller
            name={fieldNames.addressLine1}
            control={control}
            rules={{ required }}
            render={({ field }) => (
              <Input
                {...field}
                hasError={getError(fieldNames.addressLine1)}
                placeholder="Address Line 1"
                disabled={disabled}
                full
              />
            )}
          />
        </GridColumn>
      </FormRow>
      <FormRow>
        <GridColumn lg={8}>
          <Controller
            name={fieldNames.addressLine2}
            control={control}
            rules={{ required }}
            render={({ field }) => (
              <Input
                {...field}
                hasError={getError(fieldNames.addressLine2)}
                placeholder="Address Line 2"
                disabled={disabled}
                full
              />
            )}
          />
        </GridColumn>
      </FormRow>
      <FormRow>
        <GridColumn lg={4} md={6}>
          <Controller
            name={fieldNames.state}
            control={control}
            rules={{ required }}
            render={({ field }) => (
              <Input
                {...field}
                hasError={getError(fieldNames.state)}
                placeholder="State / Province"
                disabled={disabled}
                full
              />
            )}
          />
        </GridColumn>
        <GridColumn lg={4} md={6}>
          <Controller
            name={fieldNames.city}
            control={control}
            render={({ field }) => (
              <Input {...field} hasError={getError(fieldNames.city)} placeholder="City" disabled={disabled} full />
            )}
          />
        </GridColumn>
      </FormRow>
    </>
  );
};

export default AddressForm;
