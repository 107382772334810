import React from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Table from '../../../../../components/Table';
import PATH from '../../../path';
import { convertToFullDate } from '@utils/dateHelpers';
import { formatPrice, formatNumber } from '@utils/priceHelpers';
import { getColorByTransactionStatus } from '@utils/remittanceHelpers';
import { capitalize, replaceDelimiter } from '@utils/stringHelpers';

const Tr = styled.tr`
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    css`
      cursor: initial;
    `}
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grayOne};
  :hover {
    ${(props) =>
      !props.disabled &&
      css`
        background-color: ${props.theme.colors.grayOne};
      `}
  }
`;

const Td = styled.td`
  color: ${(props) => props.color};
`;

const STATUS = {
  PENDING: 'Pending',
  IN_TRANSIT: 'In Transit',
  SUCCESS: 'Success',
  FAILED: 'Failed',
  OVERDUE: 'Overdue',
};

const columns = [
  {
    Header: 'Date',
    accessor: 'date_created',
    Cell: ({ value }) => {
      if (value) {
        return convertToFullDate(value);
      } else {
        return '-';
      }
    },
    getStyle: () => css`
      width: 110px;
    `,
  },
  {
    Header: 'Funding ID',
    accessor: 'funding_number',
    Cell: ({ value }) => {
      return typeof value === 'string' ? value : '-';
    },
    getStyle: () => css`
      width: 140px;
    `,
  },
  {
    Header: 'Description',
    accessor: 'description',
    getStyle: ({ value, row }) => css`
      &.td {
        ${(props) =>
          row?.invoice_id &&
          css`
            color: ${props.theme.colors.orange};
          `}
      }
    `,
  },
  {
    Header: 'Currency',
    accessor: 'currency',
    Cell: ({ value }) => {
      return value || '-';
    },
    getStyle: ({ value }) => css`
      width: 80px;
    `,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value }) => {
      if (value) {
        return formatNumber(value, 2);
      }
      return '-';
    },
    getStyle: ({ value }) => css`
      text-align: right;
    `,
  },
  {
    Header: 'Type',
    accessor: 'source_type',
    Cell: ({ value }) => {
      return capitalize(replaceDelimiter(value, '_', ' ') || '-');
    },
    getStyle: ({ value }) => css`
      width: 150px;
    `,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => {
      return capitalize(STATUS[value] || value || '-');
    },
    getStyle: ({ value }) => css`
      color: ${getColorByTransactionStatus(value)};
    `,
  },
];

const BalanceTransactionTable = ({ data = [] }) => {
  const history = useHistory();

  const viewInvoiceDetail = (balanceTransaction) => {
    if (balanceTransaction?.invoice_id) {
      history.push(generatePath(PATH.CAPITAL_INVOICE_DETAIL, { id: balanceTransaction.invoice_id }));
    }
  };

  return <Table data={data} columns={columns} onRowClick={viewInvoiceDetail} />;
};

export default BalanceTransactionTable;
