import React from 'react';

import { useHistory, generatePath } from 'react-router-dom';
import styled, { css } from 'styled-components';

import BackButton from '../../../../../components/BackButton';
import FormTitle from '../../../../../components/FormTitle';
import PATH from '../../../path';
import { CARD_TYPE_OPTIONS } from './constants';

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 18px 0;
`;

const Link = styled.a`
  ${(props) => props.theme.text.link};
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 585px;
`;

const TypeWrapper = styled.div`
  cursor: pointer;
  margin-bottom: 24px;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.white};

  box-sizing: border-box;
  border: 2px solid ${(props) => props.theme.colors.white};
  border-radius: 12px;
  display: inline-block;

  :hover {
    border: 2px solid ${(props) => props.theme.colors.purple};
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.8;
      cursor: not-allowed;
    `}
  display: flex;
  justify-content: space-between;

  img {
    width: 50%;
  }

  > div {
    padding: 12px;
  }
`;

const Title = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: 700;
`;

const Description = styled.div`
  ${(props) => props.theme.text.micro};
  font-weight: 600;
  margin-top: 16px;
`;

const ComingSoonTag = styled.div`
  margin-top: 16px;
  font-weight: 600;
  display: inline-block;
  border-radius: 5px;
  ${(props) => props.theme.text.five};
  background-color: ${(props) => props.theme.colors.grayTwo};
  padding: 6px 9px;
`;

const Overview = () => {
  const history = useHistory();

  const handleClick = (type, disabled) => () => {
    if (!disabled) {
      history.push(generatePath(PATH.WALLET_CARDS_CREATE_FORM, { type }));
    }
  };

  return (
    <Wrapper>
      <BackButton path={PATH.WALLET_CARDS} />
      <FormTitle scale={3}>Create a new card</FormTitle>
      <HeaderRow>
        <FormTitle scale={5}>Select a card type</FormTitle>
        <Link
          href="https://help.airwallex.com/hc/en-gb/sections/900000313403-Airwallex-Borderless-Cards"
          target="_blank"
        >
          Learn more
        </Link>
      </HeaderRow>
      {CARD_TYPE_OPTIONS.map(({ title, description, imgUrl, type, disabled }) => {
        return (
          <TypeWrapper onClick={handleClick(type, disabled)} disabled={disabled}>
            <img src={imgUrl} alt={type} />
            <div>
              <Title>{title}</Title>
              <Description>{description}</Description>
              {disabled && <ComingSoonTag>{`${title} is coming soon!`}</ComingSoonTag>}
            </div>
          </TypeWrapper>
        );
      })}
    </Wrapper>
  );
};

export default Overview;
