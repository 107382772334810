// ratings is the mapping for different Choco grades
export const ratings = [
  [
    {
      label: 'Choco Rating',
      color: '#000000',
      bgColor: '#00000',
      grow: 1,
      padding: '0.15rem',
      weight: 800,
      size: '1.5rem',
    },
    {
      label: 'Credit Quality',
      color: '#000000',
      bgColor: '#FFFFFF',
      grow: 1,
      padding: '0.15rem',
      weight: 800,
      size: '1.5rem',
    },
  ],
  [
    {
      label: 'A3',
      color: '#FFFFFF',
      bgColor: '#99C763',
      grow: 1,
      padding: '0.1rem',
      weight: 600,
      size: '1.1rem',
    },
    {
      label: 'Highest',
      color: '#FFFFFF',
      bgColor: '#99C763',
      grow: 1,
      padding: '0.1rem',
      weight: 600,
      size: '1.1rem',
    },
  ],
  [
    {
      label: 'A2',
      color: '#FFFFFF',
      bgColor: '#99C763',
      grow: 1,
      padding: '0.1rem',
      weight: 600,
      size: '1.1rem',
    },
    {
      label: 'Very High',
      color: '#FFFFFF',
      bgColor: '#99C763',
      grow: 1,
      padding: '0.1rem',
      weight: 600,
      size: '1.1rem',
    },
  ],
  [
    {
      label: 'A1',
      color: '#FFFFFF',
      bgColor: '#99C763',
      grow: 1,
      padding: '0.1rem',
      weight: 600,
      size: '1.1rem',
    },
    {
      label: 'High',
      color: '#FFFFFF',
      bgColor: '#99C763',
      grow: 1,
      padding: '0.1rem',
      weight: 600,
      size: '1.1rem',
    },
  ],
  [
    {
      label: 'B3',
      color: '#ffffff',
      bgColor: '#e5b368',
      grow: 1,
      padding: '0.2rem',
      weight: 600,
      size: '1.1rem',
    },
    {
      label: 'Very Good',
      color: '#ffffff',
      bgColor: '#e5b368',
      grow: 1,
      padding: '0.2rem',
      weight: 600,
      size: '1.1rem',
    },
  ],
  [
    {
      label: 'B2',
      color: '#ffffff',
      bgColor: '#e5b368',
      grow: 1,
      padding: '0.2rem',
      weight: 600,
      size: '1.1rem',
    },
    {
      label: 'Good',
      color: '#ffffff',
      bgColor: '#e5b368',
      grow: 1,
      padding: '0.2rem',
      weight: 600,
      size: '1.1rem',
    },
  ],
  [
    {
      label: 'B1',
      color: '#ffffff',
      bgColor: '#e5b368',
      grow: 1,
      padding: '0.2rem',
      weight: 600,
      size: '1.1rem',
    },
    {
      label: 'Moderate',
      color: '#ffffff',
      bgColor: '#e5b368',
      grow: 1,
      padding: '0.2rem',
      weight: 600,
      size: '1.1rem',
    },
  ],
  [
    {
      label: 'C3',
      color: '#ffffff',
      bgColor: '#D97462',
      grow: 1,
      padding: '0.25rem',
      weight: 600,
      size: '1.1rem',
    },
    {
      label: 'Fair',
      color: '#ffffff',
      bgColor: '#D97462',
      grow: 1,
      padding: '0.25rem',
      weight: 600,
      size: '1.1rem',
    },
  ],
  [
    {
      label: 'C2',
      color: '#ffffff',
      bgColor: '#D97462',
      grow: 1,
      padding: '0.25rem',
      weight: 600,
      size: '1.1rem',
    },
    {
      label: 'Low',
      color: '#ffffff',
      bgColor: '#D97462',
      grow: 1,
      padding: '0.25rem',
      weight: 600,
      size: '1.1rem',
    },
  ],
  [
    {
      label: 'C1',
      color: '#ffffff',
      bgColor: '#D97462',
      grow: 1,
      padding: '0.25rem',
      weight: 600,
      size: '1.1rem',
    },
    {
      label: 'Very Low',
      color: '#ffffff',
      bgColor: '#D97462',
      grow: 1,
      padding: '0.25rem',
      weight: 600,
      size: '1.1rem',
    },
  ],
  [
    {
      label: 'Unrated',
      color: '#ffffff',
      bgColor: '#000000',
      grow: 1,
      padding: '0.25rem',
      weight: 600,
      size: '1.1rem',
    },
    {
      label: 'Lowest',
      color: '#ffffff',
      bgColor: '#000000',
      grow: 1,
      padding: '0.25rem',
      weight: 600,
      size: '1.1rem',
    },
  ],
];
