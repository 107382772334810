import React, { useEffect, useState, useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import AlertCard from '../../../../../components/AlertCard';
import Button from '../../../../../components/Button';
import CircularProgressBar from '../../../../../components/CircularProgressBar';
import CountrySelect from '../../../../../components/CountrySelect';
import FormTitle from '../../../../../components/FormTitle';
import HR from '../../../../../components/HR';
import Input from '../../../../../components/Input';
import Select from '../../../../../components/Select';
import SingleDatePicker from '../../../../../components/SingleDatePicker';
import COLORS from '../../../../../styles/colors';
import RHFMoneyInput from '../../../../ReactHookForm/RHFMoneyInput';
import { FieldWrapper } from '../../../components';
import TransferMethodSelect from '../../components/TransferMethodSelect';
import { globalAccountsOptions } from '../../constants';
import { generatePaymentMethodOptions, generateSourceCurrencyOptions } from '../../helpers';
import { Footer, ButtonWrapper, FormErrorMessage } from './components';
import { formatPrice, roundUpNumber, roundDownNumber, getCurrencyDecimalPosition, getNumberFromFormatted } from '@utils/priceHelpers';
import { getOptionLabelByValue, getOptionByValue } from '@utils/optionHelpers';
import { FORM_NAME, PURPOSE_OPTIONS, TARGET, BUSINESS_TYPE_OPTIONS, SWIFT_FEE_OPTIONS, MINIMUM_PAYMENT_AMOUNT_IN_USE } from './constants';
import { getWalletBeneficiarySchema, getWalletFX } from '@api/modules/integration/airwallex';
import { isRealObject, isRealNumber, findFirstMatchInArray, convertArrayToObject } from '@utils/dataTypes';
import { SUPPORT_EMAIL } from '@constants/email';
import { fetchFormSchema, fetchWalletTransferFee, fetchForeignExchange } from '@redux/modules/wallet/actions';
import { CURRENCY_COUNTRY_FLAG_MAP, countryDetails } from '@/constants';
import { convertFXAmount, formatFXRatio } from './helpers';
import { useWalletAccountId } from '@redux/selectors';
import { convertToFullDateAndTime } from '@utils/dateHelpers';

const TransferFlowInfoPadding = css`
  padding: 5px 0 0 16px;
`;

const AvailableAmount = styled.div`
  text-align: right;
  color: ${(props) => props.theme.colors.grayThree};
  ${(props) => props.theme.text.micro};
`;

const TransferFlowInfoWrapper = styled.div`
  margin-left: 16px;
  ${TransferFlowInfoPadding};
  border-left: 1px dashed ${(props) => props.theme.colors.grayTwo};

  ${FieldWrapper} {
    position: relative;
    .circle {
      margin: 5px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.white};
      position: absolute;
      left: -31px;
      top: -5px;
      div {
        position: absolute;
        top: 25%;
        right: 25%;
        width: 9px;
        height: 9px;
        background-color: ${(props) => props.theme.colors.grayThree};
        border-radius: 50%;
      }
    }
  }
`;

const PaymentDataWrapper = styled.div`
  padding: 5px 0 0 32px;
`;

const SourceCurrencyOption = styled.div`
  display: flex;
  gap: 14px;
  .currency_code {
    font-weight: 600;
  }
`;

const TransferForm = ({
  targetType,
  beneficiaryId,
  selectedBeneficiary,
  fxCountdownTime,
  components: { Recipient },
  setAmount,
  setAmountOrigin,
  setFormName,
  isLoading,
  formProps,
}) => {
  const walletAccountId = useWalletAccountId();
  const {
    control,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
    handleSubmit,
    formState: { errors, dirtyFields, touchedFields },
  } = formProps;
  const transferSchema = useSelector((state) => state.wallet.schema[FORM_NAME.TRANSFER]);
  const currencyBalances = useSelector((state) => state.wallet.currencyBalances);
  const transferFee = useSelector((state) => state.wallet.transferFee) || {};
  const foreignExchange = useSelector((state) => state.wallet.foreignExchange) || {};
  const currencyBalanceMap = convertArrayToObject(currencyBalances, 'currency');

  const [isLoadingPaymentMethod, toggleIsLoadingPaymentMethod] = useState(false);
  const [isPaymentMethodMenuOpen, toggleIsPaymentMethodMenuOpen] = useState(false);
  const [showSourceToPaymentFX, toggleShowSourceToPaymentFX] = useState(false);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
  const [localClearingSystemSchema, setLocalClearingSystemSchema] = useState();

  const watchOfSourceCurrency = watch('sourceCurrency');
  const watchOfSourceAmount = watch('sourceAmount');
  const watchOfPaymentCurrency = watch('paymentCurrency');
  const watchOfPaymentAmount = watch('paymentAmount');
  const watchOfBankCountryCode = watch('beneficiary.bank_details.bank_country_code');
  const watchOfEntityType = watch('beneficiary.entity_type');
  const watchOfPaymentMethod = watch('paymentMethod');
  const watchOfSwiftChargeOption = watch('swift_charge_option');

  const sourceToPaymentFX = foreignExchange[`${watchOfSourceCurrency}to${watchOfPaymentCurrency}`];
  const feeFX = foreignExchange[`${watchOfSourceCurrency}toUSD`];

  const selectedCurrencyBalance = currencyBalanceMap[watchOfSourceCurrency];

  useEffect(() => {
    toggleIsLoadingPaymentMethod(true);
    fetchFormSchema(FORM_NAME.TRANSFER, {
      bank_country_code: watchOfBankCountryCode,
      account_currency: watchOfPaymentCurrency,
      entity_type: watchOfEntityType,
    });
  }, [watchOfBankCountryCode, watchOfPaymentCurrency, watchOfEntityType]);

  const targetIsExistingUser = targetType === TARGET.EXISTING_USER;

  const paymentMethodSchema = useMemo(() => {
    return findFirstMatchInArray(transferSchema?.fields, ({ field }) => field.key === 'payment_method');
  }, [JSON.stringify(transferSchema)]);

  const paymentCurrencySchema = useMemo(() => {
    return findFirstMatchInArray(transferSchema?.fields, ({ field }) => field.key === 'account_currency');
  }, [JSON.stringify(transferSchema)]);

  const bankCountryCodeSchema = useMemo(() => {
    return findFirstMatchInArray(transferSchema?.fields, ({ field }) => field.key === 'bank_country_code');
  }, [JSON.stringify(transferSchema)]);

  const handleBack = (event) => {
    setFormName(FORM_NAME.OVERVIEW);
  };

  const onSubmit = (formData) => {
    const nextForm = targetType === TARGET.EXISTING_USER ? FORM_NAME.REVIEW : FORM_NAME.RECIPIENT;
    setFormName(nextForm);
  };

  useEffect(() => {
    if (watchOfSourceCurrency && watchOfPaymentCurrency && watchOfSourceCurrency !== watchOfPaymentCurrency) {
      fetchForeignExchange(walletAccountId, {
        fromCurrency: watchOfSourceCurrency,
        toCurrency: watchOfPaymentCurrency,
        amount: 10000,
      });
      fetchForeignExchange(walletAccountId, {
        fromCurrency: watchOfPaymentCurrency,
        toCurrency: watchOfSourceCurrency,
        amount: 10000,
      });
    }
  }, [watchOfSourceCurrency, watchOfPaymentCurrency]);

  useEffect(() => {
    const fetchSourceToFeeFX = () => {
      if (watchOfSourceCurrency !== 'USD') {
        fetchForeignExchange(walletAccountId, {
          fromCurrency: watchOfSourceCurrency,
          toCurrency: 'USD',
          amount: 10000,
        });
      }
    };

    fetchSourceToFeeFX();
  }, [watchOfSourceCurrency]);

  useEffect(() => {
    if (watchOfSourceCurrency && watchOfPaymentCurrency && watchOfSourceCurrency !== watchOfPaymentCurrency) {
      fetchForeignExchange(walletAccountId, {
        fromCurrency: watchOfSourceCurrency,
        amount: 10000,
        toCurrency: watchOfPaymentCurrency,
      });
    }
  }, [watchOfSourceCurrency, watchOfPaymentCurrency]);

  useEffect(() => {
    const reopenPaymentMethodMenu = () => {
      if (targetType !== TARGET.EXISTING_USER) {
        toggleIsPaymentMethodMenuOpen(true);
      }
    };

    reopenPaymentMethodMenu();
  }, [JSON.stringify(paymentMethodOptions)]);

  useEffect(() => {
    if (isRealObject(sourceToPaymentFX) && sourceToPaymentFX.quote_id && watchOfSourceCurrency !== watchOfPaymentCurrency) {
      toggleShowSourceToPaymentFX(true);
    } else {
      toggleShowSourceToPaymentFX(false);
    }
  }, [JSON.stringify(sourceToPaymentFX), watchOfSourceCurrency, watchOfPaymentCurrency]);

  useEffect(() => {
    const prefillPaymentCurrency = () => {
      const bankCountryDetail = countryDetails[watchOfBankCountryCode];
      if (!touchedFields?.paymentCurrency && bankCountryDetail && Array.isArray(paymentCurrencySchema?.field?.options) && !beneficiaryId) {
        for (const curr of bankCountryDetail.allCurrencyCodes) {
          const option = getOptionByValue(curr, paymentCurrencySchema?.field?.options);

          if (option) {
            setValue('paymentCurrency', option.value);
            return;
          }
        }

        setValue('paymentCurrency', 'USD');
      }
    };

    prefillPaymentCurrency();
  }, [watchOfBankCountryCode, JSON.stringify(paymentCurrencySchema?.field?.options)]);

  useEffect(() => {
    const prefillAddressCountry = () => {
      setValue('beneficiary.address.country_code', watchOfBankCountryCode);
    };

    prefillAddressCountry();
  }, [watchOfBankCountryCode]);

  useEffect(() => {
    const setAmountError = () => {
      const { paymentAmount, paymentCurrency, sourceAmount } = getValues();
      const fxOfToCurrencyAndUSD = foreignExchange[`${paymentCurrency}toUSD`];
      if (paymentAmount) {
        let toAmountInUSD;
        if (paymentCurrency === 'USD') {
          toAmountInUSD = paymentAmount;
        } else if (fxOfToCurrencyAndUSD) {
          toAmountInUSD = convertFXAmount(fxOfToCurrencyAndUSD, paymentCurrency, paymentAmount);
        }
        console.log('toAmountInUSD: ', toAmountInUSD);

        if (sourceAmount > selectedCurrencyBalance?.total_amount) {
          setError('sourceAmount', {
            type: 'manual',
            message: 'Amount exceeds your available balance, please change the funding currency or top up',
          });
        } else if (toAmountInUSD < MINIMUM_PAYMENT_AMOUNT_IN_USE) {
          setError('sourceAmount', {
            type: 'manual',
            message: 'Amount is too low which could not compose a valid payment amount',
          });
          setError('paymentAmount', {
            type: 'manual',
            message: 'Should be at minimum USD 10',
          });
        } else {
          clearErrors('sourceAmount');
          clearErrors('paymentAmount');
        }
      }
    };

    setAmountError();
  }, [watchOfPaymentAmount, watchOfSourceAmount, watchOfSourceCurrency, JSON.stringify(foreignExchange)]);

  const getPaymentError = () => {
    if (
      watchOfBankCountryCode &&
      watchOfPaymentCurrency &&
      watchOfEntityType &&
      Array.isArray(paymentMethodSchema?.field?.options) &&
      paymentMethodSchema?.field?.options.length === 0 &&
      !isLoading
    ) {
      return {
        // title: `We currently do not support sending ${getOptionLabelByValue(
        //   watchOfPaymentCurrency,
        //   paymentCurrencySchema?.field?.options,
        // )} to business accounts in ${getOptionLabelByValue(watchOfBankCountryCode, bankCountryCodeSchema.field.options)}
        //     `,
        title: "We currently don't support payments for the given payment currency, bank account country and entity types.",
        description: `If you have questions about the transfer options, please reach out to ${SUPPORT_EMAIL}.`,
      };
    } else if (
      sourceToPaymentFX === undefined &&
      watchOfPaymentCurrency &&
      watchOfSourceCurrency &&
      watchOfPaymentCurrency !== watchOfSourceCurrency &&
      watchOfSourceAmount &&
      !isLoading
    ) {
      return {
        title: `We currently do not support sending money from ${getOptionLabelByValue(
          watchOfSourceCurrency,
          paymentCurrencySchema?.field?.options
        )} to ${getOptionLabelByValue(watchOfPaymentCurrency, paymentCurrencySchema?.field?.options)}`,
        description: `If you have questions about the transfer options, please reach out to ${SUPPORT_EMAIL}.`,
      };
    }

    return null;
  };

  const formatTransferDateFooter = () => {
    const paymentMethod = getValues('paymentMethod');

    if (paymentMethod) {
      let methodOption;
      if (
        ['SWIFT', 'LOCAL'].indexOf(paymentMethod) !== -1 &&
        Array.isArray(paymentMethodSchema?.field?.options) &&
        paymentMethodSchema?.field?.options.length > 0
      ) {
        methodOption = getOptionByValue(paymentMethod, paymentMethodSchema?.field?.options);
      } else if (Array.isArray(localClearingSystemSchema?.field?.options) && localClearingSystemSchema?.field?.options.length > 0) {
        methodOption = getOptionByValue(paymentMethod, localClearingSystemSchema?.field?.options);
      }
      if (isRealObject(methodOption)) {
        return `${methodOption.label} transfers usually arrive within ${methodOption.processing_time} of this date`;
      }
    }

    return null;
  };

  useEffect(() => {
    toggleIsLoadingPaymentMethod(true);
    const fetchLocalPaymentMethodSchemas = async () => {
      if (Array.isArray(paymentMethodSchema?.field?.options)) {
        const localMethod = findFirstMatchInArray(paymentMethodSchema?.field?.options, ({ value }) => value === 'LOCAL');

        console.log('localMethod: ', localMethod);
        if (localMethod) {
          const { data } = await getWalletBeneficiarySchema({
            bank_country_code: watchOfBankCountryCode,
            account_currency: watchOfPaymentCurrency,
            entity_type: watchOfEntityType,
            payment_method: 'LOCAL',
          });

          const schema = findFirstMatchInArray(data.fields, ({ path }) => path === 'beneficiary.bank_details.local_clearing_system');
          console.log('schema: ', schema);
          if (schema) {
            setLocalClearingSystemSchema(schema);
          } else {
            setLocalClearingSystemSchema();
          }
          toggleIsLoadingPaymentMethod(false);
        } else {
          setLocalClearingSystemSchema();
          toggleIsLoadingPaymentMethod(false);
        }
      }
    };

    fetchLocalPaymentMethodSchemas();
  }, [JSON.stringify(transferSchema)]);

  useEffect(() => {
    const updatePaymentMethodOptions = () => {
      console.log('update options');
      if (Array.isArray(paymentMethodSchema?.field?.options)) {
        if (!isLoadingPaymentMethod) {
          const options = generatePaymentMethodOptions(paymentMethodSchema?.field?.options, localClearingSystemSchema?.field?.options);
          console.log('next options: ');
          console.log(options);
          setPaymentMethodOptions(options);
        }
      } else {
        setPaymentMethodOptions([]);
      }
    };

    updatePaymentMethodOptions();
  }, [JSON.stringify(transferSchema), JSON.stringify(localClearingSystemSchema), isLoadingPaymentMethod]);

  useEffect(() => {
    const updatePaymentMethodValue = () => {
      if (isRealObject(selectedBeneficiary)) {
        let paymentMethodOptionValue;
        if (selectedBeneficiary.payment_methods.indexOf('SWIFT') !== -1) {
          paymentMethodOptionValue = 'SWIFT';
        } else {
          paymentMethodOptionValue = selectedBeneficiary.beneficiary.bank_details.local_clearing_system || 'LOCAL';
        }

        setValue('paymentMethod', paymentMethodOptionValue);
      }
    };

    updatePaymentMethodValue();
  }, [beneficiaryId]);

  useEffect(() => {
    if (watchOfBankCountryCode && watchOfPaymentCurrency) {
      fetchWalletTransferFee(watchOfBankCountryCode, watchOfPaymentCurrency);
    }
  }, [watchOfBankCountryCode, watchOfPaymentCurrency, watchOfPaymentMethod]);

  const getSourceCurrencyOptionLabel = ({ value, label }, config) => {
    const balance = currencyBalanceMap[value];
    const logoUrl = CURRENCY_COUNTRY_FLAG_MAP[value]?.logoUrl;

    return config.context === 'menu' ? (
      <SourceCurrencyOption>
        {logoUrl && <img src={logoUrl} alt={value} />}
        <div>
          <div className="currency_code">{value}</div>
          <div>{formatPrice(value, balance?.total_amount || 0)}</div>
        </div>
      </SourceCurrencyOption>
    ) : (
      label
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {targetType === TARGET.EXISTING_USER && Recipient}
      <FormTitle scale={5}>You send</FormTitle>
      <RHFMoneyInput
        control={control}
        errors={errors}
        prefix="source"
        selectProps={{
          placeholder: 'Select Currency',
          options: generateSourceCurrencyOptions(currencyBalances, globalAccountsOptions),
          formatOptionLabel: getSourceCurrencyOptionLabel,
        }}
        inputProps={{
          alwaysPositiveNumber: true,
          rules: {
            required: true,
            min: 0,
            max: 1000,
          },
          onChange: (event) => {
            setAmount(getNumberFromFormatted(event.target.value));
            setAmountOrigin('sourceAmount');
          },
        }}
        full
      />
      <TransferFlowInfoWrapper>
        {selectedCurrencyBalance && (
          <AvailableAmount>
            {'Available balance: '}
            <b>{formatPrice(watchOfSourceCurrency, selectedCurrencyBalance?.total_amount)}</b>
          </AvailableAmount>
        )}
        {showSourceToPaymentFX && isRealObject(sourceToPaymentFX) && (
          <FieldWrapper>
            <div className="circle">
              <div />
            </div>
            <FormTitle scale={5}>
              {formatFXRatio({
                foreignExchange: sourceToPaymentFX,
                sourceCurrency: watchOfSourceCurrency,
                paymentCurrency: watchOfPaymentCurrency,
              })}
            </FormTitle>
            <Footer>
              <div>{convertToFullDateAndTime(sourceToPaymentFX.valid_from)}</div>
              <CircularProgressBar
                total={moment(sourceToPaymentFX.valid_to).diff(sourceToPaymentFX.valid_from, 'second')}
                lines={[{ value: fxCountdownTime, color: COLORS.purple, id: 'countdown' }]}
                strokeWidth={2}
                radius={4}
                strokeLinecap="square"
              />
            </Footer>
          </FieldWrapper>
        )}
        <div>
          <FieldWrapper>
            <div className="circle">
              <div />
            </div>
            <FormTitle scale={5} required>
              Transfer to
            </FormTitle>
            <Controller
              name="beneficiary.bank_details.bank_country_code"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <CountrySelect
                    {...field}
                    hasError={_.get(errors, 'beneficiary.bank_details.bank_country_code')}
                    {...(Array.isArray(bankCountryCodeSchema?.field?.options) && {
                      options: bankCountryCodeSchema?.field?.options,
                    })}
                    disabled={targetIsExistingUser}
                  />
                );
              }}
            />
          </FieldWrapper>
          <FieldWrapper>
            <div className="circle">
              <div />
            </div>
            <FormTitle scale={5} required>
              Business type
            </FormTitle>
            <Controller
              name="beneficiary.entity_type"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    hasError={_.get(errors, 'beneficiary.entity_type')}
                    options={BUSINESS_TYPE_OPTIONS}
                    disabled={targetIsExistingUser}
                  />
                );
              }}
            />
          </FieldWrapper>
        </div>
        {Array.isArray(paymentMethodOptions) && paymentMethodOptions.length > 0 && (
          <>
            <FieldWrapper>
              <div className="circle">
                <div />
              </div>
              <FormTitle scale={5} required>
                Transfer method
              </FormTitle>
              <Controller
                control={control}
                name="paymentMethod"
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <TransferMethodSelect
                      {...field}
                      options={paymentMethodOptions}
                      disabled={isLoading || targetType === TARGET.EXISTING_USER}
                      isLoading={isLoading}
                      isMenuOpen={isPaymentMethodMenuOpen}
                      toggleIsMenuOpen={toggleIsPaymentMethodMenuOpen}
                      sourceCurrency={watchOfSourceCurrency}
                      paymentCurrency={watchOfPaymentCurrency}
                      bankCountryCode={watchOfBankCountryCode}
                      swiftChargeOption={watchOfSwiftChargeOption}
                    />
                  );
                }}
              />
            </FieldWrapper>
            {watchOfPaymentMethod === 'SWIFT' && (
              <FieldWrapper>
                <Controller
                  control={control}
                  name="swift_charge_option"
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <>
                        <Select {...field} options={SWIFT_FEE_OPTIONS} half />

                        <Footer>
                          {field.value === 'SHA'
                            ? 'You pay the outgoing transfer fees, and the recipient pays the intermediary and receiving bank fees'
                            : 'You pay all the fees, including those associated with the intermediary banks'}
                        </Footer>
                      </>
                    );
                  }}
                />
              </FieldWrapper>
            )}
          </>
        )}
        <FieldWrapper style={{ marginBottom: 0 /** offset with its input */ }}>
          <div className="circle">
            <div />
          </div>
          <FormTitle scale={5}>Recipient gets</FormTitle>
        </FieldWrapper>
      </TransferFlowInfoWrapper>
      <PaymentDataWrapper>
        <RHFMoneyInput
          control={control}
          errors={errors}
          prefix="payment"
          full
          selectProps={{
            placeholder: 'Select Currency',
            ...(Array.isArray(paymentCurrencySchema?.field?.options) && {
              options: paymentCurrencySchema.field.options,
            }),
            disabled: targetIsExistingUser,
            width: 300,
            // showFlag: true,
          }}
          inputProps={{
            placeholder: '',
            min: 0,
            onChange: (event) => {
              setAmount(getNumberFromFormatted(event.target.value));
              setAmountOrigin('paymentAmount');
            },
          }}
          footer={
            <div>
              {'Available balance: '}
              <b>{formatPrice('USD', 1000)}</b>
            </div>
          }
        />
        <Footer>less any intermediary and receiving bank fees</Footer>
      </PaymentDataWrapper>
      {!isLoading &&
        (() => {
          const errorMessages = getPaymentError();
          return errorMessages && <AlertCard backgroundColor={COLORS.lightOrange} {...errorMessages} />;
        })()}
      <HR height={1} />
      <FormTitle scale={4}>Transfer details</FormTitle>
      <FieldWrapper>
        <FormTitle scale={5} required>
          Transfer date
        </FormTitle>
        <Controller
          name="transferDate"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return <SingleDatePicker {...field} hasError={errors.transferDate} full={false} />;
          }}
        />
        <Footer>{watchOfPaymentMethod && formatTransferDateFooter()}</Footer>
      </FieldWrapper>
      <FieldWrapper>
        <FormTitle scale={5} required>
          Purpose
        </FormTitle>
        <Controller
          name="reason"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return <Select {...field} hasError={errors.reason} options={PURPOSE_OPTIONS} placeholder="Select a purpose" full />;
          }}
        />
        <Footer>Please specify purpose to ensure timely dispatch of transfer</Footer>
      </FieldWrapper>
      <FieldWrapper>
        <FormTitle scale={5} required>
          Transfer reference
        </FormTitle>
        <Controller
          name="reference"
          control={control}
          rules={{ required: true, maxLength: 140 }}
          render={({ field }) => {
            return <Input {...field} hasError={errors.reference} placeholder="e.g. Payment for invoice #842392" full />;
          }}
        />
        <Footer>Displayed on recipient&apos;s bank statement</Footer>
      </FieldWrapper>
      <FieldWrapper>
        {/*
          A unique request ID specified by the client.
          Payment requests with the same request_id will be ignored.
          This allows requests to be replayed if client is unsure of the outcome,
          e.g. due to network issues, system failures, etc.
          Note: Can be non-UUID as long as it is unique between requests
         */}
        <FormTitle scale={5}>Internal reference</FormTitle>
        <Controller
          name="request_id"
          control={control}
          rules={{
            minLength: 10,
            maxLength: 50,
          }}
          render={({ field }) => {
            return <Input {...field} hasError={errors.request_id} placeholder="e.g. Job no. 232121" full />;
          }}
        />
        <Footer>We won’t send this to the recipient. It needs to be distinct over the last 7 days.</Footer>
        {errors.request_id && <FormErrorMessage>Should contain 10 to 50 characters</FormErrorMessage>}
      </FieldWrapper>
      <ButtonWrapper>
        <Button width={160} type="button" secondary onClick={handleBack}>
          Back
        </Button>
        <Button width={160} type="submit" disabled={isLoading || !watchOfPaymentMethod}>
          Next
        </Button>
      </ButtonWrapper>
    </form>
  );
};

export default TransferForm;
