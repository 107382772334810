import moment from 'moment';
import isAfterDay from 'react-dates/lib/utils/isAfterDay';

import { PERIOD } from '@/constants';
import {
  LAST_WEEK_OPTION,
  LAST_30_DAYS_OPTION,
  LAST_90_DAYS_OPTION,
  LAST_12_MONTHS_OPTION,
  THIS_MONTH_OPTION,
  LAST_MONTH_OPTION,
  THIS_YEAR_OPTION,
  LAST_YEAR_OPTION,
} from '@constants/options';

export const DD_MMM_YYYY_FORMAT = 'DD MMM YYYY';
export const MMM_YYYY_FORMAT = 'MMM YYYY'; // Jul 2021
export const SLASH_DD_MM_YYYY_FORMAT = 'DD/MM/YYYY';
export const YYYY_MM_DD_FORMAT = 'YYYY/MM/DD';
export const DATE_AND_TIME_FORMAT = 'DD MMMM YYYY, HH:mm';
export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const DD_MMM_YYYY_TIME_FORMAT = 'DD MMM YYYY, HH:mm';

export const convertDate = (date, format) => {
  if (!date /** force cache */) {
    return '';
  }

  const dateMoment = moment(date);
  if (dateMoment && dateMoment.isValid()) {
    return dateMoment.format(format);
  }

  return '';
};

export const convertToMonthYear = (date) => {
  return convertDate(date, MMM_YYYY_FORMAT);
};

export const convertToFullDate = (date) => {
  return convertDate(date, DD_MMM_YYYY_FORMAT);
};

export const convertToFullDateAndTime = (date) => {
  return convertDate(date, DATE_AND_TIME_FORMAT);
};

export const convertToApiDate = (date) => {
  return convertDate(date, API_DATE_FORMAT);
};
// month becomes short form e.g March to Mar
export const convertToFullDatePlusTime = (date) => {
  return convertDate(date, DD_MMM_YYYY_TIME_FORMAT);
};

export const datePickerAllowDaysBeforeToday = (day) => isAfterDay(day, moment());

export const getDateMomentsByShortcut = (shortcut, includeToday = false) => {
  const offsetDay = includeToday ? 0 : 1;
  let startDateMoment;
  const todayMoment = moment();
  let endDateMoment = moment().subtract(offsetDay, 'day');
  switch (shortcut) {
    case LAST_WEEK_OPTION.value:
      startDateMoment = todayMoment.subtract(6 + offsetDay, 'day');
      break;
    case LAST_30_DAYS_OPTION.value:
      startDateMoment = todayMoment.subtract(29 + offsetDay, 'day');
      break;
    case LAST_90_DAYS_OPTION.value:
      startDateMoment = todayMoment.subtract(89 + offsetDay, 'day');
      break;
    case LAST_12_MONTHS_OPTION.value:
      startDateMoment = todayMoment.subtract(12, 'months').startOf('month');
      endDateMoment = endDateMoment.subtract(1, 'months').endOf('month');
      break;
    case THIS_MONTH_OPTION.value:
      startDateMoment = todayMoment.startOf('month');
      break;
    case LAST_MONTH_OPTION.value:
      startDateMoment = todayMoment.subtract(1, 'months').startOf('month');
      endDateMoment = endDateMoment.subtract(1, 'months').endOf('month');
      break;
    case THIS_YEAR_OPTION.value:
      startDateMoment = todayMoment.startOf('year');
      break;
    case LAST_YEAR_OPTION.value:
      startDateMoment = todayMoment.subtract(1, 'year').startOf('year');
      endDateMoment = endDateMoment.subtract(1, 'year').endOf('year');
      break;
    default:
      break;
  }

  return {
    startDateMoment,
    endDateMoment,
  };
};

export const getDayMomentAfterPeriod = (startDateMoment, period) => {
  const result = moment(startDateMoment);
  switch (period) {
    case PERIOD.DAILY:
      return result.add(1, 'day');
    case PERIOD.WEEKLY:
      return result.add(7, 'day');
    case PERIOD.MONTHLY:
      return result.add(1, 'month');
    default:
      return result;
  }
};

export const adjustStripeTimeStamp = (value) => value * 1000;

export const checkSameMonth = (date, comparingDate) => {
  return moment(date).isSame(comparingDate, 'months');
};

export const checkSameDay = (date, comparingDate) => {
  return moment(date).isSame(comparingDate, 'day');
};
