import styled, { css, keyframes } from 'styled-components';

const loading = keyframes`
  100% {
      transform: translateX(100%);
  }
`;

const LoadingSkeleton = styled.div`
  height: ${(props) => props.height || '16px'};
  width: ${(props) => props.width || '100%'};
  position: relative;
  background-color: ${(props) => props.theme.colors[props.color] || props.theme.colors.grayOne};
  border-radius: 3px;

  ~ div {
    margin-top: 16px;
  }
  :after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: ${(props) => props.width || '100%'};
    height: 100%;
    transform: translateX(-100px);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
    animation: ${loading} 0.8s infinite;
  }
`;

export default LoadingSkeleton;
