import React from 'react';

import ReactSwitch from 'react-switch';

import COLORS from '../styles/colors';

const Toggle = ({ checked, onChange = () => {}, disabled }) => {
  const handleClick = (value) => {
    onChange(value);
  };

  return <ReactSwitch checked={checked} onChange={handleClick} disabled={disabled} onColor={COLORS.purple} />;
};

export default Toggle;
