import Cookies from 'universal-cookie';

import { TARGET_COMPANY_ID_NAME, USER_COMPANY_ID_NAME } from './constants';

let cookieProviderInternal = null;

export const setCookieProvider = (cookieProvider) => {
  cookieProviderInternal = cookieProvider;
};

export const getCookieProvider = () => cookieProviderInternal || new Cookies();

export const getCompanyId = () => {
  const cookieProvider = getCookieProvider();
  const targetCompanyId = cookieProvider.get(TARGET_COMPANY_ID_NAME);

  if (targetCompanyId) {
    return targetCompanyId;
  }
  const userCompanyId = cookieProvider.get(USER_COMPANY_ID_NAME);
  return userCompanyId;
};
