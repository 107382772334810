import { useEffect, useState } from 'react';

import moment from 'moment';

import { isRealNumber } from '@utils/dataTypes';
import { roundUpNumber } from '@utils/priceHelpers';

// ref: https://usehooks.com/useOnClickOutside/
export const useOnClickOutside = (ref, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
};

export const useModal = (initialIsShowing) => {
  const [isShowing, setIsShowing] = useState(initialIsShowing);

  const toggle = (willBeShown) => {
    setIsShowing(typeof willBeShown === 'boolean' ? willBeShown : !isShowing);
  };

  return [isShowing, toggle];
};

export const useSelectRow = (rowIds = []) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [areAllChecked, toggleAreAllChecked] = useState(false);

  useEffect(() => {
    if (Array.isArray(selectedIds)) {
      if (selectedIds.length > 0) {
        const checkAll = rowIds.every((id) => selectedIds.indexOf(id) !== -1);
        if (checkAll) {
          toggleAreAllChecked(true);
        } else {
          toggleAreAllChecked(false);
        }
      } else if (selectedIds.length === 0) {
        toggleAreAllChecked(false);
      }
    }
  }, [JSON.stringify(selectedIds), JSON.stringify(rowIds)]);

  const toggleSelectAll = (event) => {
    const checked = event.target.checked;
    if (checked) {
      const nextRowIds = rowIds.filter((id) => selectedIds.indexOf(id) === -1);
      setSelectedIds([...selectedIds, ...nextRowIds]);
    } else {
      const remainingIds = selectedIds.filter((id) => rowIds.indexOf(id) === -1);
      setSelectedIds(remainingIds);
    }
  };

  const toggleSelectRow = (rowId) => (event) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedIds([...selectedIds, rowId]);
    } else {
      const nextIds = selectedIds.filter((id) => id !== rowId);
      setSelectedIds(nextIds);
    }
  };

  const clearSelection = () => {
    setSelectedIds([]);
  };

  return {
    selectedIds,
    setSelectedIds,
    areAllChecked,
    toggleSelectAll,
    toggleSelectRow,
    clearSelection,
  };
};

export const usePagination = (pagination = {}) => {
  const [pageNumber, setPageNumber] = useState(pagination.pageNumber || 1);
  const [pageSize, setPageSize] = useState(pagination.pageSize || 50);
  const [pageCount, setPageCount] = useState(pagination.pageCount || 1);

  const onPageNumberChange = (index) => {
    setPageNumber(index);
  };

  const onPageSizeChange = (size) => {
    setPageSize(size);
  };

  const changePageCount = (value) => {
    setPageCount(value);
  };

  const changePageCountByTotalAndSize = (total, size) => {
    setPageCount(Math.max(roundUpNumber(total / size), 1));
  };

  return {
    pageNumber,
    pageSize,
    pageCount,
    onPageNumberChange,
    onPageSizeChange,
    changePageCount,
    changePageCountByTotalAndSize,
  };
};

export const useCountdown = ({ startTime, endTime }) => {
  const [remainingTime, setRemainingTime] = useState(); // time in seconds

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isRealNumber(remainingTime)) {
        const diff = moment(endTime).diff(moment(), 'second');
        if (diff >= 0) {
          setRemainingTime(diff);
        } else {
          setRemainingTime(0);
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (moment(startTime).isValid() && moment(endTime).isValid()) {
      const diff = moment(endTime).diff(startTime, 'second');
      setRemainingTime(diff);
    }
  }, [startTime, endTime]);

  return { remainingTime };
};

// counts down by 1 second based on timeLimitInSeconds you pass in
export const useTimer = ({ timeLimitInSeconds }) => {
  const [remainingTime, setRemainingTime] = useState(timeLimitInSeconds);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (remainingTime > 0) {
        setRemainingTime(remainingTime - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [remainingTime]);

  // restartTimer resets the timer back to the value you pass in
  const restartTimer = (value) => {
    setRemainingTime(value || timeLimitInSeconds);
  };

  return {
    remainingTime,
    restartTimer,
  };
};
