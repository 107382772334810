import { findFirstMatchInArray } from '@utils/dataTypes';
import { convertToFullDate, convertToFullDateAndTime, adjustStripeTimeStamp } from '@utils/dateHelpers';
import { formatNumber, adjustStripeAmount } from '@utils/priceHelpers';
import { capitalize, replaceDelimiter, toUpperCase } from '@utils/stringHelpers';

export const tableColumns = [
  {
    Header: 'Date',
    accessor: 'created',
    Cell: ({ value }) => {
      return convertToFullDate(adjustStripeTimeStamp(value));
    },
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ value }) => {
      return capitalize(replaceDelimiter(value, '_', ' '));
    },
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Currency',
    accessor: 'currency',
    Cell: ({ value }) => toUpperCase(value),
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    className: 'textRight',
    Cell: ({ value }) => {
      return formatNumber(adjustStripeAmount(value), 2);
    },
  },
  {
    Header: 'Processing Fee',
    accessor: 'process_fee',
    className: 'textRight',
    Cell: ({ row }) => {
      if (Array.isArray(row.original.fee_details)) {
        const processFeeObject = findFirstMatchInArray(row.original.fee_details, ({ type }) => type === 'stripe_fee');
        if (processFeeObject) {
          return `-${formatNumber(adjustStripeAmount(processFeeObject.amount), 2)}`;
        }
      }
      return '-';
    },
  },
  {
    Header: 'Other Fee',
    accessor: 'other_fee',
    className: 'textRight',
    Cell: ({ row }) => {
      if (Array.isArray(row.original.fee_details)) {
        const processFeeObject = findFirstMatchInArray(
          row.original.fee_details,
          ({ type }) => type === 'application_fee',
        );
        if (processFeeObject) {
          return `-${formatNumber(adjustStripeAmount(processFeeObject.amount), 2)}`;
        }
      }
      return '-';
    },
  },
  {
    Header: 'Net',
    accessor: 'net',
    className: 'textRight',
    Cell: ({ value }) => {
      return formatNumber(adjustStripeAmount(value), 2);
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => {
      return capitalize(value);
    },
  },
];
