import React, { useState, useEffect } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../../components/Button';
import FileDropzone from '../../../../components/FileDropzone';
import FormTitle from '../../../../components/FormTitle';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import SingleDatePicker from '../../../../components/SingleDatePicker';
import featureToggle from '../../../../featureToggle';
import Logger from '../../../../utils/logger';
import APPLICATION_PATH from '../../../Application/path';
import { Description } from "../../components";
import AddressForm from '../../components/AddressForm';
import PATH from '../../path';
import { createBusinessOwner, getBusinessOwnerById, updateBusinessOwner } from '@api/modules/dashboard/company';
import { ownerIdentityOptions } from '@constants/options';
import {
  generateCreateBusinessOwnerPayload,
  convertBusinessOwnerResponse,
  hasFileAttached,
} from '@redux/modules/company/helpers';
import { useModule } from '@hooks/routing';
import { useSelectedCompanyId, useIsUnableToEditUnderAdminView } from '@redux/selectors';
import { COMPANY_FILE_TYPE } from '@constants/file';
import { uploadCompanyFiles, getBusinessOwnerFiles } from '@api/modules/dashboard/file';
import { FormRow, GridColumn } from '@components/Grid';
import { datePickerAllowDaysBeforeToday } from '@utils/dateHelpers';
import { emailRegExp } from '@utils/validators';

const ErrorMessage = styled.div`
  ${(props) => props.theme.text.error};
  ${(props) => props.theme.text.micro};
`;

const BusinessOwnerForm = () => {
  const module = useModule();
  const { id } = useParams();
  const companyId = useSelectedCompanyId();
  const unableToEditAnotherCompany = useIsUnableToEditUnderAdminView();
  const history = useHistory();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitted },
  } = useForm({
    defaultValues: {},
  });
  const [isLoading, toggleIsLoading] = useState(false);
  const [apiError, setApiError] = useState();
  const [pendingFiles, setPendingFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const noFileAttached = !hasFileAttached(pendingFiles, uploadedFiles);

  const fetchFiles = async () => {
    const { data, error } = await getBusinessOwnerFiles(id);
    if (Array.isArray(data) && data.length > 0) {
      setUploadedFiles(data);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  useEffect(() => {
    const fetchApi = async () => {
      if (id) {
        const { data, error } = await getBusinessOwnerById(companyId, id);
        if (error) {
          //
        } else if (data) {
          const convertedData = convertBusinessOwnerResponse(data);
          reset(convertedData);
        }
      }
    };

    fetchApi();
  }, [id]);

  const onSubmit = async (formData) => {
    try {
      if (noFileAttached) {
        return;
      }

      toggleIsLoading(true);
      setApiError('');
      const payload = generateCreateBusinessOwnerPayload({
        ...formData,
        companyId,
      });

      const { data, error } = id ? await updateBusinessOwner(id, payload) : await createBusinessOwner(payload);

      if (error) {
        throw error;
      }

      if (Array.isArray(pendingFiles) && pendingFiles.length > 0) {
        const businessOwnerId = id || data.business_owner_id;
        await Promise.all(pendingFiles.map((file) => uploadCompanyFiles(COMPANY_FILE_TYPE.BUSINESS_OWNER, [file], businessOwnerId)));
      }

      history.push(module === 'dashboard' ? PATH.SETTINGS_COMPANY_OVERVIEW : APPLICATION_PATH.COMPANY);
    } catch (err) {
      setApiError(err.message);
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const handleCancel = () => {
    history.push(module === 'dashboard' ? PATH.SETTINGS_COMPANY_OVERVIEW : APPLICATION_PATH.COMPANY);
  };

  const onDrop = (acceptedFiles) => {
    setPendingFiles(acceptedFiles);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormTitle scale={5} required>
        Legal Name of Owner
      </FormTitle>
      <FormRow>
        <GridColumn lg={4} md={4} disableGutter style={{ paddingRight: '12px' }}>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input {...field} hasError={errors.firstName} placeholder="First Name" disabled={unableToEditAnotherCompany} full />
            )}
          />
        </GridColumn>
        <GridColumn lg={4} md={4} disableGutter style={{ paddingLeft: '12px' }}>
          <Controller
            name="lastName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input {...field} hasError={errors.lastName} placeholder="Last Name" disabled={unableToEditAnotherCompany} full />
            )}
          />
        </GridColumn>
      </FormRow>
      <FormTitle scale={5} required>
        Email Address
      </FormTitle>
      <FormRow>
        <GridColumn lg={8} md={8} disableGutter>
          <Controller
            name="email"
            control={control}
            rules={{ required: true, pattern: emailRegExp }}
            render={({ field }) => (
              <Input {...field} placeholder="joe@company.com" hasError={errors.email} disabled={unableToEditAnotherCompany} full />
            )}
          />
          {errors.email?.type === 'pattern' && <ErrorMessage>Email format does not match</ErrorMessage>}
        </GridColumn>
      </FormRow>
      <FormTitle scale={5} required>
        Job Title
      </FormTitle>
      <FormRow>
        <GridColumn lg={8} md={8} disableGutter>
          <Controller
            name="jobTitle"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input {...field} hasError={errors.jobTitle} placeholder="E.g. CEO, Co-Founder" disabled={unableToEditAnotherCompany} full />
            )}
          />
        </GridColumn>
      </FormRow>
      <FormRow>
        <GridColumn lg={4} md={4}>
          <FormTitle scale={5} required>
            Date of Birth
          </FormTitle>
          <Controller
            name="dateOfBirth"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <SingleDatePicker
                {...field}
                id="settings_key_person_date_of_birth"
                hasError={errors.dateOfBirth}
                isOutsideRange={datePickerAllowDaysBeforeToday}
                disabled={unableToEditAnotherCompany}
                full
              />
            )}
          />
        </GridColumn>
        <GridColumn lg={4} md={4} />
      </FormRow>
      <FormTitle scale={5} required>
        Home Address
      </FormTitle>
      <AddressForm
        control={control}
        errors={errors}
        disabled={unableToEditAnotherCompany}
        fieldNames={{
          country: 'country',
          zipCode: 'zipCode',
          addressLine1: 'addressLine1',
          addressLine2: 'addressLine2',
          state: 'state',
          city: 'city',
        }}
      />

      {featureToggle.FILE_MANAGEMENT && (
        <>
          <FormTitle scale={3} required>
            Identity Verification
          </FormTitle>
          <FormRow>
            <GridColumn lg={4} md={4}>
              <Controller
                name="idDocType"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    hasError={errors.idDocType}
                    options={ownerIdentityOptions}
                    placeholder="Select document"
                    disabled={unableToEditAnotherCompany}
                    full
                  />
                )}
              />
            </GridColumn>
            <GridColumn lg={4} md={4}>
              <Controller
                name="idDocNumber"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input {...field} hasError={errors.idDocNumber} placeholder="Number" disabled={unableToEditAnotherCompany} full />
                )}
              />
            </GridColumn>
          </FormRow>
        </>
      )}
      <FormRow>
        <GridColumn lg={8}>
          <FormTitle scale={3} required>
            Upload Identity Document
          </FormTitle>
          <Description>Please upload the copy of your identity document which is the same as above.</Description>
          <FileDropzone
            uploadedFiles={uploadedFiles}
            onDrop={onDrop}
            disabled={unableToEditAnotherCompany}
            hasError={isSubmitted && noFileAttached}
          />
        </GridColumn>
      </FormRow>
      {apiError && <ErrorMessage>{apiError}</ErrorMessage>}
      <FormRow>
        <GridColumn lg={4} md={4}>
          <Button type="submit" disabled={isLoading || unableToEditAnotherCompany} full>
            Save
          </Button>
        </GridColumn>
        <GridColumn lg={4} md={4}>
          <Button onClick={handleCancel} disabled={isLoading} tertiary full>
            Cancel
          </Button>
        </GridColumn>
      </FormRow>
    </form>
  );
};

export default BusinessOwnerForm;
