import React from 'react';

import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import BreadCrumb from '../../../../components/BreadCrumb';
import { Wrapper, Card } from '../../components';
import PATH from '../../path';
import InvoiceForm from './InvoiceForm';
import TypeSelectionForm from './TypeSelectionForm';
import { useSelectedCompany } from '@redux/selectors';

const FundingInfoTable = styled.table`
  td:first-child {
    width: 150px;
  }
`;

const ManualInvoiceContainer = () => {
  const { fundingNumber } = useSelector((state) => state.application.data);
  const { companyName } = useSelectedCompany();

  return (
    <>
      <BreadCrumb
        items={[
          {
            value: PATH.CAPITAL,
            label: 'Capital',
          },
          {
            value: PATH.CAPITAL_BALANCE_TRANSACTIONS,
            label: 'Balance Transactions',
          },
          {
            label: 'Create Invoice',
          },
        ]}
      />
      <Wrapper>
        <FundingInfoTable>
          <tbody>
            <tr>
              <td>Funding ID:</td>
              <td>{fundingNumber}</td>
            </tr>
            <tr>
              <td>Customer Name:</td>
              <td>{companyName}</td>
            </tr>
          </tbody>
        </FundingInfoTable>
        <Card>
          <Switch>
            <Route path={PATH.CAPITAL_INVOICE_FORM} component={InvoiceForm} />
            <Route exact path={PATH.CAPITAL_CREATE_MANUAL_INVOICE} component={TypeSelectionForm} />
            <Redirect to={PATH.CAPITAL_CREATE_MANUAL_INVOICE} />
          </Switch>
        </Card>
      </Wrapper>
    </>
  );
};

export default ManualInvoiceContainer;
