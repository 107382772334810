import React, { useEffect, useState } from 'react';

import countries from 'i18n-iso-countries';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import BackButton from '../../../../../components/BackButton';
import Dropdown, { DropdownOption } from '../../../../../components/Dropdown';
import FancyDateRangePicker from '../../../../../components/FancyDateRangePicker';
import HR from '../../../../../components/HR';
import Icon from '../../../../../components/Icon';
import Table from '../../../../../components/Table';
import Logger from '../../../../../utils/logger';
import { Wrapper, Card } from '../../../components';
import PATH from '../../../path';
import { WALLET_COUNTRIES, GlobalAccountTransactionsOptions, OPTION_VALUE_ALL, GlobalAccountBankAddress } from '../../constants';
import { FilterLabel, StyledSelect, Th, TR, TD } from '../components';
import { getGlobalAccountStatmentUrl } from './helpers';
import {
  getGlobalAccountById,
  getGlobalAccountTransactionsById,
  getWalletGlobalAccountStatement,
} from '@api/modules/integration/airwallex';
import { GridColumn } from '@components/Grid';
import { CREATE_AMAZON_ACCOUNTS_MODAL, GLOBAL_ACCOUNT_ACCOUNT_DETAILS } from '@modules/Modal/constants';
import { showModal } from '@redux/modules/UI/actions';
import { fetchGlobalAccountTransactions } from '@redux/modules/wallet/actions';
import { useWalletAccount } from '@redux/selectors';
import { media } from '@styles/breakpoints';
import { isRealObject } from '@utils/dataTypes';
import { convertDate, convertToApiDate, YYYY_MM_DD_FORMAT } from '@utils/dateHelpers';
import { formatNumber } from '@utils/priceHelpers';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
`;

const FlagWrapper = styled.div`
  display: flex;
  padding-left: 10px;
`;

const SectionTitle = styled.span`
  ${(props) => props.theme.text.four};
  font-weight: 600;
`;

const CurrencyWrapper = styled.div`
  padding-left: 14px;
`;

const CurrencyHeader = styled.div`
  font-weight: 600;
`;

const StyledCard = styled(Card)`
  margin: 10px;
  padding: 25px;
  width: 50%;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
`;

const CopyButton = styled.button`
  border: none;
  outline: none;
  background: transparent;

  :hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

const Title = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: 600;
  margin: 6px 0;
`;

const AccountInfoHeader = styled.div`
  ${(props) => props.theme.text.five};
  font-weight: 600;
`;

const AccountDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AccountDetailsLink = styled(Link)<{ onClick?: any }>`
  ${(props) => props.theme.text.five};
  ${(props) => props.theme.text.link};
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: flex-end;

  ${media.dablet`
    flex-direction: column;
  `};
`;

const Ul = styled.ul`
  padding: 0 16px;
`;

const Li = styled.li`
  ${(props) => props.theme.text.five};
  color: ${(props) => props.theme.colors.grayThree};
  margin: 10px 0;
`;

interface IGlobalAccount {
  currency: 'USD' | 'HKD' | 'SGD' | 'AUD' | 'CAD' | 'CNY' | 'CHF' | 'EUR' | 'GBP' | 'JPY' | 'NZD';
  nick_name?: string;
  account_name: string;
  account_number: string;
  routing_codes?: string[] | { type: string; values: string }[] | any[];
  swift_code?: string;
  country_code: string;
  capability: {
    clearing_systems: string[];
  };
}

const AccountSummary = () => {
  const dispatch = useDispatch();
  const walletAccounts = useWalletAccount();
  const { globalAccountId } = useParams() as { globalAccountId: string };
  const [globalAccountInfo, setGlobalAccountInfo] = useState<IGlobalAccount | undefined>();
  const [statusFilter, setStatusFilter] = useState<{ value: string }[] | 'ALL'>(OPTION_VALUE_ALL);
  const [transactions, setTransactions] = useState<any[]>();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateShortcutValue, setDateShortValue] = useState<string>();

  const globalAccountColumns = [
    {
      Header: 'Date',
      accessor: 'create_time',
      Cell: ({ value }: { value: string }) => {
        return convertDate(value, YYYY_MM_DD_FORMAT);
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }: { value: string }) => {
        return value;
      },
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ value }: { value: string }) => {
        return value;
      },
    },
    {
      Header: 'Details',
      accessor: 'description',
      Cell: ({ value }: { value: string }) => {
        return value;
      },
    },
    {
      Header: 'Currency',
      accessor: 'currency',
      Cell: ({ value }: { value: string }) => {
        return value;
      },
      getStyle: () => {
        return css`
          width: 150px;
        `;
      },
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      className: 'textRight',
      Cell: ({ value }: { value: number }) => {
        return formatNumber(value, 2);
      },
      getStyle: () => {
        return css`
          width: 100px;
        `;
      },
    },
  ];

  const fetchAccountById = async () => {
    try {
      const { data } = await getGlobalAccountById(walletAccounts?.account_id, globalAccountId);
      if (data) {
        setGlobalAccountInfo(data);
      }
    } catch (err) {
      Logger.error(err);
    }
  };

  const getGlobalAccountTransactions = async () => {
    try {
      const filters = [];

      if (statusFilter !== OPTION_VALUE_ALL) {
        filters.push({ field: 'status', operator: 'in', value: statusFilter.map(({ value }) => value) });
      }
      const { data } = await getGlobalAccountTransactionsById(
        walletAccounts?.account_id,
        globalAccountId,
        convertToApiDate(startDate),
        convertToApiDate(endDate),
        {
          filters,
        }
      );
      if (Array.isArray(data)) {
        setTransactions(data);
      }
    } catch (err) {
      Logger.error(err);
    }
  };

  const applyCalendar = (calendarData: { startDate: string; endDate: string; dateShortcutSelectValue: string }) => {
    setStartDate(calendarData.startDate);
    setEndDate(calendarData.endDate);
    setDateShortValue(calendarData.dateShortcutSelectValue);
  };

  useEffect(() => {
    fetchAccountById();
    getGlobalAccountTransactions();
  }, [JSON.stringify(walletAccounts)]);

  useEffect(() => {
    getGlobalAccountTransactions();
  }, [statusFilter, startDate, endDate]);

  const handleViewBankDetails = () => {
    dispatch(showModal(GLOBAL_ACCOUNT_ACCOUNT_DETAILS, globalAccountInfo));
  };

  const onAccountStatementClick = (accountType: 'GENERAL' | 'AMAZON' | 'EBAY') => async () => {
    if (accountType === 'AMAZON') {
      dispatch(showModal(CREATE_AMAZON_ACCOUNTS_MODAL, { globalAccountId, accountType }));
    } else {
      // TODO: form data as body for POST request
      const payload = {};
      getGlobalAccountStatmentUrl(walletAccounts?.account_id, globalAccountId, accountType, payload).then((response) => {
        const disposition = response.headers['content-disposition']; // attachment; filename="[filename]"
        const extractWord = /attachment; filename="(.*)"/;
        const fileName = extractWord.exec(disposition)?.[1] || 'AirwallexGlobalAccountStatement.pdf';
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    }
  };

  const {
    currency = 'USD',
    nick_name = '',
    account_name = '',
    account_number = '',
    routing_codes,
    swift_code = '',
    country_code = 'US',
    capability,
  } = globalAccountInfo || {};

  return (
    isRealObject(globalAccountInfo) && (
      <Wrapper>
        <BackButton text="All Currencies" path={PATH.WALLET_GLOBAL_ACCOUNTS_OVERVIEW} />
        <HeaderWrapper>
          <FlagWrapper>
            <img src={WALLET_COUNTRIES[currency || 'USD']?.logoUrl} alt="country flags" />
            <CurrencyWrapper>
              <CurrencyHeader>{currency} account</CurrencyHeader>
              {nick_name}
            </CurrencyWrapper>
          </FlagWrapper>
          <Dropdown menuWidth="210px" menuTextAlign="left" icon="/icons/download.svg">
            <DropdownOption onClick={onAccountStatementClick('GENERAL')}>General account statement</DropdownOption>
            <DropdownOption onClick={onAccountStatementClick('AMAZON')}>Amazon account statement</DropdownOption>
            <DropdownOption disabled onClick={onAccountStatementClick('EBAY')}>
              eBay account statement
            </DropdownOption>
          </Dropdown>
        </HeaderWrapper>
        <ColumnWrapper>
          <StyledCard>
            <AccountDetailsContainer>
              <AccountInfoHeader>Account details</AccountInfoHeader>
              <AccountDetailsLink onClick={handleViewBankDetails} to={''}>
                Show all details
              </AccountDetailsLink>
            </AccountDetailsContainer>
            <HR color="grayOne" />
            <Title>Account name</Title>
            <Container>
              {account_name}
              <CopyButton
                onClick={() => {
                  navigator.clipboard.writeText(account_name || '');
                }}
              >
                <img src="/icons/copy-icon.svg" alt="copy" />
              </CopyButton>
            </Container>
            <Title>Bank account number</Title>
            <Container>
              {account_number}
              <CopyButton
                onClick={() => {
                  navigator.clipboard.writeText(globalAccountInfo?.account_number || '');
                }}
              >
                <img src="/icons/copy-icon.svg" alt="copy" />
              </CopyButton>
            </Container>
            {Array.isArray(routing_codes) && routing_codes?.length && (
              <>
                {routing_codes?.[1]?.type && (
                  <>
                    <Title>{routing_codes?.[1]?.type === 'fedwire' ? 'Fedwire routing number' : 'Branch code'}</Title>
                    <Container>
                      {routing_codes?.[1]?.value}
                      <CopyButton
                        onClick={() => {
                          navigator.clipboard.writeText(globalAccountInfo?.routing_codes?.[1]?.value);
                        }}
                      >
                        <img src="/icons/copy-icon.svg" alt="copy" />
                      </CopyButton>
                    </Container>
                  </>
                )}
              </>
            )}
            {Array.isArray(routing_codes) && routing_codes?.length && routing_codes?.[0]?.type === 'bsb' ? (
              <>
                <Title>BSB code</Title>
                <Container>
                  {routing_codes?.[0]?.value}
                  <CopyButton
                    onClick={() => {
                      navigator.clipboard.writeText(routing_codes?.[0]?.value);
                    }}
                  >
                    <img src="/icons/copy-icon.svg" alt="copy" />
                  </CopyButton>
                </Container>
              </>
            ) : (
              routing_codes?.[0] && (
                <>
                  <Title>
                    {routing_codes?.[0]?.type === 'ach' && 'ACH routing number'}
                    {routing_codes?.[0]?.type !== 'ach' && 'Bank code'}
                  </Title>
                  <Container>
                    {routing_codes?.[0]?.value}
                    <CopyButton
                      onClick={() => {
                        navigator.clipboard.writeText(routing_codes?.[0]?.value);
                      }}
                    >
                      <img src="/icons/copy-icon.svg" alt="copy" />
                    </CopyButton>
                  </Container>
                </>
              )
            )}
            {globalAccountInfo?.swift_code && (
              <>
                <Title>SWIFT code</Title>
                <Container>
                  {globalAccountInfo?.swift_code}
                  <CopyButton
                    onClick={() => {
                      navigator.clipboard.writeText(swift_code);
                    }}
                  >
                    <img src="/icons/copy-icon.svg" alt="copy" />
                  </CopyButton>
                </Container>
              </>
            )}
            <Title>Account location</Title>
            <Container>
              {globalAccountInfo?.country_code === 'HK'
                ? 'Hong Kong (China)'
                : countries.getName(country_code, 'en', { select: 'official' })}
              <CopyButton
                onClick={() => {
                  navigator.clipboard.writeText(
                    globalAccountInfo?.country_code === 'HK'
                      ? 'Hong Kong (China)'
                      : countries.getName(country_code, 'en', { select: 'official' })
                  );
                }}
              >
                <img src="/icons/copy-icon.svg" alt="copy" />
              </CopyButton>
            </Container>
          </StyledCard>
          <StyledCard>
            <AccountInfoHeader>Account capabilities</AccountInfoHeader>
            <HR color="grayOne" />
            <Title>Accept funds via</Title>
            <Ul>
              {Array.isArray(globalAccountInfo?.capability.clearing_systems) &&
                globalAccountInfo?.capability?.clearing_systems.map((paymentType) => {
                  return <Li>{paymentType}</Li>;
                })}
              {globalAccountInfo?.country_code === 'HK' && <Li>International SWIFT Transfer</Li>}
            </Ul>
          </StyledCard>
        </ColumnWrapper>
        <GridColumn lg={12} md={12}>
          <SectionTitle>Transactions</SectionTitle>
          <FilterContainer>
            <SelectWrapper>
              <FilterLabel>Status</FilterLabel>
              <StyledSelect
                isMulti
                width={180}
                options={GlobalAccountTransactionsOptions}
                value={statusFilter}
                onChange={setStatusFilter}
                multiValueLabel="Statuses"
              />
            </SelectWrapper>
            <SelectWrapper>
              <FancyDateRangePicker
                startDate={startDate}
                endDate={endDate}
                dateShortcutValue={dateShortcutValue}
                onDateRangeSelectValueChange={setDateShortValue}
                onApply={applyCalendar}
                className={undefined}
                disabled={undefined}
                includeToday={undefined}
              />
            </SelectWrapper>
          </FilterContainer>
          <Table
            columns={globalAccountColumns}
            data={transactions}
            className={undefined}
            isLoading={undefined}
            onRowClick={undefined}
            CollapseContent={undefined}
            parentProps={undefined}
            disabled={undefined}
          />
        </GridColumn>
      </Wrapper>
    )
  );
};

export default AccountSummary;
