import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useLocation, Switch, Route, Redirect } from 'react-router-dom';
import styled, { css } from 'styled-components';

import UnderlineTab from '../../../../../components/UnderlineTab';
import PATH from '../../../path';
import ConversionActivity from "./Activity";
import CreateNewConversion from "./CreateNewConversionPage";
import ConversionDetails from "./Details";
import { useWalletAccount } from '@redux/selectors';

const Tab = styled(UnderlineTab)`
  margin-bottom: 16px;
  ${(props) => props.theme.text.three};
`;

const ConversionOverview = () => {
  return (
    <>
      <Tab selected>Conversion activity</Tab>
      <Switch>
        <Route exact path={PATH.WALLET_CONVERSION_CREATE_CONVERSION} component={CreateNewConversion} />
        <Route path={PATH.WALLET_CONVERSION_VIEW_CONVERSION_ID} component={ConversionDetails} />
        <Route path={PATH.WALLET_CONVERSION} component={ConversionActivity} />
      </Switch>
    </>
  );
};

export default ConversionOverview;
