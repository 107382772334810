import React from 'react';

import _ from 'lodash';
import { useController } from 'react-hook-form';

import Select from '../../components/Select';
import ErrorMessage from './ErrorMessage';

const ControlledSelect = ({ name, control, rules = {}, defaultValue, showError = true, ...restProps }) => {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields, errors },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const error = _.get(errors, name);
  return (
    <>
      <Select {...field} hasError={error} {...restProps} />
      {error?.message && showError && <ErrorMessage>{error?.message}</ErrorMessage>}
    </>
  );
};

export default ControlledSelect;
