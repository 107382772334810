import React from 'react';

import styled from 'styled-components';

const Wrapper = styled.a`
  color: #5d5fef;
`;

const Email = ({ address, className }) => {
  return (
    <Wrapper href={`mailto:${address}`} className={className}>
      {address}
    </Wrapper>
  );
};

export default Email;
