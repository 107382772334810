import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';

import Dropdown, { DropdownOption } from '../../../../../components/Dropdown';
import PATH from '../../../path';
import { CARD_STATUS } from '../../constants';
import { MESSAGE_MODAL } from '@modules/Modal/constants';
import { showModal } from '@redux/modules/UI/actions';
import { fetchWalletCards, fetchWalletCardDetail, updateCardStatus } from '@redux/modules/wallet/actions';
import { useWalletAccountId, useCheckIsAdminViewingAnotherCompany } from '@redux/selectors';

const CardSettingsMenu = ({ cardDetail, source = '', toggleIsLoading = () => {} }) => {
  const { card_id } = cardDetail;
  const history = useHistory();
  const dispatch = useDispatch();
  const walletAccountId = useWalletAccountId();
  const handleEditClick = () => {
    history.push(generatePath(PATH.WALLET_CARDS_EDIT_COMPANY_CARD, { id: card_id }));
  };
  const isAdminViewingAnotherCompany = useCheckIsAdminViewingAnotherCompany();
  const afterUpdateStatus = useCallback(async () => {
    if (source === 'cardDetailPage') {
      await fetchWalletCardDetail(walletAccountId, card_id);
    } else {
      await fetchWalletCards(walletAccountId);
    }
  }, [source]);

  const handleStatusChange = (cardStatus) => async () => {
    toggleIsLoading(true);
    await updateCardStatus(walletAccountId, card_id, cardStatus, afterUpdateStatus);
    toggleIsLoading(false);
  };

  const afterCancelCardSuccess = async ({ statusCode }) => {
    if (statusCode === 200) {
      await afterUpdateStatus();
      dispatch(
        showModal(MESSAGE_MODAL, {
          title: 'Card has cancelled successfully',
          showLeftButton: false,
          rightButtonProps: {
            label: 'Close',
          },
        })
      );
    }
  };

  const handleCloseClick = () => {
    dispatch(
      showModal(MESSAGE_MODAL, {
        title: 'Cancel this card?',
        description: 'By cancelling, this card will no longer be in use. Ensure you have an alternative way to make payments.',
        rightButtonProps: {
          warning: true,
        },
        hideModalAfterConfirm: false,
        onConfirm: async () => {
          await updateCardStatus(walletAccountId, card_id, CARD_STATUS.CLOSED, afterCancelCardSuccess);
        },
      })
    );
  };

  return (
    <Dropdown>
      <DropdownOption onClick={handleEditClick} disabled={isAdminViewingAnotherCompany}>
        Edit card
      </DropdownOption>
      {cardDetail.card_status === CARD_STATUS.ACTIVE && (
        <>
          <DropdownOption onClick={handleStatusChange(CARD_STATUS.INACTIVE)} disabled={isAdminViewingAnotherCompany}>
            Freeze card
          </DropdownOption>
          <DropdownOption onClick={handleCloseClick} disabled={isAdminViewingAnotherCompany}>
            Cancel card
          </DropdownOption>
        </>
      )}
      {cardDetail.card_status === CARD_STATUS.INACTIVE && (
        <DropdownOption onClick={handleStatusChange(CARD_STATUS.ACTIVE)} disabled={isAdminViewingAnotherCompany}>
          Unfreeze card
        </DropdownOption>
      )}
    </Dropdown>
  );
};

export default CardSettingsMenu;
