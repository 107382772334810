import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import AlertCard from '../../../../components/AlertCard';
import Button from '../../../../components/Button';
import FormTitle from '../../../../components/FormTitle';
import Modal from '../../../../components/Modal';
import COLORS from '../../../../styles/colors';
import Logger from '../../../../utils/logger';
import { REFUND_PAYMENT_SUCCESS_MODAL } from '../../../Modal/constants';
import ControlledCheckbox from '../../../ReactHookForm/ControlledCheckbox';
import ControlledInput from '../../../ReactHookForm/ControlledInput';
import ControlledSelect from '../../../ReactHookForm/ControlledSelect';
import RHFMoneyInput from '../../../ReactHookForm/RHFMoneyInput';
import { refundReasonOptions } from './constants';
import { generateRefundPayload, getAvailableRefundAmount } from './helpers';
import { refundPayment } from '@api/modules/integration';
import { ERROR_MESSAGE_REQUIRED } from '@constants/form';
import { showModal } from '@redux/modules/UI/actions';
import { adjustStripeAmount, formatNumber } from '@utils/priceHelpers';
import { toUpperCase } from '@utils/stringHelpers';

const Header = styled.div`
  ${(props) => props.theme.text.three}
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
  ${(props) => props.theme.buttonWrapper}
`;

const FieldWrapper = styled.div`
  margin: 15px 0;
`;

const ErrorWrapper = styled.div`
  ${(props) => props.theme.error}
`;

const RefundModal = ({ payment }) => {
  const dispatch = useDispatch();
  const availableRefundAmount = getAvailableRefundAmount(payment);
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      payments_id: payment.id,
      refundAmount: availableRefundAmount,
      refundCurrency: toUpperCase(payment.currency),
      refund_application_fee: false,
      reverse_transfer: false,
    },
  });
  const [isLoading, toggleIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');

  const onSubmit = async (formData) => {
    try {
      const { reverse_transfer, refund_application_fee } = formData;
      toggleIsLoading(true);
      setApiError('');
      clearErrors('reverse_transfer');
      if (refund_application_fee && !reverse_transfer) {
        setError('reverse_transfer', {
          type: 'custom',
          message:
            'The application fee was taken on the associated transfer, ' +
            'so to refund the application fee you must also set reverse transfer',
        });
      }
      const { data, statusCode, error } = await refundPayment(generateRefundPayload(formData));
      if (error) {
        setApiError(error.message);
      } else if (statusCode === 200) {
        dispatch(showModal(REFUND_PAYMENT_SUCCESS_MODAL, { refund: data }));
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  return (
    <Modal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header>Refund payment</Header>
        <div>
          Refunds take 5–10 days to appear on a customer&apos;s statement. Stripe&apos;s fees for the original payment won&apos;t be
          returned, but there are no additional fees for the refund.
        </div>
        <FieldWrapper>
          <FormTitle scale={5} required>
            Refund amount
          </FormTitle>
          <RHFMoneyInput
            control={control}
            prefix="refund"
            errors={errors}
            selectProps={{ disabled: true }}
            amountRules={{
              required: { value: true, message: ERROR_MESSAGE_REQUIRED },
              max: {
                value: availableRefundAmount,
                message: `Amount should not exceed ${formatNumber(availableRefundAmount)}`,
              },
              min: { value: 0, message: 'Amount should be greater than 0' },
            }}
            full
          />
        </FieldWrapper>
        <FieldWrapper>
          <ControlledCheckbox control={control} name="reverse_transfer" text="Reverse the associated transfer" />
          <ErrorWrapper>{errors?.reverse_transfer?.message}</ErrorWrapper>
        </FieldWrapper>
        <FieldWrapper>
          <ControlledCheckbox control={control} name="refund_application_fee" text="Refund the application fee" />
        </FieldWrapper>
        <FieldWrapper>
          <FormTitle scale={5} required>
            Reason
          </FormTitle>
          <ControlledSelect
            control={control}
            rules={{ required: { value: true, message: ERROR_MESSAGE_REQUIRED } }}
            name="reason"
            options={refundReasonOptions}
            menuPlacement="top"
          />
        </FieldWrapper>
        <FieldWrapper>
          <FormTitle scale={5}>Additional detail</FormTitle>
          <ControlledInput control={control} name="metadata.additional_detail" full />
        </FieldWrapper>
        {apiError && <AlertCard backgroundColor={COLORS.lightOrange} title={apiError} />}
        <ButtonWrapper>
          <Button disabled={isLoading}>Refund</Button>
        </ButtonWrapper>
      </form>
    </Modal>
  );
};

export default RefundModal;
