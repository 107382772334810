import { stringify } from 'query-string';

import { REACT_APP_API_CAL_ENGINE } from '@api/constants';
import { getCompanyId } from '@api/cookies';
import { getRequest, postRequest } from '@api/requests';
import { INVOICE_STATUS } from '@/constants';

const invoiceEndpoint = `${REACT_APP_API_CAL_ENGINE}/v1/invoices`;

export const getFirstInvoiceStartDate = async (payload) => {
  const result = await fetch(`${invoiceEndpoint}/query_start_date`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
  }).then((response) => {
    return response.json();
  });

  return result;
};

const payInvoice = async (invoiceId, payload) => {
  const result = await fetch(`${invoiceEndpoint}/pay/${invoiceId}`, {
    method: 'PUT',
    mode: 'cors',
    body: JSON.stringify(payload),
  });

  return result;
};

const voidInvoice = async (invoiceId) => {
  const result = await fetch(`${invoiceEndpoint}/void/${invoiceId}`, {
    method: 'PUT',
    mode: 'cors',
  });

  return result;
};

const uncollectInvoice = async (invoiceId) => {
  const result = await fetch(`${invoiceEndpoint}/uncollectible/${invoiceId}`, {
    method: 'PUT',
    mode: 'cors',
  });

  return result;
};

export const updateInvoiceStatus = async (invoiceId, status, payload) => {
  switch (status) {
    case INVOICE_STATUS.PAID:
      return await payInvoice(invoiceId, payload);
    case INVOICE_STATUS.VOID:
      return await voidInvoice(invoiceId);
    case INVOICE_STATUS.UNCOLLECTIBLE:
      return await uncollectInvoice(invoiceId);
    default:
      throw new Error(`invalid invoice status: ${status}`);
  }
};

export const getInvoiceDetailById = async (id) => {
  const result = await fetch(`${invoiceEndpoint}/summaries/${id}`, {
    method: 'GET',
    mode: 'cors',
  }).then((response) => {
    return response.json();
  });

  return result;
};

export const createManualInvoice = async (payload) => {
  const { body, status, error } = await fetch(`${REACT_APP_API_CAL_ENGINE}/v1/fundings/charges/invoice`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
  });
  // .then((response) => {
  //   console.log('response: ', response);
  //   return response.json();
  // });

  return { statusCode: status, data: body, error };
};
