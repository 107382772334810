import { isRealNumber } from '@utils/dataTypes';
import { formatNumber } from '@utils/priceHelpers';

export const columnConfig = [
  {
    Header: 'Date',
    accessor: 'date',
  },
  {
    Header: 'Orders',
    accessor: 'orders',
  },
  {
    Header: 'Customers',
    accessor: 'customers',
  },
  {
    Header: 'Total Sales',
    accessor: 'sales',
    Cell: (props) => formatNumber(props.value, 2),
  },
  {
    Header: 'Refunds',
    accessor: 'refunds',
    Cell: (props) => formatNumber(props.value, 2),
  },
  {
    Header: 'Revenue',
    accessor: 'revenue',
    Cell: (props) => formatNumber(props.value, 2),
  },
  {
    Header: 'AOV',
    accessor: 'aov',
    Cell: (props) => {
      return isRealNumber(props.value) ? formatNumber(props.value, 2) : '-';
    },
  },
  {
    Header: 'ACV',
    accessor: 'acv',
    Cell: (props) => {
      return isRealNumber(props.value) ? formatNumber(props.value, 2) : '-';
    },
  },
];
