import { css } from 'styled-components';

import COLORS from '../../styles/colors';

export const dynamicStyles = {
  option: (provided, state) => ({
    ...provided,
    ...(state.data.isInactive && !state.isSelected && { color: COLORS.grayTwo }),
  }),
};

const calendarIconBaseStyle = (url = '') =>
  url && {
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundImage: `url(${url})`,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 23,
      width: 23,
    },
  };

export const getCalendarIconStyle = (url) => ({
  placeholder: (styles) => ({ ...styles, ...calendarIconBaseStyle(url) }),
  singleValue: (styles) => ({ ...styles, ...calendarIconBaseStyle(url) }),
});

export const baseSelectStyles = css`
  ${(props) =>
    props.isLoading &&
    css`
      .react-select__control {
        opacity: 0.5;
      }
    `}

  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}

  .react-select__menu {
    z-index: 1000;
  }

  .react-select__menu-list {
    padding: 0;
  }
  .react-select__option--is-focused {
    background: rgba(58, 12, 163, 0.1);
  }

  .react-select__option--is-selected {
    background-color: ${COLORS.purple};
    color: ${COLORS.white};
  }

  .react-select__menu-list--is-multi {
    .react-select__option--is-selected {
      background-color: initial;
      color: initial;
    }

    .react-select__option {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .react-select__control {
    min-height: 50px;
    border-radius: 5px;
    border: 1px solid ${(props) => (props.hasError ? COLORS.red : COLORS.grayTwo)};
    :hover {
      border: 1px solid ${(props) => (props.hasError ? COLORS.red : COLORS.grayTwo)};
    }
    ${(props) =>
      props.hasError &&
      css`
        box-shadow: inset 1px 0 ${COLORS.red}, inset 0 1px ${COLORS.red}, inset -1px 0 ${COLORS.red},
          inset 0 -1px ${COLORS.red};
        :hover {
          border: 1px solid ${props.hasError ? COLORS.red : COLORS.grayTwo};
          box-shadow: inset 1px 0 ${COLORS.red}, inset 0 1px ${COLORS.red}, inset -1px 0 ${COLORS.red},
            inset 0 -1px ${COLORS.red};
        }
      `}

    &.react-select__control--is-focused {
      border: 1px solid ${COLORS.black};
      box-shadow: inset 1px 0 ${COLORS.black}, inset 0 1px ${COLORS.black}, inset -1px 0 ${COLORS.black},
        inset 0 -1px ${COLORS.black};
      :hover {
        border: 1px solid ${COLORS.black};
        box-shadow: inset 1px 0 ${COLORS.black}, inset 0 1px ${COLORS.black}, inset -1px 0 ${COLORS.black},
          inset 0 -1px ${COLORS.black};
      }
    }

    &.react-select__control--is-disabled {
      background-color: rgba(239, 239, 239, 0.3);
    }

    .react-select__single-value--is-disabled {
      color: ${COLORS.black};
    }
  }

  .react-select__value-container {
    padding-left: 16px;
  }

  .react-select__placeholder {
    color: ${(props) => props.theme.colors.grayTwo};
    ${(props) => props.theme.text.five};
    font-weight: 500;
  }
`;
