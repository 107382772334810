import React, { useState, useEffect } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useTable } from 'react-table';
import styled from 'styled-components';

import Button from '../../../../../components/Button';
import FormTitle from '../../../../../components/FormTitle';
import Modal from '../../../../../components/Modal';
import CreatableSelect from '../../../../../components/Select/Creatable';
import Logger from '../../../../../utils/logger';
import ControlledTextarea from '../../../../ReactHookForm/ControlledTextarea';
import { Table, Th, SummaryTableRow } from '../../components';
import { modalColumnConfig } from '../constants';
import { showConfirmWithdrawModal, showConfirmAppealModal } from '../helpers';
import {
  getSettlementNames,
  createBankTrxSettlement,
  getBankSettlementDetailById,
  withdrawSettlementById,
  appealSettlementById,
} from '@api/modules/dashboard/settlement';
import { findFirstMatchInArray } from '@utils/dataTypes';
import { fetchSettlements } from '@redux/modules/settlements/actions';
import { REVENUE_RECORD_STATUS_VALUE } from '@constants/remittance';
import { MESSAGE_MODAL } from '@modules/Modal/constants';
import { showModal } from '@redux/modules/UI/actions';
import { useAcceptedOffer, useIsAdminView } from '@redux/selectors';
import { formatNumber } from '@utils/priceHelpers';
import { useCanAdminHandleSettlement } from '@utils/userHelpers';

const Header = styled.div`
  ${(props) => props.theme.text.three};
`;

const StyledTable = styled(Table)`
  th {
    padding: 6px 12px;
    font-size: 12px;
  }

  td {
    padding: 4px 12px;
    font-size: 10px;
  }

  tbody {
    background-color: ${(props) => props.theme.colors.grayOne};
  }

  margin-bottom: 24px;
`;

const StyledCreatableSelect = styled(CreatableSelect)`
  width: 50%;
  margin-bottom: 30px;
`;

const ButtonWrapper = styled.div`
  text-align: right;
`;

const Info = styled.span`
  float: right;
  margin-left: 16px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const StyledControlledTextarea = styled(ControlledTextarea)`
  margin-bottom: 12px;
  display: block;
`;

const XeroSettlementModal = ({ selectedIds = [], selectedAccountId, settlement, hideModal, afterSubmit }) => {
  const dispatch = useDispatch();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, toggleIsLoading] = useState(false);
  const dataPageMap = useSelector((state) => state.bankTransaction.dataPageMap);
  const xeroBankAccounts = useSelector((state) => state.settlement.xeroBankAccounts);
  const streams = useSelector((state) => state.fund.streams);
  const activeStream = streams[0];
  const isAdmin = useIsAdminView();
  const adminCanHandleSettlement = useCanAdminHandleSettlement();

  const fetchTransactions = async () => {
    if (settlement) {
      try {
        const { data } = await getBankSettlementDetailById(settlement.settlement_id);
        const trxs = Array.isArray(data.xero_transactions) ? data.xero_transactions : [];
        setTransactions(trxs);
      } catch (err) {
        Logger.error(err);
      }
    } else {
      let allTransactions = [];
      for (const items of Object.values(dataPageMap)) {
        allTransactions = [...allTransactions, ...items];
      }
      const selectedTransactions = allTransactions.filter(({ transaction_id }) => selectedIds.indexOf(transaction_id) !== -1);
      setTransactions(selectedTransactions);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  const selectedBankAccount = findFirstMatchInArray(
    xeroBankAccounts,
    ({ BankAccountID }) => selectedAccountId === BankAccountID || settlement?.account_id === BankAccountID
  );
  const hasSettlement = !!settlement;

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    ...(settlement && { defaultValues: settlement }),
  });

  const selectedTrxCount = Array.isArray(selectedIds) && selectedIds.length > 0 ? selectedIds.length : transactions.length;

  const [settlementNames, setSettlementNames] = useState([]);
  const { id: userId } = useSelector((state) => state.user.profile);
  const { applicationId } = useSelector((state) => state.application.data);
  const acceptedOffer = useAcceptedOffer();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data: transactions,
    columns: modalColumnConfig,
  });

  const fetchSettlementNames = async () => {
    try {
      const result = await getSettlementNames();
      if (Array.isArray(result)) {
        setSettlementNames([...new Set(result)]);
      }
    } catch (err) {
      Logger.error(err);
    }
  };

  useEffect(() => {
    fetchSettlementNames();
  }, []);

  const handleCreateSettlement = (inputValue) => {
    setValue('name', inputValue);
    setSettlementNames([inputValue, ...settlementNames]);
  };

  const onSubmit = async ({ name }) => {
    const result = await createBankTrxSettlement({
      name,
      funding_id: applicationId,
      offer_id: acceptedOffer.id,
      stream_id: activeStream?.stream_id,
      // remittance_id: UUID_FAKER,
      source: 'xero',
      currency: selectedBankAccount.currency, // TODO: use account currency
      account_id: selectedBankAccount.BankAccountID,
      created_by: userId,
      transaction_ids: transactions.map(({ transaction_id }) => transaction_id),
    });

    if (result) {
      dispatch(
        showModal(MESSAGE_MODAL, {
          title: 'Revenue record submitted successfully',
          description: `${selectedTrxCount} item${
            selectedTrxCount > 1 ? 's' : ''
          } added successfully, revenue settlement record will send to client to confirm, if not, the record will automatically confirmed after 2 days on sales report page.`,
          showLeftButton: false,
          rightButtonProps: {
            label: 'Close',
          },
          onConfirm: afterSubmit,
        })
      );
    }
  };

  const withdrawSettlement = async () => {
    try {
      toggleIsLoading(true);

      const { statusCode } = await withdrawSettlementById(settlement.settlement_id);

      if (statusCode === 204) {
        await fetchSettlements(isAdmin);
        hideModal();
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const appealSettlement = async () => {
    try {
      toggleIsLoading(true);

      const appealNote = getValues('appealNote');
      const { statusCode } = await appealSettlementById(settlement.settlement_id, { appeal_note: appealNote ?? '' });

      if (statusCode === 204) {
        await fetchSettlements(isAdmin);
        hideModal();
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      toggleIsLoading(false);
    }
  };

  const handleWithdrawClick = async (event) => {
    dispatch(showConfirmWithdrawModal(withdrawSettlement));
  };

  const handleAppealClick = async (event) => {
    dispatch(showConfirmAppealModal(appealSettlement));
  };

  const summary = {
    spentAmount: transactions
      .filter((trx) => trx.Type.indexOf('SPEND') !== -1)
      .map(({ Total }) => Total)
      .reduce((prev, curr) => prev + curr, 0),
    receivedAmount: transactions
      .filter((trx) => trx.Type.indexOf('RECEIVE') !== -1)
      .map(({ Total }) => Total)
      .reduce((prev, curr) => prev + curr, 0),
  };

  return (
    <Modal width={900}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header>Add revenue record</Header>
        <FormTitle scale={5}>Revenue Name</FormTitle>
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <StyledCreatableSelect
                {...field}
                options={settlementNames.map((name) => ({
                  value: name,
                  label: name,
                }))}
                onCreateOption={handleCreateSettlement}
                hasError={errors.name}
                disabled={hasSettlement}
              />
            );
          }}
        />
        <BoldText>{`${selectedTrxCount} item${selectedTrxCount > 1 ? 's' : ''} selected`}</BoldText>
        <Info>
          Account: <BoldText>{selectedBankAccount?.BankAccountName || settlement.account_id}</BoldText>
        </Info>
        <Info>
          Service Provider: <BoldText>Xero</BoldText>
        </Info>
        <StyledTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <Th
                      isSortable={column.isSortable}
                      {...column.getHeaderProps(column.isSortable && column.getSortByToggleProps())}
                      className={column.className}
                    >
                      {column.render('Header')}
                    </Th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            <SummaryTableRow>
              {modalColumnConfig.map(({ accessor, className }, index) => {
                const value = summary ? summary[accessor] : '';
                if (index === 0) {
                  return <td>Summary</td>;
                }

                const formattedValue = ['receivedAmount', 'spentAmount'].indexOf(accessor) !== -1 ? formatNumber(value, 2) : value;

                return <td className={className}>{formattedValue}</td>;
              })}
            </SummaryTableRow>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className={cell.column.className}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
        {!hasSettlement && (
          <ButtonWrapper>
            <Button type="submit">Confirm</Button>
          </ButtonWrapper>
        )}
      </form>
      {hasSettlement && settlement.status !== REVENUE_RECORD_STATUS_VALUE.CONFIRMED && isAdmin && adminCanHandleSettlement && (
        <Button onClick={handleWithdrawClick} secondary warning>
          Withdraw
        </Button>
      )}
      {hasSettlement && settlement.status === REVENUE_RECORD_STATUS_VALUE.SUBMITTED && !isAdmin && (
        <>
          <StyledControlledTextarea control={control} name="appealNote" rules={{ maxLength: 1000 }} full />
          <Button onClick={handleAppealClick} secondary warning>
            Appeal
          </Button>
        </>
      )}
    </Modal>
  );
};

export default XeroSettlementModal;
